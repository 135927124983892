import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";

const SurfaceMenu = ({ label, value, setValue }) => {

  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);


  const valuesList = [
    {
      name: "Natural Grass",
      value: "natural"
    },
    {
      name: "Artificial Grass",
      value: "artificial"
    },
    {
      name: "Hybrid Grass",
      value: "hybrid"
    }


  ];

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  const handleSelect = (value) => {
    setValue(value);
    setIsMenuOpen(false);
  };



  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);

      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref}>
      <DropDownMenu onClick={() => handleMenuOpen()} isMenuOpen={isMenuOpen}>
        {value !== null ? (<h4>{value} Grass</h4>) : (<h5>{label}</h5>)}

        {isMenuOpen ? <UpSvg /> : <DownSvg />}
      </DropDownMenu>
      {isMenuOpen &&
        <DropDownList>
          {valuesList.map((item, index) => (
            <DropDownMenuItem key={index} onClick={() => handleSelect(item.value)}>
              <input type={"radio"} checked={value == item.value} onChange={() => handleSelect(item.value)}></input>
              <p>{item.value} Grass</p>
            </DropDownMenuItem>

          ))}

        </DropDownList>
      }
    </Container>
  );
};


export default SurfaceMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  position: relative;

`;

const DropDownMenu = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.editLeague.selectMenuBg};
    padding: 11px 15px;
    border: 1px solid #BAC3E8;
    border-radius: 6px;
    border-radius: ${(props) => props.isMenuOpen ? "8px 8px 0 0" : ""} ;
    .group {
      align-items: center;
      display: flex;
      column-gap: 5px;
      img {
        height: 14px;
        width: 18px;
        border-radius: 4px;
      }
    }
    img {
           cursor: "pointer";
      height: 21px;
      width: 22px;
    }
    svg {
           cursor: "pointer";
           fill:${({ theme }) => theme.editLeague.selectMenuSvgBg};
           stroke:${({ theme }) => theme.editLeague.selectMenuSvgColor}
    }
    h4{
      text-transform:capitalize;
      font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
    }
    h5{
      text-transform:capitalize;
      font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
      color: #ABABAB;
    }
  `;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  border: 1px solid #BAC3E8;
  top: 39px;
  border-top: 1px solid #3c4a5c;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  max-height: 400px;
  width: 200px;
  overflow-x: hidden;
  color: black;
  background-color: #ffffff;
  
`;
const DropDownMenuItem = styled.li`
display:flex;
align-items:center;
column-gap:5px;
background-color: ${({ theme }) => theme.editLeague.selectMenuBg};
  cursor: pointer;
  img {
    border-radius: 4px;
    height: 14px;
    width: 18px;
  }

  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;

  padding: 5px 10px;
  div {
    column-gap: 5px;
    align-items: center;
    display: flex;
    flex-direction: row;
    .check {
      margin-right: 5px;
      background: red;
      color: red;
    }
  }

  p {
    text-transform:capitalize;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
  }
  
`;
