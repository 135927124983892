export const mapFixturesAndPrices = (competitions, fixtures_prices) => {
  let newData = [];

  competitions?.forEach((element) => {
    const newFixuresArray = buildNewFixtureArrayWithPrices(
      element.fixtures,
      fixtures_prices
    );

    //  Deep copy
    const newElement = JSON.parse(JSON.stringify(element));
    newElement.fixtures = newFixuresArray;

    newData.push(newElement);
  });

  return newData;
};

const buildNewFixtureArrayWithPrices = (fixtures, prices) => {
  let returnArray = [];

  fixtures?.forEach((fixture) => {
    const price = prices.find(
      (element) => element.fixture_id == fixture.fixture_id
    );

    const newElement = { fixture: fixture, price: price };
    returnArray.push(newElement);
  });

  return returnArray;
};
