export const checkFlag = (country) => {
    if (!country) {
        return;
    }
    let country_code = country.country_code;

    if (country.name == "Scotland" || country.name == "Northern Ireland") {
        country_code = "GB";
    }
    if (country.name == "North America") {
        country_code = "GB";
    }
    if (country.name == "Bosnia Herzegovina") {
        country_code = "BA";
    }
    if (country.name == "Holland") {
        country_code = "NL";
    }
    if (country !== null) {

        return (`https://flagcdn.com/${country_code.toLowerCase()}.svg`);
    }

};