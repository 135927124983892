import { useEffect, useState } from "react";

export const useDarkMode = () => {
	const [theme, setTheme] = useState("light");
	const toggleTheme = () => {
		if (theme === "light") {
			window.localStorage.setItem("theme", "dark");
			setTheme("dark");
		} else {
			window.localStorage.setItem("theme", "light");
			setTheme("light");
		}
	};

	useEffect(() => {
		let localTheme = window.localStorage.getItem("theme");

		//use system prefered if not local value stored
		// if (!localTheme && window.matchMedia('(prefers-color-scheme: dark)').matches) {
		// 	localTheme = "dark"
		// }

		localTheme && setTheme(localTheme);
	}, []);

	return [theme, toggleTheme];
};
