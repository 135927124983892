import { POST } from "../APiHelper/API";
import { formatted_date_for_backend } from "../../helper/Generic/dateTimeFormat";

export const getRsLoggedSelections = (
    country_ids,
    start_time,
    end_time,
    competition_ids,
    term,
    tiers,
    onSuccess,
    onError
) => {
    const data = {
        country_ids: country_ids,
        competition_ids: competition_ids,
        start_time: formatted_date_for_backend(start_time),
        end_time: formatted_date_for_backend(end_time),
        term: term,
        tiers: tiers,
    };

    const source = POST("/api/v1/runshop_bets/logged_bets", data, onSuccess, onError);

    return source;
};
