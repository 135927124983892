import { formatted_date_for_backend } from "../../../helper/Generic/dateTimeFormat";
import { POST } from "../../APiHelper/API";

export const createNewSeason = (
    competition_ids,
    name,
    start_date,
    end_date,
    onSuccess,
    onError
) => {
    const data = {
        competition_ids: competition_ids,
        name: name,
        start_date: formatted_date_for_backend(start_date),
        end_date: formatted_date_for_backend(end_date)
    }
    POST("/api/v1/leagues/create_season", data, onSuccess, onError)
}