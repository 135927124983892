import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";
import { YesNo } from "../../../helper/Values/TeamFormation";
const ShareStadiumMenu = ({ label, value, setValue }) => {

  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);



  const handleClick = (item) => {
    setValue(item.value);
  };

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  const checkValue = () => {
    if (value == true) {
      return <h4>Yes</h4>;
    } else return <h4>No</h4>;
  };


  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);

      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);


  return (
    <Container ref={ref}>
      <DropDownMenu onClick={() => handleMenuOpen()} isMenuOpen={isMenuOpen}>

        {value != null ? (checkValue()) : (<h4>{label}</h4>)}

        {isMenuOpen ? <UpSvg /> : <DownSvg />}
      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>
          {YesNo.map((item, index) => (
            <DropDownMenuItem key={index} onClick={() => handleClick(item)}>
              <input type={"radio"}
                value={item.value}
                checked={value === item.value}
              ></input>
              <p>{item.label}</p>
            </DropDownMenuItem>
          ))}





        </DropDownList>
      )}
    </Container>
  );
};


export default ShareStadiumMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  position: relative;
  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: rgba(60, 74, 92, 1);
  }
`;
const DropDownMenu = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.editLeague.selectMenuBg};
    padding: 11px 15px;
    border: 1px solid #BAC3E8;
    border-radius: 6px;
    border-radius: ${(props) => props.isMenuOpen ? "8px 8px 0 0" : ""} ;
    .group {
      align-items: center;
      display: flex;
      column-gap: 5px;
      img {
        height: 14px;
        width: 18px;
        border-radius: 4px;
      }
    }
    img {
      cursor: pointer;
      height: 21px;
      width: 22px;
    }
    svg {
      cursor: pointer;
      fill:${({ theme }) => theme.editLeague.selectMenuSvgBg};
    stroke:${({ theme }) => theme.editLeague.selectMenuSvgColor}
    }
    h4{
      font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;

color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
    }
  `;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top: 39px;
  border: 1px solid #BAC3E8;
  top: 39px;
  border-top: 1px solid #3c4a5c;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  max-height: 200px;
  width: 100px;
  overflow-x: hidden;
  color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
  background-color: ${({ theme }) => theme.editLeague.selectMenuBg};
  .searchGroup {
    position: relative;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
    input {
      align-items: center;
      position: relative;
      width: 100%;
      padding: 4px 10px 2px 20px;
      background: #eef2fd;
      border: none;
      border: 1px solid #d9dfe7;
      border-radius: 8px;
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      :focus-visible {
        outline: none;
        border: 1px solid #006fff;
      }
     
    }
    svg {
      position: absolute;
      z-index: 1;
      top: 8, 5px;
      left: 7px;
    }
  }
`;
const DropDownMenuItem = styled.li`
background-color: ${({ theme }) => theme.editLeague.selectMenuBg};
  cursor: pointer;
display:flex;
align-items:center;
column-gap:5px;
  img {
    border-radius: 4px;
    height: 14px;
    width: 18px;
  }

  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;
  padding: 5px 10px;
  div {
    column-gap: 5px;
    align-items: center;
    display: flex;
    flex-direction: row;
    .check {
      margin-right: 5px;
      background: red;
      color: red;
    }
  }

  p {
    
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
  }
 
`;
