import { PATCH, POST } from "../../APiHelper/API";
import { formatted_date_for_backend } from "../../../helper/Generic/dateTimeFormat";

export const editSeasonAPI = (
  id,
  name,
  startDate,
  endDate,
  onSuccess,
  onError
) => {
  const data = {
    id: id,
    name: name,
    start_date: formatted_date_for_backend(startDate),
    end_date: formatted_date_for_backend(endDate),
  };
  PATCH("/api/v1/leagues/edit_season", data, onSuccess, onError);
};
