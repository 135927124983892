import { POST } from "../../APiHelper/API";

export const removeOtherTeamsFromStadium = (
    team_id,
    stadium_id,
    onSuccess,
    onError
) => {
    const data = {
        team_id: team_id,
        stadium_id: stadium_id
    }

    POST(`/api/v1/leagues/stadiums/remove_other_teams`, data, onSuccess, onError)
}