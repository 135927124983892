import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { getCurrentUser } from "../api/user/GetCurrentUser";
import LocalStorage from "../helper/Generic/LocalStorage";
import { loginUser, logoutUser } from "../Redux/user/user";

const ProtectedRoute = ({ redirectPath = "/login", user }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const authToken = LocalStorage.getAuthenticationToken();


  useEffect(() => {
    if (authToken !== null) {
      getCurrentUser(
        function (response) {
          if (!user) {
            dispatch(loginUser(response.data.user));
          }
          navigate({ redirectPath, search: location.search });
        },
        function () { }
      );
    }
  }, [])


  if (user == null) {
    return (
      <Navigate to={redirectPath} state={{ backTo: `${location.pathname}` }} />
    );
  }

  if (user) {
    return <Outlet />;
  }

};

export default ProtectedRoute;
