import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as SortSvg } from "../../assets/icons/BettingIcons/sort.svg";
import { ReactComponent as YellowCard } from "../../assets/icons/LeaguesIcons/yellowCard.svg";
import { ReactComponent as RedCard } from "../../assets/icons/LeaguesIcons/redCard.svg";
import {
  ALL,
  ATTACK,
  DEFENSIVE,
  GK,
  MIDFIELD,
  OTHER,
} from "../../helper/Values/TeamFormation";
const PlayerStatsHeader = ({
  index,
  type,
  handleChangeSortType,
  team,
  navigateToSquadManager,
}) => {
  const [field1, setField1] = useState("");
  const [field2, setField2] = useState("");
  const [field3, setField3] = useState("");
  const [field4, setField4] = useState("");

  const [field1SortKey, setField1SortKey] = useState("");
  const [field2SortKey, setField2SortKey] = useState("");
  const [field3SortKey, setField3SortKey] = useState("");
  const [field4SortKey, setField4SortKey] = useState("");

  const [stField1, setStField1] = useState("");
  const [stField2, setStField2] = useState("");
  const [stField3, setStField3] = useState("");
  const [stField4, setStField4] = useState("");
  const [stField5, setStField5] = useState("");
  const [stField6, setStField6] = useState("");
  const [stField7, setStField7] = useState("");
  const [stField8, setStField8] = useState("");

  const [stField1Sk, setstField1Sk] = useState("");
  const [stField2Sk, setstField2Sk] = useState("");
  const [stField3Sk, setstField3Sk] = useState("");
  const [stField4Sk, setstField4Sk] = useState("");
  const [stField5Sk, setstField5Sk] = useState("");
  const [stField6Sk, setstField6Sk] = useState("");
  const [stField7Sk, setstField7Sk] = useState("");
  const [stField8Sk, setstField8Sk] = useState("");

  const checkType = (type) => {
    switch (type) {
      case GK:
        setField1("Goals Conceded");
        setField2("xG Conceded");
        setField3("Aerial Duels");
        setField4("Aerial Duels Won");

        setField1SortKey("gk_saves");
        setField2SortKey("xg_save");
        setField3SortKey("gk_aerial_duels");
        setField4SortKey("gk_aerial_duels_won");

        setStField1("Min");
        setStField2("Starts");
        setStField3("Cards");
        setStField4("Cards");
        setStField5("Clean Sheets");
        setStField6("Saves");
        setStField7("Assists");
        setStField8("Expected Assists");

        setstField1Sk("minutes_played");
        setstField2Sk("starts");
        setstField3Sk("yellow_cards");
        setstField4Sk("red_cards");
        setstField5Sk("gk_clean_sheets");
        setstField6Sk("gk_conceded_goals");
        setstField7Sk("assists");
        setstField8Sk("xg_assist");

        break;
      case DEFENSIVE:
        setField1("Defensive Duels");
        setField2("Defensive Duels Won");
        setField3("Interceptions");
        setField4("Shots Blocked");

        setField1SortKey("defensive_duels");
        setField2SortKey("new_defensive_duels_won");
        setField3SortKey("interceptions");
        setField4SortKey("shots_blocked");

        setStField1("Min");
        setStField2("Starts");
        setStField3("Cards");
        setStField4("Cards");
        setStField5("Goals");
        setStField6("Expected Goals");
        setStField7("Assists");
        setStField8("Expected Assists");

        setstField1Sk("minutes_played");
        setstField2Sk("starts");
        setstField3Sk("yellow_cards");
        setstField4Sk("red_cards");
        setstField5Sk("goals");
        setstField6Sk("xg_shot");
        setstField7Sk("assists");
        setstField8Sk("xg_assist");
        break;

      case MIDFIELD:
        setField1("Forward Passes");
        setField2("Key Passes");
        setField3("Interceptions");
        setField4("Ball Recoveries");

        setField1SortKey("forward_passes");
        setField2SortKey("key_passes");
        setField3SortKey("interceptions");
        setField4SortKey("recoveries");

        setStField1("Min");
        setStField2("Starts");
        setStField3("Cards");
        setStField4("Cards");
        setStField5("Goals");
        setStField6("Expected Goals");
        setStField7("Assists");
        setStField8("Expected Assists");

        setstField1Sk("minutes_played");
        setstField2Sk("starts");
        setstField3Sk("yellow_cards");
        setstField4Sk("red_cards");
        setstField5Sk("goals");
        setstField6Sk("xg_shot");
        setstField7Sk("assists");
        setstField8Sk("xg_assist");
        break;

      case ATTACK:
        setField1("Succesfull Dribbles");
        setField2("Key Passes");
        setField3("Shots On Target");
        setField4("Opp. Half Recoveries");

        setField1SortKey("successful_dribbles");
        setField2SortKey("key_passes");
        setField3SortKey("shots_on_target");
        setField4SortKey("opponent_half_recoveries");

        setStField1("Min");
        setStField2("Starts");
        setStField3("Cards");
        setStField4("Cards");
        setStField5("Goals");
        setStField6("Expected Goals");
        setStField7("Assists");
        setStField8("Expected Assists");

        setstField1Sk("minutes_played");
        setstField2Sk("starts");
        setstField3Sk("yellow_cards");
        setstField4Sk("red_cards");
        setstField5Sk("goals");
        setstField6Sk("xg_shot");
        setstField7Sk("assists");
        setstField8Sk("xg_assist");
        break;

      case OTHER:
        setField1("");
        setField2("");
        setField3("");
        setField4("");

        setStField1("Min");
        setStField2("Starts");
        setStField3("Cards");
        setStField4("Cards");
        setStField5("Goals");
        setStField6("Expected Goals");
        setStField7("Assists");
        setStField8("Expected Assists");

        setstField1Sk("minutes_played");
        setstField2Sk("starts");
        setstField3Sk("yellow_cards");
        setstField4Sk("red_cards");
        setstField5Sk("goals");
        setstField6Sk("xg_shot");
        setstField7Sk("assists");
        setstField8Sk("xg_assist");

        break;
      default:
        break;
    }
  };

  useEffect(() => {
    checkType(type);
  }, [index, type]);

  return (
    <Container>
      <LeftSide>
        <Number></Number>
        <Avatar>
          {/* {index === 0 && <img src={team?.logo_url} alt='teamIc' />} */}
          {/* <img src={team?.logo_url} alt='teamIc' /> */}
        </Avatar>
        <NameAndPosition onClick={(e) => navigateToSquadManager()}>
          {/* {index === 0 && <>
                            <h1>{team?.name} </h1>
                        </>} */}
          {/* <h1>{team?.name} </h1> */}
        </NameAndPosition>
        <StatsCell>
          {/* {index === 0 &&
                            <>
                                <h1>Min.</h1>
                                <SortSvg onClick={() => handleChangeSortType(ALL, "minutes_played")} />
                            </>} */}
          <h1>{stField1}</h1>
          <SortSvg onClick={() => handleChangeSortType(type, stField1Sk)} />
        </StatsCell>
        <StatsCell style={{ minWidth: "64px" }}>
          {/* {index === 0 &&
                            <>
                                <h1>Starts</h1>
                                <SortSvg onClick={() => handleChangeSortType(ALL, "starts")} />
                            </>} */}
          <h1>{stField2}</h1>
          <SortSvg onClick={() => handleChangeSortType(type, stField2Sk)} />
        </StatsCell>
        <StatsCell style={{ minWidth: "65px" }}>
          {/* {index === 0 && <>
                            <YellowCard />
                            <h1>Cards</h1>
                            <SortSvg onClick={() => handleChangeSortType(ALL, "yellow_cards")} />
                        </>} */}
          <YellowCard />
          <h1>{stField3}</h1>
          <SortSvg onClick={() => handleChangeSortType(type, stField3Sk)} />
        </StatsCell>
        <StatsCell style={{ minWidth: "65px" }}>
          {/* {index === 0 && <>
                            <RedCard />
                            <h1>Cards</h1>
                            <SortSvg onClick={() => handleChangeSortType(ALL, "red_cards")} />

                        </>} */}
          <RedCard />
          <h1>{stField4}</h1>
          <SortSvg onClick={() => handleChangeSortType(type, stField4Sk)} />
        </StatsCell>

        <StatsCell style={{ minWidth: "48px" }}>
          {/* {index === 0 && <>
                            <h1>Goals</h1>
                            <SortSvg onClick={() => handleChangeSortType(ALL, "goals")} />

                        </>} */}
          <h1>{stField5}</h1>
          <SortSvg onClick={() => handleChangeSortType(type, stField5Sk)} />
        </StatsCell>
        <StatsCell style={{ minWidth: "74px" }}>
          {/* {index === 0 && <>

                            <h1>Expected Goals</h1>
                            <SortSvg onClick={() => handleChangeSortType(ALL, "xg_shot")} />
                        </>} */}
          <h1>{stField6}</h1>
          <SortSvg onClick={() => handleChangeSortType(type, stField6Sk)} />
        </StatsCell>

        <StatsCell style={{ minWidth: "60px" }}>
          {/* {index === 0 && <>
                            <h1>Assists</h1>
                            <SortSvg onClick={() => handleChangeSortType(ALL, "assists")} />

                        </>} */}
          <h1>{stField7}</h1>
          <SortSvg onClick={() => handleChangeSortType(type, stField7Sk)} />
        </StatsCell>
        <StatsCell style={{ minWidth: "74px" }}>
          {/* {index === 0 && <>

                            <h1>Expected Assists</h1>
                            <SortSvg onClick={() => handleChangeSortType(ALL, "xg_assist")} />
                        </>} */}
          <h1>{stField8}</h1>
          <SortSvg onClick={() => handleChangeSortType(type, stField8Sk)} />
        </StatsCell>
      </LeftSide>

      {/* </ColumnGroup> */}
      {/* <ColumnGroup>

                {index === 0
                    &&
                    <RightSide style={{ justifyContent: "center" }}>
                        <StatsCell>
                            <h1>Per 90 mins</h1>
                        </StatsCell>
                    </RightSide>
                } */}

      <RightSide>
        <StatsCell style={{ minWidth: "86px" }}>
          <h1>{field1}</h1>
          {field1 != "" && (
            <SortSvg
              onClick={() => handleChangeSortType(type, field1SortKey)}
            />
          )}
        </StatsCell>
        <StatsCell style={{ minWidth: "100px" }}>
          <h1>{field2}</h1>
          {field2 != "" && (
            <SortSvg
              onClick={() => handleChangeSortType(type, field2SortKey)}
            />
          )}
        </StatsCell>
        <StatsCell style={{ minWidth: "65px" }}>
          <h1>{field3}</h1>
          {field3 != "" && (
            <SortSvg
              onClick={() => handleChangeSortType(type, field3SortKey)}
            />
          )}
        </StatsCell>
        <StatsCell style={{ minWidth: "85px" }}>
          <h1>{field4}</h1>
          {field4 != "" && (
            <SortSvg
              onClick={() => handleChangeSortType(type, field4SortKey)}
            />
          )}
        </StatsCell>
      </RightSide>
      {/* </ColumnGroup> */}
    </Container>
  );
};

export default PlayerStatsHeader;
const Container = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  z-index: 2;
  position: sticky;
  top: 185px;
`;
const LeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background: ${({ theme }) => theme.playersData.headerLeftBg};
  padding: 9px 7px;
  width: 100%;
  min-height: 50px;
  min-width: 820px;
`;
const RightSide = styled(LeftSide)`
  background: ${({ theme }) => theme.playersData.headerRightBg};
  min-width: unset;
`;
const StatsCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  width: 100%;
  gap: 2px;

  h1 {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: center;
    color: ${({ theme }) => theme.playersData.color};
  }
  svg {
    cursor: pointer;
    min-width: 10px;
    height: 100%;
  }
`;
const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;
  overflow: hidden;
  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
`;
const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  min-height: 32px;
`;
const NameAndPosition = styled(StatsCell)`
  align-items: start;
  flex-direction: column;
  min-width: 130px;
  max-width: 130px;
  cursor: pointer;
  h1 {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: left;
    color: ${({ theme }) => theme.playersData.color};
    text-decoration: underline;
  }
`;
const ColumnGroup = styled.div`
display:flex;
flex-direction:column;
width:100%;
gap:5px;
background: ${({ theme }) => theme.squadManager.bg};
position:sticky:
top:200px;
`;
