import { POST } from "../../APiHelper/API";
let source;
export const searchLeague = (season_ids, country_ids, name, onSuccess, onError) => {
    const data = {
        season_ids: season_ids,
        country_ids: country_ids,
        name: name,

    }
    source = POST('/api/v1/leagues/search', data, onSuccess, onError, true, source)
}