import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { ReactComponent as UpSvg } from "../../../assets/icons/BettingIcons/downIcon.svg";
const BrokerMenu = ({ brokers, setBroker, broker }) => {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleSelect = (value) => {
    setBroker(value);
    setIsMenuOpen(false);
  };

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref}>
      <DropDownMenu onClick={() => handleMenuOpen()} isMenuOpen={isMenuOpen}>
        <h2>
          {broker === null || broker === undefined ? "Select" : broker.name}{" "}
        </h2>
        {isMenuOpen ? (
          <UpSvg style={{ transform: "rotate(180deg)" }} />
        ) : (
          <UpSvg />
        )}
      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>
          {brokers?.map((item, index) => (
            <DropDownMenuItem key={index} onClick={(e) => handleSelect(item)}>
              <h2>{item.name}</h2>
            </DropDownMenuItem>
          ))}
        </DropDownList>
      )}
    </Container>
  );
};

export default BrokerMenu;
const Container = styled.div`
  min-width: 70px;
  position: realtive;
`;
const DropDownMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.matchElementBg};
  border: 1px solid #cbd7f5;
  border-radius: 2px;
  padding: 2px 6px;
  width: 70px;
  h2 {
    font-family: "Roboto";

    font-weight: 500;
    font-size: 8px;
    line-height: 150%;
    text-transform: capitalize;
    color: ${({ theme }) => theme.leagueColor};
  }
  cursor: pointer;
`;
const DropDownList = styled.ul`
  position: absolute;
  z-index: 1;
  background: ${({ theme }) => theme.matchElementBg};
  list-style: none;
  h2 {
    font-family: "Roboto";

    font-weight: 500;
    font-size: 8px;
    line-height: 150%;
    text-transform: capitalize;
    color: ${({ theme }) => theme.leagueColor};
  }
`;
const DropDownMenuItem = styled.li`
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: -1px;
  width: 70px;
  background: ${({ theme }) => theme.matchElementBg};
  border: 1px solid #cbd7f5;
  border-radius: 2px;
  padding: 2px 0px;
`;
