import { PATCH } from "../../APiHelper/API";

export const updateStadium = (stadium_id, name, capacity, length, width, surface,
    location, latitude, longitude, altitude, team_not_listed, other_sports, race_track, country_id, onSuccess, onError) => {

    const data = {
        name: name,
        capacity: capacity,
        length: length,
        width: width,
        surface: surface,
        location: location,
        lat: latitude,
        long: longitude,
        altitude: altitude,
        team_not_listed: team_not_listed,
        other_sports: other_sports,
        race_track: race_track,
        country_id: country_id
    }
    PATCH(`/api/v1/leagues/stadiums/${stadium_id}`, data, onSuccess, onError)
}