import React, { useState } from "react";
import styled from "styled-components";
import LeaguesHeader from "../../components/leaguesComponents/LeaguesHeader";
import LeaguesServiceProvider from "../../context/useLeaguesDataService";
import RequestMenuServiceProvider from "../../context/useRequestMenuService";
import LeaguesData from "../Leagues/LeaguesData";
import MatchPreviewServiceProvider from "../../context/useMatchPreviewService";
import NotesServiceProvider from "../../context/useNotesService";

const Leagues = () => {

  return (
    <RequestMenuServiceProvider>
      <Container>
        <LeaguesServiceProvider>
          <MatchPreviewServiceProvider>
            <NotesServiceProvider>
              <LeaguesData />
            </NotesServiceProvider>
          </MatchPreviewServiceProvider>
        </LeaguesServiceProvider>

      </Container>
    </RequestMenuServiceProvider>
  );
};

export default Leagues;

const Container = styled.div`
  display: flex;
  flex-direction: column;
 background:none;
  width: 100%;
  min-width: 1600px;
  position: relative;
`;
