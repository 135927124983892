import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as SearchSvg } from "../../../assets/icons/search.svg";
import LoaderSpinner from "../../../helper/LoaderSpinner";

const SearchCompetitionMenu = ({
  label,
  value,
  setValue,
  valuesList,
  term,
  setTerm,
  shouldSearch,
  handleSearch,
}) => {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const handleSetTerm = (e) => {
    setTerm(e.currentTarget.value);
    shouldSearch.current = true;
    setIsMenuOpen(true);
  };

  const handleSetValue = (value) => {
    setValue(value);
    setTerm(value.competition);
    shouldSearch.current = false;
    setIsMenuOpen(false);
  };

  const handleSearchBtn = () => {
    handleSearch(function () {
      setIsMenuOpen(true);
    })
  };



  return (
    <Container ref={ref} isMenuOpen={isMenuOpen}>
      <SearchGroup>
        <SearchIconWrap>
          <SearchSvg />
        </SearchIconWrap>
        <Input
          name="competitionSearch"
          onClick={() => setIsMenuOpen(true)}
          placeholder={"Search"}
          value={term}
          onChange={(e) => handleSetTerm(e)}
        ></Input>
        <SearchButton onClick={() => handleSearchBtn()}>Search</SearchButton>
      </SearchGroup>
      {isMenuOpen && valuesList?.length > 0 && (
        <DropDownList>
          {valuesList?.map((item, index) => (
            <DropDownMenuItem key={index} onClick={(e) => handleSetValue(item)}>
              <input
                type="radio"
                readOnly
                value={item.competition_id}
                checked={item.competition_id == value?.competition_id}
              />
              <p>
                {item.competition} ({item.country.name}) ({item.competition_id}){" "}
              </p>
            </DropDownMenuItem>
          ))}
        </DropDownList>
      )}
    </Container>
  );
};

export default SearchCompetitionMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const DropDownList = styled.ul`
  position: absolute;
  width: 280px;
  z-index: 2;
  border: 1px solid #bac3e8;
  top: 38px;
  border-top: 1px solid #3c4a5c;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  max-height: 350px;
  overflow-x: hidden;
  color: black;
  background-color: ${({ theme }) => theme.editLeague.readOnlyInputs};
  padding: 5px 10px;
`;

const DropDownMenuItem = styled.li`
  display: flex;
  align-items: center;
  column-gap: 5px;
  background-color: ${({ theme }) => theme.editLeague.readOnlyInputs};
  cursor: pointer;
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;

  padding: 5px 0px;

  p {
    text-transform: capitalize;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  }
  span {
    font-weight: 700;
  }
`;
const Input = styled.input`
  width: 280px;
  padding: 10px 30px;
  border-radius: 4px;
  border: 1px solid #d9dfe7;
  background: ${({ theme }) => theme.editLeague.readOnlyInputs};
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
`;
const SearchButton = styled.button`
  padding: 0 30px;
  border-radius: 4px;
  border: 1px solid #006fff;
  background: ${({ theme }) => theme.editLeague.saveAllBtnBg};
  color: ${({ theme }) => theme.editLeague.buttonWithConfirmationTextColor};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
`;
const SearchIconWrap = styled.div`
  position: absolute;
  top: 12px;
  left: 10px;
`;
const SearchGroup = styled.div`
  display: flex;
  gap: 5px;
  position: relative;
  width: 100%;
`;
