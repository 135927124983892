import React from "react";
import { PulseLoader } from "react-spinners";
import styled from "styled-components";

const LoaderSpinner = () => {
  return (
    <Container>
      <PulseLoader color="#006fff" />
    </Container>
  );
};
export default LoaderSpinner;
const Container = styled.div`
display:flex;
height:100%;
width:100%;
align-items:center;
justify-content:center;
`;