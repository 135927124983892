export const currencyForBrokerName = (list, name) => {
  const found = list.find((element) => element.name === name);
  if (found) {
    return found.currencySymbol;
  }

  return "";
};

export const elementForBrokerName = (list, name) => {
  const found = list.find((element) => element.name === name);
  if (found) {
    return found;
  }

  return { name: "Unknown", currencySymbol: "" };
};
