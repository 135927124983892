import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as YoutubeIcon } from "../../../assets/icons/LeaguesIcons/youtubeIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/LeaguesIcons/editPencilIcon.svg";
import { ReactComponent as YellowCard } from "../../../assets/icons/LeaguesIcons/yellowCard.svg";
import { ReactComponent as RedCard } from "../../../assets/icons/LeaguesIcons/redCard.svg";
import { ReactComponent as NoteIcon } from "../../../assets/icons/LeaguesIcons/noteIcon.svg";
import { ReactComponent as InjuredIcon } from "../../../assets/icons/LeaguesIcons/injuredIcon.svg";
import { ReactComponent as TransferredIcon } from "../../../assets/icons/LeaguesIcons/transferredIconSmall.svg";
import { format_time_to_timezone } from "../../../helper/Generic/dateTimeFormat";
import { selectUser } from "../../../Redux/user/user";
import { useSelector } from "react-redux";
import { parseStatusValue } from "../../../helper/Analyst/SquadManager/parseStatusValue";
import { checkStatus } from "../../../helper/Analyst/SquadManager/checkPlayerStatusForFixture";
import { useSearchParams } from "react-router-dom";
import ConfirmAttendanceComponent from "../Menu/ConfirmAttendanceComponent";
import { checkPositionColor } from "../../../helper/Analyst/SquadManager/checkPOSNCellColor";
import { playerStartingLikelihoodPercentage } from "../../../helper/lineup/LineupHelper";
import { checkFlag } from "../../../helper/Generic/GetFlag";
import { useDarkMode } from "../../../helper/Generic/useDarkMode";

const MatchElement = ({
  data,
  index,
  handleSelectFixture,
  selectedFixture,
  displayPlayerNotes,
  displayTeamNotes,
  orderList,
  highlightedElementIndex,
  handleHightlight,
  checkHighlightedElement,
  handleSetConfirmedAttendance,
  unhighlightAll,
  displayPlayerGameStats,
}) => {

  const fixture = data?.fixture;

  const { theme } = useDarkMode();


  const [squad, setSquad] = useState([]);
  const user = useSelector(selectUser);
  const [homeTeam, setHomeTeam] = useState(null);
  const [awayTeam, setAwayTeam] = useState(null);
  const [thisTeam, setThisteam] = useState();
  const [searchParams] = useSearchParams();
  const [homeTeamScore, setHomeTeamScore] = useState(null);
  const [awayTeamScore, setAwayTeamScore] = useState(null);


  const [lineupSubmitted, setLineupSubmitted] = useState();
  const [lineupConfirmed, setLineupConfirmed] = useState();
  const [pmCompleted, setPmCompleted] = useState();
  const [savedLineup, setSavedLineup] = useState();

  const fixtureStartTime = format_time_to_timezone(
    fixture.start_time,
    user.timezone
  );

  const checkTransferred = (item) => {
    if (item?.player?.transferred && !item.id) {
      return true;
    }
  };

  const FilterElements = ({ status, transferred, children }) => {
    if (status == "n/a" || !status || transferred) {
      return;
    } else {
      return <>{children}</>;
    }
  };

  const sortPlayersFunction = (array, sortArray) => {
    if (!array) {
      return;
    }
    return [...array].sort(
      (a, b) => sortArray.indexOf(a.player_id) - sortArray.indexOf(b.player_id)
    );
  };

  const sortPlayers = () => {
    let list = [];
    list = sortPlayersFunction(data.squad.selections, orderList);
    setSquad(list);
  };


  const populateTeams = () => {
    if (!data) {
      return;
    }

    let thisTeam;
    let homeTeam;
    let awayTeam;

    if (data.fixture.participant1is_home) {
      setHomeTeam(data.fixture.team1);
      setAwayTeam(data.fixture.team2);
      setHomeTeamScore(data.score.home);
      setAwayTeamScore(data.score.away);

      homeTeam = data?.fixture.team1;
      awayTeam = data?.fixture.team2;
    } else {
      setHomeTeam(data.fixture.team2);
      setAwayTeam(data.fixture.team1);
      setHomeTeamScore(data.score.away);
      setAwayTeamScore(data.score.home);

      homeTeam = data?.fixture.team2;
      awayTeam = data?.fixture.team1;
    }

    if (searchParams.get("teamId") == data?.fixture?.team1?.id) {
      setThisteam(data.fixture.team1);
      thisTeam = data.fixture.team1;
    }
    if (searchParams.get("teamId") == data?.fixture?.team2?.id) {
      setThisteam(data.fixture.team2);
      thisTeam = data.fixture.team2;
    }

    if (thisTeam?.id == homeTeam?.id) {
      setLineupSubmitted(data.game.home_lineup_submitted);
      setLineupConfirmed(data.game.home_lineup_confirmed);
      setPmCompleted(data.game.pm_home_completed);
      setSavedLineup(data.game.home_lineup_saved);
    } else {
      setLineupSubmitted(data.game.away_lineup_submitted);
      setLineupConfirmed(data.game.away_lineup_confirmed);
      setPmCompleted(data.game.pm_away_completed);
      setSavedLineup(data.game.away_lineup_saved);
    }
  };

  const checkFixturePhase = () => {
    var valueToReturn = "phase1";

    if (lineupSubmitted || lineupConfirmed) {
      valueToReturn = "phase2";
    }

    if (pmCompleted) {
      valueToReturn = "phase3";
    }

    return valueToReturn;
  };

  const getPosition = (item) => {
    if (item.id != null && item.position == null) {
      return item.player.position_1;
    }

    return item.position;
  };

  const checkMatchScore = () => {

    const wining = {
      color: "rgba(35, 167, 96, 0.8)",
      background: "rgba(235, 252, 243, 1)"
    }
    const loosing = {
      color: "rgba(255, 75, 75, 1)",
      background: "rgba(255, 245, 245, 1)"
    }

    const draw = {
      color: "white",
      background: "#606A90"
    }

    if (thisTeam?.id == homeTeam?.id) {
      if (homeTeamScore > awayTeamScore) {
        return wining
      } else if (homeTeamScore < awayTeamScore) {
        return loosing
      } else if (homeTeamScore == awayTeamScore) {
        return draw
      }
    } else {
      if (homeTeamScore > awayTeamScore) {
        return loosing
      } else if (homeTeamScore < awayTeamScore) {
        return wining
      } else if (homeTeamScore == awayTeamScore) {
        return draw
      }
    }


  }

  useEffect(() => {
    // checkThisTeam();
    populateTeams();
  }, [data]);

  useEffect(() => {
    sortPlayers();
  }, [orderList, data]);

  return (
    <Container>
      <Header
        className={`${checkFixturePhase()}`}
        onClick={() => {
          handleSelectFixture(data);
        }}
        selected={
          data.fixture?.fixture_id === selectedFixture?.fixture.fixture_id
        }
      >
        <FirstLine>
          <FirstLineLeft>
            {checkFixturePhase() !== "phase1" &&
              thisTeam?.name == homeTeam?.name ? (
              <>
                <ConfirmAttendanceComponent
                  homeTeam={homeTeam}
                  awayTeam={awayTeam}
                  fixtureData={data}
                  handleSetConfirmedAttendance={handleSetConfirmedAttendance}
                />

                <ConfirmedAttendance>
                  {data?.game?.confirmed_attendance
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                </ConfirmedAttendance>

              </>
            ) : (
              ""
            )}
          </FirstLineLeft>

          <FirstLineRight>
            {homeTeamScore !== null && awayTeamScore !== null &&
              <Score color={checkMatchScore().color} background={checkMatchScore().background}>
                {homeTeamScore}-{awayTeamScore}
              </Score>
            }

            <MatchNoteButton onClick={() => displayTeamNotes(data)}>
              <EditIcon />
            </MatchNoteButton>
          </FirstLineRight>
        </FirstLine>

        <SecondLine selectedTeam={homeTeam?.name === thisTeam?.name}>
          <p>{homeTeam?.name}</p>
          {thisTeam?.name == homeTeam?.name && data?.squad?.formation && (
            <h3 style={{ fontWeight: "500" }}>{data?.squad?.formation}</h3>
          )}
        </SecondLine>

        <ThirdLine selectedTeam={awayTeam?.name === thisTeam?.name}>
          <p>{awayTeam?.name}</p>
          {thisTeam?.name == awayTeam?.name && data?.squad?.formation && (
            <h3 style={{ fontWeight: "500" }}>{data?.squad?.formation}</h3>
          )}
        </ThirdLine>
        <FourthLine>
          <DateAndTime>
            <p>{fixtureStartTime.format("DD/MM/YYYY - HH:mm")}</p>
          </DateAndTime>
          <PostponedCell>
            {data?.game?.postponed && (
              <MatchPostponed postponed={data?.game?.postponed}>
                <p>PP</p>
              </MatchPostponed>
            )}

            {data?.game?.cancelled && (
              <MatchPostponed cancelled={data?.game?.cancelled}>
                <p>C</p>
              </MatchPostponed>
            )}

            {data.cup ? (
              <>
                {data.cup.logo && (
                  <LogoContainer>
                    <img src={data.cup.logo} alt="logo" />
                  </LogoContainer>
                )}
              </>
            ) : (
              <LogoContainer>
                <img
                  src={checkFlag(data.league?.country.country_code)}
                  alt="logo"
                />
              </LogoContainer>
            )}
          </PostponedCell>
        </FourthLine>
      </Header>

      <PlayersList>
        {squad?.map((item, index) => (
          <Wrapper
            key={index}
            high={checkHighlightedElement(index)}
            onContextMenu={(e) =>
              displayPlayerGameStats(
                e,
                data.fixture.fixture_id,
                thisTeam,
                item,
                data.fixture
              )
            }
          >
            <PlayerStats
              key={index}
              className={
                checkTransferred(item) ? "transferred" : checkStatus(item)
              }
              high={checkHighlightedElement(index)}
              onClick={() => handleHightlight(index)}
              onDoubleClick={() => unhighlightAll()}
            >
              <PlayerTopLine>
                <StatusWrapper>
                  {checkTransferred(item) ? (
                    <Status>
                      <p>Transferred Out</p>
                    </Status>
                  ) : (
                    <Status>
                      {parseStatusValue(
                        item.status,
                        item.fatigue,
                        item.lineup_changed_after_pm_complete,
                        item.ratings_changed_after_pm_complete
                      )}
                    </Status>
                  )}
                </StatusWrapper>

                <FilterElements status={item.status}>
                  <POSNCell className={checkPositionColor(getPosition(item))}>
                    <h1>{getPosition(item)}</h1>
                  </POSNCell>
                </FilterElements>
                <Note has_message={item.has_message}></Note>
                <Cards>
                  {item.game_stat?.yellow_cards > 0 && <YellowCard />}
                  {item.game_stat?.red_cards > 0 && <RedCard />}
                </Cards>
                <Injured>{item.injured && <InjuredIcon />}</Injured>
              </PlayerTopLine>

              <PlayerBottomLine>
                <Rating>
                  <FilterElements status={item.status}>
                    <h2>
                      {item.importance} {item.attack_rating}/
                      {item.defence_rating}
                    </h2>
                  </FilterElements>
                </Rating>
                <StChancePercent>
                  <FilterElements status={item.status}>
                    <h2>{playerStartingLikelihoodPercentage(item)}%</h2>
                  </FilterElements>
                </StChancePercent>

                <Note has_message={item.has_message}>
                  {data.squad.id && item.player && item.id && (
                    <NoteIcon onClick={() => displayPlayerNotes(data, item)} />
                  )}
                </Note>

                <Minute>
                  <FilterElements status={item.status}>
                    {item.game_stat?.minutes_played != null && (
                      <h2>{item.game_stat?.minutes_played}'</h2>
                    )}
                  </FilterElements>
                </Minute>

                <Num>
                  <FilterElements status={item.status}>
                    <h2>
                      {item.match_rating != null ? item.match_rating : ""}
                    </h2>
                  </FilterElements>
                </Num>
              </PlayerBottomLine>
            </PlayerStats>
          </Wrapper>
        ))}
      </PlayersList>
    </Container>
  );
};

export default MatchElement;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 163px;
  max-width: 360px;

  .xi {
    background: #fff8f1 !important;
    border-left: 5px solid #ff8d21 !important;
    p {
      color: #ff8d21 !important;
    }
  }

  .available {
    background: ${({ theme }) => theme.statusColor.available.bg};
    border-left: 5px solid ${({ theme }) => theme.statusColor.available.border};
    p {
      color: ${({ theme }) => theme.statusColor.available.p};
    }
    h2 {
      color: ${({ theme }) => theme.statusColor.available.h2};
    }
  }

  .ag-highlight {
    p {
      background: rgba(71, 183, 123, 0.17);
    }
  }
  .ao-highlight {
    p {
      background: rgba(255, 151, 52, 0.17);
    }
  }
  .unav-highlight {
    p {
      background: rgba(152, 104, 254, 0.17);
    }
  }
  .rested-highlight {
    p {
      background: rgba(67, 147, 252, 0.17);
    }
  }
  .bench-highlight {
    p {
      background: rgba(148, 148, 148, 0.17);
    }
  }
  .injured-highlight {
    p {
      background: rgba(236, 72, 72, 0.17);
    }
  }

  .available-green {
    background: ${({ theme }) => theme.statusColor.availableGreen.bg};
    border-left: 5px solid
      ${({ theme }) => theme.statusColor.availableGreen.border};
    p {
      color: ${({ theme }) => theme.statusColor.availableGreen.p};
    }
    h2 {
      color: ${({ theme }) => theme.statusColor.availableGreen.h2};
    }
  }
  .available-orange {
    background: ${({ theme }) => theme.statusColor.availableOrange.bg};
    border-left: 5px solid
      ${({ theme }) => theme.statusColor.availableOrange.border};
    p {
      color: ${({ theme }) => theme.statusColor.availableOrange.p};
    }
    h2 {
      color: ${({ theme }) => theme.statusColor.availableOrange.h2};
    }
  }

  .fatigue1 {
    label {
      color: ${({ theme }) => theme.statusColor.fatigue.f1};
    }
  }

  .fatigue2 {
    label {
      color: ${({ theme }) => theme.statusColor.fatigue.f2};
    }
  }

  .fatigue3 {
    label {
      color: ${({ theme }) => theme.statusColor.fatigue.f3};
    }
  }

  .injured {
    background: ${({ theme }) => theme.statusColor.injured.bg};
    border-left: 5px solid ${({ theme }) => theme.statusColor.injured.border};
    p {
      color: ${({ theme }) => theme.statusColor.injured.p};
    }
    h2 {
      color: ${({ theme }) => theme.statusColor.injured.h2};
    }
  }

  .rested {
    background: ${({ theme }) => theme.statusColor.rested.bg};
    border-left: 5px solid ${({ theme }) => theme.statusColor.rested.border};
    p {
      color: ${({ theme }) => theme.statusColor.rested.p};
    }
    h2 {
      color: ${({ theme }) => theme.statusColor.rested.h2};
    }
  }

  .unavailable {
    background: ${({ theme }) => theme.statusColor.unavailable.bg};
    border-left: 5px solid
      ${({ theme }) => theme.statusColor.unavailable.border};
    p {
      color: ${({ theme }) => theme.statusColor.unavailable.p};
    }
    h2 {
      color: ${({ theme }) => theme.statusColor.unavailable.h2};
    }
  }

  .bench {
    background: ${({ theme }) => theme.statusColor.bench.bg};
    border-left: 5px solid ${({ theme }) => theme.statusColor.bench.border};
    p {
      color: ${({ theme }) => theme.statusColor.bench.p};
    }
    h2 {
      color: ${({ theme }) => theme.statusColor.bench.h2};
    }
  }
  .transferred {
    background: ${({ theme }) => theme.statusColor.transferred.bg};
    border-left: 5px solid
      ${({ theme }) => theme.statusColor.transferred.border};
    p {
      color: ${({ theme }) => theme.statusColor.transferred.p};
    }
    h2 {
      color: ${({ theme }) => theme.statusColor.transferred.h2};
    }
  }

  .na {
    p {
      color: #334154;
    }
  }

  .phase1 {
    background: #e1e9ff;
    p {
      color: var(--darkBlue);
    }
  }
  .phase2 {
    background: #afbfe8;
    p {
      color: var(--darkBlue);
    }
  }

  .phase3 {
    background: ${({ theme }) => theme.squadManager.matchElementP2Header};
    p {
      color: white;
    }
    h3 {
      color: white;
    }
  }

  .green {
    border-color: #cbf2dc;
    background: #ebfcf3;

    h1 {
      color: #23a760;
    }
    p {
      color: #23a760;
    }
  }
  .blue {
    border-color: #d5dff7;
    background: #e4ecff;
    h1 {
      color: #4393fc;
    }
  }
  .orange {
    border-color: #f6dabd;
    background: #fff3e7;
    h1 {
      color: #ff8d21;
    }
  }
  .red {
    border-color: #f9dddd;
    background: #fff5f5;
    h1 {
      color: #ec4848;
    }
    p {
      color: #ec4848;
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  background: #464f65;
  padding: 5px;
  width: 100%;
  min-height: 70px;
  height: 100%;
  max-height: ${(props) => (props.selected == true ? "72px" : "70px")};
  margin-top: ${(props) => (props.selected == true ? "-2px" : "")};
  border-top: ${(props) => (props.selected == true ? "2px solid #006FFF" : "")};
  margin-bottom: 3px;
  position: relative;
`;
const PlayersList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
`;
const DateAndTime = styled.div`
  display: flex;
  align-items: center;
  row-gap: 3px;
  height: 14px;
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.squadManager.textColor};
  }
`;

const Wrapper = styled.div`
  display: flex;
  padding: ${(props) => (props.high ? "3px 7px 3px 3px" : "")};
  margin: ${(props) => (props.high ? "-3px -2px -3px -3px" : "")};
  background: ${(props) =>
    props.high ? ({ theme }) => theme.squadManager.highlightColor : "white"};
`;
const PlayerStats = styled.div`
  position: relative;
  padding: 3px 5px;
  border-left: 5px solid #334154;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  min-height: 32px;
  width: 100%;
  padding: ${(props) => (props.high ? "3px 0 3px 5px" : "")};
`;

const SecondLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 3px;
  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-style: normal;
    font-weight: ${(props) => (props.selectedTeam ? "700" : "400")};
    font-size: 10px;
    color: ${({ theme }) => theme.squadManager.textColor};
  }

  h3 {
    min-width: fit-content;
    font-style: normal;
    font-weight: ${(props) => (props.selectedTeam ? "700" : "400")};
    font-size: 10px;
    color: ${({ theme }) => theme.squadManager.textColor};
  }

  h3 {
    min-width: fit-content;
    font-style: normal;
    font-weight: ${(props) => (props.selectedTeam ? "700" : "400")};
    font-size: 10px;
    color: ${({ theme }) => theme.squadManager.textColor};
  }
`;
const ThirdLine = styled(SecondLine)`
  p {
    font-style: normal;
    font-weight: ${(props) => (props.selectedTeam ? "700" : "400")};
    font-size: 10px;
    color: ${({ theme }) => theme.squadManager.textColor};
  }
`;
const FourthLine = styled.div`
  display: flex;
  aling-items: center;
  gap: 10px;
`;
const PlayerTopLine = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: 100%;
  @media (max-width: 1750px) {
    column-gap: 3px;
  }
`;
const Status = styled.div`
  display: flex;
  align-items: center;
  p {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    color: green;
    padding: 0 1px;
  }
`;
const Cards = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  min-width: 17px;
`;
const Note = styled.div`
  margin-left: auto;
  min-width: 10px;
  svg {
    fill: ${(props) => (props.has_message ? "#0E61CE" : "")};
  }
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Transferred = styled.div`
  svg {
    height: 4px;
    color: ${({ theme }) => theme.squadManager.transferredIcon};
  }
  display: flex;
  align-items: center;
`;
const PlayerBottomLine = styled.div`
  display: flex;
  column-gap: 5px;
  @media (max-width: 1750px) {
    column-gap: 3px;
  }
`;
const Rating = styled.div`
  display: flex;
  column-gap: 2px;
  min-width: 66px;
  padding-left: 1px;
  p {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 9px;
  }

  h2 {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 9px;
  }
  svg {
    height: 8px;
  }
`;
const Minute = styled.div`
  margin-left: auto;
  min-width: 17px;
  display: flex;
  justify-content: center;
  p {
    font-weight: 500;
    font-size: 9px;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    color: green;
  }
`;
const Num = styled(Minute)``;
const Injured = styled.div`
  display: flex;
  justify-content: center;
  margin-left: auto;
  min-width: 12px;
`;

const StatusWrapper = styled.div`
  display: flex;
  min-width: 66px;
`;
const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
`;
const FirstLineLeft = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  svg {
    height: 13px;
    width: 13px;
  }
 
`;
const FirstLineRight = styled(FirstLineLeft)``;
const MatchNoteButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: var(--primaryBlue);
  svg {
    height: 11px;
  }
`;
const YtButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;
const Position = styled.div``;
const POSNCell = styled.div`
  display: flex;
  align-items: center;
  min-width: 28px;
  min-height: 11px;
  border: 1px solid #e1e9ff;
  background: white;
  display: flex;
  justify-content: center;
  padding: 0 3px;
  border-radius: 2px;
  h1 {
    font-weight: 500;
    font-size: 8px;
    font-weight: 700;
  }
`;
const StChancePercent = styled.div`
  display: flex;
  column-gap: 2px;
  min-width: 28px;
  justify-content: center;
  p {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 9px;
  }

  h2 {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 9px;
  }
`;
const PostponedCell = styled.div`
  display: flex;
  column-gap: 3px;
`;
const MatchPostponed = styled.div`
  background: #ffffff;
  border: 1px solid #dcd5ed;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 14px;
  width: 14px;
  p {
    color: ${(props) =>
    props.postponed || props.cancelled
      ? "#5A37A3"
      : ({ theme }) => theme.matchPreview.textColor} !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 11px;
    text-align: center;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  right: 5px;
  bottom: 6px;
  max-height: 16px;
  max-width: 30px;
  overflow: hidden;
  img {
    max-height: 100%;
  }
`;

const Score = styled.p`

font-family: "Roboto";
font-style: normal;
font-weight: 500;
font-size: 8px;
line-height: 11px;
text-align: center;
background: ${props => props.background};
padding: 0 5px;
border-radius: 2px;
 color: ${props => props.color} !important;
`
const ConfirmedAttendance = styled.p`
  font-weight: 600;
  font-size: 8px;
  color: ${({ theme }) => theme.squadManager.confirmedAttendanceColor} !important;
  line-height: 13px !important;
 background: ${({ theme }) => theme.graphLeagueSelectionMenuItemBg};
  padding: 0 5px;
  border-radius: 2px;

`