import {
  percentageChange,
  formatNumberForValue,
} from "../helper/ModelDataHelper";

import { formatNumber } from "../helper/displayHelpers";

// Gets the first value from prices by name
export const getSingleValuePriceFromPrices = (prices, name, value = null) => {
  let returnValue = prices.find((p) => p.name == name);

  if (value != undefined) {
    returnValue = prices.find((p) => p.name == name && p.value == value);
  }

  if (returnValue !== undefined) {
    return returnValue.price;
  }

  return null;
};

// Filters all prices based on the name and provided bookmaker_ids
export const getPricesFor = (prices, name, bookmaker_ids = null) => {
  if (prices == undefined || prices.length == 0) {
    return [];
  }

  if (bookmaker_ids != undefined && bookmaker_ids.length > 0) {
    let values = prices.filter(
      (f) => f.name == name && bookmaker_ids.includes(f.bookmaker_id)
    );
    return values;
  }

  let values = prices.filter((f) => f.name == name);
  return values;
};

// Combines prices for the same name and value into an average one
export const combinePrices = (prices, bookmaker_ids = null) => {
  let filteredPrices = prices;

  if (bookmaker_ids != undefined && bookmaker_ids.length > 0) {
    filteredPrices = prices.filter((f) =>
      bookmaker_ids.includes(f.bookmaker_id)
    );
  }

  let names = filteredPrices.map((p) => p.name);
  names = [...new Set(names)];

  let returnPrices = [];
  names.forEach((name) => {
    // Prices for current name
    const pricesForCurrentName = filteredPrices.filter((f) => f.name == name);
    // All values for current price
    let valuesForCurrentName = pricesForCurrentName.map((p) => p.value);
    valuesForCurrentName = [...new Set(valuesForCurrentName)];

    valuesForCurrentName.forEach((value) => {
      const pricesForNameAndValue = pricesForCurrentName.filter(
        (f) => f.value == value
      );

      const newPrice = buildNewPrice(pricesForNameAndValue, name, value);
      if (newPrice != null) {
        returnPrices.push(newPrice);
      }
    });
  });

  return returnPrices;
};

// Calculates the min HA value
export const calculateMinHADiffValue = (prices, bookmaker_ids = null) => {
  const initial_h = getPricesFor(prices, "H");

  if (bookmaker_ids && bookmaker_ids.length > 0) {
    let filteredPrices = initial_h.filter((f) =>
      bookmaker_ids.includes(f.bookmaker_id)
    );
  }

  const combinedPrices = combinePrices(prices, bookmaker_ids);

  const h_values = getPricesFor(combinedPrices, "H");
  const a_values = getPricesFor(combinedPrices, "A");

  return minDiffValueBetweenPrices(h_values, a_values);
};

// Calculates the min OU value
export const calculateMinOUDiffValue = (prices, bookmaker_ids = null) => {
  const combinedPrices = combinePrices(prices, bookmaker_ids);

  const o_values = getPricesFor(combinedPrices, "O");
  const u_values = getPricesFor(combinedPrices, "U");

  return minDiffValueBetweenPrices(o_values, u_values);
};

// Calculates the models min HA value (if market is different use the market)
export const calculateMinModelHAValue = (
  fixturePrice,
  bookmaker_ids = null
) => {
  if (fixturePrice == undefined || fixturePrice.model_price == undefined) {
    return null;
  }

  const modelPrice = fixturePrice.model_price;
  let modelMin = calculateMinHADiffValue(modelPrice.prices);

  if (modelMin == null) {
    return null;
  }

  if (fixturePrice.market_price == null) {
    return modelMin;
  }

  //   If there are market prices, calculate the new min
  const marketPrice = fixturePrice.market_price;
  let marketMin = calculateMinHADiffValue(marketPrice.prices, bookmaker_ids);

  if (marketMin != null) {
    return marketMin;
  }

  return modelMin;
};

// Calculates the models min OU value (if market is different use the market)
export const calculateMinModelOUValue = (
  fixturePrice,
  bookmaker_ids = null
) => {
  if (fixturePrice == undefined || fixturePrice.model_price == undefined) {
    return null;
  }

  const modelPrice = fixturePrice.model_price;
  let modelMin = calculateMinOUDiffValue(modelPrice.prices);

  if (!modelMin) {
    return null;
  }

  if (!fixturePrice.market_price) {
    return modelMin;
  }

  //   If there are market prices, calculate the new min
  const marketPrice = fixturePrice.market_price;
  let marketMin = calculateMinOUDiffValue(marketPrice.prices, bookmaker_ids);

  if (marketMin != undefined) {
    return marketMin;
  }

  return modelMin;
};

// Calculates the market min HA value (if market is different use the market)
export const calculateMinMarketHAValue = (
  fixturePrice,
  bookmaker_ids = null
) => {
  if (!fixturePrice || !fixturePrice.market_price) {
    return null;
  }

  const marketPrice = fixturePrice.market_price;
  let marketMin = calculateMinHADiffValue(marketPrice.prices, bookmaker_ids);

  return marketMin;
};

// Calculates the market min OU value (if market is different use the market)
export const calculateMinMarketOUValue = (
  fixturePrice,
  bookmaker_ids = null
) => {
  if (!fixturePrice || !fixturePrice.market_price) {
    return null;
  }

  //   If there are market prices, calculate the new min
  const marketPrice = fixturePrice.market_price;
  let marketMin = calculateMinOUDiffValue(marketPrice.prices, bookmaker_ids);

  return marketMin;
};

// calculates ha hd aa ad values
export const calculateHAHDAAADValues = (fixturePricing) => {
  let ha = "";
  let hd = "";
  let aa = "";
  let ad = "";

  if (fixturePricing && fixturePricing.model_price) {
    let model_price = fixturePricing.model_price;

    ha = formatNumber(model_price.ha);
    hd = formatNumber(model_price.hd);
    aa = formatNumber(model_price.aa);
    ad = formatNumber(model_price.ad);
  }

  return { ha: ha, hd: hd, aa: aa, ad: ad };
};

// calculate 1 x 2 values
export const calculateModel1X2Values = (fixturePricing) => {
  let model_1 = "";
  let model_x = "";
  let model_2 = "";

  if (fixturePricing && fixturePricing.model_price) {
    let model_price = fixturePricing.model_price;

    model_1 = formatNumber(
      getSingleValuePriceFromPrices(model_price.prices, "1")
    );

    model_x = formatNumber(
      getSingleValuePriceFromPrices(model_price.prices, "X")
    );

    model_2 = formatNumber(
      getSingleValuePriceFromPrices(model_price.prices, "2")
    );
  }

  return { model_1: model_1, model_2: model_2, model_x: model_x };
};

// calculate 1 x 2 values
export const calculateMarket1X2Values = (fixturePricing, bookmaker_ids) => {
  let market_1 = "";
  let market_x = "";
  let market_2 = "";

  if (fixturePricing && fixturePricing.market_price) {
    let market_price = fixturePricing.market_price;

    let combined_prices = combinePrices(market_price.prices, bookmaker_ids);

    market_1 = formatNumber(
      getSingleValuePriceFromPrices(combined_prices, "1")
    );

    market_x = formatNumber(
      getSingleValuePriceFromPrices(combined_prices, "X")
    );

    market_2 = formatNumber(
      getSingleValuePriceFromPrices(combined_prices, "2")
    );
  }

  return { market_1: market_1, market_x: market_x, market_2: market_2 };
};

// calculate sup and tgx
export const calculateModelSupTgx = (fixturePricing) => {
  let model_sup = "";
  let model_tgx = "";

  if (fixturePricing && fixturePricing.model_price) {
    let model_price = fixturePricing.model_price;

    model_sup = formatNumber(model_price.sup);
    model_tgx = formatNumber(model_price.tgx);
  }

  return { model_sup: model_sup, model_tgx: model_tgx };
};

export const calculateMarketSupTgx = (fixturePricing) => {
  let market_sup = "";
  let market_tgx = "";

  if (fixturePricing && fixturePricing.market_price) {
    let model_price = fixturePricing.market_price;

    market_sup = formatNumber(model_price.sup);
    market_tgx = formatNumber(model_price.tgx);
  }

  return { market_sup: market_sup, market_tgx: market_tgx };
};

export const calculateModelHAMainLine = (fixturePricing, bookmaker_ids) => {
  // Main line ha ou values
  const modelHAValues = calculateHAModelValues(fixturePricing, bookmaker_ids);
  let model_ha_value = formatNumberForValue(
    formatNumber(modelHAValues.model_ha_value)
  );
  let model_h = formatNumber(modelHAValues.model_h);
  let model_a = formatNumber(modelHAValues.model_a);

  return { model_ha_value: model_ha_value, model_h: model_h, model_a: model_a };
};

export const calculateModelOUMainLine = (fixturePricing, bookmaker_ids) => {
  const modelOUValues = calculateModelOUValues(fixturePricing, bookmaker_ids);

  let model_ou_value = formatNumberForValue(
    formatNumber(modelOUValues.model_ou_value)
  );
  let model_o = formatNumber(modelOUValues.model_o);
  let model_u = formatNumber(modelOUValues.model_u);

  return { model_ou_value: model_ou_value, model_o: model_o, model_u: model_u };
};

export const calculateMarketHAMainLine = (fixturePricing, bookmaker_ids) => {
  // Main line ha ou values
  const marketHAValues = calculateHAMarketValues(fixturePricing, bookmaker_ids);

  let market_ha_value = formatNumberForValue(
    formatNumber(marketHAValues.market_ha_value)
  );
  let market_h = formatNumber(marketHAValues.market_h);
  let market_a = formatNumber(marketHAValues.market_a);

  return {
    market_ha_value: market_ha_value,
    market_h: market_h,
    market_a: market_a,
  };
};

export const calculateMarketOUMainLine = (fixturePricing, bookmaker_ids) => {
  // Main line ha ou values
  const marketOUValues = calculateMarketOUValues(fixturePricing, bookmaker_ids);

  let market_ou_value = formatNumberForValue(
    formatNumber(marketOUValues.market_ou_value)
  );
  let market_o = formatNumber(marketOUValues.market_o);
  let market_u = formatNumber(marketOUValues.market_u);

  return {
    market_ou_value: market_ou_value,
    market_o: market_o,
    market_u: market_u,
  };
};

// Difference in prices
export const calculatePriceDiff = (fixturePricing, bookmaker_ids) => {
  let diff_1 = "";
  let diff_x = "";
  let diff_2 = "";

  let market_1 = "";
  let market_x = "";
  let market_2 = "";

  if (fixturePricing && fixturePricing.market_price) {
    let market_price = fixturePricing.market_price;

    let combined_prices = combinePrices(market_price.prices, bookmaker_ids);

    market_1 = getSingleValuePriceFromPrices(combined_prices, "1");
    market_x = getSingleValuePriceFromPrices(combined_prices, "X");
    market_2 = getSingleValuePriceFromPrices(combined_prices, "2");
  }

  let model_1 = "";
  let model_x = "";
  let model_2 = "";

  if (fixturePricing && fixturePricing.model_price) {
    let model_price = fixturePricing.model_price;

    model_1 = getSingleValuePriceFromPrices(model_price.prices, "1");
    model_x = getSingleValuePriceFromPrices(model_price.prices, "X");
    model_2 = getSingleValuePriceFromPrices(model_price.prices, "2");
  }

  const p1 = percentageChange(model_1, market_1);
  if (p1) {
    diff_1 = p1;
  }

  const px = percentageChange(model_x, market_x);
  if (px) {
    diff_x = px;
  }

  const p2 = percentageChange(model_2, market_2);
  if (p2) {
    diff_2 = p2;
  }

  return { diff_1: diff_1, diff_x: diff_x, diff_2: diff_2 };
};

// HA diff
export const calculateHADiff = (fixturePricing, bookmaker_ids) => {
  let diff_h = "";
  let diff_a = "";

  // Main line ha ou values
  const haModelValues = calculateHAModelValues(fixturePricing, bookmaker_ids);
  let model_h = haModelValues.model_h;
  let model_a = haModelValues.model_a;

  // Main line ha ou values
  const minMarketHAVal = calculateHAMarketValues(fixturePricing, bookmaker_ids);
  let market_h = minMarketHAVal.market_h;
  let market_a = minMarketHAVal.market_a;

  const ph = percentageChange(model_h, market_h);
  if (ph) {
    diff_h = ph;
  }

  const pa = percentageChange(model_a, market_a);
  if (pa) {
    diff_a = pa;
  }

  return { diff_h: diff_h, diff_a: diff_a };
};

// OU diff
export const calculateOUDiff = (fixturePricing, bookmaker_ids) => {
  let diff_o = "";
  let diff_u = "";

  const modelOUValues = calculateModelOUValues(fixturePricing, bookmaker_ids);
  let model_o = modelOUValues.model_o;
  let model_u = modelOUValues.model_u;

  const marketOUValues = calculateMarketOUValues(fixturePricing, bookmaker_ids);
  let market_o = marketOUValues.market_o;
  let market_u = marketOUValues.market_u;

  const po = percentageChange(model_o, market_o);
  if (po) {
    diff_o = po;
  }

  const pu = percentageChange(model_u, market_u);
  if (pu) {
    diff_u = pu;
  }

  return { diff_o: diff_o, diff_u: diff_u };
};

// Compute sub lines
export const computeSubLines = (fixturePrice, bookmaker_ids) => {
  if (!fixturePrice) {
    return [];
  }

  // Min ha
  const minModelHA = calculateMinModelHAValue(fixturePrice, bookmaker_ids);
  // Min ou
  const minModelOU = calculateMinModelOUValue(fixturePrice, bookmaker_ids);

  let model_h = [];
  let model_a = [];
  if (fixturePrice.model_price && minModelHA) {
    let model_price = fixturePrice.model_price;

    const all_h_values = getPricesFor(model_price.prices, "H");
    model_h = getPricesForMainLineValue(all_h_values, minModelHA);

    const all_a_values = getPricesFor(model_price.prices, "A");
    model_a = getPricesForMainLineValue(all_a_values, minModelHA);
  }

  let model_o = [];
  let model_u = [];
  if (fixturePrice.model_price && minModelOU) {
    let model_price = fixturePrice.model_price;

    const all_o_values = getPricesFor(model_price.prices, "O");
    model_o = getPricesForMainLineValue(all_o_values, minModelOU);

    const all_u_values = getPricesFor(model_price.prices, "U");
    model_u = getPricesForMainLineValue(all_u_values, minModelOU);

  }

  let market_h = [];
  let market_a = [];
  let market_o = [];
  let market_u = [];

  if (fixturePrice.market_price) {
    let market_price = fixturePrice.market_price;

    const combined_prices = combinePrices(market_price.prices, bookmaker_ids);
    const all_h_values = getPricesFor(combined_prices, "H");

    market_h = getPricesForMainLineValue(all_h_values, minModelHA);


    const all_a_values = getPricesFor(combined_prices, "A");
    market_a = getPricesForMainLineValue(all_a_values, minModelHA);

    const all_o_values = getPricesFor(combined_prices, "O");
    market_o = getPricesForMainLineValue(all_o_values, minModelOU);

    const all_u_values = getPricesFor(combined_prices, "U");
    market_u = getPricesForMainLineValue(all_u_values, minModelOU);
  }

  const maxDepthArray = [
    model_a.length,
    model_h.length,
    model_o.length,
    model_u.length,
    market_a.length,
    market_h.length,
    market_o.length,
    market_u.length,
  ];
  const maxDepth = Math.max(...maxDepthArray);

  const returnValues = [];
  for (let i = 0; i < maxDepth; i++) {
    const line = buildSublineAtIndex(
      model_h,
      model_a,
      model_o,
      model_u,
      market_h,
      market_a,
      market_o,
      market_u,
      i
    );
    returnValues.push(line);
  }

  return returnValues;
};

// Private methods

const buildSublineAtIndex = (
  model_h,
  model_a,
  model_o,
  model_u,
  market_h,
  market_a,
  market_o,
  market_u,
  index
) => {
  let mod_ha_value = model_h.length > index ? model_h[index].value : "";
  let mod_h = model_h.length > index ? model_h[index].price : "";
  let mod_a = model_a.length > index ? model_a[index].price : "";

  let mod_ou_value = model_o.length > index ? model_o[index].value : "";
  let mod_o = model_o.length > index ? model_o[index].price : "";
  let mod_u = model_u.length > index ? model_u[index].price : "";

  let mar_ha_value = market_h.length > index ? market_h[index].value : "";
  let mar_h = market_h.length > index ? market_h[index].price : "";
  let mar_a = market_a.length > index ? market_a[index].price : "";

  let mar_ou_value = market_o.length > index ? market_o[index].value : "";
  let mar_o = market_o.length > index ? market_o[index].price : "";
  let mar_u = market_u.length > index ? market_u[index].price : "";

  return {
    model_ha_value: mod_ha_value,
    model_h: mod_h,
    model_a: mod_a,
    model_ou_value: mod_ou_value,
    model_o: mod_o,
    model_u: mod_u,
    market_ha_value: mar_ha_value,
    market_h: mar_h,
    market_a: mar_a,
    market_ou_value: mar_ou_value,
    market_o: mar_o,
    market_u: mar_u,
  };
};

// Filters prices for the main line

const getPricesForMainLineValue = (prices, value) => {
  if (!value || !prices) {
    return [];
  }

  const pricesSortedByValue = prices.sort((a, b) =>
    a.value > b.value ? 1 : -1
  );

  const values = pricesSortedByValue.map((p) => p.value);
  const foundIndex = values.indexOf(value);

  if (foundIndex) {
    let startIndex = 0;
    let endIndex = values.length;

    if (foundIndex > 2) {
      startIndex = foundIndex - 3;
    }

    if (foundIndex + 4 < values.length) {
      endIndex = foundIndex + 4;
    }

    let length = endIndex - startIndex;

    return pricesSortedByValue.splice(startIndex, length);
  }

  return [];
};

// Builds a new price based on the average of the provided prices
const buildNewPrice = (prices, name, value) => {
  if (prices.length == 0) {
    return null;
  }

  const pricesValues = prices.map((p) => p.price);
  const sum = pricesValues.reduce((p, c) => p + c, 0);
  const average = sum / prices.length;

  return { name: name, value: value, price: average, bookmaker_id: null };
};

// Calculates the min difference between two arrays of prices and returns the min price value
const minDiffValueBetweenPrices = (prices1, prices2) => {
  const merged = prices1.concat(prices2);
  let values = merged.map((p) => p.value);
  values = [...new Set(values)];

  let minDiff = Number.MAX_VALUE;
  let minValue = null;

  values.forEach((value) => {
    const v1 = prices1.find((p) => p.value == value);
    const v2 = prices2.find((p) => p.value == value);

    if (v1 != undefined && v2 != undefined) {
      const diff = Math.abs(v1.price - v2.price);

      if (diff < minDiff) {
        minDiff = diff;
        minValue = value;
      }
    }
  });

  return minValue;
};

const calculateHAModelValues = (fixturePricing, bookmaker_ids) => {
  let model_h = "";
  let model_a = "";
  let model_ha_value = "";

  // Main line ha ou values
  const minModelHAVal = calculateMinModelHAValue(fixturePricing, bookmaker_ids);
  if (minModelHAVal != null && fixturePricing && fixturePricing.model_price) {
    model_ha_value = minModelHAVal;
    model_h = getSingleValuePriceFromPrices(
      fixturePricing.model_price.prices,
      "H",
      minModelHAVal
    );
    model_a = getSingleValuePriceFromPrices(
      fixturePricing.model_price.prices,
      "A",
      minModelHAVal
    );
  }

  return { model_h: model_h, model_a: model_a, model_ha_value: model_ha_value };
};

const calculateHAMarketValues = (fixturePricing, bookmaker_ids) => {
  let market_h = "";
  let market_a = "";
  let market_ha_value = "";

  // Main line ha ou values
  const minMarketHAVal = calculateMinMarketHAValue(
    fixturePricing,
    bookmaker_ids
  );

  if (minMarketHAVal != null && fixturePricing && fixturePricing.market_price) {
    market_ha_value = minMarketHAVal;
    let market_price = fixturePricing.market_price;
    let combinedPrices = combinePrices(market_price.prices, bookmaker_ids);
    market_h = getSingleValuePriceFromPrices(
      combinedPrices,
      "H",
      minMarketHAVal
    );
    market_a = getSingleValuePriceFromPrices(
      combinedPrices,
      "A",
      minMarketHAVal
    );
  }

  return {
    market_h: market_h,
    market_a: market_a,
    market_ha_value: market_ha_value,
  };
};

const calculateModelOUValues = (fixturePricing, bookmaker_ids) => {
  let model_ou_value = "";
  let model_o = "";
  let model_u = "";

  const minModelOUVal = calculateMinModelOUValue(fixturePricing, bookmaker_ids);

  if (minModelOUVal != null && fixturePricing && fixturePricing.model_price) {
    model_ou_value = minModelOUVal;
    model_o = getSingleValuePriceFromPrices(
      fixturePricing.model_price.prices,
      "O",
      minModelOUVal
    );
    model_u = getSingleValuePriceFromPrices(
      fixturePricing.model_price.prices,
      "U",
      minModelOUVal
    );
  }

  return { model_ou_value: model_ou_value, model_o: model_o, model_u: model_u };
};

const calculateMarketOUValues = (fixturePricing, bookmaker_ids) => {
  let market_ou_value = "";
  let market_o = "";
  let market_u = "";

  // Main line ha ou values
  const minMarketOUVal = calculateMinMarketOUValue(
    fixturePricing,
    bookmaker_ids
  );

  if (minMarketOUVal != null && fixturePricing && fixturePricing.market_price) {
    let market_price = fixturePricing.market_price;
    let combinedPrices = combinePrices(market_price.prices, bookmaker_ids);

    market_ou_value = minMarketOUVal;
    market_o = getSingleValuePriceFromPrices(
      combinedPrices,
      "O",
      minMarketOUVal
    );
    market_u = getSingleValuePriceFromPrices(
      combinedPrices,
      "U",
      minMarketOUVal
    );
  }

  return {
    market_ou_value: market_ou_value,
    market_o: market_o,
    market_u: market_u,
  };
};

const computeNormalisedValue = (mainPrice, otherPrices) => {
  let den = 0;
  otherPrices.forEach((price) => {
    if (price != null && price != "") {
      den += 1 / price;
    }
  });

  const newPrice = otherPrices[0] * den;
  let percentage = percentageChange(mainPrice, newPrice);

  return percentage;
};
