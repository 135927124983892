import { POST } from "../../APiHelper/API";

export const downloadLeaguesCsv = (
  season_ids,
  cup_ids,
  country_ids,
  league_ids,
  term,
  onSuccess,
  onError
) => {
  const data = {
    season_ids: season_ids,
    cup_ids: cup_ids,
    country_ids: country_ids,
    league_ids: league_ids,
    term: term,
  };

  POST("/api/v1/leagues/csv", data, onSuccess, onError);
};
