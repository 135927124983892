import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";
import { ReactComponent as SearchSvg } from "../../../assets/icons/PricingMenuIcons/search.svg";
const CountryMenuSingleOption = ({
  label,
  countriesList,
  country,
  setCountry,
  countryId,
  setCountryId,
  setInputvalues,
}) => {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState("");

  const handleClick = (item) => {
    var updatedList = [country];
    var updatedListIds = [countryId];

    updatedList = [item];
    updatedListIds = [item.country_id];

    setInputvalues((prev) => ({
      ...prev,
      inputSearch: "",
    }));

    setCountry(updatedList);
    setCountryId(updatedListIds);

    handleMenuOpen();
  };

  const handleSearchInput = (e) => {
    setInputSearch(e.target.value);
  };

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  const filterSearch = (items, filter) => {
    if (filter === "") {
      return items;
    } else {
      return items.filter((item) =>
        item.name.toLowerCase().includes(filter.toLowerCase())
      );
    }
  };

  const checkFlag = (country) => {
    let country_code = country.country_code;

    if (country.name == "North America") {
      country_code = "USA";
    }

    if (country !== null && country_code !== null) {
      return `https://flagcdn.com/${country_code.toLowerCase()}.svg`;
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
        setInputSearch("");
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref}>
      <DropDownMenu onClick={() => handleMenuOpen()} isMenuOpen={isMenuOpen}>
        <p>{label}</p>
        {isMenuOpen ? <UpSvg /> : <DownSvg />}
      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>
          <DropDownMenuItem>
            <SearchGroup>
              <SearchSvg />
              <input
                type={"text"}
                value={inputSearch}
                onChange={handleSearchInput}
                placeholder="Search"
              ></input>
            </SearchGroup>
          </DropDownMenuItem>

          {inputSearch !== ""
            ? filterSearch(countriesList, inputSearch).map((item, index) => (
                <DropDownMenuItem key={index} onClick={() => handleClick(item)}>
                  <div>
                    <input
                      readOnly
                      // onChange={(e) => handleCheck(e, item)}
                      className="check"
                      type="radio"
                      checked={country.includes(item)}
                      value={item}
                    ></input>
                    <img
                      src={item !== null ? checkFlag(item) : ""}
                      alt=""
                    ></img>
                    <p>{item.name}</p>
                  </div>
                </DropDownMenuItem>
              ))
            : countriesList.map((item, index) => (
                <DropDownMenuItem key={index} onClick={() => handleClick(item)}>
                  <div className={item === country ? "active" : "nonActive"}>
                    <input
                      key={index}
                      readOnly
                      // onChange={(e) => handleCheck(e, item)}
                      className="check"
                      type="radio"
                      checked={country.includes(item)}
                      value={item}
                    ></input>
                    <img
                      src={item !== null ? checkFlag(item) : ""}
                      alt=""
                    ></img>
                    <p> {item.name}</p>
                  </div>
                </DropDownMenuItem>
              ))}
        </DropDownList>
      )}
    </Container>
  );
};

export default CountryMenuSingleOption;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  position: relative;
  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: ${({ theme }) => theme.dropDownColor};
  }
`;
const DropDownMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.editLeague.addEventModal.menuBg};
  padding: 11px 15px;
  border-radius: 6px;
  border-radius: ${(props) => (props.isMenuOpen ? "8px 8px 0 0" : "")};

  img {
    cursor: pointer;
    height: 21px;
    width: 22px;
  }
  svg {
    cursor: pointer;
    fill: ${({ theme }) => theme.dropDownSvgFill};
    stroke: ${({ theme }) => theme.dropDownSvgStroke};
  }
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
  }
`;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top: 45px;

  display: flex;
  flex-direction: column;
  border-radius: 8px;
  max-height: 250px;
  width: 100%;
  overflow-x: hidden;
  color: black;
`;
const DropDownMenuItem = styled.li`
  background-color: red;
  cursor: pointer;

  img {
    border-radius: 4px;
    height: 14px;
    width: 18px;
  }

  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  list-style-type: none;
  background-color: ${({ theme }) => theme.editLeague.addEventModal.menuBg};
  padding: 5px 10px;

  div {
    column-gap: 5px;
    align-items: center;
    display: flex;
    flex-direction: row;
    .check {
      margin-right: 5px;
      background: red;
      color: red;
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
  }
  .active {
    p {
      color: rgba(97, 131, 255, 1);
    }
  }
  .nonActive {
    p {
      color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
    }
  }
`;
const SearchGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: #3c4a5c;
  input {
    align-items: center;
    position: relative;
    width: 100%;
    padding: 4px 10px 4px 20px;
    background: #eef2fd;
    border: none;
    border: 1px solid #d9dfe7;
    border-radius: 8px;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    :focus-visible {
      outline: none;
      border: 1px solid #006fff;
    }
    color: #3c4a5c;
  }
  svg {
    height: 10px;
    left: 7px;
    position: absolute;
    z-index: 2;
  }
`;
