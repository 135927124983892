import { PATCH } from "../../APiHelper/API";

export const editLeague = (league_id, name, tier, onSuccess, onError) => {

    const data = {
        name: name,
        tier: tier,
    }

    PATCH(`/api/v1/leagues/${league_id}/update`, data, onSuccess, onError)
}