
import React from "react";
import styled from "styled-components";
import { ReactComponent as EditSvg } from "../../../assets/icons/BettingIcons/editBtnsvg.svg";
import { ReactComponent as SortSvg } from "../../../assets/icons/BettingIcons/sort.svg";


const LeagueElement = ({
  leagueData,
  children,
  handleEditButton,
  handleSortByKey,
  handleSortPitchSize,
  handleSortShareStadium,
  handleSortByTeamName,
  archived,
}) => {

  const checkFlag = (country_code) => {
    if (country_code !== null) {
      return `https://flagcdn.com/${country_code.toLowerCase()}.svg`;
    }
  };

  return (
    <Container>
      <Header>
        <FlagCell>
          <img src={checkFlag(leagueData.country.country_code)} alt={""} />
        </FlagCell>
        <LeagueNameCell style>
          <h2>{leagueData.name}</h2>
          <SortSvg onClick={() => handleSortByTeamName(leagueData.season_id)} />
        </LeagueNameCell>

        <Stadium>
          <h2>Stadium</h2>
          <SortSvg onClick={() => handleSortByKey("name", leagueData.season_id)} />
        </Stadium>


        <Capacity >
          <h2>Capacity</h2>
          <SortSvg onClick={() => handleSortByKey("capacity", leagueData.season_id)} />
        </Capacity>
        <Surface >
          <h2>Surface</h2>
          <SortSvg onClick={() => handleSortByKey("surface", leagueData.season_id)} />
        </Surface>
        <RaceTrack >
          <h2>Race Track</h2>
          <SortSvg onClick={() => handleSortByKey("race_track", leagueData.season_id)} />
        </RaceTrack>
        <PitchSize >
          <h2>Pitch Size</h2>
          <SortSvg onClick={() => handleSortPitchSize(leagueData.season_id)} />
        </PitchSize>
        <ShareStadium >
          <h2>Share Stadium</h2>
          <SortSvg onClick={() => handleSortShareStadium(leagueData.season_id)} />
        </ShareStadium>
        <SharingTeam>
          <h2>Sharing Team</h2>
        </SharingTeam>
        <Location >
          <h2>Location</h2>
          <SortSvg onClick={() => handleSortByKey("location", leagueData.season_id)} />
        </Location>
        <Latitude >
          <h2>Latitude</h2>
          <SortSvg onClick={() => handleSortByKey("lat", leagueData.season_id)} />
        </Latitude>
        <Longitude >
          <h2>Longitude</h2>
          <SortSvg onClick={() => handleSortByKey("long", leagueData.season_id)} />
        </Longitude>
        <Altitude >
          <h2>Altitude</h2>
          <SortSvg onClick={() => handleSortByKey("altitude", leagueData.season_id)} />
        </Altitude>
        <DerbyLow>
          <h2>Derby Low</h2>
        </DerbyLow>
        <DerbyMed>
          <h2>Derby Med</h2>
        </DerbyMed>
        <DerbyHigh>
          <h2>Derby High</h2>
        </DerbyHigh>
        <Edit style={{ justifyContent: "end" }}>
          {!archived && <EditLeagueBtn onClick={() => handleEditButton(leagueData, leagueData.season_id)}>   <EditSvg /></EditLeagueBtn>
          }
        </Edit>
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};

export default LeagueElement;
const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: auto;
`;
const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.leagueBG};
  padding: 0 9px;
  height: 40px;
  column-gap: 10px;
  h2 {
    color: ${({ theme }) => theme.betHeaderTextColor};
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }
`;

const Cell = styled.div`
  display: flex;
  column-gap:2px;
  align-items: center;
  width: 100%;
  height: 100%;
  h2 {
    color: ${({ theme }) => theme.betHeaderTextColor};
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;

  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: rgba(0, 18, 41, 0.8);
  }
  .leagueTitle {
    letter-spacing: 0.01em;
    text-decoration-line: underline;
  }
  svg{
    cursor:pointer;
  }
`;
const FlagCell = styled.div`
  min-width: 20px;
  img {
    width: 20px;
  }
`;
const LeagueNameCell = styled(Cell)`
  min-width: 120px;
  .leagueName {
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-decoration-line: underline;
    color: var(--darkBlue);
  }
`;
const Stadium = styled(Cell)`
  min-width: 140px;
  .stadiumName {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
  }
`;
const Capacity = styled(Cell)`
  min-width: 50px;
`;
const Surface = styled(Cell)`
  min-width: 60px;
`;
const RaceTrack = styled(Cell)`
  min-width: 30px;
`;
const PitchSize = styled(Cell)`
  min-width: 45px;
`;
const ShareStadium = styled(Cell)`
  min-width: 35px;
`;
const SharingTeam = styled(Cell)`
  min-width: 50px;
`;
const Location = styled(Cell)`
  min-width: 50px;
`;
const Latitude = styled(Cell)`
  min-width: 50px;
`;
const Longitude = styled(Cell)`
  min-width: 55px;
`;
const Altitude = styled(Cell)`
  min-width: 50px;
  border-right: 2px solid var(--leagueElementBg);
`;
const DerbyLow = styled(Cell)`
  min-width: 100px;
  h2 {
    width: 30px;
  }
`;
const DerbyMed = styled(Cell)`
  min-width: 70px;
  h2 {
    width: 30px;
  }
`;
const DerbyHigh = styled(Cell)`
  min-width: 60px;
  h2 {
    width: 30px;
  }
`;
const Edit = styled(Cell)`
  min-width: 50px;
`;
const EditLeagueBtn = styled.button`
  cursor: pointer;
  display: flex;
  column-gap: 5px;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: var(--primaryBlue);
  padding: 4px 3px;
  border-radius: 3px;
  border: none;
  background: none;
`;
