import { POST } from "../../APiHelper/API";
let source;
export const getCupsCompeitionsAPI = (
    term,
    onSuccess,
    onError
) => {
    const data = {
        term: term
    }
    source = POST("/api/v1/cups/competitions", data, onSuccess, onError, true, source)
}