import React from 'react'
import styled from 'styled-components'

const DeletedPlayerHeader = () => {
    return (
        <Container>
            <h3 style={{ width: "90px" }}>DELETE DATE</h3>
            <h3 style={{ width: "70px" }}>ID</h3>
            <h3 style={{ width: "110px" }}>FIRST NAME</h3>
            <h3 style={{ width: "110px" }}>LAST NAME</h3>
            <h3 style={{ width: "110px" }}>SHORT NAME</h3>
            <h3 style={{ width: "110px" }}>OTHER NAME</h3>
            <h3 style={{ width: "70px" }}>NUMBER</h3>
            <h3 style={{ width: "80px" }}>FIRST POS.</h3>
            <h3 style={{ width: "80px" }}>SECOND POS.</h3>
            <h3 style={{ width: "80px" }}>THIRD POS.</h3>

        </Container>
    )
}

export default DeletedPlayerHeader
const Container = styled.div`
display:flex;
column-gap:10px;
h3{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.editLeague.groupsTextColor}
  }
`
