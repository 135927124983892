import React from "react";
import styled from "styled-components";
import { ReactComponent as PencilSvg } from "../../assets/icons/editPencil.svg";
const EditBtn = (props) => {
  return (
    <Container onClick={props.setShowEditUser}>
      <p>Edit</p>
      <PencilSvg />
    </Container>
  );
};

export default EditBtn;
const Container = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primaryBlue);
  border: 1.5px solid rgba(0, 111, 255, 0.6);
  background: transparent;
  border-radius: 32px;
  padding: 5px 17px;
  column-gap: 10px;
  p {
    font-weight: 400;
    font-size: 15px !important;
    line-height: 140%;
  }
`;
