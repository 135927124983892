import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownSvg } from "../../../assets/icons/DropDownMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/DropDownMenuIcons/up.svg";
import { mapRole } from "../../../helper/Generic/mapUserRole";

const DropdownMenuRole = (props) => {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleSelect = (e) => {
    props.setSelectedValue(e);
    setIsMenuOpen(false);
  };

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);
  return (
    <Container ref={ref}>
      <DropDownMenu onClick={() => handleMenuOpen()}>
        {props.selectedValue === null ? (
          <p>{props.label}</p>
        ) : (
          <p>{mapRole(props.selectedValue)}</p>
        )}

        {isMenuOpen ? <UpSvg /> : <DownSvg />}
      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>
          {props.itemsSource.map((item, index) => (
            <DropDownMenuItem key={index}>
              <p onClick={() => handleSelect(item)}>{mapRole(item)}</p>
            </DropDownMenuItem>
          ))}
        </DropDownList>
      )}
    </Container>
  );
};

export default DropdownMenuRole;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 256px;
  position: relative;
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #6a7584;
  }
`;
const DropDownMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f9fb;
  padding: 12px 10px;
  border-radius: 8px;
  text-transform: capitalize;
  svg {
    cursor: pointer;
    height: 21px;
    width: 22px;
  }
`;
const DropDownList = styled.ul`
  position: absolute;
  z-index: 1;
  top: 50px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  color: black;
  scrollbar-width: 10px;
  h2 {
    color: black;
  }
`;
const DropDownMenuItem = styled.li`
  cursor: pointer;
  width: 256px;
  text-transform: capitalize;
  margin-bottom: -1px;
  :first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;
  background-color: #f8f9fb;
  color: black;
  padding: 12px 10px;
`;
