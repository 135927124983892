import LocalStorage from "../Generic/LocalStorage";

class WSDWebSocket {
  constructor() {
    this.close = false;
    this.open = false;
    this.dataCallbacks = [];
    this.numberOfUnsucessfullReconnects = 0;
  }

  connect() {
    let endpoint =
      process.env.REACT_APP_WEB_SOCKET_URL +
      LocalStorage.getAuthenticationToken();

    this.disconnect();
    this.ws = new WebSocket(endpoint);
    const self = this;
    // When the ws opens connect to the channel
    this.ws.onopen = () => {
      self.open = true;
      self.#connectToChannelsIfNeeded();
    };

    this.ws.onmessage = function (event) {
      self.#handleEvent(event);
    };

    // Reopen the socket if closed
    this.ws.onclose = function (e) {
      self.open = false;
      self.#handleSocketClosed();
    };
  }

  disconnect() {
    this.close = true;
    if (this.ws) {
      this.ws.close();
    }
  }

  subscribeToChannel(name, callback) {
    const item = { name: name, callback: callback };
    this.dataCallbacks.push(item);

    if (this.open === false) {
      return;
    }

    this.#connectToChannel(name);
  }

  // Private methods

  #connectToChannelsIfNeeded() {
    if (this.open === false) {
      return;
    }

    this.dataCallbacks.forEach((item, index) => {
      this.#connectToChannel(item.name);
    });
  }

  #connectToChannel(name) {
    if (this.open === false) {
      return;
    }

    let message = JSON.stringify(this.#connectMessage(name));

    this.numberOfUnsucessfullReconnects = 0;
    this.ws.send(message);
  }

  #connectMessage(channelName) {
    let message = {
      command: "subscribe",
      identifier: '{"channel": "' + channelName + '"}',
    };
    return message;
  }

  #handleEvent(event) {
    const json = JSON.parse(event.data);
    if (json.type === "ping") {
      return;
    }
    // console.log(json)
    const identifier = json["identifier"];
    var channelName = null;
    if (identifier) {
      const channelNameJson = JSON.parse(identifier);
      channelName = channelNameJson["channel"];
    }

    if (channelName !== null && json.message) {
      this.#handleMessageForChannel(channelName, json.message);
    }
  }

  #handleMessageForChannel(channel, message) {
    const item = this.dataCallbacks.find((obj) => obj.name === channel);
    if (item) {
      item.callback(channel, message);
    }
  }

  #handleSocketClosed() {
    // Return if the flag is true
    if (this.close === true) {
      return;
    }

    // After more than 3 attems, try again in 1 min, otherwise the server will be flooded
    if (this.numberOfUnsucessfullReconnects > 3) {
      setTimeout(() => {
        self.numberOfUnsucessfullReconnects = 0;
        console.log("reconnecting after delay");
        self.connect();
      }, 60 * 1000);
      return;
    }

    this.numberOfUnsucessfullReconnects += 1;
    console.log(self.numberOfUnsucessfullReconnects);
    console.log("reconnecting....");
    this.connect();
  }
}

export default WSDWebSocket;
