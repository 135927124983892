const HOME = "Home"
const AWAY = "Away"
const BOTH = "Both"

const ALL = "All"
const SUBMITTED = "Submitted"
const COMPLETED = "Completed"
const HIDE_DEFERRED = "Hide Deferred"

export const SquadManagerFilterValues = [
    {
        side: ALL,
        filters: [
            ALL,
            SUBMITTED,
            COMPLETED,
            HIDE_DEFERRED,
        ]
    },
    {
        side: HOME,
        filters: [
            ALL,
            SUBMITTED,
            COMPLETED,
            HIDE_DEFERRED
        ]
    }
    ,
    {
        side: AWAY,
        filters: [
            ALL,
            SUBMITTED,
            COMPLETED,
            HIDE_DEFERRED
        ]
    }

]

