import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { changePassword } from "../../api/authentication/ResetPassword";
import { notifyError, notifySucces } from "../../helper/Generic/Notifications";
import { ReactComponent as SwanLogo } from "../../assets/icons/swanLogo.svg";
import { ReactComponent as EyeSvg } from "../../assets/icons/eye.svg";
import { ReactComponent as WrongSvg } from "../../assets/icons/wrong.svg";

const NewPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordsLength, setPasswordsLength] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const handleInputPassword = (e) => {
    setPassword(e.target.value);
  };

  const handleInputPassword2 = (e) => {
    setPassword2(e.target.value);
  };
  const checkPasswordsLength = () => {
    if (password.length > 0) {
      if (password.length < 6) {
        setPasswordsLength(false);
      } else {
        setPasswordsLength(true);
      }
    }
  };
  const checkPasswordsMatch = () => {
    if (passwordsLength === true) {
      if (password2.length > 1) {
        if (password === password2) {
          setPasswordsMatch(true);
        } else {
          setPasswordsMatch(false);
        }
      }
    }
  };

  const handleResetPassword = () => {
    if (passwordsMatch === true && passwordsLength === true) {
      changePassword(
        password,
        searchParams.get("token"),
        function () {
          notifySucces("Password changed");
          navigate("/login");
        },
        function (error) {

          notifyError("Something wrong");
        }
      );
    }
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleResetPassword();
    }
  };
  useEffect(() => {
    checkPasswordsLength();
    checkPasswordsMatch();
  }, [password, password2]);

  return (
    <Container>
      <Card onKeyDown={(e) => handleKeyDown(e)}>
        <Top>
          <SwanLogo />
          <h1>New Password</h1>
        </Top>
        <InputWrapper>
          <h3>Pick a new password to use for your login credentials.</h3>
        </InputWrapper>
        <InputWrapper>
          <label>Password</label>
          <div className="passwdInput">
            <input
              type={visible ? "text" : "password"}
              placeholder={"password"}
              value={password}
              onChange={(e) => handleInputPassword(e)}
            ></input>
            <EyeSvg onClick={() => setVisible(!visible)} />
          </div>
        </InputWrapper>
        <InputWrapper>
          <label>Repeat Password</label>

          <div className="passwdInput">
            <input
              className={
                !passwordsMatch && password2.length > 1 ? "wrong" : null
              }
              type={visible2 ? "text" : "password"}
              placeholder={"Repeat password"}
              value={password2}
              onChange={(e) => handleInputPassword2(e)}
            ></input>
            <EyeSvg onClick={() => setVisible2(!visible2)} />
          </div>

          {!passwordsMatch && password2.length > 1 && (
            <label
              style={{
                display: "flex",
                alignItems: "center",
                rowGap: "5px",
                color: "#F11C0E",
              }}
            >
              <WrongSvg />
              Your passwords don’t match
            </label>
          )}
          {!passwordsLength && (
            <label
              style={{
                display: "flex",
                alignItems: "center",
                rowGap: "5px",
                color: "#F11C0E",
              }}
            >
              <WrongSvg />
              Your passwords must have minimum 6 characters
            </label>
          )}
        </InputWrapper>

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            disabled={!passwordsMatch}
            onClick={() => handleResetPassword()}
          >
            Reset Password
          </button>
        </div>
      </Card>
    </Container>
  );
};

export default NewPassword;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(249, 249, 249, 1);
  height: var(--pageHeight);
  overflow: auto;
  min-height: 600px;
  min-width: 600px;
`;
const Card = styled.div`
  min-width: 500px;
  min-height: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 25px;
  background-color: white;
  padding: 80px 145px;
  border: 2px solid #f8f9fb;
  box-shadow: 0px 1px 8px rgba(46, 46, 46, 0.04);
  border-radius: 16px;
  overflow: hidden;

  button {
    padding: 15px 0;
    min-width: 180px;
    width: 100%;
    border-radius: 4px;
    border: none;
    background: var(--primaryBlue);
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    :disabled {
      opacity: 0.5;
    }
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  min-width: 20vw;
  width: 328px;
  h2 {
    margin-top: -10px;
    color: var(--primaryBlue);
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
  }

  h3 {
    color: #4a4a4a;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .wrong {
    border: 1px solid red;
    :focus-visible {
      outline: 0px;
      border: 1px solid red;
    }
  }
  .passwdInput {
    display: flex;
    position: relative;
    svg {
      cursor: pointer;
      position: absolute;
      top: 12px;
      right: 10px;
      height: 14px;
      width: 14px;
    }
  }
  input {
    width: 100%;
    border: 1px solid #d9dfe7;
    border-radius: 4px;
    padding: 10px 30px 10px 16px;
    ::placeholder {
      color: #b3b9c0;
    }
    :focus-visible {
      outline: 0px;
      border: 1px solid var(--primaryBlue);
    }
  }
  label {
    color: #001229;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  svg {
    height: 58px;
    width: 58px;
  }
  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 42px;
    line-height: 56px;
  }
`;
