import { PATCH } from "../../APiHelper/API";

export const updatePlayer = (
  playerId,
  datas,
  onSuccess,
  onError
) => {
  const data = datas;
  PATCH(`/api/v1/leagues/player/${playerId}/update`, data, onSuccess, onError);
};
