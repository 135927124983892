import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as SendSvg } from "../../../assets/icons/sendMessage.svg";
import { notifyError, notifySucces } from "../../../helper/Generic/Notifications";
import { selectUser } from "../../../Redux/user/user";
import { useSelector } from "react-redux";
import { format_time_to_timezone, same_time_in_timezone } from "../../../helper/Generic/dateTimeFormat";
import NoteElement from "../../notes/NoteElement";
import { useNotesService } from "../../../context/useNotesService";
import cloneDeep from "lodash.clonedeep";
const MatchPreviewChatOneTeam = ({ closeNote, fixture, side }) => {

    const [inputValue, setInputValue] = useState("");
    const [homeTeam, setHomeTeam] = useState(null);
    const [awayTeam, setAwayTeam] = useState(null);
    const [thisTeam, setThisTeam] = useState(null);
    const [teamConversation, setTeamConversation] = useState([])

    const { sendMessageTeamConversation, getMessagesForTeam, deleteMessageFromTeamConversation } = useNotesService();

    const user = useSelector(selectUser);
    const bottomRef = useRef(null);

    const populateTeams = () => {
        if (!fixture) {
            return;
        }
        if (fixture.fixture.participant1is_home) {
            setHomeTeam(fixture.team1);
            setAwayTeam(fixture.team2);
            if (side == "HOME") {
                setThisTeam(fixture.team1)
            }
            if (side == "AWAY") {
                setThisTeam(fixture.team2)
            }
        }
        else {
            setHomeTeam(fixture.team2);
            setAwayTeam(fixture.team1);
            if (side == "HOME") {
                setThisTeam(fixture.team2)
            }
            if (side == "AWAY") {
                setThisTeam(fixture.team1)
            }
        }
    };

    const handleResponse = (response) => {
        let clone = cloneDeep(teamConversation);
        clone.messages.push(response.team_message)

        setTeamConversation(clone)
    }

    const _handleInputChange = (e) => {
        setInputValue(e.currentTarget.value);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleMessage();
        }
    };

    const handleMessage = () => {
        sendMessageTeamConversation(
            teamConversation.id,
            inputValue,
            function (response) {
                handleResponse(response.data)
                //getConversation();
            },
            function (error) {
                notifyError("Something wrong")
            }
        )
        setInputValue("");
    };

    const scrollToBottom = () => {
        bottomRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    };

    const getConversation = () => {
        setTeamConversation(null);
        getMessagesForTeam(
            fixture.fixture.fixture_id,
            thisTeam.id,
            function (response) {
                setTeamConversation(response.data.team_conversation);
            },
            function () {
                setTeamConversation(null)
                notifyError("Something wrong...")
            }
        )
    };
    const handleDeleteMessage = (item) => {
        deleteMessageFromTeamConversation(
            item.id,
            function (response) {
                getConversation();
                notifySucces("Message Deleted")
            },
            function (error) {
                notifyError("Something wrong")

            }
        )
    }


    useEffect(() => {
        if (!fixture) {
            return;
        }
        populateTeams();

    }, [fixture, side]);

    useEffect(() => {
        if (!thisTeam) {
            return
        }
        getConversation();
    }, [thisTeam])

    useEffect(() => {
        scrollToBottom();
    }, [teamConversation]);

    return (
        <Container>
            <Header>
                <div className="xBtn">
                    {thisTeam?.name}

                </div>

            </Header>
            <Notes>
                {teamConversation?.messages?.map((item, index) => (
                    <NoteElement key={index} message={item} user={user} handleDeleteMessage={handleDeleteMessage} />
                ))}
                <div ref={bottomRef} />
            </Notes >
            <Inpt>
                <div className="inputGroup">
                    <input
                        placeholder="Type message here..."
                        type={"text"}
                        value={inputValue}
                        onChange={(e) => _handleInputChange(e)}
                        onKeyDown={(e) => handleKeyDown(e)}
                    ></input>
                    <button onClick={() => handleMessage()}>
                        <SendSvg />
                    </button>
                </div>
            </Inpt>
        </Container>
    );
};

export default MatchPreviewChatOneTeam;
const Container = styled.div`

height:100%;
display: flex;
flex-direction: column;
min-width: 230px;
background: ${({ theme }) => theme.notePanelBg};
color: ${({ theme }) => theme.leagueColor};
border-radius: 10px;
`;
const Header = styled.div`
  padding: 20px 15px 10px 15px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.leagueBG};
  color: ${({ theme }) => theme.favoriteStarStroke};

  @media (max-width: 1800px) {
    border-bottom: 1px solid #d4d5d9;
  }
  h1 {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
  p {
    color: ${({ theme }) => theme.favoriteStarStroke};
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
  .xBtn {
    display: flex;
    justify-content: space-between;
  }
  button {
    color: ${({ theme }) => theme.favoriteStarStroke};
    background: transparent;
    border: none;
    cursor: pointer;
    font-weight: 700;
    width: fit-content;
  }
`;
const Notes = styled.div`
display: flex;
flex-direction: column;
padding: 15px;
height: 100%;
overflow: auto;
row-gap: 15px;
position:relative;
`;
const Inpt = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  padding: 10px;
  .inputGroup {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      min-width: 200px;
      border: none;
      background: ${({ theme }) => theme.notesBG};
      color: ${({ theme }) => theme.notesTextColor};
      border-radius: 30px;
      padding: 10px 40px 10px 20px;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      ::placeholder{
        color: ${({ theme }) => theme.notexInputColor};
      }
      :focus-visible {
        outline: none;
      }
    }
    button {
      cursor: pointer;
      background: transparent;
      border: none;
      position: absolute;
      right: 10px;
    }
  }
`;
