import { POST } from "../../../APiHelper/API"


export const addCupRefereeAPI = (
    data,
    cupId,
    onSuccess,
    onError
) => {
    POST(`/api/v1/cups/${cupId}/add_referee`, data, onSuccess, onError, true)
}