import React from "react";
import DatePicker from "react-date-picker";
import styled from "styled-components";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/LeaguesIcons/calendar.svg";
const CustomDateMenu = ({ value, setValue }) => {
    return (
        <Container>
            <DatePicker
                format={"dd/MM/yyyy"}
                value={value}
                onChange={setValue}
                clearIcon={false}
                calendarIcon={<CalendarIcon />}
            />
        </Container>
    );
};

export default CustomDateMenu;

const Container = styled.div`
  display: flex;
  .react-date-picker__wrapper {
    border-radius: 6px;
    border: 1px solid #bac3e8;
    height: 40px;
    min-width: 160px;
    color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
    background: ${({ theme }) => theme.editLeague.readOnlyInputs};
    svg {
      color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
    }
  }

  input {

    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
    &::placeholder {
      color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
    }
  }

  .react-date-picker__inputGroup{
    padding:0 15px;
  }
  .react-date-picker__inputGroup__leadingZero {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  }
  .react-calendar {
    background: ${({ theme }) => theme.editLeague.readOnlyInputs};
  }

  .react-calendar__month-view__weekdays__weekday {
    color: var(--primaryBlue);
  }
  .react-calendar__month-view__days__day {
    color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  }
  .react-calendar__month-view__days__day--weekend {
    color: red;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: ${({ theme }) => theme.editLeague.borderColor};
  }
  .react-calendar__tile--now {
    background: ${({ theme }) => theme.editLeague.wyChanged};
  }
  .react-calendar__navigation button {
    color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  }
  .react-calendar__year-view__months__month {
    color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  }
  .react-calendar__decade-view__years__year {
    color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  }
  .react-calendar__century-view__decades__decade {
    color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  }
  .react-calendar__navigation button:disabled {
    background: ${({ theme }) => theme.editLeague.wyChanged};
  }
  .react-calendar__navigation button:enabled:hover {
    background: ${({ theme }) => theme.editLeague.wyChanged};
  }
  .react-calendar__navigation button:focus {
    background: unset;
  }
`;
