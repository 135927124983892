import { POST } from "../../APiHelper/API";

export const addNewStadiumToTeam = (
    team_id,
    name,
    capacity,
    length,
    width,
    surface,
    location,
    lat,
    long,
    altitude,
    team_not_listed,
    other_sports,
    race_track,
    country_id,
    onSuccess,
    onError
) => {
    const data = {
        name: name,
        capacity: capacity,
        length: length,
        width: width,
        surface: surface,
        location: location,
        lat: lat,
        long: long,
        altitude: altitude,
        team_not_listed: team_not_listed,
        other_sports: other_sports,
        race_track: race_track,
        country_id: country_id,
    }
    POST(`/api/v1/leagues/team/${team_id}/create_stadium`, data, onSuccess, onError)
}