import { createContext, useCallback, useContext, useState } from "react";
import { getParkedBets } from "../api/bets/getParkedBets";
import { parkBet } from "../api/bets/parkBets";
import { unparkBet } from "../api/bets/unparkBet";


export const ParkedBetsServiceContext = createContext({
    parkedBets: [],
    getPBets: () => { },
    park_bet: () => { },
    unpark_bet: () => { }
});

const ParketBetsServiceProvider = ({ children }) => {
    const [parkedBets, setParkedBets] = useState([]);



    const getPBets = useCallback(async (callbackFunction, callbackError) => {
        try {
            await getParkedBets(
                function (response) {

                    setParkedBets(response.data.bets);
                    callbackFunction();
                },
                function (error) {
                    callbackError();
                }
            );

        }
        catch (err) {
            callbackError();
        }
    });

    const park_bet = useCallback(async (bet_id, no_move, no_price, screen_limit, markets, callbackFunction, callbackError) => {
        try {
            await parkBet(bet_id, no_move, no_price, screen_limit, markets,
                function (response) {
                    callbackFunction();
                },
                function (error) {
                    callbackError();

                }
            );

        }
        catch (err) {
            callbackError();
        }
    });
    const unpark_bet = useCallback(async (parked_bet_id, callbackFunction, callbackError) => {
        try {
            await unparkBet(parked_bet_id,
                function (response) {

                    callbackFunction();
                },
                function (error) {
                    callbackError();

                }
            );

        }
        catch (err) {
            callbackError();
        }
    });


    return (
        <ParkedBetsServiceContext.Provider value={{
            parkedBets, getPBets, park_bet, unpark_bet
        }}>
            {children}
        </ParkedBetsServiceContext.Provider>
    );
};
export const useParkedBetsService = () => useContext(ParkedBetsServiceContext);
export default ParketBetsServiceProvider;