import { PATCH } from "../APiHelper/API";

export const editSelections = (
    fixture_id,
    league_id,
    squad_id,
    formation,
    selections,
    onSuccess,
    onError
) => {
    const data = {
        fixture_id: fixture_id,
        league_id: league_id,
        squad_id: squad_id,
        formation: formation,
        selections: selections,
    };
    PATCH(`/api/v1/squads/edit_selections`, data, onSuccess, onError);
};
