
import React from "react";
import styled from "styled-components";
import { ReactComponent as RemoveSvg } from "../../../assets/icons/check/remove.svg";
import cloneDeep from "lodash.clonedeep";

const SelectedTeams = ({ value, setValue }) => {

  const handleRemove = (item) => {
    let copy = cloneDeep(value);
    let found = value.find((team) => team.id === item.id);
    if (found) {
      let filtered = copy.filter((element) => element.id !== found.id)
      setValue(filtered)
    }

  };


  return (
    <Container>
      <ul>
        {value?.map((item, key) => (
          <li key={key}>
            <div>
              <img src={item?.logo_url} alt="" />
              <p>{item?.name} <span>({item?.season?.name})</span> </p>
            </div>
            <div>
              <button onClick={() => handleRemove(item)}>
                <RemoveSvg />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default SelectedTeams;
const Container = styled.div`
  width: 258px;
  height:100%;
 // max-height:320px;
  overflow:auto;

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    row-gap: 8px;
  }

  li {
    padding: 8px;
    background: ${({ theme }) => theme.editLeague.addEventModal.selectionItemBg};
    border-radius: 6px;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      
      column-gap: 10px;
      display: flex;
      align-items: center;
      img {
        height: 14px;
        width: 18px;
      }
span{
  font-size:10px;
  opacity:0.8;
}
    }
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
    }

    button {
      cursor: pointer;
      border: none;
      background-color: transparent;
      svg {
        height: 18px;
        width: 18px;
      }
    }
  }
`;
