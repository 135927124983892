import React from "react";
import styled from "styled-components";
import { ReactComponent as TshirtIcon } from "../../assets/icons/tshirt.svg";
import {
  playerStartingLikelihoodPercentage,
  getFatigueValues,
  getSureFatigueValue,
} from "../../helper/lineup/LineupHelper";
import { useSelector } from "react-redux";
import { selectUser } from "../../Redux/user/user";

const FieldPlayer = ({ away, player }) => {
  const user = useSelector(selectUser);
  const percentage = playerStartingLikelihoodPercentage(player);
  const fatigueStatus = getFatigueValues(player);
  const sureFatigueScore = getSureFatigueValue();

  var displayedName =
    player?.player?.first_name.charAt() + "." + " " + player?.player?.last_name;

  if (user.use_short_player_names && player?.player?.short_name && player?.player?.short_name != ""
  ) {
    displayedName = player.player.short_name
  }
  if (user.use_other_player_names && player?.player?.other_player_name && player?.player?.other_player_name != "") {
    displayedName = player.player.other_player_name
  }

  let status = sureFatigueScore.score;
  let backgroundColor = sureFatigueScore.backgroundColor;
  let textColor = sureFatigueScore.textColor;

  if (fatigueStatus) {
    status = fatigueStatus.score;
    backgroundColor = fatigueStatus.backgroundColor;
    textColor = fatigueStatus.textColor;
  }

  return (
    <Container>
      <Tshirt away={away}>
        <TshirtIcon />

        {player?.squad_number !== undefined && <p>{player?.player?.squad_number}</p>}
      </Tshirt>
      <Content>
        <Top>
          <TopLeft backgroundColor={backgroundColor} textColor={textColor}>
            <p>{status}</p>
          </TopLeft>
          <TopRight away={away}>
            <p>
              {percentage}
              <label>%</label>
            </p>
          </TopRight>
        </Top>
        <Name>
          <h1>{displayedName}</h1>
        </Name>
      </Content>
    </Container>
  );
};

export default FieldPlayer;
const Container = styled.div`
  display: flex;
  position: relative;
  width: 50px;
  height: 50px;
  align-items: end;
`;
const Tshirt = styled.div`
color: ${(props) => (props.away ? ({ theme }) => theme.projectedLineup.fieldAwayColor : ({ theme }) => theme.projectedLineup.fieldHomeColor)};
  position: absolute;
  top: 0px;
  left: 10px;

  p {
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    width: 12px;
    text-align: center;
    color: #ffffff;
    position: absolute;
    top: 6px;
    left: 10.5px;
    z-index: 0;
  }
`;
const Content = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  background: #ffffff;
  border-radius: 2px;
  overflow: hidden;
  z-index: 0;
`;
const TopLeft = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background: ${(props) =>
    props.backgroundColor ? `${props.backgroundColor}` : ""};

  p {
    padding-top: 2px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: ${(props) => (props.textColor ? props.textColor : "")};
  }
`;
const Top = styled.div`
  display: flex;
  width: 100%;
`;
const TopRight = styled(TopLeft)`
  background: ${(props) => (props.away ? "#B9EEFF" : "#EFE8FF")};
  p {
    color: ${(props) => (props.away ? ({ theme }) => theme.projectedLineup.fieldAwayColor : ({ theme }) => theme.projectedLineup.fieldHomeColor)};
    padding-top: 2px;
  }
  label {
    font-weight: 500;
  }
`;
const Name = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  padding: 0 3px;
  h1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    text-align: center;
    color: #3a3957;
  }
`;
