import React from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";
import { selectUser } from "../../Redux/user/user";
import HeaderMenu from "./HeaderMenu";

const Header = ({ theme, toggleTheme }) => {

  const user = useSelector(selectUser);


  return (
    <Container>
      <Left></Left>
      {user && <HeaderMenu theme={theme} toggleTheme={toggleTheme} />}
    </Container>
  );
};

export default Header;
const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 4;
  display: flex;
  justify-content: space-between;
  background: var(--darkBlue);
  align-items: center;
  height: 45px;
  min-width: 1600px;
  width: 100%;
  padding: 0 20px;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  min-width: 200px;
  column-gap: 7px;
  img {
    height: 32px;
    cursor: pointer;
  }

  h2 {
    margin-top: 3px;
    font-family: "GillSans-Light";
    cursor: pointer;
    font-weight: 100;
    font-size: 16px;
    color: white;
  }
`;
