import React from "react";
import styled from "styled-components";
import { ReactComponent as RemoveSvg } from "../../assets/icons/check/remove.svg";

const SelectionList = ({
  selectedCompetitions,
  setSelectedCompetitions,
  allLeagues,
  unselectAllCompetitions,
  unselectAllSeasons,
  unselectAllCups,
  allSeasons,
  allCups,
  selectedCups, selectedSeasons,
  setSelectedSeasons, setSelectedCups
}) => {

  const handleFlag = (item) => {
    if (item.country.country_code !== null) {
      return (`https://flagcdn.com/${item.country.country_code.toLowerCase()}.svg`);
    }
    else {
      return "../../assets/icons/football.svg";
    }
  };

  const handleRemove = (item, list, setList) => {
    var updatedList = [...list];
    updatedList.splice(list.indexOf(item), 1);
    setList(updatedList);
  };

  return (
    <Container>

      {selectedCompetitions?.map((item, key) => (
        <li key={key}>
          <div>
            <img src={handleFlag(item)} alt="" />
            <p>{item.competition}</p>
          </div>

          <button onClick={() => handleRemove(item, selectedCompetitions, setSelectedCompetitions)}>
            <RemoveSvg />
          </button>

        </li>
      ))}

      {selectedCups?.map((item, key) => (
        <li key={key}>
          <div>
            {item.logo &&
              <img src={item.logo} alt="" />
            }
            <p>{item.name}</p>
          </div>

          <button onClick={() => handleRemove(item, selectedCups, setSelectedCups)}>
            <RemoveSvg />
          </button>

        </li>
      ))}

      {selectedSeasons?.map((item, key) => (
        <li key={key}>
          <div>

            <p>{item.name}</p>
          </div>

          <button onClick={() => handleRemove(item, selectedSeasons, setSelectedSeasons)}>
            <RemoveSvg />
          </button>

        </li>
      ))}

      {allLeagues &&
        <li>
          <div>
            <p>All competitions</p>
          </div>

          <button onClick={() => unselectAllCompetitions()}>
            <RemoveSvg />
          </button>

        </li>}
      {allSeasons &&
        <li>
          <div>
            <p>All seasons</p>
          </div>

          <button onClick={() => unselectAllSeasons()}>
            <RemoveSvg />
          </button>

        </li>}
      {allCups &&
        <li>
          <div>
            <p>All cups</p>
          </div>
          <button onClick={() => unselectAllCups()}>
            <RemoveSvg />
          </button>

        </li>}
    </Container>
  );
};

export default SelectionList;
const Container = styled.div`
width:100%;
  height:100%;
    display: flex;
    flex-direction: column;
       list-style: none;
    row-gap: 8px;
  li {
  width:100%;
    padding: 8px;
    background: ${({ theme }) => theme.editLeague.addEventModal.selectionItemBg};
    border-radius: 6px;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:10px;

    div {
    width:100%;
    overflow:hidden;
      column-gap: 10px;
      display: flex;
      align-items: center;
      img {
        height: 14px;
        width: 18px;
      }
    }

    p {
text-overflow:ellipsis;
overflow:hidden;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
    }

    button {
      cursor: pointer;
      border: none;
      background-color: transparent;
      svg {
        height: 18px;
        width: 18px;
      }
    }
  }
`;

const Subcategory = styled.div`
display:flex;
p{
font-size:14px;
line-height:16px;
font-weight:500;
   color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
}

`