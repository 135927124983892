import React from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import qs from "query-string";

import LivePopup from "../LivePopup";
import { param } from "jquery";

const LeaguesHeader = ({ params }) => {

    const LEAGUES_DATA = "LEAGUES DATA";
    const MATCH_PREVIEW = "MATCH PREVIEW";
    const ARCHIVED = "ARCHIVED"

    const navigate = useNavigate();
    const location = useLocation();


    const navigate_send_params = (navigateTo) => {
        let param = createSearchParams(params).toString()
        navigate({
            pathname: navigateTo,
            search: param
        });
    };

    const navigate_without_params = (navigateTo) => {
        navigate({
            pathname: navigateTo,
        });
    };

    return (
        <Container>
            <Main>
                <BtnWrapper>
                    <button
                        value={LEAGUES_DATA}
                        onClick={() => navigate_send_params("/leagues")}
                        className={
                            location.pathname === "/leagues" ||
                                location.pathname === "/leagues/players_data" ?
                                "active" : ""
                        }
                    >
                        {LEAGUES_DATA}
                    </button>
                    <button
                        value={MATCH_PREVIEW}
                        onClick={() =>
                            navigate_send_params("/leagues/match_preview")}
                        className={location.pathname === "/leagues/match_preview" ? "active" : ""}
                    >
                        {MATCH_PREVIEW}
                    </button>
                    <button
                        value={ARCHIVED}
                        onClick={() =>
                            navigate_without_params("/leagues/archived")}
                        className={location.pathname === "/leagues/archived" ? "active" : ""}
                    >
                        {ARCHIVED}
                    </button>
                </BtnWrapper>
                <LivePopup />

            </Main>
        </Container>
    );
};

export default LeaguesHeader;
const Container = styled.div`
position:sticky;
top:45px;
background: white;
z-index:3;
`;
const Main = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 14px;
  width: 100%;
  min-width: 1600px;
  background: rgba(0, 18, 41, 0.85);
`;
const BtnWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  border-radius: 2px;
  column-gap:3px;
  background:white;
  button {
    display: flex;
    cursor: pointer;
    padding: 8px 11px;
    border: none;
    margin: -1px;
    background: rgba(0, 18, 41, 0.85);
    color: rgba(255, 255, 255, 0.4);
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.02em;
  }

  .active {
    background: white;
    color: rgba(0, 18, 41, 1);
  }
`;

