import { percentageChange } from "../ModelDataHelper";

const SING_BOOKMAKER_ID = 282;

const ASIA_HANDICAP_MARKET_NAME = "Asian Handicap";
const ASIA_HANDICAP_AWAY = "Away";
const ASIA_HANDICAP_HOME = "Home";

const GOALS_MARKET_NAME = "OU Goals";
const GOALS_OVER = "Over";
const GOALS_UNDER = "Under";

const MATCH_ODDS_MARKET_NAME = "Match Odds";
const MATCH_ODDS_DRAW = "Draw";
const MATCH_ODDS_HOME = "Away";
const MATCH_ODDS_AWAY = "Home";

export const calculate_live_value_price = (
  confirmed_price,
  market_id,
  market_data
) => {
  if (!market_data) {
    return null;
  }

  let sing_price = find_sing_market(market_data.markets, market_id);
  let bookmaker_id = SING_BOOKMAKER_ID;
  let price = null;

  if (sing_price != undefined) {
    price = sing_price.price;
  }

  let result = calculate_nomalised_price(
    market_data.markets,
    market_id,
    bookmaker_id,
    confirmed_price,
    sing_price,
    price
  );

  return result;
};

export const calculate_nomalised_price = (
  prices,
  market_id,
  bookmaker_id,
  confirmed_price,
  market_details,
  market_price
) => {
  if (market_price == undefined) {
    return null;
  }

  let market = market_details.market;
  if (market == undefined) {
    return null;
  }

  if (two_side_calculation(market)) {
    let opposite_market_name = get_two_sides_opposite_market(market);
    let result = calculate_price(
      prices,
      market,
      bookmaker_id,
      confirmed_price,
      market_price,
      [opposite_market_name]
    );

    return result;
  }

  if (three_side_calculation(market)) {
    let opposite_market_names = get_three_sides_opposite_market(market);
    let result = calculate_price(
      prices,
      market,
      bookmaker_id,
      confirmed_price,
      market_price,
      opposite_market_names
    );

    return result;
  }

  return calculate_single_price(market.price, confirmed_price);
};

// Private methods

export const find_sing_market = (prices, market_id) => {
  let result = prices.find(
    (element) =>
      element.market_id === market_id &&
      element.bookmaker_id === SING_BOOKMAKER_ID
  );

  return result;
};

const find_price = (prices, market_id, bookmaker_id) => {
  let result = prices.find(
    (element) =>
      element.market_id === market_id && element.bookmaker_id === bookmaker_id
  );

  return result;
};

const two_side_calculation = (market) => {
  if (
    market.market == ASIA_HANDICAP_MARKET_NAME ||
    market.market == GOALS_MARKET_NAME
  ) {
    return true;
  }

  return false;
};

const three_side_calculation = (market) => {
  if (market.market == MATCH_ODDS_MARKET_NAME) {
    return true;
  }

  return false;
};

const get_two_sides_opposite_market = (market) => {
  if (market.market == ASIA_HANDICAP_MARKET_NAME) {
    if (market.selection == ASIA_HANDICAP_AWAY) {
      return ASIA_HANDICAP_HOME;
    }

    if (market.selection == ASIA_HANDICAP_HOME) {
      return ASIA_HANDICAP_AWAY;
    }
  }

  if (market.market == GOALS_MARKET_NAME) {
    if (market.selection == GOALS_OVER) {
      return GOALS_UNDER;
    }

    if (market.selection == GOALS_UNDER) {
      return GOALS_OVER;
    }
  }

  return null;
};

const get_three_sides_opposite_market = (market) => {
  if (market.market == MATCH_ODDS_MARKET_NAME) {
    if (market.selection == MATCH_ODDS_DRAW) {
      return [MATCH_ODDS_AWAY, MATCH_ODDS_HOME];
    }

    if (market.selection == MATCH_ODDS_AWAY) {
      return [MATCH_ODDS_DRAW, MATCH_ODDS_HOME];
    }

    if (market.selection == MATCH_ODDS_HOME) {
      return [MATCH_ODDS_DRAW, MATCH_ODDS_AWAY];
    }
  }

  return [];
};

const calculate_single_price = (confirmed_price, market_price) => {
  return percentageChange(confirmed_price, market_price);
};

const filter_prices = (
  prices,
  market,
  selection,
  value = null,
  bookmaker_id = null
) => {
  let return_items = prices.filter(
    (price) =>
      price.market.market == market && price.market.selection == selection
  );

  if (value != undefined) {
    return_items = return_items.filter((price) => price.market.value === value);
  }

  if (bookmaker_id != undefined) {
    return_items = return_items.filter(
      (price) => price.bookmaker_id == bookmaker_id
    );
  }

  return return_items;
};

const calculate_price = (
  prices,
  market,
  bookmaker_id,
  confirmed_price,
  market_price,
  opposite_market_names
) => {
  if (opposite_market_names == undefined || opposite_market_names.count == 0) {
    return null;
  }

  let opposite_prices = [];
  opposite_market_names.forEach((name) => {
    let filtered_prices = filter_prices(
      prices,
      market.market,
      name,
      market.value,
      bookmaker_id
    );

    if (filtered_prices == null || filtered_prices.count == 0) {
      return;
    }

    let price = filtered_prices[0];
    opposite_prices.push(price.price);
  });

  if (opposite_prices.count == 0) {
    return calculate_single_price(market_price, confirmed_price);
  }

  let den = 1 / market_price;
  opposite_prices.forEach((price) => {
    den += 1 / price;
  });

  const new_market_price = market_price * den;

  let val = calculate_single_price(new_market_price, confirmed_price);
  return val;
};
