import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

import LivePopup from "../LivePopup";
import { NumericFormat } from "react-number-format";
import WebSocketObserver from "../../helper/websocket/WebSocketObserver";
import { useWebSocketDataService } from "../../context/useWebSocketDataService";
import { getLiveStatus } from "../../api/liveStatus/getLiveStatus";

const CalculatedValueField = ({ title, value, suffix, customClass }) => {
  return (
    <CalcValueContainer>
      <span>{title} </span>
      <NumericFormat
        value={value}
        displayType="text"
        thousandSeparator=","
        className={`numberFormatter ${customClass}`}
        suffix={suffix}
        decimalScale=""
        decimalSeparator=""
      />
    </CalcValueContainer>
  );
};

const BettingPageHeader = ({
  marketEv,
  modelEv,
  loggedBetsNumber,
  loggedBetsOUGNumber,
  loggedBetsHCPNumber,
  retStakeSum,
  riskSum,
}) => {

  const BETS_LIST = "BETS LIST";
  // const ASIA_LOGGED_BETS = "LOGGED BETS";
  const ASIA_LOGGED_BETS = "ASIA LOGGED BETS";
  const RETAIL_LOGGED_BETS = "RETAIL LOGGED BETS"
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Container>
      <Main>
        <BtnWrapper>
          <button
            value={BETS_LIST}
            onClick={(e) => navigate("/betting/bets_list")}
            className={location.pathname === "/betting/bets_list" ? "active" : ""}
          >
            {BETS_LIST}
          </button>
          <button
            value={RETAIL_LOGGED_BETS}
            onClick={(e) => navigate("/betting/retail_logged_bets")}
            className={
              location.pathname === "/betting/retail_logged_bets" ? "active" : ""
            }
          >
            {RETAIL_LOGGED_BETS}
          </button>
          <button
            value={ASIA_LOGGED_BETS}
            onClick={(e) => navigate("/betting/asia_logged_bets")}
            className={
              location.pathname === "/betting/asia_logged_bets" ? "active" : ""
            }
          >
            {ASIA_LOGGED_BETS}
          </button>
        </BtnWrapper>

        <RightSide>
          {location.pathname === "/betting/asia_logged_bets" && (
            <CalculatedValuesWrapper>
              <CalculatedValueField
                title={"STAKE: "}
                value={retStakeSum}
                suffix={" "}
                customClass={"blueValue"}
              />
              <CalculatedValueField
                title={"RISK: "}
                value={riskSum}
                suffix={" "}
              />
              <CalculatedValueField
                title={"NO BETS: "}
                value={loggedBetsNumber}
                suffix={" "}
              />
              <CalculatedValueField
                title={"OU: "}
                value={loggedBetsOUGNumber}
                suffix={" "}
              />
              <CalculatedValueField
                title={"AH: "}
                value={loggedBetsHCPNumber}
                suffix={" "}
              />
              <CalculatedValueField
                title={"Model EV:"}
                value={modelEv}
                suffix={" £"}
              />
              <CalculatedValueField
                title={"Market EV:"}
                value={marketEv}
                suffix={" £"}
              />
            </CalculatedValuesWrapper>
          )}
          <LivePopup />
        </RightSide>
      </Main>
    </Container>
  );
};

export default BettingPageHeader;
const Container = styled.div`
  background: rgba(0, 18, 41, 0.85);
`;
const Main = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 14px;
  width: 100%;
  min-width: 1600px;
`;
const BtnWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;


  border-radius: 2px;
 
  button {
    display: flex;
    cursor: pointer;
    padding: 8px 11px;
    border: 1px solid white;
    margin: -1px;
    background: none;
    color: rgba(255, 255, 255, 0.4);
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    :first-child{
      border-right:none;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    :last-child{
      border-left:none;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
  .active {
    background: white;
    color: rgba(0, 18, 41, 1);
  }
`;
const LiveBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  border: none;
  color: #ec4848;
  column-gap: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  .issue {
    color: black;
  }
`;
const CalculatedValuesWrapper = styled.div`
  display: flex;
  column-gap: 6px;
`;
const CalcValueContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 3px;
  border-radius: 4px;
  padding: 0 6px;
  background: #425165;

  .blueValue {
    color: #57a7ff !important;
  }

  .numberFormatter {
    font-family: "Roboto";
    
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    color: #ffffff;
  }

  span {
    padding: 0;
    margin: 0;
    font-family: "Roboto";
    
    font-weight: 700;
    font-size: 9px;
    line-height: 14px;
    color: #a0b1c8;
  }
  p {
    font-family: "Roboto";
    
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #ffffff;
  }
`;
const RightSide = styled.div`
display:flex;
justify-content:end;
column-gap 10px;
`;
