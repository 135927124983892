import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { accept_invite } from "../../api/invites/AcceptInvite";
import { getTimeZoneInvite } from "../../api/invites/GetTimeZoneInvite";
import { notifyError, notifyDismiss, notifyLoading, notifyUpdate, notifySucces } from "../../helper/Generic/Notifications";
import { ReactComponent as SwanLogo } from "../../assets/icons/swanLogo.svg";
import { ReactComponent as EyeSvg } from "../../assets/icons/eye.svg";
import { ReactComponent as WrongSvg } from "../../assets/icons/wrong.svg";
import { ReactComponent as DropDownSvg } from "../../assets/icons/tzoneBtn.svg";
import { toast } from "react-toastify";


const Register = () => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [timeZone, setTimeZone] = useState("London");
  const [timeZones, setTimeZones] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordsLength, setPasswordsLength] = useState(true);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [ready, setReady] = useState(false);
  const toastId = useRef(null);

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };
  const handleItemClick = (e) => {
    setTimeZone(e);
    handleMenuOpen();
  };
  const handleInputName = (e) => {
    setName(e.target.value);
  };
  const handleInputPassword = (e) => {
    setPassword(e.target.value);
  };

  const handleInputPassword2 = (e) => {
    setPassword2(e.target.value);
  };
  const checkPasswordsMatch = () => {
    if (password === password2) {
      setPasswordsMatch(true);
      return true;
    } else {
      setPasswordsMatch(false);
      return false;
    }
  };
  const checkPasswordsLength = () => {
    if (password.length < 6 || password2.length < 6) {
      setPasswordsLength(false);
      return false;
    } else {
      setPasswordsLength(true);
      return true;
    }
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleCreateAccount();
    }
  };
  const handleCreateAccount = () => {
    checkPasswordsMatch();
    checkPasswordsLength();

    if (checkPasswordsMatch() && checkPasswordsLength() && name.length !== 0) {
      notifyDismiss(toastId);
      notifyLoading(toastId, "Creating account");

      accept_invite(
        password,
        searchParams.get("token"),
        name,
        timeZone,
        function () {
          notifyUpdate(toastId, "Account created", toast.TYPE.SUCCESS);
          navigate("/login");
        },
        function (error) {
          notifyError("Something wrong...");
        }
      );
    }
  };
  const checkFields = () => {
    if (
      password.length > 0 &&
      password2.length > 0 &&
      name.length > 0 &&
      timeZone !== null
    ) {
      setReady(false);
    } else {
      setReady(true);
    }
  };
  useEffect(() => {
    getTimeZoneInvite(
      searchParams.get("token"),
      function (response) {
        setTimeZones(response.data.timezones);
      },
      function () {
        notifyError("Could't get timezones");
      }
    );
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);
  useEffect(() => {
    checkFields();
  }, [password, password2, name, timeZone]);
  return (
    <Container>
      <Card onKeyDown={(e) => handleKeyDown(e)}>
        <Top>
          <SwanLogo />
          <h1>Register</h1>
        </Top>
        <InputWrapper>
          <label>Name</label>
          <input
            maxLength={50}
            autoComplete="false"
            type={"text"}
            placeholder={"Name"}
            value={name}
            onChange={(e) => handleInputName(e)}
          ></input>
        </InputWrapper>

        <InputWrapper>
          <label>Password</label>
          <div className="passwdInput">
            <input
              type={visible ? "text" : "password"}
              placeholder={"Password"}
              value={password}
              onChange={(e) => handleInputPassword(e)}
            ></input>
            <EyeSvg onClick={() => setVisible(!visible)} />
          </div>
        </InputWrapper>
        <InputWrapper>
          <label>Repeat Password</label>

          <div className="passwdInput">
            <input
              className={!passwordsMatch ? "wrong" : null}
              type={visible2 ? "text" : "password"}
              placeholder={"Repeat password"}
              value={password2}
              onChange={(e) => handleInputPassword2(e)}
            ></input>
            <EyeSvg onClick={() => setVisible2(!visible2)} />
          </div>

          {!passwordsMatch && (
            <label
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "5px",
                color: "#F11C0E",
              }}
            >
              <WrongSvg />
              Your passwords don’t match
            </label>
          )}
          {!passwordsLength && (
            <label
              style={{
                display: "flex",
                columnGap: "5px",
                alignItems: "center",
                color: "#F11C0E",
              }}
            >
              <WrongSvg />
              Your passwords must have minimum 6 characters
            </label>
          )}
        </InputWrapper>

        <InputWrapper>
          <label>Time Zone</label>
          <TimeZoneMenu ref={ref}>
            <p>{timeZone} </p>
            <DropDownSvg onClick={() => handleMenuOpen()} />

            {isMenuOpen && (
              <ListMenu>
                {timeZones &&
                  timeZones
                    .sort(function (a, b) {
                      if (a.toLowerCase() > b.toLowerCase()) return 1;
                      if (a.toLowerCase() < b.toLowerCase()) return -1;
                      return 0;
                    })
                    .map((item, index) => (
                      <TimeZoneItem
                        key={index}
                        onClick={() => handleItemClick(item)}
                      >
                        <p>{item}</p>
                      </TimeZoneItem>
                    ))}
              </ListMenu>
            )}
          </TimeZoneMenu>
        </InputWrapper>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button disabled={ready} onClick={() => handleCreateAccount()}>
            Create Account
          </button>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "left",
            gap: "5px",
          }}
        >
          <h2 style={{ color: "var(--darkBlue)", cursor: "default" }}>
            Already have an account?
          </h2>
          <h2 onClick={() => navigate("/login")}>Log in</h2>
        </div>
      </Card>
    </Container>
  );
};

export default Register;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(249, 249, 249, 1);
  height: var(--pageHeight);
  overflow: auto;
  min-height: 600px;
  min-width: 600px;
`;
const Card = styled.div`
  min-width: 500px;
  min-height: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 25px;
  background-color: white;
  padding: 70px 145px;
  border: 2px solid #f8f9fb;
  box-shadow: 0px 1px 8px rgba(46, 46, 46, 0.04);
  border-radius: 16px;
  overflow: hidden;
  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 42px;
    line-height: 56px;
  }
  h2 {
    margin-top: -10px;
    color: var(--primaryBlue);
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
  }

  button {
    padding: 15px 0;
    min-width: 180px;
    width: 100%;
    border-radius: 4px;
    border: none;
    background: var(--primaryBlue);
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    :disabled {
      cursor: inherit;
      opacity: 0.5;
    }
  }
  img {
    height: 58px;
    width: 58px;
  }
`;
const TimeZoneMenu = styled.li`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  border: 1px solid #d9dfe7;
  border-radius: 4px;
  padding: 16px 16px;
  p {
    color: var(--darkBlue);
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  svg {
    cursor: pointer;
  }
`;
const ListMenu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  z-index: 1;
  position: absolute;
  width: 100%;
  left: 0;
  top: 51px;
  border: 1px solid #d9dfe7;
  max-height: 160px;
  overflow: auto;
  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
const TimeZoneItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #d9dfe7;
  padding: 5px 16px;
  p {
    color: var(--darkBlue);
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  :last-child {
    border-bottom: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  min-width: 20vw;
  width: 100%;
  .passwdInput {
    display: flex;
    align-items: center;
    position: relative;
    svg {
      cursor: pointer;
      position: absolute;
      top: 18px;
      right: 18px;
      height: 12px;
      width: 16px;
    }
  }
  .wrong {
    border: 1px solid red;
    :focus-visible {
      outline: 0px;
      //outline-offset: 1px;
      border: 1px solid red;
    }
  }
  input {
    width: 100%;
    border: 1px solid #d9dfe7;
    border-radius: 4px;
    padding: 16px 40px 16px 16px;
    ::placeholder {
      color: #b3b9c0;
    }
    :focus-visible {
      outline: 0px;
      //outline-offset: 1px;
      border: 1px solid var(--primaryBlue);
    }
  }
  label {
    color: #001229;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
`;
