import React from 'react'
import styled from 'styled-components';

const EditLeagueSubmenuOption = ({ valuesList, value, setValue }) => {

    return (
        <Container>
            <MenuWrapper>
                {valuesList?.map((item, index) => (
                    <MenuItem key={item} onClick={() => setValue(item)} selected={item === value}>
                        <h1>{item}</h1>
                    </MenuItem>
                ))}

            </MenuWrapper>

        </Container>
    )
}

export default EditLeagueSubmenuOption
const Container = styled.div`
  display: flex;
  column-gap: 16px;
`;

const MenuWrapper = styled.div`
display:flex;
border-radius:2px;
overflow:hidden;
`
const MenuItem = styled.div`
background:${(props) => props.selected ? "rgba(0, 18, 41, 0.8)" : "rgba(0, 18, 41, 0.3)"};
padding:6px 12px;
cursor:pointer;
h1{
    font-family: Roboto;
font-size: 9px;
font-weight: 700;
line-height: 11px;
letter-spacing: 0.02em;
text-align: center;
color:white;
}
`