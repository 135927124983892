import React, { forwardRef, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerWithTime = ({ value, setValue, disabled }) => {
  const handleSelect = (date) => {
    setValue(date);
  };

  const CustomInput = forwardRef(({ value, onClick, disabled, bg }, ref) => (
    <CustomInputTimeReq onClick={onClick} ref={ref} disabled={disabled}>
      <p>{value}</p>
    </CustomInputTimeReq>
  ));

  return (
    <Container>
      <DatePicker
        disabled={disabled}
        selected={value}
        onChange={(date) => handleSelect(date)}
        showTimeInput
        dateFormat="yyyy-MM-dd HH:mm"
        customInput={<CustomInput />}
      />
    </Container>
  );
};

export default DatePickerWithTime;

const Container = styled.div`
  display: flex;
  position: relative;
  .react-datepicker-wrapper {
    display: flex;
    align-items: center;
  }
`;
const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  width: 100%;
  .positive-color {
    color: #23a760;
  }
  .negative-color {
    color: #ec4848;
  }
  p {
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }
  .numberFormatter {
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }
  button {
    cursor: pointer;
    background: none;
    border: none;
  }
`;
const CustomInputTimeReq = styled(Cell)`
display:flex;
align-items:center;
min-height:21px;
padding:0 5px;

border: ${(props) => (props.disabled ? "" : "0.7px solid #cbd7f5")};
border-radius: 2px;
p {
  max-width:60px;
text-align:center;
font-weight:500;
font-size:8px;
line-height:150%;
text-transform:uppercase;
}
`;
