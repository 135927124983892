import { POST } from "../APiHelper/API";
import { formatted_date_for_backend } from "../../helper/Generic/dateTimeFormat";

let source = null;
export const getCurrentUserBets = (
  country_ids,
  start_time,
  end_time,
  competition_ids,
  term,
  tiers,
  list_id,
  priority,
  onSuccess,
  onError
) => {
  const data = {
    country_ids: country_ids,
    start_time: formatted_date_for_backend(start_time),
    end_time: formatted_date_for_backend(end_time),
    competition_ids: competition_ids,
    term: term,
    tiers: tiers,
    list_id,
    priority,
  };

  source = POST("/api/v1/bets/", data, onSuccess, onError, true, source);
};
