import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";
import { ReactComponent as SearchSvg } from "../../../assets/icons/PricingMenuIcons/search.svg";


const SingleOptionMenu = ({ label, dataList, selectedValue, setSelectedValue, changeMenuValue, disabled }) => {

  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState("");



  const handleSearchInput = (e) => {
    setInputSearch(e.target.value);
  };


  const filterSearch = (items, filter) => {
    if (filter === "") {
      return items;
    } else {
      return items.filter((item) =>
        item.name?.toLowerCase().includes(filter.toLowerCase()) ||
        item.first_name?.toLowerCase().includes(filter.toLowerCase()) ||
        item.last_name?.toLowerCase().includes(filter.toLowerCase()) ||
        item.middle_name?.toLowerCase().includes(filter.toLowerCase())
      );
    }
  };

  function getFullName(first_name, middle_name, last_name) {
    // Create an array of the name parts
    const nameParts = [first_name, middle_name, last_name];

    // Filter out empty or null values
    const filteredNameParts = nameParts.filter(part => part && part.trim() !== '');

    // Join the remaining parts with a space
    return filteredNameParts.join(' ');
  }

  const mapName = (referee) => {
    let name = ""
    if (referee.name === null) {
      name = getFullName(referee.first_name, referee.mmiddle_name, referee.last_name)
    } else {
      name = referee.name
    }
    return name;
  }



  const handleOnClick = (item) => {
    changeMenuValue(setSelectedValue, item);
    handleMenuOpen();
  };

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
        setInputSearch("");
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref}>
      <DropDownMenu onClick={() => !disabled && handleMenuOpen()} isMenuOpen={isMenuOpen}>
        {selectedValue ? (<p>{mapName(selectedValue)}</p>) : (<p>{label}</p>)}
        {isMenuOpen ? <UpSvg /> : (!disabled && <DownSvg />)}
      </DropDownMenu>

      {isMenuOpen && (
        <DropDownList>
          <DropDownMenuItem>
            <div className="searchGroup">
              <SearchSvg />
              <input
                type={"text"}
                value={inputSearch}
                onChange={handleSearchInput}
                placeholder="Search"
              ></input>
            </div>
          </DropDownMenuItem>
          <DropDownMenuItem onClick={() => handleOnClick(null)}>
            <input
              readOnly
              onChange={() => handleOnClick(null)}
              className="check"
              type="radio"
              checked={selectedValue == null}
              value={null}
            ></input>
            <p>No referee</p>
          </DropDownMenuItem>
          {inputSearch != "" ? (
            filterSearch(dataList, inputSearch)?.map((item, index) => (
              <DropDownMenuItem key={index} onClick={() => handleOnClick(item)}>
                <input
                  key={index}
                  readOnly
                  onChange={() => handleOnClick(item)}
                  className="check"
                  type="radio"
                  checked={selectedValue == item}
                  value={item}
                ></input>
                <p> {item.wy_id ? mapName(item) + " " + `(` + item.wy_id + `)` : mapName(item)}</p>
              </DropDownMenuItem>
            ))

          )
            :

            (dataList?.map((item, index) => (
              <DropDownMenuItem key={index} onClick={() => handleOnClick(item)}>
                <input
                  key={index}
                  readOnly
                  onChange={() => handleOnClick(item)}
                  className="check"
                  type="radio"
                  checked={selectedValue == item}
                  value={item}
                ></input>
                <p> {item.wy_id ? mapName(item) + " " + `(` + item.wy_id + `)` : mapName(item)}</p>
              </DropDownMenuItem>
            )))}

        </DropDownList>
      )}
    </Container>
  );
};


export default SingleOptionMenu;
const Container = styled.div`
  display: flex;
          flex-direction: column;
          width: 180px;
          position: relative;
          cursor:pointer;
          p {
            font - weight: 700;
          font-size: 10px;
          line-height: 20px;
          color: rgba(60, 74, 92, 1);
  }
          `;
const DropDownMenu = styled.div`
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: ${({ theme }) => theme.matchPreview.selectMenuBg};
          padding: 12px 16px;
          border: 1px solid #BAC3E8;
          border-radius: 6px;
          border-radius: ${(props) => props.isMenuOpen ? "8px 8px 0 0" : ""} ;
overflow:hidden;
          p{
            width:100%;
            white-space:pre;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
    }
          svg {
            cursor: pointer;
          fill: ${({ theme }) => theme.matchPreview.selectMenuSvgBg};
          stroke: ${({ theme }) => theme.matchPreview.selectMenuSvgColor};
    }
          `;

const DropDownList = styled.ul`
          position: absolute;
          z-index: 2;
          top: 40px;
          border-top: 1px solid black !important;
          border-color: #3c4a5c;
          border: 1px solid #BAC3E8;
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          border-radius: 0 0 8px 8px;
          max-height: 400px;
          width: 180px;
          overflow-x: hidden;
          color: black;

          .searchGroup {
            position: relative;
          display: flex;
          align-items: center;
          color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
          width:100%;

          input {
            align - items: center;
          position: relative;
          width: 100%;
          padding: 5px 10px 5px 20px;
          background-color: ${({ theme }) => theme.matchPreview.selectMenuBg};
          border: none;
          border: 1px solid #d9dfe7;
          border-radius: 8px;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          :focus-visible {
            outline: none;
          border: 1px solid #006fff;
       
      }
          ::placeholder{
            color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
    }
      
    }
          svg {
            position: absolute;
          z-index: 1;
          top: 7px;
          left: 7px;
    }
  }
          `;
const DropDownMenuItem = styled.li`
          column-gap: 5px;
          align-items: center;
          display: flex;
          cursor: pointer;
          :last-child {
            border - bottom - left - radius: 8px;
          border-bottom-right-radius: 8px;
  }
          list-style-type: none;
          background-color: ${({ theme }) => theme.matchPreview.selectMenuBg};
          padding: 5px 10px;
          .check {
            height:10px;
  }
          p {
            font - family: 'Roboto';
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
  }

          `;
