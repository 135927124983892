import moment from "moment";
import React from "react";
import styled from "styled-components";
import { format_time_to_timezone, same_time_in_timezone } from "../../helper/Generic/dateTimeFormat";
import { mapRole } from "../../helper/Generic/mapUserRole";
import DeleteConfirmation from "../DeleteConfirmation";

const NoteElement = ({ message, user, handleDeleteMessage }) => {
  return (
    <Container>
      <UserLine>
        <h1>{message.user.name}</h1>
        {/* {user.id === message.user.id && <DeleteConfirmation variant={"chat"} handleRemoveItem={handleDeleteMessage} item={message} />} */}
        <DeleteConfirmation variant={"chat"} handleRemoveItem={handleDeleteMessage} item={message} />
      </UserLine>
      <UserDetails>
        <p className="role">{mapRole(message.user.roles[0])}</p>
        <p>{format_time_to_timezone(message.created_at, user.timezone).format("DD/MM/YYYY HH:mm")}</p>
      </UserDetails>
      <NoteContent>
        <p>{message.message}</p>
      </NoteContent>
    </Container >
  );
};

export default NoteElement;
const Container = styled.div`
  display:flex;
  flex-direction:column;
position:relative;
  h1 {
    color: ${({ theme }) => theme.notesTextColor};
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
  }
`;
const UserLine = styled.div`
display:flex;
justify-content:space-between;
h1 {
  color: ${({ theme }) => theme.notesTextColor};
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
}
`
const UserDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items:center;
  margin-bottom: 8px;
  .role {
    text-transform:capitalize;
    padding: 4px 5px;
    background-color: #ffe9f6;
    color: #b1086e;
    border-radius: 6px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.02em;
    opacity: 0.8;
  }
  p {
    color: ${({ theme }) => theme.notesTextColor};
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
  
  }
`;
const NoteContent = styled.div`

position:relative;
  p {
    display:flex;
    width:fit-content;
    word-wrap: break-word; 
    word-break: break-word;
    heigh:auto;
    color: ${({ theme }) => theme.notesTextColor};
    background-color: ${({ theme }) => theme.notesBG};
    font-weight: 400;
    font-size: 10px;
    line-height: 140%;
    border-radius: 0px 10px 10px 10px;
    padding: 10px 15px;
    resize: none;
    border:none;
  }
`;
