import { DELETE } from "../../APiHelper/API";

export const removePLayerFightForPositionAPI = (playerInGroupId, onSuccess, onError) => {
    let source;
    // const data = {
    //     squad_id: squad_id,
    //     selection_id: selection_id,
    //     position: position,
    // }
    source = DELETE(`/api/v1/squads/remove_player_fights_for_position/${playerInGroupId}`, onSuccess, onError, true, source)
}