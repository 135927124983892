import { PATCH } from "../APiHelper/API";

export const updateSquadsForGamePartial = (
    data,
    onSuccess,
    onError
) => {

    PATCH(`/api/v1/games/partial_squads`, data, onSuccess, onError);
};
