import { POST } from "../APiHelper/API";

export const updateGameFactorsPartial = (
  // fixture_id,
  // league_id,
  // game_factors,
  // team_stats,
  data,
  onSuccess,
  onError
) => {
  // const data = {
  //   fixture_id: fixture_id,
  //   league_id: league_id,
  //   game_factors: game_factors,
  //   team_stats: team_stats,
  // };

  POST(`/api/v1/games/partial_update_factors`, data, onSuccess, onError);
};
