import cloneDeep from "lodash/cloneDeep";

export const buildNewStateForPricingPrices = (oldPricesList, newPricesList) => {
  const oldPrices = cloneDeep(oldPricesList);
  const newPrices = cloneDeep(newPricesList);

  const oldPricesFixturesIds = oldPrices.map((price) => price.fixture_id);
  const newPricesFixturesIds = newPrices.map((price) => price.fixture_id);

  const allFixturesIds = oldPricesFixturesIds.concat(newPricesFixturesIds);

  const uniqueFixtureIds = [...new Set(allFixturesIds)];

  let newListOfPrices = [];

  uniqueFixtureIds.forEach((fixtureID) => {
    const oldFixtureData = oldPrices.find((obj) => {
      return obj.fixture_id === fixtureID;
    });

    const newFixtureData = newPrices.find((obj) => {
      return obj.fixture_id === fixtureID;
    });

    const newElement = buildNewElementFromPricingData(
      oldFixtureData,
      newFixtureData
    );

    if (newElement) {
      newListOfPrices.push(newElement);
    }
  });

  return newListOfPrices;
};

const buildNewElementFromPricingData = (oldData, newData) => {
  // If one of them is null return the other
  if (!oldData) {
    return newData;
  }

  if (!newData) {
    return oldData;
  }

  let newElement = {};
  newElement.fixture_id = oldData.fixture_id;

  // model price
  const new_model_pricing = buildNewModelPrice(oldData, newData);
  newElement.model_price = new_model_pricing;

  // model price
  const new_market_pricing = buildNewMarketPrice(oldData, newData);
  newElement.market_price = new_market_pricing;

  return newElement;
};

const buildNewMarketPrice = (oldData, newData) => {
  // If one of them is null return the other
  if (!oldData.market_price) {
    return newData.market_price;
  }

  if (!newData.market_price) {
    return oldData.market_price;
  }

  const oldMarket = oldData.market_price;
  const newMarket = newData.market_price;

  let returnValue = cloneDeep(oldMarket);

  // SUP TGX
  if (newMarket.sup) {
    returnValue.sup = newMarket.sup;
  }

  if (newMarket.tgx) {
    returnValue.tgx = newMarket.tgx;
  }

  // PRICES
  const newPrices = buildNewPricesArray(oldMarket.prices, newMarket.prices);
  returnValue.prices = newPrices;

  return returnValue;
};

const buildNewModelPrice = (oldData, newData) => {
  // If one of them is null return the other
  if (!oldData.model_price) {
    return newData.model_price;
  }

  if (!newData.model_price) {
    return oldData.model_price;
  }

  const oldModel = oldData.model_price;
  const newModel = newData.model_price;

  let returnValue = cloneDeep(oldModel);

  // HA HD AA AD SUP TGX
  if (newModel.ha) {
    returnValue.ha = newModel.ha;
  }

  if (newModel.hd) {
    returnValue.hd = newModel.hd;
  }

  if (newModel.aa) {
    returnValue.aa = newModel.aa;
  }

  if (newModel.ad) {
    returnValue.ad = newModel.ad;
  }

  if (newModel.sup) {
    returnValue.sup = newModel.sup;
  }

  if (newModel.tgx) {
    returnValue.tgx = newModel.tgx;
  }

  // PRICES
  const newPrices = buildNewPricesArray(oldModel.prices, newModel.prices);
  returnValue.prices = newPrices;

  return returnValue;
};

const buildNewPricesArray = (oldPrices, newPrices) => {
  // If one of them is null return the other
  if (!oldPrices) {
    return newPrices;
  }

  if (!newPrices) {
    return oldPrices;
  }

  // Replace the old values with new ones if needed
  var pricesToReturn = [];
  oldPrices.forEach((oldPrice) => {
    const newPrice = newPrices.find((obj) => {
      return (
        obj.name === oldPrice.name &&
        obj.bookmaker_id == oldPrice.bookmaker_id &&
        obj.value == oldPrice.value
      );
    });

    if (newPrice) {
      pricesToReturn.push(newPrice);
    } else {
      pricesToReturn.push(oldPrice);
    }
  });

  // Add all new values
  newPrices.forEach((newPrice) => {
    const oldPrice = oldPrices.find((obj) => {
      return (
        obj.name === newPrice.name &&
        obj.bookmaker_id == newPrice.bookmaker_id &&
        obj.value == newPrice.value
      );
    });

    // We could not find a value for old prices
    if (!oldPrice) {
      pricesToReturn.push(newPrice);
    }
  });

  return pricesToReturn;
};
