import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CopyButtonSvg } from "../../../assets/icons/BettingIcons/duplicate.svg";
import { ReactComponent as NoteButtonSvg } from "../../../assets/icons/BettingIcons/notePencil.svg";
import { ReactComponent as EditBtn } from "../../../assets/icons/BettingIcons/editBtnsvg.svg";
import { ReactComponent as Check } from "../../../assets/icons/BettingIcons/checkSaveBtn.svg";
import { ReactComponent as SaveBtn } from "../../../assets/icons/BettingIcons/saveBtn.svg";
import BrokerMenu from "../Menu/BrokerMenu";

import { elementForBrokerName } from "../../../helper/Values/brokerList";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

import { useLoggedBetsService } from "../../../context/useLoggedBetsService";
import {
  notifyLoading,
  notifySucces,
  notifyUpdate,
} from "../../../helper/Generic/Notifications";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import { selectUser } from "../../../Redux/user/user";
import {
  formatted_date_for_backend,
  format_time_to_timezone,
} from "../../../helper/Generic/dateTimeFormat";
import { copyToClipBoard } from "../../../helper/Generic/CopyInfo";
import { checkFlag, getCountryCode } from "../../../helper/Generic/GetFlag";
import { Switch } from "@mui/material";
import { NumericFormat } from "react-number-format";
import DatePickerWithTime from "../Menu/DatePickerWithTime";

const LoggedBetsElement = ({
  indexbg,
  data,
  handleNote,
  reloadData,
  brokers,
}) => {
  let bet = data.bet;
  let loggedBet = data.logged_bet;
  let tier = data.tier;

  const user = useSelector(selectUser);
  const timezonedBetStartTime = format_time_to_timezone(
    bet.start_time,
    user.timezone
  );
  const timezonedRequestedTime = format_time_to_timezone(
    loggedBet.time_req,
    user.timezone
  );
  const toastId = useRef(null);
  const { editLBet, getLBets, editLoggedBetAbnormalValue } =
    useLoggedBetsService();
  const [edit, setEdit] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [isReadyToSave, setIsReadyToSave] = useState(true);
  const [isIncorrect, setIsIncorrect] = useState(loggedBet.incorrect);

  const [abnormal, setAbnormal] = useState(loggedBet.abnormal);
  const [abnormalChanged, setAbnormalChanged] = useState(false);

  const [broker, setBroker] = useState(
    elementForBrokerName(brokers, data.logged_bet.broker)
  );
  const [timeRet, setTimeRet] = useState(
    format_time_to_timezone(loggedBet.time_ret, user.timezone)
  );
  const [countryCode, setCountryCode] = useState(null);
  const [timeReq, setTimeReq] = useState(timezonedRequestedTime.toDate());

  const values = useRef({
    returned_stake: {
      value: loggedBet.ret_stake,
      floatValue: loggedBet.ret_stake,
      formattedValue: loggedBet.ret_stake,
    },
    confirmed_price: {
      value: loggedBet.confirmed_price,
      floatValue: loggedBet.confirmed_price,
      formattedValue: loggedBet.confirmed_price,
    },
    incorrect: loggedBet.incorrect,
  });

  const onTimeChange = (timeRet) => {
    setTimeRet(timeRet);
  };

  const handle_copy = () => {
    copyToClipBoard(
      bet.home_team,
      bet.away_team,
      bet.competition_name,
      bet.selection,
      bet.min_price,
      bet.country_name
    );
  };
  const handleChange = (e) => {
    const { name, checked } = e.target;
    setIsIncorrect(checked);
    checkReadyToSave();
  };

  const handleAbnormalChange = (e) => {
    const { name, checked } = e.target;
    setAbnormal(checked);
  };

  const handleInputChange = (event, val) => {
    if (event.event != undefined) {
      const name = event.event.target.name;
      const value = val;
      values.current[name] = value;
      checkReadyToSave();
    }
  };
  const closeNote = () => {
    setShowNotes(false);
  };
  const checkIfTrue = (value) => {
    if (value === true) {
      return "YES";
    } else {
      return "NO";
    }
  };
  const validatePosNumber = (value) => {
    if (!isNaN(value) === true) {
      if (parseFloat(value) > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkReadyToSave = () => {
    let isRetStakeValid = validatePosNumber(
      values.current.returned_stake.value
    );
    let isConfirmedPriceValid = validatePosNumber(
      values.current.confirmed_price.value
    );
    if (isRetStakeValid && isConfirmedPriceValid && timeRet !== null) {
      setIsReadyToSave(true);
    } else {
      setIsReadyToSave(false);
    }
  };

  const editLoggedBet = () => {
    notifyLoading(toastId, "Editing...");
    let time_to_send = timeRet.toDate();
    editLBet(
      data.logged_bet.id,
      broker.name,
      values.current.returned_stake.value,
      values.current.confirmed_price.value,
      time_to_send,
      timeReq,
      isIncorrect,
      function (response) {
        notifyUpdate(toastId, "Edited", toast.TYPE.SUCCESS);

        setEdit(false);
        reloadData();
      },
      function () {
        notifyUpdate(toastId, "Failed to edit", toast.TYPE.ERROR);
      }
    );
  };

  const checkAbnormalChange = () => {
    if (abnormal != loggedBet.abnormal) {
      setAbnormalChanged(true);
    } else {
      setAbnormalChanged(false);
      values.current.confirmed_price = {
        value: loggedBet.confirmed_price,
        floatValue: loggedBet.confirmed_price,
        formattedValue: loggedBet.confirmed_price,
      };
      values.current.returned_stake = {
        value: loggedBet.ret_stake,
        floatValue: loggedBet.ret_stake,
        formattedValue: loggedBet.ret_stake,
      };
    }
  };

  const editAbnormal = () => {
    notifyLoading(toastId, "Editing...");
    editLoggedBetAbnormalValue(
      data.logged_bet.id,
      values.current.returned_stake.value,
      values.current.confirmed_price.value,
      abnormal,
      function (response) {
        notifyUpdate(toastId, "Abnormal edited", toast.TYPE.SUCCESS);
        setAbnormalChanged(false);
        //setAbnormal(response.data.item.abnormal);
        reloadData();
      },
      function (error) {
        notifyUpdate(toastId, "Failed to edit", toast.TYPE.ERROR);
      }
    );
  };

  const handleSave = () => {
    if (edit) {
      editLoggedBet();
    }
    if (abnormalChanged) {
      editAbnormal();
    }
  };

  const handleEdit = () => {
    setAbnormalChanged(false);
    setAbnormal(loggedBet.abnormal);
    setEdit(true);
  };

  const showReset = () => {
    return (
      <TimeResetBtn
        onClick={() =>
          setTimeRet(format_time_to_timezone(new Date(), user.timezone))
        }
      >
        Use current time
      </TimeResetBtn>
    );
  };

  useEffect(() => {
    checkReadyToSave();
  }, [timeRet]);

  useEffect(() => {
    getCountryCode(bet, setCountryCode);
  }, [bet]);

  useEffect(() => {
    checkAbnormalChange();
  }, [abnormal]);

  return (
    <Container indexbg={indexbg}>
      <ButtonCell>
        <button onClick={() => handle_copy()}>
          <CopyButtonSvg />
        </button>
      </ButtonCell>
      <BrokerCell>
        {edit ? (
          <BrokerMenu brokers={brokers} setBroker={setBroker} broker={broker} />
        ) : (
          <p>{broker.name}</p>
        )}
      </BrokerCell>
      <TierCell>
        <Tiercomponent>
          <h2>{bet.tier}</h2>
        </Tiercomponent>
      </TierCell>
      <GameCell>
        <h2>
          {bet.home_team} - {bet.away_team}
        </h2>
        <div>
          <img src={checkFlag(countryCode)} alt="" />
          <p>{bet.competition_name}</p>
        </div>
      </GameCell>
      <SelectionCell>
        <p>{bet.selection}</p>
      </SelectionCell>
      <Cell style={{ minWidth: "50px" }}>
        <p>{bet.min_price.toFixed(2)}</p>
      </Cell>

      <ConPriceCell>
        {edit || abnormalChanged ? (
          <NumericFormat
            value={
              values.current.confirmed_price.formattedValue
              // values.current.confirmed_price.formattedValue != undefined
              //   ? values.current.confirmed_price.formattedValue
              //   : values.current.confirmed_price.value
            }
            name="confirmed_price"
            onValueChange={(values, event) => handleInputChange(event, values)}
            thousandSeparator=","
          />
        ) : (
          <NumericFormat
            value={parseFloat(
              values.current.confirmed_price.formattedValue
            ).toFixed(2)}
            displayType="text"
            thousandSeparator=","
            className="numberFormatter"
          />
        )}
      </ConPriceCell>
      <ReturnedStakeCell>
        {edit || abnormalChanged ? (
          <NumericFormat
            value={
              values.current.returned_stake.formattedValue
              // values.current.returned_stake.formattedValue != undefined
              //   ? values.current.returned_stake.formattedValue
              //   : values.current.returned_stake.value
            }
            name="returned_stake"
            onValueChange={(values, event) => handleInputChange(event, values)}
            suffix={" " + broker.currencySymbol}
            thousandSeparator=","
          />
        ) : (
          <NumericFormat
            value={values.current.returned_stake.formattedValue}
            displayType="text"
            suffix={" " + broker.currencySymbol}
            thousandSeparator=","
            className="numberFormatter"
          />
        )}
      </ReturnedStakeCell>
      <Cell>
        <p className={bet.value > 0 ? "positive-color" : "negative-color"}>
          {bet.value != null ? `${bet.value.toFixed(2)}%` : ""}
        </p>
      </Cell>
      <MktCell>
        <p>{bet.mkt_value != undefined ? bet.mkt_value.toFixed(2) : ""}</p>
      </MktCell>

      <Cell>
        <p className={bet.live_value > 0 ? "positive-color" : "negative-color"}>
          {bet.live_value != null ? `${bet.live_value.toFixed(2)}%` : ""}
        </p>
      </Cell>
      <TimeRequestCell>
        <DatePickerWithTime
          value={timeReq}
          setValue={setTimeReq}
          disabled={!edit}
        />
        {/* <p>{requestedDate}</p>
        <p>{requestedTime}</p> */}
      </TimeRequestCell>
      <TimeReturnedCell>
        {edit ? (
          <TimePicker
            value={timeRet}
            onChange={onTimeChange}
            showSecond={false}
            addon={() => showReset()}
          />
        ) : (
          <>
            <p>{`${timeRet.format("YYYY-MM-DD")}`}</p>
            <p>{`${timeRet.format("HH")}:${timeRet.format("mm")}`}</p>
          </>
        )}
      </TimeReturnedCell>

      <Cell>
        {loggedBet.liquidity !== null ? (
          <NumericFormat
            value={loggedBet.liquidity}
            displayType="text"
            suffix={" " + broker.currencySymbol}
            thousandSeparator=","
            className="numberFormatter"
          />
        ) : (
          ""
        )}
      </Cell>
      <Cell>
        <p>{tier.competition_tire}</p>
      </Cell>
      <BtnCell>
        <p>{checkIfTrue(loggedBet.clash)}</p>
      </BtnCell>
      <BtnCell>
        <p>{checkIfTrue(loggedBet.oppo)}</p>
      </BtnCell>

      <HighlitedCell style={{ minWidth: "70px" }}>
        <p>{loggedBet.no_return == false ? "Logged" : "No Return"}</p>
      </HighlitedCell>
      <IncorrectBtnCell>
        <Switch
          disabled={!edit}
          size="small"
          name="incorrect"
          checked={isIncorrect}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </IncorrectBtnCell>
      <AbnormalBtnCell>
        <Switch
          disabled={edit}
          size="small"
          name="abnormal"
          checked={abnormal}
          onChange={handleAbnormalChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </AbnormalBtnCell>
      <NotesBtn>
        <button onClick={() => handleNote(bet)}>
          <NoteButtonSvg />
        </button>
      </NotesBtn>
      <LogBtn>
        {edit == true || abnormalChanged == true ? (
          <SaveButton disabled={!isReadyToSave} onClick={() => handleSave()}>
            <Check /> Save
          </SaveButton>
        ) : (
          <EditBtn onClick={() => handleEdit()} />
        )}
      </LogBtn>
    </Container>
  );
};

export default LoggedBetsElement;
const Container = styled.div`
  display: flex;
  padding: 6px 14px;
  background: ${(props) =>
    props.indexbg % 2
      ? ({ theme }) => theme.matchElementBgindexL
      : ({ theme }) => theme.matchElementBg};
  color: ${({ theme }) => theme.leagueColor};
  color: rgba(58, 57, 87, 1);
  column-gap: 9px;
  align-items: center;

  .right {
    width: 100%;
    display: flex;
    column-gap: 14px;
  }
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  width: 100%;
  .positive-color {
    color: #23a760;
  }
  .negative-color {
    color: #ec4848;
  }
  p {
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }
  .numberFormatter {
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }
`;
const HighlitedCell = styled(Cell)`
  min-height: 40px;

  p {
    background-color: ${({ theme }) => theme.headerBG};
    color: ${({ theme }) => theme.leagueColor};
    padding: 3px 7px;
    border-radius: 4px;
  }
`;
const ButtonCell = styled(Cell)`
  min-width: 30px;
  max-width: 40px;
  cursor: pointer;
  svg {
    fill: ${({ theme }) => theme.matchElementBg};
  }
  button {
    cursor: pointer;
    background: transparent;
    border: none;
  }
  svg {
    height: 20px;
    width: 20px;
  }
`;
const TimeCell = styled(Cell)`
  justify-content: start;
  flex-direction: column;
  width: 75px;
  p {
    color: var(--elementText2);
  }
`;
const MktCell = styled(Cell)`
  min-width: 40px;
`;
const GameCell = styled(Cell)`
  flex-direction: column;
  align-items: flex-start;
  min-width: 150px;
  row-gap: 2px;
  h2 {
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    color: ${({ theme }) => theme.bettTextColor};
  }
  div {
    background: ${({ theme }) => theme.gameCompetitionBg};
    border-radius: 2px;
    column-gap: 3px;
    padding: 2px 3px;
    p {
      font-weight: 400;
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      color: ${({ theme }) => theme.leagueColor};
    }
    display: flex;
    flex-direction: row;
    img {
      height: 10px;
      width: 13px;
    }
  }
`;
const SelectionCell = styled(Cell)`
  min-width: 100px;
  justify-content: left;
  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};
  }
`;

const BooksCell = styled(Cell)`
  min-width: 85px;
  justify-content: start;
  p {
    overflow: hidden;
    word-wrap: break-word;
    font-weight: 400;
    font-size: 9px;
    line-height: 150%;
    text-transform: uppercase;
    color: ${({ theme }) => theme.leagueColor};
  }
`;
const TimeRequestCell = styled(Cell)`
  flex-direction: column;
  align-items: center;
  min-width: 50px;
  p {
    font-weight: 500;
    font-size: 8px;
    line-height: 150%;
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};
  }
`;
const ReturnedStakeCell = styled(Cell)`
  min-width: 60px;
  p {
    padding: 4px 9px;
  }
  input {
    color: ${({ theme }) => theme.bettTextColor};
    background: ${({ theme }) => theme.matchElementBg};
    :focus-visible {
      outline: none;
    }
    cursor: text;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    width: 100%;
    border: 0.7px solid #cbd7f5;
    text-align: center;
    border-radius: 2px;
    padding: 4px 9px;
  }
`;
const ConPriceCell = styled(Cell)`
  min-width: 51px;
  input {
    color: ${({ theme }) => theme.bettTextColor};
    background: ${({ theme }) => theme.matchElementBg};
    :focus-visible {
      outline: none;
    }
    cursor: text;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    width: 100%;
    border: 0.7px solid #cbd7f5;
    text-align: center;
    border-radius: 2px;
    padding: 4px 9px;
  }
`;
const BtnCell = styled(Cell)`
  min-width: 40px;
  input {
    width: 100%;
  }
`;
const IncorrectBtnCell = styled(BtnCell)`
  min-width: 60px;
`;
const AbnormalBtnCell = styled(BtnCell)`
  min-width: 60px;
`;
const TimeReturnedCell = styled(Cell)`
  flex-direction: column;
  min-width: 60px;
  align-items: center;
  justify-content: center;
  .timePickerStyle {
    width: 100%;
    background: white;
    width: 60px;
    padding: 3px 10px;
    border: 1px solid #cbd7f5;
    border-radius: 2px;
    font-family: "Roboto";
    text-align: center;
    
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #3a3957;
  }
`;
const LogBtn = styled(Cell)`
  min-width: 40px;

  svg {
    cursor: pointer;
  }
`;
const NotesBtn = styled(Cell)`
  min-width: 50px;
  button {
    background: transparent;
    border: none;
  }
  svg {
    cursor: pointer;
    fill: ${({ theme }) => theme.matchElementBg};
  }
`;
const BrokerCell = styled(Cell)`
  min-width: 70px;
`;

const NoteWrapper = styled.div`
  @media screen and (max-width: 1820px) {
    position: absolute;
    right: 10px;
    height: 100%;
  }
`;
const SaveButton = styled.button`
  display: flex;
  column-gap: 5px;
  align-items: center;
  color: white;
  background: var(--primaryBlue);
  padding: 3px 4px;
  border-radius: 4px;
  font-family: "Roboto";
  
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  cursor: pointer;
  border: 1px solid var(--primaryBlue);
  :disabled {
    cursor: inherit;
    color: var(--primaryBlue);
    background: white;
  }
  svg {
    fill: ${({ theme }) => theme.matchElementBg};
  }
`;
const TimeResetBtn = styled.button`
  background: var(--primaryBlue);
  color: white;
  border: none;
  width: 100%;
  padding: 10px 5px;
  font-family: "Roboto";
  
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
`;

const TierCell = styled(Cell)`
  display: flex;
  align-items: ceter;
  justify-content: center;
  min-width: 30px;
  max-width: 30px;
`;
const Tiercomponent = styled.div`
  display: flex;
  align-items: ceter;
  justify-content: center;
  background: #ffffff;
  border: 0.7px solid #cbd7f5;
  border-radius: 3px;
  height: 19px;
  width: 20px;
  h2 {
    
    font-weight: 400;
    font-size: 9px;
    line-height: 19px;
    color: rgba(0, 18, 41, 0.7);
  }
`;
