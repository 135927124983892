import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownSvg } from "../../../assets/icons/downSimpleArrow.svg";
import { StatusValues } from "../../../helper/Values/StatusList";
import { parseStatusValue } from "../../../helper/Analyst/SquadManager/parseStatusValue";
import { checkColor } from "../../../helper/Analyst/SquadManager/checkStatusColor";
const StatusMenu = ({ selectedValue, setSelectedValue, width }) => {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  const handleCheck = (item) => {
    setSelectedValue(item.value);
    handleMenuOpen();
  };


  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref} width={width}>
      <DropDownMenu
        onClick={() => handleMenuOpen()}
        isMenuOpen={isMenuOpen}
        width={width}
      >

        {selectedValue != undefined ? (
          <StatusWrapper className={checkColor(selectedValue)}>{parseStatusValue(selectedValue)}</StatusWrapper>
        ) : (
          <p>-</p>
        )}
        {isMenuOpen ? <DownSvg style={{ rotate: "180deg" }} /> : <DownSvg />}
      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>
          {StatusValues.map((item, index) => (
            <DropDownMenuItem key={index} onClick={() => handleCheck(item)} className={item === selectedValue ? "active" : "nonActive"}>
              <input
                key={index}
                readOnly
                onChange={() => handleCheck(item)}
                type="radio"
                className="check"
                checked={selectedValue == item.value}
                value={item}
              ></input>
              <div className={checkColor(item.value)}>{parseStatusValue(item.value)}</div>
            </DropDownMenuItem>
          ))}
        </DropDownList>
      )}
    </Container>
  );
};

export default StatusMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width != null ? props.width : "63px")};
  position: relative;
  .injured {
    p {
     color: ${({ theme }) => theme.statusColor.injured.p};
  
  }
  };
  .available { 
    p {
      color: ${({ theme }) => theme.statusColor.availableGreen.p};
  }
  };
  .bench {
    p {
     color: ${({ theme }) => theme.statusColor.bench.p};
    }
  };
  .unavailable {
    p {
     color: ${({ theme }) => theme.statusColor.unavailable.p};
    }
  };
  .rested { 
    p {
    color: ${({ theme }) => theme.statusColor.rested.p};
  }
  };
  cursor:pointer;
`;
const DropDownMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 3px 3px;
  border: 1px solid #cbd7f5;
  border-radius: 2px;
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 150%;
    color: rgba(0, 18, 41, 0.8);
  }
  svg{
    color:#006fff;
  }
`;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top:19px;
  border-left:1px solid;
  border-bottom:1px solid;
  border-right:1px solid;
  border-color: #CBD7F5;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 4px 4px;
  max-height: 400px;
  width:100%;
  overflow-x: hidden;
  color: black;

`;
const DropDownMenuItem = styled.li`
  cursor: pointer;
  :last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  :first-child{
    border-top: 1px solid black;
  }
  list-style-type: none;
  background-color: #ffffff;
  padding: 3px 3px;
  display: flex;
  align-items: center;
 flex-direction: row;
 column-gap:2px;
  .check{
    height:10px;
  }
  
  p {
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    color: rgba(0, 18, 41, 0.8);
  }
  
`;

const StatusWrapper = styled.div`

`