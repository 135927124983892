import WSDWebSocket from "./WSDWebSocket";

class WebSocketHandler {
  static CHANNEL_NAMES = [
    "PricesChannel",
    "RunshopChannel",
    "SystemStatusChannel",
    "MarketBettingPricesChannel",
  ];

  constructor() {
    this.ws = new WSDWebSocket();
    this.observers = [];
  }

  connect() {
    this.ws.connect();
    this.#subscribeToChannels();
  }

  disconnect() {
    this.ws.disconnect();
  }

  // WebSocketObserver
  addObserver(observer) {
    this.observers.push(observer);
  }

  // WebSocketObserver
  removeObserver(observer) {
    this.observers = this.observers.filter((obs) => obs !== observer);
  }

  // Private methods

  #notify(type, message) {
    this.observers.forEach((observer, index) => {
      if (observer.channelName === type) {
        observer.update(message);
      }
    });
  }

  #subscribeToChannels() {
    const self = this;
    WebSocketHandler.CHANNEL_NAMES.forEach((name, index) => {
      this.ws.subscribeToChannel(name, (type, message) => {
        this.#notify(type, message);
      });
    });
  }
}

export default WebSocketHandler;
