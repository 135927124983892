import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ReactComponent as EditIcon } from "../../../assets/icons/LeaguesIcons/editIcon.svg";
import { useMatchPreviewService } from "../../../context/useMatchPreviewService";
import { format_time_to_timezone } from "../../../helper/Generic/dateTimeFormat";
import { notifyError, notifySucces } from "../../../helper/Generic/Notifications";
import { selectUser } from "../../../Redux/user/user";
import cloneDeep from "lodash/cloneDeep";
const Note = ({ currentLeague, currentCup, selectedFixture, setSelectedFixture, setGamesList, gamesList }) => {


    const inputRef = useRef(null);

    const [homeTeam, setHomeTeam] = useState(null);
    const [awayTeam, setAwayTeam] = useState(null);
    const [startTime, setStartTime] = useState(null);

    const [edit, setEdit] = useState(false);

    const [note, setNote] = useState(selectedFixture.notes);
    const user = useSelector(selectUser);

    const { set_notes_to_game } = useMatchPreviewService();

    const loadNotes = () => {
        if (selectedFixture.notes == null) {
            setNote("");
        } else {
            setNote(selectedFixture.notes);
        }

    };

    const setTeams = (fixture) => {
        if (!fixture) {
            return;
        }
        if (fixture?.fixture?.participant1is_home) {
            setHomeTeam(fixture.team1);
            setAwayTeam(fixture.team2);
        } else {
            setHomeTeam(fixture.team2);
            setAwayTeam(fixture.team1);
        }
    };
    const setTime = (fixture) => {
        if (!fixture) {
            return;
        }
        setStartTime(format_time_to_timezone(
            selectedFixture?.fixture?.start_time,
            user.timezone
        ).format("DD.MM.YYYY - HH:mm"));
    };

    const handleEdit = () => {
        setEdit(true);
    };

    const updateFixtureNote = (fixture) => {
        let copyLeagues = cloneDeep(gamesList);
        copyLeagues.forEach(element => {
            if (element.game.fixture.fixture_id === fixture.fixture.fixture_id) {
                element.game.notes = fixture.notes
            }
        });
        setSelectedFixture(fixture);
        setGamesList(copyLeagues);


    };

    const handleSetNote = () => {
        if (selectedFixture.notes != null) {
            if (note == "") {
                var dataObject = {
                    fixture_id: selectedFixture.fixture.fixture_id,
                    notes: null
                }
                if (currentCup) {
                    dataObject.cup_id = currentCup.id
                } else {
                    dataObject.league_id = currentLeague.id
                }
                set_notes_to_game(
                    dataObject,
                    function (response) {
                        notifySucces("Note edited");
                        setEdit(false);
                        updateFixtureNote(response.game);
                    },
                    function (error) {
                        setEdit(false);
                        notifyError("Something wrong");
                    }
                );
            } else {
                var dataObject = {
                    fixture_id: selectedFixture.fixture.fixture_id,
                    notes: note
                }
                if (currentCup) {
                    dataObject.cup_id = currentCup.id
                } else {
                    dataObject.league_id = currentLeague.id
                }
                set_notes_to_game(
                    dataObject,
                    function (response) {
                        notifySucces("Note edited");
                        setEdit(false);
                        updateFixtureNote(response.game);
                    },
                    function (error) {
                        setEdit(false);
                        notifyError("Something wrong");
                    }
                );
            }


        }

        if (selectedFixture.notes == null) {
            if (note == "") {
                setEdit(false);
            } else {

                var dataObject = {
                    fixture_id: selectedFixture.fixture.fixture_id,
                    notes: note
                }
                if (currentCup) {
                    dataObject.cup_id = currentCup.id
                } else {
                    dataObject.league_id = currentLeague.id
                }

                set_notes_to_game(
                    // selectedFixture.fixture.fixture_id,
                    // currentLeague.id,
                    // note,
                    dataObject,
                    function (response) {
                        notifySucces("Note edited");
                        setEdit(false);
                        updateFixtureNote(response.game);
                    },
                    function (error) {
                        setEdit(false);
                        notifyError("Something wrong");
                    }
                );
            }
        }


    };

    useEffect(() => {

        setTeams(selectedFixture);
        setTime(selectedFixture);
        loadNotes();
        setEdit(false);
    }, [selectedFixture]);



    useEffect(() => {
        if (edit) {
            inputRef.current.focus();
        }
    }, [edit]);

    return (
        <Container>
            <Header>
                <h1>{homeTeam?.name} - {awayTeam?.name}</h1>
                <h2>{startTime}</h2>
            </Header>
            <Content>
                <NoteItem>
                    <textarea disabled={!edit} ref={inputRef} value={note} onChange={(e) => setNote(e.currentTarget.value)}></textarea>
                    <Edit>
                        {edit == false ? (<EditButton onClick={(e) => handleEdit()}><EditIcon /> Edit</EditButton>)
                            :
                            (<SaveButton onClick={(e) => handleSetNote()}> Update Note</SaveButton>)}

                    </Edit>
                </NoteItem>
            </Content>
        </Container>
    );
};

export default Note;
const Container = styled.div`
    display:flex;
    flex-direction:column;
    background: ${({ theme }) => theme.matchPreview.bg};
height:100%;
`;
const Header = styled.div`
padding:10px 15px;
border-bottom: 1px solid #B9C4D3;
h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.matchPreview.textColor};
}
h2{
    margin-top:5px;
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: ${({ theme }) => theme.matchPreview.textColor};
}
`;
const Content = styled.div`
padding:10px 15px;
height:100%;
`;
const NoteItem = styled.div`
padding:15px;
display:flex;
flex-direction:column;
justify-content:space-between;
min-height:calc(100vh - 245px);
height:100%;
background: #F8F9FC;
border-radius: 0px 10px 10px 10px;
p{
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 140%;
color: ${({ theme }) => theme.matchPreview.textColor};
opacity: 0.8;
}

background: ${({ theme }) => theme.matchPreview.noteBg};
textarea{
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height:11px;
line-height: 140%;
color: ${({ theme }) => theme.matchPreview.textColor};
display:inline-block;
min-height:calc(100vh - 300px);
height:100%;
border:none;
background: ${({ theme }) => theme.matchPreview.noteBg};
resize:none;
outline:none;
:disabled{
    -webkit-text-fill-color: ${({ theme }) => theme.matchPreview.textColor};
}
`;
const Edit = styled.div`
display:flex;
justify-content:end;
`;
const EditButton = styled.button`
display:flex;
column-gap:5px;
align-items:center;
background: ${({ theme }) => theme.matchPreview.noteEditBtnBg};
border: 0.7px solid #006FFF;
border-radius: 3px;
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 12px;
color:var(--primaryBlue);
padding:4px 7px;
cursor:pointer;
`;
const SaveButton = styled(EditButton)`
background: var(--primaryBlue);
color:white;
`;