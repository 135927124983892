import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as XIcon } from "../../../assets/icons/xBtn.svg";
import LeagueDetailsOption from "./LeagueDetailsOption";
import { useLeaguesDataService } from "../../../context/useLeaguesDataService";
import { getCountries } from "../../../api/user/GetCountriesListAdmin";
import { notifyError } from "../../../helper/Generic/Notifications";
import ButtonWithConfirmation from "../../ButtonWithConfirmation";
import TeamDetailsOption from "./TeamDetailsOption";
import TeamsPlayersOption from "./TeamPlayersOption";

const EditLeague = ({
  leagueData,
  handleGetSeasonsAndCups,
  setLoading,
  setIsMenuOpen,
  refreshListOnClose,
  leaguesListForSeason,
  setRefreshListOnClose,
  refreshAfterDelete,
  queryParams,
  setQueryParams,

}) => {
  const LEAGUE_DETAILS = "League Details"
  const TEAM_DEATAILS = "Team Details";
  const TEAM_PLAYERS = "Team Players"

  const [option, setOption] = useState("League Details");
  const [currentLeagueData, setCurrentLeagueData] = useState(leagueData);
  // const [teamToEdit, setTeamToEdit] = useState();
  const [teamID, setTeamID] = useState(currentLeagueData?.teams[0]);
  const [allCountries, setAllCountries] = useState([]);
  const [unsavedCounter, setUnsavedCounter] = useState(0);

  const { get_league_by_id } = useLeaguesDataService();

  const closeWindow = (forceRefresh) => {
    if (refreshListOnClose == true) {
      handleGetSeasonsAndCups();
    }
    setIsMenuOpen(false);
    setRefreshListOnClose(false);

    if (forceRefresh) {
      handleGetSeasonsAndCups();
      setIsMenuOpen(false);
      setRefreshListOnClose(false);
    }
  };

  const refreshAfterDeleteLeague = () => {
    refreshAfterDelete()
    setIsMenuOpen(false);
  }

  const handleSelectTeamToEdit = (team) => {
    setTeamID(team);
  };

  const getAllCountries = () => {
    getCountries(
      function (response) {
        setAllCountries(response.data.countries);
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };

  const refreshCurrentLeague = (onSuccess) => {
    if (!currentLeagueData) {
      return;
    }
    setLoading(true);
    get_league_by_id(
      currentLeagueData.id,
      function (response) {
        // console.log(response, "AICI")
        setLoading(false);
        const league = response.data.league
        league.teams = league.teams.sort((a, b) => a.name > b.name ? 1 : -1);
        setCurrentLeagueData(league);
        setTeamID(league.teams[0]);

        // REFRESH ALL
        handleGetSeasonsAndCups();

        if (onSuccess) {
          onSuccess(response.data.league);
        }

      },
      function () {
        setLoading(false);
        notifyError("Something wrong");
      }
    );
  };


  const ShowSelectedOption = () => {
    if (option == LEAGUE_DETAILS) {
      return (
        <LeagueDetailsOption
          leagueToEdit={currentLeagueData}
          refreshCurrentLeague={refreshCurrentLeague}
          setLoading={setLoading}
          setRefreshListOnClose={setRefreshListOnClose}
          closeWindow={closeWindow}
          refreshAfterDeleteLeague={refreshAfterDeleteLeague}
        />
      );
    }
    if (option == TEAM_DEATAILS) {
      return (
        <TeamDetailsOption
          leagueToEdit={currentLeagueData}
          refreshCurrentLeague={refreshCurrentLeague}
          setLoading={setLoading}
          setRefreshListOnClose={setRefreshListOnClose}
          closeWindow={closeWindow}
          leaguesListForSeason={leaguesListForSeason}
          allCountries={allCountries}
          handleSelectTeamToEdit={handleSelectTeamToEdit}
          teamID={teamID}
          refreshAfterDeleteLeague={refreshAfterDeleteLeague}
        />
      );
    }
    if (option == TEAM_PLAYERS) {
      return (
        <TeamsPlayersOption
          leagueToEdit={currentLeagueData}
          setLoading={setLoading}
          setRefreshListOnClose={setRefreshListOnClose}
          handleSelectTeamToEdit={handleSelectTeamToEdit}
          teamID={teamID}
          refreshAfterDeleteLeague={refreshAfterDeleteLeague}
          setUnsavedCounter={setUnsavedCounter}

        />
      );
    }
  };

  const checkFlag = (country_code) => {
    if (!country_code) {
      return;
    }
    return `https://flagcdn.com/${country_code.toLowerCase()}.svg`;

  };

  const checkQueryParamsOptions = (queryParams) => {
    if (queryParams.edit_team_details) {
      setOption(TEAM_PLAYERS);
      let found = currentLeagueData?.teams?.find((element) => element.id == queryParams.teamId)
      if (found) {
        handleSelectTeamToEdit(found);

        setQueryParams(current => {
          const { edit_team_details, ...rest } = current;
          return rest
        })
      }
    }
  }

  useEffect(() => {
    getAllCountries();
    refreshCurrentLeague();
  }, []);

  useEffect(() => {
    checkQueryParamsOptions(queryParams)
  }, [currentLeagueData])

  useEffect(() => {
    setUnsavedCounter(0);
  }, [option])

  return (
    <Container>
      <CardContainer>
        <Card>
          <Top>
            <h4>Edit League Data</h4>{" "}
            {unsavedCounter > 0 ?
              <ButtonWithConfirmation variant={'close'} action={closeWindow} />
              : <button
                onClick={() => {
                  closeWindow();
                }}
              >
                <XIcon />
              </button>}

          </Top>

          <TitleAndMenu>
            <FlagTitle>
              <img
                src={checkFlag(currentLeagueData?.country?.country_code)}
                alt=""
              />
              <h1>{currentLeagueData?.name}</h1>
            </FlagTitle>

            <CardMenu>
              <button
                onClick={() => setOption(LEAGUE_DETAILS)}
                name={LEAGUE_DETAILS}
                className={option == LEAGUE_DETAILS ? "active" : ""}
              >
                League Details
              </button>
              <button
                onClick={() => setOption(TEAM_DEATAILS)}
                name={TEAM_DEATAILS}
                className={option == TEAM_DEATAILS ? "active" : ""}
              >
                Teams Details
              </button>
              <button
                onClick={() => setOption(TEAM_PLAYERS)}
                name={TEAM_PLAYERS}
                className={option == TEAM_PLAYERS ? "active" : ""}
              >
                Team Players
              </button>
            </CardMenu>
          </TitleAndMenu>

          <Content>{ShowSelectedOption()}</Content>
        </Card>
      </CardContainer>
    </Container>
  );
};

export default EditLeague;
const Container = styled.div`
 position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  z-index: 4;
  overflow: hidden;
  padding: 0 20vw;
`;
const CardContainer = styled.div`
  position: absolute;
  z-index: 4;
  top: -40px;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  padding: 0 10vw;
  overflow: none;

  @media(max-width:2000px){
    padding: 0 15vw;
   }
   
  @media(max-width:1800px){
    padding: 0 50px;
   }

   @media(max-width:1500px){
    padding: 0px;
   }
`;
const Content = styled.div`
  position: relative;
  width: 100%;
  height:100%;
  border-radius: 0 0 16px 16px;
  overflow: hidden;
  background: ${({ theme }) => theme.editLeague.bg};
`;

const Card = styled.div`
  position: sticky;
  top: 5vh;
  width: 100%;
  height:100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  min-width: 1520px;
  box-shadow: 0px 1px 8px rgba(46, 46, 46, 0.04);
  border-radius: 16px;
  @media(max-height:1024px){
    top:2vh;
    max-height:90vh;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items:center;
  padding: 14px 40px;
  background: ${({ theme }) => theme.editLeague.headerBg};
  border-radius: 16px 16px 0px 0px;
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: ${({ theme }) => theme.editLeague.headerColor};
  }
  svg{
     color: ${({ theme }) => theme.editLeague.headerColor};
  }
`;
const TitleAndMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.editLeague.bg};
`;
const FlagTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 25px 40px;
  h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: ${({ theme }) => theme.editLeague.headerColor};
  }
  img {
    border-radius: 4px;
    height: 26px;
  }
`;
const CardMenu = styled.div`
  padding: 0 40px;
  display: flex;
  column-gap: 20px;

  button {
    transition: none !important;
    background: none;
    border: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.editLeague.menuTextColor};
    padding-bottom: 4px;
    cursor: pointer;
  }
  padding-bottom: 20px;
  border-bottom: 3px solid ${({ theme }) => theme.editLeague.borderColor};
  .active {
    transition: none !important;
    margin-bottom: -3px;
    border-bottom: 3px solid var(--primaryBlue);
  }
`;
