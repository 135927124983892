import { POST } from "../APiHelper/API"
let source;
export const getPlayerStatsSeasonAPI = (season_id, team_id, player_id, onSuccess, onError) => {
    const data = {
        season_id: season_id,
        team_id: team_id,
        player_id: player_id
    }

    source = POST(`/api/v1/players_stats/player_in_season`, data, onSuccess, onError, true, source)
}