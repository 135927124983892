import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { checkPositionColor } from "../../../helper/Analyst/SquadManager/checkPOSNCellColor";
import { MatchRtgList } from "../../../helper/Values/MatchRtgList";
import { AVAILABLE, YesNo } from "../../../helper/Values/TeamFormation";
import InjuredMenu from "../Menu/InjuredMenu";
import MatchRtgMenu from "../Menu/MatchRtgMenu";
import { displayPlayersNameByOption } from "../../../helper/Analyst/LeaguesData/displayFullName";


const PlayerFinishedMatch = ({
  data,
  handleFieldChange,
  displayPlayerAverageStats,
  index,
  checkHighlightedElement,
  selectedFixture,
  user
}) => {



  const [rtg, setRtg] = useState(data.match_rating);
  const [injured, setInjured] = useState(data.injured);
  const [confirmed, setConfirmed] = useState(data.confirmed);


  const populateFiels = () => {
    setInjured(data.injured);
    setRtg(data.match_rating);
    setConfirmed(data.confirmed);
  };

  const handleChange = (e) => {
    setConfirmed(e.target.checked);
  };


  useEffect(() => {
    populateFiels();
  }, [data]);



  useEffect(() => {
    handleFieldChange(data.player_id, "match_rating", rtg);
  }, [rtg]);

  useEffect(() => {
    handleFieldChange(data.player_id, "injured", injured);
  }, [injured]);

  useEffect(() => {
    handleFieldChange(data.player_id, "confirmed", confirmed);
  }, [confirmed]);

  return (
    <>
      {data.player.wy_id != null ? (
        <Wrapper high={checkHighlightedElement(index)}>
          <PlayerItem
            checked={data.selected_for_the_game && data.status == AVAILABLE}
          >

            <PlayerCell style={{ minWidth: "160px" }} >
              <PlayerNumber>
                <h2>{data.player?.squad_number}</h2>
              </PlayerNumber>
              <PlayerNameCell onContextMenu={(e) => displayPlayerAverageStats(e, selectedFixture.league.season_id, selectedFixture.squad.team_id, data.player?.id)}>

                <ChangesMarkesCell>
                  <MarkerWrapper>
                    {data.lineup_changed_after_pm_complete && <h5> {data.lineup_changed_after_pm_complete && "★"}</h5>}
                  </MarkerWrapper>
                  <MarkerWrapper>
                    {data.ratings_changed_after_pm_complete && <h6> {data.ratings_changed_after_pm_complete && "●"}</h6>}
                  </MarkerWrapper>
                </ChangesMarkesCell>

                <PlayerNameWrapper >
                  <h1 >
                    {displayPlayersNameByOption(data, user)}
                  </h1>
                </PlayerNameWrapper>

              </PlayerNameCell>

            </PlayerCell>
            <POSNCell className={checkPositionColor(data.position)}>
              <h1>{data.position}</h1>
            </POSNCell>
            <InjuredCell>
              <InjuredMenu
                width={"50px"}
                valuesList={YesNo}
                selectedValue={injured}
                setSelectedValue={setInjured}
              />
            </InjuredCell>
            <MatchRtgCell>
              <MatchRtgMenu
                width={"70px"}
                valuesList={MatchRtgList}
                selectedValue={rtg}
                setSelectedValue={setRtg}
              />
            </MatchRtgCell>
            <ConfCell>
              <input
                type={"checkbox"}
                value={confirmed}
                checked={confirmed}
                onChange={(e) => handleChange(e)}
              />
            </ConfCell>
          </PlayerItem>
        </Wrapper>) : (

        <Wrapper high={checkHighlightedElement(index)}>
          <PlayerItem />
        </Wrapper>)
      }
    </>

  );
};

export default PlayerFinishedMatch;
const Wrapper = styled.div`
display: flex;
padding:${(props) => props.high ? "3px 0 3px 3px;" : ""};
margin:${(props) => props.high ? "-3px 0 -3px -3px;" : ""};
background: ${(props) => props.high ? ({ theme }) => theme.squadManager.highlightColor : ""};
`
const PlayerItem = styled.div`
width:100%;
  padding: 5px 10px;
  align-items: center;
  display: flex;
  column-gap: 10px;
  background: ${(props) => (props.checked ? ({ theme }) => theme.squadManager.playerSelected : ({ theme }) => theme.squadManager.playerBg)};
  height: 32px;
  .green {
    border-color: #cbf2dc;
    background: #ebfcf3;
    h1 {
      color: #23a760;
    }
    p {
      color: #23a760;
    }
  }
  .blue {
    border-color: #d5dff7;
    background: #e4ecff;
    h1 {
      color: #4393fc;
    }
  }
  .orange {
    border-color: #f6dabd;
    background: #fff3e7;
    h1 {
      color: #ff8d21;
    }
  }
  .red {
    border-color: #f9dddd;
    background: #fff5f5;
    h1 {
      color: #ec4848;
    }
    p {
      color: #ec4848;
    }
  }
`;

const PlayerCell = styled.div`
  min-width: 100px;
  display: flex;
  align-items: center;
  column-gap: 5px;

  h1 {
    cursor: pointer;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.03em;
    color: ${({ theme }) => theme.squadManager.textColor};
    overflow: hidden;
    max-width: 130px;
    overflow: hidden;
    max-height: 24px;
    white-space: break-spaces;
  }
`;
const PlayerNumber = styled.div`
  border: 0.7px solid #cbd7f5;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 20px;
  width: 20px;
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    text-align: center;
    letter-spacing: 0.02em;
    color: rgba(0, 18, 41, 0.7);
  }
`;
const POSNCell = styled(PlayerCell)`
  min-width: 34px;
  min-height: 20px;
  border: 1px solid #e1e9ff;
  background: white;
  display: flex;
  justify-content: center;
  padding: 3px 3px;
  border-radius: 2px;
  h1 {
    font-weight: 500;
  }
`;
const InjuredCell = styled(PlayerCell)`
  min-width: 50px;
`;
const MatchRtgCell = styled(PlayerCell)`
  min-width: 70px;
`;

const ConfCell = styled(PlayerCell)`
  min-width: 45px;

  justify-content: start;
  input {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }
`;

const PlayerNameCell = styled.div`
display:flex;
align-items:center;
gap:2px;
`
const PlayerNameWrapper = styled.div`
display:flex;
h1 {
  cursor: pointer;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.03em;
  color: ${({ theme }) => theme.squadManager.textColor};
  overflow: hidden;
  max-width: 130px;
  overflow: hidden;
  max-height: 24px;
  white-space: break-spaces;
}
`
const ChangesMarkesCell = styled.div`
display:flex;
flex-direction:column;
gap:2px;
justify-content:center;
align-items:center;
min-width:6px;
margin-left:-2px;
h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 5px;
  line-height: 5px;
   color: ${({ theme }) => theme.squadManager.textColor};
}
 h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 7px;
  line-height: 5px;
   color: ${({ theme }) => theme.squadManager.textColor};
}
`
const MarkerWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
min-width:6px;
min-height:5px;
`