import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightTheme } from "../../../theme/theme";
import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";
import { ReactComponent as SearchSvg } from "../../../assets/icons/PricingMenuIcons/search.svg";
const DropDownBookmakers = ({
  label,
  bookmakersList,
  bookmakers,
  setBookmakers,
  bookmakersIds,
  setBookmakersIds,
}) => {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState("");

  const retailAvgIds = [126, 221, 613, 84, 42, 17, 244, 22, 30, 744, 272];
  const asiaAvgIds = [245, 285, 894, 571, 282];

  const handleClick = (item) => {
    var updatedList = [...bookmakersIds];
    var updatedList2 = [...bookmakers];
    let found = updatedList.find((element) => element === item.bookmaker_id);

    if (found != null) {
      let index = updatedList.indexOf(
        updatedList.find((element) => element === item.bookmaker_id)
      );

      let index2 = updatedList.indexOf(
        updatedList2.find((element) => element === item)
      );

      if (index === 0 && index2 === 0) {
        updatedList = [];
        updatedList2 = [];
      } else {
        updatedList.splice(index, 1);
        updatedList2.splice(index2, 1);
      }
    } else {
      updatedList = [...bookmakersIds, item.bookmaker_id];
      updatedList2 = [...bookmakers, item];
    }

    setBookmakersIds(updatedList);
    setBookmakers(updatedList2);
  };

  const handleSearchInput = (e) => {
    setInputSearch(e.target.value);
  };

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  const handleAll = () => {
    var updateListIds = [];
    var updatedList = [];
    if (bookmakersList.length === bookmakersIds.length) {
      setBookmakers([]);
      setBookmakersIds([]);
    } else {
      bookmakersList.forEach((element) => {
        updateListIds.push(element.bookmaker_id);
        updatedList.push(element);
      });
      setBookmakers(updatedList);
      setBookmakersIds(updateListIds);
    }
  };
  const handleAllRetail = () => {
    if (!checkRetail(retailAvgIds)) {
      setBookmakersIds(retailAvgIds);
      setBookmakers(bookmakersList);
    } else {
      setBookmakers([]);
      setBookmakersIds([]);
    }
  };
  const handleAllAsia = () => {
    if (!checkRetail(asiaAvgIds)) {
      setBookmakersIds(asiaAvgIds);
      setBookmakers(bookmakersList);
    } else {
      setBookmakers([]);
      setBookmakersIds([]);
    }
  };
  const checkRetail = (retailList) => {
    let containAll = retailList.every((r) => bookmakersIds.includes(r));
    return containAll;
  };
  const filterSearch = (items, filter) => {
    if (filter === "") {
      return items;
    } else {
      return items.filter((item) =>
        item.bookmaker_name.toLowerCase().includes(filter.toLowerCase())
      );
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
        setInputSearch("");
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref}>
      <DropDownMenu onClick={() => handleMenuOpen()} isMenuOpen={isMenuOpen}>
        <p>{label}</p>
        {isMenuOpen ? <UpSvg /> : <DownSvg />}
      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>
          <DropDownMenuItem>
            <div className="searchGroup">
              <SearchSvg />
              <input
                type={"text"}
                value={inputSearch}
                onChange={handleSearchInput}
                placeholder="Search"
              ></input>
            </div>
          </DropDownMenuItem>
          <DropDownMenuItem>
            <div onClick={() => handleAll()}>
              <input
                readOnly
                className="check"
                type="checkbox"
                checked={bookmakersList.length == bookmakersIds.length}
              ></input>
              <p>Select All</p>
            </div>
          </DropDownMenuItem>
          <DropDownMenuItem onClick={(e) => handleAllRetail(e)}>
            <div>
              <input
                readOnly
                className="check"
                type="checkbox"
                checked={checkRetail(retailAvgIds)}
              ></input>
              <p>All retail (retail avg)</p>
            </div>
          </DropDownMenuItem>
          <DropDownMenuItem onClick={() => handleAllAsia()}>
            <div>
              <input
                readOnly
                className="check"
                type="checkbox"
                checked={checkRetail(asiaAvgIds)}
              ></input>
              <p>All asia (asia avg)</p>
            </div>
          </DropDownMenuItem>
          {inputSearch !== ""
            ? filterSearch(bookmakersList, inputSearch).map((item, index) => (
                <DropDownMenuItem
                  key={index}
                  onClick={(e) => handleClick(item)}
                >
                  <div>
                    <input
                      readOnly
                      className="check"
                      type="checkbox"
                      checked={bookmakersIds.includes(item.bookmaker_id)}
                      value={item}
                    ></input>
                    <img src={item.logo} alt=""></img>
                    <p>{item.bookmaker_name}</p>
                  </div>
                </DropDownMenuItem>
              ))
            : bookmakersList.map((item, index) => (
                <DropDownMenuItem
                  key={index}
                  onClick={(e) => handleClick(item)}
                >
                  <div
                    className={
                      bookmakersIds.includes(item.bookmaker_id)
                        ? "active"
                        : "nonActive"
                    }
                  >
                    <input
                      key={index}
                      readOnly
                      className="check"
                      type="checkbox"
                      checked={bookmakersIds.includes(item.bookmaker_id)}
                      value={item}
                    ></input>
                    <img src={item.logo} alt=""></img>

                    <p>{item.bookmaker_name}</p>
                  </div>
                </DropDownMenuItem>
              ))}
        </DropDownList>
      )}
    </Container>
  );
};

export default DropDownBookmakers;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 162px;
  position: relative;
  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: ${({ theme }) => theme.dropDownColor};
  }
`;
const DropDownMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.menuBgColor};
  padding: 4px 10px;
  border-radius: 8px;
  border-radius: ${(props) => (props.isMenuOpen ? "8px 8px 0 0" : "")};
  .group {
    align-items: center;
    display: flex;
    column-gap: 5px;
    img {
      height: 14px;
      width: 18px;
      border-radius: 4px;
    }
  }
  img {
    cursor: pointer;
    height: 21px;
    width: 22px;
  }
  svg {
    cursor: pointer;
    fill: ${({ theme }) => theme.dropDownSvgFill};
    stroke: ${({ theme }) => theme.dropDownSvgStroke};
  }
`;
const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top: 28px;
  border-top: 2px solid;
  border-color: ${({ theme }) => (theme === lightTheme ? "#3C4A5C" : "white")};
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  max-height: 400px;
  width: 162px;
  overflow-x: hidden;
  color: black;

  .searchGroup {
    position: relative;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.menuTextColor};
    input {
      align-items: center;
      position: relative;
      width: 100%;
      padding: 4px 10px 4px 20px;
      background: ${({ theme }) => theme.leagueBG};
      border: none;
      border: 1px solid #d9dfe7;
      border-radius: 8px;
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      :focus-visible {
        outline: none;
        border: 1px solid ${({ theme }) => theme.dropDownSvgStroke};
      }
      color: ${({ theme }) => theme.menuTextColor};
    }
    svg {
      position: absolute;
      z-index: 1;
      top: 8, 5px;
      left: 7px;
    }
  }
`;
const DropDownMenuItem = styled.li`
  background-color: red;
  cursor: pointer;
  img {
    border-radius: 4px;
    height: 14px;
    width: 18px;
  }

  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;
  background-color: ${({ theme }) => theme.dropDownBG};
  padding: 5px 10px;
  div {
    column-gap: 5px;
    align-items: center;
    display: flex;
    flex-direction: row;
    .check {
      margin-right: 5px;
      background: red;
      color: red;
    }
  }

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.dropDownColor};
  }
  .active {
    p {
      color: rgba(97, 131, 255, 1);
    }
  }
  .nonActive {
    :hover {
      p {
        color: ${({ theme }) => theme.buttonBG};
      }
    }
    p {
      color: ${({ theme }) => theme.dropDownColor};
    }
  }
`;
