export const GAME_STATUS_NOT_COMPLETED = "not_completed";
export const GAME_STATUS_PARTIAL_FACTORS = "factors_partial";
export const GAME_STATUS_FACTORS_SUBMITTED = "factors_submitted";

export const GAME_STATUS_HOME_LINEUP_SAVED = "home_lineup_saved";
export const GAME_STATUS_AWAY_LINEUP_SAVED = "away_lineup_saved";
export const GAME_STATUS_LINEUPS_SAVED = "lineups_saved";

export const GAME_STATUS_HOME_LINEUP_SUBMITTED = "home_lineup_submitted";
export const GAME_STATUS_AWAY_LINEUP_SUBMITTED = "away_lineup_submitted";
export const GAME_STATUS_LINEUPS_SUBMITTED = "lineups_submitted";

export const GAME_STATUS_PREVIEW_SUBMITTED = "preview_submitted";

export const GAME_STATUS_HOME_LINEUP_CONFIRMED = "home_lineup_confirmed";
export const GAME_STATUS_AWAY_LINEUP_CONFIRMED = "away_lineup_confirmed";
export const GAME_STATUS_LINEUPS_CONFIRMED = "lineups_confirmed";


export const GAME_STATUS_PM_HOME_COMPLETED = "pm_home_completed";
export const GAME_STATUS_PM_AWAY_COMPLETED = "pm_away_completed";
export const GAME_STATUS_PM_COMPLETED = "pm_completed";


export const GAME_STATUS_XI_HOME = "xi_home_confirmed"
export const GAME_STATUS_XI_AWAY = "xi_away_confirmed"
export const GAME_STATUS_XI_CONFIRMED = "xi_confirmed"
