import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CountryMenu from "../../components/bettingComponents/Menu/CountryMenu";
import DatePickerMenu from "../../components/bettingComponents/Menu/DatePickerMenu";
import { ReactComponent as SearchSvg } from "../../assets/icons/BettingIcons/search.svg";
import { getCountriesForCurrentUser } from "../../api/competitions/GetCountriesForCurrentUser";
import { ReactComponent as DownloadSvg } from "../../assets/icons/BettingIcons/downloadArrow.svg";
import {
  notifyError,
  notifyLoading,
  notifyUpdate,
} from "../../helper/Generic/Notifications";
import MatchesList from "../../components/matchPreviewComponents/MatchesList";
import MatchSettings, {
  MATCH_FACTORS,
  PROJECTED_LINEUP,
} from "../../components/matchPreviewComponents/MatchSettings";
import ChatSide from "../../components/matchPreviewComponents/ChatSide";
import SeasonMenu from "../../components/leaguesComponents/Menu/SeasonMenu";
import { useLeaguesDataService } from "../../context/useLeaguesDataService";
import LeagueMenuForSeason from "../../components/leaguesComponents/Menu/LeagueMenuForSeason";
import {
  formatted_date_for_backend,
  same_time_in_timezone,
} from "../../helper/Generic/dateTimeFormat";
import { useSelector } from "react-redux";
import { selectUser } from "../../Redux/user/user";
import { SpinnerComponent } from "../../components/genericComponents/SpinnerComponent";
import { useMatchPreviewService } from "../../context/useMatchPreviewService";
import LeaguesHeader from "../../components/leaguesComponents/LeaguesHeader";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import qs from "query-string";
import cloneDeep from "lodash.clonedeep";
import { gamesCSV } from "../../api/matchPreview/gamesCSV";
import { toast } from "react-toastify";
import fileDownload from "js-file-download";
import dayjs from "dayjs";
import { squadsCSV } from "../../api/matchPreview/squadsCSV";
import {
  GAME_STATUS_FACTORS_SUBMITTED,
  GAME_STATUS_PM_AWAY_COMPLETED,
  GAME_STATUS_PM_COMPLETED,
  GAME_STATUS_PM_HOME_COMPLETED,
} from "../../helper/Analyst/LeaguesData/MatchStatus";
import { goToEditTeamPlayers } from "./SquadManager";
import SeasonCupsMenu from "../../components/leaguesComponents/Menu/SeasonCupsMenu";
import EditPlayersView from "../../components/squadManagerComponents/EditPlayersView";
import FilterDefferedMatches from "../../components/matchPreviewComponents/Menu/FilterDefferedMatches";
import { sortReferees } from "../../helper/Analyst/LeaguesData/SortReferees";

const MatchPreview = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector(selectUser);
  const location = useLocation();
  const [queryParams, setQueryParams] = useState(qs.parse(location.search));
  const toastId = useRef();
  const navigate = useNavigate();

  const [settingsPage, setSettingsPage] = useState(MATCH_FACTORS);
  const [searchInput, setSearchInput] = useState("");
  const [countries, setCountries] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [stadiums, setStadiums] = useState([]);
  const newDateBefore = new Date();
  const newDateAfter = new Date();
  const date4DaysBefore = new Date(
    newDateBefore.setDate(newDateBefore.getDate() - 4)
  );
  const date4daysAfter = new Date(
    newDateAfter.setDate(newDateAfter.getDate() + 4)
  );

  const [date, setDate] = useState([date4DaysBefore, date4daysAfter]);
  const [countriesIds, setCountriesIds] = useState([]);
  const [leaguesListForSeason, setLeaguesListForSeason] = useState([]);

  const [selectedLeagues, setSelectedLeagues] = useState([]);
  const [selectedLeaguesIds, setSelectedLeaguesIds] = useState([]);

  const [selectedSeason, setSelectedSeason] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [leaguesList, setLeaguesList] = useState([]);
  const [filters, setFilters] = useState({
    filter: "deferred",
    text: "Hide deferred matches"
  })

  const [gamesList, setGamesList] = useState([]);

  const [selectedFixture, setSelectedFixture] = useState(null);
  const [currentLeague, setCurrentLeague] = useState(null);
  const [currentCup, setCurrentCup] = useState(null);
  const [refereesList, setRefereesList] = useState([]);
  const [statusCompleted, setStatusCompleted] = useState(false);
  const isFirstLoadingRef = useRef(true);
  const leaguesListRef = useRef([]);

  const [thisTeam, setThisteam] = useState();
  const refreshOnClose = useRef(null);
  const [showEditPlayersModal, setShowEditPlayersModal] = useState(false);



  const [seasonsAndCupsList, setSeasonsAndCupsList] = useState({
    seasons: [],
    cups: [],
  });

  const [selectedSeasonsAndCups, setSelectedSeasonsAndCups] = useState({
    seasons: [],
    cups: [],
  });

  const {
    get_seasons,
    search_league,
    search_referee,
    get_stadiums,
    get_seasons_and_cups,
    search_cup_referee,
  } = useLeaguesDataService();

  const { get_games, cancel_get_game_request } = useMatchPreviewService();

  const _handleInputSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSetSearchParams();
      loadGames();
    }
  };

  const handleDownloadSquadsCSV = () => {
    let countries_ids = null;
    let start_time = null;
    let end_time = null;
    let leagues_ids = null;
    let term = null;

    if (countriesIds.length === 0) {
      countries_ids = null;
    } else {
      countries_ids = countriesIds;
    }

    if (selectedLeaguesIds.length === 0) {
      leagues_ids = null;
    } else {
      leagues_ids = selectedLeaguesIds;
    }
    if (searchInput === "") {
      term = null;
    } else {
      term = searchInput;
    }
    if (date !== null) {
      start_time = same_time_in_timezone(date[0], user.timezone)
        .startOf("day")
        .toDate();
      end_time = same_time_in_timezone(date[1], user.timezone)
        .endOf("day")
        .toDate();
    }
    notifyLoading(toastId, "Generating squads CSV");

    const dataObject = {
      season_ids: selectedSeasonsAndCups.seasons,
      cup_ids: selectedSeasonsAndCups.cups,
    };

    if (countries_ids) {
      dataObject.countries_ids = countries_ids;
    }
    if (leagues_ids) {
      dataObject.leagues_ids = leagues_ids;
    }
    if (term) {
      dataObject.term = term;
    }
    if (date) {
      dataObject.start_time = start_time;
      dataObject.end_time = end_time;
    }

    squadsCSV(
      dataObject,
      function (response) {
        notifyUpdate(toastId, "Squads CSV Generated", toast.TYPE.SUCCESS);
        fileDownload(response.data, `Squads ${dayjs()}.csv`);
      },
      function (error) {
        notifyUpdate(toastId, "Failed to generate", toast.TYPE.ERROR);
      }
    );
  };

  const handleDownloadGamesCSV = () => {
    let countries_ids = null;
    let start_time = null;
    let end_time = null;
    let leagues_ids = null;
    let term = null;

    if (countriesIds.length === 0) {
      countries_ids = null;
    } else {
      countries_ids = countriesIds;
    }

    if (selectedLeaguesIds.length === 0) {
      leagues_ids = null;
    } else {
      leagues_ids = selectedLeaguesIds;
    }
    if (searchInput === "") {
      term = null;
    } else {
      term = searchInput;
    }
    if (date !== null) {
      start_time = same_time_in_timezone(date[0], user.timezone)
        .startOf("day")
        .toDate();
      end_time = same_time_in_timezone(date[1], user.timezone)
        .endOf("day")
        .toDate();
    }
    notifyLoading(toastId, "Generating games CSV");

    const dataObject = {
      season_ids: selectedSeasonsAndCups.seasons,
      cup_ids: selectedSeasonsAndCups.cups,
    };

    if (countries_ids) {
      dataObject.countries_ids = countries_ids;
    }
    if (leagues_ids) {
      dataObject.leagues_ids = leagues_ids;
    }
    if (term) {
      dataObject.term = term;
    }
    if (date) {
      dataObject.start_time = start_time;
      dataObject.end_time = end_time;
    }

    gamesCSV(
      dataObject,
      function (response) {
        notifyUpdate(toastId, "Games CSV Generated", toast.TYPE.SUCCESS);
        fileDownload(response.data, `Games ${dayjs()}.csv`);
      },
      function (error) {
        notifyUpdate(toastId, "Failed to generate", toast.TYPE.ERROR);
      }
    );
  };

  const handleCompleted = (fixture) => {
    if (!fixture) {
      return;
    }
    if (
      fixture.status == GAME_STATUS_PM_COMPLETED
      // fixture.status == GAME_STATUS_PM_HOME_COMPLETED ||
      // fixture.status == GAME_STATUS_PM_AWAY_COMPLETED
    ) {
      setStatusCompleted(true);
    } else {
      setStatusCompleted(false);
    }
  };

  const handleStatuses = (fixture) => {
    handleCompleted(fixture);
  };

  const loadInitialData = () => {
    // Load countries and seasons
    loadCountries(() => {
      loadSeasonsAndCupsLists();
    });
  };

  const loadCountries = (onSucces) => {
    setIsLoading(true);
    getCountriesForCurrentUser(
      function (response) {
        let sortedCountries = response.data.countries.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
        setCountriesList(sortedCountries);
        onSucces();
      },
      function (error) {
        setIsLoading(false);
        notifyError("Something Wrong");
      }
    );
  };

  const handleLeagueForSeasonsResponse = (leagues, params) => {
    let currentLeagueIds = leagues.map((item) => {
      return item.id;
    });
    setLeaguesListForSeason(leagues);

    if (isFirstLoadingRef.current === true) {
      isFirstLoadingRef.current = false;
      if (queryParams.leagueIds) {
        let parsed = queryParams?.leagueIds?.split(",");
        let parsedLeagueId = parsed.map((item) => parseInt(item));
        let mapped = [];
        parsedLeagueId.forEach((element) => {
          let found = leagues.find((item) => item.id === element);
          if (found) {
            mapped.push(element);
          }
        });
        setSelectedLeaguesIds(mapped);
      } else {
        setSelectedLeaguesIds(currentLeagueIds);
      }
    } else {
      setSelectedLeaguesIds(currentLeagueIds);
    }
  };

  const loadLeaguesForSeason = (season) => {
    let country_ids = null;
    let season_ids = null;

    if (season.length === 0) {
      setSelectedLeaguesIds([]);
      setLeaguesListForSeason([]);
      return;
    }

    if (countriesIds.length > 0) {
      country_ids = countriesIds;
    }
    if (selectedSeasonsAndCups.seasons.length > 0) {
      season_ids = selectedSeasonsAndCups.seasons;
    }

    setIsLoading(true);
    search_league(
      season_ids,
      country_ids,
      null,
      function (response) {
        // let leagueIds = response.leagues.map((item) => { return item.id })
        // setSelectedLeaguesIds(leagueIds);
        // setLeaguesListForSeason(response.leagues);
        // console.log(response.leagues, "RESP")
        const sortedLeagues = response.leagues.sort((a, b) => a.name > b.name ? 1 : -1)
        handleLeagueForSeasonsResponse(
          sortedLeagues,
          qs.parse(location.search)
        );
      },
      function (error) {
        setIsLoading(false);
        notifyError("Something Wrong");
      }
    );
  };

  const sortGameItems = (arrayToCompare) => {
    let comparteThis = cloneDeep(arrayToCompare);
    let sorted = comparteThis.sort((a, b) =>
      new Date(a.game.fixture.start_time).getTime() >
        new Date(b.game.fixture.start_time).getTime()
        ? 1
        : -1
    );
    return sorted;
  };

  const initializeFixtureIfNotSelected = (games) => {
    let copy = cloneDeep(games);
    let fixtureIdParam = searchParams.get("fixtureId");

    if (fixtureIdParam) {
      let found = copy.find(
        (element) =>
          element.game.fixture.fixture_id === parseInt(fixtureIdParam)
      );

      if (currentCup) {
        found = copy.find(
          (element) =>
            element.game.fixture.fixture_id === parseInt(fixtureIdParam) &&
            element.cup.id === currentCup.id
        );
      }

      if (found) {
        handleSelectFixture(found.game, found.league, found.cup);
      } else {
        let sortedGames = sortGameItems(games);
        let select = sortedGames[0];
        handleSelectFixture(select.game, select.league, select.cup);
      }
    } else {
      let sortedGames = sortGameItems(games);
      let select = sortedGames[0];
      handleSelectFixture(select.game, select.league, select.cup);
    }
  };

  const handleGetGames = () => {
    // This will force a list update
    loadGames();
  };

  const loadGames = () => {

    let dataObject = {
      season_ids: selectedSeasonsAndCups.seasons,
      cup_ids: selectedSeasonsAndCups.cups,
      league_ids: selectedLeaguesIds,
    };

    // if (selectedLeaguesIds.length > 0) {
    //   dataObject.league_ids = selectedLeaguesIds
    // }

    if (countriesIds.length > 0) {
      dataObject.country_ids = countriesIds;
    }

    if (searchInput !== "") {
      dataObject.term = searchInput;
    }

    if (date !== null) {
      let start = same_time_in_timezone(date[0], user.timezone)
        .startOf("day")
        .toDate();
      let end = same_time_in_timezone(date[1], user.timezone)
        .endOf("day")
        .toDate();
      dataObject.start_time = formatted_date_for_backend(start);
      dataObject.end_time = formatted_date_for_backend(end);
    }

    setIsLoading(true);

    if ((dataObject.season_ids.length > 0 && dataObject.league_ids.length > 0) || dataObject.cup_ids.length > 0) {
      get_games(
        dataObject,
        function (response) {
          let sorted = sortGameItems(response);
          setGamesList(sorted);
          if (response.length > 0) {
            initializeFixtureIfNotSelected(response);
          } else {
            handleSelectFixture(null, null, null);
          }

          setIsLoading(false);
        },
        function (error) {
          handleSelectFixture(null, null, null);
          setGamesList([]);
          notifyError("Something wrong");
          setIsLoading(false);
        }
      );
    } else {
      cancel_get_game_request();
      setIsLoading(false);
      handleSelectFixture(null, null, null);
      setGamesList([]);
    }
  };

  const handleGetStadiums = () => {
    get_stadiums(
      function (response) {
        setStadiums(response);
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };

  const handleSelectFixture = (fixture, league, cup) => {
    setSelectedFixture(fixture);
    setCurrentLeague(league);
    setCurrentCup(cup);
    handleStatuses(fixture);
  };

  const loadReferees = (keyWord) => {
    let term = "";
    if (!currentLeague) {
      return;
    }

    if (!keyWord) {
      term = "";
    } else {
      term = keyWord;
    }

    search_referee(
      currentLeague.id,
      term,
      function (response) {
        let sorted = sortReferees(response.data.referees)
        setRefereesList(sorted);
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };

  const loadCupReferee = (keyWord) => {
    if (!currentCup) {
      return;
    }
    let term = "";

    if (!keyWord) {
      term = "";
    } else {
      term = keyWord;
    }
    search_cup_referee(
      term,
      currentCup.id,
      function (response) {
        let sorted = sortReferees(response.data.referees)
        setRefereesList(sorted);
      },
      function (error) {
        notifyError("Something wrong");
        console.log(error);
      }
    );
  };

  const handleSetSearchParams = () => {
    let start;
    let end;
    if (date != null) {
      start = date[0].toISOString();
      end = date[1].toISOString();
    } else {
      start = null;
      end = null;
    }

    setSearchParams((params) => {
      params.set("seasonIds", selectedSeasonsAndCups.seasons);
      params.set("cupIds", selectedSeasonsAndCups.cups);
      params.set("countryIds", countriesIds);
      params.set("leagueIds", selectedLeaguesIds);
      params.set("start_date", start);
      params.set("end_date", end);
      params.set("filter", filters.filter)
      if (searchInput != "") {
        params.set("term", searchInput);
      } else {
        params.delete("term");
      }
      if (selectedFixture) {
        params.set("fixtureId", selectedFixture?.fixture.fixture_id);
      }
      return params;
    });
  };

  const initializeMenuValuesFromParams = () => {
    if (location.search != "") {
      let params = qs.parse(location.search);
      let start_date = params.start_date;
      let end_date = params.end_date;
      let cup_ids = params.cupIds;
      let season_ids = params.seasonIds;
      let leagueIds = params.leagueIds;
      let countryIds = params.countryIds;
      let term = params.term;
      let filter = params.filter

      let parsedSeasonIds = [];
      let parsedCupIds = [];
      let parsedCountryId = [];
      let parsedLeagueId = [];


      if (countryIds) {
        let parsed = countryIds.split(",");
        parsedCountryId = parsed.map((item) => parseInt(item));
        setCountriesIds(parsedCountryId);
      }

      if (season_ids) {
        let parsed = season_ids.split(",");
        parsedSeasonIds = parsed.map((item) => parseInt(item));
      }

      if (cup_ids) {
        let parsed = cup_ids.split(",");
        parsedCupIds = parsed.map((item) => parseInt(item));
      }

      if (leagueIds) {
        let parsed = leagueIds.split(",");
        parsedLeagueId = parsed.map((item) => parseInt(item));
      }

      let seasonCupIdsObj = {
        seasons: parsedSeasonIds,
        cups: parsedCupIds,
      };
      if (term) {
        setSearchInput(term);
      }

      if (filter) {
        if (filter === "all") {
          setFilters({
            filter: "all",
            text: "All matches"
          })
        }
        if (filter === "deferred") {
          setFilters({
            filter: "deferred",
            text: "Hide deferred matches"
          })
        }
      }


      if (start_date != null && end_date != null) {
        setDate([new Date(start_date), new Date(end_date)]);
      }

      setSelectedSeasonsAndCups(seasonCupIdsObj);
      setSelectedLeaguesIds(parsedLeagueId);
    }
  };

  const navigateToSquadManager = (team_id) => {
    let newParams = searchParams;
    newParams.delete("start_date");
    newParams.delete("end_date");
    newParams.set("teamId", team_id);
    if (currentLeague) {
      newParams.set("selectedLeagueForTeam", currentLeague.id);
    }
    if (currentCup) {
      newParams.set("selectedCupId", currentCup.id);
    }

    if (user.open_links_in_new_tabs) {
      let url = {
        pathname: "squad_manager",
        search: createSearchParams(newParams).toString(),
      };
      window.open(`${url.pathname}?${url.search}`, "_blank");
    } else {
      navigate({
        pathname: "/leagues/squad_manager",
        search: createSearchParams(newParams).toString(),
      });
    }
  };

  const setResponseFromPartialFactorUpdate = (newMatch) => {
    let gamesCopy = cloneDeep(gamesList);
    let newValues = gamesCopy.map((element) => {
      if (currentCup) {
        if (
          (element.game.fixture.fixture_id === newMatch.fixture.fixture_id) &
          (element.cup.id === currentCup.id)
        ) {
          return {
            game: newMatch,
            cup: element.cup,
            league: element.league,
          };
        } else {
          return element;
        }
      } else {
        if (element.game.fixture.fixture_id === newMatch.fixture.fixture_id) {
          return {
            game: newMatch,
            cup: element.cup,
            league: element.league,
          };
        } else {
          return element;
        }
      }
    });
    setGamesList(newValues);
    setSelectedFixture(newMatch);
  };

  const handleSetSettingsPageFromParams = (queryParams) => {
    if (queryParams.projected_lineup) {
      let newParams = searchParams;
      newParams.delete("projected_lineup")
      setSettingsPage(PROJECTED_LINEUP);

    }
  };

  const navigateToEditTeamPlayers = (currentLeagueId, teamId) => {
    let searchParams;
    searchParams = createSearchParams({
      seasonId: queryParams.seasonId,
      teamId: teamId,
      currentLeagueId: currentLeagueId,
      edit_team_details: true,
    }).toString();
    if (user.open_linnks_in_new_tabs) {
      let url = {
        pathname: "/leagues",
        search: searchParams,
      };
      window.open(
        `${url.pathname}?${url.search}`,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      navigate({
        pathname: "/leagues",
        search: searchParams,
      });
    }
  };

  const loadSeasonsAndCupsLists = () => {
    get_seasons_and_cups(
      false,
      searchInput,
      function (response) {
        setSeasonsAndCupsList(response.data);
        if (queryParams.seasonIds == null && queryParams.cupIds == null) {
          setSelectedSeasonsAndCups({
            cups: response.data.cups[0] ? [response.data.cups[0].id] : [],
            seasons: response.data.seasons[0]
              ? [response.data.seasons[0].id]
              : [],
          });
        }
      },
      function (error) {
        console.log(error);
        notifyError("Something wrong");
      }
    );
  };

  const closeEditPlayersWindow = () => {
    setShowEditPlayersModal(false);
  };

  const filterGameList = (gameList, filter) => {
    if (!filter.filter) {
      return
    }
    let filtered = [];
    switch (filter.filter) {
      case "all":
        return gameList

      case "deferred":
        filtered = gameList.filter((item) => !item.game.postponed && !item.game.cancelled)
        return filtered

      default:
        break;
    }
  }

  useEffect(() => {
    initializeMenuValuesFromParams();
    loadInitialData();
    handleGetStadiums();
    handleSetSettingsPageFromParams(queryParams);
  }, []);

  useEffect(() => {
    loadLeaguesForSeason(selectedSeasonsAndCups.seasons);
  }, [selectedSeasonsAndCups.seasons, countriesIds]);

  useEffect(() => {
    if (currentCup) {
      loadCupReferee();
    } else {
      loadReferees();
    }
  }, [currentLeague, currentCup]);

  useEffect(() => {

    handleGetGames();
  }, [
    selectedSeasonsAndCups.seasons,
    selectedSeasonsAndCups.cups,
    countriesIds,
    selectedLeaguesIds,
    date,
  ]);

  useEffect(() => {
    handleSetSearchParams();
  }, [
    selectedSeasonsAndCups.seasons,
    selectedSeasonsAndCups.cups,
    countriesIds,
    countriesIds,
    selectedLeaguesIds,
    date,
    selectedFixture,
    filters
  ]);

  return (
    <Container>
      {showEditPlayersModal && (
        <EditPlayersView
          teamID={thisTeam}
          closeWindow={closeEditPlayersWindow}
          leagueData={currentLeague}
          cupData={currentCup}
          refreshOnClose={refreshOnClose}
        />
      )}

      <LeaguesHeader params={searchParams} />
      <Header>
        <Left>
          <Search>
            <SearchSvg />
            <input
              value={searchInput}
              onChange={(e) => _handleInputSearch(e)}
              placeholder="Filter by keyboard"
              onKeyDown={(e) => handleKeyDown(e)}
            ></input>
          </Search>
          <SeasonCupsMenu
            dataList={seasonsAndCupsList}
            value={selectedSeasonsAndCups}
            setValue={setSelectedSeasonsAndCups}
          />
          <CountryMenu
            className="countryDropDown"
            label={"Countries"}
            countriesList={countriesList}
            countries={countries}
            setCountries={setCountries}
            countriesIds={countriesIds}
            setCountriesIds={setCountriesIds}
          />

          <LeagueMenuForSeason
            label={"Leagues"}
            competitions={leaguesListForSeason}
            selectedCompetitions={selectedLeagues}
            setSelectedCompetitions={setSelectedLeagues}
            competitionIds={selectedLeaguesIds}
            setCompetitionIds={setSelectedLeaguesIds}
          />

          <DatePickerMenu selectedValue={date} setSelectedValue={setDate} />
          <FilterDefferedMatches value={filters} setValue={setFilters} />
        </Left>

        <Right>
          <CsvBtn onClick={() => handleDownloadGamesCSV()}>
            <DownloadSvg />
            Games CSV
          </CsvBtn>
          <CsvBtn onClick={() => handleDownloadSquadsCSV()}>
            <DownloadSvg />
            Squads CSV
          </CsvBtn>
        </Right>
      </Header>
      <Content>
        <SpinnerComponent loading={isLoading}></SpinnerComponent>
        <LeftSide>
          <MatchesList
            games={filterGameList(gamesList, filters)}
            selectedFixture={selectedFixture}
            handleSelectFixture={handleSelectFixture}
            isLoading={isLoading}
          />
        </LeftSide>

        <CenterSide>
          <MatchSettings
            setSelectedFixture={setSelectedFixture}
            handleGetGames={handleGetGames}
            setIsLoading={setIsLoading}
            selectedFixture={selectedFixture}
            refereesList={refereesList}
            currentLeague={currentLeague}
            currentCup={currentCup}
            isLoading={isLoading}
            statusCompleted={statusCompleted}
            setResponseFromPartialFactorUpdate={
              setResponseFromPartialFactorUpdate
            }
            settingsPage={settingsPage}
            setSettingsPage={setSettingsPage}
            stadiums={stadiums}
            navigateToSquadManager={navigateToSquadManager}
            navigateToEditTeamPlayers={navigateToEditTeamPlayers}
            setThisteam={setThisteam}
            refreshOnClose={refreshOnClose}
            setShowEditPlayersModal={setShowEditPlayersModal}
          />
        </CenterSide>

        <RightSide>
          <ChatSide
            selectedFixture={selectedFixture}
            setSelectedFixture={setSelectedFixture}
            currentLeague={currentLeague}
            currentCup={currentCup}
            handleGetGames={handleGetGames}
            gamesList={gamesList}
            setGamesList={setGamesList}
            isLoading={isLoading}
          />
        </RightSide>
      </Content>
    </Container>
  );
};

export default MatchPreview;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1600px;
  background-color: ${({ theme }) => theme.matchPreview.headerBg};
  position: relative;
  height:100%;
`;
const Header = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.matchPreview.headerBg};
  padding: 8px 14px;
  justify-content: space-between;

`;
const Left = styled.div`
  display: flex;
  column-gap: 5px;
  color: #3c4a5c;
`;
const Right = styled(Left)`
  display: flex;
`;
const CsvBtn = styled.button`
  cursor: pointer;
  border-radius: 4px;
  column-gap: 5px;
  padding: 5px 10px 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  background: var(--primaryBlue);
  color: #ffffff;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height:100%;
  min-height:fit-content;
  max-height: calc(100vh - 134px);
  display: flex;
  overflow: hidden;
  background: ${({ theme }) => theme.matchPreview.bg};

`;
const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 395px;
  overflow: hidden;
  margin-right: 4px;

`;
const CenterSide = styled.div`
  width: 100%;

  min-width: 928px;
  margin-right: 4px;
position:relative;
overflow:auto;
`;
const RightSide = styled.div`
  width: 100%;
  min-width: 270px;
  overflow: hidden;
  position:relative;
`;

const Search = styled.div`
  position: relative;
  svg {
    color: ${({ theme }) => theme.menuTextColor};
    position: absolute;
    top: 9px;
    left: 10px;
  }

  input {
    padding: 5px 10px 3px 26px;
    background: ${({ theme }) => theme.menuBgColor};
    color: ${({ theme }) => theme.menuTextColor};
    border: none;
    border-radius: 8px;
    min-width: 242px;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    ::placeholder {
      color: ${({ theme }) => theme.menuTextColor};
    }
    outline: none;
    border: none;

    :focus-visible {
      border: none;
      outline: none;
    }
  }
`;
