import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";
import { ReactComponent as RemoveIcon } from "../assets/icons/LeaguesIcons/removeBin.svg";
import { ReactComponent as BinSvg } from "../assets/icons/bin.svg";

const PostponedMatchConfirmation = ({
  handleSave,
  postponedMatchConfirmation,
  setPostponedMatchConfirmation,
}) => {
  const handleClose = () => {
    setPostponedMatchConfirmation(false);
  };

  const save = (e) => {
    e.preventDefault();
    handleSave();
    setPostponedMatchConfirmation(false);
  };

  return (
    <div>
      <Dialog
        open={postponedMatchConfirmation}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to postpone, this will clear all data for this
            match?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>No</Button>
          <Button style={{ color: "red" }} onClick={(e) => save(e)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PostponedMatchConfirmation;

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;

  svg {
    width: 40px;
    height: 40px;
  }
`;
const SaveButton = styled.button`
  cursor: pointer;
  background: #006fff;
  border: 1.2px solid #006fff;
  border-radius: 6px;
  color: white;
  font-family: "Roboto";
 
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding: 0 35px;
  height: 30px;
`;

const SaveAllButton = styled.button`
  background: #ffffff;
  border: 1.5px solid #006fff;
  border-radius: 4px;
  padding: 6px 25px;
  font-family: "Roboto";
 
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #006fff;
  cursor: pointer;
`;
