import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";


const SearchPlayerMenu = ({ label, value, setValue, valuesList, term, setTerm, shouldSearch }) => {

  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  const handleSelect = (value) => {

    let fullName = value.first_name + " " + value.last_name;
    shouldSearch.current = false;
    setTerm(fullName);
    setValue(value);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const handleSetTerm = (term) => {
    setTerm(term);
    shouldSearch.current = true;
    setIsMenuOpen(true);
  };

  return (
    <Container ref={ref} isMenuOpen={isMenuOpen} >
      <Input
        onClick={() => setIsMenuOpen(true)}
        placeholder={"Search player name"}
        value={term}
        onChange={(e) => handleSetTerm(e.currentTarget.value)}
      >
      </Input>

      {isMenuOpen && valuesList?.length > 0 &&
        <DropDownList>
          {valuesList?.map((item) => (
            <DropDownMenuGroup key={item.country}>
              <h3>{item.country}</h3>
              {item.players.map((player) => (
                <DropDownMenuItem
                  key={player.player.wy_id}
                  onClick={() => handleSelect(player.player)}>
                  <input
                    value={player.player}
                    type="radio"
                    checked={player.player.wy_id == value?.wy_id}
                    readOnly
                  />
                  <p>{player.player.first_name} <span>{player.player.last_name} , </span> {player.player.short_name && `(${player.player.short_name})`} {player.team_name && <>{player.team_name}, </>} {player.player.birth_date} </p>
                </DropDownMenuItem>
              ))}
            </DropDownMenuGroup>
          ))
          }
        </DropDownList>
      }
    </Container>
  );
};


export default SearchPlayerMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 266px;
  position: relative;

`;
const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  border: 1px solid #BAC3E8;
  top: 30px;
  border-top: 1px solid #3c4a5c;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  max-height: 350px;
  width: 100%;
  overflow-x: hidden;
  color: black;
  background-color: ${({ theme }) => theme.editLeague.readOnlyInputs};
  
`;
const DropDownMenuGroup = styled.div`
display:flex;
flex-direction:column;
padding: 5px 10px;

h3{
  text-transform:capitalize;
  font-family: "Roboto";
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 14px;
opacity:0.5;
margin-bottom:5px;
color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
}
`;
const DropDownMenuItem = styled.li`
display:flex;
align-items:center;
column-gap:5px;
background-color: ${({ theme }) => theme.editLeague.readOnlyInputs};
cursor: pointer;
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;

  padding: 5px 0px;

  p {
    text-transform:capitalize;
    font-family: "Roboto";
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  }
  span{
    font-weight: 700;
  }

  
`;
const Input = styled.input`
  height: 32px;
   background-color: ${({ theme }) => theme.editLeague.readOnlyInputs};
  width: 100%;
  border: 1px solid #cbd7f5;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  padding: 10px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  ::placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #ababab;
  }
  :focus-visible{
    outline:none;
  }
`;