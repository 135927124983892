import { POST } from "../../APiHelper/API";

export const archiveUnarchiveCupAPI = (
    id,
    archived,
    onSuccess,
    onError
) => {
    const data = {
        id: id,
        archived: archived
    }
    POST("/api/v1/cups/archive", data, onSuccess, onError)
}