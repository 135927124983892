import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { format_time_to_timezone } from "../../helper/Generic/dateTimeFormat";
import { selectUser } from "../../Redux/user/user";
import { checkFlag } from "../../helper/Generic/GetFlag";
import { ReactComponent as SortSvg } from "../../assets/icons/BettingIcons/sort.svg";
import cloneDeep from "lodash.clonedeep";

import {
  GAME_STATUS_AWAY_LINEUP_CONFIRMED,
  GAME_STATUS_AWAY_LINEUP_SAVED,
  GAME_STATUS_AWAY_LINEUP_SUBMITTED,
  GAME_STATUS_FACTORS_SUBMITTED,
  GAME_STATUS_HOME_LINEUP_CONFIRMED,
  GAME_STATUS_HOME_LINEUP_SAVED,
  GAME_STATUS_HOME_LINEUP_SUBMITTED,
  GAME_STATUS_LINEUPS_CONFIRMED,
  GAME_STATUS_LINEUPS_SAVED,
  GAME_STATUS_LINEUPS_SUBMITTED,
  GAME_STATUS_NOT_COMPLETED,
  GAME_STATUS_PARTIAL_FACTORS,
  GAME_STATUS_PM_AWAY_COMPLETED,
  GAME_STATUS_PM_COMPLETED,
  GAME_STATUS_PM_HOME_COMPLETED,
  GAME_STATUS_PREVIEW_SUBMITTED,
  GAME_STATUS_XI_AWAY,
  GAME_STATUS_XI_CONFIRMED,
  GAME_STATUS_XI_HOME,
} from "../../helper/Analyst/LeaguesData/MatchStatus";
import _ from "lodash";

const MatchesList = ({ games, selectedFixture, handleSelectFixture, isLoading }) => {
  const user = useSelector(selectUser);

  const [sort, setSort] = useState({ sort_key: "start_time", ascending: true });

  const setTeams = (item) => {
    if (item?.fixture?.participant1is_home) {
      return (
        <p>
          {item.team1?.name} - {item.team2?.name}
        </p>
      );
    }
    if (!item?.fixture?.participant1is_home) {
      return (
        <p>
          {item.team2?.name} - {item.team1?.name}
        </p>
      );
    }
  };

  const sortFunction = (arrayToCompare) => {
    if (!arrayToCompare) {
      return
    }

    let compareThis = cloneDeep(arrayToCompare);

    let sortingArr = [
      GAME_STATUS_NOT_COMPLETED,
      GAME_STATUS_PARTIAL_FACTORS,
      GAME_STATUS_FACTORS_SUBMITTED,
      GAME_STATUS_HOME_LINEUP_SAVED,
      GAME_STATUS_AWAY_LINEUP_SAVED,
      GAME_STATUS_LINEUPS_SAVED,
      GAME_STATUS_HOME_LINEUP_SUBMITTED,
      GAME_STATUS_AWAY_LINEUP_SUBMITTED,
      GAME_STATUS_LINEUPS_SUBMITTED,
      GAME_STATUS_PREVIEW_SUBMITTED,
      GAME_STATUS_HOME_LINEUP_CONFIRMED,
      GAME_STATUS_AWAY_LINEUP_CONFIRMED,
      GAME_STATUS_LINEUPS_CONFIRMED,
      GAME_STATUS_PM_HOME_COMPLETED,
      GAME_STATUS_PM_AWAY_COMPLETED,
      GAME_STATUS_PM_COMPLETED,
    ];

    if (sort.sort_key == "start_time") {
      if (sort.ascending) {
        let sorted = compareThis.sort((a, b) =>
          new Date(a.game.fixture[sort.sort_key]).getTime() >
            new Date(b.game.fixture[sort.sort_key]).getTime()
            ? 1
            : -1
        );
        return sorted;
      } else {
        let sorted = compareThis.sort((a, b) =>
          new Date(a.game.fixture[sort.sort_key]).getTime() <
            new Date(b.game.fixture[sort.sort_key]).getTime()
            ? 1
            : -1
        );
        return sorted;
      }
    }

    if (sort.sort_key == "status") {
      if (sort.ascending) {
        let sorted = compareThis.sort(
          (a, b) =>
            sortingArr.indexOf(a.game.status) -
            sortingArr.indexOf(b.game.status)
        );
        return sorted;
      } else {
        let sorted = compareThis.sort(
          (a, b) =>
            sortingArr.indexOf(b.game.status) -
            sortingArr.indexOf(a.game.status)
        );
        return sorted;
      }
    }
  };
  const handleSort = (sortKey) => {
    setSort((prev) => ({
      sort_key: sortKey,
      ascending: !prev.ascending,
    }));
  };

  const checkStatusType = (status) => {
    if (status == GAME_STATUS_NOT_COMPLETED) {
      return "notCompleted";
    }
    if (status == GAME_STATUS_PARTIAL_FACTORS) {
      return "partialFactors";
    }
    if (status == GAME_STATUS_FACTORS_SUBMITTED) {
      return "factorsSubmitted";
    }
    if (status == GAME_STATUS_HOME_LINEUP_SAVED) {
      return "lineupSaved";
    }
    if (status == GAME_STATUS_AWAY_LINEUP_SAVED) {
      return "lineupSaved";
    }
    if (status == GAME_STATUS_LINEUPS_SAVED) {
      return "lineupsSaved";
    }
    if (status == GAME_STATUS_HOME_LINEUP_SUBMITTED) {
      return "lineupSubmitted";
    }
    if (status == GAME_STATUS_AWAY_LINEUP_SUBMITTED) {
      return "lineupSubmitted";
    }
    if (status == GAME_STATUS_LINEUPS_SUBMITTED) {
      return "lineupsSubmitted";
    }
    if (status == GAME_STATUS_PREVIEW_SUBMITTED) {
      return "previewSubmitted";
    }
    if (status == GAME_STATUS_HOME_LINEUP_CONFIRMED) {
      return "plConfirmed";
    }
    if (status == GAME_STATUS_AWAY_LINEUP_CONFIRMED) {
      return "plConfirmed";
    }
    if (status == GAME_STATUS_LINEUPS_CONFIRMED) {
      return "plsConfirmed";
    }
    if (status == GAME_STATUS_PM_HOME_COMPLETED) {
      return "pmCompleted";
    }
    if (status == GAME_STATUS_PM_AWAY_COMPLETED) {
      return "pmCompleted";
    }
    if (status == GAME_STATUS_PM_COMPLETED) {
      return "pmsCompleted";
    }
    if (status == GAME_STATUS_XI_HOME) {
      return "xiHome";
    }
    if (status == GAME_STATUS_XI_AWAY) {
      return "xiHome";
    }
    if (status == GAME_STATUS_XI_CONFIRMED) {
      return "xiCompleted";
    }
  };

  const parseStatus = (status) => {
    if (status == GAME_STATUS_NOT_COMPLETED) {
      return "Not completed";
    }
    if (status == GAME_STATUS_PARTIAL_FACTORS) {
      return "Partial Factors";
    }
    if (status == GAME_STATUS_FACTORS_SUBMITTED) {
      return "Factors submitted";
    }
    if (status == GAME_STATUS_HOME_LINEUP_SAVED) {
      return "Home lineup saved";
    }
    if (status == GAME_STATUS_AWAY_LINEUP_SAVED) {
      return "Away lineup saved";
    }
    if (status == GAME_STATUS_LINEUPS_SAVED) {
      return "Lineups saved";
    }
    if (status == GAME_STATUS_HOME_LINEUP_SUBMITTED) {
      return "Home lineup submitted";
    }
    if (status == GAME_STATUS_AWAY_LINEUP_SUBMITTED) {
      return "Away lineup submitted";
    }
    if (status == GAME_STATUS_LINEUPS_SUBMITTED) {
      return "Lineups submitted";
    }
    if (status == GAME_STATUS_PREVIEW_SUBMITTED) {
      return "Preview submitted";
    }
    if (status == GAME_STATUS_HOME_LINEUP_CONFIRMED) {
      return "PL Home confirmed";
    }
    if (status == GAME_STATUS_AWAY_LINEUP_CONFIRMED) {
      return "PL Away confirmed";
    }
    if (status == GAME_STATUS_LINEUPS_CONFIRMED) {
      return "PL Confirmed";
    }
    if (status == GAME_STATUS_PM_HOME_COMPLETED) {
      return "PM Home Completed";
    }
    if (status == GAME_STATUS_PM_AWAY_COMPLETED) {
      return "PM Away Completed";
    }
    if (status == GAME_STATUS_PM_COMPLETED) {
      return "PM Completed";
    }
    if (status == GAME_STATUS_XI_HOME) {
      return "XI Home Confirmed";
    }
    if (status == GAME_STATUS_XI_AWAY) {
      return "XI Away Confirmed";
    }
    if (status == GAME_STATUS_XI_CONFIRMED) {
      return "XI Confirmed";
    }

    return status;
  };

  const handleClick = (game, league, cup) => {
    if (isLoading) {
      return
    }
    handleSelectFixture(game, league, cup)
  }

  return (
    <Container>
      <Header>
        <LeagueCell>
          <h1>MATCH</h1>
        </LeagueCell>
        <DateCell>
          <h1>DATE</h1>
          <SortSvg onClick={() => handleSort("start_time")} />
        </DateCell>
        <StatusCell>
          <h1>STATUS</h1>
          <SortSvg onClick={() => handleSort("status")} />
        </StatusCell>
      </Header>
      <ListWrapper>


        {sortFunction(games)?.map((game, gameIndex) => (
          <MatchItem
            bg={gameIndex}
            key={game.gameIndex}
            selected={_.isEqual(selectedFixture, game.game)}
            onClick={() => handleClick(game.game, game.league, game.cup)}
          >
            <MatchCell
              postponed={game.game.postponed}
              cancelled={game.game.cancelled}
            >
              <MatchDetails>
                {setTeams(game.game)}

                <Line>
                  <h3>{game.cup ? game.cup?.name : game.league.name}</h3>
                </Line>

              </MatchDetails>

              {game.game.postponed && (
                <MatchPostponed>
                  {" "}
                  <p>PP</p>{" "}
                </MatchPostponed>
              )}
              {game.game.cancelled && (
                <MatchPostponed>
                  {" "}
                  <p>C</p>{" "}
                </MatchPostponed>
              )}
            </MatchCell>

            <DateCellMatch>
              <p>
                {format_time_to_timezone(
                  game.game.fixture.start_time,
                  user.timezone
                ).format("DD.MM.YYYY - HH:mm")}
              </p>
              <h3>{!game.cup && game.league?.season?.name}</h3>
            </DateCellMatch>
            <StatusCellMatch className={checkStatusType(game.game.status)}>
              <p>{parseStatus(game.game.status)}</p>
            </StatusCellMatch>
          </MatchItem>
        ))}
      </ListWrapper>
    </Container>
  );
};

export default MatchesList;
const Container = styled.div`
  min-width: 380px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height:100%;
  overflow:hidden;
  .notCompleted {
    background: ${({ theme }) => theme.matchStatus.notCompleted.bg};
    p {
      color: ${({ theme }) => theme.matchStatus.notCompleted.p};
    }
  }

  .partialFactors {
    background: ${({ theme }) => theme.matchStatus.partialFactors.bg};
    p {
      color: ${({ theme }) => theme.matchStatus.partialFactors.p};
    }
  }

  .factorsSubmitted {
    background: ${({ theme }) => theme.matchStatus.partialFactors.bg};
    border: 1px solid ${({ theme }) => theme.matchStatus.partialFactors.p};
    p {
      color: ${({ theme }) => theme.matchStatus.partialFactors.p};
    }
  }

  .lineupSaved {
    background: ${({ theme }) => theme.matchStatus.lineupSaved.bg};
    p {
      color: ${({ theme }) => theme.matchStatus.lineupSaved.p};
    }
  }

  .lineupsSaved {
    background: ${({ theme }) => theme.matchStatus.lineupSaved.bg};
    border: 1px solid ${({ theme }) => theme.matchStatus.lineupSaved.p};
    p {
      color: ${({ theme }) => theme.matchStatus.lineupSaved.p};
    }
  }

  .lineupSubmitted {
    background: ${({ theme }) => theme.matchStatus.lineupSubmitted.bg};
    p {
      color: ${({ theme }) => theme.matchStatus.lineupSubmitted.p};
    }
  }

  .lineupsSubmitted {
    background: ${({ theme }) => theme.matchStatus.lineupSubmitted.bg};
    border: 1px solid ${({ theme }) => theme.matchStatus.lineupSubmitted.p};
    p {
      color: ${({ theme }) => theme.matchStatus.lineupSubmitted.p};
    }
  }

  .previewSubmitted {
    background: ${({ theme }) => theme.matchStatus.previewSubmitted.bg};
    p {
      color: ${({ theme }) => theme.matchStatus.previewSubmitted.p};
    }
  }

  .plConfirmed {
    background: ${({ theme }) => theme.matchStatus.plConfirmed.bg};
    p {
      color: ${({ theme }) => theme.matchStatus.plConfirmed.p};
    }
  }

  .plsConfirmed {
    background: ${({ theme }) => theme.matchStatus.plConfirmed.bg};
    border: 1px solid ${({ theme }) => theme.matchStatus.plConfirmed.p};
    p {
      color: ${({ theme }) => theme.matchStatus.plConfirmed.p};
    }
  }

  .pmCompleted {
    background: ${({ theme }) => theme.matchStatus.pmCompleted.bg};
    p {
      color: ${({ theme }) => theme.matchStatus.pmCompleted.p};
    }
  }

  .pmsCompleted {
    background: ${({ theme }) => theme.matchStatus.pmCompleted.bg};
    border: 1px solid ${({ theme }) => theme.matchStatus.pmCompleted.p};
    p {
      color: ${({ theme }) => theme.matchStatus.pmCompleted.p};
    }
  }

  .xiHome {
    background: ${({ theme }) => theme.matchStatus.xiCompleted.bg};
    p {
      color: ${({ theme }) => theme.matchStatus.xiCompleted.p};
    }
  }

  .xiCompleted {
    background: ${({ theme }) => theme.matchStatus.xiCompleted.bg};
    border: 1px solid ${({ theme }) => theme.matchStatus.xiCompleted.p};
    p {
      color: ${({ theme }) => theme.matchStatus.xiCompleted.p};
    }
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  column-gap: 10px;
  align-items: center;
  background: ${({ theme }) => theme.matchPreview.matchListHeaderBg};
  padding: 10px;
`;
const ListWrapper = styled.div`
display:flex;
flex-direction:column;
height:100%;
overflow:auto;
`

const LeagueCell = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  min-width: 160px;
  width: 100%;
  h1 {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.matchPreview.textColor};
  }
  img {
    width: 20px;
    height: 14px;
  }
`;
const DateCell = styled.div`
  min-width: 80px;
  width: 100%;
  padding-left: 5px;
  display: flex;
  column-gap: 5px;
  align-items: center;
  h1 {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.matchPreview.textColor};
  }
  svg {
    cursor: pointer;
  }
`;
const StatusCell = styled.div`
  min-width: 106px;
  padding-left: 20px;
  display: flex;
  column-gap: 5px;
  align-items: center;
  h1 {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.matchPreview.textColor};
  }
  svg {
    cursor: pointer;
  }
`;
const MatchItem = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  column-gap: 10px;
  align-items: center;
  padding: 5px 10px;
  min-height: 60px;
  background: ${(props) =>
    props.selected
      ? ({ theme }) => theme.matchPreview.matchSelectedBg
      : props.bg % 2
        ? ({ theme }) => theme.matchPreview.matchBg1
        : ({ theme }) => theme.matchPreview.matchBg2};
`;
const MatchCell = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  column-gap: 3px;
  min-width: 160px;
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${(props) =>
    props.postponed || props.cancelled
      ? "#5A37A3"
      : ({ theme }) => theme.matchPreview.textColor};
  }
`;
const DateCellMatch = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  min-width: 80px;
  p {
    font-size: 12px;
    color: ${({ theme }) => theme.matchPreview.textColor};
    line-height: 14px;
    font-weight: 500;
  }
  h3 {
    font-family: Roboto;
    font-size: 9px;
    font-weight: 500;
    line-height: 11px;
    opacity: 0.6;
    color: ${({ theme }) => theme.matchPreview.textColor};
  }
`;
const StatusCellMatch = styled.div`
  min-width: 106px;
  background: #fff9f9;
  border-radius: 4px;
  padding: 3px 0;
  display: flex;
  justify-content: center;

  p {
    text-align: center;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    color: red;
  }
`;
const MatchPostponed = styled.div`
  background: #ffffff;
  border: 1px solid #dcd5ed;
  border-radius: 4px;
  height: 16px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    text-align: center;
    color: #9868fe;
  }
`;

const MatchDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  h3 {
    font-family: Roboto;
    font-size: 9px;
    font-weight: 500;
    line-height: 11px;
    opacity: 0.6;
    color: ${({ theme }) => theme.matchPreview.textColor};
  }
    img{
height:14px;
width:18px;
    border-radius:4px;
    }
`;

const Line = styled.div`
 display: flex;
 gap:5px;
 align-items:center;
`