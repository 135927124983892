import { createContext, useCallback, useContext, useState } from "react";
import { addConversationMessage } from "../api/conversations/AddConversationMessage";
import { addMessageForPlayerSelection } from "../api/conversations/AddMessageForPlayerSelection";
import { getConversationForFixtureId } from "../api/conversations/ConversationForFixtureId";
import { getConversationForPlayerSelection } from "../api/conversations/ConversationForPlayerSelection";
import { addTeamConversationMessage } from "../api/conversations/AddMessageForTeam";
import { getConversationForTeam } from "../api/conversations/ConversationForTeam";
import { deleteMessageFromTeam } from "../api/conversations/DeleteMessageForTeam";
import { deleteMessageFromSelection } from "../api/conversations/DeleteMesageFromSelection";
import { deleteMessageFromConversationFixture } from "../api/conversations/DeleteMesageFromConversationFixture";

export const NotesServiceContext = createContext({
    messages: [],
    selectionConversation: [],
    temConversation: [],

    getMessages: () => { },
    sendMessage: () => { },
    deleteMessageFromFixture: () => { },

    getPlayerSelectionMessage: () => { },
    sendPlayerSelectionMessage: () => { },
    deletePlayerSelectionMessage: () => { },

    getMessagesForTeam: () => { },
    sendMessageTeamConversation: () => { },
    deleteMessageFromTeamConversation: () => { },


});

const NotesServiceProvider = ({ children }) => {
    const [messages, setMessages] = useState([]);
    const [selectionConversation, setSelectionConversation] = useState([]);
    const [temConversation, setTeamConversation] = useState([])

    const getMessages = useCallback(
        async (fixture_id, errorFunction) => {
            try {
                await getConversationForFixtureId(fixture_id,
                    function (response) {
                        setMessages(response.data.conversation);
                    },
                    function (error) {
                        errorFunction();
                    }
                );
            }
            catch (error) {
                errorFunction();
            }
        }

    );
    const sendMessage = useCallback(async (fixture_id, conversation_id, message, errorFunction) => {
        try {
            await addConversationMessage(
                conversation_id,
                message,
                function () {
                    getMessages(fixture_id);
                },
                function (error) {
                    errorFunction();
                }
            );
        }
        catch (error) {
            errorFunction();
        }
    });
    const deleteMessageFromFixture = useCallback(async (
        message_id,
        onSuccess,
        onError
    ) => {
        try {
            await deleteMessageFromConversationFixture(
                message_id,
                function (response) {
                    onSuccess(response);
                },
                function (error) {
                    onError(error);
                }
            );
        }
        catch (error) {
            onError(error);
        }
    });



    const getPlayerSelectionMessage = useCallback(
        async (
            fixture_id,
            selection_id,
            onSuccess,
            onError
        ) => {
            try {
                await getConversationForPlayerSelection(
                    fixture_id,
                    selection_id,
                    function (response) {
                        setSelectionConversation(response.data.selection_conversation);
                        onSuccess(response);
                    },
                    function (error) {
                        onError();
                    }
                );
            }
            catch (error) {
                onError();
            }
        }

    );

    const sendPlayerSelectionMessage = useCallback(async (
        selection_conversation_id,
        message,
        onSuccess,
        errorFunction) => {
        try {
            await addMessageForPlayerSelection(
                selection_conversation_id,
                message,
                function (response) {
                    onSuccess(response);
                },
                function (error) {
                    errorFunction(error);
                }
            );
        }
        catch (error) {
            errorFunction();
        }
    });

    const deletePlayerSelectionMessage = useCallback(async (
        message_id,
        onSuccess,
        onError
    ) => {
        try {
            await deleteMessageFromSelection(
                message_id,
                function (response) {
                    onSuccess(response);
                },
                function (error) {
                    onError(error);
                }
            );
        }
        catch (error) {
            onError(error);
        }
    });

    const getMessagesForTeam = useCallback(
        async (
            fixture_id,
            team_id,
            onSuccess,
            onError
        ) => {
            try {
                await getConversationForTeam(
                    fixture_id,
                    team_id,
                    function (response) {
                        // setTeamConversation(response.data.selection_conversation);
                        onSuccess(response);
                    },
                    function (error) {
                        onError(error);
                    }
                );
            }
            catch (error) {
                onError(error);
            }
        }

    );

    const sendMessageTeamConversation = useCallback(async (
        team_conversation_id,
        message,
        onSuccess,
        onError) => {
        try {
            await addTeamConversationMessage(
                team_conversation_id,
                message,
                function (response) {
                    onSuccess(response);
                },
                function (error) {
                    onError(error);
                }
            );
        }
        catch (error) {
            onError(error);
        }
    });

    const deleteMessageFromTeamConversation = useCallback(async (
        message_id,
        onSuccess,
        onError
    ) => {
        try {
            await deleteMessageFromTeam(
                message_id,
                function (response) {
                    onSuccess(response);
                },
                function (error) {
                    onError(error);
                }
            );
        }
        catch (error) {
            onError(error);
        }
    });


    return (
        < NotesServiceContext.Provider value={{
            messages, selectionConversation,
            getMessages, sendMessage, deleteMessageFromFixture,
            getPlayerSelectionMessage, sendPlayerSelectionMessage, deletePlayerSelectionMessage,
            getMessagesForTeam, sendMessageTeamConversation, deleteMessageFromTeamConversation
        }}>
            {children}
        </NotesServiceContext.Provider>
    );
};

export const useNotesService = () => useContext(NotesServiceContext);
export default NotesServiceProvider;