import React from "react";
import styled from "styled-components";

const Tablehead = (props) => {
  return (
    <Container>
      <tr>{props.children}</tr>
    </Container>
  );
};

export default Tablehead;
const Container = styled.thead`
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 1;
  tr {
    background: var(--gradient1);
  }
  th {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 18, 41, 0.8);
    text-transform: uppercase;
    padding: 18px 30px;
  }
`;
