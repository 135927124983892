export const sortBetsByKey = (
  betsList,
  filterKey,
  setFunction,
  ascending = true
) => {
  let oldBets = [...betsList];
  let sorted = oldBets.sort((a, b) => {
    return a["id"] > b["id"] ? 1 : -1;
  });

  sorted = sorted.sort((a, b) => {
    if (a[filterKey] === b[filterKey]) {
      return 0;
    }

    let val = 0;
    if (ascending) {
      val = a[filterKey] < b[filterKey] ? 1 : -1;
    } else {
      val = a[filterKey] > b[filterKey] ? 1 : -1;
    }

    return val;
  });

  setFunction(sorted);
};

export const sortLoggedBetsByBetKey = (
  betsList,
  filterKey,
  setFunction,
  ascending = true
) => {
  if (ascending) {
    let oldBets = [...betsList];
    const sorted = oldBets.sort((a, b) =>
      a.bet[filterKey] > b.bet[filterKey] ? 1 : -1
    );

    setFunction(sorted);
  } else {
    let oldBets = [...betsList];
    const sorted = oldBets.sort((a, b) =>
      a.bet[filterKey] < b.bet[filterKey] ? 1 : -1
    );

    setFunction(sorted);
  }
};
export const sortLoggedBetsByLoggedBetKey = (
  betsList,
  filterKey,
  setFunction,
  ascending = true
) => {
  if (ascending) {
    let oldBets = [...betsList];
    const sorted = oldBets.sort((a, b) =>
      a.logged_bet[filterKey] > b.logged_bet[filterKey] ? 1 : -1
    );
    setFunction(sorted);
  } else {
    let oldBets = [...betsList];
    const sorted = oldBets.sort((a, b) =>
      a.logged_bet[filterKey] < b.logged_bet[filterKey] ? 1 : -1
    );

    setFunction(sorted);
  }
};

export const sortLoggedBetsLeaguesTeams = (
  loggedBets,
  setFunction,
  ascending = true
) => {
  let oldBets = [...loggedBets];
  if (ascending) {
    const sorted = oldBets.sort((a, b) => {
      if (a.bet.competition_name === b.bet.competition_name) {
        return a.bet.home_team > b.bet.home_team ? 1 : -1;
      } else {
        return a.bet.competition_name > b.bet.competition_name ? 1 : -1;
      }
    });

    setFunction(sorted);
  } else {
    const sorted = oldBets.sort((a, b) => {
      if (a.bet.competition_name === b.bet.competition_name) {
        return a.bet.home_team > b.bet.home_team ? 1 : -1;
      } else {
        return a.bet.competition_name < b.bet.competition_name ? 1 : -1;
      }
    });

    setFunction(sorted);
  }
};

export const sortRsBetsByBetKey = (
  betsList,
  filterKey,
  setFunction,
  ascending = true
) => {

  if (ascending) {
    let oldBets = [...betsList];
    const sorted = oldBets.sort((a, b) =>
      a.bet[filterKey] > b.bet[filterKey] ? 1 : -1
    );

    setFunction(sorted);
  } else {
    let oldBets = [...betsList];
    const sorted = oldBets.sort((a, b) =>
      a.bet[filterKey] < b.bet[filterKey] ? 1 : -1
    );

    setFunction(sorted);
  }
};