import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as XIcon } from "../../assets/icons/xBtn.svg";
import PositionMenuGroupPLayers from "./Menu/PositionMenuGroupPLayers";
import SelectedPositionsMenuGroupPlayers from "./Menu/SelectedPositionsMenuGroupPlayers";
import {
  ATK_POSITIONS,
  DEF_POSITIONS,
  GK_POSITIONS,
  MID_POSITIONS,
} from "../../helper/Values/TeamFormation";

const GroupPlayersModal = ({
  closeWindow,
  squadData,
  setIsLoading,
  refreshOnClose,
  loadInitialData,
}) => {
  const [fightPosition, setFightPosition] = useState([]);

  const mapType = (position) => {
    let type;

    if (GK_POSITIONS.includes(position)) {
      type = "gk";
    }
    if (DEF_POSITIONS.includes(position)) {
      type = "defensive";
    }
    if (MID_POSITIONS.includes(position)) {
      type = "neutral";
    }
    if (ATK_POSITIONS.includes(position)) {
      type = "attack";
    }

    return type;
  };

  const initializeData = () => {
    let newFightingPositions = [];
    squadData.fight_for_groups.forEach((element) => {
      let players = element.player_in_groups.map((player) => {
        return player;
      });

      newFightingPositions.push({
        position: element.position,
        type: mapType(element.position),
        players: players,
      });
    });
    setFightPosition(newFightingPositions);
  };

  useEffect(() => {
    initializeData();
  }, [squadData]);

  return (
    <FullScreen>
      <Card>
        <Top>
          <h4>Group Players</h4>
          <button
            onClick={() => {
              closeWindow();
            }}
          >
            <XIcon />
          </button>
        </Top>
        <ContentContainer>
          <Subtitle>FIGHTING POSITION</Subtitle>
          <ListsWrapper>
            <PositionMenuGroupPLayers
              selectedValue={fightPosition}
              setSelectedValue={setFightPosition}
            />
            <Divider />
            <PlayersWrapper>
              <Subtitle>Select Players</Subtitle>
              {fightPosition?.map((item, index) => (
                <SelectedPositionsMenuGroupPlayers
                  key={index}
                  value={item}
                  setValue={setFightPosition}
                  squadData={squadData}
                  setIsLoading={setIsLoading}
                  refreshOnClose={refreshOnClose}
                  loadInitialData={loadInitialData}
                />
              ))}
            </PlayersWrapper>
          </ListsWrapper>
        </ContentContainer>
      </Card>
    </FullScreen>
  );
};

export default GroupPlayersModal;

const FullScreen = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  min-width: 1600px;
  left: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  z-index: 4;
  overflow: hidden;
  padding: 10vh 0;
`;

const Card = styled.div`
  position: sticky;
  margin-left: max(200px, 10vw);
  margin-right: max(620px, 70vw);
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.editLeague.bg};
  box-shadow: 0px 1px 8px rgba(46, 46, 46, 0.04);
  border-radius: 16px;
  height: 100%;
  min-width: 780px;
  overflow: hidden;

  @media (max-width: 1600px) {
    margin-left: 5%;
    margin-right: min(420px, 100%);
  }
  @media (min-width: 2000px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px 40px;
  align-items: center;
  background: ${({ theme }) => theme.editLeague.headerBg};
  h4 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.01em;
    text-align: left;
    color: ${({ theme }) => theme.squadManager.textColor};
    opacity: 0.8;
  }

  button {
    background: none;
    border: none;
    color: ${({ theme }) => theme.editLeague.menuTextColor};
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`;

const Bottom = styled.div`
  background: ${({ theme }) => theme.editLeague.headerBg};
  padding: 14px 40px;
  border-radius: 0 0 16px 16px;
  display: flex;
  justify-content: end;
  column-gap: 10px;
  width: 100%;
`;

const SaveBtn = styled.button`
  background: var(--primaryBlue);
  border: none;
  border-radius: 4px;
  padding: 10px 25px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: white;
  cursor: pointer;
  min-width: 120px;
  min-height: 40px;
`;
const ContentContainer = styled.div`
  height: 100%;
  overflow: auto;
  padding: 35px 40px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const Subtitle = styled.h2`
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  color: ${({ theme }) => theme.editLeague.groupsTextColor};
`;
const ListsWrapper = styled.div`
  display: flex;
  gap: 10px;
  height: 100%;
  overflow: hidden;
`;
const Divider = styled.div`
  display: flex;
  width: 3px;
  height: 100%;
  background: rgba(238, 242, 246, 1);
`;
const PlayersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  heght: 100%;
  overflow: auto;
  width: 100%;
`;
