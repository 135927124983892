import styled from "styled-components";

const RoleCell = (props) => {
  const checkRole = (role) => {
    if (role === "oddscompiler") {
      return "Quant";
    } else return role;
  };

  return (
    <Container>
      <p className="tablerole">{checkRole(props.roleType)}</p>
    </Container>
  );
};

export default RoleCell;
const Container = styled.td`

  .tablerole {
    text-transform: capitalize;
    opacity: 0.7;
    width: max-content;
    position: relative;
    background: #eef2f6;
    border-radius: 8px;
    padding: 7px 18px;
    color: rgba(50, 64, 83, 1);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
`;
