import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { signIn } from "../../api/authentication/Login";
import LocalStorage from "../../helper/Generic/LocalStorage";
import {
  notifyDismiss,
  notifyError,
  notifyLoading,
  notifyUpdate,
} from "../../helper/Generic/Notifications";
import { loginUser, selectUser } from "../../Redux/user/user";
import { ReactComponent as EyeSvg } from "../../assets/icons/eye.svg";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(selectUser);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const toastId = useRef(null);
  const [ready, setReady] = useState(false);

  // const whiteSwanDomain = /\S+@whiteswam.com/;
  // const adminDomain = /\S+@admin.com/;
  // const appssembleDomain = /\S+@appssemble.com/;
  // const gmailDomain = /\S+@gmail.com/;

  const handleInputEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleInputPassword = (e) => {
    setPassword(e.target.value);
  };
  const checkReady = () => {
    if (email.length > 5 && password.length > 5) {
      if (email.includes("@")) {
        setReady(true);
      } else setReady(false);
    } else {
      setReady(false);
    }
  };

  const makeSignIn = () => {
    signIn(
      email,
      password,
      function (response) {
        dispatch(loginUser(response.data.user));
        LocalStorage.setAuthenthicationToken(response.data.access_token);
        LocalStorage.setRefreshToken(response.data.refresh_token);
        notifyUpdate(toastId, "Signed in", toast.TYPE.SUCCESS);
        navigate("/leagues");
      },
      function (error) {
        notifyUpdate(
          toastId,
          "Email / password combination not valid",
          toast.TYPE.ERROR
        );
      }
    );
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSignIn();
    }
  };

  // const validateEmail = () => {
  //   return (
  //     whiteSwanDomain.test(email) ||
  //     adminDomain.test(email) ||
  //     appssembleDomain.test(email) ||
  //     gmailDomain.test(email)
  //   );
  // };

  const handleSignIn = () => {
    notifyDismiss(toastId);
    notifyLoading(toastId, "Logging in... ");
    makeSignIn();

    // if (validateEmail()) {
    //   notifyLoading(toastId, "Loggin in... ");
    //   makeSignIn();
    // } else {
    //   notifyError("Invalid email domain ");
    // }
  };

  useEffect(() => {
    if (user) {
      if (
        location.state &&
        location.state.backTo !== null &&
        location.state.backTo !== undefined
      ) {

        navigate(`${location.state.backTo}`);
      }
    }
  }, []);

  useEffect(() => {
    checkReady();
  }, [email, password]);

  return (
    <Container>
      <Card onKeyDown={(e) => handleKeyDown(e)}>
        <Top>
          <h1>Welcome</h1>
        </Top>
        <InputWrapper>
          <label>Email</label>
          <input
            type={"email"}
            placeholder={"@email.com"}
            value={email}
            onChange={(e) => handleInputEmail(e)}
          ></input>
        </InputWrapper>
        <InputWrapper>
          <label>Password</label>
          <div className="passwdInput">
            <input
              type={visible ? "text" : "password"}
              placeholder={"password"}
              value={password}
              onChange={(e) => handleInputPassword(e)}
            ></input>
            <EyeSvg onClick={() => setVisible(!visible)} />
          </div>
        </InputWrapper>
        <div className="forgotPasswd">
          <h2 onClick={() => navigate("/forgotPassword")}>Forgot Password</h2>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button disabled={!ready} onClick={() => handleSignIn()}>
            Login
          </button>
        </div>
      </Card>
    </Container>
  );
};

export default Login;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(249, 249, 249, 1);
  height: var(--pageHeight);
  overflow: auto;
  min-height: 600px;
  min-width: 600px;
`;
const Card = styled.div`
  min-width: 600px;
  min-height: 450px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 25px;
  background-color: white;
  padding: 80px 145px;
  border: 2px solid #f8f9fb;
  box-shadow: 0px 1px 8px rgba(46, 46, 46, 0.04);
  border-radius: 16px;
  overflow: hidden;
  .forgotPasswd {
    display: flex;
    width: 100%;
    justify-content: end;
  }
  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 42px;
    line-height: 56px;
  }
  h2 {
    margin-top: -10px;
    color: var(--primaryBlue);
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    cursor: pointer;
  }
  button {
    padding: 15px 0;
    min-width: 180px;
    width: 100%;
    border-radius: 4px;
    border: none;
    background: var(--primaryBlue);
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    :disabled {
      opacity: 0.5;
    }
  }
  img {
    height: 58px;
    width: 58px;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  min-width: 20vw;
  width: 100%;
  .passwdInput {
    display: flex;
    position: relative;
    svg {
      cursor: pointer;
      position: absolute;
      top: 12px;
      right: 10px;
      height: 14px;
      width: 14px;
    }
  }
  input {
    width: 100%;
    border: 1px solid #d9dfe7;
    border-radius: 4px;
    padding: 10px 30px 10px 16px;
    ::placeholder {
      color: #b3b9c0;
    }
    :focus-visible {
      outline: 0px;
      //outline-offset: 1px;
      border: 1px solid var(--primaryBlue);
    }
  }
  label {
    color: #001229;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  svg {
    height: 58px;
    width: 58px;
  }
`;
