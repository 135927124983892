import React, { Suspense, useEffect, useState } from 'react'
import { Popover } from 'react-tiny-popover'
import styled from 'styled-components'
import { ReactComponent as InfoIcon } from "../../../assets/icons/LeaguesIcons/info.svg"
import { format_time_to_timezone } from '../../../helper/Generic/dateTimeFormat'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../Redux/user/user'
import { useLeaguesDataService } from '../../../context/useLeaguesDataService'
import { notifyError } from '../../../helper/Generic/Notifications'
import { NumericFormat } from 'react-number-format'
import { ReactComponent as XBtn } from "../../../assets/icons/xBtn.svg";


const AttendanceCard = ({ teamId, fixtureId, team, setIsOpen }) => {
    const { get_last_home_confirmed_attendance } = useLeaguesDataService()
    const user = useSelector(selectUser);
    const [loading, setLoading] = useState(false);
    const [confirmedAttendance, setConfirmedAttendance] = useState([])

    useEffect(() => {
        if (!teamId || !fixtureId) {

            return
        }
        setLoading(true);
        get_last_home_confirmed_attendance(
            teamId,
            fixtureId,
            (response) => {
                setConfirmedAttendance(response.data.confirmed_attendances)
                setLoading(false);
            },
            (error) => {
                console.log(error)
                notifyError("Something wrong...")
                setLoading(false)
            }
        )
    }, [])

    return (
        <Card>
            <Content>
                <TitleLine>
                    <h1>CONFIRMED ATTENDANCES</h1>
                    <CloseBtn onClick={() => setIsOpen(false)}>
                        <XBtn />
                    </CloseBtn>
                </TitleLine>
                <AttendanceList>
                    {confirmedAttendance.length > 0 ? confirmedAttendance.map((element, index) => (
                        <AttendanceElement key={index}>
                            <AttendanceValue>
                                <NumericFormat
                                    readOnly
                                    thousandSeparator=","
                                    value={element.attendance}
                                >

                                </NumericFormat>

                            </AttendanceValue>
                            <Match>{team.name}-{element.opponent}</Match>
                            <MatchDate>{format_time_to_timezone(
                                element.date,
                                user.timezone
                            ).format("DD.MM.YYYY - HH:mm")}</MatchDate>
                        </AttendanceElement>

                    ))
                        : <AttendanceValue>No data for this team</AttendanceValue>}
                </AttendanceList>
            </Content>
        </Card>

    )
}


const AttendanceModal = ({ teamId, fixtureId, team }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Container>
            <Popover
                isOpen={isOpen}
                positions={["top"]}
                transform={{ top: -20 }}
                transformMode='relative'
                content={
                    <AttendanceCard teamId={teamId} fixtureId={fixtureId} team={team} setIsOpen={setIsOpen} />
                }
            >
                <InfoAttendanceButton onClick={() => setIsOpen(!isOpen)}>
                    <InfoIcon />
                </InfoAttendanceButton>
            </Popover>
        </Container>
    )
}

export default AttendanceModal
const Container = styled.div`
`
const InfoAttendanceButton = styled.button`
display:flex;
align-items:center;
jusitfy-content:center;
border:none;
background:none;
cursor:pointer;
color:${({ theme }) => theme.matchPreview.pitchConditionTextColor};
`
const Card = styled.div`
display:flex;
flex-direction:column;
gap:20px;
min-width:250px;
max-width:250px;
min-height:100px;
border-radius: 10px;
background:red;
padding: 15px;
box-shadow: 0px 0px 10px rgba(98, 101, 112, 0.15);
background: ${({ theme }) => theme.editLeague.readOnlyInputs};
`
const Loader = styled.div`
display:flex;
justify-content:center;
align-items:center;
margin-top:auto;
margin-bottom:auto;
`
const TitleLine = styled.div`
  display: flex;
  row-gap: 5px;
  h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
`;
const Content = styled.div`
display:flex;
flex-direction:column;
gap:20px;
`
const AttendanceList = styled.div`
display: flex;
flex-direction: column;
row-gap: 20px;
`
const AttendanceValue = styled.div`
 font-family: "Roboto";
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 15px;
letter-spacing: 0.02em;
color: ${({ theme }) => theme.matchPreview.pitchConditionTextColor};
        input{
            font-family: "Roboto";
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 15px;
letter-spacing: 0.02em;
color: ${({ theme }) => theme.matchPreview.pitchConditionTextColor};
background:none;
border:none;
outline:none;

        }
`
const Match = styled.p`
font-family: "Roboto";
font-style: normal;
font-weight: 700;
font-size: 10px;
line-height: 140%;
color: ${({ theme }) => theme.matchPreview.pitchConditionTextColor};
`
const MatchDate = styled.p`
font-family: "Roboto";
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 140%;
color: ${({ theme }) => theme.matchPreview.pitchConditionTextColor};
`
const AttendanceElement = styled.div`
display: flex;
flex-direction: column;
border-left: 4px solid;
border-color: ${({ theme }) => theme.matchPreview.pitchConditionTextColor};
padding-left: 5px;
`

const CloseBtn = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  margin-left:auto;

  svg{
      color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
`;