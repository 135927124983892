export const displayPlayersNameByOption = (data, user) => {
    if (!data || !data.player) {
        return
    }
    var player_full_name = data.player?.first_name + " " + data.player?.last_name;

    if (user.use_short_player_names && data?.player?.short_name && data?.player?.short_name != "") {
        player_full_name = data.player.short_name
    }

    if (user.use_other_player_names && data?.player?.other_player_name && data?.player?.other_player_name != "") {
        player_full_name = data.player.other_player_name
    }

    return player_full_name
}