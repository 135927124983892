import { getCompetitionsForCountryId } from "../api/competitions/GetCompetitionForCountryId";
import { getCompetitionsForCurrentUser } from "../api/competitions/GetCompetitionsForCurrentUser";
import { getCountriesForCurrentUser } from "../api/competitions/GetCountriesForCurrentUser";
import { notifyError } from "./Generic/Notifications";

//Get countries for current user
export const getCountries = (setCountriesList) => {
  getCountriesForCurrentUser(
    function (response) {
      let sortedCountries = response.data.countries.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      setCountriesList(sortedCountries);
    },
    function (error) {
      notifyError("Something wrong");
    }
  );
};
//Get all competitions for current user
export const getCompetitions = (setCompetitions) => {
  getCompetitionsForCurrentUser(
    function (response) {
      let sortedCompetition = response.data.competitions.sort((a, b) =>
        a.competition > b.competition ? 1 : -1
      );
      setCompetitions(sortedCompetition);
    },
    function (error) {
      notifyError("Something wrong");
    }
  );
};
//Get competitions from selected countries for current user
export const getCompetitionsForId = (countriesIds, onSuccess) => {
  getCompetitionsForCountryId(
    countriesIds,
    function (response) {
      let sortedCompetition = response.data.competitions.sort((a, b) =>
        a.competition > b.competition ? 1 : -1
      );
      onSuccess(sortedCompetition);
    },
    function (error) {
      notifyError("Something wrong");
    }
  );
};
