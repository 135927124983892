import { formatted_date_for_backend } from "../../helper/Generic/dateTimeFormat";
import { PATCH } from "../APiHelper/API";


export const updateRsSelection = (bet_id, price, stake, active_until, onSuccess, onError) => {

    const data = {
        id: bet_id,
        price: price,
        stake: stake,
        active_until: formatted_date_for_backend(active_until),
    }
    PATCH("/api/v1/runshop_bets/update_selection", data, onSuccess, onError);
}