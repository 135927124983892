import { POST } from "../APiHelper/API";
import { formatted_date_for_backend } from "../../helper/Generic/dateTimeFormat";

export const logBet = (
  parked_bet_id,
  broker,
  ret_stake,
  confirmed_price,
  clash,
  oppo,
  time_ret,
  no_return,
  time_req,
  onSuccess,
  onError
) => {
  const data = {
    parked_bet_id: parked_bet_id,
    broker: broker,
    ret_stake: ret_stake,
    confirmed_price: confirmed_price,
    clash: clash,
    oppo: oppo,
    time_ret: formatted_date_for_backend(time_ret),
    no_return: no_return,
    time_req: time_req
  };

  POST("/api/v1/bets/log_bet", data, onSuccess, onError);
};
