
import styled from "styled-components";
import AwayTeam from "./AwayTeam.js";

import HomeTeam from "./HomeTeam.js";


const TeamVsTeam = ({
    homeTeam,
    awayTeam,
    homeTeamStats,
    awayTeamStats,
    handleChangeHomeTeamStats,
    handleChangeAwayTeamStats,
    readyToSubmitHome,
    setReadyToSubmitHome,
    readyToSubmitAway,
    setReadyToSubmitAway,
    statusCompleted,
    navigateToSquadManager,
    handleUpdateFactorsPartial,
    shouldUpdate,
    selectedFixture,
    shouldRefreshHomeStats,
    shouldRefreshAwayStats
}) => {

    return (
        <Container>
            <HomeTeam
                team={homeTeam}
                stats={homeTeamStats}
                handleChangeHomeTeamStats={handleChangeHomeTeamStats}
                readyToSubmitHome={readyToSubmitHome}
                setReadyToSubmitHome={setReadyToSubmitHome}
                statusCompleted={statusCompleted}
                navigateToSquadManager={navigateToSquadManager}
                handleUpdateFactorsPartial={handleUpdateFactorsPartial}
                shouldUpdate={shouldUpdate}
                selectedFixture={selectedFixture}
                shouldRefreshHomeStats={shouldRefreshHomeStats}
            />
            <AwayTeam
                team={awayTeam}
                stats={awayTeamStats}
                handleChangeAwayTeamStats={handleChangeAwayTeamStats}
                readyToSubmitAway={readyToSubmitAway}
                setReadyToSubmitAway={setReadyToSubmitAway}
                statusCompleted={statusCompleted}
                navigateToSquadManager={navigateToSquadManager}
                handleUpdateFactorsPartial={handleUpdateFactorsPartial}
                shouldUpdate={shouldUpdate}
                shouldRefreshAwayStats={shouldRefreshAwayStats}
            />
        </Container>
    );
};

export default TeamVsTeam;
const Container = styled.div`
display:flex;
column-gap:20px;
align-items:start;
`;
