import cloneDeep from "lodash.clonedeep";

export const initializePositionWithFirstPosition = (playerList) => {
    let newState = cloneDeep(playerList);

    newState.forEach(element => {
        if (!element.position) {
            element.position = element.player.position_1
        }
    });

    return newState
}