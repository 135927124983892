import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";
import { ReactComponent as RemoveIcon } from "../assets/icons/LeaguesIcons/removeBin.svg";
import { ReactComponent as BinSvg } from "../assets/icons/bin.svg";
import { ReactComponent as AddIcon } from "../assets/icons/LeaguesIcons/plusIconBold.svg"
import { ReactComponent as TransferIcon } from "../assets/icons/LeaguesIcons/transferIcon.svg"
import { ReactComponent as WarningIcon } from "../assets/icons/LeaguesIcons/WarningIcon.svg"
import { ReactComponent as RevertTransferIcon } from "../assets/icons/LeaguesIcons/revert-transfer.svg"
import { ReactComponent as XIcon } from "../assets/icons/xBtn.svg";

const ButtonWithConfirmation = ({ action, item, variant, disableButton }) => {
    const TRANSFER = "transfer";
    const REVERT_TRANSFER = "revert-transfer"
    const REVERT_DELETE = "revert-delete"
    const PRE_SEASON_OVERWRITE = "pre-season-overwite";
    const NEW_PLAYERS = "new-players";
    const CLOSE = "close";
    const SHORT_NAMES = "short-names";
    const OTHER_NAMES = "other-names";

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRemove = () => {
        if (item) {
            action(item);
            setOpen(false);
        }
        else {
            action();
            setOpen(false);
        }

    };

    const DisplayVariant = ({ variant }) => {
        let displayItem = (
            <ActionButton onClick={() => handleClickOpen()} disabled={disableButton}>
                SELECT VARIANT
            </ActionButton>
        );

        if (variant == NEW_PLAYERS)
            displayItem = (
                <ActionButton onClick={() => handleClickOpen()} disabled={disableButton}>
                    <AddIcon />
                    Add new players
                </ActionButton>
            )

        if (variant == PRE_SEASON_OVERWRITE)
            displayItem = (
                <ActionButton onClick={() => handleClickOpen()} disabled={disableButton}>
                    <WarningIcon />
                    Pre-season squad overwrite
                </ActionButton>
            )

        if (variant == TRANSFER)
            displayItem = (
                <SmallActionButton onClick={() => handleClickOpen()} disabled={disableButton} >
                    <TransferIcon />
                </SmallActionButton>
            )
        if (variant == REVERT_TRANSFER)
            displayItem = (
                <ActionButton onClick={() => handleClickOpen()} disabled={disableButton} >
                    <RevertTransferIcon />
                    Revert
                </ActionButton>
            )
        if (variant == REVERT_DELETE)
            displayItem = (
                <ActionButton onClick={() => handleClickOpen()} disabled={disableButton} >
                    <RevertTransferIcon />
                    Revert
                </ActionButton>
            )

        if (variant == CLOSE)
            displayItem = (
                <XButton onClick={() => handleClickOpen()} disabled={disableButton}>
                    <XIcon />
                </XButton>
            )

        if (variant == SHORT_NAMES)
            displayItem = (
                <ActionButton onClick={() => handleClickOpen()} disabled={disableButton}>
                    Overwrite short names
                </ActionButton>
            )
        if (variant == OTHER_NAMES)
            displayItem = (
                <ActionButton onClick={() => handleClickOpen()} disabled={disableButton}>
                    Overwrite other short names
                </ActionButton>
            )

        return displayItem;
    };

    const DisplayDialogVariant = ({ variant }) => {
        let displayDialog = (
            <DialogContentText id="alert-dialog-description">
                By continuing this action, all the old players and data — selections and
                squads will be deleted and all the players from WYScout will be added
                for this team, are you sure you want to continue?
            </DialogContentText>
        );
        if (variant == NEW_PLAYERS)
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    By continuing this action, all new players will be added
                    for this team, are you sure you want to continue?
                </DialogContentText>
            )
        if (variant == TRANSFER)
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    By continuing this action, you will set this player as transfered, are you sure you want to continue?
                </DialogContentText>
            )
        if (variant == REVERT_TRANSFER)
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    By continuing this action, you will revert the action of transfer player, are you sure you want to continue?
                </DialogContentText>
            )
        if (variant == REVERT_DELETE)
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    By continuing this action, you will revert the action of deleting this item, are you sure you want to continue?
                </DialogContentText>
            )
        if (variant == CLOSE)
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    By continuing this action, you will close the window and not all changes will be saved.
                    Do you want to close this windows without save?
                </DialogContentText>
            )
        if (variant == SHORT_NAMES)
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    This will overwrite all the short names, with the current ones, are you sure you want to do this?
                </DialogContentText>
            )
        if (variant == OTHER_NAMES)
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    This will overwrite all the other player names, with the current ones, are you sure you want to do this?
                </DialogContentText>
            )

        return displayDialog;
    };

    return (
        <div>
            <DisplayVariant variant={variant} />

            <Dialog

                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
                <DialogContent>
                    <DisplayDialogVariant variant={variant} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button style={{ color: "red" }} onClick={handleRemove} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ButtonWithConfirmation;

const ActionButton = styled.button`
display:flex;
align-items:center;
column-gap:5px;
  background:${({ theme }) => theme.editLeague.buttonWithConfirmationBg};
  height: 40px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.editLeague.buttonWithConfirmationBorder};
  padding: 0 14px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.editLeague.buttonWithConfirmationTextColor};
  cursor: pointer;

  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
  svg{
    stroke:${({ theme }) => theme.editLeague.buttonWithConfirmationTextColor};
  }
`;

const SmallActionButton = styled.button`
display:flex;
align-items:center;
justify-content:center;
column-gap:5px;
  background:${({ theme }) => theme.editLeague.buttonWithConfirmationBg};
  height: 40px;
  width:40px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.editLeague.buttonWithConfirmationBorder};
  padding: 0 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.editLeague.buttonWithConfirmationTextColor};
  cursor: pointer;

  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`
const XButton = styled.button`

    background: none;
    border: none;
    cursor: pointer;

    svg{
        color: ${({ theme }) => theme.editLeague.headerColor};
     }

`