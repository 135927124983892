import React from "react";
import styled from "styled-components";
import { ReactComponent as SortSvg } from "../../assets/icons/BettingIcons/sort.svg";
const SortCell = ({ name, onClick }) => {
  return (
    <Container>
      <p>{name}</p>
      <SortSvg onClick={onClick} />
    </Container>
  );
};

export default SortCell;
const Container = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
  position: relative;
  min-width: 60x;

  p {
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.02em;
  }
  svg {
    position: absolute;
    right: -12px;
    cursor: pointer;
  }
`;
