import { formatted_date_for_backend } from "../../helper/Generic/dateTimeFormat";
import { POST } from "../APiHelper/API";

export let getGamesRequest;

export const getGames = (
  data,
  onSuccess,
  onError
) => {

  getGamesRequest = POST(`/api/v1/games`, data, onSuccess, onError, true, getGamesRequest);
};
