import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as YellowCard } from "../../assets/icons/LeaguesIcons/yellowCard.svg";
import { ReactComponent as RedCard } from "../../assets/icons/LeaguesIcons/redCard.svg";
import LoaderSpinner from '../../helper/LoaderSpinner';
import { ATK_POSITIONS, ATTACK, DEFENSIVE, DEF_POSITIONS, GK, GK_POSITIONS, MIDFIELD, MID_POSITIONS } from '../../helper/Values/TeamFormation';
import { type } from '@testing-library/user-event/dist/type';
import { isValid } from 'js-base64';
import { useSelector } from 'react-redux';
import { selectUser } from '../../Redux/user/user';
import moment from 'moment';
import { format_time_to_timezone } from '../../helper/Generic/dateTimeFormat';

const Stats = ({ value, text, convertToInt, isPercent }) => {
    var val = parseFloat(value).toFixed(2);

    if (convertToInt) {
        val = parseInt(value)
    }

    return (
        <StatsContainer>
            {value != null && !isNaN(value) ?
                (
                    isPercent ?
                        <h1>{val} %</h1>
                        :
                        <h1>{val}</h1>

                ) :
                (<h1>-</h1>)}

            <h2>{text}</h2>
        </StatsContainer>
    )
}

const CardsStats = ({ text, yellowCards, redCards, isValid }) => {

    return (
        <StatsContainer>
            <div style={{ display: 'flex', gap: "2px" }}>
                <h1>{yellowCards != null && !isNaN(yellowCards) ? yellowCards : "-"}</h1>
                <h1>{redCards != null && !isNaN(redCards) ? `(${redCards})` : "(-)"}</h1>
            </div>
            <div>
                <YellowCard /><RedCard />
            </div>
            <h2>{text}</h2>
        </StatsContainer>
    )
}

const StatsType = ({ type }) => {
    let cell;
    switch (type) {
        case GK:
            cell = <GKCell>
                <h1>GOALKEEPER</h1>
            </GKCell>
            break;
        case MIDFIELD:
            cell = <NTCell>
                <h1>NEUTRAL</h1>
            </NTCell>
            break;
        case ATTACK:
            cell = <ATKCell>
                <h1>OFFENSIVE</h1>
            </ATKCell>
            break;
        case DEFENSIVE:
            cell = <DEFCell>
                <h1>DEFENSIVE</h1>
            </DEFCell>
            break;

        default:
            break;
    }
    return cell
}

const PlayerMetricsSeasonCard = ({
    playerStatsPopupLoading,
    playerAverageStats,
    playerTeam,
    seasonAverage,
    fixture

}) => {
    const user = useSelector(selectUser);
    const [playerType, setPlayerType] = useState();
    const ref = useRef()

    var fullName = "-"
    if (playerAverageStats?.player_data) {
        fullName = playerAverageStats?.player_data.first_name + " " + playerAverageStats?.player_data.last_name;
    }

    if (user.use_short_player_names && playerAverageStats?.player_data.short_name && playerAverageStats?.player_data.short_name != "") {
        fullName = playerAverageStats.player_data.short_name
    }
    if (user.use_other_player_names && playerAverageStats?.player_data.other_player_name && playerAverageStats?.player_data.other_player_name != "") {
        fullName = playerAverageStats.player_data.other_player_name
    }


    const checkPlayerType = () => {
        if (!playerAverageStats.player_data) {
            return
        }
        let type;

        if (GK_POSITIONS.includes(playerAverageStats?.player_data.position_1)) {
            type = GK
        } else
            if (DEF_POSITIONS.includes(playerAverageStats?.player_data.position_1)) {
                type = DEFENSIVE
            }
            else
                if (MID_POSITIONS.includes(playerAverageStats?.player_data.position_1)) {
                    type = MIDFIELD
                }
                else
                    if (ATK_POSITIONS.includes(playerAverageStats?.player_data.position_1)) {
                        type = ATTACK
                    }

        setPlayerType(type)
    }

    const goalkeperInfo = () => {

        return (
            <StatsWrapper>
                <StatsType type={GK} />
                <StatsGroup>
                    <Stats
                        value={playerAverageStats?.gk_conceded_goals}
                        text={"GOALS CONCEDED"}
                        convertToInt={!seasonAverage}
                    />
                    ---

                    <Stats
                        value={playerAverageStats?.xg_save}
                        text={"XG CONCEDED "}
                    />
                </StatsGroup>
                <StatsGroup>
                    <Stats
                        value={playerAverageStats?.gk_aerial_duels}
                        text={"AERIAL DUELS"}
                        convertToInt={!seasonAverage}
                    />
                    ---
                    <Stats
                        value={playerAverageStats?.gk_aerial_duels_won}
                        text={"AERIAL DUELS WON"}
                        isPercent
                    />
                </StatsGroup>

            </StatsWrapper>
        )
    }

    const neutralInfo = () => {
        return (
            <StatsWrapper >
                <StatsType type={MIDFIELD} />
                <StatsGroup>
                    <Stats
                        value={playerAverageStats?.recoveries}
                        text={"BALL RECOVERIES"}
                        convertToInt={!seasonAverage}
                    />
                    ---
                    <Stats
                        value={playerAverageStats?.key_passes}
                        text={"KEY PASSES"}
                        convertToInt={!seasonAverage}
                    />
                </StatsGroup>
                <StatsGroup>
                    <Stats
                        value={playerAverageStats?.interceptions}
                        text={"INTERCEPTIONS"}
                        convertToInt={!seasonAverage}
                    />
                    ---
                    <Stats
                        value={playerAverageStats?.forward_passes}
                        text={"FORWARD PASSES"}
                        convertToInt={!seasonAverage}
                    />
                </StatsGroup>
            </StatsWrapper>
        )
    }
    const attackInfo = () => {
        return (
            <StatsWrapper key={"attackInfo"} >
                <StatsType type={ATTACK} />
                <StatsGroup>
                    <Stats
                        value={playerAverageStats?.successful_dribbles}
                        text={"SUCCESFUL DRIBBLES"}
                        convertToInt={!seasonAverage}
                    />
                    ---

                    <Stats
                        value={playerAverageStats?.key_passes}
                        text={"KEY PASSES"}
                        convertToInt={!seasonAverage}
                    />
                </StatsGroup>
                <StatsGroup>

                    <Stats
                        value={playerAverageStats?.shots_on_target}
                        text={"SHOTS ON TARGET"}
                        convertToInt={!seasonAverage}
                    />
                    ---
                    <Stats
                        value={playerAverageStats?.opponent_half_recoveries}
                        text={"OPP. HALF RECOVERIES"}
                        convertToInt={!seasonAverage}
                    />
                </StatsGroup>
            </StatsWrapper>
        )
    }

    const deffensiveInfo = () => {
        return (
            <StatsWrapper key={"deffInfo"} >
                <StatsType type={DEFENSIVE} />
                <StatsGroup>
                    <Stats
                        value={playerAverageStats?.defensive_duels}
                        text={"DEFENSIVE DUELS"}
                        convertToInt={!seasonAverage}
                    />
                    ---
                    <Stats
                        value={playerAverageStats?.new_defensive_duels_won}
                        text={"DEFENSIVE DUELS WON"}
                        isPercent

                    />

                </StatsGroup>
                <StatsGroup>
                    <Stats
                        value={playerAverageStats?.interceptions}
                        text={"INTERCEPTIONS"}
                        convertToInt={!seasonAverage}
                    />
                    ---
                    <Stats
                        value={playerAverageStats?.shots_blocked}
                        text={"SHOTS BLOCKED"}
                        convertToInt={!seasonAverage}
                    />
                </StatsGroup>
            </StatsWrapper>
        )
    }

    const displayGeneralStats = () => {
        let display = <></>

        if (playerType == GK) {
            display =
                <>
                    <Stats
                        value={playerAverageStats?.starts}
                        text={"STARTS"}
                        convertToInt
                    />
                    <StatsGroup>
                        <CardsStats
                            yellowCards={parseInt(playerAverageStats?.yellow_cards)}
                            redCards={parseInt(playerAverageStats?.red_cards)}
                            text={"CARDS"}
                        />
                        ---
                        <Stats
                            value={playerAverageStats?.minutes_played}
                            text={"MINS.PLAYED"}
                            convertToInt={true}
                        />
                    </StatsGroup>
                    <StatsGroup>
                        <Stats
                            value={playerAverageStats?.gk_clean_sheets}
                            text={"CLEAN SHEETS"}
                            convertToInt
                        />
                        ---
                        <Stats
                            value={playerAverageStats?.gk_saves}
                            text={"SAVES"}
                            convertToInt
                        />
                    </StatsGroup>
                    <StatsGroup>
                        <Stats
                            value={playerAverageStats?.assists}
                            text={"ASSISTS"}
                            convertToInt
                        />
                        ---
                        <Stats
                            value={playerAverageStats?.xg_assist}
                            text={"EXPECTED ASSISTS"}
                        />
                    </StatsGroup>
                </>


        } else {
            display =
                <>
                    <Stats
                        value={playerAverageStats?.starts}
                        text={"STARTS"}
                        convertToInt
                    />
                    <StatsGroup>
                        <CardsStats
                            yellowCards={parseInt(playerAverageStats?.yellow_cards)}
                            redCards={parseInt(playerAverageStats?.red_cards)}
                            text={"CARDS"}
                        />
                        ---
                        <Stats
                            value={playerAverageStats?.minutes_played}
                            text={"MINS.PLAYED"}
                            convertToInt={true}
                        />
                    </StatsGroup>
                    <StatsGroup>
                        <Stats
                            value={playerAverageStats?.goals}
                            text={"GOALS"}
                            convertToInt
                        />
                        ---
                        <Stats
                            value={playerAverageStats?.xg_shot}
                            text={"EXPECTED GOALS"}
                        />
                    </StatsGroup>
                    <StatsGroup>
                        <Stats
                            value={playerAverageStats?.assists}
                            text={"ASSISTS"}
                            convertToInt
                        />
                        ---
                        <Stats
                            value={playerAverageStats?.xg_assist}
                            text={"EXPECTED ASSISTS"}
                        />
                    </StatsGroup>
                </>
        }
        return display

    }

    const displayInfo = () => {
        let display = <></>
        switch (playerType) {
            case GK:
                display = goalkeperInfo();
                break;
            case DEFENSIVE:
                display = [deffensiveInfo(), neutralInfo(), attackInfo()];
                break;
            case MIDFIELD:
                display = [neutralInfo(), deffensiveInfo(), attackInfo()];
                break;
            case ATTACK:
                display = [attackInfo(), neutralInfo(), deffensiveInfo()];
                break;

            default:
                break;
        }
        return display
    }

    const checkHeight = () => {
        let height = 660;
        if (playerType == GK) {
            height = 430;
        }
    }

    useEffect(() => {
        if (!playerAverageStats || playerAverageStats.length == 0) {
            return
        }
        checkPlayerType();

    }, [playerAverageStats])

    return (
        <Card ref={ref}>
            {
                playerStatsPopupLoading ?
                    <LoaderWrap>
                        < LoaderSpinner />
                    </LoaderWrap > :
                    <>
                        <FlagTitle>
                            {playerTeam ? <img src={playerTeam.logo_url} /> : ""}
                            <p> {playerTeam ? playerTeam.name : "-"}</p>
                        </FlagTitle>
                        {fixture &&
                            <FixtureDetails>
                                <p><label>{fixture.team1.name}</label> - <label >{fixture.team2.name}</label> {moment(format_time_to_timezone(fixture.start_time, user.timezone)).format("DD/MM/yyyy - HH:mm")}</p>
                            </FixtureDetails>}


                        <PlayerInfoWrapper>
                            <Player>
                                <h1>{fullName}</h1>
                                <h2>{seasonAverage ? "League Season Totals" :
                                    "Player Performance"
                                }</h2>
                            </Player>
                            <PlayerPhoto>
                                {playerAverageStats && <img src={playerAverageStats?.player_data?.picture_url} alt='playerAvatar' />}
                            </PlayerPhoto>
                        </PlayerInfoWrapper>

                        <StatsWrapper>
                            {displayGeneralStats()}
                            <Separator />
                            {seasonAverage && <Player>
                                <h2>Average Per 90 min </h2>
                            </Player>}
                            {displayInfo()}
                        </StatsWrapper>




                    </>
            }



        </Card >
    )
}

export default PlayerMetricsSeasonCard
const Card = styled.div`
min-width:420px;
height:100%;
min-height:${(props) => props.specificHeight ? `${props.specificHeight}px` : "100%"};
max-width:380px;
top:0;
left:0;
z-index:10;
background:${({ theme }) => theme.playerPerformaceCard.cardBg};
border-radius:10px;

padding:17px 22px;
display:flex;
flex-direction:column;
gap:10px;
box-shadow:1px 2px 2px rgba(0,0,0,.1),2px 4px 4px rgba(0,0,0,.1),3px 6px 6px rgba(0,0,0,.1);
`
const FlagTitle = styled.div`
display:flex;
align-items:center;
gap:10px;
p{
    color:${({ theme }) => theme.playerPerformaceCard.cardColor};
    font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 16px;
}
img{
    height:23px;
}
`
const PlayerInfoWrapper = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
`
const PlayerPhoto = styled.div`
position:relative;
display:flex;
align-items:center;
justify-content:center;
height:78px;
width:78px;
min-height:78px;
min-width:78px;
overflow:hidden;
img{
position:relative;
height:100%;
width:100%;
object-fit:contain;
}
`
const Player = styled.div`
display:flex;
flex-direction:column;
gap:10px;
justify-content:space-between;
h1{
    color:${({ theme }) => theme.playerPerformaceCard.cardColor};
    font-family: Roboto;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    
}

h2{
    opacity:0.6;
    color:${({ theme }) => theme.playerPerformaceCard.cardColor};
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    
}
`
const StatsGroup = styled.div`
display:flex;
align-items:center;
gap:10px;
color:${({ theme }) => theme.playerPerformaceCard.cardColor};
`
const StatsWrapper = styled.div`
display:flex;
flex-direction:column;
gap:15px;
`
const StatsContainer = styled.div`
display:flex;
align-items:center;
gap:5px;
h1{
    color:${({ theme }) => theme.playerPerformaceCard.cardColor};
font-size: 20px;
font-weight: 600;
line-height: 24px;
white-space: nowrap;
}
h2{
color:${({ theme }) => theme.playerPerformaceCard.cardColor};
font-size: 10px;
font-weight: 400;
line-height: 20px;
}
svg{
    height:100%;
    width:16px;
}
`
const Separator = styled.div`
height:1px;
width:50%;
background:rgba(210, 216, 229, 1);
`
const GKCell = styled.div`
width:94px;
height:22px;
border-radius:4px;
display:flex;
align-items:center;
justify-content:center;
background:${({ theme }) => theme.playerPerformaceCard.gk.bg};
h1{
    font-family: Roboto;
font-size: 12px;
font-weight: 500;
line-height: 14px;
    color:${({ theme }) => theme.playerPerformaceCard.gk.color};
}
`
const ATKCell = styled(GKCell)`
background:${({ theme }) => theme.playerPerformaceCard.attack.bg};
h1{
    color:${({ theme }) => theme.playerPerformaceCard.attack.color};
}
`
const NTCell = styled(GKCell)`
background:${({ theme }) => theme.playerPerformaceCard.neutral.bg};
h1{
    color:${({ theme }) => theme.playerPerformaceCard.neutral.color};
}
`
const DEFCell = styled(GKCell)`
background:${({ theme }) => theme.playerPerformaceCard.defensive.bg};
h1{
    color:${({ theme }) => theme.playerPerformaceCard.defensive.color};
}
`

const LoaderWrap = styled.div`
min-height:420px;
width:100%;
height:100%;
display:flex;
align-items:center;
justify-content:center;
`;
const FixtureDetails = styled.div`
display:flex;
align-items:center;
p{
    color:${({ theme }) => theme.playerPerformaceCard.cardColor};
    font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 16px;
}
`