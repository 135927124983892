export const AtkDefValues = [
    { value: 6 },
    { value: 5 },
    { value: 4 },
    { value: 3 },
    { value: 2 },
    { value: 1 },
    { value: 0 },
    { value: -1 },
    { value: -2 },
    { value: -3 },
    { value: -4 },
    { value: -5 },
    { value: -6 },
];