import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CountryMenu from "../Menu/CountryMenu";
import DatePickerMenu from "../Menu/DatePickerMenu";
import LeagueMenu from "../Menu/LeagueMenu";
import SortCell from "../SortCell";
import Countdown, { zeroPad } from "react-countdown";
import { ReactComponent as SearchSvg } from "../../../assets/icons/BettingIcons/search.svg";
import { ReactComponent as RefreshSvg } from "../../../assets/icons/BettingIcons/refresh.svg";
import { ReactComponent as DownloadSvg } from "../../../assets/icons/BettingIcons/downloadArrow.svg";

import { useBetsService } from "../../../context/useBetsService";

import { useRequestMenuService } from "../../../context/useRequestMenuService";
import TierMenu from "../Menu/TierMenu";
import { tierList } from "../../../helper/Values/TierList";
import BetListIdMenu from "../Menu/BetsListIdMenu";
import Prioritymenu from "../Menu/PriorityMenu";
import {
  getCompetitions,
  getCompetitionsForId,
  getCountries,
} from "../../../helper/PricingHeaderMenuRequests";
import moment from "moment";
import qs from "query-string";
import { useLocation, useSearchParams } from "react-router-dom";

const BetsListHeader = ({
  sortBets,
  handleDownloadCSV,
  getLatestBets,
  handleGetBets,
}) => {
  const {
    searchInput,
    countries,
    countriesList,
    date,
    countriesIds,
    selectedCompetitions,
    competitions,
    competitionsIds,
    tier,
    tierIds,
    listId,
    priority,

    setSearchInput,
    setCountries,
    setCountriesList,
    setDate,
    setCountriesIds,
    setSelectedCompetitions,
    setCompetitions,
    setCompetitionIds,
    setTier,
    setTierIds,
    setListId,
    setPriority,

  } = useRequestMenuService();

  const refreshTimeLimit = 300000;
  const [canRefresh, setCanRefresh] = useState(false);
  const [counter, setCounter] = useState(moment().valueOf() + refreshTimeLimit);
  const counterRef = useRef();
  const { betsListIds, latestListDate, setLatestListDate } = useBetsService();
  const [ascending, setAscending] = useState(true);

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();




  const Completionist = () => <>Refresh</>;
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </>
      );
    }
  };
  const handleComplete = () => {
    setCanRefresh(true);
  };
  const handleSort = (filterKey) => {
    sortBets(ascending, filterKey);
    setAscending(!ascending);
  };

  const handleCounter = () => {

    const currentDate = moment().valueOf(); //miliseconds
    let latestListDataMilisecond = new Date(latestListDate).getTime(); //miliseconds

    if (latestListDataMilisecond > currentDate) {
      setCounter(moment().valueOf());
    } else
      if (latestListDataMilisecond < (currentDate - refreshTimeLimit)) {
        setCounter(moment().valueOf());
      }
      else {
        let difference = refreshTimeLimit - (currentDate - latestListDataMilisecond);
        setCounter(moment().valueOf() + difference);
      }

  };

  const handleRefresh = () => {
    setCanRefresh(false);
    getLatestBets();
    handleCounter();
  };

  const _handleInputSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSetSearchParams();
      handleGetBets();
    }
  };

  const handleSetSearchParams = () => {
    let start;
    let end;

    if (date != null) {
      start = date[0].toISOString();
      end = date[1].toISOString();
    } else {
      start = null;
      end = null;
    }

    let params = qs.stringify({
      tier: tierIds,
      countryId: countriesIds,
      leagueId: competitionsIds,
      term: searchInput,
      start_date: start,
      end_date: end,
      priority: priority,
      betListId: listId,
    });

    setSearchParams(params);

  };

  const initializeMenuValuesFromParams = () => {
    if (location.search != "") {

      let params = qs.parse(location.search);

      let tier = params.tier;
      let end_date = params.end_date;
      let start_date = params.start_date;
      let term = params.term;
      let leaguesId = params.leagueId;
      let countryId = params.countryId;
      let betListId = params.betListId;
      let priority = params.priority;

      setSearchInput(term);
      setListId(parseInt(betListId));
      setPriority(priority);

      if (start_date != "" && end_date != "") {
        setDate([new Date(start_date), new Date(end_date)]);
      }

      if (Array.isArray(leaguesId)) {
        let values = leaguesId.map((item) => parseInt(item));
        setCompetitionIds(values);
      } else
        if (leaguesId?.length > 0) {
          setCompetitionIds([parseInt(leaguesId)]);
        }

      if (Array.isArray(countryId)) {
        let values = countryId.map((item) => parseInt(item));
        setCountriesIds(values);
      } else
        if (countryId?.length > 0) {
          setCountriesIds([parseInt(countryId)]);
        }

      if (Array.isArray(tier)) {
        let values = tier.map((item) => parseInt(item));
        setTierIds(values);
      } else
        if (tier?.length > 0) {
          setTierIds([parseInt(tier)]);
        }

    }
  };


  useEffect(() => {
    if (countriesList.length == 0) {
      getCountries(setCountriesList);
      getCompetitions(setCompetitions);
    }

    if (countriesList.length > 0 && countries.length > 0) {
      getCompetitionsForId(countriesIds,
        (sorted) => {
          setCompetitionIds([]);
          setSelectedCompetitions([]);
          setCompetitions(sorted);
        }
      );
    }

    if (countriesList.length > 0 && countries.length == 0) {
      getCompetitions(setCompetitions);
    }


  }, [countries]);

  useEffect(() => {
    handleCounter();
  }, [latestListDate, betsListIds]);

  useEffect(() => {
    initializeMenuValuesFromParams();
  }, []);

  useEffect(() => {
    handleSetSearchParams();
  }, [countriesIds, competitionsIds, date, tierIds, listId, priority]);

  return (
    <Container>
      <MenuHeader>
        {" "}
        <Left>
          <div className="searchInput">
            <SearchSvg />
            <input
              value={searchInput}
              onChange={(e) => _handleInputSearch(e)}
              placeholder="Filter by keyboard"
              onKeyDown={(e) => handleKeyDown(e)}
            ></input>
          </div>

          <CountryMenu
            className="countryDropDown"
            label={"Countries"}
            countriesList={countriesList}
            countries={countries}
            setCountries={setCountries}
            countriesIds={countriesIds}
            setCountriesIds={setCountriesIds}
          />

          <LeagueMenu
            label={"Leagues"}
            competitions={competitions}
            selectedCompetitions={selectedCompetitions}
            setSelectedCompetitions={setSelectedCompetitions}
            competitionIds={competitionsIds}
            setCompetitionIds={setCompetitionIds}
          />

          <DatePickerMenu
            label={"Date"}
            selectedValue={date}
            setSelectedValue={setDate}
          />
          <TierMenu
            label={"Tier"}
            tierList={tierList}
            tier={tier}
            setTier={setTier}
            tierIds={tierIds}
            setTierIds={setTierIds}
          />
          <BetListIdMenu
            listValues={betsListIds}
            listId={listId}
            setListId={setListId}
          />
          <Prioritymenu
            label={"Priority"}
            priority={priority}
            setPriority={setPriority}
          />
        </Left>
        <Right>
          <RefreshBtn disabled={!canRefresh} onClick={() => handleRefresh()}>
            <RefreshSvg />
            <Countdown
              ref={counterRef}
              key={counter}
              date={counter}
              renderer={renderer}
              onComplete={() => handleComplete()}
            />
          </RefreshBtn>

          <CsvBtn onClick={() => handleDownloadCSV()}>
            <DownloadSvg />
            CSV
          </CsvBtn>
        </Right>
      </MenuHeader>

      <BottomHeader>
        <Cell style={{ minWidth: "30px", maxWidth: "30px" }}></Cell>
        <CellWrap style={{ width: "75px" }}>
          <SortCell name={"KO TIME"} onClick={() => handleSort("start_time")} />
        </CellWrap>
        <Cell style={{ minWidth: "14px", maxWidth: "14px" }}>
          <SortCell name={"P"} onClick={() => handleSort("priority_value")} />
        </Cell>
        <CellWrap style={{ minWidth: "150px" }}>
          <SortCell name={"GAME"} onClick={() => handleSort("home_team")} />
        </CellWrap>
        <CellWrap style={{ minWidth: "45px", maxWidth: "60px", justifyContent: "center" }}>
          <SortCell name={"TIME"} onClick={() => handleSort("received_time")} />
        </CellWrap>
        <CellWrap className="center" style={{ minWidth: "100px" }}>
          <SortCell name={"MARKET"} onClick={() => handleSort("market")} />
        </CellWrap>
        <CellWrap style={{ minWidth: "100px" }}>
          <SortCell
            name={"SELECTION"}
            onClick={() => handleSort("selection")}
          />
        </CellWrap>
        <CellWrap style={{ minWidth: "60px" }} className="center">
          <SortCell name={"TARGET"} onClick={() => handleSort("target")} />
        </CellWrap>


        <CellWrap style={{ minWidth: "60px" }} className="center">
          <SortCell
            name={"MIN PRICE"}
            onClick={() => handleSort("min_price")}
          />
        </CellWrap>

        <CellWrap className="center" style={{ minWidth: "40px" }}>
          <SortCell name={"MKT"} onClick={() => handleSort("market_price")} />
        </CellWrap>

        <CellWrap className="center" style={{ minWidth: "60px" }}>
          <SortCell
            name={"MKT VALUE"}
            onClick={() => handleSort("market_value")}
          />
        </CellWrap>
        <Cell style={{ minWidth: "200px", justifyContent: "left" }}>
          <p>BOOKS</p>
        </Cell>
        <Cell style={{ minWidth: "2px" }} ></Cell>
        <Cell style={{ minWidth: "2px" }}></Cell>

        <Cell style={{ minWidth: "70px" }}>
          <p>NO MOVe</p>
        </Cell>
        <Cell style={{ minWidth: "95px" }}>
          <p>NO PRICE</p>
        </Cell>
        <Cell style={{ minWidth: "70px" }}>
          <p>SCREEN LIMIT</p>
        </Cell>
        <Cell>
          <SortCell
            name={"PLACED"}
            onClick={() => handleSort("placed_value")}
          />
        </Cell>
        <Cell style={{ minWidth: "50px" }}>
          <p>SEND RS</p>
        </Cell>
        <Cell style={{ minWidth: "50px" }}>
          <p>PARK BET</p>
        </Cell>

        <Cell style={{ minWidth: "40px" }}>
          <p>NOTES</p>
        </Cell>

      </BottomHeader>
    </Container>
  );
};

export default BetsListHeader;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const MenuHeader = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.headerBG};
  padding: 8px 14px;
  justify-content: space-between;
`;
const BottomHeader = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-width: 1600px;
  background-color: ${({ theme }) => theme.leagueBG};
  color: ${({ theme }) => theme.betHeaderTextColor};
  padding: 14px 14px;
  column-gap: 9px;
  max-height: 36px;
  .center {
    justify-content: center;
  }
  .toggleWidth {
    min-width: 51px;
  }
`;

const Left = styled.div`
  display: flex;
  column-gap: 5px;
  color: #3c4a5c;
  .searchInput {
    position: relative;
    svg {
      color: ${({ theme }) => theme.menuTextColor};
      position: absolute;
      top: 9px;
      left: 10px;
    }
    input {
      min-width: 242px;
      color: ${({ theme }) => theme.menuTextColor};
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      ::placeholder {
        color: ${({ theme }) => theme.menuTextColor};
      }
      outline: none;
      border: none;
      :focus-visible {
        border: none;
        outline: none;
      }
      padding: 5px 10px 3px 26px;
      background: ${({ theme }) => theme.menuBgColor};
      border: none;
      border-radius: 8px;
    }
  }
`;
const CellWrap = styled.div`
  display: flex;
  align-items: center;
  min-width: 60px;
  width: 100%;
`;
const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  width: 100%;

  p {
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
`;

const Right = styled(Left)`
  display: flex;
`;
const CsvBtn = styled.button`
  cursor: pointer;
  border-radius: 4px;
  column-gap: 5px;
  padding: 5px 10px 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  background-color: ${({ theme }) => theme.buttonBG};
  color: ${({ theme }) => theme.buttonTextColor};
`;
const RefreshBtn = styled.button`
  cursor: pointer;
  padding: 5px 8px;
  display: flex;
  column-gap: 4px;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  background: rgba(255, 89, 89, 0.8);
  border: 2px solid #ffffff;
  border-radius: 20px;

  :disabled {
    cursor: inherit;
  }
`;
