import {
  createContext,
  useCallback,
  useContext,
  useState,
  useRef,
} from "react";
import { getCurrentUserBets } from "../api/bets/currentUserBets";
import { getCurrentUserBetsPrices } from "../api/bets/getCurrentUserBetsPrices";
import { get_bets_listIds } from "../api/bets/getListIds";
import { buildNewStateForBetsPrices } from "../helper/Betting/betsPriceStateHelper";
import { getBrokersAndCurrencies } from "../api/bets/getBrokersAndCurrencies";
import { mapResponseToBrokerList } from "../helper/broker/brokerMapper";
import { getFixturePrices } from "../api/fixtures/GetFixturePrices";
import { buildNewStateForPricingPrices } from "../helper/Pricing/pricingPriceStateHelper";
import { canSendToRunshop } from "../api/bets/canSendToRunshop";

export const BetsServiceContext = createContext({
  bets: [],
  betsPrices: [],
  pricingPrices: [],
  canSendToRunshopList: [],
  betsListIds: [],
  latestListDate: [],
  brokers: [],
  webhookPriceUpdate: [],
  getBets: () => {},
  getBetsPrices: () => {},
  getSendToRunshopList: () => {},
  getBetsListIds: () => {},
  setLatestListDate: () => {},
  getBrokers: () => {},
  fixturePrices: () => {},
  pricingChannelResponsHandler: () => {},
  bettingPricesWSHandler: () => {},
});

const BetsServiceProvider = ({ children }) => {
  const [bets, setBets] = useState([]);
  const [betsPrices, setBetsPrices] = useState([]);
  const [canSendToRunshopList, setCanSendToRunshopList] = useState([]);
  const [pricingPrices, setPricingPrices] = useState([]);
  const [betsListIds, setBetsListIds] = useState([]);
  const [latestListDate, setLatestListDate] = useState();
  const [brokers, setBrokers] = useState([]);

  // Stores the prices
  const betsPricesRef = useRef([]);
  const pricingPricesRef = useRef([]);

  const bettingPricesWSHandler = (data) => {
    if (data == null || data.length == 0) {
      return;
    }
    handleNewPrices(data);
  };

  const pricingChannelResponsHandler = (data) => {
    if (data == null) {
      return;
    }
    handleWsNewPrices(data);
  };

  const getBets = useCallback(
    async (
      countries_ids,
      start_time,
      end_time,
      competitions_ids,
      term,
      tiers,
      list_id,
      priority,
      callBackFunction,
      callbackError
    ) => {
      try {
        await getCurrentUserBets(
          countries_ids,
          start_time,
          end_time,
          competitions_ids,
          term,
          tiers,
          list_id,
          priority,
          function (response) {
            setBets(response.data.bets_to_place);
            callBackFunction(response.data.bets_to_place);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (err) {
        callbackError();
      }
    }
  );

  const getBetsListIds = useCallback(
    async (start_time, end_time, callBackFunction, callbackError) => {
      try {
        await get_bets_listIds(
          start_time,
          end_time,
          function (response) {
            setBetsListIds(response.data.list_ids);

            callBackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (err) {
        callbackError();
      }
    }
  );

  const getBetsPrices = useCallback(async (callbackError) => {
    try {
      await getCurrentUserBetsPrices(
        function (response) {
          handleNewPrices(response.data.prices);
        },
        function (error) {
          callbackError(error);
        }
      );
    } catch (err) {
      callbackError();
    }
  });

  const getSendToRunshopList = useCallback(
    async (list, callBackFunction, callbackError) => {
      try {
        await canSendToRunshop(
          list,
          function (response) {
            setCanSendToRunshopList(response.data.bets);
            callBackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (err) {
        callbackError();
      }
    }
  );

  const getBrokers = useCallback(async (errorCallback) => {
    try {
      await getBrokersAndCurrencies(
        function (response) {
          handleBrokers(response.data.brokers);
        },
        function (error) {
          errorCallback();
        }
      );
    } catch (err) {
      errorCallback();
    }
  });

  //prices from pricing page(HA,HD,AA,AD) -> initialize before pricesWS
  const fixturePrices = useCallback(async (callbackError) => {
    try {
      await getFixturePrices(
        function (response) {
          handleWsNewPrices(response.data);
        },
        function (error) {
          callbackError();
        }
      );
    } catch (err) {
      callbackError();
    }
  });

  // Private methods

  const handleNewPrices = useCallback((newPrices) => {
    const newState = buildNewStateForBetsPrices(
      betsPricesRef.current,
      newPrices
    );
    setBetsPrices(newState);
    betsPricesRef.current = newState;
  });

  const handleWsNewPrices = useCallback((newPricingPrices) => {
    const newState = buildNewStateForPricingPrices(
      pricingPricesRef.current,
      newPricingPrices
    );
    setPricingPrices(newState);
    pricingPricesRef.current = newState;
  });

  const handleBrokers = (brokers) => {
    const brokerList = mapResponseToBrokerList(brokers);
    setBrokers(brokerList);
  };

  return (
    <BetsServiceContext.Provider
      value={{
        bets,
        canSendToRunshopList,
        brokers,
        getBets,
        betsPrices,
        pricingPrices,
        getBetsPrices,
        betsListIds,
        getSendToRunshopList,
        getBetsListIds,
        latestListDate,
        setLatestListDate,
        getBrokers,
        fixturePrices,
        pricingChannelResponsHandler,
        bettingPricesWSHandler,
      }}
    >
      {children}
    </BetsServiceContext.Provider>
  );
};
export const useBetsService = () => useContext(BetsServiceContext);
export default BetsServiceProvider;
