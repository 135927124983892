import React, { useEffect, useState } from "react";
import styled from "styled-components";

const IntensityField = ({ width, value }) => {

    const [color, setColor] = useState();
    const [background, setBackground] = useState();

    const checkType = (value) => {
        if (value == "low") {
            setColor("#47B77B");
            setBackground("#ECFFF5");

        }
        if (value == "mid") {
            setColor("#FF8D21");
            setBackground("#FFF3E7");
        }
        if (value == "high") {
            setColor("#EC4848");
            setBackground("#FFEEEE");
        }
    };
    useEffect(() => {
        checkType(value);
    }, [value]);
    return (
        <Container width={width} color={color} background={background}>
            <p>{value}</p>
        </Container>
    );
};

export default IntensityField;
const Container = styled.div`
min-width:60px;
background: ${(props) => props.background != null ? props.background : ""};
column-gap: 10px;
border-radius: 6px;
display: flex;
width:${(props) => props.width != null ? props.width : "60px"};
padding: 12px 16px;
border-radius: 6px;
p{
    text-transform:capitalize;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.03em;
    width:100%;
    color:  ${(props) => props.color != null ? `${props.color}` : ""};

}
svg{
    height: 15px;
}
`;