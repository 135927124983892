import { POST } from "../APiHelper/API";

export const sendInvite = (
  email,
  role,

  all_competitions,
  all_seasons,
  all_cups,

  competition_ids,
  season_ids,
  cup_ids,

  onSuccess,
  onError
) => {
  const data = {
    email: email,
    role: role,
    all_competitions: all_competitions,
    all_seasons: all_seasons,
    all_cups: all_cups,
    competition_ids: competition_ids,
    season_ids: season_ids,
    cup_ids: cup_ids

  };

  POST("/api/v1/users/invite_user", data, onSuccess, onError);
};
