export const formatNumber = (value) => {

  if (value == null) {
    return "";
  }

  if (value.length == 0) {
    return "";
  }

  return value.toFixed(2);
};
