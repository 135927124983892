import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as MenuDotIcon } from "../../../assets/icons/LeaguesIcons/menuDot.svg";
import { ReactComponent as TransferIcon } from "../../../assets/icons/LeaguesIcons/transferDataButton.svg";
import DeleteConfirmation from "../../DeleteConfirmation";
import { useSelector } from "react-redux";
import { selectUser } from "../../../Redux/user/user";

const DotMenu = ({
    optionsList,
    disabled,
    fixtureData,
    phase
}) => {

    const ref = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const user = useSelector(selectUser);

    const isUserAdmin = () => {
        if (!user) {
            return
        }

        if (user.roles.includes('admin')) {
            return true
        } else {
            return false
        }
    }

    const isElementDisabledInThisPhase = (element) => {
        if (phase && element?.optionDisabled && (element?.optionDisabled === phase)) {
            return true
        } else {
            return false
        }
    }

    const disableElement = (element) => {
        let elementDisabledInThisPhase = isElementDisabledInThisPhase(element);
        if (elementDisabledInThisPhase ||
            (element.requestAdmin && !isUserAdmin()) ||
            fixtureData.squad.all_confirmed
        ) {
            return true
        } else {
            return false
        }
    }


    const handleMenuOpen = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        } else {
            setIsMenuOpen(true);
        }
    };

    const handleOption = (action) => {
        action();
    }


    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    return (
        <Container ref={ref}>

            <DotMenuContainer onClick={() => !disabled && handleMenuOpen()} isMenuOpen={isMenuOpen} disabled={disabled}>
                <MenuDotIcon />
            </DotMenuContainer>

            {isMenuOpen && (
                <DropDownList>
                    {!fixtureData.default_squad &&
                        optionsList?.map((option, index) => (
                            <DropDownMenuItem
                                onClick={() => handleOption(option.optionAction)}
                                key={index}
                                disabled={disableElement(option)}
                            >
                                <IconWrapper>
                                    {option.optionIcon}
                                </IconWrapper>
                                <p>{option.optionTitle}</p>
                            </DropDownMenuItem>
                        ))
                    }



                </DropDownList>
            )
            }


        </Container >
    );
};


export default DotMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
   cursor:pointer;

`;

const DropDownList = styled.ul`
  position: absolute;
  width:100px;
  z-index: 2;
  top: 12px;
  right:3px;
  border-color: #3c4a5c;
  border: 1px solid #BAC3E8;
  overflow:hidden;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
align-items:center;
  background:${({ theme }) => theme.matchPreview.bg};
    ::placeholder{
      color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
    }
      

`;
const DropDownMenuItem = styled.button`
width:100%;
gap:5px;
cursor:pointer;
display:flex;
background:${({ theme }) => theme.matchPreview.bg};
padding:6px 10px;
align-items:center;
border-radius:3px;
border:none;
p{
    font-family: Roboto;
font-size: 10px;
font-weight: 400;
line-height: 12px;
 color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
}
:disabled{
display:none;
}
`;
const DotMenuContainer = styled.button`
border:none;
background:none;
display:flex;
align-items:center;
cursor:pointer;
:disabled{
    opacity:0.3;
}


`

const IconWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
    svg{
        height:10px;
        width:10px;
        stroke:${({ theme }) => theme.squadManager.textColor};
}
`