import { POST } from "../../APiHelper/API";

export const addPLayerFightForPositionAPI = (squad_id, selection_id, position, onSuccess, onError) => {
    let source;
    const data = {
        squad_id: squad_id,
        selection_id: selection_id,
        position: position,
    }
    source = POST(`/api/v1/squads/add_player_fights_for_position`, data, onSuccess, onError, true, source)
}