export const mapTeamDetails = (squad_details) => {
  let listData = [];
  let data = {};

  squad_details.forEach((fixture) => {

    data = {
      default_squad: fixture.default_squad,
      fixture: fixture.fixture,
      squad: fixture.squad,
      league: fixture.league,
      cup: fixture.cup,
      has_both_squads_selected: fixture.has_both_squads_selected,
      game: fixture.game,
      score: fixture.score,
      can_edit_current_state: fixture.can_edit_current_state,
      can_confirm_selection: fixture.can_confirm_selection,

    };
    listData.push(data);
  });

  return listData;
};
