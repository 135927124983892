import styled from "styled-components";


export const parseStatusValue = (status, fatigue = 0, lineup_changed_after_pm_complete, ratings_changed_after_pm_complete) => {
  switch (status) {
    case "injured":
      return (
        <>
          {(lineup_changed_after_pm_complete || ratings_changed_after_pm_complete) &&
            <ChangesWrapper>
              <MarkerWrapper>
                <h5> {lineup_changed_after_pm_complete && "★"}</h5>
              </MarkerWrapper>
              <MarkerWrapper>
                <h6> {ratings_changed_after_pm_complete && "●"}</h6>
              </MarkerWrapper>
            </ChangesWrapper>
          }
          <p>
            Injured
            {fatigue != 0 && <label> ({fatigue})</label>}
          </p>
        </>
      )
    case "available":
      return (
        <>

          {(lineup_changed_after_pm_complete || ratings_changed_after_pm_complete) &&
            <ChangesWrapper>
              <MarkerWrapper>
                <h5> {lineup_changed_after_pm_complete && "★"}</h5>
              </MarkerWrapper>
              <MarkerWrapper>
                <h6> {ratings_changed_after_pm_complete && "●"}</h6>
              </MarkerWrapper>
            </ChangesWrapper>
          }
          <p>
            Available
            {fatigue != 0 && <label> ({fatigue})</label>}
          </p>

        </>
      );

    case "bench":
      return (
        <>
          {(lineup_changed_after_pm_complete || ratings_changed_after_pm_complete) &&
            <ChangesWrapper>
              <MarkerWrapper>
                <h5> {lineup_changed_after_pm_complete && "★"}</h5>
              </MarkerWrapper>
              <MarkerWrapper>
                <h6> {ratings_changed_after_pm_complete && "●"}</h6>
              </MarkerWrapper>
            </ChangesWrapper>
          }
          <p>
            Bench
            {fatigue != 0 && <label> ({fatigue})</label>}
          </p>

        </>);
    case "unavailable":

      return (
        <>
          {(lineup_changed_after_pm_complete || ratings_changed_after_pm_complete) &&
            <ChangesWrapper>
              <MarkerWrapper>
                <h5> {lineup_changed_after_pm_complete && "★"}</h5>
              </MarkerWrapper>
              <MarkerWrapper>
                <h6> {ratings_changed_after_pm_complete && "●"}</h6>
              </MarkerWrapper>
            </ChangesWrapper>
          }
          <p>
            Unavailable
            {fatigue != 0 && <label> ({fatigue})</label>}
          </p>

        </>)

    case "rested":
      return (
        <>
          {(lineup_changed_after_pm_complete || ratings_changed_after_pm_complete) &&
            <ChangesWrapper>
              <MarkerWrapper>
                <h5> {lineup_changed_after_pm_complete && "★"}</h5>
              </MarkerWrapper>
              <MarkerWrapper>
                <h6> {ratings_changed_after_pm_complete && "●"}</h6>
              </MarkerWrapper>
            </ChangesWrapper>
          }
          <p>
            Rested
            {fatigue != 0 && <label> ({fatigue})</label>}
          </p>
        </>)
    case "n/a":
      return (
        <>
          {(lineup_changed_after_pm_complete || ratings_changed_after_pm_complete) &&
            <ChangesWrapper>
              <MarkerWrapper>
                <h5> {lineup_changed_after_pm_complete && "★"}</h5>
              </MarkerWrapper>
              <MarkerWrapper>
                <h6> {ratings_changed_after_pm_complete && "●"}</h6>
              </MarkerWrapper>
            </ChangesWrapper>
          }
          <p>
            N/A
          </p>
        </>
      )
    default:
      break;
  }
};

const ChangesWrapper = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
height:11px;
width:4px;
 h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 5px;
    line-height: 5px;
  }
   h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 7px;
    line-height: 5px;
  }
`

const MarkerWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
min-width:5px;
min-height:5px;
`