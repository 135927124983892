import { createContext, useCallback, useContext, useRef, useState } from "react";

import { getActiveRsBets } from "../api/bets/getActiveRsBets";
import { sendRsSelection } from "../api/bets/sendRsSelection";
import { disableRsSelection } from "../api/bets/disableRsSelection";
import { updateRsSelection } from "../api/bets/updateRsSelection";
// import WSDWebsocket from "../helper/Pricing/PricingWebSocketHelper";
import { getRsLoggedSelections } from "../api/bets/getRsLoggedBets";

export const RsBetsServiceContext = createContext({
    rsBets: [],
    getRsBets: () => { },
    sendRsBet: () => { },
    disableRsBet: () => { },
    updateRsBet: () => { },
    getRsLoggedBets: () => { },
    runShopChannelHandler: () => { }
});

const RsBetsServiceProvider = ({ children }) => {
    const previousRsLoggedBetsCallRef = useRef(null);
    const [rsBets, setRsBets] = useState([]);

    const runShopChannelHandler = (data) => {

        if (data == undefined) {
            return;
        }
        setRsBets(data[0]);

        // setRsBets([{
        //     bet: {
        //         away_team: "FC Memmingen",
        //         competition_name: "Regionalliga",
        //         country_name: "Germany",
        //         fixture_id: 13669232,
        //         home_team: "Bayern Munchen II",
        //         id: "d3278e30-b575-4044-a5cc-76e04fa1d306",
        //         list_id: 14925,
        //         market: "OUG",
        //         market_id: 64,
        //         min_price: 1.8599999999999999,
        //         model_price: 1.466056717005949,
        //         priority: "top",
        //         real_bet: 1,
        //         received_time: "2023-09-01T16:36:18.000Z",
        //         selection: "Under 3.75",
        //         start_time: "2023-09-01T18:00:00.000Z",
        //         status: "active",
        //         target: 10000,
        //         tier: 4,
        //     },
        //     current_status: {
        //         active: true,
        //         fixture_id: 13669232,
        //         id: 57372,
        //         price: 2,
        //         selection_id: 14220,
        //         stake_online: 0,
        //         stake_shops: 0,
        //         stake_target: 333,
        //         total_stakes: 0,
        //     },
        //     runshop: {
        //         active: true,
        //         active_until: "2023-09-01T18:00:00.000Z",
        //         bet_id: "d3278e30-b575-4044-a5cc-76e04fa1d306",
        //         created_at: "2023-09-07T07:36:44.253Z",
        //         id: 21,
        //         price: "2.0",
        //         selection_id: 14220,
        //         stake: 333,
        //     },
        //     markets: [],
        //     market_value: null,
        // }]);
    };


    const getRsBets = useCallback(async (callbackFunction, callbackError) => {
        try {
            await getActiveRsBets(
                function (response) {
                    setRsBets(response.data.bets);
                    callbackFunction();
                },
                function (error) {
                    callbackError();
                }
            );
        } catch (err) {
            callbackError();
        }
    });

    const sendRsBet = useCallback(
        async (
            bet_id,
            price,
            stake,
            active_until,
            callbackFunction,
            callbackError
        ) => {
            try {
                await sendRsSelection(
                    bet_id,
                    price,
                    stake,
                    active_until,
                    function (response) {
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (err) {
                callbackError();
            }
        }
    );

    const updateRsBet = useCallback(
        async (
            bet_id,
            price,
            stake,
            active_until,
            callbackFunction,
            callbackError
        ) => {
            try {
                await updateRsSelection(
                    bet_id,
                    price,
                    stake,
                    active_until,
                    function (response) {
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (err) {
                callbackError();
            }
        }
    );

    const disableRsBet = useCallback(
        async (rs_bet_it, callbackFunction, callbackError) => {
            try {
                await disableRsSelection(
                    rs_bet_it,
                    function (response) {
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (err) {
                callbackError();
            }
        }
    );


    const getRsLoggedBets = useCallback(
        async (
            country_ids,
            start_time,
            end_time,
            competition_ids,
            term,
            tiers,
            callbackFunction,
            callbackError
        ) => {
            try {
                previousRsLoggedBetsCallRef.current?.cancel();
                const ref = getRsLoggedSelections(
                    country_ids,
                    start_time,
                    end_time,
                    competition_ids,
                    term,
                    tiers,
                    function (response) {
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
                previousRsLoggedBetsCallRef.current = ref;
            } catch (err) {
                callbackError();
            }
        }
    );




    return (
        <RsBetsServiceContext.Provider
            value={{
                sendRsBet,
                getRsBets,
                disableRsBet,
                updateRsBet,
                rsBets,
                getRsLoggedBets,
                runShopChannelHandler
            }}
        >
            {children}
        </RsBetsServiceContext.Provider>
    );
};
export const useRsBetsService = () => useContext(RsBetsServiceContext);
export default RsBetsServiceProvider;
