import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import PermissionsGate from '../../../helper/Generic/PermissionGate';
import { SCOPES } from '../../../helper/Generic/Permissions';
import DeleteConfirmation from '../../DeleteConfirmation';
import ButtonWithConfirmation from '../../ButtonWithConfirmation';
import { format_time_to_timezone } from '../../../helper/Generic/dateTimeFormat';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../Redux/user/user';
import moment from 'moment';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Tooltip } from '@mui/material';

const DeletedTeamElement = ({ handleRevert, item, displayUpdatedAt }) => {
  const user = useSelector(selectUser);

  const checkValue = (item) => {
    if (!item) {
      return
    }
    let name = item.name

    if (item.season) {
      name = item.name + " " + `(${item?.season?.name})`
    }

    if (displayUpdatedAt) {
      let updatedAt = format_time_to_timezone(item.updated_at, user.timezone);
      name = name + " " + `(${moment(updatedAt).format("YYYY-MM-DD/hh:mm")})`
    }

    return name;
  }


  const displayName = (item) => {
    if (!item) {
      return
    }
    let name = item.name

    if (item.season) {
      name = item.name + " " + `(${item?.season?.name})`
    }

    return name;

  }

  return (

    <DeletedElement >
      <Tooltip
        title={checkValue(item)}
      >
        <ElementInput
          value={displayName(item)}
          disabled
        />
      </Tooltip>
      <ButtonWithConfirmation
        variant={"revert-delete"}
        action={handleRevert}
        item={item?.id}
      />
    </DeletedElement>


  )
}

export default DeletedTeamElement

const DeletedElement = styled.div`
  display:flex;
  align-items: center;
  column-gap: 6px;
`;

const ElementInput = styled.input`
  height: 40px;
  width:240px;
   background: ${({ theme }) => theme.editLeague.leagueDetails.deletedTeamsBg};
  border: 1px solid #bac3e8;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color:${({ theme }) => theme.editLeague.leagueDetails.deletedTeamsColor};
  padding: 10px 15px;

  :focus {
    outline:none;
  }
  ::placeholder {
     color:${({ theme }) => theme.editLeague.leagueDetails.deletedTeamsColor};
     opacity:0.7;
  }
  :disabled{
    background: ${({ theme }) => theme.editLeague.leagueDetails.deletedTeamsBg};
    color:${({ theme }) => theme.editLeague.leagueDetails.deletedTeamsColor};

  }
 
`;