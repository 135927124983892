import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import styled from "styled-components";

const notifyComponent = (message) => {
	return (
		<Container>
			<CircularProgress size={20} />
			<p>{message}</p>
		</Container>
	);
};
const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 20px;
  max-height: 30px;
`;
//-----------<  >-----------//

export const notifyError = (message) => {
	toast.error(message, {
		position: toast.POSITION.BOTTOM_RIGHT,
	});
};

export const notifySucces = (message) => {
	toast.success(message, {
		position: toast.POSITION.BOTTOM_RIGHT,
	});
};

export const notifyLoading = (toastId, message) => {
	toastId.current = toast(notifyComponent(message), {
		position: toast.POSITION.BOTTOM_RIGHT,
		autoClose: false,
	});
};

export const notifyUpdate = (toastId, message, type) => {
	toast.update(toastId.current, {
		render: message,
		type: type,
		autoClose: 2000,
	});
};

export const notifyInfo = (message) => {
	toast.info(message, {
		position: toast.POSITION.BOTTOM_RIGHT,
	})
}

export const notifyInfoNoClose = (toastId, message) => {
	if (toastId.current) {
		return
	} else {
		toastId.current = toast.info(message, {
			position: toast.POSITION.BOTTOM_RIGHT,
			closeButton: true,
			autoClose: false,
			onClose: () => { toastId.current = null }
		})

	}


}

export const notifyDismiss = (toastId) => {
	toast.dismiss(toastId.current);
};
