import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownSvg } from "../../../assets/icons/LeaguesIcons/down.svg";

const PenaltyTakerMenu = ({ label, valuesList, value, setValue }) => {

  const ref = useRef(null);
  const selectmenuref = useRef(null);
  const [selectedValueId, setSelectedValueId] = useState(null);

  const diplayPlayerName = (player) => {
    let first_name = player.first_name;
    let last_name = player.last_name;
    let full_name = first_name + " " + last_name;
    return full_name;
  };

  const handleChange = (item) => {
    setSelectedValueId(item);
    let found = valuesList.find((player) => {
      return player.id == item;
    });
    if (found) {
      setValue(found);
    } else {
      setValue(null);
    }
  };

  const sortPlayers = (players) => {
    if (!players) {
      return;
    }
    let sorted = players.sort((a, b) => (a.first_name + a.last_name) > (b.first_name + b.last_name) ? 1 : -1);
    return sorted;
  };


  useEffect(() => {
    if (value) {
      setSelectedValueId(value.id);
    } else {
      setSelectedValueId(null);
    }
  }, [valuesList]);

  return (
    <Container ref={ref}>
      <Wrapper>
        <DownSvg />
        <SelectMenu value={selectedValueId} onChange={(e) => handleChange(e.target.value)} ref={selectmenuref}>
          <SelectMenuItem value={null} label={"-"} ></SelectMenuItem>
          {sortPlayers(valuesList)?.map((item, index) => (
            <SelectMenuItem value={item.id} key={index} >
              {diplayPlayerName(item)}
            </SelectMenuItem>
          ))}

        </SelectMenu>
      </Wrapper>
    </Container>
  );
};


export default PenaltyTakerMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;

  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
  }
 
`;

const SelectMenu = styled.select`
background-color: ${({ theme }) => theme.editLeague.selectMenuBg};
padding: 11px 30px 11px 15px;
border: 1px solid #BAC3E8;
border-radius: 6px;
border-radius: ${(props) => props.isMenuOpen ? "8px 8px 0 0" : ""} ;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
width:100%;

background-position: 230px 10px ;
background-size:16px;
background-repeat:no-repeat;
color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

`;
const SelectMenuItem = styled.option`

`;
const Wrapper = styled.div`
  position: relative;
  svg {
    position: absolute;
    right: 10px;
    top: 12px;

    fill: ${({ theme }) => theme.editLeague.selectMenuSvgBg};
    stroke: ${({ theme }) => theme.editLeague.selectMenuSvgColor};
  }
`;