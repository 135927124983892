import React from 'react'
import styled from 'styled-components';
import DeleteConfirmation from '../../DeleteConfirmation';

const RefereeElement = ({ item, handleRemoveReferee }) => {

  var name = ""

  if (item.name === null) {
    name = getFullName(item.first_name, item.middle_name, item.last_name)
  } else {
    name = item.name
  }

  function getFullName(first_name, middle_name, last_name) {
    // Create an array of the name parts
    const nameParts = [first_name, middle_name, last_name];

    // Filter out empty or null values
    const filteredNameParts = nameParts.filter(part => part && part.trim() !== '');

    // Join the remaining parts with a space
    return filteredNameParts.join(' ');
  }

  let valueToDisplay = name;

  if (item.wy_id) {
    valueToDisplay = name + " " + `(${item.wy_id})`
  }

  return (
    <AddedElement>
      <ElementInput
        disabled
        value={valueToDisplay}
      />
      <DeleteConfirmation
        handleRemoveItem={handleRemoveReferee}
        item={item}
      />
    </AddedElement>
  )
}

export default RefereeElement
const AddedElement = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 6px;
`;
const ElementInput = styled.input`
  height: 40px;
   background: ${({ theme }) => theme.editLeague.readOnlyInputs};
  width: 240px;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color:${({ theme }) => theme.editLeague.readOnlyInputsColor};
  padding: 10px 15px;
  :focus {
    outline:none;
  }
  ::placeholder {
     color:${({ theme }) => theme.editLeague.readOnlyInputsColor};
     opacity:0.7;
  }
  :disabled{
    background: ${({ theme }) => theme.editLeague.readOnlyInputs};
    color:${({ theme }) => theme.editLeague.readOnlyInputsColor};

  }
`;