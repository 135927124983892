import { POST } from "../APiHelper/API";

let source = null;
export const canSendToRunshop = (bets, onSuccess, onError) => {
  const data = {
    bets: bets,
  };

  source = POST(
    "/api/v1/runshop_bets/can_send",
    data,
    onSuccess,
    onError,
    true,
    source
  );
};
