import React from "react";
import styled from "styled-components";
import { formatNumber } from "../../../helper/displayHelpers";
import {
  formatNumberForValue,
  highlightedExpandedDetailsClass,
} from "../../../helper/ModelDataHelper";

const Item = ({ value, className = null }) => {
  return (
    <Cell className={className}>
      <h2 className={className}>{value}</h2>
    </Cell>
  );
};

const MatchExpandDetails = ({
  data,
  highlightModelHA,
  highlightModelOU,
  highlightMarketHA,
  highlightMarketOU,
}) => {
  let model_ha_value = formatNumberForValue(data.model_ha_value);
  let model_h = formatNumber(data.model_h);
  let model_a = formatNumber(data.model_a);

  let model_ou_value = formatNumberForValue(data.model_ou_value);
  let model_o = formatNumber(data.model_o);
  let model_u = formatNumber(data.model_u);

  let market_ha_value = formatNumberForValue(data.market_ha_value);
  let market_h = formatNumber(data.market_h);
  let market_a = formatNumber(data.market_a);

  let market_ou_value = formatNumberForValue(data.market_ou_value);
  let market_o = formatNumber(data.market_o);
  let market_u = formatNumber(data.market_u);

  let modelHAClass = highlightedExpandedDetailsClass(highlightModelHA);
  let modelOUClass = highlightedExpandedDetailsClass(highlightModelOU);
  let marketHAClass = highlightedExpandedDetailsClass(highlightMarketHA);
  let marketOUClass = highlightedExpandedDetailsClass(highlightMarketOU);

  return (
    <Container>
      <Left>
        <FirstCell></FirstCell>
        <Item value={""} />
        <Item value={""} />
        <Item value={""} />
        <Item value={""} />
        <div className="grp ">
          <Item value={""} />
          <Item value={""} />
          <Item value={""} />
        </div>
        <div className="grp">
          <Item
            className={
              modelHAClass !== "" ? `${modelHAClass} + modelValue` : ""
            }
            value={model_ha_value}
          />
          <Item value={model_h} className={modelHAClass} />
          <Item value={model_a} className={modelHAClass} />
        </div>
        <div className="grp">
          <Item
            className={
              modelOUClass !== "" ? `${modelOUClass} + modelValue` : ""
            }
            value={model_ou_value}
          />
          <Item value={model_o} className={modelOUClass} />
          <Item value={model_u} className={modelOUClass} />
        </div>
        <Item value={""} />
        <Item value={""} />
      </Left>

      <Rght>
        <div className="grp ">
          <Item value={""} />
          <Item value={""} />
        </div>
        <div className="grp ">
          <Item value={""} />
          <Item value={""} />
          <Item value={""} />
        </div>

        <div className="grp">
          <Item
            value={market_ha_value}
            className={
              marketHAClass !== "" ? `${marketHAClass} + marketValue` : ""
            }
          />
          <Item value={market_h} className={marketHAClass} />
          <Item value={market_a} className={marketHAClass} />
        </div>
        <div className="grp">
          <Item
            value={market_ou_value}
            className={
              marketOUClass !== "" ? `${marketOUClass} + marketValue` : ""
            }
          />

          <Item value={market_o} className={marketOUClass} />
          <Item value={market_u} className={marketOUClass} />
        </div>
        <div className="grp ">
          <Item value={""} />
          <Item value={""} />
          <Item value={""} />
        </div>
        <div className="grp ">
          <Item value={""} />
          <Item value={""} />
        </div>
        <div className="grp ">
          <Item value={""} />
          <Item value={""} />
        </div>
        <Item value={""} />
      </Rght>
    </Container>
  );
};

export default MatchExpandDetails;
const Container = styled.div`
  display: flex;
`;
const Left = styled.ul`
  width: 100%;
  min-width: 800px;
  display: flex;
  align-items: center;
  list-style: none;
  background-color: ${({ theme }) => theme.matchElementDetailsBgL};
  color: ${({ theme }) => theme.leagueColor};
  padding: 6px 10px;

  .grp {
    width: 100%;
    display: flex;
    margin: 0 6px;
    justify-content: center;
    align-items: center;
  }

  .marketValue {
    font-weight: 800;
    font-size: 10px;
    line-height: 14px;
    color: ${({ theme }) => theme.matchDistinctValue};
  }
  .modelValue {
    font-weight: 800;
    font-size: 10px;
    line-height: 14px;
    color: ${({ theme }) => theme.matchDistinctValueModel};
  }
  border-right: 2px solid ${({ theme }) => theme.middleBorder};
`;
const Rght = styled(Left)`
  background-color: ${({ theme }) => theme.matchElementDetailsBgR};
  border-right: none;
`;
const Cell = styled.li`
  min-width: 32px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
  }
  .bld {
    font-weight: 900;
  }
  .procent {
    padding: 0 2px;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: -0.3px;
  }
`;
const FirstCell = styled.li`
  display: flex;
  min-width: 250px;
`;
