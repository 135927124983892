import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as ConfAttendanceIcon } from "../../../assets/icons/LeaguesIcons/conf-attendance-icon.svg";
import { ReactComponent as XBtn } from "../../../assets/icons/xBtn.svg";
import { NumericFormat } from "react-number-format";
import { Popover } from "react-tiny-popover";
const ConfirmAttendanceComponent = ({ readOnly, homeTeam, awayTeam, fixtureData, handleSetConfirmedAttendance }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [changedValue, setChangedValue] = useState(false);
    const [edit, setEdit] = useState(false);

    const ref = useRef(null);
    const [predictedAttendance, setPredictedAttendance] = useState({
        floatValue: null,
        formattedValue: "",
        value: "",
    });

    const handleOpen = () => { setIsOpen(!isOpen); };
    const handleClose = () => {
        setIsOpen(false);
        setChangedValue(false);
        setEdit(false);
    };
    const handlePredictedAttendanceValue = (val) => {
        const value = val;
        setPredictedAttendance(value);

    };

    const setInitialValues = () => {
        if (fixtureData.game.confirmed_attendance == null) {
            setPredictedAttendance({
                floatValue: null,
                formattedValue: "",
                value: ""
            });
        }
        else {
            setPredictedAttendance({
                floatValue: fixtureData.game.confirmed_attendance,
                formattedValue: fixtureData.game.confirmed_attendance,
                value: fixtureData.game.confirmed_attendance
            });
        }
    };

    const handleConfirmedAttendance = () => {
        handleSetConfirmedAttendance(
            fixtureData.fixture.fixture_id,
            fixtureData.league.id,
            predictedAttendance.floatValue,
            fixtureData.cup?.id,
            function () {
                handleClose();
            }
        );
    };


    const checkChangedValue = () => {
        if (predictedAttendance?.floatValue != fixtureData.game.confirmed_attendance) {
            setChangedValue(true);
        } else {
            setChangedValue(false);
        }
    };

    useEffect(() => {
        setInitialValues();
    }, [fixtureData]);

    useEffect(() => {
        checkChangedValue();
    }, [predictedAttendance, fixtureData]);
    return (
        <Container >
            <Popover
                isOpen={isOpen}
                positions={["bottom", "left"]}
                onClickOutside={() => handleClose()}
                transform={{ top: 5 }}
                transformMode='relative'
                content={
                    <DialogBox>
                        <BoxHeader>
                            <XBtn onClick={() => handleClose()} />
                        </BoxHeader>

                        <BoxContent>
                            <TeamsWrapper>
                                <Team>
                                    <img src={homeTeam?.logo_url} alt="logo" />
                                    <h1>{homeTeam?.name}</h1>
                                </Team>
                                <Team>
                                    <img src={awayTeam?.logo_url} alt="logo" />
                                    <h1>{awayTeam?.name}</h1>
                                </Team>
                            </TeamsWrapper>

                            <InputGroup>
                                <h3>CONF TOTAL ATTENDANCE</h3>

                                {readOnly ?
                                    <NumericFormat
                                        disabled={!edit}
                                        value={predictedAttendance.formattedValue}
                                        onValueChange={(values) =>
                                            handlePredictedAttendanceValue(values)
                                        }
                                        thousandSeparator=","
                                    />
                                    :
                                    <>
                                        {fixtureData?.game.confirmed_attendance == null ? <NumericFormat
                                            value={predictedAttendance.formattedValue}
                                            onValueChange={(values) =>
                                                handlePredictedAttendanceValue(values)
                                            }
                                            thousandSeparator=","
                                        /> :
                                            <NumericFormat
                                                disabled={!edit}
                                                value={predictedAttendance.formattedValue}
                                                onValueChange={(values) =>
                                                    handlePredictedAttendanceValue(values)
                                                }
                                                thousandSeparator=","
                                            />}
                                    </>
                                }

                            </InputGroup>
                            {readOnly ? "" :
                                <>
                                    {fixtureData?.game.confirmed_attendance == null ?
                                        <SubmitButton onClick={() => handleConfirmedAttendance()}>
                                            Add
                                        </SubmitButton> :
                                        <EditButton hidden={edit} onClick={() => setEdit(true)}>
                                            Edit
                                        </EditButton>
                                    }
                                    {edit &&
                                        <SubmitButton disabled={!changedValue} onClick={() => handleConfirmedAttendance()}>
                                            Save
                                        </SubmitButton>
                                    }
                                </>
                            }

                        </BoxContent>

                    </DialogBox>
                }
            >

                <ConfirmedAttendanceBtn onClick={() => handleOpen()}>
                    <ConfAttendanceIcon />
                </ConfirmedAttendanceBtn>
            </Popover>
        </Container>
    );
};

export default ConfirmAttendanceComponent;

const Container = styled.div`
position:relative;
`;

const ConfirmedAttendanceBtn = styled.button`
background:none;
border:none;
cursor:pointer;
display:flex;
align-items:center;
justify-content:center;

`;
const DialogBox = styled.div`

background: #FFFFFF;
border-left: 3px solid #006FFF;
box-shadow: 0px 0px 10px rgba(98, 101, 112, 0.15);
border-radius: 10px;

z-index:2;
padding:12px;

`;
const BoxContent = styled.div`
display:flex;
flex-direction:column;
row-gap:10px;
height:100%;
padding:0 20px 10px 8px;


`;
const TeamsWrapper = styled.div`
display:flex;
flex-direction:column;
row-gap:3px;
`;
const Team = styled.div`
display:flex;
column-gap:5px;
align-items:center;
img{
    background: #FFFFFF;
    border: 0.5px solid #EEF2FD;
    border-radius: 2px;
    height:17px;
    padding:1px;
}
h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    color: #001229;
}
`;
const BoxHeader = styled.div`
display:flex;
width:100%;
justify-content:end;
color:var(--darkBlue);
svg{
    height:10px;
    cursor:pointer;
}
`;
const InputGroup = styled.div`
display:flex;
flex-direction:column;
margin-top:10px;
row-gap:5px;
h3{
    font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 12px;
color: #001229;
}
input{
    background: #FFFFFF;
border: 1px solid #BAC3E8;
border-radius: 6px;
height:30px;
color: #263549;
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 14px;
padding:0 13px;

}
`;
const SubmitButton = styled.button`
display:flex;
padding:0 20px;
height:22px;
display:flex;
justify-content:center;
align-items:center;
width:fit-content;
background: #006FFF;
border-radius: 4px;
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 20px;
color: #FFFFFF;
cursor:pointer;
border:none;
:disabled{
    cursor:initial;
    opacity:0.5;
}
`;
const EditButton = styled(SubmitButton)`
background:white;
color:var(--primaryBlue);
border:1px solid var(--primaryBlue);
display:${props => props.hidden ? "none" : "block"}
`;