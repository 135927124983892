import React from 'react'
import styled from 'styled-components'

const PlayersHeader = () => {
    return (
        <Container>
            <NameContainer>
                <h3 style={{ width: "80px" }}>ID</h3>
                <h3 style={{ width: "110px" }}>FIRST NAME</h3>
                <h3 style={{ width: "110px" }}>LAST NAME</h3>
                <h3 style={{ width: "110px" }}>SHORT NAME</h3>
                <h3 style={{ width: "110px" }}>OTHER NAME</h3>
            </NameContainer>

            <h3 style={{ width: "56px" }}>NUMBER</h3>
            <PositionContainer>
                <h3 style={{ width: "115px" }}>FIRST POSITION</h3>
                <h3 style={{ width: "115px" }}>SECOND POSITION</h3>
                <h3 style={{ width: "115px" }}>THIRD POSITION</h3>
            </PositionContainer>
        </Container>
    )
}

export default PlayersHeader
const Container = styled.div`
display:flex;
column-gap:10px;

h3{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: ${({ theme }) => theme.editLeague.groupsTextColor}
  }
`
const NameContainer = styled.div`
display:flex;
column-gap:10px;
`
const PositionContainer = styled.div`
display: flex;
column-gap: 10px;
`