import { POST } from "../../APiHelper/API";

export const getCupsAndFilterAPI = (
    data,
    onSuccess,
    onError
) => {

    POST("/api/v1/cups/", data, onSuccess, onError)
}

export const getCupsForAdminAPICall = (
    data,
    onSuccess,
    onError
) => {

    POST("/api/v1/cups/all", data, onSuccess, onError)
}