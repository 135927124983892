import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import AddPlayerGroupPosition from "./AddPlayerGrouPosition";
import { ReactComponent as RemoveIcon } from "../../../assets/icons/LeaguesIcons/removeBin.svg";
import { useSquadManagerService } from "../../../context/useSquadManagerService";
import { notifyError } from "../../../helper/Generic/Notifications";
import { cloneDeep } from "lodash";

const SelectedPositionsMenuGroupPlayers = ({
  value,
  setValue,
  squadData,
  setIsLoading,
  refreshOnClose,
  loadInitialData,
}) => {
  const {
    add_player_fightin_for_position,
    remove_player_fightin_for_position,
  } = useSquadManagerService();
  const shouldReinitialize = useRef(false);

  const mapCategoryType = (category) => {
    let categoryType = {
      bg: "",
      color: "",
    };
    switch (category) {
      case "gk":
        categoryType = {
          bg: ({ theme }) => theme.playerPerformaceCard.gk.bg,
          color: ({ theme }) => theme.playerPerformaceCard.gk.color,
        };
        break;
      case "neutral":
        categoryType = {
          bg: ({ theme }) => theme.playerPerformaceCard.neutral.bg,
          color: ({ theme }) => theme.playerPerformaceCard.neutral.color,
        };
        break;
      case "defensive":
        categoryType = {
          bg: ({ theme }) => theme.playerPerformaceCard.defensive.bg,
          color: ({ theme }) => theme.playerPerformaceCard.defensive.color,
        };
        break;
      case "attack":
        categoryType = {
          bg: ({ theme }) => theme.playerPerformaceCard.attack.bg,
          color: ({ theme }) => theme.playerPerformaceCard.attack.color,
        };
        break;

      default:
        break;
    }
    return categoryType;
  };

  const mapName = (currentPLayer) => {
    if (!currentPLayer) {
      return;
    }
    let player = currentPLayer.player;
    let fullName = player.first_name + " " + player.last_name;

    return fullName;
  };

  const handleNewPlayerAdded = (newPlayer, position) => {
    let copy = cloneDeep(value);
    if (copy.position === position) {
      if (!copy.players) {
        copy.players = [newPlayer];
      } else {
        copy.players.push(newPlayer);
      }
    }

    setValue((prev) => {
      let mapped = prev.map((item) => {
        if (item.position === position) {
          return {
            position: item.position,
            type: item.type,
            players: copy.players,
          };
        } else {
          return item;
        }
      });
      return mapped;
    });
  };

  const handleRemovePlayerFromList = (playerInGroupId, position) => {
    setValue((prev) => {
      let mapped = prev.map((item) => {
        if (item.position === position) {
          let filtered = item.players.filter(
            (player) => player.id != playerInGroupId
          );
          return {
            position: item.position,
            type: item.type,
            players: filtered,
          };
        } else {
          return item;
        }
      });
      return mapped;
    });
  };

  const handleAddNewPlayerToGroup = (squad_id, selection_id, position) => {
    setIsLoading(true);
    add_player_fightin_for_position(
      squad_id,
      selection_id,
      position,
      function (response) {
        const resp = response.player_in_group;
        handleNewPlayerAdded(resp, position);
        setIsLoading(false);
        refreshOnClose.current = true;
        shouldReinitialize.current = true;
      },
      function (error) {
        notifyError("Something wrong");
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  const handleRemovePlayer = (playerInGroupId, position) => {
    setIsLoading(true);
    remove_player_fightin_for_position(
      playerInGroupId,
      function (response) {
        refreshOnClose.current = true;
        shouldReinitialize.current = true;
        setIsLoading(false);
        handleRemovePlayerFromList(playerInGroupId, position);
      },
      function (error) {
        notifyError("Something wrong");
        console.log(error);
        setIsLoading(false);
      }
    );
  };


  return (
    <Container>
      <MenuList>
        <MenuCategory type={mapCategoryType(value.type)}>
          <p type={mapCategoryType(value.type)}>{value.position}</p>
        </MenuCategory>
        {value?.players?.map((player, index) => (
          <SelectPlayerForPosition key={index}>
            <ElementInput value={mapName(player.selection)} disabled />
            <DeleteButton
              onClick={() => handleRemovePlayer(player.id, value.position)}
            >
              <RemoveIcon />
            </DeleteButton>
          </SelectPlayerForPosition>
        ))}

        <AddPlayerGroupPosition
          handleAddNewPlayerToGroup={handleAddNewPlayerToGroup}
          squadData={squadData}
          position={value.position}
          shouldReinitialize={shouldReinitialize}
          selectedPlayers={value.players}
        />
      </MenuList>
    </Container>
  );
};

export default SelectedPositionsMenuGroupPlayers;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;
const Subtitle = styled.h2`
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  color: ${({ theme }) => theme.editLeague.groupsTextColor};
`;

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 10px;
  background: none;
  position: absolute;
  width: 100%;
  border-radius: 10px;
  position: relative;
  gap: 5px;
`;

const MenuCategory = styled.div`
  display: flex;
  padding: 7px 20px;
  border-radius: 6px;
  width: 100%;
  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: ${(props) => props?.type?.color};
    text-transform: uppercase;
  }
  background: ${(props) => props?.type?.bg};
`;
const SelectPlayerForPosition = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
`;
const ElementInput = styled.input`
  height: 40px;
  background: ${({ theme }) => theme.editLeague.readOnlyInputs};
  width: 100%;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  padding: 10px 15px;

  :focus {
    outline: none;
  }
  ::placeholder {
    color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
    opacity: 0.7;
  }
  :disabled {
    background: ${({ theme }) => theme.editLeague.readOnlyInputs};
    color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
    -webkit-text-fill-color: ${({ theme }) =>
    theme.editLeague.readOnlyInputsColor};
  }
`;
const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;

  svg {
    width: 40px;
    height: 40px;
  }
  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`;
