// if change values here change also in positionsList.js
import { Positions } from "./positionsList";
const POSITIONS = [];
Positions.forEach((item) => POSITIONS.push(item.position))
// [
//   "GK",
//   "LWB",
//   "LB",
//   "CB",
//   "RB",
//   "RWB",
//   "DM",
//   "CM",
//   "LM",
//   "RM",
//   "CAM",
//   "AM",
//   "LW",
//   "RW",
//   "CF",
// ];

const IMPORTANCE = ["sk", "cp", "i", "ab", "r", "rot", "b", "res", "yp"];

export const playerImportanceSortingValue = (player) => {
  return sortingFieldValue(IMPORTANCE, player.importance);
};

export const playerPositionSortingValue = (player) => {
  return sortingFieldValue(POSITIONS, player.position);
};

export const playerAttackDefenceRatingSortingValue = (player) => {
  return player.attack_rating + player.defence_rating;
};

// Returns the index of a value in an array, if it does not exists returns the length of the array

const sortingFieldValue = (array, value) => {
  let index = array.indexOf(value);
  if (index == -1) {
    index = array.length;
  }

  return index;
};
