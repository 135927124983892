import { PUT } from "../APiHelper/API";


export const setAbnormalValue = (logged_bet_id, ret_stake, confirmed_price, abnormal, onSuccess, onError) => {
    const data = {
        logged_bet_id: logged_bet_id,
        ret_stake: ret_stake,
        confirmed_price: confirmed_price,
        abnormal: abnormal
    }
    PUT("/api/v1/bets/set_abnormal_value", data, onSuccess, onError)
}