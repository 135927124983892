

import { POST } from "../../APiHelper/API";
let source;

export const searchParticipantAPICall = (name, onSuccess, onError) => {
    const data = {
        name: name
    }
    source = POST('/api/v1/leagues/participant', data, onSuccess, onError, true, source)
}

export const addParticipantAPICall = (league_id, participant_id, onSuccess, onError) => {
    const data = {
        participant_id: participant_id
    }
    source = POST(`/api/v1/leagues/${league_id}/add_participant_team`, data, onSuccess, onError, true, source)
}
