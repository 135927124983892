import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightTheme } from "../../../theme/theme";
import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";


const SeasonMenu = ({ label, dataList, value, setValue }) => {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleCheck = (item) => {
    setValue(item);
  };

  const findSelectedSeasonName = (season_id) => {
    let found = dataList.find((item) => item.id == season_id);
    if (found) {
      return found.name;
    }
    else {
      return "Season";
    }

  };

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref}>
      <DropDownMenu onClick={() => handleMenuOpen()} isMenuOpen={isMenuOpen}>
        <p>{findSelectedSeasonName(value)}</p>
        {isMenuOpen ? <UpSvg /> : <DownSvg />}
      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>
          {dataList?.map((item, index) => (
            <DropDownMenuItem key={index} onClick={(e) => handleCheck(item.id)}>
              <div className={item.id === value ? "active" : "nonActive"}>
                <input
                  key={index}
                  readOnly
                  //onChange={(e) => handleCheck(e, item)}
                  className="check"
                  type="radio"
                  checked={value == item.id}
                  value={item}
                ></input>
                <p> {item.name}</p>
              </div>
            </DropDownMenuItem>
          ))}
        </DropDownList>
      )}
    </Container>
  );
};


export default SeasonMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
width:150px;
  position: relative;

  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: ${({ theme }) => theme.dropDownColor};
  }
`;
const DropDownMenu = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.menuBgColor};
    padding: 4px 10px;
    border-radius: 8px;
    border-radius: ${(props) => props.isMenuOpen ? "8px 8px 0 0" : ""} ;
    p{
      margin-right:5px;
      width:100%;
      overflow:hidden;
      text-overflow:elipsis;
      white-space:nowrap;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 20px;
      color: ${({ theme }) => theme.dropDownColor};
    }
    svg {
      cursor: pointer;
      fill: ${({ theme }) => theme.dropDownSvgFill};
      stroke: ${({ theme }) => theme.dropDownSvgStroke};
    }
  `;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top: 28px;
  border-top: 2px solid;
  border-color: ${({ theme }) => (theme === lightTheme ? "#3C4A5C" : "white")};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  max-height: 400px;
    width:100%;
  overflow-x: hidden;
  color: black;
   
  .searchGroup {
    position: relative;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.menuTextColor};
    input {
      align-items: center;
      position: relative;
      width: 100%;
      padding: 5px 10px 5px 20px;
      background: ${({ theme }) => theme.leagueBG};
      border: none;
      border: 1px solid #d9dfe7;
      border-radius: 8px;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      :focus-visible {
        outline: none;
        border: 1px solid ${({ theme }) => theme.dropDownSvgStroke};
      }
      color: ${({ theme }) => theme.menuTextColor};
    }
    svg {
      position: absolute;
      z-index: 1;
      top: 7px;
      left: 7px;
    }
  }
`;
const DropDownMenuItem = styled.li`

  cursor: pointer;
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;
  background-color: ${({ theme }) => theme.dropDownBG};
  padding: 5px 10px;
  div {
    column-gap: 5px;
    align-items: center;
    display: flex;
    flex-direction: row;
    .check {
      margin-right: 5px;
      background: red;
      color: red;
    }
  }
  p {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.dropDownColor};
  }
  .active {
    p {
      color: rgba(97, 131, 255, 1);
    }
  }
  .nonActive {
    :hover {
      p {
        color: ${({ theme }) => theme.buttonBG};
      }
    }
    p {
      color: ${({ theme }) => theme.dropDownColor};
    }
  }
`;
