import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownSvg } from "../../../assets/icons/downSimpleArrow.svg";
import {
  buildPlayersListForDropdown,
  filterInvalidPlayers,
} from "../../../helper/lineup/LineupHelper";
import { checkPositionColor } from "../../../helper/Analyst/SquadManager/checkPOSNCellColor";
import cloneDeep from "lodash.clonedeep";
import { ALL_POSITIONS, BENCH } from "../../../helper/Values/TeamFormation";
import { initializePositionWithFirstPosition } from "../../../helper/Analyst/SquadManager/initializePositionWithFirstPosition";
import { useSelector } from "react-redux";
import { selectUser } from "../../../Redux/user/user";
import { displayPlayersNameByOption } from "../../../helper/Analyst/LeaguesData/displayFullName";

const SelectTeamPlayerMenu = ({
  playersList,
  currentSelection,
  setCurrentSelection,
  width,
  bg,
  type,
  currentPlayerIndex,
  isDuplicated,
  playerChanged,
  disabled,
  emptyElement,
}) => {
  const user = useSelector(selectUser);
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const selectedPlayer = currentSelection[currentPlayerIndex];

  const emptyItem = {
    attack_rating: null,
    confirmed: null,
    defence_rating: null,
    fatigue: null,
    has_message: null,
    id: null,
    importance: null,
    injured: null,
    long_term_formation: null,
    match_rating: null,
    player: null,
    player_id: null,
    position: null,
    squad_id: null,
    squad_number: null,
    starting_chance: null,
    status: null,
    xi: null,
  };


  const isPlayerInjured = (player) => {
    if (player.status === "injured") {
      return true
    }
    else {
      return false
    }
  }

  const handleSortPlayers = (players) => {
    let newSort = players;
    let injuredPlayers = players.filter((player) => isPlayerInjured(player))
    let normalPlayers = players.filter((player) => !isPlayerInjured(player))

    //sort based on position list
    injuredPlayers.sort((a, b) => {
      return (
        ALL_POSITIONS.indexOf(a.position) -
        ALL_POSITIONS.indexOf(b.position)
      )
    })

    //sort based on position list
    normalPlayers.sort((a, b) => {
      return (
        ALL_POSITIONS.indexOf(a.position) -
        ALL_POSITIONS.indexOf(b.position)
      )
    })

    return normalPlayers.concat(injuredPlayers)
  }

  // For normal players (not bench) show all their possible positions
  let playersToSelect = filterInvalidPlayers(playersList);

  //initialize position with player.position_1
  playersToSelect = initializePositionWithFirstPosition(playersToSelect);
  playersToSelect = handleSortPlayers(playersToSelect)


  //sort based on statusOrderList
  // let statusSortOrder = ["available", "bench", "rested", "injured", "unavailable", "n/a"]
  // playersToSelect.sort((a, b) => {
  //   return (
  //     statusSortOrder.indexOf(a.status) -
  //     statusSortOrder.indexOf(b.status)
  //   )
  // })



  if (type != BENCH) {
    playersToSelect = buildPlayersListForDropdown(playersList, type);
  }

  // const getFullName = (player) => {
  //   if (!player || !player.player) {
  //     return null;
  //   }
  //   let fullName =
  //     player?.player?.first_name + " " + player?.player?.last_name;

  //   if (user.use_short_player_names && player?.player?.short_name && player?.player?.short_name != "") {
  //     fullName = player.player.short_name
  //   }

  //   if (user.use_other_player_names && player?.player?.other_player_name && player?.player?.other_player_name != "") {
  //     fullName = player.player.other_player_name
  //   }

  //   return fullName;
  // };

  const toggleMenu = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  const handleClick = (player) => {
    let newState = cloneDeep(currentSelection);
    newState[currentPlayerIndex] = player;
    setCurrentSelection(newState);
    toggleMenu();
  };

  const selectedPlayerPosition = () => {
    if (selectedPlayer?.position != null) {
      return selectedPlayer?.position;
    }

    return selectedPlayer?.player.position_1;
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref} width={width} bg={bg} playerChanged={playerChanged}>
      <DropDownMenu
        onClick={() => !disabled && toggleMenu()}
        isMenuOpen={isMenuOpen}
        width={width}
        isDuplicated={isDuplicated}
        type={type}
      >
        {!selectedPlayer?.id &&
          (type == BENCH ? (
            <MenuLabel>Choose bench Player</MenuLabel>
          ) : (
            <MenuLabel>Choose starting Player</MenuLabel>
          ))}
        {selectedPlayer?.id && (
          <>
            {" "}
            <PlayerInfo>
              <PlayerPosition
                className={checkPositionColor(selectedPlayerPosition())}
              >
                <p>{selectedPlayerPosition()}</p>
              </PlayerPosition>
              <PlayerNumber>
                {selectedPlayer?.squad_number !== undefined && (
                  <p>{selectedPlayer.player.squad_number}</p>
                )}
              </PlayerNumber>
              <PlayerName injured={selectedPlayer.status == "injured"}>{displayPlayersNameByOption(selectedPlayer, user)} </PlayerName>
            </PlayerInfo>
            <PlayerStats>
              <Stats>
                {selectedPlayer?.importance} (A {selectedPlayer?.attack_rating}{" "}
                / D {selectedPlayer?.defence_rating})
              </Stats>
            </PlayerStats>
          </>
        )}

        <Arrow>
          {isMenuOpen ? (
            <DownSvg style={{ rotate: "180deg" }} />
          ) : (
            !disabled && <DownSvg />
          )}
        </Arrow>
      </DropDownMenu>

      {isMenuOpen && (
        <DropDownList type={type}>
          {emptyElement && (
            <DropDownMenuItem key={9999} onClick={() => handleClick(emptyItem)}>
              <div
                className={
                  emptyItem.id === selectedPlayer.id ? "active" : "nonActive"
                }
              >
                <PlayerInfo>
                  <PlayerPosition className={checkPositionColor("")}>
                    <p>-</p>
                  </PlayerPosition>
                  <PlayerNumber>
                    <p>-</p>
                  </PlayerNumber>
                  <PlayerName>Clear selected player </PlayerName>
                </PlayerInfo>
                <PlayerStats>
                  <Stats>(A / D )</Stats>
                </PlayerStats>
              </div>
            </DropDownMenuItem>
          )}

          {playersToSelect.map((item, index) => (

            < DropDownMenuItem key={index} onClick={() => handleClick(item)}>
              <div
                className={
                  item.position === selectedPlayer?.position &&
                    item.id === selectedPlayer?.id
                    ? "active"
                    : "nonActive"
                }
              >
                <PlayerInfo>
                  <PlayerPosition className={checkPositionColor(item.position)}>
                    <p>{item.position}</p>
                  </PlayerPosition>
                  <PlayerNumber>
                    <p>{item.player.squad_number}</p>
                  </PlayerNumber>
                  <PlayerName injured={item.status == "injured"}  >
                    {displayPlayersNameByOption(item, user)}{" "}
                  </PlayerName>
                </PlayerInfo>
                <PlayerStats>
                  <Stats>
                    {item?.importance} (A {item.attack_rating} / D{" "}
                    {item.defence_rating})
                  </Stats>
                </PlayerStats>
              </div>
            </DropDownMenuItem>
          ))}
        </DropDownList>
      )
      }
    </Container >
  );
};

export default SelectTeamPlayerMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width != null ? props.width : "100%")};
  background: ${(props) =>
    props.playerChanged
      ? ({ theme }) => theme.projectedLineup.playerChanged
      : ({ theme }) => theme.projectedLineup.playerMenuBg};
  position: relative;
  border-radius: 2px;
  .green {
    background: #ebfcf3;
    p {
      color: #23a760;
    }
  }
  .blue {
    background: #e4ecff;
    p {
      color: #4393fc;
    }
  }
  .orange {
    background: #fff3e7;
    p {
      color: #ff8d21;
    }
  }
  .red {
    background: #fff5f5;
    p {
      color: #ec4848;
    }
  }
`;
const DropDownMenu = styled.div`
  position: relative;
  height: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) =>
    props.isDuplicated
      ? ({ theme }) => theme.projectedLineup.playerDuplicated
      : "transparent"};
  padding: 2px 5px;
  border: 1px solid ${({ theme }) => theme.projectedLineup.playerMenuBorder};
  column-gap: 5px;
  border-radius: 2px;
  border-radius: ${(props) =>
    props.isMenuOpen
      ? props.type === BENCH
        ? "0 0 4px 4px"
        : "4px 4px 0 0"
      : ""};

  .group {
    align-items: center;
    display: flex;
    column-gap: 5px;
    img {
      height: 14px;
      width: 18px;
      border-radius: 4px;
    }
  }
  img {
    cursor: pointer;
    height: 21px;
    width: 22px;
  }
  svg {
    cursor: pointer;
    fill: #f8f9fb;
    stroke: #006fff;
  }
`;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 1;

  top:${(props) => (props.type === BENCH ? "" : "24px")};
  bottom:${(props) => (props.type === BENCH ? "24px" : "")};

  border:1px solid ${({ theme }) => theme.projectedLineup.playerMenuBorder};
  border-top:${(props) => (props.type === BENCH ? "" : "2px solid black")};
  border-bottom:${(props) => (props.type === BENCH ? "2px solid black" : "")};
  display: flex;
  flex-direction: column;
  border-radius: 4px 4px 0 0;

  max-height: 400px;
  width: 100%;
  overflow-x: hidden;
  color: black;
    svg {
      position: absolute;
      z-index: 1;
      top: 8, 5px;
      left: 7px;
    }
  }
`;
const DropDownMenuItem = styled.li`
  cursor: pointer;
  img {
    border-radius: 4px;
    height: 14px;
    width: 18px;
  }

  list-style-type: none;
  background-color: ${({ theme }) => theme.projectedLineup.playerMenuBg};
  padding: 2px 5px;
  div {
    column-gap: 5px;
    align-items: center;
    display: flex;
    flex-direction: row;
    .check {
      margin-right: 5px;
      background: red;
      color: red;
    }
  }

  .active {
    p {
      color: rgba(97, 131, 255, 1);
    }
  }
  .nonActive {
    :hover {
      p {
        color: #006fff;
      }
    }
  }
`;
const PlayerInfo = styled.div`
  display: flex;
  width: 100%;
  column-gap: 5px;
  align-items: center;
  position: relative;
`;
const PlayerStats = styled.div`
  display: flex;
  min-width: 85px;
  background: #ebefff;
  border-radius: 2px;
  padding: 2px 4px;
`;
const Stats = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  text-transform: uppercase;
  color: #4b5377;
`;
const PlayerPosition = styled.div`
  border-radius: 2px;
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    border-radius: 4px;
  }
  min-width: 40px;
  display: flex;
  justify-content: center;
  padding: 3px;
`;
const PlayerNumber = styled.div`
  min-width: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  p {
    min-width: 30px;
    padding: 3px 4px;
    background: #f9fafe;
    border-radius: 4px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    color: #7d94ce;
  }
`;
const PlayerName = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  text-decoration:${(props) => props.injured ? "line-through" : ""};
  color: ${({ theme }) => theme.projectedLineup.playerMenuTextColor};
  width: 100%;
  max-width: 175px;
`;
const Arrow = styled.div`
  display: flex;
`;
const MenuLabel = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.projectedLineup.playerMenuTextColor};
  opacity: 0.5;
`;
