import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownSvg } from "../../../assets/icons/downSimpleArrow.svg";
import { StChanceList } from "../../../helper/Values/StChance";

const StChanceMenu = ({ selectedValue, setSelectedValue, width }) => {
    const ref = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuOpen = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        } else {
            setIsMenuOpen(true);
        }
    };

    const handleCheck = (item) => {
        setSelectedValue(item.value);
        handleMenuOpen();
    };
    const setValueColor = (value) => {
        switch (value) {
            case "nailed_on":
                return "nailedOn";
            case "likely":
                return "likely";
            case "50-50":
                return "fiftyFifty";
            case "unlikely":
                return "unlikely";
            case "no_chance":
                return "noChance";
            default:
                break;
        }
    };

    const mapValue = (value) => {
        switch (value) {
            case "nailed_on":
                return "Nailed On";
            case "likely":
                return "Likely";
            case "50-50":
                return "50-50";
            case "unlikely":
                return "Unlikely";
            case "no_chance":
                return "No Chance";
            default:
                break;
        }
    };

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    return (
        <Container ref={ref} width={width}>
            <DropDownMenu
                onClick={() => handleMenuOpen()}
                isMenuOpen={isMenuOpen}
                width={width}
            >

                {selectedValue != undefined ? (
                    <p className={setValueColor(selectedValue)}>{mapValue(selectedValue)}</p>

                ) : (
                    <p>-</p>
                )}
                {isMenuOpen ? <DownSvg style={{ rotate: "180deg" }} /> : <DownSvg />}
            </DropDownMenu>
            {isMenuOpen && (
                <DropDownList>
                    {StChanceList.map((item, index) => (
                        <DropDownMenuItem key={index} onClick={() => handleCheck(item)} className={item === selectedValue ? "active" : "nonActive"}>
                            <input
                                key={index}
                                readOnly
                                onChange={() => handleCheck(item)}
                                type="radio"
                                className="check"
                                checked={selectedValue == item.value}
                                value={item}
                            ></input>
                            <p className={setValueColor(item.value)}>{mapValue(item.value)}</p>
                        </DropDownMenuItem>
                    ))}
                </DropDownList>
            )}
        </Container>
    );
};

export default StChanceMenu;
const Container = styled.div`
cursor:pointer;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width != null ? props.width : "63px")};
  position: relative;
  width:70px;
  .nailedOn{color:#47B77B};
  .likely{color: #EBCC2A};
  .fiftyFifty{color: #FF8D21};
  .unlikely{color: #FF4B4B};
  .noChance{color: #334154};
`;
const DropDownMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 3px 3px;
  border: 1px solid #cbd7f5;
  border-radius: 2px;
  width:70px;
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 150%;
    color: rgba(0, 18, 41, 0.8);

    overflow:hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }
  svg{
    color:#006fff;
  }
`;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top:19px;
  border-left:1px solid;
  border-bottom:1px solid;
  border-right:1px solid;
  border-color: #CBD7F5;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 4px 4px;
  max-height: 400px;
  width:100%;
  overflow-x: hidden;
  color: black;

`;
const DropDownMenuItem = styled.li`
  cursor: pointer;
  :last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  :first-child{
    border-top: 1px solid black;
  }
  list-style-type: none;
  background-color: #ffffff;
  padding: 3px 2px;
  display: flex;
  align-items: center;
 flex-direction: row;
 column-gap:2px;
  .check{
    height:10px;
  }
  
  p {
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    color: rgba(0, 18, 41, 0.8);
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }
  
`;
