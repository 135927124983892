import { POST } from "../APiHelper/API";
import { formatted_date_for_backend } from "../../helper/Generic/dateTimeFormat";

export const getCurrentUserFixtures = (
  country_ids,
  start_time,
  end_time,
  competition_ids,
  term,
  onSuccess,
  onError
) => {
  const data = {
    country_ids: country_ids,
    start_time: formatted_date_for_backend(start_time),
    end_time: formatted_date_for_backend(end_time),
    competition_ids: competition_ids,
    term: term,
  };

  let source = POST("/api/v1/fixtures/", data, onSuccess, onError, true);
  return source;
};
