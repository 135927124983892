import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { checkPositionColor } from '../../helper/Analyst/SquadManager/checkPOSNCellColor'
import { ATTACK, DEFENSIVE, GK, MIDFIELD, OTHER } from '../../helper/Values/TeamFormation';
import { useSelector } from 'react-redux';
import { selectUser } from '../../Redux/user/user';
import { displayPlayersNameByOption } from '../../helper/Analyst/LeaguesData/displayFullName';

const PlayerStatsRow = ({ player, type }) => {

    const user = useSelector(selectUser);

    const [field1, setField1] = useState({
        value: "",
        type: "normal"
    });
    const [field2, setField2] = useState({
        value: "",
        type: "normal"
    });
    const [field3, setField3] = useState({
        value: "",
        type: "normal"
    });
    const [field4, setField4] = useState({
        value: "",
        type: "normal"
    });

    const [filed1St, setField1St] = useState({
        value: "",
        type: "normal"
    })
    const [filed2St, setField2St] = useState({
        value: "",
        type: "normal"
    })
    const [filed3St, setField3St] = useState({
        value: "",
        type: "normal"
    })
    const [filed4St, setField4St] = useState({
        value: "",
        type: "normal"
    })
    const [filed5St, setField5St] = useState({
        value: "",
        type: "normal"
    })
    const [filed6St, setField6St] = useState({
        value: "",
        type: "normal"
    })
    const [filed7St, setField7St] = useState({
        value: "",
        type: "normal"
    })
    const [filed8St, setField8St] = useState({
        value: "",
        type: "normal"
    })

    var fullName = player.player_data.first_name + " " + player.player_data.last_name;

    if (user.use_short_player_names && player?.player_data?.short_name && player?.player_data?.short_name != "") {
        fullName = player.player_data.short_name
    }
    if (user.use_other_player_names && player?.player_data?.other_player_name && player?.player_data?.other_player_name != "") {
        fullName = player.player_data.other_player_name
    }

    const setNormalValue = (value) => {
        if (value == "NaN") {
            return {
                value: 0,
                type: "NaN"
            }
        }
        return {
            value: value,
            type: "normal"
        }
    }

    const setPercent = (value) => {

        if (value == "NaN") {
            return {
                value: 0,
                type: "NaN"
            }
        }

        return {
            value: value,
            type: "percent",
        }
    }

    const percentColorBasedOnValue = (field) => {
        const { type, value } = field;
        if (type !== "percent") {
            return
        }
        if (value > 0 && value <= 30) {
            return ({ theme }) => theme.POSCell.atkColor
        }
        if (value > 30 && value <= 60) {
            return ({ theme }) => theme.POSCell.neutralColor
        }
        if (value > 60 && value <= 80) {
            return ({ theme }) => theme.POSCell.defColor
        }
        if (value > 80 && value <= 100) {
            return ({ theme }) => theme.POSCell.gkColor
        }
    }

    const displayValueBasedOnType = (field) => {
        switch (field.type) {

            case "percent":
                return <p>{field.value} % </p>

            case "normal":
                return <p>{field.value} </p>

            case "NaN":
                return <p>NaN</p>

            default:
                break;
        }
    }

    const checkType = (type) => {

        switch (type) {

            case GK:
                setField1(setNormalValue(parseFloat(player.gk_conceded_goals).toFixed(1)))
                setField2(setNormalValue(parseFloat(player.xg_save).toFixed(1)))
                setField3(setNormalValue(parseFloat(player.gk_aerial_duels).toFixed(1)))
                setField4(setPercent(parseFloat(player.gk_aerial_duels_won).toFixed(1)))

                setField1St(setNormalValue(parseInt(player.minutes_played)))
                setField2St(setNormalValue(parseInt(player.starts)))
                setField3St(setNormalValue(parseInt(player.yellow_cards)))
                setField4St(setNormalValue(parseInt(player.red_cards)))
                setField5St(setNormalValue(parseInt(player.gk_clean_sheets)))
                setField6St(setNormalValue(parseInt(player.gk_saves)))
                setField7St(setNormalValue(parseInt(player.assists)))
                setField8St(setNormalValue(parseFloat(player.xg_assist).toFixed(1)))



                break;

            case DEFENSIVE:
                setField1(setNormalValue(parseFloat(player.defensive_duels).toFixed(1)))
                setField2(setPercent(parseFloat(player.new_defensive_duels_won).toFixed(1)))
                setField3(setNormalValue(parseFloat(player.interceptions).toFixed(1)))
                setField4(setNormalValue(parseFloat(player.shots_blocked).toFixed(1)))

                setField1St(setNormalValue(parseInt(player.minutes_played)))
                setField2St(setNormalValue(parseInt(player.starts)))
                setField3St(setNormalValue(parseInt(player.yellow_cards)))
                setField4St(setNormalValue(parseInt(player.red_cards)))
                setField5St(setNormalValue(parseInt(player.goals)))
                setField6St(setNormalValue(parseFloat(player.xg_shot).toFixed(1)))
                setField7St(setNormalValue(parseInt(player.assists)))
                setField8St(setNormalValue(parseFloat(player.xg_assist).toFixed(1)))
                break;

            case MIDFIELD:
                setField1(setNormalValue(parseFloat(player.forward_passes).toFixed(1)))
                setField2(setNormalValue(parseFloat(player.key_passes).toFixed(1)))
                setField3(setNormalValue(parseFloat(player.interceptions).toFixed(1)))
                setField4(setNormalValue(parseFloat(player.recoveries).toFixed(1)))

                setField1St(setNormalValue(parseInt(player.minutes_played)))
                setField2St(setNormalValue(parseInt(player.starts)))
                setField3St(setNormalValue(parseInt(player.yellow_cards)))
                setField4St(setNormalValue(parseInt(player.red_cards)))
                setField5St(setNormalValue(parseInt(player.goals)))
                setField6St(setNormalValue(parseFloat(player.xg_shot).toFixed(1)))
                setField7St(setNormalValue(parseInt(player.assists)))
                setField8St(setNormalValue(parseFloat(player.xg_assist).toFixed(1)))
                break;

            case ATTACK:
                setField1(setNormalValue(parseFloat(player.successful_dribbles).toFixed(1)))
                setField2(setNormalValue(parseFloat(player.key_passes).toFixed(1)))
                setField3(setNormalValue(parseFloat(player.shots_on_target).toFixed(1)))
                setField4(setNormalValue(parseFloat(player.opponent_half_recoveries).toFixed(1)))

                setField1St(setNormalValue(parseInt(player.minutes_played)))
                setField2St(setNormalValue(parseInt(player.starts)))
                setField3St(setNormalValue(parseInt(player.yellow_cards)))
                setField4St(setNormalValue(parseInt(player.red_cards)))
                setField5St(setNormalValue(parseInt(player.goals)))
                setField6St(setNormalValue(parseFloat(player.xg_shot).toFixed(1)))
                setField7St(setNormalValue(parseInt(player.assists)))
                setField8St(setNormalValue(parseFloat(player.xg_assist).toFixed(1)))
                break;

            case OTHER:
                setField1("")
                setField2("")
                setField3("")
                setField4("")

                setField1St(setNormalValue(parseInt(player.minutes_played)))
                setField2St(setNormalValue(parseInt(player.starts)))
                setField3St(setNormalValue(parseInt(player.yellow_cards)))
                setField4St(setNormalValue(parseInt(player.red_cards)))
                setField5St(setNormalValue(parseInt(player.goals)))
                setField6St(setNormalValue(parseFloat(player.xg_shot).toFixed(1)))
                setField7St(setNormalValue(parseInt(player.assists)))
                setField8St(setNormalValue(parseFloat(player.xg_assist).toFixed(1)))
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        if (!player) {
            return
        }
    }, [player])

    useEffect(() => {
        checkType(type);
    }, [type, player])

    return (
        <Container>
            <LeftSide>
                <Number>
                    <p>{player.shirt_number}</p>
                </Number>
                <Avatar>
                    {player?.player_data?.picture_url && <img src={player.player_data.picture_url} alt='playerAvatar' />}
                </Avatar>
                <NameAndPosition>
                    <h1>{fullName}</h1>
                    {player.player_data.position_1 &&
                        <POSCell
                            className={checkPositionColor(player.player_data.position_1)}>
                            <p>{player.player_data.position_1}</p>
                        </POSCell>}

                </NameAndPosition>
                <StatsCell>
                    {displayValueBasedOnType(filed1St)}
                </StatsCell>
                <StatsCell style={{ minWidth: "64px" }}>
                    {displayValueBasedOnType(filed2St)}
                </StatsCell>
                <StatsCell style={{ minWidth: "65px" }}>
                    {displayValueBasedOnType(filed3St)}
                </StatsCell>
                <StatsCell style={{ minWidth: "65px" }}>
                    {displayValueBasedOnType(filed4St)}
                </StatsCell>
                <StatsCell style={{ minWidth: "48px" }}>
                    {displayValueBasedOnType(filed5St)}
                </StatsCell>
                <StatsCell style={{ minWidth: "74px" }}>
                    {displayValueBasedOnType(filed6St)}
                </StatsCell>
                <StatsCell style={{ minWidth: "60px" }}>
                    {displayValueBasedOnType(filed7St)}
                </StatsCell>
                <StatsCell style={{ minWidth: "74px" }}>
                    {displayValueBasedOnType(filed8St)}
                </StatsCell>
            </LeftSide>

            <RightSide>
                <StatsCell style={{ minWidth: "86px" }} percentColor={percentColorBasedOnValue(field1)}>
                    {displayValueBasedOnType(field1)}
                </StatsCell>
                <StatsCell style={{ minWidth: "100px" }} percentColor={percentColorBasedOnValue(field2)} >
                    {displayValueBasedOnType(field2)}
                </StatsCell>
                <StatsCell style={{ minWidth: "65px" }} percentColor={percentColorBasedOnValue(field3)}>
                    {displayValueBasedOnType(field3)}
                </StatsCell>
                <StatsCell style={{ minWidth: "85px" }} percentColor={percentColorBasedOnValue(field4)}>
                    {displayValueBasedOnType(field4)}
                </StatsCell>
            </RightSide>

        </Container>
    )
}

export default PlayerStatsRow
const Container = styled.div`
display:flex;
gap:10px;
max-height:50px;
width:100%;
`
const LeftSide = styled.div`
display:flex;
align-items:center;
gap:12px;
background:${({ theme }) => theme.playersData.elementLeftBg};
padding:9px 7px;
width:100%;
min-width:820px;
`
const RightSide = styled(LeftSide)`
background:${({ theme }) => theme.playersData.elementRightBg};
min-width:unset;
.greenPercent{
    p {
        color: ${({ theme }) => theme.POSCell.gkColor};
      }
}
.redPercent{
    p {
        color: ${({ theme }) => theme.POSCell.atkColor};
      }
}
.yellowPercent{
    p {
        color: ${({ theme }) => theme.POSCell.neutralColor};
      }
}
.bluePercent{
    p {
        color: ${({ theme }) => theme.POSCell.defColor};
      }
}

`
const StatsCell = styled.div`
display:flex;
align-items:center;
justify-content:center;
min-width:40px;
width:100%;
p{
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: center;
    color:${(props) => props.percentColor ? props.percentColor : ({ theme }) => theme.playersData.color};
  
}

`
const Avatar = styled.div`
display:flex;
align-items:center;
justify-content:center;
min-width:32px;
min-height:32px;
max-height:32px;
max-width:32px;
overflow:hidden;
img{
    object-fit:contain;
height:100%;
width:100%;
}

`
const Number = styled.div`
display:flex;
align-items:center;
justify-content:center;
min-width:24px;
min-height:24px;
border:1px solid rgba(203, 215, 245, 1);
border-radius:4px;
background:rgba(255, 255, 255, 1);
margin-left:8px;
justify-content:center;
p{
font-size: 12px;
font-weight: 700;
line-height: 18px;
text-align: center;
color:rgba(0, 18, 41, 0.7);
}
`
const NameAndPosition = styled(StatsCell)`
align-items:start;
flex-direction:column;
min-width:130px;
max-width:130px;

h1{
    color:${({ theme }) => theme.playersData.color};
    font-family: Roboto;
font-size: 14px;
font-weight: 700;
line-height: 16px;
letter-spacing: 0.01em;
text-align: left;
}
 .green {
    border-color: ${({ theme }) => theme.POSCell.gkBorder};
    background:${({ theme }) => theme.POSCell.gkBg};
    p {
      color: ${({ theme }) => theme.POSCell.gkColor};
    }

  }
  .blue {
    border-color: ${({ theme }) => theme.POSCell.defBorder};
    background:${({ theme }) => theme.POSCell.defBg};
    p {
      color: ${({ theme }) => theme.POSCell.defColor};
    }
  }
  .orange {
    border-color: ${({ theme }) => theme.POSCell.neutralBorder};
    background:${({ theme }) => theme.POSCell.neutralBg};
    p {
      color: ${({ theme }) => theme.POSCell.neutralColor};
    }
  }
  .red {
    border-color: ${({ theme }) => theme.POSCell.atkBorder};
    background:${({ theme }) => theme.POSCell.atkBg};
    p {
      color: ${({ theme }) => theme.POSCell.atkColor};
    }
  }
`
const POSCell = styled.div`
display: flex;
justify-content: center;
align-items:center;
width:36px;
height:18px;
  border: 1px solid #e1e9ff;
  background: white;
  border-radius: 2px;
  p{
    font-family: Roboto;
font-size: 11px;
font-weight: 500;
line-height: 13px;

  }
 
`;