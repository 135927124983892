export const ROLES = {
	admin: "admin",
	oddscompiler: "oddscompiler",
	trader: "trader",
	analyst: "analyst",
};

export const SCOPES = {
	canAudit: "can-audit",
	canEdit: "can-edit",
	canView: "can-view",
	canBet: "can-bet",
	canAnalyze: "can-analyze"
};

export const PERMISSIONS = {
	[ROLES.admin]: [SCOPES.canView, SCOPES.canBet, SCOPES.canEdit, SCOPES.canAudit, SCOPES.canAnalyze],
	[ROLES.trader]: [SCOPES.canBet, SCOPES.canView],
	[ROLES.analyst]: [SCOPES.canAnalyze, SCOPES.canView],
	[ROLES.oddscompiler]: [SCOPES.canView],

};