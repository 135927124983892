import cloneDeep from "lodash/cloneDeep";

export const buildNewStateForBetsPrices = (oldPrices, newPrices) => {
  const oldBetsPrices = cloneDeep(oldPrices);
  const newBetsPrices = cloneDeep(newPrices);

  const oldBetsPricesFixturesIds = oldBetsPrices.map(
    (price) => price.fixture_id
  );

  const newBetsPricesFixturesIds = newBetsPrices.map(
    (price) => price.fixture_id
  );

  const allFixturesIds = oldBetsPricesFixturesIds.concat(
    newBetsPricesFixturesIds
  );

  const uniqueFixtureIds = [...new Set(allFixturesIds)];

  let newListOfPrices = [];

  uniqueFixtureIds.forEach((fixtureID) => {
    const oldFixtureData = oldBetsPrices.find((obj) => {
      return obj.fixture_id === fixtureID;
    });

    const newFixtureData = newBetsPrices.find((obj) => {
      return obj.fixture_id === fixtureID;
    });

    const newElement = buildNewElementFromFixtureData(
      oldFixtureData,
      newFixtureData
    );

    if (newElement) {
      newListOfPrices.push(newElement);
    }
  });

  return newListOfPrices;
};

export const buildNewElementFromFixtureData = (oldData, newData) => {
  // If one of them is null return the other
  if (!oldData) {
    return newData;
  }

  if (!newData) {
    return oldData;
  }

  const oldMarkets = oldData.markets;
  const newMarkets = newData.markets;

  var marketsToReturn = [];

  // Change the old markets if needed
  oldMarkets.forEach((oldMarket) => {
    const newMarketData = newMarkets.find((obj) => {
      return (
        obj.market_id === oldMarket.market_id &&
        obj.bookmaker_id == oldMarket.bookmaker_id
      );
    });

    // Replace the old market with the new one if a newer one exists
    if (newMarketData) {
      marketsToReturn.push(newMarketData);
    } else {
      // Otherwise just keep the old one
      marketsToReturn.push(oldMarket);
    }
  });

  // Add the new market items if needed
  newMarkets.forEach((newMarket) => {
    const oldMarketData = oldMarkets.find((obj) => {
      return (
        obj.market_id === newMarket.market_id &&
        obj.bookmaker_id == newMarket.bookmaker_id
      );
    });

    // If no old market data found, we have a new value, add it to the list
    if (!oldMarketData) {
      marketsToReturn.push(newMarket);
    }
  });

  return { fixture_id: oldData.fixture_id, markets: marketsToReturn };
};
