export const TeamImp = [
    { value: "sk" },
    { value: "cp" },
    { value: "i" },
    { value: "ab" },
    { value: "r" },
    { value: "rot" },
    { value: "b" },
    { value: "res" },
    { value: "yp" },

];

// if change values here change also in PlayerHelper.js