

{
  /*Filter array elements by name*/
}
export const filterElementsByName = (elements, name) => {
  let values = elements.filter((f) => f.name === name);
  return values;
};

{
  /*Find minimum difference between arrays values*/
}
export const findMinDiffFromValues = (elements1, elements2) => {
  const allElements = [...elements1, ...elements2];

  const values = allElements.map((e) => {
    return e.value;
  });

  let minDif = Number.MAX_VALUE;
  let minValue = Number.MIN_VALUE;
  values.forEach((value) => {
    const e1 = elements1.find((element) => element.value === value);
    const e2 = elements2.find((element) => element.value === value);
    if (e1 === null || e1 === undefined || e2 === null || e2 === undefined) {
      return;
    }
    let diff = Math.abs(e1.price - e2.price);
    if (diff < minDif) {
      minDif = diff;
      minValue = value;
    }
  });
  if (minValue === Number.MIN_VALUE) {
    return null;
  }
  const e1 = elements1.find((element) => element.value === minValue);
  const e2 = elements2.find((element) => element.value === minValue);

  return {
    first: e1,
    second: e2,
    value: minValue,
  };
};
{
  /* Return minimum same model min value as minimum market value  */
}
export const findSameMinimumAsMarketMinimum = (elements1, elements2, value) => {
  const allElements = [...elements1, ...elements2];
  allElements = allElements.filter((e) => e.value === value);
};
{
  /*Calculate increase percentage between 2 values*/
}
export const calcPercentage = (v1, v2) => {
  if (v1 === undefined || v2 === undefined) {
    return null;
  }

  let a = v2.price - v1.price;
  let b = (v1.price + v2.price) / 2;
  let percentage = (a / b) * 100;

  return percentage.toFixed(1);
};

export const percentageChange = (n1, n2) => {
  if (!n1 || !n2) {
    return null;
  }

  const a = n2 - n1;
  const b = Math.abs(n1);

  const percentage = (a / b) * 100;

  return percentage.toFixed(1);
};

export const formatNumberForValue = (val) => {
  if (val.length == 0) {
    return "";
  }

  let num = Number(val).toFixed(2);
  if (num > 0) {
    return "+" + num;
  }

  return num;
};

export const sortFixturesByDate = (value) => {
  const results = value;
  const returned = [];
  results.forEach((element) => {
    const newElement = { ...element };
    const fixtures = newElement.fixtures;
    fixtures.sort(function (a, b) {
      return new Date(a.start_time) - new Date(b.start_time);
    });
    newElement[fixtures] = fixtures;

    returned.push(newElement);
  });
  return returned;
};
export const sortFixturesByTierAndDivision = (value) => {
  const results = value;
  const sorted = results.sort((a, b) => {
    if (a.competition.tier === b.competition.tier) {
      return a.competition.division > b.competition.division ? 1 : -1;
    } else {
      return a.competition.tier > b.competition.tier ? 1 : -1;
    }
  });
  return sorted;
};
export const shouldMakeValuesBold = (val1, val2) => {
  if (!val1 || !val2) {
    return false;
  }

  return val2 > val1;
};

export const highlightedExpandedDetailsClass = (highlight) => {
  // if (highlight != null && item != null && highlight === item.value) {
  //   return "yello-strip";
  // }

  if (highlight) {
    return "yello-strip";
  }

  return "";
};

export const findMinPriceAndBookMakers = (prices, marketId) => {
  let calculatedObject = {
    min_price: Number.MAX_VALUE,
    bookmakers: [],
  };

  let filterMarketPrices = prices.filter((marketPrice) => {
    return marketPrice.market_id == marketId;
  });

  if (filterMarketPrices !== null && filterMarketPrices.length > 0) {
    filterMarketPrices.forEach((price) => {
      calculatedObject.bookmakers.push(price.bookmaker_name);
      if (price.price < calculatedObject.min_price) {
        calculatedObject.min_price = price.price;
      }
    });
    return calculatedObject;
  }
  return {
    min_price: null,
    bookmakers: [],
  };
};

export const findBookMakers = (prices, marketId) => {
  let filterMarketPrices = prices.filter((marketPrice) => {
    return marketPrice.market_id == marketId;
  });

  const bookmakers = filterMarketPrices.map((price) => price.bookmaker_name);
  return bookmakers;
};

export const get_betting_price_for_logged_bets = (prices, marketId) => {
  if (prices == null || prices == undefined) {
    return null;
  }

  let filterMarketPrices = prices.filter((marketPrice) => {
    return marketPrice.market_id == marketId;
  });

  const SING_BOOKMAKER_ID = 282;
  const BETISN_BOOKMAKER_ID = 571;
  const IBCBET_BOOKMAKER_ID = 285;

  const sing = filterMarketPrices.find(
    (element) => element.bookmaker_id === SING_BOOKMAKER_ID
  );
  if (sing != null && sing != undefined) {
    return sing.price;
  }

  const betisn = filterMarketPrices.find(
    (element) => element.bookmaker_id === BETISN_BOOKMAKER_ID
  );
  if (betisn != null && betisn != undefined) {
    return betisn.price;
  }

  const ibcbet = filterMarketPrices.find(
    (element) => element.bookmaker_id === IBCBET_BOOKMAKER_ID
  );
  if (ibcbet != null && ibcbet != undefined) {
    return ibcbet.price;
  }

  return null;
};

export const get_singbet_price = (prices, marketId) => {
  if (prices == null || prices == undefined) {
    return null;
  }

  let filterMarketPrices = prices.filter((marketPrice) => {
    return marketPrice.market_id == marketId;
  });

  const SING_BOOKMAKER_ID = 282;

  const sing = filterMarketPrices.find(
    (element) => element.bookmaker_id === SING_BOOKMAKER_ID
  );

  if (sing != null && sing != undefined) {
    return sing.price;
  }

  return null;
};

export const sortLoggedBetsByCreatedDate = (loggedBets) => {
  let oldBets = [...loggedBets];
  const sorted = oldBets.sort((a, b) =>
    a.logged_bet.created_at < b.logged_bet.created_at ? 1 : -1
  );
  return sorted;
};
