import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownSvg } from "../../../assets/icons/downSimpleArrow.svg";

const MatchRtgMenu = ({
  valuesList,
  selectedValue,
  setSelectedValue,
  width,
}) => {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  const handleClick = (item) => {
    setSelectedValue(item);
    handleMenuOpen();
  };

  const mapRatingValue = (value) => {
    if (value === null) {
      return "N/A";
    } else {
      return value;
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref} width={width}>
      <DropDownMenu
        onClick={() => handleMenuOpen()}
        isMenuOpen={isMenuOpen}
        width={width}
      >
        {/* <DropDownMenu onClick={() => alert("IMPLEMENT")} isMenuOpen={isMenuOpen}> */}
        <p>{mapRatingValue(selectedValue)}</p>
        {isMenuOpen ? <DownSvg style={{ rotate: "180deg" }} /> : <DownSvg />}
      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>
          {valuesList?.map((item, index) => (
            <DropDownMenuItem
              key={index}
              onClick={() => handleClick(item.value)}
              className={item === selectedValue ? "active" : "nonActive"}
            >
              <input
                key={index}
                readOnly
                onChange={() => handleClick(item.value)}
                className="check"
                type="radio"
                checked={selectedValue == item.value}
                value={item.value}
              ></input>
              <p>{mapRatingValue(item.value)}</p>
            </DropDownMenuItem>
          ))}
        </DropDownList>
      )}
    </Container>
  );
};

export default MatchRtgMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width != null ? props.width : "63px")};
  position: relative;
  cursor: pointer;
`;
const DropDownMenu = styled.div`
  position: relative;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 2px 5px;
  border: 1px solid #cbd7f5;
  border-radius: 2px;
  border-radius: ${(props) => (props.isMenuOpen ? "4px 4px 0 0" : "")};
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 150%;
    /* or 15px */
    display: flex;
    align-items: center;
    letter-spacing: 0.2em;
    color:rgba(0, 18, 41, 0.8);
  }
`;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top: 19px;
  border-left: 1px solid;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-color: #cbd7f5;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 4px 4px;
  max-height: 400px;
  width: 100%;
  overflow-x: hidden;
  
`;
const DropDownMenuItem = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 2px;
  .check {
    height: 10px;
  }
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  :first-child {
    border-top: 1px solid black;
  }
  list-style-type: none;
  background-color: #ffffff;
  padding: 2px 3px;
  p {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    color: rgba(0, 18, 41, 0.8);
  }
`;
