import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";

const EditPlayerConfirmation = ({ handleEdit, data, initialData, variant }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        if (data?.wy_id !== initialData?.wy_id) {
            setOpen(true);
        } else {
            handleEdit();
        }

    };

    const handleClose = () => {
        setOpen(false);
    };

    const edit = (e) => {
        e.preventDefault();
        handleEdit();
        setOpen(false);
    };

    return (
        <div>
            {variant == "all" ? (
                <SaveAllButton onClick={() => handleClickOpen()}>Save all</SaveAllButton>
            )
                : (<SaveButton onClick={() => handleClickOpen()}>Save</SaveButton>)}



            < Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Warning"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ids of the players have changed, are you sure you want to save those changes?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()}>No</Button>
                    <Button style={{ color: "red" }} onClick={(e) => edit(e)} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default EditPlayerConfirmation;

const DeleteButton = styled.button`
  background:none;
  border:none;
  cursor:pointer;
  width:40px;
  height:40px;

  svg{
    width:40px;
    height:40px;
  }
`;
const SaveButton = styled.button`
cursor: pointer;
  background: #006fff;
  border: 1.2px solid #006fff;
  border-radius: 6px;
  color: white;
  font-family: "Roboto";

  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding: 0 35px;
  height: 30px;
`;

const SaveAllButton = styled.button`
background: #ffffff;
border: 1.5px solid #006fff;
border-radius: 4px;
padding: 6px 25px;
font-family: "Roboto";
font-weight: 600;
font-size: 14px;
line-height: 16px;
color: #006fff;
cursor: pointer;
`;