import React from "react";
import styled from "styled-components";
import { ReactComponent as CheckSvg } from "../../assets/icons/check/check.svg";
import { ReactComponent as PlusSvg } from "../../assets/icons/check/plus.svg";

const LeaguesList = ({ country, competitions, selectedCompetitions, setSelectedCompetitions, }) => {


  const handleCheck = (event, item) => {
    let itemToSend = null;
    if (item.country === null || item.country === undefined) {
      var newItem = {
        ...item,
        country: country
      };
      itemToSend = newItem;
    }
    else {
      itemToSend = item;
    }
    var updatedList = [...selectedCompetitions];

    if (event.target.checked) {
      updatedList = [...selectedCompetitions, itemToSend];


    } else {
      let index = updatedList.indexOf(updatedList.find(item => item.competition_id === itemToSend.competition_id));
      updatedList.splice(index, 1);


    }
    setSelectedCompetitions(updatedList);

  };

  const handleFlag = (item) => {

    if (item.country != null && item.country != undefined && item.country.country_code != null) {
      return (`https://flagcdn.com/${item.country.country_code.toLowerCase()}.svg`);
    }
    else {
      if (country != null && country.country_code != null) {
        return (`https://flagcdn.com/${country.country_code.toLowerCase()}.svg`);
      }
      else {

        return ("");
      }
    }
  };

  return (
    <Container>
      <ul>
        {competitions.map((item, index) => (
          <LeagueItem key={index} draggable>
            <div>
              <img src={handleFlag(item)} alt=""></img>
              <p>{item.competition}</p>
            </div>
            <div>
              <label className="checkContainer">
                <input
                  key={index}
                  checked={selectedCompetitions.some(element => element.competition_id === item.competition_id)}
                  value={item}
                  onChange={(e) => handleCheck(e, item)}
                  type="checkbox"
                ></input>
                {selectedCompetitions.some(element => element.competition_id === item.competition_id) ? <CheckSvg /> : <PlusSvg />}

                <span className="checkmark"></span>
              </label>
            </div>
          </LeagueItem>
        ))}
      </ul>
    </Container>
  );
};

export default LeaguesList;
const Container = styled.div`
  position:relative;
  list-style-type: none;

  max-height:300px;
  overflow:auto;

`;
const LeagueItem = styled.li`
  padding: 5px 15px;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
width:256px;
  div {
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
    }
    img {
      height: 14px;
      width: 18px;
      border-radius: 4px;
      fill:${({ theme }) => theme.groupPlayersModal.checkFill};
    }
    svg {
      height: 14px;
      width: 18px;
      border-radius: 4px;
      fill:${({ theme }) => theme.groupPlayersModal.checkFill};
    }
    align-items: center;
    display: flex;
    column-gap: 10px;
    color: rgba(0, 18, 41, 1);
  }
  .checkContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .checkContainer input {
    display: none;
  }
  .checkContainer input:checked ~ svg {
    background: #ebf3ff;
    fill:var(--primaryBlue);
    border: 1px solid var(--primaryBlue);
  }
  .checkContainer svg {
    padding: 2px 3px;
    border: 1px solid ${({ theme }) => theme.groupPlayersModal.checkFill};
    border-radius: 2px;
    background: transparent;

    fill:${({ theme }) => theme.groupPlayersModal.checkFill};
  }
`;
