import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectUser } from "../../Redux/user/user";
import EditBtn from "./EditBtn";
import RoleCell from "./Table/RoleCell";
import TableBody from "./Table/TableBody";
import Tablehead from "./Table/Tablehead";
import TRow from "./Table/TRow";

const UserProfileTable = (props) => {
	const user = useSelector(selectUser);

	const parseYesNo = (value) => {
		if (value == true) {
			return "Yes";
		}
		if (value == false) {
			return "No";
		}
	};
	const mapValues = (value) => {
		let display = "First name, last name"

		if (value == "fullName") {
			display = "First name, last name"
		}
		if (value == "shortName") {
			display = "Short names"
		}
		if (value == "otherName") {
			display = "Other name"
		}

		return display
	}



	return (
		<Container>
			<table cellSpacing={0}>
				<Tablehead>
					<th className="mw1">Name</th>
					<th className="mw2">Email</th>
					<th className="mw3">Time Zone</th>
					<th className="links">Open links in new tabs</th>
					<th className="links">Players name</th>
					<th className="roles">Roles</th>
					<th className="btnmw"> </th>
				</Tablehead>
				<TableBody>
					<TRow>
						<td className="mw1">{user.name}</td>
						<td className="mw2">{user.email}</td>
						<td className="mw3">{user.timezone_name}</td>
						<td className="links">{parseYesNo(user.open_links_in_new_tabs)}</td>
						<td className="links">{mapValues(props.displayPlayerName)}</td>
						<RoleCell roleType={user.roles[0]} />

						<td className="btnmw">
							<EditBtn setShowEditUser={props.setShowEditUser} />
						</td>
					</TRow>
				</TableBody>
			</table>
		</Container>
	);
};

export default UserProfileTable;
const Container = styled.div`
  overflow: auto;
  border: 2px solid #f8f9fb;
  box-shadow: 0px 1px 8px rgba(46, 46, 46, 0.04);

  .mw1 {
	width:250px;
	overflow:hidden;
    text-overflow: ellipsis;
  }
  .mw2 {
	width:350px;
    min-width:350px;
    max-width:350px;
	overflow:hidden;
    text-overflow: ellipsis;
  }
  .mw3 {
    min-width: 160px;
  }
  .links {
    min-width: 200px;
	text-align:center;
  }
  .roles{
	padding-left:38px;
    min-width: 170px;
    max-width:300px;
  }
  .btnmw{
	width:157px;
	padding:18px;
	justify-content:start!important;
  }
  
  table {
    width: 100%;
  }
`;
