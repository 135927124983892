import { PATCH } from "../../APiHelper/API"


export const addPenaltyTakers = (team_id, first_penalty_taker_id, second_penalty_taker_id, third_penalty_taker_id, onSuccess, onError) => {
    const data = {
        first_penalty_taker_id: first_penalty_taker_id,
        second_penalty_taker_id: second_penalty_taker_id,
        third_penalty_taker_id: third_penalty_taker_id,

    }
    PATCH(`/api/v1/leagues/team/${team_id}/penalty_takers`, data, onSuccess, onError)
}