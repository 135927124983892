import { PATCH } from "../APiHelper/API";

export const updateUser = (
  userId,
  all_competitions,
  all_seasons,
  all_cups,
  competitions_ids,
  season_ids,
  cup_ids,
  role,
  onSucces,
  onError
) => {
  const data = {
    all_competitions: all_competitions,
    all_seasons: all_seasons,
    all_cups: all_cups,
    competition_ids: competitions_ids,
    season_ids: season_ids,
    cup_ids: cup_ids,
    role: role,
  };

  PATCH(`/api/v1/users/${userId}/update`, data, onSucces, onError);
};
