import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightTheme } from "../../../theme/theme";
import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";


const CupsForTeamMenu = ({ valuesList, value, setValue }) => {
    const ref = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);



    const handleMenuOpen = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        } else {
            setIsMenuOpen(true);
        }
    };

    const handleSetValue = (newValue) => {
        let found = value.find((element) => element === newValue)
        if (!found) {
            setValue((prev) => {
                return [...prev, newValue]
            })
        } else {
            let newData = value.filter((element) => element != newValue)
            setValue(newData)
        }
    }

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    return (
        <Container ref={ref}>
            <DropDownMenu onClick={() => handleMenuOpen()} isMenuOpen={isMenuOpen}>
                <p>Cups</p>
                {isMenuOpen ? <UpSvg /> : <DownSvg />}
            </DropDownMenu>
            {isMenuOpen && (
                <DropDownList>
                    {valuesList?.map((item, index) => (
                        <DropDownMenuItem key={item.id} onClick={() => handleSetValue(item.id)}>
                            <input
                                type="checkbox"
                                readOnly
                                checked={value.includes(item.id)}
                            >
                            </input>
                            <p>{item.name}</p>
                        </DropDownMenuItem>





                    ))}
                </DropDownList>
            )}
        </Container>
    );
};


export default CupsForTeamMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width:150px;
  position: relative;

  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: ${({ theme }) => theme.dropDownColor};
  }
`;
const DropDownMenu = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.menuBgColor};
    padding: 4px 10px;
    border-radius: 8px;
    border-radius: ${(props) => props.isMenuOpen ? "8px 8px 0 0" : ""} ;
    min-height:28px;
    p{
      margin-right:5px;
      width:100%;
      overflow:hidden;
      text-overflow:elipsis;
      white-space:nowrap;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 20px;
      color: ${({ theme }) => theme.dropDownColor};
    }
    svg {
      cursor: pointer;
      fill: ${({ theme }) => theme.dropDownSvgFill};
      stroke: ${({ theme }) => theme.dropDownSvgStroke};
    }
  `;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;   
  top: 28px;
  border-top: 2px solid;
  border-color: ${({ theme }) => (theme === lightTheme ? "#3C4A5C" : "white")};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  max-height: 400px;
  width:100%;
  overflow-x: hidden;
  background: ${({ theme }) => theme.menuBgColor};
  padding:10px;


`;
const DropDownMenuItem = styled.li`
display:flex;
column-gap:5px;
align-items:center;
cursor:pointer;
input{
    height:10px;
}
`;
const CategoryItem = styled.div`
display:flex;
column-gap:5px;
align-items:center;
height:15px;
cursor:pointer;
p{
    font-family: Roboto;
font-size: 10px;
font-weight: 600;
line-height: 12px;
text-align: left;

}
svg{
    color: ${({ theme }) => theme.dropDownSvgStroke};
}
`