import React from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import TimeZoneDropdownMenu from "./TimeZoneDropdownMenu";
import OpenInNewTabMenu from "./DropDown/OpenInNewTabMenu";
import { PlayerNameOptions, YesNo } from "../../helper/Values/TeamFormation";
import PlayerNameDisplayMenu from "./DropDown/DropdownPlayerNameDisplay";
const EditUserModal = (props) => {
  return (
    <Container>
      <Card>
        <div className="top">
          <h2>Edit user details</h2>
          <div onClick={props.setShowEditUser}>
            <CloseIcon style={{ cursor: "pointer", fontWeight: "500" }} />
          </div>
        </div>
        <div className="mid">
          <label>Name</label>
          <input
            maxLength={50}
            type={"text"}
            value={props.inputUser}
            onChange={props.handleInputUserChange}
          ></input>
          <label>Time Zone</label>
          <TimeZoneDropdownMenu
            value={props.timeZone}
            handleTimeZoneChange={props.handleTimeZoneChange}
          />
          <label>Open in new tab</label>
          <OpenInNewTabMenu
            valuesList={YesNo}
            value={props.openLinksInNewTabs}
            setValue={props.setOpenLinksInNewTabs}
          />
          <label>Player name display</label>
          <PlayerNameDisplayMenu
            valuesList={PlayerNameOptions}
            value={props.displayPlayerName}
            handleNameOptionsMenu={props.handleNameOptionsMenu}
          />
        </div>
        <Bottom>
          <button
            disabled={!props.changes}
            className="saveChanges"
            onClick={props.saveClick}
          >
            Save changes
          </button>
        </Bottom>
      </Card>
    </Container>
  );
};

export default EditUserModal;
const Container = styled.div`
  z-index: 2;
  display: flex;
  align-items: baseline;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(119, 124, 134, 0.5);
`;
const Card = styled.div`
  width: 616px;
  display: flex;
  position: sticky;
  top: 5%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 1px 8px rgba(46, 46, 46, 0.04);

  .top {
    padding: 12px 23px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    background: var(--gradient1);
    display: flex;
    justify-content: space-between;
    h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
    }
  }
  .mid {
    display: flex;
    flex-direction: column;
    padding: 25px 23px;
    row-gap: 10px;
    margin-bottom: 15px;
    label {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: var(--darkBlue);
    }
    input {
      border: 1px solid #d9dfe7;
      border-radius: 4px;
      padding: 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: var(--darkBlue);
      :focus-visible {
        outline: 0px;
        border: 1px solid var(--primaryBlue);
      }
    }
  }
`;
const Bottom = styled.div`
  background: var(--gradient1);
  display: flex;
  padding: 12px 23px;
  justify-content: end;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  button {
    padding: 12px 27px;
    border-radius: 4px;
    border: none;
    background-color: var(--primaryBlue);
    color: white;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    :disabled {
      opacity: 0.5;
      cursor: inherit;
    }
  }
`;
