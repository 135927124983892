import { POST } from "../../APiHelper/API";

export const archiveSeasonAPI = (
    id,
    archived,
    onSuccess,
    onError
) => {
    const data = {
        id: id,
        archived: archived
    }
    POST("/api/v1/leagues/seasons/archive", data, onSuccess, onError)
}