import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as SearchSvg } from "../../../assets/icons/search.svg";
import SeasonMenuForNewEvent from "./SeasonMenuForNewEvent";
import SelectedTeams from "./SelectedTeamsList";
import { useLeaguesDataService } from "../../../context/useLeaguesDataService";
import { notifyError } from "../../../helper/Generic/Notifications";
import SearchCompetitionMenu from "./SearchCompetitionMenu";
import { FullScreenSpinner } from "../../genericComponents/FullScreenSpinner";
import TeamsList from "./TeamsList";
import { cloneDeep } from "lodash";
const AddNewCupCard = ({
  setIsAddNewSeasonOpen,
  setSeasonsAndCupsList,
  setSelectedSeasonsAndCups,
}) => {
  const initialInputValues = {
    cupName: "",
    teamNameSearch: "",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputvalues] = useState(initialInputValues);
  const [competitionSearchTerm, setCompetitionSearchTerm] = useState("");

  const inputLogoRef = useRef();
  const shouldSearch = useRef();

  const [ready, setReady] = useState(false);
  const [previewFile, setPreviewFile] = useState();
  const [logo, setLogo] = useState();

  const [selectedSeason, setSelectedSeason] = useState();
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState(null);

  const [competitionsList, setCompetitionsList] = useState([]);
  const [seasonsList, setSeasonsList] = useState([]);

  const {
    get_seasons,
    get_cup_competitions,
    search_teams,
    teamsList,
    create_new_cup,
    setTeamsList,
  } = useLeaguesDataService();

  const handleUploadBtnClick = () => {
    inputLogoRef.current.click();
  };

  const handleFileInputChange = (e) => {
    setLogo(e.target.files[0]);
    setPreviewFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputvalues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleCompetitionSearch = (onSuccess = null) => {
    setIsLoading(true);
    get_cup_competitions(
      competitionSearchTerm,
      function (response) {
        setIsLoading(false);
        let sorted = response.data.competitions.sort((a, b) => a.country.name > b.country.name ? 1 : -1)
        setCompetitionsList(sorted);
        if (onSuccess) {
          onSuccess();
        }

      },
      function (error) {
        console.log(error);
        notifyError("Something wrong");
      }
    );
  };

  const handleSelectSeason = (season) => {
    setSelectedSeason(season);
    setInputvalues((prev) => {
      const newData = {
        ...prev,
        teamNameSearch: "",
      };
      return newData;
    });
    handleTeamSearch("", season);
  };

  const handleTeamSearch = (term, seasonId) => {
    setIsLoading(true);
    search_teams(
      term,
      seasonId,
      function (response) {
        setIsLoading(false);
      },
      function (error) {
        setIsLoading(false);
        console.log(error);
        notifyError("Something wrong");
      }
    );
  };

  const handleGetSeasons = () => {
    const data = new FormData();
    data.append("term", inputValues.teamNameSearch);
    get_seasons(
      data,
      function (response) {
        let filtered = response.filter((item) => item.archived !== true)
        let sorted = filtered.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
        setSeasonsList(sorted);
      },
      function (error) {
        console.log(error);
        notifyError("Something wrong");
      }
    );
  };

  const checkDuplicateTems = () => {
    let duplicate = false;
    selectedTeams.map((team) => {
      let found = selectedTeams.filter((filteredTeam) => filteredTeam.participant_id === team.participant_id)
      if (found && found.length > 1) {
        duplicate = true
      }
    })
    return duplicate
  }

  const checkReady = () => {
    if (
      inputValues.cupName.length < 3 ||
      selectedTeams.length === 0 ||
      !logo ||
      selectedCompetition == null ||
      checkDuplicateTems()
    ) {
      setReady(false);
    } else {
      setReady(true);
    }
  };

  const handleAddNewCup = () => {
    setIsLoading(true);
    let teamIds = selectedTeams.map((item) => {
      return item.id;
    });

    const dataObj = new FormData();
    dataObj.append("name", inputValues.cupName);
    dataObj.append("team_ids", JSON.stringify(teamIds));
    dataObj.append("competition_id", selectedCompetition.competition_id);
    dataObj.append("image", logo);

    create_new_cup(
      dataObj,
      function (response) {
        let newCup = response.data.cup;

        setSelectedSeasonsAndCups((prev) => {
          return {
            ...prev,
            cups: [...prev.cups, newCup.id],
          };
        });

        setSeasonsAndCupsList((prev) => {
          return {
            ...prev,
            cups: [newCup, ...prev.cups],
          };
        });
        setIsLoading(false);
        setTeamsList([]);
        setIsAddNewSeasonOpen(false);
      },
      function (error) {
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  useEffect(() => {
    handleGetSeasons();
  }, []);

  useEffect(() => {
    checkReady();
  }, [inputValues.cupName, selectedCompetition, selectedTeams, logo]);

  useEffect(() => {
    let time = setTimeout(() => {
      if (inputValues.teamNameSearch !== "") {
        setSelectedSeason(null);
        setTeamsList([]);
        handleTeamSearch(inputValues.teamNameSearch, null);
      } else {
        setTeamsList([]);
        setSelectedSeason(null);
      }
    }, 300);
    return () => {
      clearTimeout(time);
    };
  }, [inputValues.teamNameSearch]);

  useEffect(() => {
    let time = setTimeout(() => {
      if (competitionSearchTerm !== "") {
        if (shouldSearch.current) {
          handleCompetitionSearch();
        }
      }
    }, 300);
    return () => {
      clearTimeout(time);
    };
  }, [competitionSearchTerm]);

  return (
    <Card>
      {/* <Wrapper> */}
      {isLoading && <FullScreenSpinner loading={isLoading} />}
      <div style={{ height: "100%", overflow: "auto", position: "relative", display: "flex", flexDirection: "column", }}>
        <Top>
          <Content>
            <GroupContainer>
              <h3> NAME</h3>
              <ElementInput
                placeholder="Enter cup name"
                name="cupName"
                onChange={(e) => handleInputChange(e)}
                value={inputValues.cupName}
              />
            </GroupContainer>
            <GroupContainer>
              <h3>COMPETITION</h3>
              <SearchCompetitionMenu
                value={selectedCompetition}
                setValue={setSelectedCompetition}
                valuesList={competitionsList}
                term={competitionSearchTerm}
                setTerm={setCompetitionSearchTerm}
                handleSearch={handleCompetitionSearch}
                shouldSearch={shouldSearch}
              />
            </GroupContainer>
            <GroupContainer>
              <h2>Search by team name</h2>
              <SearchGroup>
                <SearchIconWrap>
                  <SearchSvg />
                </SearchIconWrap>
                <SearchInput
                  placeholder="Search"
                  name="teamNameSearch"
                  onChange={(e) => handleInputChange(e)}
                  value={inputValues.teamNameSearch}
                />
                <SearchButton>Search</SearchButton>
              </SearchGroup>
            </GroupContainer>
          </Content>
          <LogoContent>
            <GroupContainer>
              <h3>Logo</h3>
              <LogoInputWrapper>
                <input
                  type="file"
                  hidden
                  ref={inputLogoRef}
                  onChange={(e) => handleFileInputChange(e)}
                />
                <LogoWrapper>
                  {previewFile ? (
                    <img src={previewFile} alt="prev" />
                  ) : (
                    <p>Upload logo</p>
                  )}
                </LogoWrapper>
                <UploadBtn onClick={() => handleUploadBtnClick()}>
                  {logo ? "Replace logo" : "Upload"}
                </UploadBtn>
              </LogoInputWrapper>
            </GroupContainer>
          </LogoContent>
        </Top>

        <CompetitionsList>
          <Left>
            <GroupContainer>
              <h2>Choose teams from a specific season</h2>
              <SeasonMenuForNewEvent
                dataList={seasonsList}
                value={selectedSeason}
                setValue={handleSelectSeason}
              />
            </GroupContainer>

            <TeamsList
              teamsList={teamsList}
              selectedTeams={selectedTeams}
              setSelectedTeams={setSelectedTeams}
              allTeams={selectedTeams}

            />
          </Left>

          <Right>
            {selectedTeams?.length > 0 && (
              <GroupContainer>
                <h4>Selected Teams</h4>
                <SelectedTeams
                  value={selectedTeams}
                  setValue={setSelectedTeams}
                />
              </GroupContainer>
            )}
          </Right>
        </CompetitionsList>
      </div>
      <Bottom>
        <button disabled={!ready} onClick={() => handleAddNewCup()}>
          Add cup
        </button>
      </Bottom>

      {/* </Wrapper> */}
    </Card>
  );
};

export default AddNewCupCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
`;
const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding: 0 40px;
  height:100%;
`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
  h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */
    color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
`;
const ElementInput = styled.input`
  height: 40px;
  background: ${({ theme }) => theme.editLeague.readOnlyInputs};
  width: 280px;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  padding: 10px 15px;
`;
const Bottom = styled.div`
  background: ${({ theme }) => theme.editLeague.headerBg};
  padding: 10px 40px;
  border-radius: 0 0 16px 16px;
  display: flex;
  justify-content: end;
  z-index: 2;
  button {
    background-color: var(--primaryBlue);
    color: white;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 27px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    :disabled {
      opacity: 0.5;
    }
  }
`;

const CompetitionsList = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 20px 40px 0 40px;
  overflow: hidden;
min-height:350px;
`;
const Left = styled.div`
  display: flex;
  min-width: 280px;
  border-right: 1.5px solid #eef2f6;
  flex-direction: column;
  row-gap: 20px;
  padding-right: 25px;
  height:100%;
  position:relative;
`;

const Right = styled.div`
  padding: 20px 0 10px 15px;
  width: 100%;
  border-left: 1.5px solid #eef2f6;
  display: flex;
`;

const SearchGroup = styled.div`
  display: flex;
  gap: 5px;
  position: relative;
`;
const SearchInput = styled.input`
  padding: 10px 30px;
  border-radius: 4px;
  border: 1px solid #d9dfe7;
  width: 280px;
  background: ${({ theme }) => theme.editLeague.readOnlyInputs};
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
`;
const SearchButton = styled.button`
  padding: 0 30px;
  border-radius: 4px;
  border: 1px solid #006fff;
  background: ${({ theme }) => theme.editLeague.saveAllBtnBg};
  color: ${({ theme }) => theme.editLeague.buttonWithConfirmationTextColor};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
`;
const SearchIconWrap = styled.div`
  position: absolute;
  top: 12px;
  left: 10px;
`;
const Top = styled.div`
  display: flex;
  gap: 30px;
`;
const LogoInputWrapper = styled.div`
  border-radius: 4px;
  border: 1px dashed gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 25px 30px;
`;
const LogoContent = styled.div``;
const UploadBtn = styled.button`
  width: 100px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #006fff;
  background: ${({ theme }) => theme.editLeague.saveAllBtnBg};
  color: ${({ theme }) => theme.editLeague.saveAllBtnColor};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
`;
const LogoWrapper = styled.div`
  height: 100%;
  width: 100%;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 55px;
    width: 55px;
  }
  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #2e2e2e66;
  }
`;
