import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PositionMenu from "../../squadManagerComponents/Menu/PositionMenu";
import { ReactComponent as RemoveBinSvg } from "../../../assets/icons/LeaguesIcons/removeBin.svg";
import DeleteConfirmation from "../../DeleteConfirmation";
import { Positions } from "../../../helper/positionsList";
import PermissionsGate from "../../../helper/Generic/PermissionGate";
import { SCOPES } from "../../../helper/Generic/Permissions";
import EditPlayerConfirmation from "../../EditPlayerConfirmation";
import ButtonWithConfirmation from "../../ButtonWithConfirmation";

const PlayerElement = ({
  data,
  initialData,
  handleEditPlayer,
  handleRemovePlayer,
  handleFieldChange,
  handleTransferPlayer,
}) => {
  const [wyId, setWyId] = useState(data.wy_id);
  const [firstName, setFirstName] = useState(data.first_name);
  const [lastName, setLastName] = useState(data.last_name);
  const [shortName, setShortName] = useState(data.short_name)
  const [otherName, setOtherName] = useState(data.other_player_name)
  const [number, setNumber] = useState(data.squad_number);
  const [position1, setPosition1] = useState(data.position_1);
  const [position2, setPosition2] = useState(data.position_2);
  const [position3, setPosition3] = useState(data.position_3);

  const [readyToSave, setreadyToSave] = useState(false);

  const handleConfirmEdit = () => {
    handleEditPlayer(
      data.id,
      wyId,
      firstName,
      lastName,
      shortName,
      otherName,
      position1,
      position2,
      position3,
      number,
      () => {
        setreadyToSave(false);
      }
    );
  };

  // const setInitialValues = () => {
  //   setWyId(initialData.wy_id)
  //   setFirstName(initialData.first_name)
  //   setLastName(initialData.last_name)
  //   setNumber(initialData.squad_number)
  //   setPosition1(initialData.position_1)
  //   setPosition2(initialData.position_2)
  //   setPosition3(initialData.position_3)
  // }


  const checkSaveReady = () => {
    let fNameChanged = false;
    let lNameChange = false;
    let sNameChange = false;
    let oNameChange = false;
    let numberChanged = false;
    let wyIdChanged = false;
    let pos1Changed = false;
    let pos2Changed = false;
    let pos3Changed = false;

    if (initialData.position_1 != position1) {
      pos1Changed = true;
    } else {
      pos1Changed = false;
    }

    if (initialData.position_2 != position2) {
      pos2Changed = true;
    } else {
      pos2Changed = false;
    }

    if (initialData.position_3 != position3) {
      pos3Changed = true;
    } else {
      pos3Changed = false;
    }

    if (wyId) {
      if (wyId != initialData.wy_id) {
        wyIdChanged = true;
      } else {
        wyIdChanged = false;
      }
    }

    if (firstName != initialData.first_name) {
      fNameChanged = true;
    } else {
      fNameChanged = false;
    }

    if (lastName != initialData.last_name) {
      lNameChange = true;
    } else {
      lNameChange = false;
    }
    if (shortName != initialData.short_name) {
      sNameChange = true;
    } else {
      sNameChange = false;
    }

    if (otherName != initialData.other_player_name) {
      oNameChange = true;
    } else {
      oNameChange = false;
    }

    if (number) {
      if (number != initialData.squad_number) {
        numberChanged = true;
      } else {
        numberChanged = false;
      }
    }

    if (
      fNameChanged ||
      lNameChange ||
      sNameChange ||
      oNameChange ||
      numberChanged ||
      wyIdChanged ||
      pos1Changed ||
      pos2Changed ||
      pos3Changed
    ) {
      if (wyId != initialData.wy_id) {
        handleFieldChange(data.id, "wyId_details_changed", true);
      }
      handleFieldChange(data.id, "details_changed", true);
      setreadyToSave(true);
    } else {
      handleFieldChange(data.id, "details_changed", false);
      if (wyId != initialData.wy_id) {
        handleFieldChange(data.id, "wyId_details_changed", false);
      }
      handleFieldChange(data.id, "details_changed", false);
      setreadyToSave(false);
    }
  };

  const handleChangeWyId = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setWyId(value);
  };

  useEffect(() => {
    checkSaveReady();
  }, [
    initialData,
    firstName,
    lastName,
    shortName,
    otherName,
    number,
    wyId,
    position1,
    position2,
    position3,
  ]);

  useEffect(() => {
    handleFieldChange(data.id, "wy_id", wyId);
  }, [wyId]);

  useEffect(() => {
    handleFieldChange(data.id, "first_name", firstName);
  }, [firstName]);

  useEffect(() => {
    handleFieldChange(data.id, "last_name", lastName);
  }, [lastName]);

  useEffect(() => {
    handleFieldChange(data.id, "short_name", shortName);
  }, [shortName]);

  useEffect(() => {
    handleFieldChange(data.id, "other_player_name", otherName);
  }, [otherName]);

  useEffect(() => {
    handleFieldChange(data.id, "squad_number", number);
  }, [number]);

  useEffect(() => {
    handleFieldChange(data.id, "position_1", position1);
  }, [position1]);

  useEffect(() => {
    handleFieldChange(data.id, "position_2", position2);
  }, [position2]);

  useEffect(() => {
    handleFieldChange(data.id, "position_3", position3);
  }, [position3]);

  useEffect(() => {
    setreadyToSave(data.details_changed);
  }, [data]);

  return (
    <Container>
      <PermissionsGate
        errorProps={{ disabled: true }}
        scopes={[SCOPES.canEdit]}
      >
        <Input
          disabled={data.transferred}
          valueChanged={data.wy_id_changed}
          transferred={data.transferred}
          style={{ width: "80px", minWidth: "80px" }}
          placeholder={"wyId"}
          value={wyId}
          onChange={(e) => handleChangeWyId(e)}
        ></Input>
      </PermissionsGate>

      <Input
        disabled={data.transferred}
        valueChanged={data.first_name_changed}
        transferred={data.transferred}
        style={{ width: "110px", minWidth: "110px" }}
        placeholder={firstName}
        value={firstName}
        onChange={(e) => setFirstName(e.currentTarget.value)}
      ></Input>

      <Input
        disabled={data.transferred}
        valueChanged={data.last_name_changed}
        transferred={data.transferred}
        style={{ width: "110px", minWidth: "110px" }}
        placeholder={lastName}
        value={lastName}
        onChange={(e) => setLastName(e.currentTarget.value)}
      ></Input>
      <Input
        disabled={data.transferred}
        valueChanged={data.short_name_changed}
        transferred={data.transferred}
        style={{ width: "110px", minWidth: "110px" }}
        placeholder={shortName}
        value={shortName}
        onChange={(e) => setShortName(e.currentTarget.value)}
      ></Input>
      <Input
        disabled={data.transferred}
        valueChanged={data.other_player_name_changed}
        transferred={data.transferred}
        style={{ width: "100px", minWidth: "110px" }}
        placeholder={otherName}
        value={otherName}
        onChange={(e) => setOtherName(e.currentTarget.value)}
      ></Input>
      <Input
        disabled={data.transferred}
        transferred={data.transferred}
        style={{ width: "56px", minWidth: "56px" }}
        placeholder={number}
        value={number}
        onChange={(e) => setNumber(e.currentTarget.value)}
      ></Input>

      <PositionMenu
        transferred={data.transferred}
        valuesList={Positions}
        value={position1}
        setValue={setPosition1}
      />
      <PositionMenu
        transferred={data.transferred}
        valuesList={Positions}
        value={position2}
        setValue={setPosition2}
      />
      <PositionMenu
        transferred={data.transferred}
        valuesList={Positions}
        value={position3}
        setValue={setPosition3}
      />


      <DeleteConfirmation
        handleRemoveItem={handleRemovePlayer}
        item={data.id}
      />
      <ButtonWithConfirmation
        variant={"transfer"}
        action={handleTransferPlayer}
        item={data.id}
        disableButton={data.transferred}
      />

      {readyToSave && (
        <EditPlayerConfirmation
          handleEdit={handleConfirmEdit}
          data={data}
          initialData={initialData}
        />
      )}
    </Container>
  );
};

export default PlayerElement;
const Container = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  overflow:hidden;
  min-height:41px;
  width:100%;
`;

const Input = styled.input`
  height: 40px;
  background:${(props) =>
    props.transferred
      ? ({ theme }) => theme.editLeague.transferredPlayerBg
      : props.valueChanged
        ? ({ theme }) => theme.editLeague.wyChanged
        : ({ theme }) => theme.editLeague.readOnlyInputs}
  }
  width: 240px;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  padding: 10px 15px;
  overflow: hidden;
  text-overflow: ellipsis;

  ::placeholder {
    color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  }

`;
