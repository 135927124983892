import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname) {
      navigate("/leagues")
    }
  }, [])

  return (
    <Container>
      <div className="top">
        <h1>Error...</h1>
        <h2>
          You are out of field... click to go{" "}
          <button onClick={() => navigate("/")}>HOME</button>
        </h2>
      </div>
    </Container>
  );
};

export default NotFound;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width:1600px;
  padding: 150px 300px;
  background-image: url("/resources/images/404BG.jpg");
  height: var(--pageHeight);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  h1 {
    font-size: 42px;
    font-weight: 500;
    color: white;
  }
  h2 {
    display: flex;
    column-gap: 20px;
    font-size: 36px;
    font-weight: 500;
    color: white;
    align-items: center;
  }
  .top {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  button {
    cursor: pointer;
    border: 2px solid var(--primaryBlue);
    padding: 15px 50px;
    background: transparent;
    color: var(--primaryBlue);
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    transition: 200ms;
    :hover {
      background-color: rgba(255, 255, 255, 0.9);
      transition: 400ms;
    }
  }
`;
