import React from "react";
import styled from "styled-components";
import MatchFactors from "./MatchFactors";
import ProjectedLineUp from "./ProjectedLineUp";
import PleaseSelectFixtureDialog from "./PleaseSelectFixtureDialog";

export const MATCH_FACTORS = "MATCH FACTORS";
export const PROJECTED_LINEUP = "PROJECTED LINE-UP";

const MatchSettings = ({
  setSelectedFixture,
  selectedFixture,
  refereesList,
  currentLeague,
  currentCup,
  setIsLoading,
  handleGetGames,
  isLoading,
  statusCompleted,
  setResponseFromPartialFactorUpdate,
  settingsPage, setSettingsPage,
  stadiums,
  navigateToSquadManager,
  navigateToEditTeamPlayers,
  setThisteam,
  refreshOnClose,
  setShowEditPlayersModal,
}) => {

  const handleChange = (e) => {
    setSettingsPage(e.currentTarget.value);
  };

  const SelectPage = () => {
    if (!selectedFixture) {
      return;
    }

    if (settingsPage == MATCH_FACTORS) {
      return <MatchFactors
        setSelectedFixture={setSelectedFixture}
        selectedFixture={selectedFixture}
        refereesList={refereesList}
        currentLeague={currentLeague}
        currentCup={currentCup}
        setIsLoading={setIsLoading}
        handleGetGames={handleGetGames}
        statusCompleted={statusCompleted}
        setResponseFromPartialFactorUpdate={setResponseFromPartialFactorUpdate}
        stadiums={stadiums}
        navigateToSquadManager={navigateToSquadManager}
      />;
    }

    if (settingsPage == PROJECTED_LINEUP) {
      return <ProjectedLineUp
        selectedFixture={selectedFixture}
        currentLeague={currentLeague}
        currentCup={currentCup}
        handleGetGames={handleGetGames}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        statusCompleted={statusCompleted}
        navigateToSquadManager={navigateToSquadManager}
        navigateToEditTeamPlayers={navigateToEditTeamPlayers}
        setThisteam={setThisteam}
        refreshOnClose={refreshOnClose}
        setShowEditPlayersModal={setShowEditPlayersModal}
      />;
    }
  };

  return (
    <Container>
      {selectedFixture ? (
        <Menu>
          <BtnWrapper>
            <button value={MATCH_FACTORS} className={settingsPage === MATCH_FACTORS ? "active" : ""} onClick={(e) => handleChange(e)}>MATCH FACTORS</button>
            <button value={PROJECTED_LINEUP} className={settingsPage === PROJECTED_LINEUP ? "active" : ""} onClick={(e) => handleChange(e)}>PROJECTED LINE-UP</button>
          </BtnWrapper>

        </Menu>) : (
        !isLoading &&
        <Warning>
          <PleaseSelectFixtureDialog />
        </Warning>
      )}

      <Content>
        {SelectPage()}
      </Content>
    </Container>
  );
};

export default MatchSettings;
const Container = styled.div`
display:flex;
flex-direction:column;
width:100%;
row-gap:5px;
height:100%;
overflow:hidden;

`;
const Menu = styled.div`
display:flex;
background: ${({ theme }) => theme.matchPreview.matchFactorsBg};
padding:5px;
`;
const Content = styled.div`
display:flex;
flex-direction:column;
background: ${({ theme }) => theme.matchPreview.matchFactorsBg};
height:100%;
overflow:auto;
`;
const BtnWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.matchPreview.optionMenuActive};
  border-radius: 2px;
  background:transparent;

  button {
    display: flex;
    cursor: pointer;
    padding: 6px 16px;
    border: none;
    color: #7D8692;
    background: transparent;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 0.02em;
  }

  .active {
    color: ${({ theme }) => theme.matchPreview.bg};
    background: ${({ theme }) => theme.matchPreview.optionMenuActive};
  }
`;
const Warning = styled.div`
display:flex;
justify-content:center;
align-items:center;
widht:100%;
height:100%;
min-height:calc(100vh - 145px);
margin-top:30px;
margin-left:30px;
`;

