import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";
import { format_time_to_timezone } from "../../../helper/Generic/dateTimeFormat";
import { useSelector } from "react-redux";
import { selectUser } from "../../../Redux/user/user";

const SelectFixtureMenu = ({
  label,
  dataList,
  selectedValue,
  setSelectedValue,
  changeMenuValue,
  disabled,
}) => {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const user = useSelector(selectUser);

  const showFixtureStartTime = (startTime) => {
    const fixtureStartTime = format_time_to_timezone(startTime, user.timezone);
    return fixtureStartTime.format("DD/MM/YYYY / HH:mm:ss");
  };

  const handleOnClick = (item) => {
    setSelectedValue(item);
    handleMenuOpen();
  };

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref}>
      <DropDownMenu
        onClick={() => !disabled && handleMenuOpen()}
        isMenuOpen={isMenuOpen}
      >
        {selectedValue ? (
          <DopDownSelectedValue>
            <p> {selectedValue.participant1.name}</p>
            <p>-</p>
            <p> {selectedValue.participant2.name}</p>
            <p>({showFixtureStartTime(selectedValue.start_time)})</p>
            {selectedValue?.wy_id && <p>({selectedValue.wy_id})</p>}
          </DopDownSelectedValue>
        ) : (
          <h5>{label}</h5>
        )}
        {isMenuOpen ? <UpSvg /> : !disabled && <DownSvg />}
      </DropDownMenu>

      {isMenuOpen && (
        <DropDownList>
          {dataList?.map((item, index) => (
            <DropDownMenuItem key={index} onClick={() => handleOnClick(item)}>
              <p> {item.participant1.name}</p>
              <p>-</p>
              <p> {item.participant2.name}</p>
              <p>({showFixtureStartTime(item.start_time)})</p>
              {item?.wy_id && <p>({item.wy_id})</p>}
            </DropDownMenuItem>
          ))}
        </DropDownList>
      )}
    </Container>
  );
};

export default SelectFixtureMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  position: relative;
  cursor: pointer;
  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: rgba(60, 74, 92, 1);
  }
`;
const DropDownMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.matchPreview.selectMenuBg};
  padding: 11px 16px;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  border-radius: ${(props) => (props.isMenuOpen ? "8px 8px 0 0" : "")};
  max-width: 500px;
  h5 {
    max-width: 480px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
    opacity: 0.6;
  }
  svg {
    cursor: pointer;
    fill: ${({ theme }) => theme.matchPreview.selectMenuSvgBg};
    stroke: ${({ theme }) => theme.matchPreview.selectMenuSvgColor};
  }
`;

const DopDownSelectedValue = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 430px;
  overflow: hidden;
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
  }
`;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top: 40px;
  border-color: #3c4a5c;
  border: 1px solid #BAC3E8;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  max-height: 400px;
  overflow-x: hidden;

  width:100%;
    ::placeholder{
      color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
    }
      
    }
    svg {
      position: absolute;
      z-index: 1;
      top: 7px;
      left: 7px;
    }
  }
`;
const DropDownMenuItem = styled.li`
  align-items: center;
  display: flex;
  gap: 5px;
  cursor: pointer;
  list-style-type: none;
  background-color: ${({ theme }) => theme.matchPreview.selectMenuBg};
  padding: 8px 16px;

  p {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
  }
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
