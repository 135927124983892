import { cloneDeep } from "lodash"

export const sortReferees = (referees) => {
    if (!referees) {
        return
    }

    let refCopy = cloneDeep(referees)

    let mappedRefs = referees?.map((ref) => {
        if (ref.name) {
            return ref
        } else {
            return {
                ...ref,
                name: ref.first_name + " " + ref.last_name
            }
        }
    })
    mappedRefs.sort((a, b) => a.name > b.name ? 1 : -1)

    let sortingArr = mappedRefs.map((item) => item.id);

    let sortedRefs = refCopy.sort((a, b) => {
        return sortingArr.indexOf(a.id) - sortingArr.indexOf(b.id)
    })

    return (sortedRefs)
}
