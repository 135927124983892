export const tierList = [
    {
        tier_name: "Tier 1",
        tier_id: 1

    },
    {
        tier_name: "Tier 2",
        tier_id: 2

    },
    {
        tier_name: "Tier 3",
        tier_id: 3
    },
    {
        tier_name: "Tier 4",
        tier_id: 4

    }
];