import {
  percentageChange,
  findBookMakers,
  get_singbet_price,
} from "../ModelDataHelper";

export const mapBetObject = (bets, betsPrices, canSendRsList) => {
  let listData = [];
  let data = {};

  bets?.forEach((bet) => {
    let markets = [];

    let bookmakers = null;
    let singprice = null;
    let sendToRs = false;

    //map send_to_rs based on canSendRsList
    if (canSendRsList != undefined && canSendRsList.length > 0) {
      let canSendRsItem = canSendRsList.find((item) => {
        return item.bet_id == bet.id
      })
      if (canSendRsItem != undefined) {
        sendToRs = canSendRsItem.can_send_to_rs
      }
    }

    if (betsPrices !== undefined && betsPrices.length > 0) {
      let price = betsPrices?.find((element) => {
        return element.fixture_id === bet.fixture_id;
      });

      markets = price?.markets.filter((marketPrice) => {
        return marketPrice.market_id == bet.market_id;
      });

      if (
        price !== null &&
        price !== undefined &&
        price.markets !== undefined
      ) {
        let marketId = bet.market_id;
        bookmakers = findBookMakers(price.markets, marketId);
        singprice = get_singbet_price(price.markets, marketId);
      }
    }

    data = {
      away_team: bet.away_team,
      competition_name: bet.competition_name,
      country_name: bet.country_name,
      fixture_id: bet.fixture_id,
      home_team: bet.home_team,
      id: bet.id,
      market: bet.market,
      market_id: bet.market_id,
      min_price: bet.min_price,
      model_price: bet.model_price,
      selection: bet.selection,
      start_time: bet.start_time,
      status: bet.status,
      target: bet.target,
      received_time: bet.received_time,
      list_id: bet.list_id,
      placed: bet.previously_placed,
      placed_value: mapPlacedToValue(bet),
      liquidity: null,
      bookmakers: null,
      market_value: null,
      market_price: null,
      markets: markets,
      priority: bet.priority,
      priority_value: mapPriorityToValue(bet),
      can_send_to_rs: bet.can_send_to_rs
    };

    data.bookmakers = bookmakers;
    data.can_send_to_rs = sendToRs;

    if (singprice != null) {
      const l_model_price = bet.model_price;

      data.market_price = parseFloat(singprice);

      const mkt_value = percentageChange(l_model_price, singprice);

      if (mkt_value != undefined) {
        data.market_value = parseFloat(mkt_value);
      }
    }
    listData.push(data);
  });
  return listData;
};

const mapPriorityToValue = (bet) => {
  const priority = bet.priority;
  if (!priority) {
    return -1;
  }

  if (priority === "top") {
    return 1;
  }

  if (priority === "high") {
    return 2;
  }

  if (priority === "medium") {
    return 3;
  }

  if (priority === "low") {
    return 4;
  }

  return -1;
};

const mapPlacedToValue = (bet) => {
  const previouslyPlaced = bet.previously_placed;
  if (!previouslyPlaced) {
    return 0;
  }

  if (previouslyPlaced.length > 0) {
    return 1;
  }

  return 0;
};
