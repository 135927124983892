import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import CountryMenu from "../../components/bettingComponents/Menu/CountryMenu";
import { useRequestMenuService } from "../../context/useRequestMenuService";
import { ReactComponent as SearchSvg } from "../../assets/icons/BettingIcons/search.svg";
import { ReactComponent as PlusSvg } from "../../assets/icons/LeaguesIcons/plusIcon.svg";
import { getCountriesForCurrentUser } from "../../api/competitions/GetCountriesForCurrentUser";
import { ReactComponent as DownloadSvg } from "../../assets/icons/BettingIcons/downloadArrow.svg";
import {
    notifyError,
    notifyLoading,
    notifyUpdate,
} from "../../helper/Generic/Notifications";
import LeagueElement from "../../components/leaguesComponents/SeasonComponents/LeagueElement";
import { useLeaguesDataService } from "../../context/useLeaguesDataService";
import { mapLeaguesObjects } from "../../helper/Analyst/LeaguesData/mapLeagues";
import LeagueElementItem from "../../components/leaguesComponents/SeasonComponents/LeagueElementItem";
import LeagueMenuForSeason from "../../components/leaguesComponents/Menu/LeagueMenuForSeason";
import { SpinnerComponent } from "../../components/genericComponents/SpinnerComponent";
import EditLeague from "../../components/leaguesComponents/EditLeagueComponents/EditLeague";
import { useLocation, useSearchParams } from "react-router-dom";
import qs from "query-string";
import { downloadLeaguesCsv } from "../../api/analyst-leagues/leagues/downloadCSV";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { selectUser } from "../../Redux/user/user";
import {
    sortLeaguesAz,
    sortTeamsByKey,
    sortTeamsByName,
    sortTeamsByPitchSize,
    sortTeamsBySharedStadium,
    sortTeamsInLeagueAZ,
} from "../../helper/Analyst/LeaguesData/sortTeamsFromLeague";
import AddNewEvents from "../../components/leaguesComponents/AddNewLeagueEvents/AddNewEvents";
import SeasonCupsMenu from "../../components/leaguesComponents/Menu/SeasonCupsMenu";
import CupItem from "../../components/leaguesComponents/CupComponets/CupItem";
import CupTeam from "../../components/leaguesComponents/CupComponets/CupTeam";
import SeasonItem from "../../components/leaguesComponents/SeasonComponents/SeasonItem";
import LeaguesHeader from "../../components/leaguesComponents/LeaguesHeader";
import EditSeasonModal from "../../components/leaguesComponents/EditLeagueEvents/EditSeasonModal";
import EditCupModal from "../../components/leaguesComponents/EditLeagueEvents/EditCupModal";
import PlayerMetricsSeasonCard from "../../components/squadManagerComponents/PlayerMetricsSeasonCard";
import cloneDeep from "lodash.clonedeep";
import { param } from "jquery";


const Archived = () => {
    const user = useSelector(selectUser);

    const [isLoading, setIsLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isAddNewSeasonOpen, setIsAddNewSeasonOpen] = useState(false);
    const [isEditSeasonModalOpen, setIsEditSeasonModalOpen] = useState(false)
    const [isEditCupModalOpen, setIsEditCupModalOpen] = useState(false)
    const location = useLocation();
    const [queryParams, setQueryParams] = useState(qs.parse(location.search));

    const [selectedSeason, setSelectedSeason] = useState();
    const [selectedSeasonToEdit, setSelectedSeasonToEdit] = useState();
    const [selectedCupToEdit, setSelectedCupToEdit] = useState()

    const [seasonsAndCupsList, setSeasonsAndCupsList] = useState({
        seasons: [],
        cups: []
    });

    const [selectedSeasonsAndCups, setSelectedSeasonsAndCups] = useState({
        seasons: [],
        cups: []
    })

    const [seasons, setSeasons] = useState([]);
    const [cups, setCups] = useState([]);

    const [leaguesList, setLeaguesList] = useState([]);

    const {
        searchInput,
        countries,
        countriesList,
        countriesIds,
        setSearchInput,
        setCountries,
        setCountriesList,
        setCountriesIds,
    } = useRequestMenuService();

    const { get_leagues, get_seasons_and_cups, search_league, search_teams, archive_unarchive_cup, delete_league } =
        useLeaguesDataService();

    const [selectedLeagues, setSelectedLeagues] = useState([]);
    const [selectedLeaguesIds, setSelectedLeaguesIds] = useState([]);

    const [leaguesListForSeason, setLeaguesListForSeason] = useState([]);
    const [refreshListOnClose, setRefreshListOnClose] = useState(false);
    const [selectedLeagueToEdit, setSelectedLeagueToEdit] = useState(null);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [ascending, setAscending] = useState(true);

    const toastId = useRef();
    const shouldRefreshLeaguesAndCups = useRef();

    const _handleInputSearch = (e) => {
        setSearchInput(e.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSetSearchParams();
            handleGetSeasonsAndCups();
        }
    };

    const handleDownloadCSV = () => {
        let term = null;
        let country_ids = null;
        let selectedLeaguesIdsList = null;
        let season_ids = null;
        let cup_ids = null;
        if (selectedLeaguesIds.length < 1) {
            selectedLeaguesIdsList = null;
        } else {
            selectedLeaguesIdsList = selectedLeaguesIds;
        }
        if (countriesIds.length < 1) {
            country_ids = null;
        } else {
            country_ids = countriesIds;
        }

        if (selectedSeasonsAndCups.seasons.length < 1) {
            season_ids = null;
        } else {
            season_ids = selectedSeasonsAndCups.seasons;
        }
        if (selectedSeasonsAndCups.cups.length < 1) {
            cup_ids = null;
        } else {
            cup_ids = selectedSeasonsAndCups.cups;
        }

        if (searchInput == "") {
            term = null;
        } else {
            term = searchInput;
        }
        notifyLoading(toastId, "Generating CSV");
        downloadLeaguesCsv(
            season_ids,
            cup_ids,
            country_ids,
            selectedLeaguesIdsList,
            term,
            function (response) {
                notifyUpdate(toastId, "CSV Generated", toast.TYPE.SUCCESS);
                fileDownload(response.data, `Leagues data ${dayjs()}.csv`);
            },
            function (error) {
                console.log(error)
                notifyError("Something wrong");
            }
        );
    };

    const handleEditButton = (league, selectedSeasonId) => {
        let leagueSortedTeamsAZ = sortTeamsInLeagueAZ(league);
        setSelectedLeagueToEdit(leagueSortedTeamsAZ);
        setSelectedSeason(selectedSeasonId)
        setIsMenuOpen(true);
    };

    const createLeaguesRows = (leagueList) => {
        let mappedLeagueList = mapLeaguesObjects(leagueList);
        handleSetSettingsPageFromParams(queryParams, mappedLeagueList)
        return mappedLeagueList
    };

    const handleSortByKey = (sortKey, seasonId) => {
        let seasonClone = cloneDeep(seasons);
        let selectedSeason = seasonClone.find((season) => season.season.id === seasonId);
        let sortedLeagues = sortTeamsByKey(selectedSeason.leagues, sortKey, ascending);
        selectedSeason.leagues = sortedLeagues;
        let mapped = seasonClone.map((season) => {
            if (season.season.id === selectedSeason.season.id) {
                return selectedSeason
            } else {
                return season
            }
        })
        setSeasons(mapped);
        setAscending(!ascending);
    };

    const handleSortPitchSize = (seasonId) => {
        let seasonClone = cloneDeep(seasons);
        let selectedSeason = seasons.find((season) => season.season.id === seasonId);
        let sortedLeagues = sortTeamsByPitchSize(selectedSeason.leagues, ascending);
        selectedSeason.leagues = sortedLeagues;
        let mapped = seasonClone.map((season) => {
            if (season.season.id === selectedSeason.season.id) {
                return selectedSeason
            } else {
                return season
            }
        })
        setSeasons(mapped)
        setAscending(!ascending);
    };

    const handleSortShareStadium = (seasonId) => {
        let seasonClone = cloneDeep(seasons);
        let selectedSeason = seasons.find((season) => season.season.id === seasonId);
        let sortedLeagues = sortTeamsBySharedStadium(selectedSeason.leagues, ascending);
        selectedSeason.leagues = sortedLeagues;
        let mapped = seasonClone.map((season) => {
            if (season.season.id === selectedSeason.season.id) {
                return selectedSeason
            } else {
                return season
            }
        })
        setSeasons(mapped)
        setAscending(!ascending);
    };

    const handleSortByTeamName = (seasonId) => {
        let seasonClone = cloneDeep(seasons);
        let selectedSeason = seasons.find((season) => season.season.id === seasonId);
        let sortedLeagues = sortTeamsByName(selectedSeason.leagues, ascending);

        selectedSeason.leagues = sortedLeagues;
        let mapped = seasonClone.map((season) => {
            if (season.season.id === selectedSeason.season.id) {
                return selectedSeason
            } else {
                return season
            }
        })
        setSeasons(mapped);
        setAscending(!ascending);
    };

    const handleGetSeasonsAndCups = () => {
        // This will force a list update
        loadCupsAndSeasons();
    };

    const refreshAfterDelete = () => {
        loadLeaguesForSeason(selectedSeasonsAndCups.seasons, () => {
            handleGetSeasonsAndCups();
        });
    };

    //load seasons and cups OBJECTS
    const loadCupsAndSeasons = () => {
        let season_ids = null;
        let cup_ids = null;
        let term = null;
        let country_ids = null;
        let selectedLeaguesIdsList = null;
        let archived_teams = true;

        if (selectedSeasonsAndCups.cups.length < 1) {
            cup_ids = null;
        } else {
            cup_ids = selectedSeasonsAndCups.cups;
        }

        if (selectedSeasonsAndCups.seasons.length < 1) {
            season_ids = null;
        } else {
            season_ids = selectedSeasonsAndCups.seasons;
        }

        if (selectedLeaguesIds.length < 1) {
            selectedLeaguesIdsList = null;
        } else {
            selectedLeaguesIdsList = selectedLeaguesIds;
        }
        if (countriesIds.length < 1) {
            country_ids = null;
        } else {
            country_ids = countriesIds;
        }
        if (searchInput == "") {
            term = null;
        } else {
            term = searchInput;
        }

        setIsLoading(true);
        get_leagues(
            season_ids,
            cup_ids,
            country_ids,
            selectedLeaguesIdsList,
            term,
            archived_teams,
            function (response) {
                const sortedSeason = handleSeasonsResponse(response.data.seasons);
                setSeasons(sortedSeason);
                setCups(response.data.cups)
                setIsLoading(false);
            },
            function (error) {
                setIsLoading(false);
                notifyError("Something wrong...");
            }
        );
    };

    const handleSeasonsResponse = (seasons) => {
        let sortedSeason = seasons.map((season) => {
            return {
                season: season.season,
                leagues: sortLeaguesAz(season.leagues)
            }
        })
        return sortedSeason;
    }

    const loadTeamsForSelectedSeason = (seasonId) => {
        if (!seasonId) {
            return;
        }
        search_teams(
            null,
            seasonId,
            function (response) { },
            function (error) {
                notifyError("Something wrong...");
            }
        );
    };

    const loadInitialData = () => {
        // Load countries and seasons
        loadCountries(() => {
            loadSeasonsAndCupsLists();
        });
    };

    const loadSeasonsAndCupsLists = () => {
        let params = qs.parse(location.search);
        setIsLoading(true)
        get_seasons_and_cups(
            true,
            searchInput,
            function (response) {
                setSeasonsAndCupsList(response.data);
                setIsLoading(false)
                if (!params.seasonIds && !params.leagueIds) {
                    setSelectedSeasonsAndCups({
                        cups: response.data.cups[0] ? [response.data.cups[0].id] : [],
                        seasons: response.data.seasons[0] ? [response.data.seasons[0].id] : []
                    })
                }
            },
            function (error) {
                console.log(error)
                setIsLoading(false)
                notifyError("Something wrong")
            }
        )
    }

    const loadLeaguesForSeason = (season, onSuccess) => {
        let country_ids = null;
        let season_ids = null
        if (season.length === 0) {
            setSelectedLeaguesIds([]);
            setLeaguesListForSeason([]);
            return;
        }

        if (countriesIds.length > 0) {
            country_ids = countriesIds;
        }
        if (selectedSeasonsAndCups.seasons.length > 0) {
            season_ids = selectedSeasonsAndCups.seasons
        }


        setIsLoading(true);
        search_league(
            season_ids,
            country_ids,
            null,
            function (response) {
                setSelectedLeaguesIds([]);
                const sortedLeagues = response.leagues.sort((a, b) => a.name > b.name ? 1 : -1);
                setLeaguesListForSeason(sortedLeagues);
                onSuccess();
            },
            function (error) {
                setIsLoading(false);
                notifyError("Something Wrong");
            }
        );
    };

    const loadCountries = (onSucces) => {
        setIsLoading(true);
        getCountriesForCurrentUser(
            function (response) {
                setIsLoading(false);

                let sortedCountries = response.data.countries.sort((a, b) =>
                    a.name > b.name ? 1 : -1
                );
                setCountriesList(sortedCountries);

                onSucces();
            },
            function (error) {
                setIsLoading(false);
                notifyError("Something Wrong");
            }
        );
    };

    const handleSetSearchParams = () => {
        setSearchParams((params) => {

            params.set("seasonIds", selectedSeasonsAndCups.seasons)
            params.set("cupIds", selectedSeasonsAndCups.cups)
            params.set("countryId", countriesIds);
            params.set("leagueIds", selectedLeaguesIds);
            params.set("term", searchInput);
            return params
        })

    };


    const initializeMenuValuesFromParams = () => {

        if (location.search != "") {
            let params = qs.parse(location.search);
            let seasonIds = params.seasonIds;
            let cupIds = params.cupIds;
            let leaguesIds = params.leagueIds;
            let countryIds = params.countryIds
            let term = params.term;

            let parsedSeasonIds = [];
            let parsedCupIds = [];
            let parsedCountryId = []
            let parsedLeagueId = []

            if (countryIds) {
                let parsed = countryIds.split(",")
                parsedCountryId = parsed.map((item) => parseInt(item))
                setCountriesIds(parsedCountryId)
            }

            if (seasonIds) {
                let parsed = seasonIds.split(",")
                parsedSeasonIds = parsed.map((item) => parseInt(item))

            }
            if (cupIds) {
                let parsed = cupIds.split(",")
                parsedCupIds = parsed.map((item) => parseInt(item))
            }

            if (leaguesIds) {
                let parsed = leaguesIds.split(",")
                parsedLeagueId = parsed.map((item) => parseInt(item))
            }

            let seasonCupIdsObj = {
                seasons: parsedSeasonIds,
                cups: parsedCupIds
            }

            if (term) {
                setSearchInput(term)
            }

            setSelectedSeasonsAndCups(seasonCupIdsObj)
            setSelectedLeaguesIds(parsedLeagueId)

        }
    };

    const handleArchiveCup = (cupId, archive) => {
        setIsLoading(true)
        archive_unarchive_cup(
            cupId,
            archive,
            function (response) {
                setIsLoading(false);
                let archivedCup = response.data.cup
                let filteredCup = seasonsAndCupsList.cups.filter((cup) => cup.id != archivedCup.id)
                let filteredCupSelection = selectedSeasonsAndCups.cups.filter((cup) => cup != archivedCup.id)
                setSeasonsAndCupsList((prev) => {
                    return {
                        ...prev,
                        cups: filteredCup
                    }
                })
                setSelectedSeasonsAndCups((prev) => {
                    return {
                        ...prev,
                        cups: filteredCupSelection

                    }
                })

                handleCloseEditCup();
            },
            function (error) {
                notifyError("Something wrong")
                console.log(error)
            }
        )
    }

    const handleSetSettingsPageFromParams = (queryParams, leaguesList) => {
        if (queryParams.edit_team_details) {
            let found = leaguesList.find((element) => element.id == queryParams.currentLeagueId);
            setSelectedLeagueToEdit(found);
            setIsMenuOpen(true);
        }
    }

    const handleOpenEditSeason = (seasonToEdit) => {
        let found = seasons.find((season) => season.season.id === seasonToEdit)
        setSelectedSeasonToEdit(found);
        setIsEditSeasonModalOpen(true);
    }

    const handleCloseEditSeason = () => {
        if (shouldRefreshLeaguesAndCups.current) {
            shouldRefreshLeaguesAndCups.current = false
            loadCupsAndSeasons()
        }
        setIsEditSeasonModalOpen(false)
    }

    const handleOpenEditCup = (cupToEdit) => {
        setSelectedCupToEdit(cupToEdit)
        setIsEditCupModalOpen(true)
    }
    const handleCloseEditCup = () => {
        setIsEditCupModalOpen(false)
    }

    const handleDeleteLeagueFromSeason = (leagueId) => {

        setIsLoading(true)
        delete_league(
            leagueId,
            function (response) {
                let filteredLeagues = selectedSeasonToEdit.leagues.filter((league) => league.id !== leagueId)
                let newSeason = {
                    ...selectedSeasonToEdit,
                    leagues: filteredLeagues
                }
                setSelectedSeasonToEdit(newSeason);
                setIsLoading(false);
                shouldRefreshLeaguesAndCups.current = true;
            },

            function (error) {
                notifyError("Something Wrong")
                console.log(error)
                setIsLoading(false)
            }
        )
    }

    const sortTeams = (teams) => {
        let sorted = teams.sort((a, b) => a.name > b.name ? 1 : -1)
        return sorted
    }

    useEffect(() => {
        initializeMenuValuesFromParams();
        loadInitialData();
    }, []);


    useEffect(() => {
        if (!selectedSeason) {
            return
        }
        loadTeamsForSelectedSeason(selectedSeason);
    }, [selectedSeason]);

    useEffect(() => {
        loadLeaguesForSeason(selectedSeasonsAndCups.seasons, () => { });
    }, [selectedSeasonsAndCups.seasons, countriesIds]);

    useEffect(() => {
        handleGetSeasonsAndCups();
        handleSetSearchParams();
    }, [selectedSeasonsAndCups.cups, selectedSeasonsAndCups.seasons, countriesIds, selectedLeaguesIds, selectedSeason]);

    return (
        <Container>
            {isMenuOpen && (
                <EditLeague
                    leagueData={selectedLeagueToEdit}
                    handleGetSeasonsAndCups={handleGetSeasonsAndCups}
                    setLoading={setIsLoading}
                    setIsMenuOpen={setIsMenuOpen}
                    refreshListOnClose={refreshListOnClose}
                    setRefreshListOnClose={setRefreshListOnClose}
                    leaguesListForSeason={leaguesListForSeason}
                    refreshAfterDelete={refreshAfterDelete}
                    queryParams={queryParams}
                    setQueryParams={setQueryParams}
                />
            )}

            {isEditSeasonModalOpen && (
                <EditSeasonModal
                    selectedSeasonToEdit={selectedSeasonToEdit}
                    handleCloseEditSeason={handleCloseEditSeason}
                    shouldRefreshLeaguesAndCups={shouldRefreshLeaguesAndCups}
                    loadInitialData={loadInitialData}
                    setSelectedSeasonsAndCups={setSelectedSeasonsAndCups}
                    handleDeleteLeagueFromSeason={handleDeleteLeagueFromSeason}
                    setIsLoading={setIsLoading}
                />

            )}
            {isEditCupModalOpen && (

                <EditCupModal
                    selectedCupToEdit={selectedCupToEdit}
                    loadCupsAndSeasons={loadCupsAndSeasons}
                    handleCloseEditCup={handleCloseEditCup}
                    handleArchiveCup={handleArchiveCup}
                    setIsLoading={setIsLoading}
                />
            )}

            <LeaguesHeader
            />

            <Header>
                <Left>
                    {" "}
                    <Search>
                        <SearchSvg />
                        <input
                            value={searchInput}
                            onChange={(e) => _handleInputSearch(e)}
                            placeholder="Filter by keyword"
                            onKeyDown={(e) => handleKeyDown(e)}
                        ></input>
                    </Search>

                    <SeasonCupsMenu
                        dataList={seasonsAndCupsList}
                        value={selectedSeasonsAndCups}
                        setValue={setSelectedSeasonsAndCups}
                    />

                    <CountryMenu
                        className="countryDropDown"
                        label={"Countries"}
                        countriesList={countriesList}
                        countries={countries}
                        setCountries={setCountries}
                        countriesIds={countriesIds}
                        setCountriesIds={setCountriesIds}
                    />

                    <LeagueMenuForSeason
                        label={"Leagues"}
                        competitions={leaguesListForSeason}
                        selectedCompetitions={selectedLeagues}
                        setSelectedCompetitions={setSelectedLeagues}
                        competitionIds={selectedLeaguesIds}
                        setCompetitionIds={setSelectedLeaguesIds}
                    />
                </Left>

                <Right>
                    <AddNewSeasonButton onClick={() => setIsAddNewSeasonOpen(true)}>
                        <PlusSvg /> Add new
                    </AddNewSeasonButton>
                    <CsvBtn onClick={() => handleDownloadCSV()}>
                        <DownloadSvg />
                        CSV
                    </CsvBtn>{" "}
                </Right>
            </Header>
            <Content>

                {/* TODO: Card ready */}
                {/* <PlayerMetricsSeasonCard /> */}
                <SpinnerComponent loading={isLoading}></SpinnerComponent>

                {cups.map((cup, index) => (
                    <CupItem
                        key={cup.id}
                        cup={cup}
                        handleEditButton={handleOpenEditCup}
                    >
                        {sortTeams(cup.teams)?.map((team, index) => (
                            <CupTeam
                                key={team.id}
                                teamId={team.id}
                                cupId={cup.id}
                                teamName={team.name}
                                season={team.season.name}
                                user={user}
                                logo={team.logo_url}
                                searchParams={searchParams}
                                archived={cup.archived}
                            />

                        ))}

                    </CupItem>
                ))}

                {seasons.map((season, index) => (
                    <SeasonItem
                        key={season.season.id}
                        season={season.season}
                        handleEditButton={handleOpenEditSeason}
                    >

                        {createLeaguesRows(season.leagues).map((league, index) => (
                            <LeagueElement
                                leagueData={league}
                                key={league.id}
                                handleGetSeasonsAndCups={handleGetSeasonsAndCups}
                                handleEditButton={handleEditButton}
                                handleSortByKey={handleSortByKey}
                                handleSortPitchSize={handleSortPitchSize}
                                handleSortShareStadium={handleSortShareStadium}
                                handleSortByTeamName={handleSortByTeamName}
                            >
                                {league.teams?.map((team, teamIndex) => (
                                    <LeagueElementItem
                                        leagueTeamData={team}
                                        leagueData={league}
                                        key={team.id}
                                        bg={teamIndex}
                                        leagueTier={league.tier}
                                        user={user}
                                        searchParams={searchParams}
                                        archived={season.season.archived}
                                    />
                                ))}
                            </LeagueElement>
                        ))}


                    </SeasonItem>
                ))}


            </Content>


            {
                isAddNewSeasonOpen && (
                    <AddNewEvents
                        setIsAddNewSeasonOpen={setIsAddNewSeasonOpen}
                        setSearchParams={setSearchParams}
                        setSeasonsAndCupsList={setSeasonsAndCupsList}
                        setSelectedSeasonsAndCups={setSelectedSeasonsAndCups}

                    />
                )
            }

        </Container >
    );
};

export default Archived;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1600px;
  min-height: calc(100vh - 45px);
  background-color: ${({ theme }) => theme.headerBG};

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
  }
`;
const Header = styled.div`
  position: sticky;
  top: 90px;
  display: flex;
  background-color: ${({ theme }) => theme.headerBG};
  padding: 8px 14px;
  justify-content: space-between;

  z-index:2;
`;
const Left = styled.div`
  display: flex;
  column-gap: 5px;
  color: #3c4a5c;
`;
const Right = styled(Left)`
  display: flex;
`;
const CsvBtn = styled.button`
  cursor: pointer;
  border-radius: 4px;
  column-gap: 5px;
  padding: 5px 10px 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  background: var(--primaryBlue);
  color: #ffffff;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
const Search = styled.div`
  position: relative;
  svg {
    color: ${({ theme }) => theme.menuTextColor};
    position: absolute;
    top: 9px;
    left: 10px;
  }

  input {
    padding: 5px 10px 3px 26px;
    background: ${({ theme }) => theme.menuBgColor};
    color: ${({ theme }) => theme.menuTextColor};
    border: none;
    border-radius: 8px;
    min-width: 242px;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    ::placeholder {
      color: ${({ theme }) => theme.menuTextColor};
    }
    outline: none;
    border: none;

    :focus-visible {
      border: none;
      outline: none;
    }
  }
`;
const AddNewSeasonButton = styled.button`
  cursor: pointer;
  display: flex;
  column-gap: 5px;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: white;
  padding: 8px;
  border-radius: 3px;
  border: none;
  background-color: #263549;
`;
