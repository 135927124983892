
import { PulseLoader } from "react-spinners";
import styled from "styled-components";

export const FullScreenSpinner = ({ loading, components }) => {
    return (
        <div>
            {loading ?
                (<div>
                    <SpinnerWrapper>
                        <PulseLoader
                            color={"#006fff"}
                            loading={loading}
                            size={15}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </SpinnerWrapper>
                    {components}
                </div>) :
                ("")}
        </div>
    );
};

const SpinnerWrapper = styled.div`
position:absolute;
z-index:10;
top:0;
left:0;
right:0;
bottom:0;
display:flex;
justify-content:center;
align-items:center;
`