export const ATTACK = "attack";
export const DEFENSIVE = "defensive";
export const MIDFIELD = "midfield";
export const GK = "goalkeeper";
export const OTHER = "other"
export const ALL = "all";
export const BENCH = "bench";
export const AVAILABLE = "available";
export const UNAVAILABLE = "unavailable";

export const GK_POSITIONS = ["GK"];
export const DEF_POSITIONS = ["LB", "CB", "RB", "DM"];
export const MID_POSITIONS = ["LWB", "RWB", "LM", "CM", "RM",];
export const ATK_POSITIONS = ["AM", "LW", "CAM", "RW", "CF",];

export const ALL_POSITIONS = GK_POSITIONS.concat(DEF_POSITIONS)
  .concat(MID_POSITIONS)
  .concat(ATK_POSITIONS);
export const BENCH_POSITIONS = ALL_POSITIONS;

export const TeamFormation = [
  {
    label: "4-3-3",
    value: "4-3-3",
    formation: [1, 4, 3, 3],
    groups: [
      {
        type: GK,
        number_of_players: 1,
      },
      {
        type: DEFENSIVE,
        number_of_players: 4,
      },
      {
        type: MIDFIELD,
        number_of_players: 3,
      },
      {
        type: ATTACK,
        number_of_players: 3,
      },
    ],
  },

  {
    label: "4-2-3-1",
    value: "4-2-3-1",
    formation: [1, 4, 2, 3, 1],
    groups: [
      {
        type: GK,
        number_of_players: 1,
      },
      {
        type: DEFENSIVE,
        number_of_players: 4,
      },
      {
        type: MIDFIELD,
        number_of_players: 2,
      },
      {
        type: ATTACK,
        number_of_players: 3,
      },

      {
        type: ATTACK,
        number_of_players: 1,
      },
    ],
  },
  {
    label: "4-4-2",
    value: "4-4-2",
    formation: [1, 4, 4, 2],
    groups: [
      {
        type: GK,
        number_of_players: 1,
      },
      {
        type: DEFENSIVE,
        number_of_players: 4,
      },
      {
        type: MIDFIELD,
        number_of_players: 4,
      },
      {
        type: ATTACK,
        number_of_players: 2,
      },
    ],
  },
  {
    label: "4-1-4-1",
    value: "4-1-4-1",
    formation: [1, 4, 1, 4, 1],
    groups: [
      {
        type: GK,
        number_of_players: 1,
      },
      {
        type: DEFENSIVE,
        number_of_players: 4,
      },
      {
        type: MIDFIELD,
        number_of_players: 1,
      },
      {
        type: ATTACK,
        number_of_players: 4,
      },
      {
        type: ATTACK,
        number_of_players: 1,
      },
    ],
  },
  {
    label: "4-5-1",
    value: "4-5-1",
    formation: [1, 4, 5, 1],
    groups: [
      {
        type: GK,
        number_of_players: 1,
      },
      {
        type: DEFENSIVE,
        number_of_players: 4,
      },
      {
        type: MIDFIELD,
        number_of_players: 5,
      },
      {
        type: ATTACK,
        number_of_players: 1,
      },
    ],
  },
  {
    label: "4-2-4",
    value: "4-2-4",
    formation: [1, 4, 2, 4],
    groups: [
      {
        type: GK,
        number_of_players: 1,
      },
      {
        type: DEFENSIVE,
        number_of_players: 4,
      },
      {
        type: MIDFIELD,
        number_of_players: 2,
      },
      {
        type: ATTACK,
        number_of_players: 4,
      },
    ],
  },
  {
    label: "4-1-2-1-2",
    value: "4-1-2-1-2",
    formation: [1, 4, 1, 2, 1, 2],
    groups: [
      {
        type: GK,
        number_of_players: 1,
      },
      {
        type: DEFENSIVE,
        number_of_players: 4,
      },
      {
        type: DEFENSIVE,
        number_of_players: 1,
      },
      {
        type: MIDFIELD,
        number_of_players: 2,
      },
      {
        type: ATTACK,
        number_of_players: 1,
      },
      {
        type: ATTACK,
        number_of_players: 2,
      },
    ],
  },
  {
    label: "4-2-2-2",
    value: "4-2-2-2",
    formation: [1, 4, 2, 2, 2],

    groups: [
      {
        type: GK,
        number_of_players: 1,
      },
      {
        type: DEFENSIVE,
        number_of_players: 4,
      },
      {
        type: MIDFIELD,
        number_of_players: 2,
      },
      {
        type: ATTACK,
        number_of_players: 2,
      },
      {
        type: ATTACK,
        number_of_players: 2,
      },
    ],
  },

  {
    label: "4-4-1-1",
    value: "4-4-1-1",
    formation: [1, 4, 4, 1, 1],

    groups: [
      {
        type: GK,
        number_of_players: 1,
      },
      {
        type: DEFENSIVE,
        number_of_players: 4,
      },
      {
        type: MIDFIELD,
        number_of_players: 4,
      },
      {
        type: ATTACK,
        number_of_players: 1,
      },
      {
        type: ATTACK,
        number_of_players: 1,
      },
    ],
  },
  {
    label: "4-1-3-2",
    value: "4-1-3-2",
    formation: [1, 4, 1, 3, 2],

    groups: [
      {
        type: GK,
        number_of_players: 1,
      },
      {
        type: DEFENSIVE,
        number_of_players: 4,
      },
      {
        type: MIDFIELD,
        number_of_players: 1,
      },
      {
        type: ATTACK,
        number_of_players: 3,
      },
      {
        type: ATTACK,
        number_of_players: 2,
      },
    ],

  },
  {
    label: "4-3-1-2",
    value: "4-3-1-2",
    formation: [1, 4, 3, 1, 2],

    groups: [
      {
        type: GK,
        number_of_players: 1,
      },
      {
        type: DEFENSIVE,
        number_of_players: 4,
      },
      {
        type: MIDFIELD,
        number_of_players: 3,
      },
      {
        type: ATTACK,
        number_of_players: 1,
      },
      {
        type: ATTACK,
        number_of_players: 2,
      },
    ],
  },
  {
    label: "3-4-3",
    value: "3-4-3",
    formation: [1, 3, 4, 3],
    groups: [
      {
        type: GK,
        number_of_players: 1,
      },
      {
        type: DEFENSIVE,
        number_of_players: 3,
      },
      {
        type: MIDFIELD,
        number_of_players: 4,
      },
      {
        type: ATTACK,
        number_of_players: 3,
      },
    ],
  },
  {
    label: "3-5-2",
    value: "3-5-2",
    formation: [1, 3, 5, 2],
    groups: [
      {
        type: GK,
        number_of_players: 1,
      },
      {
        type: DEFENSIVE,
        number_of_players: 3,
      },
      {
        type: MIDFIELD,
        number_of_players: 5,
      },

      {
        type: ATTACK,
        number_of_players: 2,
      },
    ],
  },
  {
    label: "3-1-4-2",
    value: "3-1-4-2",
    formation: [1, 3, 1, 4, 2],
    groups: [
      {
        type: GK,
        number_of_players: 1,
      },
      {
        type: DEFENSIVE,
        number_of_players: 3,
      },
      {
        type: MIDFIELD,
        number_of_players: 1,
      },
      {
        type: ATTACK,
        number_of_players: 4,
      },
      {
        type: ATTACK,
        number_of_players: 2,
      },
    ],
  },
  {
    label: "3-4-2-1",
    value: "3-4-2-1",
    formation: [1, 3, 4, 2, 1],

    groups: [
      {
        type: GK,
        number_of_players: 1,
      },
      {
        type: DEFENSIVE,
        number_of_players: 3,
      },
      {
        type: MIDFIELD,
        number_of_players: 4,
      },
      {
        type: ATTACK,
        number_of_players: 2,
      },
      {
        type: ATTACK,
        number_of_players: 1,
      },
    ],
  },
  {
    label: "5-3-2",
    value: "5-3-2",
    formation: [1, 5, 3, 2],
    groups: [
      {
        type: GK,
        number_of_players: 1,
      },
      {
        type: DEFENSIVE,
        number_of_players: 5,
      },
      {
        type: MIDFIELD,
        number_of_players: 3,
      },
      {
        type: ATTACK,
        number_of_players: 2,
      },
    ],
  },
  {
    label: "5-4-1",
    value: "5-4-1",
    formation: [1, 5, 4, 1],
    groups: [
      {
        type: GK,
        number_of_players: 1,
      },
      {
        type: DEFENSIVE,
        number_of_players: 5,
      },
      {
        type: MIDFIELD,
        number_of_players: 4,
      },
      {
        type: ATTACK,
        number_of_players: 1,
      },
    ],
  },
];

export const YesNo = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const PitchConditionList = [
  { label: "Excellent", value: "excellent" },
  { label: "good", value: "good" },
  { label: "average", value: "average" },
  { label: "poor", value: "poor" },
  { label: "terrible", value: "terrible" },
];

export const AtkDefValues = [
  { label: "-1", value: -1 },
  { label: "0", value: 0 },
  { label: "+1", value: +1 },
];

export const MatchImp = [
  { label: "0", value: 0 },
  { label: "-1", value: -1 },
  { label: "-2", value: -2 },
];

export const WinLoseValue = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
];

export const TierValues = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
];

export const PlayerNameOptions = [
  {
    label: "First name, last name",
    value: "fullName"
  },
  {
    label: "Short names",
    value: "shortName"
  },
  {
    label: "Other name",
    value: "otherName"
  },
]