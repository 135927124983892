import { cloneDeep } from "lodash";

export const mergeSquadDetailsStats = (squadDetails, squadStats) => {
  let newDetails = cloneDeep(squadDetails);

  squadStats.forEach((stats) => {
    let index = newDetails?.findIndex(
      (item) => item?.squad?.id === stats.squad?.id
    );

    if (index == -1) {
      return;
    }

    newDetails[index].squad.selections.forEach((element) => {
      let newGameStats = stats.squad.selections.find(
        (selection) => selection.id === element.id
      );

      if (newGameStats) {
        element.game_stat = newGameStats.game_stat;
      }
    });
  });

  return newDetails;
};
