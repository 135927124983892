import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";
import { ReactComponent as SearchSvg } from "../../../assets/icons/PricingMenuIcons/search.svg";
const SharedTeamsMenu = ({ label, valuesList, value, setValue, index, sharedTeams }) => {

  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState("");

  const handleOnClick = (item) => {
    let updateList = [...sharedTeams];
    updateList[index] = item;
    setValue(updateList);
    handleMenuOpen();
  };



  const handleSearchInput = (e) => {
    setInputSearch(e.target.value);
  };

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  const filterSearch = (items, filter) => {
    if (filter === "") {
      return items;
    } else {
      return items.filter((item) =>
        item.name.toLowerCase().includes(filter.toLowerCase())
      );
    }
  };


  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
        setInputSearch("");
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref}>
      <DropDownMenu onClick={() => handleMenuOpen()} isMenuOpen={isMenuOpen}>
        {value != null ? (<h4>{value.name}</h4>) : (<h4>{label}</h4>)}
        {isMenuOpen ? <UpSvg /> : <DownSvg />}
      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>
          <DropDownMenuItem>
            <div className="searchGroup">
              <SearchSvg />
              <input
                type={"text"}
                value={inputSearch}
                onChange={handleSearchInput}
                placeholder="Search"
              ></input>
            </div>
          </DropDownMenuItem>
          {inputSearch !== ""
            ? filterSearch(valuesList, inputSearch).map(
              (item, index) => (
                <DropDownMenuItem key={index} onClick={() => handleOnClick(item)}>
                  <div
                  >
                    <input
                      readOnly
                      // onChange={(e) => handleCheck(e, item)}
                      className="check"
                      type="radio"
                      checked={value?.id == item.id}
                      value={item}
                    ></input>
                    {/* <img src={item !== null ? (checkFlag(item)) : ""} alt=""></img> */}
                    <p>{item.name}</p>
                  </div>
                </DropDownMenuItem>
              )
            )
            : valuesList.map((item, index) => (
              <DropDownMenuItem key={index} onClick={() => handleOnClick(item)}>
                <div
                  className={
                    item === valuesList ? "active" : "nonActive"
                  }
                >
                  <input
                    key={index}
                    readOnly
                    className="check"
                    type="radio"
                    checked={value?.id == item.id}
                    value={item}
                  ></input>
                  {/* <img src={item !== null ? (checkFlag(item)) : ""} alt=""></img> */}
                  <p> {item.name}</p>
                </div>
              </DropDownMenuItem>
            ))}
        </DropDownList>
      )}
    </Container>
  );
};


export default SharedTeamsMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
width:142px;
  position: relative;

  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;

  }
`;
const DropDownMenu = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.editLeague.selectMenuBg};
    padding: 11px 15px;
    border: 1px solid #BAC3E8;
    border-radius: 6px;
    border-radius: ${(props) => props.isMenuOpen ? "8px 8px 0 0" : ""} ;
    .group {
      align-items: center;
      display: flex;
      column-gap: 5px;
      img {
        height: 14px;
        width: 18px;
        border-radius: 4px;
      }
    }
    img {
      cursor: pointer;
      height: 21px;
      width: 22px;
    }
    svg {
      cursor: pointer;
      fill:${({ theme }) => theme.editLeague.selectMenuSvgBg};
    stroke:${({ theme }) => theme.editLeague.selectMenuSvgColor}
    }
    h4{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
    }
  `;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top: 40px;
  border:1px solid #BAC3E8;
  border-top: 2px solid #3c4a5c;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  max-height: 250px;
  width: 100%;
  overflow-x: hidden;
  color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
  background-color: ${({ theme }) => theme.editLeague.selectMenuBg};
  .searchGroup {
    position: relative;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
    input {
      background-color: ${({ theme }) => theme.editLeague.selectMenuBg};
      color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
      align-items: center;
      position: relative;
      width: 100%;
      padding: 4px 10px 2px 20px;
      border: none;
      border: 1px solid #d9dfe7;
      border-radius: 8px;
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      :focus-visible {
        outline: none;
        border: 1px solid #006fff;
      }
      ::placeholder{
        color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
      }


    }
    svg {
      position: absolute;
      z-index: 1;
      top: 8, 5px;
      left: 7px;
    }
  }
`;
const DropDownMenuItem = styled.li`
background-color: ${({ theme }) => theme.editLeague.selectMenuBg};
  cursor: pointer;
  img {
    border-radius: 4px;
    height: 14px;
    width: 18px;
  }

  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;
  
  padding: 5px 10px;
  div {
    column-gap: 5px;
    align-items: center;
    display: flex;
    flex-direction: row;
    .check {
      margin-right: 5px;
      background: red;
      color: red;
    }
  }

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
  }
  
`;
