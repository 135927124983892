import React, { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../../assets/icons/xBtn.svg";
import { NumericFormat } from "react-number-format";
import bwin from "../../../assets/icons/bookmakers-small-icons/BETISN.svg";
import calendarIcon from "../../../assets/icons/BettingIcons/calendar.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/BettingIcons/calendar.svg";
import downIcon from "../../../assets/icons/BettingIcons/downArrowBlack.svg";
import { ReactComponent as DownIcon } from "../../../assets/icons/BettingIcons/downArrowBlack.svg";
import DatePicker from "react-datepicker";
import moment from "moment";
import { checkFlag, getCountryCode } from "../../../helper/Generic/GetFlag";
import { format_time_to_timezone } from "../../../helper/Generic/dateTimeFormat";
import { selectUser } from "../../../Redux/user/user";
import { useSelector } from "react-redux";
import { bookmakersList } from "../../../helper/Values/BookmakersList";
import { notifyError } from "../../../helper/Generic/Notifications";
import { SpinnerComponent } from "../../genericComponents/SpinnerComponent";
import { PulseLoader } from "react-spinners";

const CustomInput = ({
  title,
  name,
  value,
  handleInputChange,
  readOnly = false,
  placeHolder,
  toFixed = false,
}) => {
  let currentValue = value;

  if (toFixed) {
    currentValue = value?.toFixed(2);
  }

  return (
    <CustomInputWrapper>
      <span>{title}</span>
      <NumericFormat
        placeholder={placeHolder}
        disabled={readOnly}
        value={currentValue}
        onValueChange={(values, sourceInfo) =>
          handleInputChange(sourceInfo, values)
        }
        name={name}
      ></NumericFormat>
    </CustomInputWrapper>
  );
};

const BookmakerWithPrice = ({ bookmakerName, value }) => {
  let element = bookmakersList.find(
    (e) => e.bookmaker_backend_name == bookmakerName
  );

  if (!element) {
    return <div></div>;
  }

  return (
    <BookmakerElement>
      <img src={element.logo_small} />
      <NumericFormat disabled value={value.toFixed(2)} displayType="text" />
    </BookmakerElement>
  );
};

const ActiveUntilField = ({
  value,
  setValue,
  disabled,
  maximumDateAndTime,
}) => {
  let filterTime = (time) => {
    let maxTime = new Date(maximumDateAndTime);
    let selected = new Date(time);
    return maxTime.getTime() >= selected.getTime();
  };

  const handleSelect = (date) => {
    if (moment(date).format() <= maximumDateAndTime.format()) {
      setValue(date);
    } else {
      notifyError("Select date and time before starting time");
    }
  };

  const CustomInput = forwardRef(({ value, onClick, disabled, time }, ref) => (
    <CustomImputActiveUntil onClick={onClick} ref={ref} disabled={disabled}>
      {/* <img src={calendarIcon} alt='calendar' /> */}
      <CalendarIcon />
      <p>{moment(value).format("MMMM DD, YYYY")}</p>
      <p>{moment(value).format("HH:mm")}</p>
      <DownIcon />
      {/* <img src={downIcon} alt='calendar' /> */}
    </CustomImputActiveUntil>
  ));

  return (
    <CustomDateWrapper>
      <span>ACTIVE UNTIL</span>
      <div>
        <DatePicker
          disabled={disabled}
          selected={value}
          onChange={(date) => handleSelect(date)}
          showTimeInput
          dateFormat="yyyy-MM-dd HH:mm"
          customInput={<CustomInput />}
          maxDate={new Date(maximumDateAndTime)}
          filterTime={(date) => filterTime(date)}
          showTimeSelect
          timeIntervals={5}
          timeFormat="HH:mm"
        />
      </div>
    </CustomDateWrapper>
  );
};

const RsBetModal = ({
  displayRsModalHandler,
  data,
  handleSendRsBet,
  handleEditRsBet,
  editMode,
}) => {
  const initialValues = {
    modelPrice: {},
    marketPrice: {},
    stake: {},
    price: {},
  };
  let activeInitialDate = new Date(moment().add(1, "hour"));
  const user = useSelector(selectUser);
  const [activeUntil, setActiveUntil] = useState(activeInitialDate);
  const [countryCode, setCountryCode] = useState(null);
  const [values, setValues] = useState(initialValues);
  const [readyToSend, setReadyToSend] = useState(false);
  const [readyToEdit, setReadyToEdit] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const timezonedStartTime = format_time_to_timezone(
    data.bet.start_time,
    user.timezone
  );

  const handleInputChange = (event, val) => {
    if (!event.event) {
      return;
    }
    const name = event.event.target.name;
    const value = val;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSendRsButton = () => {
    setIsLoading(true);
    handleSendRsBet(
      data.bet.id,
      values.price.floatValue,
      values.stake.floatValue,
      activeUntil,
      function () {
        setIsLoading(false);
      },
      function () {
        setIsLoading(false);
        notifyError("Something Wrong");
      }
    );
  };

  const handleEditRsButton = () => {
    setIsLoading(true);
    handleEditRsBet(
      data.runshop.id,
      values.price.floatValue,
      values.stake.floatValue,
      activeUntil,
      function () {
        setIsLoading(false);
      },
      function () {
        setIsLoading(false);
        notifyError("Something Wrong");
      }
    );
  };

  const checkValidDate = () => {
    if (new Date(activeInitialDate) > new Date(timezonedStartTime)) {
      setActiveUntil(new Date(timezonedStartTime));
    }
  };
  const checkReadyToSend = () => {
    checkValidDate();

    if (values.stake.floatValue && values.price.floatValue) {
      setReadyToSend(true);
    } else {
      setReadyToSend(false);
    }
  };

  const activeUntilChanged = () => {
    let selected = moment(activeUntil).format();
    let betActiveUntil = moment(data.runshop.active_until).format();
    if (selected != betActiveUntil) {
      return true;
    } else {
      return false;
    }
  };

  const checkReadyToEdit = () => {
    if (
      (values.stake.floatValue &&
        values.price.floatValue &&
        (values.stake.floatValue != data.runshop.stake ||
          values.price.floatValue != data.runshop.price)) ||
      activeUntilChanged()
    ) {
      setReadyToEdit(true);
    } else {
      setReadyToEdit(false);
    }
  };

  useEffect(() => {
    if (editMode) {
      setActiveUntil(new Date(data.runshop.active_until));
      setValues({
        ...values,
        modelPrice: {
          floatValue: data.bet.model_price,
        },
        marketPrice: {
          floatValue: data.bet.market_price,
        },
        stake: {
          floatValue: data.runshop.stake,
          formattedValue: data.runshop.stake,
        },
        price: {
          floatValue: parseFloat(data.runshop.price),
          formattedValue: data.runshop.price,
        },
      });
    } else {
      setValues({
        ...values,
        modelPrice: {
          floatValue: data.bet.model_price,
        },
        marketPrice: {
          floatValue: data.bet.market_price,
        },
      });
    }
    getCountryCode(data.bet, setCountryCode);
  }, [data]);

  useEffect(() => {
    if (!editMode) {
      checkReadyToSend();
    }
  }, [values.stake, values.price]);

  useEffect(() => {
    if (editMode) {
      checkReadyToEdit();
    }
  }, [values.stake, values.price, activeUntil]);

  return (
    <FullScreenItem>
      <Card>
        <CloseBtnWrapper>
          <CloseButton onClick={displayRsModalHandler}>
            {" "}
            <CloseIcon />
          </CloseButton>
        </CloseBtnWrapper>
        <Content>
          {editMode ? (
            <HeaderWrapper>
              <h1>Edit RS Bet</h1>
              <EditButton
                disabled={!readyToEdit || isLoading}
                onClick={(e) => handleEditRsButton()}
              >
                Update Bet
              </EditButton>
            </HeaderWrapper>
          ) : (
            <HeaderWrapper>
              <h1>Send RS Bet</h1>
              <SendButton
                disabled={!readyToSend || isLoading}
                onClick={(e) => handleSendRsButton()}
              >
                Send RS
              </SendButton>
            </HeaderWrapper>
          )}

          <MatchInfo>
            <h1>
              {data.bet.home_team} - {data.bet.away_team}
            </h1>
            <Details>
              <p>
                {" "}
                <img src={checkFlag(countryCode)} alt="" />
                {data.bet.competition_name}{" "}
              </p>
              <p>{timezonedStartTime.format("DD/MM/YYYY")}</p>
              <p> {timezonedStartTime.format("HH:mm")}</p>
            </Details>
          </MatchInfo>
          <MatchPrices>
            <InputsWrapper>
              <CustomInput
                title={"MODEL PRICE"}
                readOnly
                name={"modelPrice"}
                value={values.modelPrice.floatValue}
                handleInputChange={handleInputChange}
                toFixed
              />
              <CustomInput
                title={"MARKET PRICE"}
                readOnly
                name={"marketPrice"}
                value={values.modelPrice.floatValue}
                handleInputChange={handleInputChange}
                toFixed
              />
              <CustomInput
                title={"STAKE"}
                placeHolder="Add stake"
                name={"stake"}
                value={values.stake.formattedValue}
                handleInputChange={handleInputChange}
              />
              <CustomInput
                title={"PRICE"}
                placeHolder="Add price"
                name={"price"}
                value={values.price.formattedValue}
                handleInputChange={handleInputChange}
              />
            </InputsWrapper>
            <BookmakersWrapper>
              {data?.markets?.map((item, index) => (
                <BookmakerWithPrice
                  key={index}
                  value={item.price}
                  bookmakerName={item.bookmaker_name}
                />
              ))}
            </BookmakersWrapper>
          </MatchPrices>
          <ActiveUntilField
            value={activeUntil}
            setValue={setActiveUntil}
            maximumDateAndTime={timezonedStartTime}
          />
        </Content>

        {isLoading && (
          <LoadingWrapper>
            <PulseLoader
              color={"#006fff"}
              loading={isLoading}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </LoadingWrapper>
        )}
      </Card>
    </FullScreenItem>
  );
};

export default RsBetModal;
const FullScreenItem = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(119, 124, 134, 0.5);
  justify-content: center;
  display: flex;
`;
const Card = styled.div`
  background: ${({ theme }) => theme.betting.rsBetModal.bg};
  width: 928px;
  height: 546px;
  position: sticky;
  top: 25vh;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;
const CloseBtnWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
  width: 100%;
  padding: 24px 33px;
  z-index: 5;
  svg {
    color: ${({ theme }) => theme.betting.rsBetModal.groupColor};
  }
`;
const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  h1 {
    color: ${({ theme }) => theme.betting.rsBetModal.groupColor};
    font-family: Roboto;
    font-size: 26px;

    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.26px;
    opacity: 0.8;
  }
`;
const SendButton = styled.button`
  cursor: pointer;
  background: none;
  color: #c165ed;
  border-radius: 4px;
  border: 1.5px solid #c165ed;
  padding: 5px 31px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`;
const EditButton = styled(SendButton)`
  background: var(--primaryBlue);
  color: white;
  border: none;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding: 0 50px;
`;
const MatchInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  h1 {
    color: ${({ theme }) => theme.betting.rsBetModal.teamsColor};
    font-family: Roboto;
    font-size: 20px;

    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
  }
`;
const Details = styled.div`
  display: flex;
  column-gap: 25px;
  align-items: center;

  img {
    widht: 19px;
    height: 14px;
  }
  p {
    border-radius: 2px;
    column-gap: 5px;
    align-items: center;
    display: flex;
    color: ${({ theme }) => theme.betting.rsBetModal.detailsColor};
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;
    opacity: 0.8;
    background: ${({ theme }) => theme.betting.rsBetModal.detailsBg};

    padding: 5px;
  }
`;
const MatchPrices = styled.div`
  display: flex;
  column-gap: 55px;
`;
const InputsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  position: relative;
  margin: -3px;
  padding: 3px;
  min-width: 380px;
  max-height: 180px;
  row-gap: 30px;
  column-gap: 20px;

  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const CustomInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-height: 68px;
  span {
    color: ${({ theme }) => theme.betting.rsBetModal.inputColor};
    font-family: Roboto;
    font-size: 12px;

    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.36px;
    text-transform: uppercase;
  }

  input {
    max-width: 180px;
    height: 40px;
    border-radius: 6px;
    border: 1px solid #bac3e8;
    background: ${({ theme }) => theme.betting.rsBetModal.inputBg};
    font-family: "Roboto";

    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.betting.rsBetModal.inputColor};
    padding: 10px 15px;
    ::placeholder {
      color: ${({ theme }) => theme.betting.rsBetModal.inputPlaceHolderColor};
    }
    :disabled {
      background: rgba(186, 195, 232, 0.2);
      border: 1px solid rgba(186, 195, 232, 0);
    }
  }
`;
const BookmakersWrapper = styled(InputsWrapper)``;
const BookmakerElement = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  img {
    width: 45px;
    height: 18px;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 6px;
    font-family: "Roboto";

    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.betting.rsBetModal.bookmakerColor};
    background: rgba(186, 195, 232, 0.2);
    padding: 0 18px;
  }
`;

const CustomDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: fit-content;

  span {
    color: ${({ theme }) => theme.betting.rsBetModal.activeUntilColor};
    font-family: Roboto;
    font-size: 12px;

    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.36px;
    text-transform: uppercase;
  }
`;
const CustomImputActiveUntil = styled.div`

display:flex;
align-items:center;
column-gap:15px;
min-height:21px;
padding:0 5px;
cursor:pointer;
border: ${(props) => (props.disabled ? "" : "1px solid #BAC3E8")};
border-radius: 10px;
background: ${({ theme }) => theme.betting.rsBetModal.inputBg};
padding:12px 20px;
p {

    color: ${({ theme }) => theme.betting.rsBetModal.activeUntilColor};
    font-family: Roboto;
    font-size: 14px;
    
    font-weight: 500;
    line-height: normal;
}

    svg{
        color: ${({ theme }) => theme.betting.rsBetModal.activeUntilColor};
    }
    

'
`;
const LoadingWrapper = styled.div`
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;
