import {
  percentageChange,
  findBookMakers,
  get_singbet_price,
} from "../ModelDataHelper";

export const mapParkedObject = (parkedBets, betsPrices) => {
  let listData = [];
  let data = {};

  parkedBets?.forEach((bet) => {
    let bookmakers = null;
    let singprice = null;

    if (betsPrices != undefined && betsPrices.length > 0) {
      let price = betsPrices?.find((element) => {
        return element.fixture_id === bet.fixture_id;
      });

      if (price !== null && price !== undefined) {
        let marketId = bet.market_id;
        bookmakers = findBookMakers(price.markets, marketId);
        singprice = get_singbet_price(price.markets, marketId);
      }
    }

    data = {
      away_team: bet.away_team,
      competition_name: bet.competition_name,
      country_name: bet.country_name,
      fixture_id: bet.fixture_id,
      home_team: bet.home_team,
      id: bet.id,
      market: bet.market,
      market_id: bet.market_id,
      min_price: bet.min_price,
      markets: bet.markets,
      model_price: bet.model_price,
      selection: bet.selection,
      start_time: bet.start_time,
      status: bet.status,
      target: bet.target,
      requested_date: bet.date,
      list_id: bet.list_id,
      parked_bet_id: bet.parked_bet_id,
      screen_limit: parseFloat(bet.screen_limit),
      bookmakers: null,
      market_value: null,
    };

    data.bookmakers = bookmakers;
    if (singprice != null) {
      const l_model_price = bet.model_price;
      const l_model_min_price = bet.min_price;

      data.market_price = parseFloat(singprice);

      const mkt_value = percentageChange(l_model_price, singprice);

      if (mkt_value != undefined) {
        data.market_value = parseFloat(mkt_value);
      }
    }
    listData.push(data);
  });
  return listData;
};
