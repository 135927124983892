import React, { Suspense, useEffect, useRef, useState } from "react";
import { PulseLoader } from "react-spinners";
import styled from "styled-components";
import BetsListElement from "../../components/bettingComponents/BetsList/BetsListElement";
import BetsListHeader from "../../components/bettingComponents/BetsList/BetsListHeader";
import ParkedBetsElement from "../../components/bettingComponents/BetsList/ParkedBetsElement";
import ParkedbetsHeader from "../../components/bettingComponents/BetsList/ParkedBetsHeader";
import NotePanel from "../../components/notes/NotePanel";
import { useBetsService } from "../../context/useBetsService";
import { useParkedBetsService } from "../../context/useParkedBetsService";
import { useRequestMenuService } from "../../context/useRequestMenuService";

import { same_time_in_timezone } from "../../helper/Generic/dateTimeFormat";

import { mapBetObject } from "../../helper/Betting/mapBets";
import { mapParkedObject } from "../../helper/Betting/mapParkedBets";
import { downloadBetsCSV } from "../../api/bets/DownloadBetsCsv";
import {
  notifyError,
  notifyLoading,
  notifySucces,
  notifyUpdate,
} from "../../helper/Generic/Notifications";

import {
  sortBetsByKey,
  sortRsBetsByBetKey,
} from "../../helper/Betting/sortBets";
import { useSelector } from "react-redux";
import { selectUser } from "../../Redux/user/user";
import { toast } from "react-toastify";
import { logBet } from "../../api/bets/logBet";
import fileDownload from "js-file-download";
import dayjs from "dayjs";
import RsBetsHeader from "../../components/bettingComponents/BetsList/RsBetsHeader";
import RsBetListElement from "../../components/bettingComponents/BetsList/RsBetListElement";
import RsBetModal from "../../components/bettingComponents/BetsList/RsBetModal";
import {
  calculateBetsPrices,
  mapBetsAndPrices,
} from "../../helper/Betting/betsPricingPricesHelper";
import { useRsBetsService } from "../../context/useRsBetsService";
import { mapRsObject } from "../../helper/Betting/mapRsBets";
import moment from "moment";
import WebSocketObserver from "../../helper/websocket/WebSocketObserver";
import { useWebSocketDataService } from "../../context/useWebSocketDataService";

const BetsListPage = () => {
  const user = useSelector(selectUser);
  const [isLoadingParked, setIsLoadingParked] = useState(true);
  const [isLoadingRs, setIsLoadingRs] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [rsBetModalEditMode, setRsBetModalEditMode] = useState(false);
  const [refreshRsListOnClose, setRefreshRsListOnClose] = useState(false);
  const {
    bets,
    betsPrices,
    brokers,
    canSendToRunshopList,
    getBets,
    getSendToRunshopList,
    getBetsPrices,
    getBetsListIds,
    getBrokers,
    setLatestListDate,
    fixturePrices,
    pricingPrices,
    bettingPricesWSHandler,
    pricingChannelResponsHandler,
  } = useBetsService();

  const { parkedBets, park_bet, unpark_bet, getPBets } = useParkedBetsService();
  const {
    rsBets,
    getRsBets,
    sendRsBet,
    updateRsBet,
    disableRsBet,
    runShopChannelHandler,
  } = useRsBetsService();
  const {
    searchInput,
    date,
    countriesIds,
    competitionsIds,
    tierIds,
    listId,
    priority,
    setListId,
    setDate,
  } = useRequestMenuService();
  const { add_observer, remove_observer } = useWebSocketDataService();

  const bettingMarketPriceObserver = new WebSocketObserver(
    "MarketBettingPricesChannel",
    (message) => {
      bettingPricesWSHandler(message);
    }
  );

  const pricingChannelbserver = new WebSocketObserver(
    "PricesChannel",
    (message) => {
      pricingChannelResponsHandler(message);
    }
  );

  const runShopChannelObserver = new WebSocketObserver(
    "RunshopChannel",
    (message) => {
      runShopChannelHandler(message);
    }
  );

  const [betsList, setBetsList] = useState([]);
  const [parkedBetsList, setParkedBetsList] = useState([]);
  const [rsBetsList, setRsBetsList] = useState([]);
  const [showNotes, setShowNotes] = useState(false);
  const [showRsModal, setShowRsModal] = useState(false);
  const [fixtureForConversation, setFixtureForConversation] = useState(null);
  const [lastAscendingBets, setLastAscendingBets] = useState(true);
  const [lastSortKeyBets, setLastSortKeyBets] = useState(null);
  const [lastAscendingParked, setLastAscendingParked] = useState(true);
  const [lastSortKeyParked, setLastSortKeyParked] = useState(null);
  const [lastSortKeyRsBets, setLastSortKeyRsBets] = useState(null);
  const [lastAscendingRsBets, setLastAscendingRsBets] = useState(true);
  const [selectedRsBet, setSelectedRsBet] = useState();
  const toastId = useRef(null);

  const fetchBrokers = () => {
    getBrokers(function () {
      notifyError("Something wrong...");
    });
  };

  const closeNote = () => {
    setShowNotes(false);
  };

  const handleNote = (fixture) => {
    setShowNotes(true);
    setFixtureForConversation(fixture);
  };

  const CreateBetsRows = () => {
    let bList = mapBetObject(bets, betsPrices, canSendToRunshopList);
    sortNewListIfNeeded(bList, setBetsList, lastSortKeyBets, lastAscendingBets);
  };

  const CreateParkedBetsRows = () => {
    let parketBList = mapParkedObject(parkedBets, betsPrices);
    sortNewListIfNeeded(
      parketBList,
      setParkedBetsList,
      lastSortKeyParked,
      lastAscendingParked
    );
  };

  const CreateRsBetsRows = () => {
    let rsBetsList = mapRsObject(rsBets, betsPrices);
    setRsBetsList(rsBetsList);
  };

  const sortBets = (ascending, filterKey) => {
    setLastAscendingBets(ascending);
    setLastSortKeyBets(filterKey);
    sortBetsByKey(betsList, filterKey, setBetsList, ascending);
  };

  const sortParkedBets = (ascending, filterKey) => {
    setLastAscendingParked(ascending);
    setLastSortKeyParked(filterKey);

    sortBetsByKey(parkedBetsList, filterKey, setParkedBetsList, ascending);
  };

  const sortRsBets = (ascending, filterKey) => {
    setLastAscendingRsBets(ascending);
    setLastSortKeyRsBets(filterKey);
    sortRsBetsByBetKey(rsBetsList, filterKey, setRsBetsList, ascending);
  };

  const sortNewListIfNeeded = (newList, setFunction, sortKey, ascending) => {
    if (sortKey != null) {
      sortBetsByKey(newList, sortKey, setFunction, ascending);
    } else {
      setFunction(newList);
    }
  };

  const handleGetBets = () => {
    // setBetsList([]);
    setIsLoading(true);
    let countries_ids = null;
    let start_time = null;
    let end_time = null;
    let competitions_ids = null;
    let term = null;
    let tier_filter = null;
    let priority_filter = null;

    if (countriesIds.length === 0) {
      countries_ids = null;
    } else {
      countries_ids = countriesIds;
    }
    if (competitionsIds.length === 0) {
      competitions_ids = null;
    } else {
      competitions_ids = competitionsIds;
    }
    if (searchInput === "") {
      term = null;
    } else {
      term = searchInput;
    }

    if (date !== null) {
      start_time = same_time_in_timezone(date[0], user.timezone)
        .startOf("day")
        .toDate();
      end_time = same_time_in_timezone(date[1], user.timezone)
        .endOf("day")
        .toDate();
    }

    if (tierIds == null) {
      tier_filter = null;
    } else {
      tier_filter = tierIds;
    }
    if (priority == null) {
      priority_filter = null;
    } else {
      priority_filter = priority;
    }

    getBets(
      countries_ids,
      start_time,
      end_time,
      competitions_ids,
      term,
      tier_filter,
      listId,
      priority_filter,
      function (response) {
        setIsLoading(false);

        handleGetSendToRsList(response);
      },
      function () {
        notifyError("Something wrong...");
        setIsLoading(false);
      }
    );

    // getBetsPrices(function () {
    //   notifyError("Something wrong...");
    // });
  };

  const handleGetSendToRsList = (betsToCheck) => {
    let list = [];
    betsToCheck?.forEach((bet) => {
      list.push(bet.id);
    });

    if (list.length > 0) {
      getSendToRunshopList(
        list,
        function (response) { },
        function (error) {
          notifyError("Something wrong");
        }
      );
    } else {
      setBetsList([]);
    }
  };

  const handleGetBetsPrices = () => {
    getBetsPrices(function () {
      notifyError("Something wrong...");
    });
  };

  const handleDownloadCSV = () => {
    let countries_ids = null;
    let start_time = null;
    let end_time = null;
    let competitions_ids = null;
    let term = null;
    let tier_filter = null;
    let priority_filter = null;
    notifyLoading(toastId, "Generating CSV");

    if (countriesIds.length === 0) {
      countries_ids = null;
    } else {
      countries_ids = countriesIds;
    }

    if (competitionsIds.length === 0) {
      competitions_ids = null;
    } else {
      competitions_ids = competitionsIds;
    }

    if (searchInput === "") {
      term = null;
    } else {
      term = searchInput;
    }

    if (tierIds == null) {
      tier_filter = null;
    } else {
      tier_filter = tierIds;
    }

    if (priority == null) {
      priority_filter = null;
    } else {
      priority_filter = priority;
    }

    if (date !== null) {
      start_time = same_time_in_timezone(date[0], user.timezone)
        .startOf("day")
        .toDate();
      end_time = same_time_in_timezone(date[1], user.timezone)
        .endOf("day")
        .toDate();
    }

    downloadBetsCSV(
      countries_ids,
      start_time,
      end_time,
      competitions_ids,
      term,
      tier_filter,
      listId,
      priority_filter,
      function (response) {
        notifyUpdate(toastId, "CSV Generated", toast.TYPE.SUCCESS);
        fileDownload(response.data, `${dayjs()}.csv`);
      },
      function () {
        notifyUpdate(toastId, "Failed to generate", toast.TYPE.ERROR);
      }
    );
  };

  const refreshParkedBets = () => {
    getPBets(
      function () {
        setIsLoadingParked(false);
      },
      function () {
        notifyError("Something wrong");
        setIsLoadingParked(false);
      }
    );
  };

  const handleGetBetsListIds = () => {
    let start_time = null;
    let end_time = null;
    if (date !== null) {
      start_time = same_time_in_timezone(date[0], user.timezone)
        .startOf("day")
        .toDate();
      end_time = same_time_in_timezone(date[1], user.timezone)
        .endOf("day")
        .toDate();
    }

    setIsLoading(true);
    getBetsListIds(start_time, end_time, function (response) {
      setIsLoading(false);
      if (response.list_ids.length > 0) {
        setListId(response.list_ids[0]);
        setLatestListDate(response.latest_list_date);
      } else {
        setListId(null);
      }
    }),
      function () {
        setIsLoading(false);
        notifyError("Something wrong");
      };
  };

  const handleParkBet = (id, no_move, no_price, screen_limit, markets) => {
    notifyLoading(toastId, "Parking bet...");
    park_bet(
      id,
      no_move,
      no_price,
      screen_limit,
      markets,
      function () {
        notifyUpdate(toastId, "Bet parked", toast.TYPE.SUCCESS);
        setIsLoadingParked(true);
        refreshParkedBets();
      },
      function () {
        notifyUpdate(toastId, "Failed to park bet", toast.TYPE.ERROR);
      }
    );
  };

  const handleLogBet = (
    parked_bet_id,
    broker,
    returned_stake,
    confirmed_price,
    clash,
    oppo,
    no_return,
    timeRet,
    time_req
  ) => {
    setIsLoadingParked(true);
    notifyLoading(toastId, "Logging bet...");

    logBet(
      parked_bet_id,
      broker,
      returned_stake,
      confirmed_price,
      clash,
      oppo,
      timeRet,
      no_return,
      time_req,
      function () {
        notifyUpdate(toastId, "Logged Bet", toast.TYPE.SUCCESS);
        refreshParkedBets();
        handleGetBets();
      },
      function () {
        setIsLoadingParked(false);
        notifyUpdate(toastId, "Failed to log bet", toast.TYPE.ERROR);
      }
    );
  };

  const handleUnparkBet = (parked_bet_id) => {
    setIsLoadingParked(true);
    notifyLoading(toastId, "Unparking bet...");

    unpark_bet(
      parked_bet_id,
      function () {
        notifyUpdate(toastId, "Unparked Bet", toast.TYPE.SUCCESS);
        refreshParkedBets();
      },
      function () {
        notifyUpdate(toastId, "Failed to unpark bet", toast.TYPE.ERROR);
        setIsLoadingParked(false);
        // notifyError("Failed to unpark bet");
      }
    );
  };

  const displayRsModalHandler = (item = null, edit = false) => {
    if (showRsModal) {
      setShowRsModal(!showRsModal);
    } else {
      if (edit) {
        setRsBetModalEditMode(true);
        setSelectedRsBet({
          ...item,
          runshop: item.runshop,
        });
      } else {
        setRsBetModalEditMode(false);
        setSelectedRsBet({
          bet: item,
          markets: item.markets,
          runshop: null,
        });
      }
      setShowRsModal(!showRsModal);
    }
  };

  //changing the date fetch the list id and then bets for the current date
  const getLatestBets = () => {
    const newDate = new Date();
    const newDateToday = new Date();
    const newDateTommorow = new Date(newDate.setDate(newDate.getDate() + 1));

    setDate([newDateToday, newDateTommorow]);
  };

  const handleGetPricingPrices = () => {
    fixturePrices(function () {
      notifyError("Something wrong");
    });
  };

  const handleSendRsBet = (
    bet_id,
    price,
    stake,
    active_until,
    onSuccess,
    onError
  ) => {
    // setIsLoadingRs(true);
    sendRsBet(
      bet_id,
      price,
      stake,
      active_until,
      function (response) {
        onSuccess();
        notifySucces("Rs Sent");
        displayRsModalHandler();
        refreshRsBets();
        handleGetBets();
      },
      function (error) {
        onError();
        setIsLoadingRs(false);
        notifyError("Something wrong");
      }
    );
  };
  const handleEditRsBet = (
    bet_id,
    price,
    stake,
    active_until,
    onSuccess,
    onError
  ) => {
    // setIsLoadingRs(true);
    updateRsBet(
      bet_id,
      price,
      stake,
      active_until,
      function (response) {
        onSuccess();
        notifySucces("Rs Sent");
        displayRsModalHandler();
        refreshRsBets();
      },
      function (error) {
        onError();
        setIsLoadingRs(false);
        notifyError("Something wrong");
      }
    );
  };

  const refreshRsBets = () => {
    setIsLoadingRs(true);
    getRsBets(
      function () {
        setIsLoadingRs(false);
      },
      function (error) {
        setIsLoadingRs(false);
        notifyError("Something wrong");
      }
    );
  };

  const handleDisableRsBet = (bet_id) => {
    setIsLoadingRs(true);
    disableRsBet(
      bet_id,
      function (response) {
        notifySucces("Rs Bet disabled");
        refreshRsBets();
      },
      function (error) {
        setIsLoadingRs(false);
        notifyError("Something Wrong");
      }
    );
  };

  const calculateActiveUntil = (activeUntil) => {
    if (!activeUntil) {
      return {
        days: null,
        hours: null,
        minutes: null,
      };
    }

    let currentTime = moment();
    let endTime = moment(activeUntil);

    let diff = endTime.diff(currentTime);
    let diffDuration = moment.duration(diff);

    let days = diffDuration.get("day");
    let hours = diffDuration.get("hour");
    let minutes = diffDuration.get("minutes");

    return {
      days: days,
      hours: hours,
      minutes: minutes,
    };
  };

  useEffect(() => {
    refreshParkedBets();
    fetchBrokers();

    refreshRsBets();
    handleGetBetsPrices();
    handleGetPricingPrices();
  }, []);

  useEffect(() => {
    // Only do the request if we have a list ID, otherwise we'll have duplicate requests
    if (!listId) {
      return;
    }

    handleGetBets();
  }, [countriesIds, competitionsIds, tierIds, priority, listId]);

  useEffect(() => {
    handleGetBetsListIds();
  }, [date]);

  useEffect(() => {
    CreateParkedBetsRows();
  }, [parkedBets]);

  useEffect(() => {
    CreateRsBetsRows();
  }, [rsBets])

  useEffect(() => {
    if (bets.length > 0) {
      CreateBetsRows();
    }
  }, [bets, canSendToRunshopList]);

  useEffect(() => {
    CreateBetsRows();
    CreateParkedBetsRows();
    CreateRsBetsRows();
  }, [betsPrices]);

  useEffect(() => {
    if (!pricingPrices || pricingPrices.length < 1) {
      return;
    }
    const newBets = mapBetsAndPrices(betsList, pricingPrices);
    const betsCalculatedValues = calculateBetsPrices(newBets);
    setBetsList(betsCalculatedValues);
  }, [pricingPrices]);

  useEffect(() => {
    add_observer(bettingMarketPriceObserver);
    add_observer(pricingChannelbserver);
    add_observer(runShopChannelObserver);
    return () => {
      remove_observer(bettingMarketPriceObserver);
      remove_observer(pricingChannelbserver);
      remove_observer(runShopChannelObserver);
    };
  }, []);

  return (
    <Container>
      <Main>
        <ParkedBetsSection>
          <ParkedbetsHeader sortParkedBets={sortParkedBets} />
          <ParkedBetsListSection>
            {isLoadingParked ? (
              <div className="betsLoader">
                <PulseLoader color="#006fff" />
              </div>
            ) : (
              parkedBetsList.map((item, index) => (
                <ParkedBetsElement
                  key={item.parked_bet_id}
                  data={item}
                  indexbg={index}
                  handleNote={handleNote}
                  setIsLoadingParked={setIsLoadingParked}
                  setIsLoading={setIsLoading}
                  handleLogBet={handleLogBet}
                  handleUnparkBet={handleUnparkBet}
                  brokers={brokers}
                />
              ))
            )}
          </ParkedBetsListSection>
        </ParkedBetsSection>


        <RsBetsSection>
          <RsBetsHeader
            sortRsBets={sortRsBets}
          />
          <RsBetsListSection>
            {isLoadingRs ? (<div className="betsLoader">
              <PulseLoader color="#006fff" />
            </div>) : (
              rsBetsList.map((item, index) => (
                <RsBetListElement
                  key={item.runshop.id}
                  data={item}
                  handleNote={handleNote}
                  handleDisableRsBet={handleDisableRsBet}
                  calculateActiveUntil={calculateActiveUntil}
                  displayRsModalHandler={displayRsModalHandler}

                />
              ))
            )}
          </RsBetsListSection>

        </RsBetsSection>

        <BetsSection>
          <BetsListHeader
            sortBets={sortBets}
            setIsLoading={setIsLoading}
            handleDownloadCSV={handleDownloadCSV}
            getLatestBets={getLatestBets}
            handleGetBets={handleGetBets}
          />
          <BetsListSection>
            {isLoading ? (
              <div className="betsLoader">
                <PulseLoader color="#006fff" />
              </div>
            ) : (
              betsList.map((item, index) => (
                <BetsListElement
                  key={item.id}
                  indexbg={index}
                  data={item}
                  handleNote={handleNote}
                  setIsLoadingParked={setIsLoadingParked}
                  setIsLoading={setIsLoading}
                  handleParkBet={handleParkBet}
                  displayRsModalHandler={displayRsModalHandler}
                />
              ))
            )}
          </BetsListSection>
        </BetsSection>
      </Main>
      {showNotes && (
        <NoteWrapper>
          <NotePanel closeNote={closeNote} fixture={fixtureForConversation} />
        </NoteWrapper>
      )}

      {showRsModal && (
        <RsBetModal
          displayRsModalHandler={displayRsModalHandler}
          data={selectedRsBet}
          handleSendRsBet={handleSendRsBet}
          handleEditRsBet={handleEditRsBet}
          editMode={rsBetModalEditMode}
        />
      )}
    </Container>
  );
};

export default BetsListPage;
const Container = styled.div`
  display: flex;
  width: 100%;
  min-width: 1600px;
  min-height: calc(100vh - 90px);
  background: ${({ theme }) => theme.matchElementBg};
  .betsLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
  }
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: calc(100vh - 90px);
`;
const NoteWrapper = styled.div`
  @media screen and (max-width: 1820px) {
    position: absolute;
    right: 10px;
    height: 100%;
  }
`;
const ParkedBetsListSection = styled.ul`
  width: 100%;
  min-width: 1600px;
  position: relative;
`;
const BetsListSection = styled.ul`
  width: 100%;
  min-width: 1600px;
`;
const ParkedBetsSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  .rc-time-picker-input {
    background-color: ${({ theme }) => theme.matchElementBg};
    color: ${({ theme }) => theme.bettTextColor};
  }
`;

const BetsSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
const RsBetsSection = styled(BetsSection)``;
const RsBetsListSection = styled(ParkedBetsListSection)``;
