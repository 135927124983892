import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CopyButtonSvg } from "../../../assets/icons/BettingIcons/duplicate.svg";
import { ReactComponent as NoteButtonSvg } from "../../../assets/icons/BettingIcons/notePencil.svg";
import { ReactComponent as DeleteButtonSvg } from "../../../assets/icons/BettingIcons/deleteBtnParkedBet.svg";
import BrokerMenu from "../Menu/BrokerMenu";
import TimePicker from "rc-time-picker";

import { useSelector } from "react-redux";
import { selectUser } from "../../../Redux/user/user";
import { checkFlag, getCountryCode } from "../../../helper/Generic/GetFlag";
import {
  format_time_to_timezone,
  same_time_in_timezone,
} from "../../../helper/Generic/dateTimeFormat";
import { copyToClipBoard } from "../../../helper/Generic/CopyInfo";
import { NumericFormat } from "react-number-format";
import DatePickerWithTime from "../Menu/DatePickerWithTime";

const ParkedBetsElement = ({
  indexbg,
  data,
  handleNote,
  handleLogBet,
  handleUnparkBet,
  brokers,
}) => {
  const initialValues = {
    clash: false,
    oppo: false,
    noreturn: false,
    returnedStake: {},
    confirmedPrice: {},
  };

  const user = useSelector(selectUser);
  const [readyToLog, setReadyToLog] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [countryCode, setCountryCode] = useState(null);

  const [currency, setCurrency] = useState("");
  const [broker, setBroker] = useState();

  const timezonedStartTime = format_time_to_timezone(
    data.start_time,
    user.timezone
  );
  const startTime = timezonedStartTime.format("HH:mm");
  const startDate = timezonedStartTime.format("YYYY-MM-DD");
  const timezonedRequestedTime = format_time_to_timezone(
    data.requested_date,
    user.timezone
  );

  // const requestedDate = timezonedRequestedTime.format("DD/MM/YYYY");
  // const requestedDateTime = timezonedRequestedTime.format("HH:mm");

  // const reqTest = timezonedRequestedTime.format("YYYY-MM-DD HH:mm:ss");

  const [timeRet, setTimeRet] = useState(
    format_time_to_timezone(new Date(), user.timezone)
  );
  const [reqTime, setReqTime] = useState(timezonedRequestedTime.toDate());

  const onTimeChange = (timeRet) => {
    setTimeRet(timeRet);
  };

  const handle_copy = () => {
    copyToClipBoard(
      data.home_team,
      data.away_team,
      data.competition_name,
      data.selection,
      data.min_price,
      data.country_name
    );
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name == "oppo") {
      setValues({
        ...values,
        [name]: checked,
        clash: false,
      });
    } else if (name == "clash") {
      setValues({
        ...values,
        [name]: checked,
        oppo: false,
      });
    } else {
      setValues({
        ...values,
        [name]: checked,
      });
    }
  };

  const handleInputChange = (event, val) => {
    if (!event.event) {
      return;
    }

    const name = event.event.target.name;
    const value = val;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const validatePosNumber = (value) => {
    if (!isNaN(value) === true) {
      if (parseFloat(value) > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const verifyReadyToLog = () => {
    let isRetStakeValid = validatePosNumber(values.returnedStake.value);
    let isConfirmedPriceValid = validatePosNumber(values.confirmedPrice.value);

    if (
      broker !== null &&
      broker !== undefined &&
      values.returnedStake.value !== "" &&
      values.confirmedPrice.value !== "" &&
      values.confirmedPrice.value >= 1 &&
      timeRet !== undefined &&
      timeRet !== null &&
      isRetStakeValid &&
      isConfirmedPriceValid
    ) {
      setReadyToLog(true);
    } else {
      setReadyToLog(false);
    }
  };

  const showReset = () => {
    return (
      <TimeResetBtn
        onClick={() =>
          setTimeRet(format_time_to_timezone(new Date(), user.timezone))
        }
      >
        Use current time
      </TimeResetBtn>
    );
  };

  const updateCurrencySign = () => {
    if (broker) {
      setCurrency(broker.currencySymbol);
    }
  };

  useEffect(() => {
    verifyReadyToLog();
  }, [broker, values, timeRet]);
  useEffect(() => {
    getCountryCode(data, setCountryCode);
  }, [data]);
  useEffect(() => {
    updateCurrencySign();
  }, [broker]);

  return (
    <Container indexbg={indexbg}>
      <ButtonCell>
        <button>
          <CopyButtonSvg onClick={() => handle_copy()} />
        </button>
      </ButtonCell>
      <TimeCell>
        <p>{startTime}</p>
        <p>{startDate}</p>
      </TimeCell>
      <GameCell>
        <h2>
          {data.home_team} - {data.away_team}
        </h2>
        <div
          style={{ display: "flex", columnGap: "5px", alignItems: "Center" }}
        >
          <GameCellCompetiton>
            <img src={checkFlag(countryCode)} alt="" />
            <p>{data.competition_name}</p>
          </GameCellCompetiton>
          <ListNo>
            <p>{data.list_id}</p>
          </ListNo>
        </div>
      </GameCell>
      <HighlitedCell>
        <p>{data.market}</p>
      </HighlitedCell>
      <SelectionCell>
        <p>{data.selection}</p>
      </SelectionCell>
      <Cell style={{ minWidth: "60px" }}>
        <NumericFormat
          value={data.target.toFixed(0)}
          displayType="text"
          suffix={" " + currency}
          thousandSeparator=","
          className="numberFormatter"
        />
      </Cell>
      <Cell>
        <p>{data.min_price.toFixed(2)}</p>
      </Cell>

      <Cell style={{ minWidth: "40px" }}>
        <p>{data.market_price}</p>
      </Cell>
      <ScreenLimit>
        <NumericFormat
          value={data.screen_limit}
          displayType="text"
          suffix={" " + currency}
          thousandSeparator=","
          className="numberFormatter"
        />
      </ScreenLimit>
      <Cell style={{ minWidth: "60px" }}>
        {data.market_value !== null && (
          <p
            className={
              data.market_value > 0 ? "positive-color" : "negative-color"
            }
          >{`${data.market_value.toFixed(2)}%`}</p>
        )}
      </Cell>
      <BrokerCell>
        <BrokerMenu brokers={brokers} setBroker={setBroker} broker={broker} />
      </BrokerCell>
      <TimeRequestCell>
        <DatePickerWithTime value={reqTime} setValue={setReqTime} />
      </TimeRequestCell>
      <ReturnedStakeCell>
        <NumericFormat
          value={values.returnedStake.formattedValue}
          name="returnedStake"
          onValueChange={(values, sourceInfo) =>
            handleInputChange(sourceInfo, values)
          }
          suffix={" " + currency}
          thousandSeparator=","
        />
      </ReturnedStakeCell>
      <ConfirmedStakeCell>
        <NumericFormat
          value={values.confirmedPrice.formattedValue}
          name="confirmedPrice"
          onValueChange={(values, sourceInfo) =>
            handleInputChange(sourceInfo, values)
          }
          thousandSeparator=","
        />
      </ConfirmedStakeCell>
      <BtnCell>
        <Switch
          size="small"
          name="clash"
          checked={values.clash}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </BtnCell>
      <BtnCell>
        <Switch
          size="small"
          name="oppo"
          checked={values.oppo}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </BtnCell>

      <TimeReturnedCell>
        <TimePicker
          value={timeRet}
          onChange={onTimeChange}
          showSecond={false}
          addon={() => showReset()}
        ></TimePicker>
      </TimeReturnedCell>

      <BtnCell>
        <Switch
          size="small"
          name="noreturn"
          checked={values.noreturn}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </BtnCell>
      <LogBtn>
        <button
          disabled={!readyToLog}
          onClick={() =>
            handleLogBet(
              data.parked_bet_id,
              broker.name,
              values.returnedStake.value,
              values.confirmedPrice.value,
              values.clash,
              values.oppo,
              values.noreturn,
              timeRet,
              reqTime
            )
          }
        >
          LOG
        </button>
      </LogBtn>
      <NotesBtn>
        <button>
          <NoteButtonSvg onClick={() => handleNote(data)} />
        </button>
      </NotesBtn>
      <DeleteBtn>
        <button>
          <DeleteButtonSvg
            onClick={() => handleUnparkBet(data.parked_bet_id)}
          />
        </button>
      </DeleteBtn>
    </Container>
  );
};

export default ParkedBetsElement;
const Container = styled.div`
  display: flex;
  padding: 6px 14px;
  background: ${(props) =>
    props.indexbg % 2
      ? ({ theme }) => theme.matchElementBgindexL
      : ({ theme }) => theme.matchElementBg};
  color: ${({ theme }) => theme.leagueColor};
  column-gap: 9px;
  align-items: center;

  .right {
    width: 100%;
    display: flex;
    column-gap: 14px;
  }
`;
const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  width: 100%;
  .positive-color {
    color: #23a760;
  }
  .negative-color {
    color: #ec4848;
  }
  p {
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }
  .numberFormatter {
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }
  button {
    cursor: pointer;
    background: none;
    border: none;
  }
`;
const HighlitedCell = styled(Cell)`
min-width:100px;
  p {
    padding: 3px 7px;
    background: var(--betsHeaderBg1);
    color: var(--elementHighlitedText);
    border-radius: 4px;
  }
`;
const ScreenLimit = styled(Cell)`
  min-width: 60px;
`;
const ButtonCell = styled(Cell)`
  min-width: 30px;
  max-width: 30px;
  svg {
    height: 20px;
    width: 20px;
  }
`;
const TimeCell = styled(Cell)`
  flex-direction: column;
  justify-content: start;
  width: 75px;
  p {
    color: ${({ theme }) => theme.leagueColor};
  }
`;
const GameCell = styled(Cell)`
  flex-direction: column;
  align-items: flex-start;
  min-width: 150px;
  row-gap: 2px;
  h2 {
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    color: ${({ theme }) => theme.bettTextColor};
  }
`;
const GameCellCompetiton = styled.div`
  background: ${({ theme }) => theme.gameCompetitionBg};
  border-radius: 2px;
  column-gap: 3px;
  padding: 3px 4px;

  p {
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: ${({ theme }) => theme.leagueColor};
  }
  display: flex;
  flex-direction: row;
  img {
    height: 10px;
    width: 13px;
  }
`;
const SelectionCell = styled(Cell)`
  min-width: 100px;
  justify-content: left;
  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
  }
`;


const TimeRequestCell = styled(Cell)`
  flex-direction: column;
  min-width: 60px;
  p {
    font-weight: 500;
    font-size: 8px;
    line-height: 150%;
    text-transform: uppercase;
  }
`;
const ReturnedStakeCell = styled(Cell)`
  min-width: 70px;
  input {
    color: ${({ theme }) => theme.leagueColor};
    background: ${({ theme }) => theme.matchElementBg};
    :focus-visible {
      outline: none;
    }
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    width: 100%;
    border: 0.7px solid #cbd7f5;
    text-align: center;
    border-radius: 2px;
    padding: 4px 9px;
  }
`;
const ConfirmedStakeCell = styled(Cell)`
  min-width: 90px;

  input {
    color: ${({ theme }) => theme.bettTextColor};
    background: ${({ theme }) => theme.matchElementBg};
    :focus-visible {
      outline: none;
    }
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    width: 100%;
    border: 0.7px solid #cbd7f5;
    text-align: center;
    border-radius: 2px;
    padding: 4px 9px;
  }
`;
const BtnCell = styled(Cell)`
  min-width: 51px;
  input {
    width: 100%;
  }
`;
const TimeReturnedCell = styled(Cell)`
  position: relative;
  flex-direction: column;
  min-width: 60px;
  .rc-time-picker-clear-icon:after {
    color: ${({ theme }) => theme.bettTextColor};
  }

  .rc-time-picker-panel-inner {
    background: green !important;
    box-shadow: 0 1px 5px red;
  }
`;

const LogBtn = styled(Cell)`
  min-width: 50px;
  button {
    :disabled {
      cursor: inherit;
      opacity: 0.5;
    }
    width: 100%;
    padding: 5px 8px;
    background: ${({ theme }) => theme.matchElementBg};
    border: 1px solid var(--primaryBlue);
    border-radius: 4px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: var(--primaryBlue);
  }
`;
const NotesBtn = styled(Cell)`
  min-width: 40px;
`;
const DeleteBtn = styled(Cell)`
  min-width: 50px;
  svg {
    fill: ${({ theme }) => theme.matchElementBg};
  }
`;
const BrokerCell = styled(Cell)`
  min-width: 70px;
`;
const TimeResetBtn = styled.button`
  background: var(--primaryBlue);
  color: white;
  border: none;
  width: 100%;
  padding: 10px 5px;
  font-family: "Roboto";
  
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
`;
const ListNo = styled.div`
  background: ${({ theme }) => theme.gameCompetitionListNoBg};
  border: 0.7px solid #e4e9f8;
  border-radius: 2px;
  padding: 3px 4px;
  p {
    font-family: "Roboto";
    
    font-weight: 500;
    font-size: 8px;
    line-height: 9px;
    text-align: center;
    letter-spacing: 0.04em;
    color: ${({ theme }) => theme.gameCompetitonColor};
  }
`;
