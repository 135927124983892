import { POST } from "../../../APiHelper/API"

let source;
export const searchCupRefereeAPI = (
    term,
    cupId,
    onSuccess,
    onError
) => {
    const data = {
        term: term
    }

    source = POST(`/api/v1/cups/${cupId}/search_referee`, data, onSuccess, onError, true, source)
}