

//set status menu  text color (className) for each  status

export const checkColor = (status) => {

    if (status != null) {
        if (status == "available") {
            return "available";
        }
        if (status == "injured") {
            return "injured";
        }
        if (status == "rested") {
            return "rested";
        }
        if (status == "bench") {
            return "bench";
        }
        if (status == "unavailable") {
            return "unavailable";
        }
        if (status == "n/a") {
            return "na";
        }
    }
};
