import { lookup } from "country-data";

export const getCountryCode = (selected_country, setCountryCode) => {
  if (selected_country.country_name === "England") {
    setCountryCode("GB");
  } else if (selected_country.country_name === "Holland") {
    setCountryCode("NL");
  } else if (selected_country.country_name === "Scotland") {
    setCountryCode("GB");
  } else if (selected_country.country_name === "Northern Ireland") {
    setCountryCode("GB");
  } else if (selected_country.country_name == "Bosnia Herzegovina") {
    setCountryCode("BA");
  } else if (selected_country.country_name == "Russia") {
    setCountryCode("RU");
  } else if (selected_country.country_name == "South Korea") {
    setCountryCode("KR");
  } else if (selected_country.country_name == "USA") {
    setCountryCode("US");
  } else {
    let country = lookup.countries({
      name: `${selected_country.country_name}`,
    })[0];

    if (country) {
      setCountryCode(country.alpha2);
    }
  }
};

export const checkFlag = (country_code) => {

  if (!country_code) {
    return
  }
  else return `https://flagcdn.com/${country_code?.toLowerCase()}.svg`
};
