//return specify className for each status

export const checkStatus = (item) => {
  const AVAILABLE = "available";
  const INJURED = "injured";
  const BENCH = "bench";
  const RESTED = "rested";
  const UNAVAILABLE = "unavailable";
  const NA = "n/a";

  const AVAILABLEGREEN = "available-green";
  const AVAILABLEORANGE = "available-orange";
  const status = item.status;
  const fatigue = item.fatigue;
  const selected_for_the_game = item.selected_for_the_game;
  const xi = item.xi;
  const ltf = item.long_term_formation;


  let returnedStatus = "";

  if (status != null) {
    if (status == AVAILABLE) {
      returnedStatus = AVAILABLE;
      if (returnedStatus == AVAILABLE && ltf) {
        returnedStatus += " ag-highlight";
      }
    }
    if (status == INJURED) {
      returnedStatus = INJURED;
      if (returnedStatus == INJURED && ltf) {
        returnedStatus += " injured-highlight";
      }
    }
    if (status == RESTED) {
      returnedStatus = RESTED;
      if (returnedStatus == RESTED && ltf) {
        returnedStatus += " rested-highlight";
      }
    }
    if (status == BENCH) {
      returnedStatus = BENCH;
      if (returnedStatus == BENCH && ltf) {
        returnedStatus += " bench-highlight";
      }
    }
    if (status == UNAVAILABLE) {
      returnedStatus = UNAVAILABLE;
      if (returnedStatus == UNAVAILABLE && ltf) {
        returnedStatus += " unav-highlight";
      }
    }
    if (status == NA) {
      returnedStatus = "na";
    }

    if (status == AVAILABLE && xi) {
      returnedStatus = AVAILABLEORANGE;
    }

    if (status == BENCH && xi) {
      returnedStatus = AVAILABLEORANGE;
    }

    if (status == AVAILABLE && selected_for_the_game) {
      returnedStatus = AVAILABLEGREEN;
    }

    var fatigueStatus = "";

    if (
      (returnedStatus == AVAILABLEGREEN ||
        returnedStatus == AVAILABLEORANGE ||
        returnedStatus == AVAILABLE) &&
      fatigue === -1
    ) {
      fatigueStatus += " fatigue1";
    }

    if (
      (returnedStatus == AVAILABLEGREEN ||
        returnedStatus == AVAILABLEORANGE ||
        returnedStatus == AVAILABLE) &&
      fatigue === -2
    ) {
      fatigueStatus += " fatigue2";
    }
    if (
      (returnedStatus == AVAILABLEGREEN ||
        returnedStatus == AVAILABLEORANGE ||
        returnedStatus == AVAILABLE) &&
      fatigue === -3
    ) {
      fatigueStatus += " fatigue3";
    }

    if (returnedStatus == AVAILABLEGREEN && ltf) {
      returnedStatus += " ag-highlight";
    }

    if (returnedStatus == AVAILABLEORANGE && ltf) {
      returnedStatus += " ao-highlight";
    }

    return returnedStatus + fatigueStatus;
  }
};
