import React from 'react'
import styled from 'styled-components'
import { ReactComponent as SeasonIcon } from '../../../assets/icons/LeaguesIcons/seasonIcon.svg'
import { ReactComponent as EditSvg } from "../../../assets/icons/LeaguesIcons/editIcon.svg"
const SeasonItem = ({ children, handleEditButton, season, archived }) => {
    return (
        <Container>
            <CupHeader>
                <Cell>
                    <Title>
                        <SeasonIcon />
                        <h1>{season?.name}</h1>
                    </Title>
                </Cell>
                {!archived && <Edit style={{ justifyContent: "end" }}>
                    <EditLeagueBtn onClick={() => handleEditButton(season.id)}>  <EditSvg />Edit Season</EditLeagueBtn>
                </Edit>}

            </CupHeader>
            <Content>{children}</Content>
        </Container>
    )
}

export default SeasonItem
const Container = styled.div`
display:flex;
flex-direction:column;
`

const CupHeader = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
padding:8px 14px;
min-height:40px;
background:${({ theme }) => theme.seasonElementHeaderBg};
width:100%;
`

const Title = styled.div`
display:flex;
aling-items:center;
gap:6px;
background:${({ theme }) => theme.seasonElementTitleBg};
border-radius:4px;
padding:6px 9px;

h1{
    font-size: 9px;
    font-weight: 700;
    line-height: 13px;
    color: white;
}
svg{

}
`
const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const Cell = styled.div`
display: flex;
column-gap:2px;
align-items: center;
min-width: 50px;
width:100%;
`
const Edit = styled.div`
display: flex;
column-gap:2px;
align-items: center;
min-width: 50px;
width:100%;
`;
const EditLeagueBtn = styled.button`
  cursor: pointer;
  display: flex;
  column-gap: 5px;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: var(--primaryBlue);
  padding: 4px 3px;
  border-radius: 3px;
  border: 1px solid  var(--primaryBlue);
  background: none;
`;
