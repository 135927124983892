import { POST } from "../APiHelper/API";

export const accept_invite = (
  password,
  invitation_token,
  name,
  timezone_name,
  onSuccess,
  onError
) => {
  const data = {
    password: password,
    client_id: process.env.REACT_APP_CLIENT_ID,
    invitation_token: invitation_token,
    name: name,
    timezone_name: timezone_name,
  };
  POST("/api/v1/users/accept_invite", data, onSuccess, onError);
};
