import { createSearchParams, useNavigate } from "react-router-dom";
import { bookmakersList } from "../../../helper/Values/BookmakersList";
import { useEffect, useState } from "react";
import styled from "styled-components";

const BookmakerIcon = ({ bookmaker, small, data }) => {

  const BookmakerPopup = ({ icon, price }) => {

    return (
      <BookmakerPopupwrapper mousePos={mousePos}>
        <img src={icon}></img>
        <h5>{price}</h5>
      </BookmakerPopupwrapper>
    );
  };
  const [show, setShow] = useState(false);
  const [mousePos, setMousePose] = useState({});
  const navigate = useNavigate();
  const handleClick = (element) => {
    // navigate(`/pricing/${element.bookmaker_id}`)
    navigate({
      pathname: "/pricing",
      search: createSearchParams({
        bookmakers: element.bookmaker_id,
      }).toString(),
    });
  };

  const handleShowPopup = (e) => {
    e.preventDefault();
    setShow(!show);

  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePose({ x: event.clientX, y: event.clientY });
    };
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  let element = bookmakersList.find(
    (e) => e.bookmaker_backend_name == bookmaker
  );

  if (!element) {
    return <div></div>;
  }

  if (small) {
    return (
      <img
        className="bookmaker-icon-small"
        src={element.logo_small}
        alt=""
        onClick={() => handleClick(element)}
      ></img>
    );
  }

  return (
    <div>
      <img
        onMouseOver={(e) => handleShowPopup(e)}
        onMouseLeave={() => setShow(false)}
        className="bookmaker-icon-betting"
        src={element.logo_small}
        alt=""
        onClick={() => handleClick(element)}
      ></img>
      {show && (
        <BookmakerPopup
          icon={element.logo_small}
          price={data.price}
          mousePos={mousePos}
        />
      )}
    </div>
  );
};

export const BookmakersElement = ({ bookmakersNames, small, marketsData }) => {
  if (!bookmakersNames) {
    return <div></div>;
  }

  // Sort the elements so we display them the same in all columns
  const sortedNames = bookmakersNames.sort((a, b) => (a < b ? 1 : -1));
  let icons = [];
  sortedNames.forEach((element, index) => {
    let bookmakerData = marketsData.filter(
      (item) => item.bookmaker_name == element
    );
    icons.push(
      <BookmakerIcon
        key={index}
        bookmaker={element}
        small={small}
        data={bookmakerData[0]}
      />
    );
  });

  return <div className="bookmakers-container">{icons}</div>;
};

const BookmakerPopupwrapper = styled.div`

  position: fixed;
  width: 114px;
  height: 63px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: white;
  top: ${(props) => props.mousePos.y}px;
  left: calc(${(props) => props.mousePos.x}px + 10px);
  padding: 5px 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;

  img {
    height: 18px;
    width: 45px;
  }

  h5 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3A3957;
    font-family: Roboto;
    font-size: 10px;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    width: 100%;
    min-height: 20px;
    border-radius: 2px;
    background: #ecf0fa;
  }
`;
