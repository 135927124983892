import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";
import styled from "styled-components";
import { ReactComponent as DownLightSvg } from "../../../assets/icons/downSimpleArrow.svg";
import { ReactComponent as SearchSvg } from "../../../assets/icons/search.svg";
import cloneDeep from "lodash.clonedeep";

const SeasonCupsMenu = ({ dataList, value, setValue }) => {
    const ref = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [filteredItems, setFilteredItems] = useState([])
    const SEASON = "season";
    const CUPS = "cups"
    const [expand, setExpand] = useState({
        seasons: false,
        cups: false,
    });
    const [searchValue, setSearchValue] = useState("");

    const handleExpand = (side, value) => {
        setExpand((prev) => {
            return {
                ...prev,
                [side]: value,
            };
        });
    };


    const handleItemClick = (item, type) => {
        let copy = cloneDeep(value);
        let seasonsCopy = copy.seasons;
        let cupsCopy = copy.cups;

        if (type === SEASON) {
            let found = copy.seasons.includes(item)
            if (found) {
                let filtered = seasonsCopy.filter((season) => season !== item)
                copy.seasons = filtered
            } else {
                copy.seasons.push(item)
            }
            setValue(copy)
        }

        if (type === CUPS) {
            let found = copy.cups.includes(item)
            if (found) {
                let filtered = cupsCopy.filter((cup) => cup !== item)
                copy.cups = filtered
            } else {
                copy.cups.push(item)
            }
            setValue(copy)
        }
    };

    const filterSearch = () => {
        let items = []
        let seasons = dataList.seasons.filter((item) => item.name.toLowerCase().includes(searchValue))
        let cups = dataList.cups.filter((item) => item.name.toLowerCase().includes(searchValue))
        seasons.map((item) => {
            items.push({
                ...item,
                type: SEASON
            })
        })
        cups.map((item) => {
            items.push({
                ...item,
                type: CUPS
            })
        })
        setFilteredItems(items)
    }
    const handleMenuOpen = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        } else {
            setIsMenuOpen(true);
        }
    };

    useEffect(() => {
        if (searchValue == "") {
            return
        }
        filterSearch();
    }, [searchValue])

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                handleMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    return (
        <Container ref={ref}>
            <DropdownMenu onClick={() => handleMenuOpen()} isMenuOpen={isMenuOpen}>
                <p>Seasons and Cups</p>
                {isMenuOpen ? <UpSvg /> : <DownSvg />}
            </DropdownMenu>
            {isMenuOpen && (
                <DropDownList>
                    <SearchGroup>
                        <SearchSvg />
                        <CustomSearch
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                    </SearchGroup>
                    {searchValue !== "" ?
                        <>
                            {filteredItems.map((item, key) => (
                                <DropDownMenuItem key={key} onClick={() => handleItemClick(item.id, item.type)}>
                                    <input
                                        type="checkbox"
                                        readOnly
                                        checked={item.type === SEASON ?
                                            value.seasons.includes(item.id) : value.cups.includes(item.id)}
                                    />
                                    <p>{item.name}</p>
                                </DropDownMenuItem>
                            ))}
                        </>

                        :
                        <>

                            <CategoryItem
                                key={2}
                                onClick={() => handleExpand("cups", !expand.cups)}
                            >
                                <p>Cups</p>
                                {expand.cups ? (
                                    <DownLightSvg style={{ rotate: "180deg" }} />
                                ) : (
                                    <DownLightSvg />
                                )}
                            </CategoryItem>
                            {expand.cups && (
                                dataList.cups?.map((cup, index) => (
                                    <DropDownMenuItem key={index} onClick={() => handleItemClick(cup.id, CUPS)}>
                                        <input
                                            type="checkbox"
                                            readOnly
                                            checked={value.cups.includes(cup.id)}
                                        />
                                        <p>{cup.name}</p>
                                    </DropDownMenuItem>
                                ))

                            )}

                            <CategoryItem
                                onClick={() => handleExpand("seasons", !expand.seasons)}
                            >
                                <p>Seasons</p>
                                {expand.seasons ? (
                                    <DownLightSvg style={{ rotate: "180deg" }} />
                                ) : (
                                    <DownLightSvg />
                                )}
                            </CategoryItem>

                            {expand.seasons && (
                                dataList.seasons?.map((season, index) => (
                                    <DropDownMenuItem key={index} onClick={() => handleItemClick(season.id, SEASON)}>
                                        <input
                                            type="checkbox"
                                            readOnly
                                            checked={value.seasons.includes(season.id)}
                                        />
                                        <p>{season.name}</p>
                                    </DropDownMenuItem>
                                ))

                            )}
                        </>
                    }

                </DropDownList>
            )}
        </Container>
    );
};

export default SeasonCupsMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  position: relative;
`;

const DropdownMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.menuBgColor};
  padding: 4px 10px;
  border-radius: 8px;
  border-radius: ${(props) => (props.isMenuOpen ? "8px 8px 0 0" : "")};
  min-height: 28px;

  p {
    margin-right: 5px;
    width: 100%;
    overflow: hidden;
    text-overflow: elipsis;
    white-space: nowrap;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: ${({ theme }) => theme.dropDownColor};
  }

  svg {
    cursor: pointer;
    fill: ${({ theme }) => theme.dropDownSvgFill};
    stroke: ${({ theme }) => theme.dropDownSvgStroke};
  }
`;

const DropDownList = styled.div`
  position: absolute;
  z-index: 3;
  top: 28px;
  border-top: 2px solid;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  min-height: 200px;
  max-height: 400px;
  width: 100%;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.menuBgColor};
  padding: 5px 10px;
  gap: 5px;
`;

const CategoryItem = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  height: 15px;
  cursor: pointer;
  p {
    font-family: Roboto;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    text-align: left;
    color: ${({ theme }) => theme.dropDownColor};
  }
  svg {
    color: ${({ theme }) => theme.dropDownSvgStroke};
  }
`;
const SearchGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 4px;
  border: 1px solid #d9dfe7;
  padding: 3px 5px;
`;
const CustomSearch = styled.input`
  border: none;
  background: none;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.dropDownColor};
width:100%;
  &:focus {
    outline: none;
  }
`;
const DropDownMenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 10px;
  cursor:pointer;
  p {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    color: ${({ theme }) => theme.dropDownColor};
  }
`;
