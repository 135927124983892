import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownSvg } from "../../../assets/icons/LeaguesIcons/down.svg";

const DerbyTeamsMenu = ({
  label,
  valuesList,
  value,
  derby,
  setDerby,
  index,
  teamToEdit,
}) => {
  const ref = useRef(null);
  const selectmenuref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedValueId, setSelectedValueId] = useState();

  const checKId = (value) => {
    if (value.team_1 != null && value.team_2 != null) {
      if (value.team_1.id == teamToEdit.id) {
        setSelectedValueId(value.team_2.id);
      }
      if (value.team_2.id === teamToEdit.id) {
        setSelectedValueId(value.team_1.id);
      }
    }
  };

  const withoutCurrentTeam = (team) => {
    return team.id != teamToEdit.id;
  };

  const handleChange = (item) => {
    setSelectedValueId(item);
    var updateList = [...derby];
    updateList[index].team_2 = {
      name: "",
      id: item,
    };
    setDerby(updateList);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    checKId(value);
  }, [value]);
  return (
    <Container ref={ref}>
      <Wrapper>
        <DownSvg />
        <SelectMenu
          value={selectedValueId}
          onChange={(e) => handleChange(e.target.value)}
          ref={selectmenuref}
        >
          {/* <DownSvg /> */}
          <SelectMenuItem value={null} label={"-"}></SelectMenuItem>
          {valuesList
            ?.sort((a, b) => (a.name > b.name ? 1 : -1))
            .filter(withoutCurrentTeam)
            .map((item, index) => (
              <SelectMenuItem value={item.id} key={index}>
                {item.name}
              </SelectMenuItem>
            ))}
        </SelectMenu>
      </Wrapper>
    </Container>
  );
};

export default DerbyTeamsMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;

  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
  }
`;

const SelectMenu = styled.select`
  background-color: ${({ theme }) => theme.editLeague.selectMenuBg};
  padding: 11px 15px;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  border-radius: ${(props) => (props.isMenuOpen ? "8px 8px 0 0" : "")};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 40px;
  background-position: 230px 10px;
  background-size: 16px;
  background-repeat: no-repeat;
  color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
  svg {
    fill: ${({ theme }) => theme.editLeague.selectMenuSvgBg};
    stroke: ${({ theme }) => theme.editLeague.selectMenuSvgColor};
  }
`;
const SelectMenuItem = styled.option``;
const Wrapper = styled.div`
  position: relative;
  svg {
    position: absolute;
    right: 10px;
    top: 12px;

    fill: ${({ theme }) => theme.editLeague.selectMenuSvgBg};
    stroke: ${({ theme }) => theme.editLeague.selectMenuSvgColor};
  }
`;
