import { createContext, useCallback, useContext } from "react";
import { getPlayerStats } from "../api/squadManager/getPlayerStats";
import { getSquadDetailsForFixture } from "../api/squadManager/getSquadDetailsForFixture";
import { getSquadsForTeam, getSquadsStatsForTeam } from "../api/squadManager/getSquadsForTeam";
import { saveNewSquadForTeam } from "../api/squadManager/saveNewSquadForTeam";
import { updateSelections } from "../api/squadManager/updateSelections";
import { editSelections } from "../api/squadManager/editSelections";
import { submitNewSquadForTeam } from "../api/squadManager/submitNewSquadForTeam"
import { getSquadAvailableForDataTransferApi } from "../api/squadManager/getSquadsAvailableForDataTransfer";
import { transferSquadAndGameDataApi } from "../api/squadManager/transferSquadAndGameData";
import { addPLayerFightForPositionAPI } from "../api/squadManager/fightForGroups/addPlayerFightForPosition";
import { removePLayerFightForPositionAPI } from "../api/squadManager/fightForGroups/removePlayerFightForPosition";
import { resetSquadAPI } from "../api/squadManager/resetSquad";

export const SquadManagerServiceContext = createContext({
  get_squads_for_team: () => { },
  get_squads_stats_team: () => { },
  save_new_squad_for_team: () => { },
  submit_new_squad_for_team: () => { },
  update_selection: () => { },
  edit_selections: () => { },
  get_player_stats: () => { },
  get_squad_details_for_fixture: () => { },
  get_squad_available_for_data_transfer: () => { },
  transfer_squad_and_game_data: () => { },
  remove_player_fightin_for_position: () => { },
  add_player_fightin_for_position: () => { },
  reset_squad: () => { }
});

const SquadManagerServiceProvider = ({ children }) => {
  const get_squads_for_team = useCallback(
    async (data, callbackFunction, callbackError) => {
      try {
        await getSquadsForTeam(
          data,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_squads_stats_team = useCallback(
    async (data, callbackFunction, callbackError) => {
      try {
        await getSquadsStatsForTeam(
          data,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_squad_details_for_fixture = useCallback(
    async (team_id, fixture_id, callbackFunction, callbackError) => {
      try {
        await getSquadDetailsForFixture(
          team_id,
          fixture_id,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const save_new_squad_for_team = useCallback(
    async (
      data,
      callbackFunction,
      callbackError
    ) => {
      try {
        await saveNewSquadForTeam(
          data,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const reset_squad = useCallback(
    async (
      data,
      callbackFunction,
      callbackError
    ) => {
      try {
        await resetSquadAPI(
          data,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const submit_new_squad_for_team = useCallback(
    async (
      data,
      callbackFunction,
      callbackError
    ) => {
      try {
        await submitNewSquadForTeam(
          data,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const update_selection = useCallback(
    async (
      data,
      callbackFunction,
      callbackError
    ) => {
      try {
        await updateSelections(
          data,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const edit_selections = useCallback(
    async (
      fixture_id,
      league_id,
      squad_id,
      formation,
      selections,
      callbackFunction,
      callbackError
    ) => {
      try {
        await editSelections(
          fixture_id,
          league_id,
          squad_id,
          formation,
          selections,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );




  const get_squad_available_for_data_transfer = useCallback(
    async (
      fixture_id,
      date,
      callbackFunction,
      callbackError,
    ) => {
      try {
        await getSquadAvailableForDataTransferApi(
          fixture_id,
          date,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const transfer_squad_and_game_data = useCallback(
    async (
      from_fixture_id,
      to_fixture_id,
      callbackFunction,
      callbackError,
    ) => {
      try {
        await transferSquadAndGameDataApi(
          from_fixture_id,
          to_fixture_id,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_player_stats = useCallback(
    async (player_id, callbackFunction, callbackError) => {
      try {
        await getPlayerStats(
          player_id,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const add_player_fightin_for_position = useCallback(
    async (
      squad_id,
      selection_id,
      position,
      callbackFunction,
      callbackError,
    ) => {
      try {
        await addPLayerFightForPositionAPI(
          squad_id,
          selection_id,
          position,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const remove_player_fightin_for_position = useCallback(
    async (
      playerInGroupId,
      callbackFunction,
      callbackError,
    ) => {
      try {
        await removePLayerFightForPositionAPI(
          playerInGroupId,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  return (
    <SquadManagerServiceContext.Provider
      value={{
        get_squads_for_team,
        get_squads_stats_team,
        save_new_squad_for_team,
        submit_new_squad_for_team,
        update_selection,
        get_player_stats,
        get_squad_details_for_fixture,
        edit_selections,
        get_squad_available_for_data_transfer,
        transfer_squad_and_game_data,
        remove_player_fightin_for_position,
        add_player_fightin_for_position,
        reset_squad,
      }}
    >
      {children}
    </SquadManagerServiceContext.Provider>
  );
};
export const useSquadManagerService = () =>
  useContext(SquadManagerServiceContext);
export default SquadManagerServiceProvider;
