import { mapTeamObjects } from "./mapTeams";

export const mapLeaguesObjects = (leagues) => {
  let listData = [];
  let data = {};

  leagues.forEach((league) => {
    data = {
      id: league.id,
      name: league.name,
      competition_id: league.competition_id,
      tier: league.tier,
      season_id: league.season_id,
      country: league.country,
      teams: mapTeamObjects(league.teams),
      referees: league.referees,
    };
    listData.push(data);
  });
  return listData;
};
