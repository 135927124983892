import {
  findMinPriceAndBookMakers,
  get_betting_price_for_logged_bets,
  percentageChange,
} from "../ModelDataHelper";

import {
  calculate_live_value_price,
  find_sing_market,
} from "./PricesHelper";

export const mapLoggedBets = (loggedBets, betsPrices) => {
  let listData = [];
  let data = {};

  loggedBets?.forEach((loggedBet) => {
    let bookmakers = [];
    let market_price = null;
    let model_price = null;
    let live_value = null;
    let live_sing_price = null;

    if (betsPrices != undefined && betsPrices.length > 0) {
      let price = betsPrices?.find((element) => {
        return element.fixture_id === loggedBet.bet.fixture_id;
      });

      if (
        price !== null &&
        price !== undefined &&
        price.markets &&
        price.markets !== undefined
      ) {
        let marketId = loggedBet.bet.market_id;
        market_price = get_betting_price_for_logged_bets(
          price.markets,
          marketId
        );

        const calculatedData = findMinPriceAndBookMakers(
          price.markets,
          marketId
        );

        bookmakers = calculatedData.bookmakers;
        model_price = loggedBet.bet.model_price;

        let sing_price = find_sing_market(
          price.markets,
          loggedBet.bet.market_id
        );
        if (sing_price != undefined) {
          live_sing_price = sing_price.price.valueOf();
        }
      }

      live_value = calculate_live_value_price(
        loggedBet.logged_bet.confirmed_price,
        loggedBet.bet.market_id,
        price
      );
    }

    data = {
      bet: {
        fixture_id: loggedBet.bet.fixture_id,
        country_name: loggedBet.bet.country_name,
        competition_name: loggedBet.bet.competition_name,
        home_team: loggedBet.bet.home_team,
        away_team: loggedBet.bet.away_team,
        start_time: loggedBet.bet.start_time,
        market_id: loggedBet.bet.market_id,
        model_price: loggedBet.bet.model_price,
        status: loggedBet.bet.status,
        id: loggedBet.bet.id,
        min_price: loggedBet.bet.min_price,
        target: loggedBet.bet.target,
        selection: loggedBet.bet.selection,
        market: loggedBet.bet.market,
        tier: loggedBet.bet.tier,
        bookmakers: null,
        mkt_value: null,
        value: null,
        live_value: null,
      },
      logged_bet: {
        id: loggedBet.logged_bet.id,
        broker: loggedBet.logged_bet.broker,
        ret_stake: parseFloat(loggedBet.logged_bet.ret_stake),
        gbp_ret_stake: parseFloat(loggedBet.logged_bet.gbp_ret_stake),
        confirmed_price: parseFloat(loggedBet.logged_bet.confirmed_price),
        clash: loggedBet.logged_bet.clash,
        oppo: loggedBet.logged_bet.oppo,
        time_ret: loggedBet.logged_bet.time_ret,
        no_return: loggedBet.logged_bet.no_return,
        parked_bet_id: loggedBet.logged_bet.parked_bet_id,
        fixture_id: loggedBet.logged_bet.fixture_id,
        time_req: loggedBet.logged_bet.time_req,
        liquidity: parseFloat(loggedBet.logged_bet.liquidity),
        incorrect: loggedBet.logged_bet.incorrect,
        abnormal: loggedBet.logged_bet.abnormal,
      },
      tier: {
        competition_tire: loggedBet.competition_tire,
        competition_division: loggedBet.competition_division,
      },
    };

    if (market_price != null) {
      data.bet.mkt_value = parseFloat(market_price);
    }

    const value = percentageChange(
      model_price,
      data.logged_bet.confirmed_price
    );

    if (value != null) {
      data.bet.value = parseFloat(value);
    }

    if (live_value != null) {
      data.bet.live_value = parseFloat(live_value);
    }

    if (live_sing_price != null) {
      data.bet.live_sing_value = live_sing_price;
    }

    listData.push(data);
  });

  return listData;
};
