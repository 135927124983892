import {
  playerImportanceSortingValue,
  playerPositionSortingValue,
  playerAttackDefenceRatingSortingValue,
} from "../PlayerHelper";
import cloneDeep from "lodash/cloneDeep";

import {
  ALL,
  ALL_POSITIONS,
  ATK_POSITIONS,
  ATTACK,
  BENCH,
  BENCH_POSITIONS,
  DEFENSIVE,
  DEF_POSITIONS,
  GK,
  GK_POSITIONS,
  MIDFIELD,
  MID_POSITIONS,
} from "../Values/TeamFormation";
import { sortLeaguesAz } from "../Analyst/LeaguesData/sortTeamsFromLeague";

const STARTING_CHANGES_PERCENTAGES = {
  nailed_on: 99,
  highly_likely: 90,
  likely: 75,
  "50-50": 50,
  unlikely: 25,
  highly_unlikely: 10,
  no_chance: 1,
};
const STARTING_CHANCES_VALUES = {
  nailed_on: 1,
  highly_likely: 0.9,
  likely: 0.75,
  "50-50": 0.5,
  unlikely: 0.25,
  highly_unlikely: 0.1,
  no_chance: 0,
};

const AVAILABLE_FATIGUE_SCORE = {
  0: {
    score: 0,
    textColor: "#47B77B",
    backgroundColor: "#E8FFF2",
  },
  "-1": {
    score: -1,
    textColor: "#E44EF0",
    backgroundColor: "#FDE5FF",
  },
  "-2": {
    score: -2,
    textColor: "#FF9C3E",
    backgroundColor: "#FFF3E7",
  },
  "-3": {
    score: -3,
    textColor: "#EC4848",
    backgroundColor: "#FFF5F5",
  },
};

export const calculateStartingChanceFromPlayers = (selection) => {
  if (!selection || selection.length == 0) {
    return 0;
  }

  var selectionScores = selection
    .filter((s) => s.status != null)
    .map((s) => STARTING_CHANCES_VALUES[s.starting_chance]);
  if (selectionScores.length > 0) {
    selectionScores = selectionScores.reduce((a, b) => a + b);
    return selectionScores;
  } else {
    return 0;
  }
};

export const sortPlayersBasedOnImportance = (players) => {
  const sortedList = cloneDeep(players).sort(function (a, b) {
    const injuredA = a.injured;
    const injuredB = b.injured;

    const injuredValue = injuredA == injuredB;

    const positionA = playerPositionSortingValue(a);
    const positionB = playerPositionSortingValue(b);

    const importanceA = playerImportanceSortingValue(a);
    const importanceB = playerImportanceSortingValue(b);

    const attackDefenceA = playerAttackDefenceRatingSortingValue(a);
    const attackDefenceB = playerAttackDefenceRatingSortingValue(b);

    const squadNumberA = a.squad_number;
    const squadNumberB = b.squad_number;

    return (
      injuredValue ||
      positionA - positionB ||
      importanceA - importanceB ||
      attackDefenceA - attackDefenceB ||
      squadNumberA - squadNumberB
    );
  });

  return sortedList;
};

export const sortPlayersBasedOnPositions = (players) => {
  const sortedList = cloneDeep(players).sort(function (a, b) {
    const positionA = playerPositionSortingValue(a);
    const positionB = playerPositionSortingValue(b);

    return positionA - positionB;
  });

  return sortedList;
};

const sortPlayersAz = (players) => {
  let sorted = players.sort((a, b) =>
    a.player.first_name > b.player.first_name ? 1 : -1
  );
  return sorted;
};

export const buildPlayersListForDropdown = (players, type) => {
  var playersToReturn = [];
  // Only players that are on bench or selected
  let playersSelectedForTheGame = filterInvalidPlayers(players);
  // filterPlayersThatWereSelectedForTheGame(players);

  // Expand the list of players to include all positions
  var expandedPlayersList = [];
  // all players with all positions
  playersSelectedForTheGame.forEach((element) => {
    var playersList = expandPlayerForPositions(element, ALL_POSITIONS);
    // Player has no position add it anyway
    if (playersList.length == 0) {
      playersList = [element];
    }

    expandedPlayersList = expandedPlayersList.concat(playersList);
  });

  // Sorted alphabetically
  expandedPlayersList = sortPlayersAz(expandedPlayersList);

  // Players that could play this specific position
  var playersForSpecificPositions = filterPlayersBasedOnTypeForExtendedList(
    expandedPlayersList,
    type
  );

  // Sorted alphabetically
  playersForSpecificPositions = sortPlayersAz(playersForSpecificPositions);

  // Remove the specific players from initial list
  let otherPlayersList = expandedPlayersList.filter(
    (player) => !existsSpecificPlayerInList(player, playersForSpecificPositions)
  );

  // Add the correct selected players first
  playersToReturn = playersForSpecificPositions.concat(otherPlayersList);

  // Remove duplicates
  playersToReturn = removePlayersDuplicate(playersToReturn);

  return playersToReturn;
};

export const existsSpecificPlayerInList = (player, players) => {
  var returnValue = false;
  players.forEach((element) => {
    if (
      element.position === player.position &&
      element.player.id === player.player.id
    ) {
      returnValue = true;
    }
  });

  return returnValue;
};

// Expands the players list to include all players with their positions
export const buildPlayersListForDropdown_old = (players, type) => {
  const sortedPlayers = bringBenchPlayersToFrontOfTheList(cloneDeep(players));
  const filteredPlayers = filterPlayersBasedOnType(sortedPlayers, type);
  const positions = playersPositionsForType(type);
  const allPositions = playersPositionsForType(ALL);

  var playersToReturn = [];

  //all players with all positions for specified type on line
  filteredPlayers.forEach((element) => {
    let playersList = expandPlayerForPositions(element, positions);
    playersToReturn = playersToReturn.concat(playersList);
  });

  let positionsToExclude = getPositionToExclude(playersToReturn);

  //all players with all positions (pos1,po2,po3)included position=null
  var allPlayersExpanded = [];
  sortedPlayers.forEach((element) => {
    let allPlayersExpandedList = expandPlayerForPositions(
      element,
      allPositions,
      positionsToExclude,
      true
    );
    allPlayersExpanded = allPlayersExpanded.concat(allPlayersExpandedList);
  });

  sortPlayersAz(allPlayersExpanded);

  return playersToReturn.concat(allPlayersExpanded);
};

export const filterPlayersBasedOnType = (playersList, type) => {
  const positions = playersPositionsForType(type);
  const filteredPlayers = playersList.filter((player) =>
    getPositionsForPlayer(player).some((p) => positions.includes(p))
  );

  return filteredPlayers;
};

export const filterInvalidPlayers = (playersList) => {
  const filteredPlayers = playersList.filter(
    (player) => player.player !== null
  );

  return filteredPlayers;
};

export const removePlayersDuplicate = (playersList) => {
  const uniquePlayers = playersList.filter((val, id, array) => {
    let index = array.findIndex(
      (x) => x.id === val.id && x.position === val.position
    );

    return index == id;
  });

  return uniquePlayers;
};

export const filterPlayersBasedOnTypeForExtendedList = (playersList, type) => {
  const positions = playersPositionsForType(type);
  const filteredPlayers = playersList.filter((player) =>
    positions.includes(player.position)
  );

  return filteredPlayers;
};

export const filterPlayersThatWereSelectedForTheGame = (playersList) => {
  const filteredPlayers = playersList.filter(
    (player) => player.xi === true || player.status === BENCH
  );

  return filteredPlayers;
};

export const filterPlayersWithNoPositions = (playersList) => {
  const filteredPlayers = playersList.filter(
    (player) => getPositionsForPlayer(player).filter((n) => n).length == 0
  );

  return filteredPlayers;
};

export const isPlayerDuplicateInPlayers = (player, playersList) => {
  if (!player?.id) {
    return false;
  }
  const filteredPlayers = playersList.filter((p) => p.id == player.id);

  return filteredPlayers.length > 1;
};

export const teamHaveDuplicatedPlayers = (team) => {
  let filteredValidItems = team.filter((item) => item.id)
  let mappedIds = filteredValidItems.map((item) => { return item.id })

  let duplicated = mappedIds.filter((item, index) => mappedIds.indexOf(item) != index);

  if (duplicated.length > 0) {
    return true
  } else {
    return false
  }
}

export const isPlayerChangedFromInitialSelection = (
  currentSelection,
  initialSelection,
  index
) => {
  if (currentSelection.length <= index) {
    return false;
  }

  const current = currentSelection[index];

  const initial = initialSelection.find((el) => {
    return el.player_id === current.player_id;
  });

  if (!current) {
    return false;
  }

  if (!initial) {
    return true;
  }

  return current.player_id != initial.player_id || current.xi != initial.xi;
};

export const shouldShowDuplicated = (
  currentSelection,
  playerChanged,
  index
) => {
  const current = currentSelection[index];

  let isValueEmpty;
  if (current.id != null) {
    isValueEmpty = false;
  } else {
    isValueEmpty = true;
  }

  return !isValueEmpty && playerChanged;
};
export const buildUpdatedSelectionWithNewBench = (oldSelection, newBench) => {
  const valuesOnly = cloneDeep(
    oldSelection.filter((el) => el.status !== BENCH)
  );

  const benchValues = cloneDeep(newBench);
  const newList = valuesOnly.concat(benchValues);

  return newList;
};

export const numberOfDifferentPlayersBetweenSelections = (
  currentSelection,
  initialSelection
) => {
  let differentPlayers = 0;

  currentSelection.forEach((element, index) => {
    const playerDifferent = isPlayerChangedFromInitialSelection(
      currentSelection,
      initialSelection,
      index
    );
    if (playerDifferent) {
      differentPlayers++;
    }
  });

  return differentPlayers;
};

export const getValidSelections = (selection) => {
  if (!selection) {
    return;
  }
  let notNullSelections = [];
  selection.forEach((item) => {
    if (item.id != null) {
      notNullSelections.push(item)
    }
  })

  return notNullSelections

}

export const hasDuplicatePlayers = (selection) => {
  if (!selection) {
    return;
  }
  let players = selection.filter((item) => item.player != null);
  let ids = players.map((item) => {
    return item.id;
  });
  let duplicates = ids.length !== new Set(ids).size;

  return duplicates;
};

export const playerStartingLikelihoodPercentage = (player) => {
  if (!player) {
    return null;
  }

  const percentage = STARTING_CHANGES_PERCENTAGES[player.starting_chance];

  return percentage;
};

export const getFatigueValues = (player) => {
  if (!player) {
    return null;
  }
  var fatigueScore = AVAILABLE_FATIGUE_SCORE[`${player.fatigue}`];

  return fatigueScore;
};

export const getSureFatigueValue = () => {
  return AVAILABLE_FATIGUE_SCORE["0"];
};

// Private methods

const playersPositionsForType = (type) => {
  if (type == GK) {
    return GK_POSITIONS;
  }

  if (type == DEFENSIVE) {
    return DEF_POSITIONS;
  }

  if (type == MIDFIELD) {
    return MID_POSITIONS;
  }

  if (type == ATTACK) {
    return ATK_POSITIONS;
  }

  if (type == ALL) {
    return ALL_POSITIONS;
  }

  if (type == BENCH) {
    return BENCH_POSITIONS;
  }
};

const bringBenchPlayersToFrontOfTheList = (players) => {
  const benchPlayers = players.filter((element) => element.status == BENCH);
  const otherPlayers = players.filter(
    (element) => benchPlayers.indexOf(element) < 0
  );

  return benchPlayers.concat(otherPlayers);
};

const getPositionsForPlayer = (player) => {
  if (!player || !player.player) {
    return;
  }

  let positions = [
    player.player?.position_1,
    player.player?.position_2,
    player.player?.position_3,
  ];

  return positions;
};

const expandPlayerForPositions = (
  player,
  allPositions,
  excludeList = [],
  allPosibilities = false
) => {
  if (!player.player) {
    return [];
  }

  const pos1 = player.player.position_1;
  const pos2 = player.player.position_2;
  const pos3 = player.player.position_3;

  let returnList = [];

  if (allPosibilities) {
    returnList = [
      player,
      playerForPosition(player, pos1, allPositions),
      playerForPosition(player, pos2, allPositions),
      playerForPosition(player, pos3, allPositions),
    ].filter((player) => player);
  } else {
    returnList = [
      playerForPosition(player, pos1, allPositions),
      playerForPosition(player, pos2, allPositions),
      playerForPosition(player, pos3, allPositions),
    ].filter((player) => player);
  }

  if (excludeList.length > 0) {
    let newList;
    newList = returnList.filter((item) => !excludeList.includes(item.position));

    return newList;
  }

  return returnList;
};

const playerForPosition = (player, position, allPositions) => {
  if (!position) {
    return null;
  }

  if (allPositions.indexOf(position) != -1) {
    let playerPos1 = cloneDeep(player);
    playerPos1.position = position;

    return playerPos1;
  }

  return null;
};

const getPositionToExclude = (playersList) => {
  let excludeList = playersList.map((item) => {
    if (item.position != null) return item.position;
  });
  let filteredExcludeList;
  filteredExcludeList = excludeList.filter((element, index) => {
    return excludeList.indexOf(element) == index;
  });

  return filteredExcludeList;
};
