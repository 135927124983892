import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { ReactComponent as LiveIcon } from "../assets/icons/liveStatus/liveIcon.svg";
import { ReactComponent as IssueIcon } from "../assets/icons/liveStatus/issueIcon.svg";
import { ReactComponent as Line } from "../assets/icons/liveStatus/line.svg";
import { ReactComponent as LiveButton } from "../assets/icons/liveButton.svg";
import { ReactComponent as LiveIssue } from "../assets/icons/liveIssue.svg";
import { getLiveStatus } from "../api/liveStatus/getLiveStatus";
import { useWebSocketDataService } from "../context/useWebSocketDataService";
import WebSocketObserver from "../helper/websocket/WebSocketObserver";
import {
  SystemStatusContext,
  useSystemStatus,
} from "../context/useSystemStatus";

const LivePopup = () => {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [status, setStatus] = useState(false);
  const [systems, setSystems] = useState([]);

  const { statusMessage } = useSystemStatus();

  const livestatusReceived = (data) => {
    if (data !== null && data !== undefined && data.length !== 0) {
      setStatus(data.live);
      setSystems(data.systems);
    }
  };

  const handleIsMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    livestatusReceived(statusMessage);
  }, [statusMessage]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref}>
      <LiveStatus onClick={() => handleIsMenuOpen()}>
        {status ? (
          <LiveButton style={{ color: "#23A760" }} />
        ) : (
          <LiveIssue style={{ color: "#EC4848" }} />
        )}
        <h2 className={status ? "" : "issue"}>
          {status ? "Live" : "Live issues"}
        </h2>
      </LiveStatus>
      {isMenuOpen && (
        <PopUp>
          <Content>
            <Row>
              <h1>SYSTEM</h1>
              <h1 style={{ marginRight: "10px" }}>STATUS</h1>
            </Row>
            <Line />

            {systems?.map((element, index) => (
              <Row key={index}>
                <h2>{element.name}</h2>
                {element.live == true ? <LiveIcon /> : <IssueIcon />}
              </Row>
            ))}
          </Content>
        </PopUp>
      )}
    </Container>
  );
};

export default LivePopup;
const Container = styled.div`
  position: relative;
  display: flex;
  z-index:3;
`;
const LiveStatus = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
  h2 {
    font-family: "Roboto";

    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #23a760;
  }
  .issue {
    color: #ec4848;
  }
`;
const PopUp = styled.div`
  position: absolute;
  z-index: 10;
  width: 186px;
  background-color: ${({ theme }) => theme.menuBgColor};
  box-shadow: 0px 4px 16px rgba(46, 46, 46, 0.12);
  border-radius: 10px;
  left: -125px;
  top: 30px;
  padding: 10px 15px;
`;
const Row = styled.div`
  margin-top: 7px;
  margin-bottom: 7px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    font-family: "Roboto";

    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: ${({ theme }) => theme.dropDownColor};
  }
  h2 {
    font-family: "Roboto";

    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    color: ${({ theme }) => theme.dropDownColor};
  }
`;
const Content = styled.div`
  position: relative;

  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
