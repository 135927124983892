import { POST } from "../APiHelper/API";

export const updateGameFactors = (
  data,
  onSuccess,
  onError
) => {
  // const data = {
  //   fixture_id: fixture_id,
  //   league_id: league_id,
  //   game_factors: game_factors,
  //   team_stats: team_stats,
  // };

  POST(`/api/v1/games/update_factors`, data, onSuccess, onError);
};
