import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownSvg } from "../../../assets/icons/downSimpleArrow.svg";


const InjuredMenu = ({ valuesList, selectedValue, setSelectedValue, width }) => {
    const ref = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleMenuOpen = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        } else {
            setIsMenuOpen(true);
        }
    };

    const handleCheck = (item) => {
        setSelectedValue(item);
        handleMenuOpen();
    };

    const parsevalue = (value) => {
        if (value == true) {
            return "Yes";
        }
        if (value == false) {
            return "No";
        }
    };


    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    return (
        <Container ref={ref} width={width}>
            <DropDownMenu
                onClick={() => handleMenuOpen()}
                isMenuOpen={isMenuOpen}
                width={width}
            >
                {selectedValue != undefined ? <p>{parsevalue(selectedValue)}</p> : <p>-</p>}
                {isMenuOpen ? <DownSvg style={{ rotate: "180deg" }} /> : <DownSvg />}
            </DropDownMenu>
            {isMenuOpen && (
                <DropDownList>
                    {valuesList.map((item, index) => (
                        <DropDownMenuItem
                            key={index}
                            onClick={() => handleCheck(item.value)}
                            className={item === selectedValue ? "active" : "nonActive"}
                        >
                            <input
                                key={index}
                                readOnly
                                onChange={() => handleCheck(item.value)}
                                className="check"
                                type="radio"
                                checked={selectedValue == item.value}
                                value={item.value}
                            ></input>
                            <p>{parsevalue(item.value)}</p>
                        </DropDownMenuItem>
                    ))}
                </DropDownList>
            )}
        </Container>
    );
};

export default InjuredMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width != null ? props.width : "90px")};
  position: relative;
  cursor:pointer;
`;
const DropDownMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 2px 5px;
  border: 1px solid #cbd7f5;
  border-radius: 2px;
  border-radius: ${(props) => (props.isMenuOpen ? "4px 4px 0 0" : "")};
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color:rgba(0, 18, 41, 0.8);
  }

`;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top: 20px;
 border-left:1px solid;
  border-bottom:1px solid;
  border-right:1px solid;
  border-color: #CBD7F5;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 4px 4px;
  max-height: 400px;
  width: 100%;
  overflow-x: hidden;

`;

const DropDownMenuItem = styled.li`
  cursor: pointer;
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
    :first-child{
    border-top: 1px solid black;
  }
  list-style-type: none;
  padding:2px 3px;
  .check {
    height: 10px;
  }
  background-color: #ffffff;
  align-items: center;
  display: flex;
  flex-direction: row;
  column-gap:2px;
  p {
    font-weight: 500;
    font-size: 8px;
    line-height: 8px;
    color:rgba(0, 18, 41, 0.8);
  }
  
`;
