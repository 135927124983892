import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DropdownMenuLeagues from "../../components/pricingComponents/Menu/DropDownMenuLeagues";
import dayjs from "dayjs";
import DropDownMenuCountry from "../../components/pricingComponents/Menu/DropDownMenuCountry";
import DropdownMenuMatches from "../../components/pricingComponents/Menu/DropdownMenuMatches";
import League from "../../components/pricingComponents/content/League";
import Match from "../../components/pricingComponents/content/Match";
import NotePanel from "../../components/pricingComponents/notes/NotePanel";
import LeagueGraph from "../../components/pricingComponents/graph/LeagueGraph";
import { ReactComponent as SearchSvg } from "../../assets/icons/PricingPageIcons/pricingSearch.svg";
import { ReactComponent as DownloadSvg } from "../../assets/icons/BettingIcons/downloadArrow.svg";
import { PulseLoader } from "react-spinners";
import {
  downloadCSV,
  downloadCSVFavorites,
} from "../../api/fixtures/DownloadCSV";
import fileDownload from "js-file-download";
import {
  notifyError,
  notifyLoading,
  notifyUpdate,
} from "../../helper/Generic/Notifications";
import { toast } from "react-toastify";
import DropDownMenuDateRangePicker from "../../components/pricingComponents/Menu/DropDownMenuDateRangePicker";
import { usePricingService } from "../../context/usePricingService";
import {
  getCompetitions,
  getCompetitionsForId,
  getCountries,
} from "../../helper/PricingHeaderMenuRequests";
import LivePopup from "../../components/LivePopup";

import { checkFlag } from "../../helper/Generic/GetFlag";
import {
  format_time_to_timezone,
  same_time_in_timezone,
} from "../../helper/Generic/dateTimeFormat";
import { useSelector } from "react-redux";
import { selectUser } from "../../Redux/user/user";
import DropDownBookmakers from "../../components/pricingComponents/Menu/DropDownBookmakers";
import { bookmakersList } from "../../helper/Values/BookmakersList";
import { useLocation, useSearchParams } from "react-router-dom";
import qs from "query-string";
import { useWebSocketDataService } from "../../context/useWebSocketDataService";
import WebSocketObserver from "../../helper/websocket/WebSocketObserver";

const Pricing = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    fixtures,
    setFixtures,
    getFixtures,
    fixturePrices,
    favoriteList,
    setFavoriteList,
    getFavoriteFixtures,
    competitionData,
    favoriteCompetitionData,
    websocketPricesReceived,
  } = usePricingService();
  const { add_observer, remove_observer } = useWebSocketDataService();

  const user = useSelector(selectUser);

  const observer = new WebSocketObserver("PricesChannel", (message) => {
    websocketPricesReceived(message)
  })

  const newDate = new Date();
  const [searchInput, setSearchInput] = useState("");
  const [countries, setCountries] = useState([]);
  const [allMatches, setAllMatches] = useState(true);
  const [date, setDate] = useState([newDate, newDate]);
  const [showNotes, setShowNotes] = useState(false);
  const [showLeagueGraph, setShowLeagueGraph] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [selectedCompetitions, setSelectedCompetitions] = useState([]);
  const [competitionsIds, setCompetitionIds] = useState([]);
  const [countriesIds, setCountriesIds] = useState([]);
  const [fixtureForConversation, setFixtureForConversation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [bookmakers, setBookmakers] = useState([]);

  // Select sing by default
  const [bookmakersIds, setBookmakersIds] = useState([282]);

  const toastId = useRef(null);
  const mList = [];

  const handleInputSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const _showLeagueGraph = () => {
    setShowLeagueGraph(true);
  };

  const closeLeagueGraph = () => {
    setShowLeagueGraph(false);
  };

  const handleNote = (fixture) => {
    setShowNotes(true);
    setFixtureForConversation(fixture);
  };

  const closeNote = () => {
    setShowNotes(false);
  };

  const handleDownloadCSV = () => {
    let countries_ids = null;
    let start_time = null;
    let end_time = null;
    let competitions_ids = null;
    let term = null;
    notifyLoading(toastId, "Generating CSV");
    if (countriesIds.length === 0) {
      countries_ids = null;
    } else {
      countries_ids = countriesIds;
    }

    if (competitionsIds.length === 0) {
      competitions_ids = null;
    } else {
      competitions_ids = competitionsIds;
    }

    if (searchInput === "") {
      term = null;
    } else {
      term = searchInput;
    }

    if (date !== null) {
      start_time = same_time_in_timezone(date[0], user.timezone)
        .startOf("day")
        .toDate();
      end_time = same_time_in_timezone(date[1], user.timezone)
        .endOf("day")
        .toDate();
    }

    if (allMatches === true) {
      downloadCSV(
        countries_ids,
        start_time,
        end_time,
        competitions_ids,
        term,
        bookmakersIds,
        function (response) {
          notifyUpdate(toastId, "CSV Generated", toast.TYPE.SUCCESS);
          fileDownload(response.data, `${dayjs()}.csv`);
        },
        function (error) {
          notifyUpdate(toastId, "Failed to generate", toast.TYPE.ERROR);
        }
      );
    }
    if (allMatches === false) {
      downloadCSVFavorites(
        countries_ids,
        start_time,
        end_time,
        competitions_ids,
        term,
        bookmakersIds,
        function (response) {
          notifyUpdate(toastId, "CSV Generated", toast.TYPE.SUCCESS);
          fileDownload(response.data, `Favorites ${dayjs()}.csv`);
        },
        function (error) {
          notifyUpdate(toastId, "Failed to generate", toast.TYPE.ERROR);
        }
      );
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSetSearchParams();
      handleFixtures();
    }
  };

  {
    /*Get fixture prices from server*/
  }

  const handleFixturePrices = () => {
    fixturePrices(function () {
      notifyError("Something wrong");
    });
  };

  {
    /*Get Fixtures/Favorites from server and Set */
  }

  const handleFixtures = () => {
    setIsLoading(true);
    if (favoriteList.length > 0) {
      setFavoriteList([]);
    }
    if (fixtures.length > 0) {
      setFixtures([]);
    }

    let countries_ids = null;
    let start_time = null;
    let end_time = null;
    let competitions_ids = null;
    let term = null;

    if (countriesIds.length === 0) {
      countries_ids = null;
    } else {
      countries_ids = countriesIds;
    }

    if (competitionsIds.length === 0) {
      competitions_ids = null;
    } else {
      competitions_ids = competitionsIds;
    }

    if (searchInput === "") {
      term = null;
    } else {
      term = searchInput;
    }

    if (date !== null) {
      start_time = same_time_in_timezone(date[0], user.timezone)
        .startOf("day")
        .toDate();
      end_time = same_time_in_timezone(date[1], user.timezone)
        .endOf("day")
        .toDate();
    }

    if (allMatches === true) {
      getFixtures(
        countries_ids,
        start_time,
        end_time,
        competitions_ids,
        term,
        function () {
          setIsLoading(false);
        },
        function () {
          notifyError("Something wrong");
        }
      );
    }
    if (allMatches === false) {
      getFavoriteFixtures(
        countries_ids,
        start_time,
        end_time,
        competitions_ids,
        term,
        function () {
          setIsLoading(false);
        },
        function () {
          notifyError("Something wrong");
        }
      );
    }
  };

  const verifyBookmakersParams = () => {
    if (location.search != "") {
      let params = qs.parse(location.search);
      let bookmakersId = params.bookmakers;

      bookmakersList.find((element) => {
        let found = element.bookmaker_id == bookmakersId;

        if (found) {
          setBookmakers([element]);
          setBookmakersIds([element.bookmaker_id]);
        }
      });
    }
  };

  const handleSetSearchParams = () => {
    let start;
    let end;

    if (date != null) {
      start = date[0].toISOString();
      end = date[1].toISOString();
    } else {
      start = null;
      end = null;
    }

    let params = qs.stringify({
      countryId: countriesIds,
      leagueId: competitionsIds,
      term: searchInput,
      start_date: start,
      end_date: end,
      all_matches: allMatches,
      bookmakers: bookmakersIds,
    });

    setSearchParams(params);
  };

  const initializeMenuValuesFromParams = () => {
    if (location.search != "") {
      let params = qs.parse(location.search);
      let term = params.term;
      let countryId = params.countryId;
      let leaguesId = params.leagueId;
      let end_date = params.end_date;
      let start_date = params.start_date;
      let bookmakers = params.bookmakers;
      let all_matches = params.all_matches;

      setSearchInput(term);
      if (all_matches) {
        setAllMatches(all_matches === "true");
      } else {
        setAllMatches(true);
      }

      if (start_date) {
        setDate([new Date(start_date), new Date(end_date)]);
      }

      if (Array.isArray(leaguesId)) {
        let values = leaguesId.map((item) => parseInt(item));
        setCompetitionIds(values);
      } else if (leaguesId?.length > 0) {
        setCompetitionIds([parseInt(leaguesId)]);
      }

      if (Array.isArray(countryId)) {
        let values = countryId.map((item) => parseInt(item));
        setCountriesIds(values);
      } else if (countryId?.length > 0) {
        setCountriesIds([parseInt(countryId)]);
      }

      if (Array.isArray(bookmakers)) {
        let values = bookmakers.map((item) => parseInt(item));
        setBookmakersIds(values);
      } else if (bookmakers?.length > 0) {
        setBookmakersIds([parseInt(bookmakers)]);
      }
    } else {
    }
  };

  useEffect(() => {
    if (countries.length === 0) {
      {
        /*GET countries for current user*/
      }
      getCountries(setCountriesList);
      {
        /*GET competitions for current user*/
      }
      getCompetitions(setCompetitions);
    }

    if (countries.length !== 0) {
      {
        /*GET competitions for selected country*/
      }
      getCompetitionsForId(countriesIds, (sorted) => {
        setCompetitionIds([]);
        setSelectedCompetitions([]);
        setCompetitions(sorted);
      });
    }
  }, [countries]);

  useEffect(() => {
    handleFixtures();
    handleFixturePrices();
    handleSetSearchParams();
  }, [countriesIds, competitionsIds, date, allMatches]);

  useEffect(() => {
    handleSetSearchParams();
  }, [countriesIds, competitionsIds, date, allMatches, bookmakersIds]);

  useEffect(() => {
    verifyBookmakersParams();
    initializeMenuValuesFromParams();

    add_observer(observer);
    return () => {
      remove_observer(observer)
    }

  }, []);

  return (
    <Container>
      <Main>
        <div className="stickTop">
          <Top>
            <Left>
              <div className="searchInput">
                <SearchSvg />
                <input
                  value={searchInput}
                  onChange={(e) => handleInputSearch(e)}
                  placeholder="Filter by keyboard"
                  onKeyDown={handleKeyDown}
                ></input>
              </div>
              <DropDownMenuCountry
                label={"Countries"}
                countriesList={countriesList}
                countries={countries}
                setCountries={setCountries}
                countriesIds={countriesIds}
                setCountriesIds={setCountriesIds}
              />
              <DropdownMenuLeagues
                label={"Leagues"}
                competitions={competitions}
                selectedCompetitions={selectedCompetitions}
                setSelectedCompetitions={setSelectedCompetitions}
                competitionIds={competitionsIds}
                setCompetitionIds={setCompetitionIds}
              />
              <DropdownMenuMatches
                label={"All matches"}
                allMatches={allMatches}
                setAllMatches={setAllMatches}
              />
              <DropDownMenuDateRangePicker
                label={"Date"}
                selectedValue={date}
                setSelectedValue={setDate}
              />
              <DropDownBookmakers
                label={"Bookmakers"}
                bookmakersList={bookmakersList}
                bookmakers={bookmakers}
                setBookmakers={setBookmakers}
                bookmakersIds={bookmakersIds}
                setBookmakersIds={setBookmakersIds}
              />
            </Left>
            <Right>
              <LiveBtn>
                <LivePopup />
              </LiveBtn>

              <CsvBtn onClick={() => handleDownloadCSV()}>
                <DownloadSvg />
                CSV
              </CsvBtn>
            </Right>
          </Top>

          <Top2>
            <div className="t2lft">
              <h2>MODEL PRICING</h2>
            </div>
            <div className="t2rght">
              <h2>MARKET PRICING</h2>
            </div>
          </Top2>
        </div>

        <Content>
          {isLoading && (
            <div className="loader">
              <PulseLoader color="#006fff" />
            </div>
          )}
          {allMatches ? (
            <>
              {/* <CreateLeaguesAndRows fixtures={fixtures} /> */}
              {competitionData.map((element, leagueIndex) => (
                <League
                  name={element.competition.competition}
                  flag={checkFlag(element.country.country_code)}
                  country={element.country}
                  key={leagueIndex}
                  _showLeagueGraph={_showLeagueGraph}
                >
                  {element.fixtures.map((item, index) => (
                    <Match
                      key={index}
                      bg={index}
                      handleNote={handleNote}
                      fixtureData={item}
                      bookmakersIds={bookmakersIds}
                    />
                  ))}
                </League>
              ))}
            </>
          ) : (
            <>
              {favoriteCompetitionData.map((element, leagueIndex) => (
                <League
                  name={element.competition.competition}
                  flag={checkFlag(element.country.country_code)}
                  country={element.country}
                  key={leagueIndex}
                  _showLeagueGraph={_showLeagueGraph}
                >
                  {element.fixtures.map((item, index) => (
                    <Match
                      key={index}
                      bg={index}
                      handleNote={handleNote}
                      fixtureData={item}
                      bookmakersIds={bookmakersIds}
                    />
                  ))}
                </League>
              ))}
            </>
          )}
        </Content>
      </Main>

      {showNotes && (
        <NoteWrapper>
          <NotePanel closeNote={closeNote} fixture={fixtureForConversation} />
        </NoteWrapper>
      )}

      {showLeagueGraph && <LeagueGraph closeLeagueGraph={closeLeagueGraph} />}
    </Container>
  );
};

export default Pricing;
const Container = styled.div`
  display: flex;
  min-width: 1600px;
  width: 100%;
  min-height: var(--pageHeight);
  background-color: ${({ theme }) => theme.leagueBG};
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
`;
const NoteWrapper = styled.div`
  @media screen and (max-width: 1820px) {
    position: absolute;
    right: 10px;
    height: 100%;
  }
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .stickTop {
    position: sticky;
    position: -webkit-sticky;
    top: 45px;
    z-index: 1;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Top = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.headerBG};
  justify-content: space-between;
  padding: 10px 25px;
`;
const Top2 = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.matchElementBg};
  width: 100%;
  color: ${({ theme }) => theme.leagueColor};
  position: sticky;
  position: -webkit-sticky;
  top: 65px;
  .t2lft {
    padding: 10px 7px;
    width: 100%;
    border-right: 2px solid ${({ theme }) => theme.middleBorder};

    h2 {
      display: flex;
      justify-content: center;
      aling-items: center;
      width: 100%;
      font-weight: 600;
      font-size: 10px;
    }
  }
  .t2rght {
    aling-items: center;
    padding: 10px 7px;
    width: 100%;
    h2 {
      display: flex;
      justify-content: center;
      aling-items: center;
      width: 100%;
      font-weight: 600;
      font-size: 10px;
    }
  }
`;

const Left = styled.div`
  display: flex;

  column-gap: 5px;
  color: ${({ theme }) => theme.menuTextColor};
  .searchInput {
    position: relative;
    svg {
      color: ${({ theme }) => theme.menuTextColor};
      position: absolute;
      top: 9px;
      left: 10px;
    }
    input {
      min-width: 242px;
      color: ${({ theme }) => theme.menuTextColor};
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      ::placeholder {
        color: ${({ theme }) => theme.menuTextColor};
      }
      outline: none;
      border: none;
      :focus-visible {
        border: none;
        outline: none;
      }
      padding: 5px 10px 3px 26px;
      background: ${({ theme }) => theme.menuBgColor};
      border: none;
      border-radius: 8px;
    }
  }
`;

const Right = styled.div`
  display: flex;
  column-gap: 15px;
`;
const CsvBtn = styled.button`
  cursor: pointer;
  border-radius: 4px;
  column-gap: 5px;
  padding: 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  background-color: ${({ theme }) => theme.buttonBG};
  color: ${({ theme }) => theme.buttonTextColor};
`;
const LiveBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  border: none;
  color: #ec4848;
  column-gap: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`;
