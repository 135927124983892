/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { YesNo } from "../../helper/Values/TeamFormation";

import StadiumMenu from "./Menu/StadiumMenu";
import YesNoMenu from "./Menu/YesNoMenu";
import ReadOnlyField from "./ReadOnlyField";
import { ReactComponent as PrecipitationIcon } from "../../assets/icons/LeaguesIcons/cloudIcon.svg";
import { ReactComponent as HumidityIcon } from "../../assets/icons/LeaguesIcons/humidityIcon.svg";
import { ReactComponent as TemperatureIcon } from "../../assets/icons/LeaguesIcons/temperatureIcon.svg";
import { ReactComponent as AltitudeIcon } from "../../assets/icons/LeaguesIcons/altitudeIcon.svg";
import { ReactComponent as WindIcon } from "../../assets/icons/LeaguesIcons/windIcon.svg";
import SingleOptionMenu from "./Menu/SingleOptionMenu";
import IntensityField from "./IntensityFiel";
import TeamVsTeam from "./TeamVsTeam";
import PitchConditionMenu from "./Menu/PitchConditionMenu";
import PitchConditionStatistics from "./Menu/PitchConditionStatistics";
import { useLeaguesDataService } from "../../context/useLeaguesDataService";
import { useMatchPreviewService } from "../../context/useMatchPreviewService";
import { notifyError, notifyInfoNoClose } from "../../helper/Generic/Notifications";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { selectUser } from "../../Redux/user/user";
import { useSelector } from "react-redux";
import qs from "query-string";
import NeutralVenueMenue from "./Menu/NeutralVenueMenu";
import PostponedMatchConfirmation from "./../PostponedMatchConfirmation";

const MatchFactors = ({
  setSelectedFixture,
  selectedFixture,
  refereesList,
  currentLeague,
  currentCup,
  setIsLoading,
  handleGetGames,
  statusCompleted,
  setResponseFromPartialFactorUpdate,
  stadiums,
  navigateToSquadManager,
}) => {
  const {
    get_wyScout_id,
    get_stadium_weather,
    get_pitch_condition,
    update_game_factors,
    update_game_factors_partial,
    set_game_postponed,
    set_game_cancelled,
  } = useMatchPreviewService();

  const [homeTeam, setHomeTeam] = useState(null);
  const [homeTeamStats, setHomeTeamStats] = useState(null);
  const [awayTeam, setAwayTeam] = useState(null);
  const [awayTeamStats, setAwayTeamStats] = useState(null);
  const [selectedReferee, setSelectedReferee] = useState(null);
  const [selectedStadium, setSelectedStadium] = useState(null);
  const [weather, setWeather] = useState(null);
  const [pitchCondition, setPitchConditions] = useState(null);
  const [pitchConditionsHistory, setPitchConditionsHistory] = useState([]);
  const [naturalVenue, setNaturalVenue] = useState(false);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [readyToSubmitHome, setReadyToSubmitHome] = useState(false);
  const [readyToSubmitAway, setReadyToSubmitAway] = useState(false);
  const [readyToSubmitFactors, setReadyToSubmitFactors] = useState(false);
  const [factorsFieldsFilled, setFactorsFieldsFilled] = useState(false);
  const [sharedStadium, setSharedStadium] = useState();
  const [wyscoutId, setWyscoutId] = useState("");

  const [derby, setDerby] = useState(null);
  const shouldUpdate = useRef(null);
  const [postponedMatchConfirmation, setPostponedMatchConfirmation] =
    useState(false);

  const notifyNoCloseId = useRef();
  const shouldRefreshHomeStats = useRef(false);
  const shouldRefreshAwayStats = useRef(false);

  const setInitialValues = (fixture) => {
    if (!fixture) {
      setSelectedReferee(null);
      setSelectedStadium(null);
      setWeather(null);
      setPitchConditions(null);
      setReadyToSubmitFactors(false);
      setNaturalVenue(false);
      setPitchConditionsHistory(null);
      setDerby(null);
    }

    setSelectedReferee(fixture.referee);
    setSelectedStadium(fixture.stadium);
    setWeather(null);
    setPitchConditions(fixture.pitch_condition);
    setReadyToSubmitFactors(fixture.ready);
    setPitchConditionsHistory([]);
    setDerby(fixture.derby);
    checkNaturalVenue(fixture);
  };

  const checkNaturalVenue = (fixture) => {
    if (!fixture) {
      return;
    }

    let selectedStadium = fixture.stadium;
    let team1Stadium = fixture.team1.stadium;
    let team2Stadium = fixture.team2.stadium;

    if (
      selectedStadium?.id != team1Stadium?.id &&
      selectedStadium?.id != team2Stadium?.id
    ) {
      setNaturalVenue(true);
    } else {
      setNaturalVenue(false);
    }
  };

  const getTeamStats = (team) => {
    let teams = selectedFixture.team_stats;
    let found = teams?.find((item) => item.team_id == team.id);
    return found;
  };

  const handleTeamStats = (teamStats, teamId) => {
    if (teamStats) {
      var reworkedStat = teamStats;
      // Some data is corupt, so this to be reworked
      if (reworkedStat.ready && reworkedStat.predicted_attendance == null) {
        reworkedStat.predicted_attendance = 0;
        return reworkedStat;
      }

      return teamStats;
    }

    let newStats = {
      attack_edge: null,
      defence_edge: null,
      match_importance: null,
      must_not_lose: null,
      must_win: null,
      predicted_attendance: null,
      ready: null,
      team_id: teamId,
    };
    return newStats;
  };

  const setTeams = (fixture) => {
    if (!fixture) {
      return;
    }

    let team1stats;
    let team2stats;

    team1stats = handleTeamStats(getTeamStats(fixture.team1), fixture.team1.id);
    team2stats = handleTeamStats(getTeamStats(fixture.team2), fixture.team2.id);

    if (fixture?.fixture?.participant1is_home) {
      setHomeTeam(fixture.team1);
      setAwayTeam(fixture.team2);
      if (team1stats && team2stats) {
        setHomeTeamStats(team1stats);
        setAwayTeamStats(team2stats);
      } else {
        setHomeTeamStats(null);
        setAwayTeamStats(null);
      }
    } else {
      setHomeTeam(fixture.team2);
      setAwayTeam(fixture.team1);
      if (team1stats && team2stats) {
        setHomeTeamStats(team2stats);
        setAwayTeamStats(team1stats);
      } else {
        setHomeTeamStats(null);
        setAwayTeamStats(null);
      }
    }
  };

  const handleNaturalVenueChange = (value) => {
    if (!selectedFixture) {
      return;
    }
    if (value == false) {
      setSelectedStadium(selectedFixture.stadium);
    }
  };

  const getStadiumWeather = (fixture) => {
    if (!fixture) {
      return;
    }
    if (!selectedStadium) {
      return;
    }
    setIsLoading(true);
    get_stadium_weather(
      selectedStadium.id,
      fixture.fixture.fixture_id,
      function (response) {
        setWeather(response.result);
        setIsLoading(false);
      },
      function (error) {
        notifyError("Something wrong");
        setIsLoading(false);
      }
    );
  };

  const getPitchConditionForStadium = (stadium) => {
    if (!stadium) {
      return;
    }

    get_pitch_condition(
      stadium.id,
      function (response) {
        setPitchConditionsHistory(response.result);
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };

  const getWyScoutId = (fixture_id) => {
    if (!fixture_id) {
      return;
    }
    get_wyScout_id(
      fixture_id,
      function (response) {
        setWyscoutId(response.wyscout_id);
      },
      function (error) {
        console.log("err", error);
        notifyError("Something Wrong");
      }
    );
  };

  const handleChangeHomeTeamStats = (id, field, value) => {
    setHomeTeamStats((prevData) => {
      let old = prevData;
      if (old?.team_id == id) {
        const newData = {
          ...old,
          [field]: value,
        };
        return newData;
      }
    });
  };

  const handleChangeAwayTeamStats = (id, field, value) => {
    setAwayTeamStats((prevData) => {
      let old = prevData;
      if (old?.team_id == id) {
        const newData = {
          ...old,
          [field]: value,
        };
        return newData;
      }
    });
  };

  const createStatsObject = (team) => {
    if (!team) {
      return;
    }
    let obj = {
      team_id: team.team_id,
    };

    if (team.predicted_attendance != null && team.predicted_attendance != "") {
      obj.predicted_attendance = team.predicted_attendance;
    }

    if (team.predicted_attendance == 0) {
      obj.predicted_attendance = team.predicted_attendance;
    }

    if (team.attack_edge != null) {
      obj.attack_edge = team.attack_edge;
    }
    if (team.defence_edge != null) {
      obj.defence_edge = team.defence_edge;
    }
    if (team.match_importance != null) {
      obj.match_importance = team.match_importance;
    }
    if (team.must_win != null) {
      obj.must_win = team.must_win;
    }
    if (team.must_not_lose != null) {
      obj.must_not_lose = team.must_not_lose;
    }

    if (team.ready != null) {
      obj.ready = team.ready;
    }

    return obj;
  };

  const handleUpdateFactors = () => {
    setIsLoading(true);

    let game_factors = {
      neutral_venue: naturalVenue,
      stadium_id: selectedStadium.id,
      // referee_id: selectedReferee.id,
      pitch_condition: pitchCondition,
      ready: readyToSubmitFactors,
    };

    if (selectedReferee) {
      game_factors.referee_id = selectedReferee.id
    }

    let team_stats = [
      createStatsObject(homeTeamStats),
      createStatsObject(awayTeamStats),
    ];


    let dataObject = {
      fixture_id: selectedFixture.fixture.fixture_id,
      game_factors: game_factors,
      team_stats: team_stats,
    };

    if (currentCup) {
      dataObject.cup_id = currentCup.id;
    } else {
      dataObject.league_id = currentLeague.id;
    }


    update_game_factors(
      dataObject,
      function (response) {
        handleGetGames();
        if (!selectedReferee) {
          notifyInfoNoClose(
            notifyNoCloseId,
            `Factors were submitted without a referee for ${homeTeam?.name}-${awayTeam?.name}`
          );
        }

      },
      function (error) {
        notifyError("Something wrong");
        setIsLoading(false);
      }
    );
  };

  const handleUpdateFactorsPartial = () => {
    setIsLoading(true);

    let game_factors = {};

    if (naturalVenue != null) {
      game_factors.neutral_venue = naturalVenue;
    }
    if (selectedStadium != null) {
      game_factors.stadium_id = selectedStadium.id;
    }
    if (selectedReferee != null) {
      game_factors.referee_id = selectedReferee.id;
    }
    if (pitchCondition != null) {
      game_factors.pitch_condition = pitchCondition;
    }
    if (readyToSubmitFactors != null) {
      game_factors.ready = readyToSubmitFactors;
    }

    let team_stats = [
      createStatsObject(homeTeamStats),
      createStatsObject(awayTeamStats),
    ];

    let dataObject = {
      fixture_id: selectedFixture.fixture.fixture_id,
      game_factors: game_factors,
      team_stats: team_stats,
    };

    if (currentCup) {
      dataObject.cup_id = currentCup.id;
    } else {
      dataObject.league_id = currentLeague.id;
    }

    update_game_factors_partial(
      dataObject,
      function (response) {

        setIsLoading(false);
        setResponseFromPartialFactorUpdate(response.game);
      },
      function (error) {
        notifyError("Something wrong");
        setIsLoading(false);
      }
    );
  };

  const handlePostponeGame = (postponed_status) => {
    if (postponed_status) {
      setPostponedMatchConfirmation(true);
    } else {
      makePostponeAPICall(false);
    }
  };

  const makePostponeAPICall = (postponed_status) => {
    let dataObject = {
      fixture_id: selectedFixture.fixture.fixture_id,
      postponed: postponed_status,
    };

    if (currentCup) {
      dataObject.cup_id = currentCup.id;
    } else {
      dataObject.league_id = currentLeague.id;
    }

    setIsLoading(true);
    set_game_postponed(
      dataObject,
      function (response) {
        setInitialValues(response.game);
        setSelectedFixture(response.game);
        shouldRefreshHomeStats.current = response.game.postponed;
        shouldRefreshAwayStats.current = response.game.postponed;
        handleGetGames();
      },
      function (error) {
        notifyError("Something wrong");
        setIsLoading(false);
      }
    );
  };

  const handleCancellMatch = (cancelled_status) => {
    setIsLoading(true);
    let dataObject = {
      fixture_id: selectedFixture.fixture.fixture_id,
      cancelled: cancelled_status,
    };

    if (currentCup) {
      dataObject.cup_id = currentCup.id;
    } else {
      dataObject.league_id = currentLeague.id;
    }
    set_game_cancelled(
      dataObject,
      function (response) {
        setInitialValues(response.game);
        setSelectedFixture(response.game);
        shouldRefreshHomeStats.current = response.game.cancelled;
        shouldRefreshAwayStats.current = response.game.cancelled;
        handleGetGames();
      },
      function (error) {
        notifyError("Something wrong");
        setIsLoading(false);
      }
    );
  };

  const checkReadyToSubmit = () => {
    if (
      pitchCondition !== null &&
      // selectedReferee !== null &&
      selectedStadium !== null &&
      readyToSubmitHome == true &&
      readyToSubmitAway == true &&
      readyToSubmitFactors == true
    ) {
      setReadyToSubmit(true);
    } else {
      setReadyToSubmit(false);
    }
  };

  const checkFactorsFieldFilled = () => {
    if (
      pitchCondition !== null &&
      // selectedReferee !== null &&
      selectedStadium !== null
    ) {
      setFactorsFieldsFilled(true);
    } else {
      setFactorsFieldsFilled(false);
    }
  };

  const changeMenuValue = (setFunction, value) => {
    shouldUpdate.current = true;
    setFunction(value);
  };

  const neutralMenuValueChange = (setFunction, value) => {
    if (value == false) {
      setSelectedStadium(homeTeam.stadium);
      setFunction(value);
      shouldUpdate.current = true;
    } else {
      setFunction(value);
      shouldUpdate.current = true;
    }
  };

  const checkIfStadiumIsShared = (stadium) => {

    if (!stadium) {
      setSharedStadium(false);
      return;
    }
    let stadiumFound = stadiums.find((item) => item.id === stadium.id);

    if (
      stadiumFound != null &&
      (stadiumFound.teams.length > 1 ||
        stadiumFound.team_not_listed ||
        stadiumFound.other_sports)
    ) {
      setSharedStadium(true);
    } else {
      setSharedStadium(false);
    }
  };


  useEffect(() => {
    if (!selectedFixture) {
      return
    }
    setTeams(selectedFixture);
    setInitialValues(selectedFixture);
    checkNaturalVenue(selectedFixture);
    getWyScoutId(selectedFixture.fixture.fixture_id);
  }, [selectedFixture.fixture.fixture_id, currentCup?.id]);

  useEffect(() => {
    getStadiumWeather(selectedFixture);
    getPitchConditionForStadium(selectedStadium);
    checkIfStadiumIsShared(selectedStadium);
  }, [selectedStadium]);

  useEffect(() => {
    checkFactorsFieldFilled();
  }, [selectedStadium, selectedReferee, pitchCondition]);

  useEffect(() => {
    checkReadyToSubmit();
  }, [
    selectedStadium,
    selectedReferee,
    readyToSubmitHome,
    readyToSubmitAway,
    readyToSubmitFactors,
    pitchCondition,
  ]);

  useEffect(() => {
    if (shouldUpdate.current == true) {
      handleUpdateFactorsPartial();
      shouldUpdate.current = false;
    }
  }, [
    selectedStadium,
    pitchCondition,
    selectedReferee,
    homeTeamStats,
    awayTeamStats,
    readyToSubmitFactors,
  ]);

  useEffect(() => {
    if (readyToSubmitFactors && !selectedReferee) {
      notifyInfoNoClose(
        notifyNoCloseId,
        `Factors were submitted without a referee for ${homeTeam?.name}-${awayTeam?.name}`
      );
    }
  }, [readyToSubmitFactors, selectedReferee])

  return (
    <Container>
      <GameSide>
        <Top>
          <TopLeft>
            <h1>Game</h1>
            {factorsFieldsFilled && !statusCompleted && (
              <InputWrapper
                onClick={(e) =>
                  changeMenuValue(
                    setReadyToSubmitFactors,
                    !readyToSubmitFactors
                  )
                }
              >
                <input
                  type={"checkbox"}
                  value={readyToSubmitFactors}
                  checked={readyToSubmitFactors}
                  readOnly
                ></input>
                <p>Ready to be submitted</p>
              </InputWrapper>
            )}
          </TopLeft>
          {!statusCompleted && (
            <ButtonsWrapper>
              {!selectedFixture.cancelled ? (
                <PpostPonedButton onClick={() => handleCancellMatch(true)}>
                  Match cancelled
                </PpostPonedButton>
              ) : (
                <PpostPonedButton onClick={() => handleCancellMatch(false)}>
                  Un-cancel match
                </PpostPonedButton>
              )}
              {!selectedFixture.postponed ? (
                <PpostPonedButton onClick={() => handlePostponeGame(true)}>
                  Match postponed
                </PpostPonedButton>
              ) : (
                <PpostPonedButton onClick={() => handlePostponeGame(false)}>
                  Un-postpone match
                </PpostPonedButton>
              )}

              <UpdateButton
                disabled={!readyToSubmit}
                onClick={() => handleUpdateFactors()}
              >
                Update factors
              </UpdateButton>
            </ButtonsWrapper>
          )}
        </Top>
        <Row style={{ marginTop: "-10px" }}>
          <WyScoutIdField>
            <p>ID: {wyscoutId}</p>
          </WyScoutIdField>
        </Row>
        <Row>
          {" "}
          <Group>
            <label>stadium</label>
            <StadiumMenu
              label={"-"}
              disabled={
                !naturalVenue || statusCompleted || readyToSubmitFactors
              }
              dataList={stadiums}
              selectedValue={selectedStadium}
              setSelectedValue={setSelectedStadium}
              changeMenuValue={changeMenuValue}
            />
          </Group>
          <Group>
            <label>neutral venue</label>
            <NeutralVenueMenue
              label={"Yes/No"}
              dataList={YesNo}
              disabled={
                statusCompleted || readyToSubmitFactors || readyToSubmitFactors
              }
              value={naturalVenue}
              setValue={setNaturalVenue}
              neutralMenuValueChange={neutralMenuValueChange}
            />
          </Group>
          <Group>
            <label>share stadium</label>
            <ReadOnlyField value={sharedStadium} width={"90px"} />
          </Group>
        </Row>
        <Row>
          <Group>
            <label>stadium capacity</label>
            <ReadOnlyField value={selectedStadium?.capacity} width={"130px"} />
          </Group>
          <Group>
            <label>surface</label>
            <ReadOnlyField value={selectedStadium?.surface} width={"130px"} />
          </Group>
          <Group>
            <label>pitch condition</label>
            <PitchConditionLine>
              <PitchConditionMenu
                disabled={statusCompleted || readyToSubmitFactors}
                label={"Excelent"}
                width={"130px"}
                selectedValue={pitchCondition}
                setSelectedValue={setPitchConditions}
                changeMenuValue={changeMenuValue}
              />
              <PitchConditionStatistics
                pitchConditionsHistory={pitchConditionsHistory}
                currentStadium={selectedStadium}
              />
            </PitchConditionLine>
          </Group>
        </Row>
        <Row>
          <Group>
            <label>wind</label>
            <ReadOnlyField
              value={weather?.wind_speed}
              secondValue={weather?.wind_direction}
              type={"wind"}
              icon={<WindIcon />}
              width={"170px"}
            />
          </Group>
          <Group>
            <label>precipitation</label>
            <ReadOnlyField
              value={null}
              type={"percent"}
              icon={<PrecipitationIcon />}
            />
          </Group>
          <Group>
            <label>humidity</label>
            <ReadOnlyField
              value={weather?.humidity}
              type={"percent"}
              icon={<HumidityIcon />}
            />
          </Group>
          <Group>
            <label>temperature</label>
            <ReadOnlyField
              value={weather?.temperature}
              type={"temperature"}
              icon={<TemperatureIcon />}
            />
          </Group>
          <Group>
            <label>altitude</label>
            <ReadOnlyField
              value={selectedStadium?.altitude}
              type={"altitude"}
              icon={<AltitudeIcon />}
            />
          </Group>
        </Row>
        <Row>
          <Group>
            <label>referee</label>
            <SingleOptionMenu
              label={"-"}
              disabled={statusCompleted || readyToSubmitFactors}
              dataList={refereesList}
              selectedValue={selectedReferee}
              setSelectedValue={setSelectedReferee}
              changeMenuValue={changeMenuValue}
            />
          </Group>
          <Group>
            <label>derby</label>
            <ReadOnlyField width={"60px"} value={derby ? "Yes" : "No"} />
          </Group>
          {derby && (
            <IntensityGroup>
              <label>intensity</label>
              <IntensityField value={derby.derby_type} />
            </IntensityGroup>
          )}
        </Row>
      </GameSide>

      <TeamSide>
        <TeamVsTeam
          navigateToSquadManager={navigateToSquadManager}
          statusCompleted={statusCompleted}
          handleChangeHomeTeamStats={handleChangeHomeTeamStats}
          handleChangeAwayTeamStats={handleChangeAwayTeamStats}
          homeTeam={homeTeam}
          homeTeamStats={homeTeamStats}
          awayTeam={awayTeam}
          awayTeamStats={awayTeamStats}
          readyToSubmitHome={readyToSubmitHome}
          setReadyToSubmitHome={setReadyToSubmitHome}
          readyToSubmitAway={readyToSubmitAway}
          setReadyToSubmitAway={setReadyToSubmitAway}
          handleUpdateFactorsPartial={handleUpdateFactorsPartial}
          changeMenuValue={changeMenuValue}
          shouldUpdate={shouldUpdate}
          selectedFixture={selectedFixture}
          shouldRefreshHomeStats={shouldRefreshHomeStats}
          shouldRefreshAwayStats={shouldRefreshAwayStats}
        />
      </TeamSide>
      <PostponedMatchConfirmation
        handleSave={() => {
          makePostponeAPICall(true);
        }}
        postponedMatchConfirmation={postponedMatchConfirmation}
        setPostponedMatchConfirmation={setPostponedMatchConfirmation}
      />
    </Container>
  );
};

export default MatchFactors;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height:100%;
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.matchPreview.textColor};
  }
`;
const UpdateButton = styled.button`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  background: #ffffff;
  border: 1px solid #006fff;
  border-radius: 4px;
  color: var(--primaryBlue);
  padding: 0px 10px;
  cursor: pointer;
  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`;
const Row = styled.div`
  display: flex;
  column-gap: 40px;
`;
const Group = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    letter-spacing: 0.03em;
    color: ${({ theme }) => theme.matchPreview.textColor};
    text-transform: uppercase;
  }
`;
const GameSide = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding: 20px 30px;
  border-bottom: 2px solid rgba(215, 220, 239, 0.6);
`;

const TeamSide = styled.div`
  padding: 20px 30px 70px 30px;
`;
const PitchConditionLine = styled.div`
  display: flex;
  column-gap: 12px;
`;
const IntensityGroup = styled(Group)`
  align-items: center;
  label {
    align-text: center;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
`;
const PpostPonedButton = styled.button`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  color: var(--primaryBlue);
  background: #ffffff;
  border: 1px solid #006fff;
  border-radius: 4px;
  padding: 0 10px;
  cursor: pointer;
  :disabled {
    opacity: 0;
  }
`;
const TopLeft = styled.div`
  display: flex;
  column-gap: 15px;
  align-items: center;
`;
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  cursor: pointer;
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    text-align: right;
    color: ${({ theme }) => theme.matchPreview.textColor};
  }
`;
const WyScoutIdField = styled.div`
  background: ${({ theme }) => theme.matchPreview.matchFactorsReadOnlyInputs};
  padding: 6px 15px;
  border-radius: 3px;
  p {
    color: ${({ theme }) => theme.matchPreview.textColor};
    font-family: Roboto;
    font-size: 10px;

    font-weight: 600;
    letter-spacing: 0.3px;
  }
`;
