import { POST } from "../APiHelper/API";

export const resetPasswordEmail = (email, onSuccess, onError) => {
  const data = {
    email: email,
  };
  POST("/api/v1/users/reset_password", data, onSuccess, onError);
};
export const changePassword = (password, resetToken, onSuccess, onError) => {
  const data = {
    new_password: password,
    reset_token: resetToken,
  };
  POST("/api/v1/users/change_password", data, onSuccess, onError);
};
