import React from "react";
import DatePicker from "react-date-picker";

import styled from "styled-components";

import "../../theme/dateRangePicker.css";
import "../../theme/react-calendar.css";

const DatePickerMenu = (props) => {
  return (
    <Container>
      <DatePicker
        className={props.selectedValue == null ? "disabledXbtn" : ""}
        format="dd/MM/y"
        onChange={props.setSelectedValue}
        value={props.selectedValue}
      />
    </Container>
  );
};

export default DatePickerMenu;

const Container = styled.div`
  display: flex;
  .react-daterange-picker {
    display: inline-flex;
    position: relative;
    width: 220px;
  }
  .react-daterange-picker,
  .react-daterange-picker *,
  .react-daterange-picker *:before,
  .react-daterange-picker *:after {
    font-weight: 700;
    font-size: 10px;
    background-color: #ffffff;
    border-radius: 8px;
  }
  .react-daterange-picker__inputGroup__input--hasLeadingZero {
    margin-left: 0;
    padding: 0;
  }
  .disabledXbtn {
    .react-daterange-picker__clear-button {
      display: none;
    }
  }

  .react-daterange-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    border: none;
    justify-content: center;
    padding: 0 5px;
  }
  .react-daterange-picker__inputGroup {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .react-daterange-picker__inputGroup__input {
    color: #3c4a5c;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #ffffff;
  }
  .react-daterange-picker__range-divider {
    color: #3c4a5c;
  }
  .react-daterange-picker__button:enabled:focus
    .react-daterange-picker__button__icon {
    stroke: #3c4a5c;
  }
  .react-daterange-picker__button:disabled
    .react-daterange-picker__button__icon {
    stroke: #3c4a5c;
  }
  .react-daterange-picker__button {
    padding: 2px;
    svg {
      height: 12px;
      stroke: #006fff;
    }
  }
  .react-daterange-picker__button:enabled:hover
    .react-daterange-picker__button__icon,
  .react-daterange-picker__button:enabled:focus
    .react-daterange-picker__button__icon {
    stroke: #3c4a5c;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  .react-calendar__tile--active {
    background: rgba(231, 237, 255, 1);
    color: #3c4a5c;
  }
  .react-daterange-picker__button:disabled
    .react-daterange-picker__button__icon {
    stroke: red;
  }
  .react-calendar__navigation button:disabled {
    background-color: #fff;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: rgba(231, 237, 255, 1);
  }
`;
