
import React, { useState } from "react";
import styled from "styled-components";
import SortCell from "../SortCell";

const RsBetsHeader = ({ sortRsBets }) => {
    const [ascending, setAscending] = useState(true);

    const handleSort = (filterKey) => {
        sortRsBets(ascending, filterKey);
        setAscending(!ascending);
    };

    return (
        <Container>
            <Cell style={{ minWidth: "30px", maxWidth: "30px" }}></Cell>
            <CellWrap style={{ width: "75px" }}>
                <SortCell name={"KO TIME"} onClick={() => handleSort("start_time")} />
            </CellWrap>
            <CellWrap style={{ minWidth: "150px" }}>
                <SortCell name={"GAME"} onClick={() => handleSort("home_team")} />
            </CellWrap>
            <CellWrap className="center" style={{ minWidth: "100px" }}>
                <SortCell name={"MARKET"} onClick={() => handleSort("market")} />
            </CellWrap>
            <CellWrap style={{ minWidth: "100px" }}>
                <SortCell name={"SELECTION"} onClick={() => handleSort("selection")} />
            </CellWrap>
            <CellWrap style={{ minWidth: "60px" }} className="center">
                <SortCell name={"TARGET "} onClick={() => handleSort("target")} />
            </CellWrap>
            <CellWrap style={{ minWidth: "60px" }} className="center">
                <SortCell name={"MIN PRICE"} onClick={() => handleSort("min_price")} />
            </CellWrap>



            <CellWrap className="center" style={{ minWidth: "40px" }}>
                <SortCell name={"MKT"} onClick={() => handleSort("market_price")} />
            </CellWrap>
            <CellWrap className="center" style={{ minWidth: "60px" }}>
                <SortCell
                    name={"MKT VALUE"}
                    onClick={() => handleSort("market_value")}
                />
            </CellWrap>


            <CellWrap style={{ minWidth: "70px" }} className="center">
            </CellWrap>

            <Cell style={{ minWidth: "60px", justifyContent: "center" }}>
            </Cell>

            <Cell style={{ minWidth: "70px" }}>
            </Cell>

            <Cell style={{ minWidth: "129px", justifyContent: "start" }}>
                <p>STAKE</p>
            </Cell>
            <Cell style={{ minWidth: "110px", justifyContent: "start" }}>
                <p>TIME LEFT</p>
            </Cell>

            <Cell className="toggleWidth">
                <p>PRICE</p>
            </Cell>
            <Cell style={{ minWidth: "60px" }}>

            </Cell>
            <Cell className="toggleWidth">

            </Cell>

            <Cell style={{ minWidth: "50px" }}>
                <p>ACTION</p>
            </Cell>
            <Cell style={{ minWidth: "40px" }}>
                <p>NOTES</p>
            </Cell>
            <Cell style={{ minWidth: "50px" }}></Cell>
        </Container>
    );
};

export default RsBetsHeader;
const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-width: 1600px;
  background-color: var(--betsHeaderBg3);
  color: var(--betsHeaderText);
  padding: 14px 14px;
  column-gap: 9px;
  max-height: 36px;
  .center {
    justify-content: center;
  }
  .toggleWidth {
    min-width: 51px;
  }
`;

const CellWrap = styled.div`
  display: flex;
  align-items: center;
  min-width: 60px;
  width: 100%;
`;
const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  width: 100%;

  p {
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
`;
