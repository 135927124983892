import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import down from "../../../assets/icons/LeaguesIcons/down.svg";

const PositionMenu = ({ valuesList, value, setValue, smallMenu, transferred = false, }) => {
  const ref = useRef(null);
  const selectmenuref = useRef(null);


  const [selectedValue, setSelectedValue] = useState();


  const handleChange = (item) => {
    setSelectedValue(item);
    setValue(item);
  };


  useEffect(() => {
    if (value) {
      setSelectedValue(value);
    } else {
      setSelectedValue("");
    }
  }, [value]);
  return (
    <Container ref={ref}>
      <Wrapper>
        {/* <DownSvg /> */}
        <SelectMenu
          disabled={transferred}
          transferred={transferred}
          smallMenu={smallMenu}
          value={selectedValue}
          onChange={(e) => handleChange(e.target.value)}
          ref={selectmenuref}
        >
          <SelectMenuItem value={null} label={"-"}></SelectMenuItem>
          {valuesList.map((item, index) => (
            <SelectMenuItem value={item.position} key={index}>
              {item.position}
            </SelectMenuItem>
          ))}
        </SelectMenu>
      </Wrapper>
    </Container>
  );
};

export default PositionMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 115px;
  min-width:115px;
  position: relative;
`;

const SelectMenu = styled.select`
height:${(props) => props.smallMenu ? "32px" : "40px"};
background-color:${(props) => props.transferred ? ({ theme }) => theme.editLeague.transferredPlayerBg : ({ theme }) => theme.editLeague.readOnlyInputs};
  padding: 0 15px;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  border-radius: ${(props) => (props.isMenuOpen ? "8px 8px 0 0" : "")};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  background-image: url(${down});
  background-position: 230px 10px;
  background-size: 16px;
  background-repeat: no-repeat;
  color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
:disabled{
  opacity:unset;
}
`;
const SelectMenuItem = styled.option``;
const Wrapper = styled.div`
  position: relative;
  svg {
    position: absolute;
    right: 10px;
    top: 10px;
    fill: #f8f9fb;
    stroke: #006fff;
  }
`;
