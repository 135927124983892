import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";
import { ReactComponent as RemoveIcon } from "../assets/icons/LeaguesIcons/removeBin.svg";
import { ReactComponent as BinSvg } from "../assets/icons/bin.svg";
import { useLeaguesDataService } from "../context/useLeaguesDataService";
import { notifyError } from "../helper/Generic/Notifications";
import { isNumber } from "lodash";

const DeleteConfirmationDouble = ({ handleRemoveItem, item, variant, disableButton, season = null }) => {
    const [open, setOpen] = React.useState(false);
    const [phase, setPhase] = useState(1);
    const { get_teams_from_leagues_in_valid_cups, get_teams_used_in_valid_cups } = useLeaguesDataService();

    const LEAGUE = "league";
    const ARCHIVE_SEASON = "archive_season"
    const DELETE_TEAM = "delete_team"

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setPhase(1);
    };

    const handleRemove = (item) => {
        handleRemoveItem(item);
        setOpen(false);
    };

    const getValidTeams = (league_ids) => {

        let ids = [];
        if (variant == ARCHIVE_SEASON) {
            season.leagues.map((league) => {
                ids.push(league.id)
            })
        } else {
            ids = [league_ids]
        }

        get_teams_from_leagues_in_valid_cups(
            ids,
            function (response) {
                if (response.data.cups.length > 0) {
                    setPhase(2);
                }
                else {
                    handleCaseTwo();
                }
            },
            function (error) {
                console.log(error)
                notifyError("Something wrong")
            }
        )
    }

    const getUsedTeamInCup = (team_id) => {
        get_teams_used_in_valid_cups(
            team_id,
            function (response) {
                if (response.data.cups.length > 0) {
                    setPhase(2);
                }
                else {
                    handleCaseTwo();
                }
            },
            function (error) {
                console.log(error)
                notifyError("Something wrong")
            }
        )
    }



    const handleCaseTwo = () => {
        handleRemove(item);
    }

    const handleCaseOne = () => {
        if (variant === DELETE_TEAM) {
            getUsedTeamInCup(item)
        }
        else {
            getValidTeams(item)
        }
    }

    const handleOnClick = () => {
        switch (phase) {
            case 1:
                handleCaseOne();
                break;
            case 2:
                handleCaseTwo();
                break;

            default:
                break;
        }

    }



    const DisplayVariant = ({ variant }) => {

        let displayItem = (
            <DeleteButton onClick={() => handleClickOpen()} disabled={disableButton}>
                <RemoveIcon />
            </DeleteButton>
        )

        if (variant == LEAGUE) {
            displayItem = (
                <DeleteLeagueBtn onClick={() => handleClickOpen()}>
                    <BinSvg />
                    Delete league
                </DeleteLeagueBtn>)
        }
        if (variant == ARCHIVE_SEASON) {
            displayItem = (
                <DeleteLeagueBtn onClick={() => handleClickOpen()}>
                    <BinSvg />
                    Archive Season
                </DeleteLeagueBtn>)
        }
        if (variant == DELETE_TEAM) {
            displayItem = (
                <DeleteButton onClick={() => handleClickOpen()} disabled={disableButton}>
                    <RemoveIcon />
                </DeleteButton>

            )
        }



        return (
            displayItem
        )

    }

    const DisplayDialogVariant = ({ variant }) => {

        let displayDialog = (
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to remove this item?
            </DialogContentText>
        )

        if (variant == ARCHIVE_SEASON) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to archive this item?
                </DialogContentText>
            )
        }

        return displayDialog
    }

    const DisplaySecondDialogVariant = ({ variant }) => {
        let displayDialog = (
            <DialogContentText id="alert-dialog-description">
                This League have selected teams in valid cups , are you sure you want to continue?
            </DialogContentText>)

        if (variant == DELETE_TEAM) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    This Team is selected in valid cup, are you sure you want to continue?
                </DialogContentText>)



        }
        return displayDialog
    }

    useEffect(() => {

    }, [])

    return (
        (<div>
            <DisplayVariant variant={variant} />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
                <DialogContent>
                    {phase === 1 && <DisplayDialogVariant variant={variant} />}
                    {phase === 2 && <DisplaySecondDialogVariant variant={variant} />}

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button style={{ color: "red" }} onClick={handleOnClick} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>


        </div>)


    );
};

export default DeleteConfirmationDouble;

const DeleteButton = styled.button`
  background:  none;
  border:  none;
  cursor:  pointer;
  width:  40px;
  height:  40px;

  svg{
    width:40px;
    height:40px;
  }
  :disabled{
    cursor:inherit;
    opacity:0.5;
  }
`;
const DeleteLeagueBtn = styled.button`
  display: flex;
  height: 40px;
  align-items: center;
  column-gap: 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ec4848;
`;
const DeleteMessageButton = styled.button`
background:  none;
border:  none;
cursor:  pointer;
width:  12px;
height:  12px;
svg{
  width:12px;
  height:12px;
}
:disabled{
  cursor:inherit;
  opacity:0.5;
}
`