import React, { useState } from "react";
import styled from "styled-components";
import MatchPreviewChat from "./Chat&Notes/MatchPreviewChat";
import Note from "./Chat&Notes/Note";
import MatchPreviewChatOneTeam from "./Chat&Notes/MatchPreviewChatOneTeam";


const ChatSide = ({ selectedFixture, setSelectedFixture, currentLeague, currentCup, handleGetGames, setGamesList, gamesList }) => {
  const [page, setPage] = useState("NOTES");

  const handleChange = (e) => {
    setPage(e.currentTarget.value);
  };
  const SelectPage = () => {
    if (!selectedFixture) {
      return;
    }
    if (page == "NOTES") {
      return <Note
        selectedFixture={selectedFixture}
        setSelectedFixture={setSelectedFixture}
        currentLeague={currentLeague}
        currentCup={currentCup}
        handleGetGames={handleGetGames}
        setGamesList={setGamesList}
        gamesList={gamesList}
      />;
    }
    if (page == "CHAT") {
      return <MatchPreviewChat fixture={selectedFixture} />;
    }
    if (page == "HOME") {
      return <MatchPreviewChatOneTeam fixture={selectedFixture} side={"HOME"} />;
    }
    if (page == "AWAY") {
      return <MatchPreviewChatOneTeam fixture={selectedFixture} side={"AWAY"} />;
    }
  };
  return (
    <Container>
      {selectedFixture && <Menu>
        <BtnWrapper>
          <button value={"NOTES"} className={page === "NOTES" ? "active" : ""} onClick={(e) => handleChange(e)}>NOTES</button>
          <button value={"CHAT"} className={page === "CHAT" ? "active" : ""} onClick={(e) => handleChange(e)}>CHAT</button>
          <button value={"HOME"} className={page === "HOME" ? "active" : ""} onClick={(e) => handleChange(e)}>HOME</button>
          <button value={"AWAY"} className={page === "AWAY" ? "active" : ""} onClick={(e) => handleChange(e)}>AWAY</button>
        </BtnWrapper>

      </Menu>

      }

      <Content>
        {SelectPage()}
      </Content>
    </Container>
  );
};

export default ChatSide;
const Container = styled.div`
display:flex;
flex-direction:column;
width:100%;
min-width:260px;
row-gap:5px;
height:100%;
overflow:hidden;
position:relative;
min-height:calc(100vh - 134px);
`;
const Menu = styled.div`
display:flex;
background: ${({ theme }) => theme.matchPreview.matchFactorsBg};
padding:5px;
`;
const Content = styled.div`
display:flex;
flex-direction:column;
height:100%;
overflow:hidden;
`;
const BtnWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.matchPreview.optionMenuActive};
  border-radius: 2px;

  background:transparent;
  button {
    display: flex;
    cursor: pointer;
    padding: 6px 16px;
    border: none;
    color: #7D8692;
    background: transparent;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 0.02em;
  }
  .active {
     color: ${({ theme }) => theme.matchPreview.bg};
    background: ${({ theme }) => theme.matchPreview.optionMenuActive};
  }
`;

