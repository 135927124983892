import { Radio } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownSvg } from "../../../assets/icons/DropDownMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/DropDownMenuIcons/up.svg";
import { ReactComponent as SearchSvg } from "../../../assets/icons/search.svg";
import { checkFlag } from "../../../helper/Generic/GetFlag";

const DropDownMenuCountry = (props) => {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const [radioValue, setRadioValue] = useState("");

  const handleRadiochange = (e) => {
    setRadioValue(e);
  };

  const handleSelect = (e) => {
    props.setSelectedValue(e);
    setIsMenuOpen(false);
  };

  const handleSearchInput = (e) => {
    setInputSearch(e.target.value);
  };

  const handleMenuOpen = () => {
    if (props.disabled) {
      return;
    }
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  const filterSearch = (items, filter) => {
    if (filter === "") {
      return items;
    } else {
      return items.filter((item) =>
        item.name.toLowerCase().includes(filter.toLowerCase())
      );
    }
  };

  const filterNullCountryCode = (items) => {
    return items.filter(
      (item) => item.country_code !== null || item.name === "International"
    );
  };
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref}>
      <DropDownMenu onClick={() => handleMenuOpen()} disabled={props.disabled}>
        {props.selectedValue === null ? (
          <p>{props.label}</p>
        ) : (
          <div className="group">
            <img
              src={
                props.selectedValue.country_code !== null
                  ? checkFlag(props.selectedValue.country_code)
                  : ""
              }
              alt=""
            ></img>
            <p>{props.selectedValue.name}</p>
          </div>
        )}
        {isMenuOpen ? <UpSvg /> : <DownSvg />}
      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>
          <DropDownMenuItem>
            <SearchGroup>
              <SearchSvg />
              <CustomInput
                type={"text"}
                value={inputSearch}
                onChange={handleSearchInput}
                placeholder="Search"
              ></CustomInput>
            </SearchGroup>
          </DropDownMenuItem>
          {inputSearch !== ""
            ? filterNullCountryCode(
              filterSearch(props.itemsSource, inputSearch)
            ).map((item, index) => (
              <DropDownMenuItem key={index}>
                <div
                  onClick={() => {
                    handleSelect(item);
                    setRadioValue(item.name);
                    setInputSearch("");
                  }}
                >
                  <Radio
                    sx={{ height: "18px", width: "18px" }}
                    checked={radioValue === `${item.name}`}
                    value={item.name}
                    onChange={() => handleRadiochange(item.name)}
                  ></Radio>
                  <img
                    src={
                      item.country_code !== null
                        ? checkFlag(item.country_code)
                        : ""
                    }
                    alt=""
                  ></img>
                  <p>{item.name}</p>
                </div>
              </DropDownMenuItem>
            ))
            : filterNullCountryCode(props.itemsSource).map((item, index) => (
              <DropDownMenuItem key={index}>
                <div

                  selected={item == props.selectedValue}
                  onClick={() => {
                    handleSelect(item);
                    setRadioValue(item.name);
                  }}
                >
                  <Radio

                    sx={{
                      height: "18px",
                      width: "18px",
                    }}
                    checked={radioValue === `${item.name}`}
                    value={item.name}
                    onChange={() => handleRadiochange(item.name)}
                  ></Radio>
                  <img
                    src={
                      item.country_code !== null
                        ? checkFlag(item.country_code)
                        : ""
                    }
                    alt=""
                  ></img>
                  <p> {item.name}</p>
                </div>
              </DropDownMenuItem>
            ))}
        </DropDownList>
      )}
    </Container>
  );
};

export default DropDownMenuCountry;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 256px;
  position: relative;
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #6a7584;
  }
`;

const DropDownMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f9fb;
  padding: 12px 10px;
  border-radius: 8px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  .group {
    align-items: center;
    display: flex;
    column-gap: 10px;
    img {
      height: 18px;
      width: 24px;
      border-radius: 4px;
    }
  }

  svg {
    cursor: pointer;
    height: 22px;
    width: 22px;
  }
`;
const DropDownList = styled.ul`
  position: absolute;
  background-color: #f8f9fb;
  z-index: 2;
  top: 50px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  max-height: 200px;
  overflow-x: hidden;
  color: black;

  img {
    border-radius: 4px;
    height: 18px;
    width: 24px;
  }
  h2 {
    color: black;
  }
`;

const DropDownMenuItem = styled.li`
  cursor: pointer;
  width: 256px;


  list-style-type: none;
  background-color: #f8f9fb;
  color: black;
  padding: 6px 12px;

  div {
    column-gap: 10px;
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #737a89;
  }

  .MuiRadio-root.Mui-checked {
    color: rgba(97, 131, 255, 1);
  }

svg{
    width:20px;
    }

  :first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const SearchGroup = styled.div`
  display: flex;
  position: relative;
  align-items: center;

  svg {
    height: 14px;
    width: 14px;
    position: absolute;
    top: 10px;
    left: 10px;
  }
`;

const CustomInput = styled.input`
  font-size: 12px;
    width: 100%;
 border: 1px solid #d9dfe7;
    border-radius: 4px;
    padding: 9px;
padding-left: 30px;
    ::placeholder {
      color: #b3b9c0;
    }
    :focus-visible {
      outline: 0px;
      //outline-offset: 1px;
      border: 1px solid var(--primaryBlue);
    }
`