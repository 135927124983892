import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
// import NoteElement from "./NoteElement";
import { ReactComponent as SendSvg } from "../../../assets/icons/sendMessage.svg";
import { ReactComponent as Xicon } from "../../../assets/icons/xBtn.svg";
import { getConversationForFixtureId } from "../../../api/conversations/ConversationForFixtureId";
import { addConversationMessage } from "../../../api/conversations/AddConversationMessage";
import { deleteMessageFromConversationFixture } from "../../../api/conversations/DeleteMesageFromConversationFixture";
import { notifyError, notifySucces } from "../../../helper/Generic/Notifications";
import { format_time_to_timezone, same_time_in_timezone } from "../../../helper/Generic/dateTimeFormat";
import { useSelector } from "react-redux";
import { selectUser } from "../../../Redux/user/user";
import NoteElement from "../../notes/NoteElement";
import { useNotesService } from "../../../context/useNotesService";

const NotePanel = ({ closeNote, fixture }) => {
  const [inputValue, setInputValue] = useState("");
  const [conversation, setConversation] = useState(null);
  const { messages, getMessages, sendMessage, deleteMessageFromFixture } = useNotesService();
  const bottomRef = useRef(null);
  var h = window.innerHeight - 550;
  const user = useSelector(selectUser);
  const _handleInputChange = (e) => {
    setInputValue(e.currentTarget.value);

  };

  const handleGetMessages = () => {
    getMessages(fixture.fixture_id,
      function (error) {
        notifyError("Something wrong");

      }

    );
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleMessage();
    }
  };

  const handleMessage = () => {
    sendMessage(messages.fixture_id, messages.id, inputValue,
      function () {
        notifyError("Something Wrong")
      });
    setInputValue("");

  };

  const handleDeleteMessage = (item) => {
    deleteMessageFromFixture(
      item.id,
      function (response) {
        handleGetMessages();
        notifySucces("Message Deleted");
      },
      function (error) {
        notifyError("Something wrong");

      }
    )
  }

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  };

  useEffect(() => {
    handleGetMessages();
  }, [fixture]);

  useEffect(() => {
    scrollToBottom();
  }, [conversation]);

  return (
    <Container windowHeight={h}>
      <Header>
        <div className="xBtn">
          {fixture.participant1is_home ? (
            <h1>{fixture.participant1.name} - {fixture.participant2.name}</h1>
          ) : (<h1>{fixture.participant2.name} - {fixture.participant1.name}</h1>)}
          <button onClick={closeNote}><Xicon /></button>
        </div>
        <p>{format_time_to_timezone(fixture.start_time, user.timezone).format("DD/MM/YYYY HH:mm")}</p>
      </Header>
      <Notes>
        {messages !== null && messages.messages?.map((item, index) => (
          // <NoteElement key={index} message={item} user={user} />
          <NoteElement key={index} message={item} user={user} handleDeleteMessage={handleDeleteMessage} />
        ))}
        <div ref={bottomRef} />
      </Notes >
      <Inpt>
        <div className="inputGroup">
          <input
            placeholder="Type message here..."
            type={"text"}
            value={inputValue}
            onChange={(e) => _handleInputChange(e)}
            onKeyDown={(e) => handleKeyDown(e)}
          ></input>
          <button onClick={() => handleMessage()}>
            <SendSvg />
          </button>
        </div>
      </Inpt>
    </Container>
  );
};

export default NotePanel;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: var(--pageHeight);
  position: sticky;
  top:45px;
  justify-content: space-between;
  min-width: 230px;
  background: ${({ theme }) => theme.notePanelBg};
  color: ${({ theme }) => theme.leagueColor};
  border-left: 3px solid ${({ theme }) => theme.leagueBG};

  @media screen and (max-width: 1820px) {
    z-index:1;
    top:${(props) => props.windowHeight}px;
    right:10px;
    width: 265px;
    height: 484px;
    box-shadow: 0px 4px 16px rgba(46, 46, 46, 0.12);
    border-radius: 10px;
    border: 1px solid #d4d5d9;
  }
`;
const Header = styled.div`
  padding: 20px 15px 10px 15px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.leagueBG};
  color: ${({ theme }) => theme.favoriteStarStroke};

  @media (max-width: 1800px) {
    border-bottom: 1px solid #d4d5d9;
  }
  h1 {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
  p {
    color: ${({ theme }) => theme.favoriteStarStroke};
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
  .xBtn {
    display: flex;
    justify-content: space-between;
  }
  button {
    color: ${({ theme }) => theme.favoriteStarStroke};
    background: transparent;
    border: none;
    cursor: pointer;
    font-weight: 700;
    width: fit-content;
  }
`;
const Notes = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  height: 100%;
  overflow: auto;
  row-gap: 15px;
  windiw.scroll
`;
const Inpt = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  padding: 10px;
  .inputGroup {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      min-width: 200px;
      border: none;
      background: ${({ theme }) => theme.notesBG};
      color: ${({ theme }) => theme.notesTextColor};
      border-radius: 30px;
      padding: 10px 40px 10px 20px;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      ::placeholder{
        color: ${({ theme }) => theme.notexInputColor};
      }
      :focus-visible {
        outline: none;
      }
    }
    button {
      cursor: pointer;
      background: transparent;
      border: none;
      position: absolute;
      right: 10px;
    }
  }
`;
