import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightTheme } from "../../../theme/theme";
import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";
const Prioritymenu = ({ label, priority, setPriority, }) => {

  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const priorityList = ["top", "high", "medium", "low"];

  const handleClick = (item) => {
    if (priority == item) {
      setPriority(null);
      handleMenuOpen();
    } else {
      setPriority(item);
      handleMenuOpen();
    }
  };

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };



  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);

      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref}>
      <DropDownMenu onClick={() => handleMenuOpen()} isMenuOpen={isMenuOpen}>
        <p>{label}</p>
        {isMenuOpen ? <UpSvg /> : <DownSvg />}
      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>

          {priorityList.map((item, index) => (
            <DropDownMenuItem key={index} onClick={(e) => handleClick(item)}>
              <input
                key={index}
                readOnly
                className="check"
                type="checkbox"
                value={item}
                checked={item == priority}
              ></input>
              <p className={`${item}-priority`}>{item}</p>

            </DropDownMenuItem>
          ))}
        </DropDownList>
      )}
    </Container>
  );
};

export default Prioritymenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 162px;
  position: relative;
  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: ${({ theme }) => theme.dropDownColor};
  }
`;
const DropDownMenu = styled.div`
position: relative;
display: flex;
align-items: center;
justify-content: space-between;
background-color: ${({ theme }) => theme.menuBgColor};
padding: 4px 10px;
border-radius: 8px;
border-radius: ${props => props.isMenuOpen ? "8px 8px 0 0" : ""};
svg {
  cursor: pointer;
  fill: ${({ theme }) => theme.dropDownSvgFill};
  stroke: ${({ theme }) => theme.dropDownSvgStroke};
}

`;
const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top: 28px;
  border-top: 2px solid;
  border-color: ${({ theme }) => (theme === lightTheme ? "#3C4A5C" : "white")};
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  max-height: 400px;
  width: 162px;
  overflow-x: hidden;
  color: ${({ theme }) => theme.menuTextColor};

 
`;
const DropDownMenuItem = styled.li`
  cursor: pointer;
  column-gap: 5px;
  align-items: center;
  display: flex;
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;
  background-color: ${({ theme }) => theme.dropDownBG};
  padding: 5px 10px;
  p {
    text-transform:capitalize;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.dropDownColor};
  }
 
  .top-priority{
    color:${({ theme }) => theme.topPriority}
  }
  .high-priority{
    color:#4A88E5;
  }
  .medium-priority{
    color:#82BCF4;
  }
  .low-priority{
    color:#BFE3FF;
  }
`;
