import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";
import { ReactComponent as SearchSvg } from "../../../assets/icons/PricingMenuIcons/search.svg";
import { lightTheme } from "../../../theme/theme";
const BetListIdMenu = ({ listValues, listId, setListId }) => {
    const ref = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [inputSearch, setInputSearch] = useState("");

    const handleSelect = (e) => {
        setListId(e);
        setIsMenuOpen(false);
    }
    const handleSearchInput = (e) => {
        setInputSearch(e.target.value);
    };

    const handleMenuOpen = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        } else {
            setIsMenuOpen(true);
        }
    };
    const filterSearch = (items, filter) => {
        if (filter == "") {
            return items;
        } else {
            return items.filter((item) =>
                item.toString().includes(filter)
            );
        }
    };
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
                setInputSearch("")
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    return (
        <Container ref={ref}>
            <DropDownMenu onClick={() => handleMenuOpen()} isMenuOpen={isMenuOpen}>
                <p>Bet list id</p>


                <ButtonsSide>
                    {/* {tier === null ? "" : <button onClick={() => handleClearBtn()}>x</button>} */}
                    {isMenuOpen ? <UpSvg /> : <DownSvg />}
                </ButtonsSide>

            </DropDownMenu>
            {isMenuOpen && (
                <DropDownList>
                    <DropDownMenuItem>
                        <div className="searchGroup">
                            <SearchSvg />
                            <input
                                type={"text"}
                                value={inputSearch}
                                onChange={handleSearchInput}
                                placeholder="Search"
                            ></input>
                        </div>
                    </DropDownMenuItem>
                    {
                        inputSearch !== "" ?
                            (filterSearch(listValues, inputSearch).map((item, index) => (
                                <DropDownMenuItem key={index} onClick={() => handleSelect(item)}>
                                    <input type={"radio"}
                                        checked={listId === item}
                                        readOnly
                                    >
                                    </input>
                                    <p
                                        className={
                                            listId === item ? "active" : "nonActive"
                                        }
                                    >
                                        {item}
                                    </p>
                                </DropDownMenuItem>
                            )))
                            :
                            (listValues.map((item, index) => (
                                <DropDownMenuItem key={index} onClick={() => handleSelect(item)}>
                                    <input type={"radio"}
                                        checked={listId === item}
                                        readOnly
                                    >
                                    </input>
                                    <p
                                        className={
                                            listId === item ? "active" : "nonActive"
                                        }
                                    >
                                        {item}
                                    </p>
                                </DropDownMenuItem>
                            )))

                    }
                </DropDownList>
            )}
        </Container>
    );
};
export default BetListIdMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 162px;
  position: relative;
  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: ${({ theme }) => theme.dropDownColor};
  }
`;
const DropDownMenu = styled.div`
position: relative;
display: flex;

align-items: center;
justify-content: space-between;
background-color: ${({ theme }) => theme.menuBgColor};
padding: 4px 10px;
border-radius: 8px;
border-radius: ${(props) => props.isMenuOpen ? "8px 8px 0 0" : ""} ;
svg {
  height:100%;
  cursor: pointer;
  fill: ${({ theme }) => theme.dropDownSvgFill};
  stroke: ${({ theme }) => theme.dropDownSvgStroke};
}
`;
const ButtonsSide = styled.div`
display:flex;
column-gap:5px;
height:100%;
align-items:center;
justify-content:center;
button{
border:none;
background:none;
cursor:pointer;
}
`
const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top: 28px;
  border-top: 2px solid;
  border-color: ${({ theme }) => (theme === lightTheme ? "#3C4A5C" : "white")};
  display: flex;
  width: 162px;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  color: black;
  scrollbar-width: 10px;
  max-height: 200px;
  overflow-x: hidden;
  
`;
const DropDownMenuItem = styled.li`
  cursor: pointer;
  display:flex;
  column-gap:5px;
  align-items:center;
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;
  background-color: ${({ theme }) => theme.dropDownBG};
  color: black;
  padding: 5px 10px;
  p {

    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.dropDownColor};
    :hover {
      color: #006fff;
    }
  }
  .active {
    color: rgba(97, 131, 255, 1);
  }
  .nonActive {
    :hover {
        color: ${({ theme }) => theme.buttonBG};
    }

   color: ${({ theme }) => theme.dropDownColor};
  }
  .searchGroup {
    position: relative;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.menuTextColor};
    input[type="text"] {
      align-items: center;
      position: relative;
      width: 100%;
      padding: 4px 10px 4px 20px;
      background: ${({ theme }) => theme.leagueBG};
      border: 1px solid #d9dfe7;
      border-radius: 8px;
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      :focus-visible {
        outline: none;
        border: 1px solid ${({ theme }) => theme.dropDownSvgStroke};
      }
      color: ${({ theme }) => theme.menuTextColor};
    }
    svg {
      position: absolute;
      z-index: 1;
      top: 8, 5px;
      left: 7px;
    }
  }
`;
