import React from "react";
import styled from "styled-components";


const LeagueGraph = ({ closeLeagueGraph }) => {
  const test = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  return (
    <Container>
      <GraphContainer>
        <CloseBtn>
          <button
            style={{ color: "#585E6C", fontSize: "14px" }}
            onClick={closeLeagueGraph}
          >
            x
          </button>
        </CloseBtn>
        <Title>
          <h1>League Graph</h1>
          <button>Screeengrab</button>
        </Title>
        <League>
          {/* <img src={LEAGUES[0].img} alt="" /> */}
          <h1>League</h1>
        </League>
        <GraphContent>
          <SelectionMenu>
            <SelectionMenuItem>
              <input type={"checkbox"}></input>
              <p>All teams</p>
            </SelectionMenuItem>
            {test.map((item, index) => (
              <SelectionMenuItem key={index}>
                <input type={"checkbox"}></input>
                <p>Team {index}</p>
              </SelectionMenuItem>
            ))}
          </SelectionMenu>
        </GraphContent>
      </GraphContainer>
    </Container>
  );
};

export default LeagueGraph;
const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(119, 124, 134, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;
const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  min-height: 400px;
  padding: 24px 30px 30px 50px;
  background: ${({ theme }) => theme.graphBg};
  border-radius: 10px;
  row-gap: 23px;
`;
const CloseBtn = styled.div`
  display: flex;
  justify-content: end;
  button {
    display: flex;
    font-size: 14px;
    cursor: pointer;
    border: none;
    background: transparent;
  }
`;
const Title = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    color: ${({ theme }) => theme.graphTitleText};
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: -0.01em;
  }
  button {
    cursor: pointer;
    padding: 4px 22px;
    background-color: ${({ theme }) => theme.buttonBG};
    border: 1.5px solid ${({ theme }) => theme.buttonBG};
    border-radius: 4px;
  }
`;
const League = styled.div`
  display: flex;
  column-gap: 9px;
  margin-top: 20px;
  img {
    width: 24px;
    height: 18px;
    border-radius: 4px;
  }
  h1 {
    color: ${({ theme }) => theme.graphLeagueText};
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
  }
`;
const GraphContent = styled.div`
  display: flex;
`;
const SelectionMenu = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px 0px 0px 4px;
  background-color: ${({ theme }) => theme.graphLeagueSelectionMenuItemBg};
  max-height: 320px;
  min-width: 168px;
  overflow: auto;
`;
const SelectionMenuItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 8px 6px;
  border-bottom: 1px solid ${({ theme }) => theme.graphLeagueLine};
  input {
    background: transparent;
  }
  p {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.02em;
    color: ${({ theme }) => theme.leagueColor};
  }
`;
