import React from "react";
import styled from "styled-components";

const TRow = ({ children }) => {
  return <Container>{children}</Container>;
};

export default TRow;
const Container = styled.tr`
  text-align: left;
  td {
    color: rgba(50, 64, 83, 1);
    padding: 18px 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    border-bottom: 2px solid #f8f9fb;
    :first-child {
    }
    :last-child {
      display: flex;
      justify-content: flex-end;
      border-right: 2px solid #f8f9fb;
    }
  }
`;
