import { PATCH } from "../APiHelper/API";

export const updateSquadsForGame = (
  data,
  onSuccess,
  onError
) => {

  PATCH(`/api/v1/games/squads`, data, onSuccess, onError);
};
