import React from "react";
import styled from "styled-components";

import RaceTrackMenu from "../Menu/RaceTrackMenu";
import SharedTeamsMenu from "../Menu/SharedTeamsMenu";
import ShareStadiumMenu from "../Menu/ShareStadiumMenu";
import StadiumsMenuSingleOption from "../Menu/StadiumsMenuSingleOption";
import SurfaceMenu from "../Menu/SurfaceMenu";
import { ReactComponent as PlusSvg } from "../../../assets/icons/LeaguesIcons/plusIcon.svg";
import { ReactComponent as BinIcon } from "../../../assets/icons/bin.svg";

import CountryMenuTeamsDetails from "../Menu/CountryMenuTeamDetails";
import { NumericFormat } from "react-number-format";
import { PatternFormat } from 'react-number-format';
import cloneDeep from "lodash.clonedeep";


const TeamHaveStadium = ({ handleInputChange, inputValues, surface, setSurface, raceTrack, setRaceTrack,
    allCountries, stadiumCountry, setStadiumCountry, leagueToEdit,
    stadiums, stadium, setStadium, sharedStadium, setSharedStadium, setSharedTeams, sharedTeams, teamsList,
    newSharedTeams, setNewSharedTeams, handleAddnNewSharedTeams, handleCheckInputChange,
    handleInsertNewStadium, handleSetSharedTeamsToRemove, handleCustomInputChange, handleRemoveNewSharedTeamItem
}) => {

    // const handleCoordinateChange = (event) => {
    //     const { name, value } = event.target;

    //     var newEvent = cloneDeep(event);

    //     var cleanValue = value
    //         .replace(/[^\d.-]/g, "")
    //         .split(".")
    //         .join("");
    //     var newValue = cleanValue;

    //     const hasMinus = newValue.startsWith("-");
    //     if (hasMinus) {
    //         newValue = newValue.substring(1);
    //     }

    //     // Without sign
    //     if (newValue.length > 2) {
    //         let begining = newValue.substring(0, 2);
    //         let end = newValue.substring(2);

    //         newValue = begining + "." + end;
    //     }

    //     if (hasMinus) {
    //         newValue = "-" + newValue;
    //     }

    //     newEvent.target.value = newValue;
    //     handleInputChange(newEvent);
    // };

    return (
        <Container>

            <LeftSide>
                <GroupContainer>
                    <h3>STADIUM NAME</h3>
                    <div style={{ display: "flex", columnGap: "10px" }}>
                        <StadiumsMenuSingleOption label={"Choose stadium"} dataList={stadiums} value={stadium} setValue={setStadium} leagueToEdit={leagueToEdit} />

                    </div>

                    <div style={{ display: "flex", widht: "100%", justifyContent: "end" }}>
                        <NewRefereeButton disabled={inputValues.referees == ""} onClick={() => handleInsertNewStadium()} > <PlusSvg />Add new stadium</NewRefereeButton>
                    </div>
                </GroupContainer>
                <div
                    style={{ display: "flex", columnGap: "30px", marginTop: "-10px" }}
                >

                    <GroupContainer>
                        <h3>CAPACITY</h3>
                        <CustomElementInput>
                            <NumericFormat
                                style={{ width: "96px", marginTop: "20px" }}
                                value={inputValues.capacity.formattedValue}
                                name="capacity"
                                onValueChange={(values, event) => handleCustomInputChange(event, values)}
                                thousandSeparator=","
                            />
                        </CustomElementInput>
                        {/* <ElementInput

                            name="capacity" onChange={(e) => handleInputChange(e)} value={inputValues.capacity.toLocaleString()}
                            placeholder="-"
                            style={{ width: "96px", marginTop: "20px" }}
                        /> */}
                    </GroupContainer>

                    <GroupContainer>
                        <h3>PITCH SIZE</h3>
                        <div style={{ display: "flex", columnGap: "10px" }}>
                            <InputWithLabel>
                                <label>Length</label>
                                <CustomElementInput>
                                    <NumericFormat
                                        style={{ width: "96px" }}
                                        value={inputValues.length.formattedValue}
                                        name="length"
                                        onValueChange={(values, event) => handleCustomInputChange(event, values)}
                                        thousandSeparator=","
                                        placeholder="0m"
                                    />
                                </CustomElementInput>

                            </InputWithLabel>
                            <InputWithLabel>
                                <label>Width</label>
                                <CustomElementInput>
                                    <NumericFormat
                                        style={{ width: "96px" }}
                                        value={inputValues.width.formattedValue}
                                        name="width"
                                        onValueChange={(values, event) => handleCustomInputChange(event, values)}
                                        thousandSeparator=","
                                        placeholder="0m"
                                    />
                                </CustomElementInput>

                            </InputWithLabel>
                        </div>
                    </GroupContainer>
                </div>

                <div style={{ display: "flex", columnGap: "30px" }}>
                    <GroupContainer>
                        <h3>SURFACE</h3>
                        <SurfaceMenu label={"-"} value={surface} setValue={setSurface} />
                    </GroupContainer>
                    <GroupContainer>
                        <h3>RACE TRACK</h3>
                        <RaceTrackMenu label={"-"} value={raceTrack} setValue={setRaceTrack} />
                    </GroupContainer>
                </div>
            </LeftSide>
            <RightSide>
                <div style={{ display: "flex", columnGap: "15px", height: "100%" }}>

                    <GroupContainer>
                        <h3>SHARE STADIUM</h3>
                        <ShareStadiumMenu label={"-"} value={sharedStadium} setValue={setSharedStadium} />
                    </GroupContainer>

                    {sharedStadium &&
                        <>
                            <GroupContainer>
                                <h3>SHARE TEAM</h3>
                                {sharedTeams.map((item, index) => (
                                    <SharedTeamContainer key={index}>
                                        <SharedTeamsMenu index={index} label={"Share team"} valuesList={teamsList} value={item} setValue={setSharedTeams} sharedTeams={sharedTeams} />
                                        <RemoveStadiumButton onClick={() => handleSetSharedTeamsToRemove(index)}>
                                            <BinIcon />
                                        </RemoveStadiumButton>
                                    </SharedTeamContainer>
                                ))}

                                {newSharedTeams.map((item, index) => (
                                    <SharedTeamContainer key={index}>
                                        <SharedTeamsMenu index={index} label={"Share team"} valuesList={teamsList} value={item} setValue={setNewSharedTeams} sharedTeams={newSharedTeams} />
                                        <RemoveStadiumButton onClick={() => handleRemoveNewSharedTeamItem(index)}>
                                            <BinIcon />
                                        </RemoveStadiumButton>
                                    </SharedTeamContainer>
                                ))}
                                <div style={{ display: "flex", width: "100%", justifyContent: "end", paddingRight: "50px" }}>
                                    <AddNewRowButton onClick={() => handleAddnNewSharedTeams()}>
                                        <PlusSvg />
                                        Add share team
                                    </AddNewRowButton>
                                </div>
                            </GroupContainer>
                            <GroupContainer style={{ rowGap: "20px" }}>
                                <h3>TEAM NOT LISTED</h3>

                                <input
                                    style={{ height: "20px" }}
                                    type="checkbox"
                                    name="team_not_listed"
                                    checked={inputValues.team_not_listed}
                                    value={inputValues.team_not_listed}
                                    onChange={(e) => handleCheckInputChange(e)}
                                >

                                </input>
                            </GroupContainer>

                            <GroupContainer style={{ rowGap: "20px" }}>
                                <h3>OTHER SPORTS</h3>
                                <input
                                    style={{ height: "20px" }}
                                    type="checkbox"
                                    name="other_sports"
                                    checked={inputValues.other_sports}
                                    value={inputValues.other_sports}
                                    onChange={(e) => handleCheckInputChange(e)}
                                >
                                </input>
                            </GroupContainer>
                        </>


                    }


                </div>


                <div
                    style={{ display: "flex", columnGap: "10px", marginTop: "-10px" }}
                >
                    <GroupContainer>
                        <h3>STADIUM LOCATION</h3>
                        <ElementInput

                            placeholder="Add the city name"
                            style={{ width: "210px", marginTop: "10px" }}
                            name="stadiumLocation" onChange={(e) => handleInputChange(e)} value={inputValues.stadiumLocation}
                        />
                    </GroupContainer>
                    <GroupContainer>
                        <h3>Country</h3>
                        <div style={{ marginTop: "10px" }}>
                            <CountryMenuTeamsDetails
                                label={"Select country"}
                                valuesList={allCountries}
                                value={stadiumCountry}
                                setValue={setStadiumCountry} />
                        </div>

                    </GroupContainer>
                </div>
                <div style={{ display: "flex", columnGap: "11px" }}>
                    <GroupContainer>
                        <h3>LATITUDE</h3>
                        <ElementInput

                            placeholder="00.00"
                            style={{ width: "125px" }}
                            name="latitude" onChange={(e) => handleInputChange(e)} value={inputValues.latitude}
                        />
                    </GroupContainer>
                    <GroupContainer>
                        <h3>LONGITUDE</h3>

                        <ElementInput
                            placeholder="00.00"
                            style={{ width: "125px" }}
                            name="longitude" onChange={(e) => handleInputChange(e)} value={inputValues.longitude}
                        />
                    </GroupContainer>
                    <GroupContainer>
                        <h3>ALTITUDE</h3>
                        <CustomElementInput>
                            <NumericFormat
                                style={{ width: "125px" }}
                                value={inputValues.altitude.formattedValue}
                                name="altitude"
                                onValueChange={(values, event) => handleCustomInputChange(event, values)}
                                thousandSeparator=","
                                placeholder="0m"
                            />
                        </CustomElementInput>
                    </GroupContainer>
                </div>



            </RightSide>

        </Container >

    );
};

export default TeamHaveStadium;

const Container = styled.div`
width:100%;
height:100%;
display: flex;
  justify-content: space-between;

#sharedTeam{
max-height:250px;
overflow:auto;
}

`;
const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  height:100%;
  row-gap: 10px;
  
  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
      color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
  
  h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */
    text-transform: uppercase;
     color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
`;
const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
height:100%;
`;
const RightSide = styled(LeftSide)``;
const ElementInput = styled.input`
  height: 40px;
  background-color: ${({ theme }) => theme.editLeague.readOnlyInputs};
  width: 330px;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  padding: 10px 15px;
  ::placeholder {
    color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  }
`;
const InputWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height, or 117% */
     color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
`;
const AddNewRowButton = styled.button`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  background: #ffffff;
  border: 0.7px solid #006fff;
  border-radius: 3px;
  padding: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--primaryBlue);
  cursor: pointer;
`;
const NewRefereeButton = styled.button`
cursor:pointer;
width:fit-content;
  display:flex;
  align-items:center;
  justify-content:center;
  column-gap:5px;
  background: #FFFFFF;
  border: 0.7px solid #006FFF;
  border-radius: 3px;
  padding:4px;
  font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 14px;
color:var(--primaryBlue);
:disabled{
    cursor:inherit;
  opacity:0.5;

}
`;
const RemoveStadiumButton = styled.button`
cursor:pointer;
background: transparent;
border: 1px solid rgba(237, 94, 95, 0.4);
border-radius: 6px;
width:40px;
height:40px;

`;
const SharedTeamContainer = styled.div`
display:flex;
column-gap:15px;
align-items:center;

`;
const CustomElementInput = styled.div`
input{
    height: 40px;
    background-color: ${({ theme }) => theme.editLeague.readOnlyInputs};
    border: 1px solid #bac3e8;
    border-radius: 6px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
    padding: 10px 15px;
    ::placeholder {
        color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
    }
}
 
`;