import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightTheme } from "../../theme/theme";
import { ReactComponent as DownSvg } from "../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../assets/icons/PricingMenuIcons/up.svg";
import { ReactComponent as DownLightSvg } from "../../assets/icons/downSimpleArrow.svg";
import { cloneDeep } from "lodash";
import { ATTACK, DEFENSIVE, GK, MIDFIELD, OTHER } from "../../helper/Values/TeamFormation";


const FilterTeamDetailsMenu = ({
    positionFilters,
    setPositionFilters,
    minutesFilter,
    setMinutesFilter,
    currentSquadFilter,
    setCurrentSquadFilter
}) => {

    const ref = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [expand, setExpand] = useState();

    const teamStatsPositionFilter = [
        {
            submenu: "Position",
            options: [
                {
                    position_type: GK,
                    filter_name: "Goalkeepers"
                },
                {
                    position_type: DEFENSIVE,
                    filter_name: "Defenders"
                }, {
                    position_type: MIDFIELD,
                    filter_name: "Neutral"
                }, {
                    position_type: ATTACK,
                    filter_name: "Attackers"
                }
                , {
                    position_type: OTHER,
                    filter_name: "Other"
                }
                ,


            ]
        }

    ]

    const handleExpand = (value) => {
        if (expand == value) {
            setExpand(undefined)
        } else
            setExpand(value)
    }

    const handleMenuOpen = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        } else {
            setIsMenuOpen(true);
        }
    };

    const handleSetPositionFilters = (item) => {
        let newPositions = cloneDeep(positionFilters);
        let found = positionFilters.find((pos) => pos === item);
        if (found) {
            newPositions = newPositions.filter((pos) => pos != item);
        } else {
            newPositions.push(item)
        }
        setPositionFilters(newPositions)
    }

    const handleMinInputChange = (e) => {
        const { name, value } = e.target;
        const numericValue = value.replace(/[^0-9]/g, "");

        setMinutesFilter({
            ...minutesFilter,
            [name]: numericValue,
        });
    };

    const handleSetCurrentSquadFilter = () => {
        setCurrentSquadFilter(!currentSquadFilter)
    }


    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    return (
        <Container ref={ref}>
            <DropDownMenu onClick={() => handleMenuOpen()} isMenuOpen={isMenuOpen}>
                <p>Filter</p>
                {isMenuOpen ? <UpSvg /> : <DownSvg />}
            </DropDownMenu>
            {isMenuOpen && (
                <DropDownList>
                    {teamStatsPositionFilter.map((filterObj, index) => (
                        <div key={index}>
                            <CategoryItem onClick={() => handleExpand(filterObj.submenu)}>
                                <p>{filterObj.submenu}</p>
                                {expand == filterObj.submenu ? <DownLightSvg style={{ rotate: "180deg" }} /> : <DownLightSvg />}
                            </CategoryItem>
                            {expand === filterObj.submenu &&
                                filterObj.options.map((option, index) => (
                                    <DropDownMenuItem onClick={() => handleSetPositionFilters(option.position_type)} key={index}>
                                        <input
                                            type="checkbox"
                                            readOnly
                                            checked={positionFilters?.includes(option.position_type)}
                                            style={{ height: "10px" }}
                                        >
                                        </input>
                                        <p>{option.filter_name} </p>
                                    </DropDownMenuItem>
                                ))}
                        </div>
                    ))}

                    <CategoryItem onClick={() => handleExpand("squad")}>
                        <p>Squad</p>
                        {expand == "squad" ? <DownLightSvg style={{ rotate: "180deg" }} /> : <DownLightSvg />}
                    </CategoryItem>
                    {
                        expand === "squad" &&
                        <DropDownMenuItem>
                            <DropDownMenuItem onClick={() => handleSetCurrentSquadFilter()}>
                                <input
                                    type="checkbox"
                                    readOnly
                                    checked={currentSquadFilter}
                                    style={{ height: "10px" }} a
                                >
                                </input>
                                <p>Current Squad Only</p>
                            </DropDownMenuItem>

                        </DropDownMenuItem>
                    }



                    <CategoryItem onClick={() => handleExpand("season_total_min")}>
                        <p>Season Total Minutes</p>
                        {expand == "season_total_min" ? <DownLightSvg style={{ rotate: "180deg" }} /> : <DownLightSvg />}
                    </CategoryItem>
                    {
                        expand === "season_total_min" &&
                        <DropDownMenuItem>
                            <CustomMinInput
                                name="min"
                                value={minutesFilter.min}
                                placeholder={"MIN"}
                                onChange={(e) => handleMinInputChange(e)}
                            />
                            <CustomMinInput
                                name="max"
                                value={minutesFilter.max}
                                placeholder={"MAX"}
                                onChange={(e) => handleMinInputChange(e)}
                            />
                        </DropDownMenuItem>
                    }

                </DropDownList>
            )}
        </Container>
    );
};


export default FilterTeamDetailsMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width:150px;
  position: relative;

  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: ${({ theme }) => theme.dropDownColor};
  }
`;
const DropDownMenu = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.menuBgColor};
    padding: 4px 10px;
    border-radius: 8px;
    border-radius: ${(props) => props.isMenuOpen ? "8px 8px 0 0" : ""} ;
    min-height:28px;
    p{
      margin-right:5px;
      width:100%;
      overflow:hidden;
      text-overflow:elipsis;
      white-space:nowrap;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 20px;
      color: ${({ theme }) => theme.dropDownColor};
    }
    svg {
      cursor: pointer;
      fill: ${({ theme }) => theme.dropDownSvgFill};
      stroke: ${({ theme }) => theme.dropDownSvgStroke};
    }
  `;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 3;   
  top: 28px;
  border-top: 2px solid;
  border-color: ${({ theme }) => (theme === lightTheme ? "#3C4A5C" : "white")};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  max-height: 400px;
  width:100%;
  overflow-x: hidden;
  background: ${({ theme }) => theme.menuBgColor};
  padding:10px;


`;
const DropDownMenuItem = styled.li`
display:flex;
column-gap:5px;
align-items:center;
cursor:pointer;
position:relative;
width:100%;
overflow:hidden;
`;
const CategoryItem = styled.div`
display:flex;
column-gap:5px;
align-items:center;
height:15px;
cursor:pointer;
p{
    font-family: Roboto;
font-size: 10px;
font-weight: 600;
line-height: 12px;
text-align: left;

}
svg{
    color: ${({ theme }) => theme.dropDownSvgStroke};
}
`
const CustomMinInput = styled.input`
border-radius:5px;
border:1px solid #bac3e8;
height:20px;
position:relative;
max-width:50px;
outline:none;
padding:5px 10px;
background: ${({ theme }) => theme.editLeague.readOnlyInputs};
font-size: 10px;
font-weight: 400;
line-height: 12px;
color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
`
