import { IconButton } from "@mui/material";
import React from "react";
import styled from "styled-components";
import RoleCell from "./Table/RoleCell";
import TableBody from "./Table/TableBody";
import Tablehead from "./Table/Tablehead";
import TRow from "./Table/TRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InvitedRow from "./Table/InvitedRow";

const TeamMermbersTable = ({
  otherUsers,
  setShowEditTeamMember,
  setEditMember,
}) => {
  const handleClick = (item) => {
    setEditMember(item);
    setShowEditTeamMember(true);
  };
  const checkInvited = (name) => {
    if (name === null) {
      return "Invited";
    } else {
      return name;
    }
  };
  return (
    <Container>
      <table cellSpacing={0}>
        <Tablehead>
          <th className="mw1">Name</th>
          <th className="mw2">Email</th>
          <th className="mw3">Time Zone</th>
          <th className="mw4">Roles</th>
          <th className="btnmw"> </th>
        </Tablehead>
        <TableBody>
          {otherUsers !== null &&
            otherUsers.map((item, index) => (
              <>
                {item.name === null ? (
                  <InvitedRow key={index}>
                    <td className="mw1">{checkInvited(item.name)}</td>
                    <td className="mw2">{item.email}</td>
                    <td className="mw3">{item.timezone_name}</td>
                    <RoleCell roleType={item.roles[0]} />
                    <td className="btnmw">
                      <IconButton onClick={() => handleClick(item)}>
                        <MoreVertIcon className="MoreVertIcon" />
                      </IconButton>
                    </td>
                  </InvitedRow>
                ) : (
                  <TRow key={index + item.email}>
                    <td className="mw1">{checkInvited(item.name)}</td>
                    <td className="mw2">{item.email}</td>
                    <td className="mw3">{item.timezone_name}</td>
                    <RoleCell roleType={item.roles[0]} />
                    <td className="btnmw">
                      <IconButton onClick={() => handleClick(item)}>
                        <MoreVertIcon className="MoreVertIcon" />
                      </IconButton>
                    </td>
                  </TRow>
                )}
              </>
            ))}
        </TableBody>
      </table>
    </Container>
  );
};

export default TeamMermbersTable;
const Container = styled.div`
  max-height: 800px;
  overflow: auto;
  border: 2px solid #f8f9fb;
  box-shadow: 0px 1px 8px rgba(46, 46, 46, 0.04);
  
  .invited {
    opacity: 0.5;
  }
  .mw1 {

    width:250px;
    overflow:hidden;
    text-overflow: ellipsis;
  }
  .mw2 {

    width:350px;
    min-width:350px;
    max-width:350px;
    overflow:hidden;
    text-overflow: ellipsis;
  }
  .mw3 {
    width:100%;
    min-width: 200px;
  }
  .mw4{

    min-width: 200px;
    max-width:300px;
  }
  .btnmw{
    width:157px;
  }
  table {
    width: 100%;
    .MoreVertIcon {
      rotate: 90deg;
    }
  }
`;
