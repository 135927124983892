/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import NoteElement from "./NoteElement";
import { ReactComponent as SendSvg } from "../../assets/icons/sendMessage.svg";
import { ReactComponent as Xicon } from "../../assets/icons/xBtn.svg";

import { useNotesService } from "../../context/useNotesService";
import { notifyError, notifySucces } from "../../helper/Generic/Notifications";

import { selectUser } from "../../Redux/user/user";
import { useSelector } from "react-redux";
import { format_time_to_timezone } from "../../helper/Generic/dateTimeFormat";
import { checkPositionColor } from "../../helper/Analyst/SquadManager/checkPOSNCellColor";
import { checkFlag } from "../../helper/Generic/getFlagSrc";
const NotePopupPanel = ({ closeNote, fixture, player, loadInitialData, handleMessageicon, thisTeam }) => {

  const [inputValue, setInputValue] = useState("");
  const [homeTeam, setHomeTeam] = useState(null);
  const [awayTeam, setAwayTeam] = useState(null);
  const { getPlayerSelectionMessage, sendPlayerSelectionMessage, selectionConversation, deletePlayerSelectionMessage } = useNotesService();
  const fullName = player?.player.first_name + " " + player?.player.last_name;
  const user = useSelector(selectUser);
  const bottomRef = useRef(null);

  const handleClose = () => {
    if (player.has_message == false && selectionConversation?.messages.length > 0) {
      handleMessageicon(fixture, player, true);
    }
    if (player.has_message == true && selectionConversation?.messages.length == 0) {
      handleMessageicon(fixture, player, false);
    }

    closeNote();
  };


  const populateTeams = () => {
    if (fixture.fixture.participant1is_home) {
      setHomeTeam(fixture.fixture.team1);
      setAwayTeam(fixture.fixture.team2);
    }
    else {
      setHomeTeam(fixture.fixture.team2);
      setAwayTeam(fixture.fixture.team1);
    }
  };

  const _handleInputChange = (e) => {
    setInputValue(e.currentTarget.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleMessage();
    }
  };

  const handleMessage = () => {
    sendPlayerSelectionMessage(
      selectionConversation.id,
      inputValue,
      function (response) {
        getConversation();
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
    setInputValue("");
  };

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  };

  const getConversation = () => {
    getPlayerSelectionMessage(
      fixture.fixture.fixture_id,
      player.id,
      function (response) {
        checkHasMessage(response.data.selection_conversation);
      },
      function (error) {
        notifyError("Something wrong");
      });
  };

  const handleDeleteMessage = (item) => {
    deletePlayerSelectionMessage(
      item.id,
      function (response) {
        getConversation();
        notifySucces("Message Deleted");
      },
      function (error) {
        notifyError("Something wrong");

      }
    )
  }

  const checkHasMessage = (conversation) => {
    if (player.has_message == false && conversation?.messages?.length > 0) {
      handleMessageicon(fixture, player, true);
    }
    if (player.has_message == true && conversation?.messages?.length == 0) {
      handleMessageicon(fixture, player, false);
    }
  }

  useEffect(() => {
    populateTeams();
    getConversation();
  }, [fixture, player]);


  useEffect(() => {
    scrollToBottom();
  }, [selectionConversation]);

  return (
    <Container >
      <Header>
        <FirstRow>
          <TeamsTitle>
            {homeTeam?.name} - {awayTeam?.name}
          </TeamsTitle>

          <button onClick={() => handleClose()}><Xicon /></button>
        </FirstRow>
        <p>{format_time_to_timezone(fixture.fixture.start_time, user.timezone).format("DD/MM/YYYY HH:mm")}</p>
      </Header>
      <TeamDetails>
        <LeagueFlag>
          <img src={thisTeam?.logo_url} alt=""></img>
          <h1>{thisTeam?.name}</h1>
        </LeagueFlag>
        <PlayerInfo>
          <h1>{fullName}</h1>
          <Line> <PlayerNumber >
            <h2>{player?.player.squad_number}</h2>
          </PlayerNumber>
            <POSNCell className={checkPositionColor(player.position)}>
              <h1>{player.position}</h1>
            </POSNCell>
          </Line>
        </PlayerInfo>
      </TeamDetails>
      <Notes>
        {selectionConversation?.messages?.map((item, index) => (
          <NoteElement key={index} message={item} user={user} handleDeleteMessage={handleDeleteMessage} />
        ))}
        <div ref={bottomRef} />
      </Notes >
      <Inpt>
        <div className="inputGroup">
          <input
            placeholder="Type message here..."
            type={"text"}
            value={inputValue}
            onChange={(e) => _handleInputChange(e)}
            onKeyDown={(e) => handleKeyDown(e)}
          ></input>
          <button onClick={() => handleMessage()}>
            <SendSvg />
          </button>
        </div>
      </Inpt>
    </Container>
  );
};

export default NotePopupPanel;
const Container = styled.div`
position:fixed;
width: 265px;
height: 584px;
z-index:2;
box-shadow: 0px 4px 16px rgba(46, 46, 46, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 230px;
  background: ${({ theme }) => theme.notePanelBg};
  color: ${({ theme }) => theme.leagueColor};
  border-left: 3px solid ${({ theme }) => theme.leagueBG};
  bottom:20px;
  right:30px;
    border-radius: 10px;
    border: 1px solid #d4d5d9;
  }
  .green {
    border-color: #cbf2dc;
    background: #ebfcf3;
    h1 {
      color: #23a760;
    }
    p{
      color: #23a760;
    }
  }
  .blue {
    border-color: #D5DFF7;
    background: #E4ECFF;
    h1 {
      color: #4393FC;
    }
  }
  .orange {
    border-color: #F6DABD;
    background: #FFF3E7;
    h1 {
      color: #FF8D21;
    }
  }
  .red {
    border-color: #F9DDDD;
    background: #FFF5F5;
    h1 {
      color: #EC4848;
    }
    p{
      color: #EC4848;
    }
  }
`;
const Header = styled.div`
  padding: 20px 15px 10px 15px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.leagueBG};
  color: ${({ theme }) => theme.favoriteStarStroke};

  @media (max-width: 1800px) {
    border-bottom: 1px solid #d4d5d9;
  }
  h1 {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
  p {
    color: ${({ theme }) => theme.favoriteStarStroke};
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
  .xBtn {
    display: flex;
    justify-content: space-between;
  }
  button {
    color: ${({ theme }) => theme.favoriteStarStroke};
    background: transparent;
    border: none;
    cursor: pointer;
    font-weight: 700;
    width: fit-content;
  }
`;
const Notes = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  height: 100%;
  overflow: auto;
  row-gap: 15px;
  windiw.scroll
`;
const Inpt = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  padding: 10px;
  .inputGroup {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      min-width: 200px;
      border: none;
      background: ${({ theme }) => theme.notesBG};
      color: ${({ theme }) => theme.notesTextColor};
      border-radius: 30px;
      padding: 10px 40px 10px 20px;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      ::placeholder{
        color: ${({ theme }) => theme.notexInputColor};
      }
      :focus-visible {
        outline: none;
      }
    }
    button {
      cursor: pointer;
      background: transparent;
      border: none;
      position: absolute;
      right: 10px;
    }
  }
`;
const TeamDetails = styled.div`
display:flex;
flex-direction:column;
padding: 13px 22px ;
row-gap:15px;
`;
const LeagueFlag = styled.div`
display:flex;
column-gap:10px;
align-items:center;
img{
  object-fit:contain;
  height:16px;
  width:16px;
}
h1{
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
color: ${({ theme }) => theme.favoriteStarStroke
  }
`;
const PlayerInfo = styled.div`
display:flex;
flex-direction:column;
row-gap:5px;
h1{
  font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 21px;
color: ${({ theme }) => theme.favoriteStarStroke
  }
`;
const Line = styled.div`
display:flex;
column-gap:5px;
`;
const PlayerNumber = styled.div`
  border: 0.7px solid #cbd7f5;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 20px;
  width: 20px;
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.02em;
    color: rgba(0, 18, 41, 0.7);
  }
`;
const PlayerCell = styled.div`
  min-width: 100px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  h1 {
    cursor:pointer;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.03em;
    color: var(--darkBlue);
  }
`;
const POSNCell = styled(PlayerCell)`
min-width: 30px;
min-height:20px;
border: 1px solid #CBD7F5;
background: white;
display: flex;
justify-content: center;
padding: 3px 3px;
`;

const FirstRow = styled.div`
display:flex;
justify-content:space-between;
align-items:start;
column-gap:10px;
button{
  margin-top:2px;
}
`
const TeamsTitle = styled.div`
display:flex;
`