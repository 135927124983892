import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import ToggleDarkMode from "./ToggleDarkMode";
import { ReactComponent as UserSvg } from "../../assets/icons/user.svg";
import PageSelectorMenu from "./PageSelectorMenu";
import { ReactComponent as TimezoneIcon } from "../../assets/icons/timezoneIcon.svg";
import { selectUser } from "../../Redux/user/user";
import { useSelector } from "react-redux";
const HeaderMenu = ({ theme, toggleTheme }) => {
  const ref = useRef(null);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [page, setPage] = useState("Pricing");
  const [showDarkMode, setShowDarkMode] = useState(true);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const user = useSelector(selectUser);


  const checkPage = () => {
    if (location.pathname.includes("betting")) {
      setPage("Betting");
    }
    if (location.pathname.includes("pricing")) {
      setPage("Pricing");
    }
    if (location.pathname.includes("leagues")) {
      setPage("Leagues");
    }
    if (location.pathname.includes("audit")) {
      setPage("Audit");
    }
    if (location.pathname === "/") {
      setPage("Pricing");
    }
    if (location.pathname === "/userprofile") {
      setPage("Menu");
    }
  };


  const handleDarkMode = () => {
    if (location.pathname === "/pricing" || location.pathname === "/") {
      setShowDarkMode(true);
    } else {
      setShowDarkMode(false);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setAnchorEl(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  useEffect(() => {
    handleDarkMode();
    checkPage();
  }, [location]);

  return (
    <Container>

      {page !== "Menu" && (
        <ToggleDarkMode theme={theme} toggleTheme={toggleTheme} />
      )}
      <TimezoneCell>
        <TimezoneIcon />
        <h1>{user.timezone_name}</h1>
      </TimezoneCell>
      <div className="f-end">
        <PageSelectorMenu page={page} />

      </div>
      <UserSvg
        onClick={() => navigate("/userprofile")}
        style={{ cursor: "pointer" }}
        color={
          location.pathname === "/userprofile" ? "var(--primaryBlue)" : "white"
        }
      />
    </Container>
  );
};

export default HeaderMenu;
const Container = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  column-gap: 20px;
  min-width: 180px;
  img {
    cursor: pointer;
    height: 100%;
  }
  .f-end {
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;
  }
  
`;

const TimezoneCell = styled.div`
display:flex;
align-items:center;
padding:6px 20px;
column-gap:5px;
background: #263549;
border-radius: 20px;
h1{

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;

color: #FFFFFF;

}
`;