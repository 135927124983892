import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as XIcon } from "../../../assets/icons/xBtn.svg";
import EditLeagueSubmenuOption from "../EditLeagueComponents/EditLeagueSubmenuOption";
import {
  CURRENT_LEAGUES,
  DELETED_LEAGUES,
  editSeasonSubmenuList,
} from "../../../helper/Values/EditLeagueSubmenuValues";
import TeamElement from "../LeagueDetailsComponents/TeamElement";
import { getCountries } from "../../../api/user/GetCountriesListAdmin";
import { ReactComponent as BinSvg } from "../../../assets/icons/bin.svg";
import DeletedTeamElement from "../LeagueDetailsComponents/DeletedTeamElement";
import { useLeaguesDataService } from "../../../context/useLeaguesDataService";
import {
  notifyError,
  notifyInfoNoClose,
} from "../../../helper/Generic/Notifications";
import DeleteConfirmationDouble from "../../DeleteConfirmationDouble";
import LeagueElement from "../LeagueDetailsComponents/LeagueElement";
import { ReactComponent as SearchSvg } from "../../../assets/icons/search.svg";
import { getCompetitionsForTerm } from "../../../api/competitions/GetCompetitionsForTerm";
import LoaderSpinner from "../../../helper/LoaderSpinner";
import CountryMenuSingleOption from "../Menu/CountryMenuSingleOption";
import { getCompetitionsForCountryId } from "../../../api/competitions/GetCompetitionForCountryId";
import LeagueListForEditseason from "../SeasonComponents/LeagueListForEditSeason";
import SelectedTeamsListForEditCup from "../AddNewLeagueEvents/SelectedTeamsListForEditCup";
import SelectedLeaguesForEditSeason from "../SeasonComponents/SelectedLeaguesForEditSeason";
import CustomDateMenu from "../Menu/CustomDateMenu";
import { format_time_to_timezone } from "../../../helper/Generic/dateTimeFormat";
import { selectUser } from "../../../Redux/user/user";
import { useSelector } from "react-redux";
import moment from "moment";

const EditSeasonModal = ({
  selectedSeasonToEdit,
  handleCloseEditSeason,
  loadInitialData,
  handleDeleteLeagueFromSeason,
  shouldRefreshLeaguesAndCups,
  setSelectedSeasonsAndCups,
  handleUndoDeleteLeagueFromSeason,
  handleAddLeagueToSeason,
}) => {
  const { edit_season, archive_season, get_deleted_leagues_for_season } =
    useLeaguesDataService();
  const initialInputValues = {
    inputSearch: "",
  };
  const user = useSelector(selectUser);

  let parsedStartDate = format_time_to_timezone(
    selectedSeasonToEdit.season.start_date,
    user.timezone
  );
  let parsedEndDate = format_time_to_timezone(
    selectedSeasonToEdit.season.end_date,
    user.timezone
  );

  var start = null;
  var end = null;
  if (parsedStartDate.isValid()) {
    start = new Date(parsedStartDate);
  }

  if (parsedEndDate.isValid()) {
    end = new Date(parsedEndDate);
  }

  const [inputValues, setInputvalues] = useState(initialInputValues);
  const [submenuValue, setSubmenuValue] = useState(CURRENT_LEAGUES);
  const [seasonName, setSeasonName] = useState(
    selectedSeasonToEdit?.season?.name
  );
  const [readyToSave, setReadyToSave] = useState(false);
  const [deletedLeagues, setDeletedLeagues] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [countriesList, setCountriesList] = useState([]);
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState([]);

  const [selectedCompetitions, setSelectedCompetitions] = useState([]);

  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);

  const notifyNoCloseId = useRef();

  const handleNameInputChange = (e) => {
    setSeasonName(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputvalues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleCompetitions = () => {
    setIsLoading(true);
    getCompetitionsForCountryId(
      countryId,
      function (response) {
        setCompetitions(response.data.competitions);
        setIsLoading(false);
      },
      function (error) {
        notifyError("Something wrong");
        setIsLoading(false);
      }
    );
  };

  const handleSearchCompetition = () => {
    setIsLoading(true);
    setCountry([]);
    setCountryId([]);
    if (inputValues.inputSearch !== "") {
      getCompetitionsForTerm(
        inputValues.inputSearch,
        function (response) {
          setCompetitions(response.data.competitions);
          setIsLoading(false);
        },
        function (error) {
          setIsLoading(false);
          notifyError("Something wrong");
        }
      );
    } else {
      setCompetitions([]);
      setIsLoading(false);
    }
  };

  var content;

  const handleEditSeason = (season_id, seasonName, startDate, endDate) => {
    edit_season(
      season_id,
      seasonName,
      startDate,
      endDate,
      function (response) {
        shouldRefreshLeaguesAndCups.current = true;
        handleCloseEditSeason();
      },
      function (error) {
        notifyError("Something wrong");
        console.log(error);
      }
    );
  };

  const handleArchiveButton = (season_id) => {
    var archive = true;
    if (selectedSeasonToEdit.season.archived) {
      archive = false;
    }

    archive_season(
      season_id,
      archive,
      function (response) {
        setSelectedSeasonsAndCups((prev) => {
          let filtered = prev.seasons.filter(
            (element) => element !== response.data.season.id
          );
          return {
            ...prev,
            seasons: filtered,
          };
        });
        loadInitialData();
        handleCloseEditSeason();
      },
      function (error) {
        notifyError("Something wrong");
        console.log(error);
      }
    );
  };

  const handleGetDeletedLeagues = (season_id) => {
    get_deleted_leagues_for_season(
      season_id,
      function (response) {
        setDeletedLeagues(response.leagues);
      },
      function (error) {
        console.log(error);
        notifyError("Something wrong");
      }
    );
  };

  const undoDeleteHandler = (league_id) => {
    handleUndoDeleteLeagueFromSeason(league_id, function (response) {
      let filtered = deletedLeagues.filter((item) => item.id != league_id);
      setDeletedLeagues(filtered);
    });
  };

  const handleSaveButton = () => {
    switch (submenuValue) {
      case CURRENT_LEAGUES:
        handleEditSeason(
          selectedSeasonToEdit.season.id,
          seasonName,
          startDate,
          endDate
        );
        break;

      default:
        break;
    }
  };

  const checkChanges = () => {
    let momentStart = moment(startDate);
    let momentEnd = moment(endDate);

    if (
      (seasonName != selectedSeasonToEdit.season.name ||
        momentStart.toISOString() != moment(parsedStartDate).toISOString() ||
        momentEnd.toISOString() != moment(parsedEndDate).toISOString()) &&
      momentStart.isValid() &&
      momentEnd.isValid()
    ) {
      setReadyToSave(true);
    } else {
      setReadyToSave(false);
    }
  };
  const handleCountryList = () => {
    getCountries(
      function (response) {
        setCountriesList(response.data.countries);
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };

  const checkDuplicateCompetitions = () => {
    let mappedCompetitions = selectedSeasonToEdit.leagues.map((competition) => {
      return competition.competition_id;
    });
    const duplicates = mappedCompetitions.filter(
      (item, index) => mappedCompetitions.indexOf(item) !== index
    );
    if (duplicates.length > 0) {
      notifyInfoNoClose(
        notifyNoCloseId,
        "The season contains now duplicate leagues, please check the current leagues list!"
      );
    }
  };

  switch (submenuValue) {
    case CURRENT_LEAGUES:
      content = (
        <>
          <NameAndDateWrapper>
            <GroupContainer>
              <h3>NAME</h3>
              <CustomInputWrapper>
                <input
                  placeholder="Enter Season name"
                  name="seasonName"
                  onChange={(e) => handleNameInputChange(e)}
                  value={seasonName}
                />
              </CustomInputWrapper>
            </GroupContainer>
            <GroupContainer>
              <h3>SEASON START DATE</h3>
              <CustomDateMenu value={startDate} setValue={setStartDate} />
            </GroupContainer>
            <GroupContainer>
              <h3>SEASON END DATE</h3>
              <CustomDateMenu value={endDate} setValue={setEndDate} />
            </GroupContainer>
          </NameAndDateWrapper>

          <GroupContainer>
            <h3>Search by league name</h3>
            <SearchGroup>
              <SearchSvg />
              <ElementInput
                placeholder="Search leagues"
                name="inputSearch"
                onChange={(e) => handleInputChange(e)}
                value={inputValues.inputSearch}
              // onKeyDown={(e) => handleKeyDown(e)}
              />
              <button onClick={() => handleSearchCompetition()}> Search</button>
            </SearchGroup>
          </GroupContainer>

          <AddNewSeasonWrapper>
            <Left>
              <GroupContainer>
                <h3>Choose leagues from a specific country</h3>
                <CountryMenuSingleOption
                  label={"COUNTRY"}
                  countriesList={countriesList}
                  setCountry={setCountry}
                  country={country}
                  setCountryId={setCountryId}
                  countryId={countryId}
                  setInputvalues={setInputvalues}
                />
                {isLoading && <LoaderSpinner />}
              </GroupContainer>

              <LeagueListForEditseason
                country={country}
                competitions={competitions}
                selectedCompetitions={selectedCompetitions}
                setSelectedCompetitions={setSelectedCompetitions}
                handleAddLeagueToSeason={handleAddLeagueToSeason}
              />
            </Left>
            <Right>
              <GroupContainer>
                <h3>Selected Leagues</h3>
                <SelectedLeaguesForEditSeason
                  value={selectedCompetitions}
                  handleDeleteLeagueFromSeason={handleDeleteLeagueFromSeason}
                />
              </GroupContainer>
            </Right>
          </AddNewSeasonWrapper>
        </>
      );
      break;

    case DELETED_LEAGUES:
      content = (
        <>
          <LeaguesListContainer>
            {deletedLeagues?.map((league, index) => (
              <DeletedTeamElement
                key={index}
                handleRevert={undoDeleteHandler}
                item={league}
                displayUpdatedAt={true}
              />
            ))}
          </LeaguesListContainer>
        </>
      );
      break;

    default:
      break;
  }
  useEffect(() => {
    handleCountryList();
  }, []);

  useEffect(() => {
    if (country.length > 0) {
      handleCompetitions();
    }
  }, [country]);

  useEffect(() => {
    if (!selectedSeasonToEdit) {
      return;
    }

    handleGetDeletedLeagues(selectedSeasonToEdit.season.id);
    setSelectedCompetitions(selectedSeasonToEdit.leagues);
    checkDuplicateCompetitions();
  }, [selectedSeasonToEdit]);

  useEffect(() => {
    checkChanges();
  }, [seasonName, startDate, endDate]);

  return (
    <Container>
      <Card>
        <CardContent>
          <Top>
            <h1>Edit season</h1>
            <CloseButton onClick={() => handleCloseEditSeason()}>
              <XIcon />
            </CloseButton>
          </Top>
          <SubmenuWrapper>
            <EditLeagueSubmenuOption
              value={submenuValue}
              setValue={setSubmenuValue}
              valuesList={editSeasonSubmenuList}
            />
          </SubmenuWrapper>
          <Content>{content}</Content>

          <Bottom>
            {selectedSeasonToEdit?.season?.archived ? (
              <ArchiveBtn
                onClick={() =>
                  handleArchiveButton(selectedSeasonToEdit.season.id)
                }
              >
                <BinSvg />
                Unarchive Season
              </ArchiveBtn>
            ) : (
              <DeleteConfirmationDouble
                handleRemoveItem={handleArchiveButton}
                variant={"archive_season"}
                item={selectedSeasonToEdit?.season?.id}
                season={selectedSeasonToEdit}
              />
            )}

            <SaveBtn disabled={!readyToSave} onClick={() => handleSaveButton()}>
              Save
            </SaveBtn>
          </Bottom>
        </CardContent>
      </Card>
    </Container>
  );
};

export default EditSeasonModal;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  z-index: 4;
  overflow: hidden;
  padding: 0 20vw;
`;

const Card = styled.div`
  position: sticky;
  background: ${({ theme }) => theme.editLeague.bg};
  top: 5vh;
  max-height: 80vh;
  height: 100%;
  width: 100%;
  overflow: hidden;
  @media (max-height: 1024px) {
    top: 2vh;
    max-height: 90vh;
  }
  border-radius: 16px;
`;
const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px 40px;
  background: ${({ theme }) => theme.editLeague.headerBg};

  h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: ${({ theme }) => theme.editLeague.headerColor};
  }
`;

const CloseButton = styled.div`
  cursor: pointer;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
      svg{
        color: ${({ theme }) => theme.editLeague.headerColor};
     }
`;

const SubmenuWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 25px 40px;
  border-bottom: 3px solid ${({ theme }) => theme.editLeague.borderColor};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 25px 40px;
  position: relative;
  height: 100%;
  overflow: auto;
`;

const SeasonNameWrapper = styled.div``;
const LeaguesNameWrapper = styled.div``;

const Bottom = styled.div`
  background: ${({ theme }) => theme.editLeague.headerBg};
  padding: 10px 40px;
  display: flex;
  justify-content: space-between;
`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  position: relative;
  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
`;

const LeaguesListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const CustomInputWrapper = styled.div`
  input {
    background: ${({ theme }) => theme.editLeague.readOnlyInputs};
    border: 1px solid #bac3e8;
    border-radius: 6px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.editLeague.menuTextColor};
    ::placeholder {
      color: ${({ theme }) => theme.editLeague.menuTextColor};
      opacity: 0.7;
    }
    padding: 11px 15px;
  }
`;
const ArchiveBtn = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #ec4848;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;
const SaveBtn = styled.button`
  background-color: var(--primaryBlue);
  color: white;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding: 12px 27px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`;

const AddNewSeasonWrapper = styled.div`
  display: flex;
  gap: 20px;
  height: 100%;
`;

const ElementInput = styled.input`
  height: 40px;
  background: ${({ theme }) => theme.editLeague.readOnlyInputs};
  width: 280px;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  padding: 10px 15px;
`;
const SearchGroup = styled.div`
  display: flex;
  position: relative;
  column-gap: 10px;
  input {
    padding-left: 30px;
  }
  svg {
    position: absolute;
    top: 13px;
    left: 10px;
  }
  button {
    background: ${({ theme }) => theme.editLeague.saveAllBtnBg};
    border: 1px solid #006fff;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.editLeague.buttonWithConfirmationTextColor};
    padding: 7px 28px;
    cursor: pointer;
  }
`;

const Left = styled.div`
  min-width: 280px;
  border-right: 1.5px solid #eef2f6;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const Right = styled.div`
  padding: 20px 0 10px 15px;
  width: 100%;
  // border-left: 1.5px solid #eef2f6;
`;
const NameAndDateWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
