import { POST } from "../../APiHelper/API"


export const addPlayerToTeam = (
    team_id,
    first_name,
    last_name,
    short_name,
    other_player_name,
    squad_number,
    position_1,
    position_2,
    position_3,
    wy_id,
    onSuccess,
    onError
) => {
    const data = {
        team_id: team_id,
        first_name: first_name,
        position_1: position_1,
        position_2: position_2,
        position_3: position_3,
        squad_number: squad_number,
        last_name: last_name,
        short_name: short_name,
        other_player_name: other_player_name,
        wy_id: wy_id,
    }
    POST(`/api/v1/leagues/player`, data, onSuccess, onError)
}