import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";

const RaceTrackMenu = ({ label, value, setValue, disabled }) => {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState("");

  const valuesList = [
    {
      value: true,
    },
    {
      value: false,
    },
  ];

  const handleMenuOpen = () => {
    if (!disabled) {
      if (isMenuOpen) {
        setIsMenuOpen(false);
      } else {
        setIsMenuOpen(true);
      }
    }
  };

  const showValue = (value) => {
    if (value === true) {
      return <h4>Yes</h4>;
    }
    if (value == false) {
      return <h4>No</h4>;
    }
  };
  const showListValue = (value) => {
    if (value === true) {
      return <p>Yes</p>;
    }
    if (value == false) {
      return <p>No</p>;
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
        setInputSearch("");
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref}>
      <DropDownMenu
        onClick={() => handleMenuOpen()}
        isMenuOpen={isMenuOpen}
        disabled={disabled}
      >
        {value != null ? showValue(value) : <h5>{label}</h5>}

        {isMenuOpen ? <UpSvg /> : <DownSvg />}
      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>
          {valuesList.map((item, index) => (
            <DropDownMenuItem key={index} onClick={() => setValue(item.value)}>
              <input
                type={"radio"}
                checked={value == item.value}
                onChange={() => setValue(item.value)}
              ></input>
              {showListValue(item.value)}
            </DropDownMenuItem>
          ))}
        </DropDownList>
      )}
    </Container>
  );
};

export default RaceTrackMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  position: relative;
  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: rgba(60, 74, 92, 1);
  }
`;
const DropDownMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.editLeague.selectMenuBg};
  padding: 11px 15px;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  border-radius: ${(props) => (props.isMenuOpen ? "8px 8px 0 0" : "")};
  .group {
    align-items: center;
    display: flex;
    column-gap: 5px;
    img {
      height: 14px;
      width: 18px;
      border-radius: 4px;
    }
  }
  img {
    cursor: ${(props) => (props.disabled === true ? "inherir" : "pointer")};
    height: 21px;
    width: 22px;
  }
  svg {
    cursor: ${(props) => (props.disabled === true ? "inherir" : "pointer")};
    fill:${({ theme }) => theme.editLeague.selectMenuSvgBg};
    stroke:${({ theme }) => theme.editLeague.selectMenuSvgColor}
  }
  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
  }
  h5 {
    text-transform: capitalize;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ababab;
  }
`;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top: 39px;
  border: 1px solid #bac3e8;
  border-top: 1px solid #3c4a5c;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  max-height: 400px;
  width: 100px;
  overflow-x: hidden;
  color: black;
  background-color: ${({ theme }) => theme.editLeague.selectMenuBg};
  .searchGroup {
    position: relative;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
    input {
      align-items: center;
      position: relative;
      width: 100%;
      padding: 4px 10px 2px 20px;
      background: #eef2fd;
      border: none;
      border: 1px solid #d9dfe7;
      border-radius: 8px;
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      :focus-visible {
        outline: none;
        border: 1px solid #006fff;
      }
     
    }
    svg {
      position: absolute;
      z-index: 1;
      top: 8, 5px;
      left: 7px;
    }
  }
`;
const DropDownMenuItem = styled.li`
background-color: ${({ theme }) => theme.editLeague.selectMenuBg};
  cursor: pointer;
  display: flex;
  column-gap: 5px;
  align-items: center;
  img {
    border-radius: 4px;
    height: 14px;
    width: 18px;
  }

  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;

  padding: 5px 10px;
  div {
    column-gap: 5px;
    align-items: center;
    display: flex;
    flex-direction: row;
    .check {
      margin-right: 5px;
      background: red;
      color: red;
    }
  }

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
  }
  
`;
