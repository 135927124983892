import { POST } from "../APiHelper/API";


export const parkBet = (bet_id, no_move, no_price, screen_limit, markets, onSuccess, onError) => {
    const data = {
        bet_id: bet_id,
        no_move: no_move,
        no_price: no_price,
        screen_limit: screen_limit,
        markets: markets,
    }
    POST("/api/v1/bets/park_bet", data, onSuccess, onError);
}