import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
	user: undefined,
};

const userSlice = createSlice({
	name: "user",
	initialState: INITIAL_STATE,
	reducers: {
		loginUser: (state, action) => {
			state.user = action.payload;
		},
		logoutUser: (state) => {
			state.user = null;
		},
	},
});
export const { loginUser, logoutUser } = userSlice.actions;
export const selectUser = (state) => state.user.user;
export default userSlice.reducer;
