import cloneDeep from "lodash.clonedeep";
import { calculateHAHDAAADValues, calculateModel1X2Values, calculateModelHAMainLine, calculateModelOUMainLine, calculateModelSupTgx, computeSubLines } from "../fixturesPricingHelper";

export const mapBetsAndPrices = (bets, pricing_prices) => {
    let newData = []

    bets?.forEach(element => {
        const newElement = concatBetsWithPrices(element, pricing_prices);
        newData.push(newElement)
    }

    );
    return newData;
}


const concatBetsWithPrices = (bet, prices) => {

    const newElement = cloneDeep(bet);
    const price = prices.find((element) => element.fixture_id === bet.fixture_id)
    newElement.prices = price;

    return newElement;
}

export const calculateBetsPrices = (bets) => {
    let newBets = [];
    bets?.forEach((bet) => {
        let complexBet = cloneDeep(bet);

        // Model simple values
        let handicapValues = calculateHAHDAAADValues(bet.prices);
        let ha = handicapValues.ha;
        let hd = handicapValues.hd;
        let aa = handicapValues.aa;
        let ad = handicapValues.ad;
        let model1X2Values = calculateModel1X2Values(bet.prices);
        let model_1 = model1X2Values.model_1;
        let model_x = model1X2Values.model_x;
        let model_2 = model1X2Values.model_2;
        let modelSupTXG = calculateModelSupTgx(bet.prices);
        let model_sup = modelSupTXG.model_sup;
        let model_tgx = modelSupTXG.model_tgx;

        // Model HA main line
        const modelHAMainLine = calculateModelHAMainLine(bet.prices);
        let model_ha_value = modelHAMainLine.model_ha_value;
        let model_h = modelHAMainLine.model_h;
        let model_a = modelHAMainLine.model_a;

        // Model OU main line
        const modelOUMainLine = calculateModelOUMainLine(bet.prices);
        let model_ou_value = modelOUMainLine.model_ou_value;
        let model_o = modelOUMainLine.model_o;
        let model_u = modelOUMainLine.model_u;

        const sublines = computeSubLines(bet.prices);

        complexBet.calculatedValues = {
            ha: ha,
            hd: hd,
            aa: aa,
            ad: ad,
            model_1: model_1,
            model_x: model_x,
            model_2: model_2,
            model_sup: model_sup,
            model_tgx: model_tgx,
            model_ha_value: model_ha_value,
            model_h: model_h,
            model_a: model_a,
            model_ou_value: model_ou_value,
            model_o: model_o,
            model_u: model_u,
            sublines: sublines,
        }
        newBets.push(complexBet)
    })

    return newBets;

}