import React from "react";

import styled from "styled-components";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/entry.nostyle";
import "../../../theme/dateRangePicker.css";
import "../../../theme/react-calendar.css";
import { useSelector } from "react-redux";
import { selectUser } from "../../../Redux/user/user";
import moment from "moment";

const DropDownMenuDateRangePicker = (props) => {
  const user = useSelector(selectUser);
  let minDate = moment(new Date()).startOf('day').toDate();

  return (
    <Container>
      <DateRangePicker
        className={props.selectedValue == null ? "disabledXbtn" : ""}
        minDate={minDate}
        format="dd/MM/y"
        onChange={props.setSelectedValue}
        value={props.selectedValue}
      />
    </Container>
  );
};

export default DropDownMenuDateRangePicker;

const Container = styled.div`
  display: flex;
  .react-daterange-picker {
    display: inline-flex;
    position: relative;
    width:220px;
  }
  .react-daterange-picker,
  .react-daterange-picker *,
  .react-daterange-picker *:before,
  .react-daterange-picker *:after {
    font-weight: 700;
    font-size: 10px;
    background-color: ${({ theme }) => theme.menuBgColor};
    border-radius: 8px;
  }
  .react-daterange-picker__inputGroup__input--hasLeadingZero {
    margin-left: 0;
    padding:0;
  }
.disabledXbtn{
  .react-daterange-picker__clear-button{
    display:none;
  }
}
  .react-daterange-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    border: none;
    padding: 0 5px;
  }
  .react-daterange-picker__inputGroup{
    display:flex;
    justify-content:center;
    align-items:center;
  }
  .react-daterange-picker__inputGroup__input {
    padding:0;
    align-items:center;
    color: ${({ theme }) => theme.menuTextColor};
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: ${({ theme }) => theme.menuBgColor};
  }
  .react-daterange-picker__range-divider {
    color: ${({ theme }) => theme.menuTextColor};
  }
  .react-daterange-picker__button:enabled:focus
    .react-daterange-picker__button__icon {
    stroke: ${({ theme }) => theme.dropDownColor};
  }
  .react-daterange-picker__button:disabled
    .react-daterange-picker__button__icon {
    stroke: ${({ theme }) => theme.dropDownColor};
  }
  .react-daterange-picker__button {
    svg {
      height:12px;
      stroke: ${({ theme }) => theme.dropDownSvgStroke};
    }
  }
  .react-daterange-picker__button:enabled:hover
    .react-daterange-picker__button__icon,
  .react-daterange-picker__button:enabled:focus
    .react-daterange-picker__button__icon {
    stroke: ${({ theme }) => theme.dropDownColor};
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  .react-calendar__tile--active {
    background: ${({ theme }) => theme.matchElementBgindexR};
    color: ${({ theme }) => theme.dropDownColor};
  }
  .react-daterange-picker__button:disabled
    .react-daterange-picker__button__icon {
    stroke: red;
  }
  .react-calendar__navigation button:disabled {
    background-color: ${({ theme }) => theme.matchElementDetailsBgL};
  }
  .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background-color: ${({ theme }) => theme.matchElementDetailsBgR};
}
`;
