import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import DropdownMenuRole from "./DropDown/DropdownMenuRole";
import DropDownMenuCountry from "./DropDown/DropDownMenuCountry";
import LeaguesList from "./LeaguesList";
import SelectionList from "./SelectionList";
import { Button, Switch } from "@mui/material";
import { getOtherUsersRoles, getUsersRoles } from "../../api/user/GetUsersRoles";
import { deleteUser } from "../../api/user/Deleteuser";
import { getOtherUsers } from "../../api/user/GetOtherUsers";
import { updateUser } from "../../api/user/UpdateOtherUser";
import { notifyError, notifySucces } from "../../helper/Generic/Notifications";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactComponent as BinSvg } from "../../assets/icons/bin.svg";
import { ReactComponent as SearchSvg } from "../../assets/icons/search.svg";
import { getCountries } from "../../api/user/GetCountriesListAdmin";
import { getCompetitionsForCountryId } from "../../api/competitions/GetCompetitionForCountryId";
import { getCompetitionsForTerm } from "../../api/competitions/GetCompetitionsForTerm";
import DropDownMenuSeasonCup from "./DropDown/DropdownSeasonCup";
import SeasonCupList from "./SeasonCupList";
import { useLeaguesDataService } from "../../context/useLeaguesDataService";

const EditTeamMemberModal = (props) => {
  const COMPETITIONS = "competitons";
  const SEASONCUP = "season/cup";


  const { get_cups_for_admin, get_seasons_for_admin } = useLeaguesDataService()
  const [inputEmail, setInputEmail] = useState(props.member.email);
  const [inputSearch, setInputSearch] = useState("");
  const [role, setRole] = useState(props.member.roles[0]);
  const [country, setCountry] = useState(null);
  const [competitions, setCompetitions] = useState([]);
  const [selectedCompetitions, setSelectedCompetitions] = useState([]);

  const [initialCompetitions, setInitialCompetitions] = useState([])
  const [initialSeasons, setInitialSeasons] = useState([])
  const [initialCups, setInitialCups] = useState([])

  const [allLeagues, setallLeagues] = useState(props.member.all_competitions_access);
  const [allSeasons, setAllSeasons] = useState(props.member.all_seasons_access);
  const [allCups, setAllCups] = useState(props.member.all_cups_access);

  const [rolesList, setRolesList] = useState([]);
  const [open, setOpen] = useState(false);
  const [changes, setChanges] = useState(false);
  const [countriesList, setCountriesList] = useState([]);

  const [selectedSeasons, setSelectedSeasons] = useState([]);
  const [selectedCups, setSelectedCups] = useState([]);

  const [seasonsList, setSeasonsList] = useState([]);
  const [cupsList, setCupsList] = useState([]);

  const [access, setAccess] = useState(COMPETITIONS);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEmailInput = (e) => {
    setInputEmail(e.target.value);
  };

  const handleGetSeasonsForAdmin = () => {
    const data = {}
    get_seasons_for_admin(
      data,
      (response) => {
        setSeasonsList(response)
      },

      (error) => {
        console.log(error)
      }

    )
  }

  const handleGetCupsForAdmin = () => {
    const data = {}
    get_cups_for_admin(
      data,
      (response) => {
        setCupsList(response.data.cups)
      },

      (error) => {
        console.log(error)
      }
    )
  }

  const handleallLeagues = (event) => {
    setSelectedCompetitions([]);
    setCompetitions([]);
    setallLeagues(event.target.checked);
    setCountry(null);
  };

  const unselectAllCompetitions = () => {
    setSelectedCompetitions([]);
    setCompetitions([]);
    setallLeagues(false);
    setCountry(null);
  };

  const unselectAllSeasons = () => {
    setSelectedSeasons([]);
    setAllSeasons(false);
  };

  const unselectAllCups = () => {
    setSelectedCups([]);
    setAllCups(false);
  };

  const handleAllSeasons = (event) => {
    setSelectedSeasons([]);
    setAllSeasons(event.target.checked);

  };

  const handleAllCups = (event) => {
    setSelectedCups([]);
    setAllCups(event.target.checked);
  };

  const handleSearchInput = (e) => {
    setInputSearch(e.target.value);
  };


  const getUserRoles = () => {
    getUsersRoles(
      function (response) {
        setRolesList(response.data.roles);
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };


  const checkChanges = () => {

    let competitionsIds = selectedCompetitions.map((item) => {
      return item.competition_id
    })

    let initialCompetitionsIds = initialCompetitions.map((item) => {
      return item.competition_id
    })

    competitionsIds = competitionsIds.sort((a, b) => a > b ? 1 : -1)
    initialCompetitionsIds = initialCompetitionsIds.sort((a, b) => a > b ? 1 : -1)

    let seasonsIds = selectedSeasons.map((item) => {
      return item.id
    })

    let initialSeasonsIds = initialSeasons.map((item) => {
      return item.id
    })

    seasonsIds = seasonsIds.sort((a, b) => a > b ? 1 : -1)
    initialSeasonsIds = initialSeasonsIds.sort((a, b) => a > b ? 1 : -1)

    let cupsIds = selectedCups.map((item) => {
      return item.id
    })
    let initialCupsIds = initialCups.map((item) => {
      return item.id
    })

    cupsIds = cupsIds.sort((a, b) => a > b ? 1 : -1)
    initialCupsIds = initialCupsIds.sort((a, b) => a > b ? 1 : -1)

    if (
      role !== props.member.roles[0] ||
      !_.isEqual(competitionsIds, initialCompetitionsIds) ||
      !_.isEqual(seasonsIds, initialSeasonsIds) ||
      !_.isEqual(cupsIds, initialCupsIds) ||
      allCups != props.member.all_cups_access ||
      allLeagues != props.member.all_competitions_access ||
      allSeasons != props.member.all_seasons_access

    ) {
      setChanges(true);
    } else {
      setChanges(false);
    }
  };

  const handleEditUser = () => {

    const competitionsIds = selectedCompetitions.map((item) => {
      return item.competition_id
    })

    const seasonsIds = selectedSeasons.map((item) => {
      return item.id
    })

    const cupsIds = selectedCups.map((item) => {
      return item.id
    })

    updateUser(
      props.member.id,
      allLeagues,
      allSeasons,
      allCups,
      competitionsIds,
      seasonsIds,
      cupsIds,
      role,
      function () {
        props.setOtherUsers([]);
        getOtherUsers(
          function (response) {
            props.setOtherUsers(response.data.users);
            props.setShowEditTeamMember(false);
            notifySucces("Team member edited");
          },
          function (error) {
            notifyError("Something wrong");
          }
        );
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };

  const geUserSelectedCompetitions = () => {
    getOtherUsersRoles(
      props.member.id,
      function (response) {

        if (!props.member.all_competitions_access) {
          setSelectedCompetitions(response.data.competitions);
          setInitialCompetitions(response.data.competitions)
        }

        if (!props.member.all_cups_access) {
          setSelectedCups(response.data.cups)
          setInitialCups(response.data.cups)
        }

        if (!props.member.all_seasons_access) {
          setSelectedSeasons(response.data.seasons)
          setInitialSeasons(response.data.seasons)
        }

      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };

  const handleCountries = () => {
    getCountries(
      function (response) {
        setCountriesList(response.data.countries);
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };

  const handleCompetitionList = () => {
    if (country !== null) {
      getCompetitionsForCountryId(
        [country.country_id],
        function (response) {
          setCompetitions(response.data.competitions);
        },
        function (error) {
          notifyError("Something wrong");
        }
      );
    }
  };

  const handleSearchCompetition = () => {
    setCountry(null);
    if (access == SEASONCUP) {
      return
    }
    if (inputSearch !== "") {
      getCompetitionsForTerm(
        inputSearch,
        function (response) {
          setCompetitions(response.data.competitions);
        },
        function (error) {
          notifyError("Something wrong");
        }
      );
    } else {
      setCompetitions([]);
    }
  };

  const handleDeleteUser = () => {
    deleteUser(
      props.member.id,
      function () {
        getOtherUsers(
          function (response) {
            props.setOtherUsers(response.data.users);
            props.setShowEditTeamMember(false);
            setOpen(false);
            notifySucces("User deleted");
          },
          function (error) {
            notifyError("Something wrong");
          }
        );
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearchCompetition();
    }
  };

  const handleAccessSelect = (acessTo) => {
    setAccess(acessTo);
    setCompetitions([]);
    setInputSearch("")
    setCountry(null);
  };


  useEffect(() => {
    handleCountries();
    geUserSelectedCompetitions();
    getUserRoles();
    handleGetCupsForAdmin();
    handleGetSeasonsForAdmin();
  }, []);

  useEffect(() => {
    handleCompetitionList();
  }, [country]);

  useEffect(() => {
    checkChanges();
  }, [role,
    selectedCompetitions,
    initialCompetitions,
    selectedCups,
    selectedSeasons,
    initialCups,
    initialSeasons]);

  return (
    <Container>
      {/* <Sticker> */}
      <Card>
        <Top>
          <h2>Edit member details</h2>
          <div onClick={() => props.setShowEditTeamMember(false)}>
            <CloseIcon style={{ cursor: "pointer" }} />
          </div>
        </Top>

        <Middle>
          <Section>
            <h2>Send an invite to your team member via email</h2>
            <ColumnGroup>
              <label>Email</label>
              <CustomInput
                type={"email"}
                value={inputEmail}
                onChange={handleEmailInput}
                placeholder="email@whiteswam.com"
              ></CustomInput>
            </ColumnGroup>
          </Section>
          <Section>
            <h2>Choose the member role</h2>
            <DropdownMenuRole
              label={"Roles"}
              itemsSource={rolesList}
              selectedValue={role}
              setSelectedValue={setRole}
            />
          </Section>
          <Section>
            <h2>Interface</h2>
            <SelectEventType>
              <Event
                onClick={() => handleAccessSelect(COMPETITIONS)}
                selected={access === COMPETITIONS}
              >
                <p>{COMPETITIONS}</p>
              </Event>
              <Event
                onClick={() => handleAccessSelect(SEASONCUP)}
                selected={access === SEASONCUP}
              >
                <p>{SEASONCUP}</p>
              </Event>
            </SelectEventType>
          </Section>
          <Section>
            {access === COMPETITIONS && (
              <Line>
                <h2>Competitions access</h2>
                <SeletAllWrapper style={{ marginLeft: "auto" }}>
                  <h3 style={{ fontWeight: 400 }}>
                    access to all competitions
                  </h3>
                  <Switch
                    checked={allLeagues}
                    onChange={handleallLeagues}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </SeletAllWrapper>
              </Line>
            )}

            {access === SEASONCUP && (
              <Line>
                <h2>Seasons and cups access</h2>
                <SeletAllWrapper style={{ marginLeft: "auto" }}>
                  <h3 style={{ fontWeight: 400 }}>
                    access to all seasons
                  </h3>
                  <Switch
                    checked={allSeasons}
                    onChange={handleAllSeasons}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </SeletAllWrapper>
                <SeletAllWrapper style={{ marginLeft: "auto" }}>
                  <h3 style={{ fontWeight: 400 }}>
                    access to cups
                  </h3>
                  <Switch
                    checked={allCups}
                    onChange={handleAllCups}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </SeletAllWrapper>
              </Line>
            )}

            <ColumnGroup >
              {access === COMPETITIONS ? <label>Search by league name</label> : <label>Search by season cup name</label>}
              <SearchWrapper>
                <SearchSvg />
                <CustomInput
                  onKeyDown={(e) => handleKeyDown(e)}
                  type={"text"}
                  value={inputSearch}
                  onChange={handleSearchInput}
                  placeholder="Search"
                ></CustomInput>
                <button onClick={() => handleSearchCompetition()}>
                  {" "}
                  Search
                </button>
              </SearchWrapper>
            </ColumnGroup>

          </Section>

          <Section style={{ flexDirection: "row", }}>
            {access === COMPETITIONS && (
              <Section >
                <label>Choose competitions from a specific country</label>
                <div>
                  <DropDownMenuCountry
                    label={"Country"}
                    itemsSource={countriesList}
                    selectedValue={country}
                    setSelectedValue={setCountry}
                    disabled={allLeagues}
                  />
                  <LeaguesList
                    country={country}
                    competitions={competitions}
                    selectedCompetitions={selectedCompetitions}
                    setSelectedCompetitions={setSelectedCompetitions}
                  />
                </div>
              </Section>
            )}

            {access === SEASONCUP && (
              <Section>
                <label>Choose seasons/cups</label>
                <ColumnGroup>
                  <DropDownMenuSeasonCup
                    label={"Season"}
                    listItems={seasonsList}
                    itemsSource={countriesList}
                    setSelectedItems={setSelectedSeasons}
                    selectedItems={selectedSeasons}
                    disabled={allSeasons}
                    searchTerm={inputSearch}

                  />
                  <DropDownMenuSeasonCup
                    disabled={allCups}
                    label={"Cup"}
                    listItems={cupsList}
                    itemsSource={countriesList}
                    setSelectedItems={setSelectedCups}
                    selectedItems={selectedCups}
                    searchTerm={inputSearch}

                  />
                </ColumnGroup>
              </Section>
            )}
            <hr
              style={{
                margin: "0 25px ",
                minWidth: "3px",
                background: "#EEF2F6",
                border: "none",
              }}
            />

            {access === COMPETITIONS &&
              <Section>
                <h2>Your selection</h2>
                <SelectionList
                  allLeagues={allLeagues}
                  country={country}
                  selectedCompetitions={selectedCompetitions}
                  setSelectedCompetitions={setSelectedCompetitions}
                  unselectAllCompetitions={unselectAllCompetitions}
                />
              </Section>
            }
            {access === SEASONCUP &&
              <Section>
                <h2>Your selection</h2>
                <SelectionList
                  country={country}
                  selectedCups={selectedCups}
                  setSelectedCups={setSelectedCups}
                  selectedSeasons={selectedSeasons}
                  setSelectedSeasons={setSelectedSeasons}
                  unselectAllSeasons={unselectAllSeasons}
                  unselectAllCups={unselectAllCups}
                  allSeasons={allSeasons}
                  allCups={allCups}
                />
              </Section>
            }
          </Section>
        </Middle>

        <Bottom>
          <button onClick={() => handleClickOpen()} className="deteleButton">
            <BinSvg />
            Remove member
          </button>
          <button
            disabled={!changes}
            onClick={() => handleEditUser()}
            className="savebutton"
          >
            Save
          </button>
        </Bottom>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete user?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDeleteUser} autoFocus>
            Delete user
          </Button>
        </DialogActions>
      </Dialog>
      {/* </Sticker> */}
    </Container>
  );
};

export default EditTeamMemberModal;

const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  z-index: 5;
  background-color: rgba(119, 124, 134, 0.5);
  height:100%;
  padding:20px;
`;

const Card = styled.div`
  display: flex;
  position: relative;
  width: 616px;
  height: 100%;
  max-height:820px;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 1px 8px rgba(46, 46, 46, 0.04);
`;

const Top = styled.div`
  display: flex;
  padding: 12px 23px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background: var(--gradient1);
  display: flex;
  justify-content: space-between;
  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
`;

const Middle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  row-gap: 15px;
  height:100%;
overflow:auto;
  h2 {
    color: var(--darkBlue);
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
  }

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--darkBlue);
  }



`;

const CustomInput = styled.input`
font-size:14px;
line-height:16px;
 border: 1px solid #d9dfe7;
    border-radius: 4px;
    padding: 11px 16px;
    ::placeholder {
      color: #b3b9c0;
    }
    :focus-visible {
      outline: 0px;
      //outline-offset: 1px;
      border: 1px solid var(--primaryBlue);
    }
`

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
width:100%;
`;

const ColumnGroup = styled.div`
 display: flex;
    flex-direction: column;
    row-gap: 8px;
    width:100%;
`


const Bottom = styled.div`
  display: flex;
  margin-top: auto;
  background: var(--gradient1);
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  display: flex;
  justify-content: space-between;
  padding: 12px 23px;
  .deteleButton {
    display: flex;
    column-gap: 10px;
    align-items: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    color: #ff7979;
    background: none;
    border: none;
  }
  .savebutton {
    padding: 12px 27px;
    border-radius: 4px;
    border: none;
    background-color: var(--primaryBlue);
    color: white;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    :disabled {
      opacity: 0.5;
      cursor: inherit;
    }
  }
`;


const SelectEventType = styled.div`
  display: flex;
  border: 1px solid
    ${({ theme }) => theme.editLeague.addEventModal.selectedOptionBg};
  width: fit-content;
  border-radius: 2px;
`;
const Event = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 30px;
  min-width: 100px;
  max-width: 100px;
  width: 100%;
  cursor: pointer;
  background: ${(props) =>
    props.selected
      ? ({ theme }) => theme.editLeague.addEventModal.selectedOptionBg
      : ({ theme }) => theme.editLeague.addEventModal.optionBg};
  p {
    font-family: Roboto;
    font-size: 9px;
    font-weight: 700;
    line-height: 11px;
    text-align: center;
    text-transform: uppercase;
    color: ${(props) =>
    props.selected
      ? ({ theme }) => theme.editLeague.addEventModal.selectedOptionColor
      : ({ theme }) => theme.editLeague.addEventModal.optionColor};
  }
`;

const SeletAllWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  position: relative;
  column-gap: 10px;
  input {
    width: 100%;
    padding-left: 30px;
  }

  svg {
    position: absolute;
    top: 12px;
    left: 10px;
  }

  button {
    background: #ffffff;
    border: 1px solid #006fff;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--primaryBlue);
    padding: 7px 28px;
    cursor: pointer;
  }
`;
