import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as BackToLeaguesIcon } from "../../assets/icons/LeaguesIcons/backToLeaguesBtn.svg";
import {
    createSearchParams,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import { FullScreenSpinner } from "../../components/genericComponents/FullScreenSpinner";
import LeaguesHeader from "../../components/leaguesComponents/LeaguesHeader";
import qs from "query-string";
import PlayerStatsRow from "../../components/playersData Components/PlayerStatsRow";
import PlayerStatsHeader from "../../components/playersData Components/PlayerStatsHeader";
import { ReactComponent as EditPencilIcon } from "../../assets/icons/LeaguesIcons/editPencilIcon.svg";

import { usePlayerStatsService } from "../../context/usePlayerStatsService";
import { notifyError } from "../../helper/Generic/Notifications";
import {
    GK_POSITIONS,
    DEF_POSITIONS,
    MID_POSITIONS,
    ATK_POSITIONS,
    GK,
    DEFENSIVE,
    MIDFIELD,
    ATTACK,
    ALL,
    OTHER,
} from "../../helper/Values/TeamFormation";
import FilterTeamDetailsMenu from "../../components/playersData Components/FilterTeamDetailsMenu";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import { selectUser } from "../../Redux/user/user";
import EditPlayersView from "../../components/squadManagerComponents/EditPlayersView";

const PlayersData = () => {
    const { getTeamStats } = usePlayerStatsService();

    const [queryParams, setQueryParams] = useState(qs.parse(location.search));
    const [searchParams, setSearchParams] = useSearchParams();
    const [teamStats, setTeamStats] = useState([]);
    const [filteredStats, setFilteredStats] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showEditPlayersModal, setShowEditPlayersModal] = useState(false);
    const [positionFilters, setPositionFilters] = useState([
        GK,
        DEFENSIVE,
        MIDFIELD,
        ATTACK,
        OTHER,
    ]);
    const refreshOnClose = useRef(null);
    const [team, setTeam] = useState();
    const [league, setLeague] = useState();
    const user = useSelector(selectUser);
    const [minutesFilter, setMinutesFilter] = useState({
        min: "",
        max: "",
    });

    const [currentSquadFilter, setCurrentSquadFilter] = useState(true)
    const [sortType, setSortType] = useState({
        type: "all",
        sortKey: "minutes_played",
        ascending: true,
    });

    const navigate = useNavigate();

    const handleBackToLeagues = () => {
        let params = createSearchParams({
            seasonIds: searchParams.getAll("seasonIds"),
            cupIds: searchParams.getAll("cupIds"),
        }).toString();

        navigate({
            pathname: "/leagues",
            search: params,
        });
    };

    const handleGetTeamStats = () => {
        setIsLoading(true);
        getTeamStats(
            searchParams.get("selectedSeasonId"),
            searchParams.get("teamId"),
            function (response) {
                let resp = response.data.stats;
                let league = response.data.league;
                let team = response.data.team;
                setTeam(team);
                setLeague(league);
                let mapped = buildStatsBasedOnPosition(resp);
                setTeamStats(mapped);
                setIsLoading(false);
            },
            function (error) {
                console.log(error);
                notifyError("Something wrong...");
                setIsLoading(false);
            }
        );
        // window.scrollTo(0, 0);
    }

    const displayEditPlayersWindow = () => {
        setShowEditPlayersModal(!showEditPlayersModal);
    };


    const closeEditPlayersWindow = () => {
        if (refreshOnClose.current == true) {
            setShowEditPlayersModal(false);
            handleGetTeamStats();
        }
        setShowEditPlayersModal(false);
    };

    const buildStatsBasedOnPosition = (teamStats) => {
        var goalkeeprs = [];
        var defPositions = [];
        var midPositions = [];
        var atkPositions = [];
        var otherPositions = [];

        GK_POSITIONS.forEach((position) => {
            teamStats.map((statsItem) => {
                if (statsItem.player_data.position_1 == position) {
                    goalkeeprs.push(statsItem);
                }
            });
        });

        DEF_POSITIONS.forEach((position) => {
            teamStats.map((statsItem) => {
                if (statsItem.player_data.position_1 == position) {
                    defPositions.push(statsItem);
                }
            });
        });

        MID_POSITIONS.forEach((position) => {
            teamStats.map((statsItem) => {
                if (statsItem.player_data.position_1 == position) {
                    midPositions.push(statsItem);
                }
            });
        });

        ATK_POSITIONS.forEach((position) => {
            teamStats.map((statsItem) => {
                if (statsItem.player_data.position_1 == position) {
                    atkPositions.push(statsItem);
                }
            });
        });

        teamStats.map((statsItem) => {
            if (!statsItem.player_data.position_1) {
                otherPositions.push(statsItem);
            }
        });

        let statsObject = [
            {
                position_type: GK,
                players: goalkeeprs,
            },
            {
                position_type: DEFENSIVE,
                players: defPositions,
            },
            {
                position_type: MIDFIELD,
                players: midPositions,
            },
            {
                position_type: ATTACK,
                players: atkPositions,
            },
            {
                position_type: OTHER,
                players: otherPositions,
            },
        ];

        return statsObject;
    };





    const applyFilters = (team_stats) => {
        var clone = cloneDeep(team_stats);
        var positionFilteredStats = [];
        var minutesFilteredStats = [];
        var currentSquadOnlyStats = []

        //filter based on position type
        positionFilters.forEach((element) => {
            let found = clone.find((item) => item.position_type === element);
            if (found) {
                positionFilteredStats.push(found);
            }
        });



        positionFilteredStats?.forEach((positionObj) => {
            let newPlayers = [];

            if (minutesFilter.min?.length > 0 && minutesFilter.max?.length > 0) {
                newPlayers = positionObj.players.filter(
                    (player) =>
                        parseInt(minutesFilter.min) <= player.minutes_played &&
                        player.minutes_played <= minutesFilter.max
                );
                minutesFilteredStats.push({
                    ...positionObj,
                    players: newPlayers,
                });
            } else if (minutesFilter.min?.length > 0) {
                newPlayers = positionObj.players.filter(
                    (player) => parseInt(minutesFilter.min) <= player.minutes_played
                );
                minutesFilteredStats.push({
                    ...positionObj,
                    players: newPlayers,
                });
            } else if (minutesFilter.max?.length > 0) {
                newPlayers = positionObj.players.filter(
                    (player) => player.minutes_played <= parseInt(minutesFilter.max)
                );
                minutesFilteredStats.push({
                    ...positionObj,
                    players: newPlayers,
                });
            } else {
                minutesFilteredStats = positionFilteredStats;
            }
        });

        //sort right order
        let positionSortOrder = [GK, DEFENSIVE, MIDFIELD, ATTACK, OTHER];


        //filter based on current squad, player.transferred==false
        minutesFilteredStats?.forEach((positionObj) => {
            let newPlayers = [];
            if (currentSquadFilter) {
                newPlayers = positionObj.players.filter((player) =>
                    player.player_data.transferred == false
                )
                currentSquadOnlyStats.push({
                    ...positionObj,
                    players: newPlayers
                })
            } else {
                currentSquadOnlyStats = minutesFilteredStats
            }
        })

        //filter empty positions
        currentSquadOnlyStats = currentSquadOnlyStats?.filter(
            (item) => item.players.length > 0
        );


        currentSquadOnlyStats.sort((a, b) => {
            return (
                positionSortOrder.indexOf(a.position_type) -
                positionSortOrder.indexOf(b.position_type)
            );
        });





        setFilteredStats(currentSquadOnlyStats);
    };

    const handleChangeSortType = (type, sortKey) => {
        setSortType({
            type: type,
            sortKey: sortKey,
            ascending: !sortType.ascending,
        });
    };

    const applySort = (type, sortKey) => {
        let sortedStats = cloneDeep(filteredStats);
        let foundObj;
        if (type === ALL) {
            sortedStats = sortedStats.map((element) => {
                let sortedPlayers;
                if (sortType.ascending) {
                    sortedPlayers = element.players.sort((a, b) =>
                        parseFloat(a[sortKey]) >= parseFloat(b[sortKey]) ? 1 : -1
                    );
                } else {
                    sortedPlayers = element.players.sort((a, b) =>
                        parseFloat(a[sortKey]) <= parseFloat(b[sortKey]) ? 1 : -1
                    );
                }
                return {
                    ...element,
                    players: sortedPlayers,
                };
            });
        } else {
            foundObj = sortedStats.find((item) => item.position_type === type);
            let index = sortedStats.findIndex((item) => item.position_type === type);

            if (!foundObj) {
                return;
            }

            foundObj.players = foundObj.players.sort((a, b) => {
                let aSortKey = a[sortKey];
                let bSortKey = b[sortKey];

                if (aSortKey == null) {
                    aSortKey = -1;
                }
                if (bSortKey == null) {
                    bSortKey = -1;
                }
                if (sortType.ascending) {
                    return parseFloat(aSortKey) >= parseFloat(bSortKey) ? 1 : -1;
                } else {
                    return parseFloat(aSortKey) <= parseFloat(bSortKey) ? 1 : -1;
                }
            });

            sortedStats[index] = foundObj;
        }

        setFilteredStats(sortedStats);
    };

    const navigateToSquadManager = () => {
        let paramsObj = {
            teamId: team.id,
            selectedLeagueForTeam: league.id,
            seasonIds: league.season_id,
            cupIds: searchParams.getAll("cupIds"),
        };

        if (user.open_links_in_new_tabs) {
            let url = {
                pathname: "/leagues/squad_manager",
                search: createSearchParams(paramsObj).toString(),
            };
            window.open(`${url.pathname}?${url.search}`, "_blank");
        } else {
            navigate({
                pathname: "/leagues/squad_manager",
                search: createSearchParams(paramsObj).toString(),
            });
        }
    };


    useEffect(() => {
        handleGetTeamStats();
    }, []);

    useEffect(() => {
        let time = setTimeout(() => {
            applyFilters(teamStats);
        }, 300);
        return () => {
            clearTimeout(time);
        };
    }, [minutesFilter.max, minutesFilter.min, positionFilters, teamStats, currentSquadFilter]);

    useEffect(() => {
        applySort(sortType.type, sortType.sortKey);
    }, [sortType]);

    return (
        <Container>
            <FullScreenSpinner loading={isLoading} />
            <LeaguesHeader />
            <Header>
                <HeaderGroup>
                    <BackToLeaguesButton onClick={() => handleBackToLeagues()}>
                        <BackToLeaguesIcon />
                        back to leagues
                    </BackToLeaguesButton>

                </HeaderGroup>
                <HeaderGroup>
                    <FilterTeamDetailsMenu
                        positionFilters={positionFilters}
                        setPositionFilters={setPositionFilters}
                        minutesFilter={minutesFilter}
                        setMinutesFilter={setMinutesFilter}
                        setCurrentSquadFilter={setCurrentSquadFilter}
                        currentSquadFilter={currentSquadFilter}
                    />
                    <NavigateToEditTeamPlayers
                        onClick={() => displayEditPlayersWindow()}
                    >
                        <EditPencilIcon />
                        Edit players
                    </NavigateToEditTeamPlayers>
                </HeaderGroup>
            </Header>

            <HeaderLabels>
                <HeaderLabelLeft>
                    <Number>
                    </Number>
                    <Avatar>
                        <img src={team?.logo_url} alt='teamIc' />
                    </Avatar>
                    <NameAndPosition onClick={(e) => navigateToSquadManager()}>
                        <h1>{team?.name} </h1>
                    </NameAndPosition>

                    <StatsCell>
                        <h1>League Season Totals</h1>
                    </StatsCell>
                </HeaderLabelLeft>
                <HeaderLabelRight>
                    <StatsCell>
                        <h1>Per 90 mins</h1>
                    </StatsCell>
                </HeaderLabelRight>
            </HeaderLabels>

            <Content>
                {filteredStats.map((element, index) => (
                    <ElementWrapper key={index}>
                        <PlayerStatsHeader
                            index={index}
                            type={element.position_type}
                            handleChangeSortType={handleChangeSortType}
                            team={team}
                            navigateToSquadManager={navigateToSquadManager}
                        />
                        {element.players.map((player) => (
                            <PlayerStatsRow
                                key={player.player_data.player_id}
                                player={player}
                                type={element.position_type}
                            />
                        ))}
                    </ElementWrapper>
                ))}
            </Content>

            {showEditPlayersModal && (
                <EditPlayersView
                    teamID={team}
                    closeWindow={closeEditPlayersWindow}
                    refreshOnClose={refreshOnClose}
                    leagueData={league}
                />
            )}
        </Container>
    );
};

export default PlayersData;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1600px;
  min-height: calc(100vh - 45px);

`;
const Header = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.squadManager.headerBg};
  padding: 8px 14px;
  justify-content: space-between;
  position:sticky;
  top:88px;
  z-index:3;
`;
const BackToLeaguesButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  column-gap: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  color: ${({ theme }) => theme.squadManager.textColor};
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 85px);
  overflow: none;
  background: ${({ theme }) => theme.squadManager.bg};
  padding: 5px 0;
  position:relative;
  z-index:1;
`;
const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  width: 100%;
  position:relative;
`;
const HeaderGroup = styled.div`
  display: flex;
  column-gap: 15px;

`;

const Avatar = styled.div`
display:flex;
align-items:center;
justify-content:center;
min-width:28px;
min-height:28px;
max-width:28px;
max-height:28px;
overflow:hidden;
img{
    object-fit:contain;
    height:100%;
    width:100%;
}
`


const StatsCell = styled.div`
display:flex;
align-items:center;
justify-content:center;
min-width:40px;
width:100%;
gap:2px;

h1{
    font-family: Roboto;
font-size: 14px;
font-weight: 700;
line-height: 16px;
letter-spacing: 0.01em;
text-align: center;
color:${({ theme }) => theme.playersData.color};
}
svg{
    cursor:pointer;
    min-width:10px;
    height:100%;
}
`
const NameAndPosition = styled(StatsCell)`
align-items:start;
flex-direction:column;
min-width:130px;
max-width:130px;
cursor:pointer;

h1{
    font-family: Roboto;
font-size: 14px;
font-weight: 700;
line-height: 16px;
letter-spacing: 0.01em;
text-align: left;
color:${({ theme }) => theme.playersData.color};
text-decoration:underline;
}
`


const NavigateToEditTeamPlayers = styled.button`
  display: inline-flex;
  align-items: center;
  column-gap: 5px;
  color: var(--primaryBlue);
  border-radius: 3px;
  border: 1px solid var(--primaryBlue);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  background: white;
  padding: 0 8px;
  cursor: pointer;
  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`;
const HeaderLabels = styled.div`
display:flex;
align-items:center;
gap:10px;
background: ${({ theme }) => theme.squadManager.bg};
padding-top:5px;
position:sticky;
top:132px;
z-index:2;
`
const HeaderLabelLeft = styled.div`
display:flex;
align-items:center;
gap:16px;
background:${({ theme }) => theme.playersData.headerLeftBg};
padding:9px 7px;
width:100%;
min-height:50px;
min-width:820px;
`
const HeaderLabelRight = styled(HeaderLabelLeft)`
background:${({ theme }) => theme.playersData.headerRightBg};
min-width:unset;
`

const Number = styled.div`
display:flex;
align-items:center;
justify-content:center;
min-width:32px;
min-height:32px;

`