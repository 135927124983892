import { POST } from "../APiHelper/API";

export const saveNewSquadForTeam = (data, onSuccess, onError) => {
    // const data = {
    //     team_id: team_id,
    //     fixture_id: fixture_id,
    //     formation: formation,
    //     selections: selections
    // }
    POST(`/api/v1/squads/teams/save_squad`, data, onSuccess, onError)
}