export const CURRENT_PLAYERS = "Current Player"
export const TRANSFERRED_PLAYERS = "Transferred Players"
export const DELETED_PLAYERS = "Deleted Players"

export const CURRENT_TEAMS = "Current Teams"
export const DELETED_TEAMS = "Deleted Teams"

export const CURRENT_LEAGUES = "Current League"
export const DELETED_LEAGUES = "Deleted Leagues"

export const CURRENT_CUPS = "Current Cup"

export const DELETED_REFEREES = "Deleted Referees"


export const teamPlayersSubmenuList = [CURRENT_PLAYERS, TRANSFERRED_PLAYERS, DELETED_PLAYERS]
export const leagueDetailsSubmenuList = [CURRENT_TEAMS, DELETED_TEAMS]
export const editSeasonSubmenuList = [CURRENT_LEAGUES, DELETED_LEAGUES]
export const editCupSubmenuList = [CURRENT_CUPS, DELETED_TEAMS]
