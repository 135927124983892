import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import TierMenu from "../Menu/TierMenu.js";
import { ReactComponent as PlusSvg } from "../../../assets/icons/LeaguesIcons/plusIcon.svg";
import { TierValues } from "../../../helper/Values/TeamFormation.js";
import { useLeaguesDataService } from "../../../context/useLeaguesDataService.js";
import {
  notifyError,
  notifyInfoNoClose,
  notifySucces,
} from "../../../helper/Generic/Notifications.js";
import { sortTeamsAZ } from "../../../helper/Analyst/LeaguesData/sortTeamsFromLeague.js";
import EditLeagueSubmenuOption from "./EditLeagueSubmenuOption.js";
import {
  CURRENT_TEAMS,
  DELETED_TEAMS,
  leagueDetailsSubmenuList,
} from "../../../helper/Values/EditLeagueSubmenuValues.js";
import TeamElement from "../LeagueDetailsComponents/TeamElement.js";
import RefereeElement from "../LeagueDetailsComponents/RefereeElement.js";
import DeletedTeamElement from "../LeagueDetailsComponents/DeletedTeamElement.js";
import AddNewTeamForLeague from "../LeagueDetailsComponents/AddNewTeamForLeague.js";
import DeleteConfirmationDouble from "../../DeleteConfirmationDouble.js";
import { useSelector } from "react-redux";
import { selectUser } from "../../../Redux/user/user.js";
import SearchRefereeMenu from "../Menu/SearchRefereeMenu.js";
import { cloneDeep } from "lodash";
import { sortReferees } from "../../../helper/Analyst/LeaguesData/SortReferees.js";

const LeagueDetailsOption = ({
  leagueToEdit,
  refreshCurrentLeague,
  setRefreshListOnClose,
  setLoading,
  refreshAfterDeleteLeague,
}) => {
  const {
    edit_league,
    add_referee,
    delete_referee,
    delete_team,
    delete_league,
    get_available_teams_for_league,
    add_team_to_league,
    get_deleted_teams_for_league,
    undo_delete_team,
    search_participant,
    add_participant,
    search_referee_to_add_league
  } = useLeaguesDataService();

  const leagueRef = useRef();
  leagueRef.current = leagueToEdit;
  const user = useSelector(selectUser)

  const tierRef = useRef(leagueToEdit?.tier);
  const notifyNoCloseId = useRef();

  const [readyToEdit, setReadyToEdit] = useState(false);
  const [detailChanged, setDetailsChanged] = useState(false);
  const [addRefereeButtonEnabled, setAddRefereeButtonEnabled] = useState(false);
  const [leagueSubmenu, setLeagueSubmenu] = useState(CURRENT_TEAMS);
  const [selectedTeamToAdd, setSelectedTeamToAdd] = useState(null);
  const [availableTeams, setAvailableTeams] = useState([]);
  const [deletedTeams, setDeletedTeams] = useState([]);

  const [searchParticipantTerm, setSearchParticipantTerm] = useState("")
  const [otherParticipants, setOtherParticipants] = useState([])

  const refereeField = useRef();

  const [refereeSearchTerm, setRefereeSearchTerm] = useState("");
  const [referees, setReferees] = useState(sortReferees(leagueToEdit?.referees))
  const [newReferee, setNewReferee] = useState(null);
  const shouldSearchReferee = useRef(false);
  const [refereeList, setRefereeList] = useState(null)

  const [inputValues, setInputValues] = useState({
    leagueName: leagueToEdit?.name,
    referee: "",
    teamName: "",
  });



  const teams = leagueToEdit?.teams || [];

  const setTier = (newValue) => {
    tierRef.current = newValue;
    checkReadyToEdit();
  };

  const handleAddReferee = () => {
    setLoading(true);
    const refereeToAdd = newReferee

    add_referee(
      leagueRef.current.id,
      refereeToAdd,
      function () {
        setRefreshListOnClose(true);
        setLoading(false);
        setNewReferee(null)
        setRefereeSearchTerm("");
        setRefereeList([]);
        notifySucces("Referee added");
        refreshStateWithLatestData();
      },
      function () {
        setLoading(false);
        notifyError("Something wrong...");
      }
    );
  };

  const handleRemoveReferee = (referee) => {
    setLoading(true);
    delete_referee(
      referee.id,
      function () {
        setLoading(false);
        notifySucces("Referee deleted");
        refreshStateWithLatestData();
      },
      function (error) {
        setLoading(false);
        notifyError("Someting Wrong...");
      }
    );
  };

  const handleDeleteLeague = (league_id) => {
    setLoading(true);
    delete_league(
      league_id,
      function () {
        setLoading(false);
        notifySucces("League deleted");
        setRefreshListOnClose(true);
        refreshAfterDeleteLeague();
      },
      function (error) {
        setLoading(false);
        notifyError("Something wrong");
      }
    );
  };

  const handleDeleteTeam = (team_id) => {
    setLoading(true);
    delete_team(
      team_id,
      function () {
        handleGetDeletedTeamsForLeague(leagueToEdit.id);
        setLoading(false);
        notifySucces("Team deleted");
        refreshStateWithLatestData();
        setRefreshListOnClose(true);
      },
      function (error) {
        setLoading(false);
        notifyError("Something wrong...");
      }
    );
  };

  const handleRevertRemoveTeam = (team_id) => {
    setLoading(true);
    undo_delete_team(
      team_id,
      function (response) {
        handleGetDeletedTeamsForLeague(leagueToEdit.id);
        refreshStateWithLatestData();
        setRefreshListOnClose(true);
        notifySucces("Team reverted");
      },
      function (error) {
        console.log(error);
        notifyError("Something wrong");
      }
    );
  };

  const handleGetDeletedTeamsForLeague = (team_id) => {
    get_deleted_teams_for_league(
      team_id,
      function (response) {
        let sorted = sortTeamsAZ(response.data.teams);
        setDeletedTeams(sorted);
      },
      function (error) {
        console.log(error);
        notifyError("Something wrong");
      }
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputValues({
      ...inputValues,
      [name]: value,
    });

    checkReadyToEdit();
  };

  const refreshStateWithLatestData = () => {
    refreshCurrentLeague((newLeague) => {
      leagueRef.current = newLeague;
    });
  };

  const checkFlag = (country_code) => {
    if (!country_code) {
      return;
    }
    return `https://flagcdn.com/${country_code.toLowerCase()}.svg`;
  };

  const handleSaveButton = () => {
    if (detailChanged) {
      handleEditLeague();
    }
  };

  const handleEditLeague = () => {
    setLoading(true);
    edit_league(
      leagueToEdit.id,
      inputValues.leagueName,
      tierRef.current,
      function () {
        setLoading(false);

        refreshStateWithLatestData();
        setRefreshListOnClose(true);

        notifySucces("League edited");

        setDetailsChanged(false);
        setReadyToEdit(false);
      },
      function () {
        setLoading(false);
        notifyError("Something wrong");
      }
    );
  };

  const handleGetAvailableTeamsForLeague = (league_id) => {
    get_available_teams_for_league(
      league_id,
      function (response) {
        let sorted = response.data.team.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
        setAvailableTeams(sorted);
      },
      function (error) {
        console.log(error);
      }
    );
  };

  const handleSearchParticipant = (searchTerm) => {

    if (searchTerm != "" && user?.roles[0] == "admin") {
      search_participant(
        searchTerm,
        function (response) {

          setOtherParticipants(response.data.participants)
        },
        function (error) {
          console.log(error);
        }
      );
    } else {
      setOtherParticipants([])
    }

  };

  const addTeamToLeague = (team) => {
    setLoading(true);
    add_team_to_league(
      leagueToEdit.id,
      team.participant_id,
      team.wy_id,
      team.legacy_participant_id,

      function (response) {
        setRefreshListOnClose(true);
        setSelectedTeamToAdd(null);
        refreshStateWithLatestData();
        setLoading(false);
      },
      function (error) {
        setLoading(false);
        if (error?.response?.data) {
          notifyError(error?.response?.data?.message);
        }

        console.log(error);
      }
    );
  }


  const addTeamToLeagueADMIN = (team) => {
    if (user.roles[0] !== "admin") {
      return
    }
    setLoading(true);
    add_participant(
      leagueToEdit.id,
      team.id,
      (response) => {
        setRefreshListOnClose(true);
        setSelectedTeamToAdd(null);
        refreshStateWithLatestData();
        setLoading(false);
      },
      function (error) {
        setLoading(false);
        if (error?.response?.data) {
          notifyError(error?.response?.data?.message);
        }
        console.log(error);
      }

    )
  }

  const handleAddNewTeam = () => {
    if (selectedTeamToAdd.from_another_league) {
      addTeamToLeagueADMIN(selectedTeamToAdd.team)
    } else {
      addTeamToLeague(selectedTeamToAdd.team);
    }
  };

  const checkDuplicates = () => {
    let mapped = teams?.map((team) => {
      if (team.wy_id) {
        return team.wy_id;
      } else {
        return null
      }
    });

    let findDuplicates = mapped?.filter(
      (item, index) => {
        if (item) {
          return mapped.indexOf(item) != index
        }
      }
    );

    if (findDuplicates?.length > 0) {
      notifyInfoNoClose(
        notifyNoCloseId,
        "The league contains now duplicate teams, please check the current teams list!"
      );
    }
  };

  const checkDuplicatesReferees = () => {
    let mapped = referees?.map((referee) => {
      if (referee.wy_id) {
        return referee.wy_id;
      } else {
        return null
      }
    });

    let findDuplicates = mapped?.filter(
      (item, index) => {
        if (item) {
          return mapped.indexOf(item) != index
        }
      }
    );

    if (findDuplicates?.length > 0) {
      notifyInfoNoClose(
        notifyNoCloseId,
        "The league contains now duplicate referee, please check the current referee list!"
      );
    }
  };



  const handleSearchRefereeToAdd = (term) => {
    setLoading(true)
    search_referee_to_add_league(
      leagueToEdit.id,
      term,
      function (response) {
        setLoading(false);
        handleRefereesResponse(response.data.referees);
      },
      function (error) {
        setLoading(false);
        notifyError("Something wrong...");
      }
    )
  }


  const handleRefereesResponse = (referees) => {
    // console.log(referees, "refs")

    let mappedReferees = [];
    referees.forEach(referee => {
      let idx = mappedReferees.findIndex((item) => item.country == referee.country);

      if (idx == -1) {
        mappedReferees.push({
          country: referee.country,
          country_code: referee.country_code,
          referees: [
            referee
          ],
        });
      }

      if (idx != -1) {
        mappedReferees[idx].referees.push(
          referee
        );
      }
    });

    let sorted = mappedReferees.sort((a, b) => a.country > b.country ? 1 : -1);


    //find if exist index of element.country_code==currentLeague.country_code
    let wantedElementIdx = sorted.findIndex((item) => item.country == leagueToEdit?.country);

    if (wantedElementIdx != -1) {
      let element = sorted.splice(wantedElementIdx, 1)[0];
      sorted.splice(0, 0, element);
    }

    //find if exist index of unknownCountry and put elements at the end
    let unknounIdx = mappedReferees.findIndex((item) => item.country == null);

    if (unknounIdx != -1) {
      let unknownCountry = sorted.splice(unknounIdx, 1)[0]
      sorted.splice(sorted.length, 0, unknownCountry);
    }

    setRefereeList(sorted);
    setLoading(false);
  };

  useEffect(() => {
    if (!leagueToEdit) {
      return;
    }
    checkReadyToEdit();
  }, [inputValues, newReferee]);

  const checkReadyToEdit = () => {
    if (
      inputValues.leagueName != leagueRef.current.name ||
      tierRef.current != leagueRef.current.tier
    ) {
      setDetailsChanged(true);
      setReadyToEdit(true);
    } else {
      setDetailsChanged(false);
      setReadyToEdit(false);
    }
    setAddRefereeButtonEnabled(newReferee != null);
  };


  useEffect(() => {
    handleGetDeletedTeamsForLeague(leagueToEdit.id);
    handleGetAvailableTeamsForLeague(leagueToEdit.id);
  }, []);

  useEffect(() => {
    setReferees(sortReferees(leagueToEdit?.referees))
  }, [leagueToEdit])

  useEffect(() => {
    checkDuplicates();
  }, [teams]);

  useEffect(() => {
    checkDuplicatesReferees(referees);
  }, [referees]);

  useEffect(() => {
    let time = setTimeout(() => {
      handleSearchParticipant(searchParticipantTerm)
    }, 300);
    return () => {
      clearTimeout(time);
    };
  }, [searchParticipantTerm])

  useEffect(() => {
    if (shouldSearchReferee.current == false) {
      return;
    }

    if (refereeSearchTerm == "" || refereeSearchTerm.length < 3) {
      setRefereeList(null);
      return;
    }

    const timer = setTimeout(() => {
      handleSearchRefereeToAdd(refereeSearchTerm);
    }, 250);

    return () => clearTimeout(timer);
  }, [refereeSearchTerm]);

  return (
    <Card>
      <CardContent>
        <SubmenuWrapper>
          <EditLeagueSubmenuOption
            valuesList={leagueDetailsSubmenuList}
            value={leagueSubmenu}
            setValue={setLeagueSubmenu}
          />
        </SubmenuWrapper>
        {leagueSubmenu === CURRENT_TEAMS && (
          <>
            <LeagueInput>
              <LeagueDetailsContainer>
                <LeagueDetails>
                  <GroupContainer>
                    <h3>LEAGUE COUNTRY</h3>
                    <LeagueCountryReadOnly>
                      <img
                        src={checkFlag(leagueToEdit?.country.country_code)}
                      ></img>
                      <h4>{leagueToEdit?.country.name}</h4>
                    </LeagueCountryReadOnly>
                  </GroupContainer>

                  <GroupContainer>
                    <h3>TIER RANKING</h3>
                    <TierMenu
                      label={"-"}
                      dataList={TierValues}
                      value={tierRef.current}
                      setValue={setTier}
                    />
                  </GroupContainer>
                </LeagueDetails>

                <LeagueName>
                  <GroupContainer>
                    <h3>LEAGUE NAME</h3>
                    <input
                      placeholder="Enter league name"
                      name="leagueName"
                      onChange={(e) => handleInputChange(e)}
                      value={inputValues.leagueName}
                    ></input>
                  </GroupContainer>
                </LeagueName>
              </LeagueDetailsContainer>

              <RefereesContainer>
                <h3>REFEREES</h3>
                <RefereeContent>
                  {referees?.map((item, index) => (
                    <RefereeElement
                      key={index}
                      item={item}
                      handleRemoveReferee={handleRemoveReferee}
                    />
                  ))}

                  <GroupContainer style={{ alignItems: "end" }}>
                    <SearchRefereeMenu
                      value={newReferee}
                      setValue={setNewReferee}
                      term={refereeSearchTerm}
                      setTerm={setRefereeSearchTerm}
                      valuesList={refereeList}
                      shouldSearch={shouldSearchReferee}
                    />

                    <NewRefereeButton
                      disabled={!addRefereeButtonEnabled}
                      onClick={() => handleAddReferee()}
                    >
                      <PlusSvg />
                      Add new referee
                    </NewRefereeButton>
                  </GroupContainer>

                </RefereeContent>
              </RefereesContainer>
            </LeagueInput>

            <TeamInput>
              <GroupContainer>
                <h3>TEAM NAME</h3>
              </GroupContainer>
              <TeamContent>
                {sortTeamsAZ(teams).map((item, index) => (
                  <TeamElement
                    key={item.id}
                    handleDeleteTeam={handleDeleteTeam}
                    item={item}
                  />
                ))}

                <AddNewTeamForLeague
                  selectedTeamToAdd={selectedTeamToAdd}
                  setSelectedTeamToAdd={setSelectedTeamToAdd}
                  availableTeams={availableTeams}
                  handleAddNewTeam={handleAddNewTeam}
                  handleSearchParticipant={handleSearchParticipant}
                  searchTerm={searchParticipantTerm}
                  setSearchTerm={setSearchParticipantTerm}
                  otherParticipants={otherParticipants}
                />
              </TeamContent>
            </TeamInput>
          </>
        )}

        {leagueSubmenu === DELETED_TEAMS && (
          <>
            <TeamInput>
              <TeamContent>
                {deletedTeams?.map((team, index) => (
                  <DeletedTeamElement
                    key={index}
                    item={team}
                    handleRevert={handleRevertRemoveTeam}
                    displayUpdatedAt={true}
                  />
                ))}
              </TeamContent>
            </TeamInput>
          </>
        )}
      </CardContent>

      <Bottom>
        {/* <DeleteLeagueBtn onClick={() => { alert("implement"); }}><BinSvg />Delete league</DeleteLeagueBtn> */}
        <DeleteConfirmationDouble
          handleRemoveItem={handleDeleteLeague}
          item={leagueToEdit?.id}
          variant={"league"}
        />
        <SaveBtn disabled={!readyToEdit} onClick={() => handleSaveButton()}>
          Save name
        </SaveBtn>
      </Bottom>
    </Card>
  );
};

export default LeagueDetailsOption;

const CardContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 25px 0;
  overflow: auto;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
`;
const LeagueInput = styled.div`
  display: flex;
  width: 100%;
  padding: 0 40px;
  padding-bottom: 20px;
  border-bottom: 3px solid ${({ theme }) => theme.editLeague.borderColor};
  column-gap: 40px;
`;
const LeagueCountryReadOnly = styled.div`
  height: 40px;
  background: ${({ theme }) => theme.editLeague.readOnlyInputs};
  color: ${({ theme }) => theme.editLeague.menuTextColor};
  width: 240px;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  column-gap: 10px;
  img {
    border-radius: 4px;
    width: 26px;
    height: 20px;
  }
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.editLeague.menuTextColor};
  }
`;
const TeamInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px 40px;
  padding-bottom: 20px;
  column-gap: 40px;
`;
const SubmenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 40px;
  margin-bottom: 20px;
  padding-bottom: 25px;
  border-bottom: 3px solid ${({ theme }) => theme.editLeague.borderColor};
`;
const LeagueDetailsContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`;
const LeagueName = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 5px;
  input {
    background: ${({ theme }) => theme.editLeague.readOnlyInputs};
    border: 1px solid #bac3e8;
    border-radius: 6px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.editLeague.menuTextColor};
    ::placeholder {
      color: ${({ theme }) => theme.editLeague.menuTextColor};
      opacity: 0.7;
    }
    padding: 11px 15px;
  }
`;
const LeagueDetails = styled.div`
  display: flex;

  column-gap: 20px;
`;
const RefereesContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
`;
const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
`;
const RefereeContent = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 9px;
  column-gap: 40px;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  // overflow: auto;
`;
const TeamContent = styled(RefereeContent)`
  padding-top: 10px;
`;
const ElementInput = styled.input`
  height: 40px;
  background: ${({ theme }) => theme.editLeague.readOnlyInputs};
  width: 240px;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  padding: 10px 15px;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
    opacity: 0.7;
  }
  :disabled {
    background: ${({ theme }) => theme.editLeague.readOnlyInputs};
    color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  }
`;
const NewRefereeButton = styled.button`
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  background: #ffffff;
  border: 0.7px solid #006fff;
  border-radius: 3px;
  padding: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--primaryBlue);
  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`;

const Bottom = styled.div`
  background: ${({ theme }) => theme.editLeague.headerBg};
  padding: 10px 40px;
  border-radius: 0 0 16px 16px;
  display: flex;
  justify-content: space-between;
`;

const SaveBtn = styled.button`
  background-color: var(--primaryBlue);
  color: white;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding: 12px 27px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`;
