export const checkPositionColor = (position) => {
    if (position) {
        if (position.toLowerCase() == "gk") {
            return "green";
        } else
            if (position.toLowerCase() == "dm" ||
                position.toLowerCase() == "lb" ||
                position.toLowerCase() == "cb" ||
                position.toLowerCase() == "rb" ||
                position.toLowerCase() == "rcb" ||
                position.toLowerCase() == "lcb"
            ) {
                return "blue";
            } else
                if (position.toLowerCase() == "om" ||
                    position.toLowerCase() == "cm" ||
                    position.toLowerCase() == "rm" ||
                    position.toLowerCase() == "lm" ||
                    position.toLowerCase() == "rwb" ||
                    position.toLowerCase() == "lwb") {
                    return "orange";
                }
                else
                    if (position.toLowerCase() == "cam" ||
                        position.toLowerCase() == "rw" ||
                        position.toLowerCase() == "lw" ||
                        position.toLowerCase() == "cf" ||
                        position.toLowerCase() == "st" ||
                        position.toLowerCase() == "str" ||
                        position.toLowerCase() == "am") {
                        return "red";
                    }
                    else return "";
    }

};