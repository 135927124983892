import React from "react";
import styled from "styled-components";
import { ReactComponent as WarningIcon } from "../../assets/icons/LeaguesIcons/squadWarning.svg";

const PleaseSelectFixtureDialog = () => {
    return (
        <Container>

            <Card>
                <WarningIcon />
                <p>No match available or not match selected </p>
            </Card>


        </Container>
    );
};

export default PleaseSelectFixtureDialog;

const Container = styled.div`
display:flex;
height:100%;
width:100%;
`;

const Card = styled.div`
display:flex;
flex-direction:column;
row-gap:10px;
padding:15px 25px;
height:117px;
width:358px;
background: #FFFFFF;
border-left: 3px solid #FFB13A;
box-shadow: 0px 0px 10px rgba(98, 101, 112, 0.15);
border-radius: 10px;
p{
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 20px;
color: #000000;

}
`;