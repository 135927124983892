import {
    createContext,
    useCallback,
    useContext,
} from "react";
import { getTeamStatsAPI } from "../api/playerStats/getTeamStats";
import { getPlayerStatsGameAPI } from "../api/playerStats/getPlayersStatsGame";
import { getPlayerStatsSeasonAPI } from "../api/playerStats/getPlayerStatsSeason";

export const PlayerStatsServiceContext = createContext({
    getTeamStats: () => { },
    getPlayerStatsForGame: () => { },
    getPlayerStatsForSeason: () => { },
});

const PlayerStatsServiceProvider = ({ children }) => {

    const getTeamStats = useCallback(async (seasonId, teamId, onSuccess, onError) => {
        try {
            await getTeamStatsAPI(
                seasonId,
                teamId,
                function (response) {
                    onSuccess(response);
                },
                function (error) {
                    onError(error);
                }
            );
        }
        catch (err) {
            onError(err);
        }
    });

    const getPlayerStatsForGame = useCallback(async (fixture_id, teamId, playerId, onSuccess, onError) => {
        try {
            await getPlayerStatsGameAPI(
                fixture_id,
                teamId,
                playerId,
                function (response) {
                    onSuccess(response);
                },
                function (error) {
                    onError(error);
                }
            );
        }
        catch (err) {
            onError(err);
        }
    });

    const getPlayerStatsForSeason = useCallback(async (seasonId, teamId, playerId, onSuccess, onError) => {
        try {
            await getPlayerStatsSeasonAPI(
                seasonId,
                teamId,
                playerId,
                function (response) {
                    onSuccess(response);
                },
                function (error) {
                    onError(error);
                }
            );
        }
        catch (err) {
            onError(err);
        }
    });

    return (
        <PlayerStatsServiceContext.Provider
            value={{
                getTeamStats,
                getPlayerStatsForGame,
                getPlayerStatsForSeason
            }}
        >
            {children}
        </PlayerStatsServiceContext.Provider>
    );
};

export const usePlayerStatsService = () => useContext(PlayerStatsServiceContext);
export default PlayerStatsServiceProvider;
