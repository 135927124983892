import { PATCH } from "../APiHelper/API";

export const editUser = (name, timezone, openLinksInNewTabs, use_short_player_names, use_other_player_names, onSucces, onError) => {
  const data = {
    name: name,
    timezone_name: timezone,
    open_links_in_new_tabs: openLinksInNewTabs,
    use_short_player_names: use_short_player_names,
    use_other_player_names: use_other_player_names,
  };
  PATCH("/api/v1/users/update", data, onSucces, onError);
};

export const editNonAdminUser = (timezone, openLinksInNewTabs, use_short_player_names, use_other_player_names, onSucces, onError) => {
  const data = {
    timezone_name: timezone,
    open_links_in_new_tabs: openLinksInNewTabs,
    use_short_player_names: use_short_player_names,
    use_other_player_names: use_other_player_names,
  };
  PATCH("/api/v1/users/update", data, onSucces, onError);
};
