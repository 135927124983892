import { POST } from "../APiHelper/API";
import { formatted_date_for_backend } from "../../helper/Generic/dateTimeFormat";

export const downloadCSV = (
  country_ids,
  start_time,
  end_time,
  competition_ids,
  term,
  bookmakersIds,
  onSuccess,
  onError
) => {
  const data = {
    country_ids: country_ids,
    start_time: formatted_date_for_backend(start_time),
    end_time: formatted_date_for_backend(end_time),
    competition_ids: competition_ids,
    term: term,
    bookmakers_ids: bookmakersIds,
  };

  POST("/api/v1/fixtures/download_csv", data, onSuccess, onError);
};

export const downloadCSVFavorites = (
  country_ids,
  start_time,
  end_time,
  competition_ids,
  term,
  bookmakersIds,
  onSuccess,
  onError
) => {
  const data = {
    country_ids: country_ids,
    start_time: formatted_date_for_backend(start_time),
    end_time: formatted_date_for_backend(end_time),
    competition_ids: competition_ids,
    term: term,
    bookmakers_ids: bookmakersIds,
  };

  POST("/api/v1/fixtures/favorites/download_csv", data, onSuccess, onError);
};
