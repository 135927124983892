import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { store } from "./store";
import { Provider } from "react-redux";
import WebSocketServiceProvider from "./context/useWebSocketDataService";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<WebSocketServiceProvider>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</WebSocketServiceProvider>

	</Provider>
);
