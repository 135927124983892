import { formatted_date_for_backend } from "../../helper/Generic/dateTimeFormat";
import { POST } from "../APiHelper/API";

let source;

export const getLastConfirmedAttendanceApiCall = (
    team_id,
    fixture_id,
    onSuccess,
    onError
) => {
    const data = {
        team_id: team_id,
        fixture_id: fixture_id
    }

    source = POST(`/api/v1/games/last_home_confirmed_attendances`, data, onSuccess, onError, true, source);
};
