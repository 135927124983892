import {
  createContext,
  useCallback,
  useContext,
  useState,
  useRef,
} from "react";
import { editLoggedBet } from "../api/bets/editLoggedBet";
import { getLoggedBets } from "../api/bets/getLoggedBet";
import { logBet } from "../api/bets/logBet";
import { sortLoggedBetsByCreatedDate } from "../helper/ModelDataHelper";
import { setAbnormalValue } from "../api/bets/SetAbnormalValue";

export const LoggedBetsServiceContext = createContext({
  loggedBets: [],
  logbet: () => {},
  getLBets: () => {},
  editLBet: () => {},
  editLoggedBetAbnormalValue: () => {},
});

const LoggedBetsServiceProvider = ({ children }) => {
  const previousLoggedBetsCallRef = useRef(null);

  const getLBets = useCallback(
    async (
      country_ids,
      start_time,
      end_time,
      competition_ids,
      term,
      tiers,
      callbackFunction,
      errorFunction
    ) => {
      try {
        // Cancel previous request
        previousLoggedBetsCallRef.current?.cancel();
        const ref = getLoggedBets(
          country_ids,
          start_time,
          end_time,
          competition_ids,
          term,
          tiers,
          function (response) {
            // const sorted = sortLoggedBetsByCreatedDate(response.data.bets);
            // setLoggedBets(sorted);
            callbackFunction(response);
          },
          function (error) {
            errorFunction(error);
          }
        );
        previousLoggedBetsCallRef.current = ref;
      } catch (err) {
        errorFunction();
      }
    }
  );

  const logbet = useCallback(
    async (
      parked_bet_id,
      broker,
      ret_stake,
      confirmed_price,
      clash,
      oppo,
      time_ret,
      no_return,
      time_req,
      callbackFunction,
      errorFunction
    ) => {
      try {
        await logBet(
          parked_bet_id,
          broker,
          ret_stake,
          confirmed_price,
          clash,
          oppo,
          time_ret,
          no_return,
          time_req,
          function (response) {
            callbackFunction();
          },
          function (error) {
            errorFunction();
          }
        );
      } catch (err) {
        errorFunction();
      }
    }
  );

  const editLBet = useCallback(
    async (
      parked_bet_id,
      broker,
      ret_stake,
      confirmed_price,
      time_ret,
      time_req,
      incorrect,
      callbackFunction,
      errorFunction
    ) => {
      try {
        await editLoggedBet(
          parked_bet_id,
          broker,
          ret_stake,
          confirmed_price,
          time_ret,
          time_req,
          incorrect,
          function (response) {
            callbackFunction();
          },
          function (error) {
            errorFunction();
          }
        );
      } catch (err) {
        errorFunction();
      }
    }
  );

  const editLoggedBetAbnormalValue = useCallback(
    async (
      logged_bet_id,
      ret_stake,
      confirmed_price,
      abnormal,
      callbackFunction,
      errorFunction
    ) => {
      try {
        await setAbnormalValue(
          logged_bet_id,
          ret_stake,
          confirmed_price,
          abnormal,
          callbackFunction,
          errorFunction,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            errorFunction();
          }
        );
      } catch (err) {
        errorFunction();
      }
    }
  );
  return (
    <LoggedBetsServiceContext.Provider
      value={{ getLBets, logbet, editLBet, editLoggedBetAbnormalValue }}
    >
      {children}
    </LoggedBetsServiceContext.Provider>
  );
};
export const useLoggedBetsService = () => useContext(LoggedBetsServiceContext);
export default LoggedBetsServiceProvider;
