import { createContext, useContext, useState } from "react";


export const RequestMenuServiceContext = createContext({
    searchInput: "",
    countries: [],
    countriesList: [],
    date: [],
    countriesIds: [],
    selectedCompetitions: [],
    competitions: [],
    competitionsIds: [],
    tier: [],
    tierIds: [],
    listId: [],
    priority: [],

    setSearchInput: () => { },
    setCountries: () => { },
    setCountriesList: () => { },
    setDate: () => { },
    setCountriesIds: () => { },
    setSelectedCompetitions: () => { },
    setCompetitions: () => { },
    setCompetitionIds: () => { },
    setTier: () => { },
    setTierIds: () => { },
    setListId: () => { },
    setPriority: () => { },

});

const RequestMenuServiceProvider = ({ children }) => {
    const [searchInput, setSearchInput] = useState("");
    const [countries, setCountries] = useState([]);
    const [countriesList, setCountriesList] = useState([]);
    const newDate = new Date();
    const newDateToday = new Date();
    const newDateTommorow = new Date(newDate.setDate(newDate.getDate() + 1));
    const [date, setDate] = useState([newDateToday, newDateTommorow]);
    const [countriesIds, setCountriesIds] = useState([]);
    const [selectedCompetitions, setSelectedCompetitions] = useState([]);
    const [competitions, setCompetitions] = useState([]);
    const [competitionsIds, setCompetitionIds] = useState([]);
    const [tier, setTier] = useState([]);
    const [tierIds, setTierIds] = useState([2, 3, 4]);
    const [listId, setListId] = useState();
    const [priority, setPriority] = useState();

    return (
        <RequestMenuServiceContext.Provider
            value={{
                searchInput,
                countries,
                countriesList,
                date,
                countriesIds,
                selectedCompetitions,
                competitions,
                competitionsIds,
                tier,
                tierIds,
                listId,
                priority,
                setSearchInput,
                setCountries,
                setCountriesList,
                setDate,
                setCountriesIds,
                setSelectedCompetitions,
                setCompetitions,
                setCompetitionIds,
                setTier,
                setTierIds,
                setListId,
                setPriority,

            }}
        >
            {children}
        </RequestMenuServiceContext.Provider>
    );
};
export const useRequestMenuService = () =>
    useContext(RequestMenuServiceContext);
export default RequestMenuServiceProvider;
