import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { ReactComponent as XIcon } from "../../assets/icons/xBtn.svg";


import { useLeaguesDataService } from "../../context/useLeaguesDataService";
import { getCountries } from "../../api/user/GetCountriesListAdmin";
import { notifyError } from "../../helper/Generic/Notifications";

import { checkFlag } from '../../helper/Generic/GetFlag';
import ButtonWithConfirmation from '../ButtonWithConfirmation';
import TeamsPlayersOption from '../leaguesComponents/EditLeagueComponents/TeamPlayersOption';




const EditPlayersView = ({ teamID, setTeamID, closeWindow, refreshOnClose, leagueData, cupData }) => {
  const TEAM_PLAYERS = "Team Players"
  const [option, setOption] = useState(TEAM_PLAYERS);
  const [currentLeagueData, setCurrentLeagueData] = useState(leagueData);
  const [unsavedCounter, setUnsavedCounter] = useState(0);

  const { get_league_by_id } = useLeaguesDataService();

  const handleSelectTeamToEdit = (team) => {
    setTeamID(team);
  };

  const ShowSelectedOption = () => {

    if (option == TEAM_PLAYERS) {
      return (
        <TeamsPlayersOption
          leagueToEdit={currentLeagueData}
          cupData={cupData}
          handleSelectTeamToEdit={handleSelectTeamToEdit}
          teamID={teamID}
          refreshOnClose={refreshOnClose}
          needToRefreshList={true}
          unsavedCounter={unsavedCounter}
          setUnsavedCounter={setUnsavedCounter}
        // refreshCurrentLeague={refreshCurrentLeague}
        // setLoading={setLoading}
        // setRefreshListOnClose={setRefreshListOnClose}
        //   refreshAfterDeleteLeague={refreshAfterDeleteLeague}
        />
      );
    }
  };

  const refreshCurrentLeague = (onSuccess) => {
    if (!currentLeagueData) {
      return;
    }

    // setLoading(true);
    get_league_by_id(
      currentLeagueData.id,
      function (response) {
        // setLoading(false);
        setCurrentLeagueData(response.data.league);
        onSuccess(response.data.league);
      },
      function () {
        setLoading(false);
        notifyError("Something wrong");
      }
    );
  };

  useEffect(() => {
    // refreshCurrentLeague(function () { })
  }, [])

  return (
    <Container>
      <CardContainer>
        <Card>
          <Top>
            <h4>{teamID?.name}</h4>{" "}
            {unsavedCounter > 0 ?
              <ButtonWithConfirmation variant={'close'} action={closeWindow} />
              :
              <button
                onClick={() => {
                  closeWindow();
                }}
              >
                <XIcon />
              </button>
            }


          </Top>

          <TitleAndMenu>
            <FlagTitle>
              <img
                src={cupData ? cupData?.logo : leagueData && checkFlag(leagueData?.country?.country_code)}
                alt=""
              />
              <h1>{cupData ? cupData?.name : currentLeagueData?.name}</h1>
            </FlagTitle>

            <CardMenu>

              <button
                onClick={() => setOption(TEAM_PLAYERS)}
                name={TEAM_PLAYERS}
                className={option == TEAM_PLAYERS ? "active" : ""}
              >
                Team Players
              </button>
            </CardMenu>
          </TitleAndMenu>

          <Content>{ShowSelectedOption()}</Content>
        </Card>
      </CardContainer>
    </Container>
  )
}

export default EditPlayersView
const Container = styled.div`
  display: flex;

`;
const CardContainer = styled.div`
  position: fixed;
  z-index: 5;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  padding: 0vh 20vw 5vh 20vw;
  overflow: none;

`;
const Content = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 0 0 16px 16px;
  overflow: hidden;
  background: ${({ theme }) => theme.editLeague.bg};
`;
const Card = styled.div`
position: sticky;
top: 5vh;
width: 100%;
max-height: 80%;
height:100%;
display: flex;
flex-direction: column;
min-width: 1400px;
background: white;
box-shadow: 0px 1px 8px rgba(46, 46, 46, 0.04);
border-radius: 16px;
@media(max-height:1024px){

  max-height:90vh;
}
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items:center;
  padding: 14px 40px;
  background: ${({ theme }) => theme.editLeague.headerBg};
  border-radius: 16px 16px 0px 0px;
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: ${({ theme }) => theme.editLeague.headerColor};
  }
  svg{
     color: ${({ theme }) => theme.editLeague.headerColor};
  }
`;
const TitleAndMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.editLeague.bg};
`;
const FlagTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 25px 40px;
  h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: ${({ theme }) => theme.editLeague.headerColor};
  }
  img {
    border-radius: 4px;
    height: 26px;
  }
`;
const CardMenu = styled.div`
  padding: 0 40px;
  display: flex;
  column-gap: 20px;

  button {
    transition: none !important;
    background: none;
    border: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.editLeague.menuTextColor};
    padding-bottom: 4px;
    cursor: pointer;
  }
  padding-bottom: 20px;
  border-bottom: 3px solid ${({ theme }) => theme.editLeague.borderColor};
  .active {
    transition: none !important;
    margin-bottom: -3px;
    border-bottom: 3px solid var(--primaryBlue);
  }
`;