import bet365Icon from "../../assets/icons/BookmakersIcons/bet365.svg";
import skybetIcon from "../../assets/icons/BookmakersIcons/skybet.svg";
import betFairIcon from "../../assets/icons/BookmakersIcons/betfair.svg";
import Paddypower from "../../assets/icons/BookmakersIcons/paddypower.svg";
import willhillIcon from "../../assets/icons/BookmakersIcons/willhill.svg";
import ladbrokersIcon from "../../assets/icons/BookmakersIcons/ladbrokers.svg";
import coralIcon from "../../assets/icons/BookmakersIcons/coral.svg";
import betvictorIcon from "../../assets/icons/BookmakersIcons/betvictor.svg";
import bwinIcon from "../../assets/icons/BookmakersIcons/bwin.svg";
import unibetIcon from "../../assets/icons/BookmakersIcons/unibet.svg";
import spinIcon from "../../assets/icons/BookmakersIcons/spin.svg";
import hkjcIcon from "../../assets/icons/BookmakersIcons/hkjc.svg";
import ibcIcon from "../../assets/icons/BookmakersIcons/ibc.svg";
import sharpbetIcon from "../../assets/icons/BookmakersIcons/sharpbet.svg";
import isnIcon from "../../assets/icons/BookmakersIcons/isn.svg";
import pinnacleIcon from "../../assets/icons/BookmakersIcons/pinnacle.svg";
import singIcon from "../../assets/icons/BookmakersIcons/sing.svg";
import sbobetIcon from "../../assets/icons/BookmakersIcons/sbobet.svg";
import goldbetIcon from "../../assets/icons/BookmakersIcons/goldbet.svg";
import tipwinIcon from "../../assets/icons/BookmakersIcons/tipwin.svg";
import tiptorroIcon from "../../assets/icons/BookmakersIcons/tiptorro.svg";

import betisnSmallIcon from "../../assets/icons/bookmakers-small-icons/BETISN.svg";
import ibcbetSmallIcon from "../../assets/icons/bookmakers-small-icons/IBCBET.svg";
import sbobetSmallIcon from "../../assets/icons/bookmakers-small-icons/SBOBET.svg";
import singbetSmallIcon from "../../assets/icons/bookmakers-small-icons/SINGBET.svg";
import goldbetSmallIcon from "../../assets/icons/bookmakers-small-icons/GOLDBET.svg";
import tipwinSmallIcon from "../../assets/icons/bookmakers-small-icons/TIPWIN.svg";
import tiptorroSmallIcon from "../../assets/icons/bookmakers-small-icons/TIPTORRO.svg";

export const bookmakersList = [
  {
    bookmaker_name: "Bet365",
    bookmaker_id: 126,
    logo: bet365Icon,
    logo_small: bet365Icon,
    bookmaker_backend_name: "bet365",
  },
  {
    bookmaker_name: "Skybet",
    bookmaker_id: 221,
    logo: skybetIcon,
    logo_small: skybetIcon,
    bookmaker_backend_name: "skybet",
  },
  {
    bookmaker_name: "BFSportsbook",
    bookmaker_id: 613,
    logo: betFairIcon,
    logo_small: betFairIcon,
    bookmaker_backend_name: "betfairsb",
  },
  {
    bookmaker_name: "Paddypower",
    bookmaker_id: 84,
    logo: Paddypower,
    logo_small: Paddypower,
    bookmaker_backend_name: "paddypower",
  },
  {
    bookmaker_name: "WillHill",
    bookmaker_id: 42,
    logo: willhillIcon,
    logo_small: willhillIcon,
    bookmaker_backend_name: "willhill",
  },
  {
    bookmaker_name: "Ladbrokes",
    bookmaker_id: 17,
    logo: ladbrokersIcon,
    logo_small: ladbrokersIcon,
    bookmaker_backend_name: "ladbrokes",
  },
  {
    bookmaker_name: "Coral",
    bookmaker_id: 244,
    logo: coralIcon,
    logo_small: coralIcon,
    bookmaker_backend_name: "coral",
  },
  {
    bookmaker_name: "Betvictor",
    bookmaker_id: 22,
    logo: betvictorIcon,
    logo_small: betvictorIcon,
    bookmaker_backend_name: "victor",
  },
  {
    bookmaker_name: "Bwin",
    bookmaker_id: 30,
    logo: bwinIcon,
    logo_small: bwinIcon,
    bookmaker_backend_name: "bwin",
  },
  {
    bookmaker_name: "Unibet",
    bookmaker_id: 744,
    logo: unibetIcon,
    logo_small: unibetIcon,
    bookmaker_backend_name: "unibet.co.uk",
  },
  {
    bookmaker_name: "SPIN",
    bookmaker_id: 272,
    logo: spinIcon,
    logo_small: spinIcon,
    bookmaker_backend_name: "sportingindex",
  },
  {
    bookmaker_name: "HKJC",
    bookmaker_id: 245,
    logo: hkjcIcon,
    logo_small: hkjcIcon,
    bookmaker_backend_name: "hkjc",
  },
  {
    bookmaker_name: "IBC",
    bookmaker_id: 285,
    logo: ibcIcon,
    logo_small: ibcbetSmallIcon,
    bookmaker_backend_name: "ibcbet",
  },
  {
    bookmaker_name: "Sharpbet",
    bookmaker_id: 894,
    logo: sharpbetIcon,
    logo_small: sharpbetIcon,
    bookmaker_backend_name: "sharpbet",
  },
  {
    bookmaker_name: "ISN",
    bookmaker_id: 571,
    logo: isnIcon,
    logo_small: betisnSmallIcon,
    bookmaker_backend_name: "betisn",
  },
  {
    bookmaker_name: "Pinnacle",
    bookmaker_id: 0,
    logo: pinnacleIcon,
    logo_small: pinnacleIcon,
    bookmaker_backend_name: "pinnaclesports",
  },
  {
    bookmaker_name: "Sing",
    bookmaker_id: 282,
    logo: singIcon,
    logo_small: singbetSmallIcon,
    bookmaker_backend_name: "singbet",
  },
  {
    bookmaker_name: "Sbobet",
    bookmaker_id: 327,
    logo: sbobetIcon,
    logo_small: sbobetSmallIcon,
    bookmaker_backend_name: "sbobet",
  },
  {
    bookmaker_name: "GoldBet",
    bookmaker_id: 128,
    logo: goldbetIcon,
    logo_small: goldbetSmallIcon,
    bookmaker_backend_name: "goldbet",
  },
  {
    bookmaker_name: "TipWin",
    bookmaker_id: 920,
    logo: tipwinIcon,
    logo_small: tipwinSmallIcon,
    bookmaker_backend_name: "tipwin",
  },
  {
    bookmaker_name: "TipTorro",
    bookmaker_id: 921,
    logo: tiptorroIcon,
    logo_small: tiptorroSmallIcon,
    bookmaker_backend_name: "tiptoro",
  },
];
