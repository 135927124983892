import React, { useState } from "react";
import styled from "styled-components";
import {
    sortLoggedBetsByBetKey,
    sortLoggedBetsByLoggedBetKey,
    sortLoggedBetsLeaguesTeams,
    sortRsBetsByBetKey,
} from "../../../helper/Betting/sortBets";
import SortCell from "../SortCell";


const RetailLoggedBetsHeader = ({ loggedBetsList, setLoggedBetsList }) => {
    const [ascending, setAscending] = useState(true);
    const [lastSortKeyRsBets, setLastSortKeyRsBets] = useState(null);
    const [lastAscendingRsBets, setLastAscendingRsBets] = useState(true);

    const sortRsBets = (ascending, filterKey) => {
        setLastAscendingRsBets(ascending);
        setLastSortKeyRsBets(filterKey);
        sortRsBetsByBetKey(loggedBetsList, filterKey, setLoggedBetsList, ascending);
    }

    const handleSort = (filterKey) => {
        sortRsBets(ascending, filterKey);
        setAscending(!ascending);
    };

    return (
        <Container>

            <Cell style={{ minWidth: "30px", maxWidth: "30px" }}></Cell>
            <Cell style={{ minWidth: "30px", maxWidth: "30px" }}>
                <p>TIER</p>
            </Cell>
            <CellWrap style={{ width: "75px" }}>
                <SortCell name={"KO TIME"} onClick={() => handleSort("start_time")} />
            </CellWrap>
            <CellWrap style={{ minWidth: "150px" }}>
                <SortCell name={"GAME"} onClick={() => handleSort("home_team")} />
            </CellWrap>
            <CellWrap className="center" style={{ minWidth: "100px" }}>
                <SortCell name={"MARKET"} onClick={() => handleSort("market")} />
            </CellWrap>
            <CellWrap style={{ minWidth: "100px" }}>
                <SortCell name={"SELECTION"} onClick={() => handleSort("selection")} />
            </CellWrap>
            <CellWrap style={{ minWidth: "60px" }} className="center">
                <SortCell name={"TARGET "} onClick={() => handleSort("target")} />
            </CellWrap>
            <CellWrap style={{ minWidth: "60px" }} className="center">
                <SortCell name={"MIN PRICE"} onClick={() => handleSort("min_price")} />
            </CellWrap>



            <CellWrap className="center" style={{ minWidth: "40px" }}>
                <SortCell name={"MKT"} onClick={() => handleSort("market_price")} />
            </CellWrap>
            <CellWrap className="center" style={{ minWidth: "60px" }}>
                <SortCell
                    name={"MKT VALUE"}
                    onClick={() => handleSort("market_value")}
                />
            </CellWrap>


            <CellWrap style={{ minWidth: "70px" }} className="center">
            </CellWrap>

            <Cell style={{ minWidth: "60px", justifyContent: "center" }}>
            </Cell>

            <Cell style={{ minWidth: "70px" }}>
            </Cell>

            <Cell style={{ minWidth: "70px" }}>
                <p>STAKE</p>
            </Cell>

            <Cell className="toggleWidth">
                <p>PRICE</p>
            </Cell>
            <Cell style={{ minWidth: "60px" }}></Cell>

            <Cell style={{ minWidth: "50px" }}></Cell>
            <Cell className="toggleWidth"> <p>NOTES</p></Cell>


        </Container>
    );
};

export default RetailLoggedBetsHeader;
const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-width: 1600px;
  background-color: ${({ theme }) => theme.leagueBG};
  color: ${({ theme }) => theme.betHeaderTextColor};
  padding: 14px 14px;
  column-gap: 9px;
  max-height: 36px;
  .center {
    justify-content: center;
  }
  .toggleWidth {
    min-width: 40px;
  }
`;

const CellWrap = styled.div`
  display: flex;
  align-items: center;
  min-width: 60px;
  width: 100%;
`;
const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  width: 100%;

  p {
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    text-transform: uppercase;
  }
`;
