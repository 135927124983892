import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as PlusSvg } from "../../../assets/icons/LeaguesIcons/plusIcon.svg";
import LoaderSpinner from '../../../helper/LoaderSpinner';
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";
import { lightTheme } from "../../../theme/theme";
import { useSelector } from 'react-redux';
import { selectUser } from '../../../Redux/user/user';

const AddNewTeamForLeague = ({
    setSelectedTeamToAdd,
    selectedTeamToAdd,
    availableTeams,
    handleAddNewTeam,
    searchTerm,
    setSearchTerm,
    otherParticipants
}) => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const ref = useRef();
    const user = useSelector(selectUser);

    const handleSelectTeam = (team, from_another_league) => {
        setSelectedTeamToAdd(
            {
                team: team,
                from_another_league: from_another_league

            }

        );
        setSearchTerm("");
        setIsMenuOpen(false)
    }

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    return (
        <Container>
            <GroupContainer style={{ alignItems: "end" }}>
                <MenuWrapper ref={ref}>
                    <SelectedItemWrapper onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        {/* <p>{selectedTeamToAdd?.name} ({selectedTeamToAdd?.wy_id})</p> */}
                        <ElementInput
                            value={selectedTeamToAdd ? selectedTeamToAdd?.team?.name : ""}
                            readOnly
                            placeholder='Select team'
                        />
                        <DropdownArrow>
                            <UpSvg style={isMenuOpen ? {} : { transform: "rotate(180deg)" }} />
                        </DropdownArrow>
                    </SelectedItemWrapper>
                    {isMenuOpen && (
                        availableTeams.length == 0 ?
                            <LoaderSpinner />
                            :
                            <MenuList>
                                {user?.roles[0] === "admin" &&
                                    <>
                                        <MenuListItem>
                                            <SearchParticipantInput placeholder='Search participant' value={searchTerm} onChange={(e) => setSearchTerm(e.currentTarget.value)} />
                                        </MenuListItem>
                                        {otherParticipants?.map((otherParticipant, index) => (
                                            <MenuListItem key={otherParticipant.participant_id} onClick={() => handleSelectTeam(otherParticipant, true)} otherParticipant>
                                                <p>{otherParticipant.name} ({otherParticipant.area}, {otherParticipant.id})</p>
                                            </MenuListItem>

                                        ))}
                                    </>
                                }

                                {otherParticipants?.length == 0 && availableTeams?.map((team, index) => (
                                    <MenuListItem key={index} onClick={() => handleSelectTeam(team, false)}>
                                        <p>{team.name} ({team.wy_id})</p>
                                    </MenuListItem>
                                ))}
                            </MenuList>)
                    }

                </MenuWrapper>

                <NewRefereeButton
                    disabled={!selectedTeamToAdd}
                    onClick={() => handleAddNewTeam()}
                >
                    <PlusSvg />
                    Add new team
                </NewRefereeButton>
            </GroupContainer>
        </Container>
    )
}

export default AddNewTeamForLeague

const Container = styled.div`

`
const MenuWrapper = styled.div`
position:relative;
width:100%;
`

const MenuList = styled.div`
display:flex;
flex-direction:column;
position:absolute;
max-height:250px;
overflow:auto;
width:100%;
 background: ${({ theme }) => theme.editLeague.readOnlyInputs};
 z-index:2;
 border:1px solid #bac3e8;
 margin-top:-3px;
`
const MenuListItem = styled.div`
display:flex;
align-items:center;
padding: 5px 10px;
width:100%;
cursor:pointer;
 p {
    text-transform:capitalize;
    font-family: "Roboto";
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 14px;
 color: ${props => props.otherParticipant ? (({ theme }) => theme.playerPerformaceCard.attack.color) : ({ theme }) => theme.editLeague.menuTextColor};
 }
`
const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
   
  }
`;

const ElementInput = styled.input`
  height: 40px;
   background: ${({ theme }) => theme.editLeague.readOnlyInputs};
  width: 240px;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color:${({ theme }) => theme.editLeague.readOnlyInputsColor};
  padding: 10px 15px;
  cursor:inherit;
  :focus {
    outline:none;
  }
  ::placeholder {
     color:${({ theme }) => theme.editLeague.readOnlyInputsColor};
     opacity:0.7;
  }
  :disabled{
    background: ${({ theme }) => theme.editLeague.readOnlyInputs};
    color:${({ theme }) => theme.editLeague.readOnlyInputsColor};

  }
`;
const NewRefereeButton = styled.button`
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  background: #ffffff;
  border: 0.7px solid #006fff;
  border-radius: 3px;
  padding: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--primaryBlue);
  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`;


const SelectedItemWrapper = styled.div
    `
    cursor:pointer;
    display:flex;
align-items:center;

    `
const DropdownArrow = styled.div`
display:flex;
align-items:center;
position:absolute;
right:10px;
    svg {
        cursor: pointer;
        fill:${({ theme }) => theme.editLeague.selectMenuSvgBg};
          stroke:${({ theme }) => theme.editLeague.selectMenuSvgColor}
      }
    `
const SearchParticipantInput = styled.input`
    display:flex;
    width:100%;
    background: ${({ theme }) => theme.editLeague.readOnlyInputs};
    outline:none;
    border: 1px solid #bac3e8;
    border-radius:6px;
    padding:3px 5px;
    color:${({ theme }) => theme.editLeague.readOnlyInputsColor};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    `

const Separator = styled.div`
    height:2px;
    width:100%;
    background:red;
    min-height:2px;
    `