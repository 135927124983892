import { POST } from "../APiHelper/API";

export const getSquadsForGame = (data, onSuccess, onError) => {
  // const data = {
  //   fixture_id: fixture_id,
  //   league_id: league_id,
  // };

  POST(`/api/v1/games/squads`, data, onSuccess, onError);
};
