import { PUT } from "../APiHelper/API";
import { formatted_date_for_backend } from "../../helper/Generic/dateTimeFormat";

export const editLoggedBet = (
  logged_bet_id,
  broker,
  ret_stake,
  confirmed_price,
  time_ret,
  time_req,
  incorrect,
  onSuccess,
  onError
) => {
  const data = {
    logged_bet_id: logged_bet_id,
    broker: broker,
    ret_stake: ret_stake,
    confirmed_price: confirmed_price,
    time_ret: formatted_date_for_backend(time_ret),
    time_req: formatted_date_for_backend(time_req),
    incorrect: incorrect,
  };

  PUT("/api/v1/bets/edit_logged_bet", data, onSuccess, onError);
};
