import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CountryMenu from "../../components/bettingComponents/Menu/CountryMenu";
import LeagueMenu from "../../components/bettingComponents/Menu/LeagueMenu";
import { ReactComponent as SearchSvg } from "../../assets/icons/BettingIcons/search.svg";
import { ReactComponent as DownloadSvg } from "../../assets/icons/BettingIcons/downloadArrow.svg";
import { PulseLoader } from "react-spinners";
import LoggedBetsHeader from "../../components/bettingComponents/LoggedBets/LoggedBetsHeader";
import LoggedBetsElement from "../../components/bettingComponents/LoggedBets/LoggedBetsElement";
import { getCountriesForCurrentUser } from "../../api/competitions/GetCountriesForCurrentUser";
import { getCompetitionsForCurrentUser } from "../../api/competitions/GetCompetitionsForCurrentUser";
import { useLoggedBetsService } from "../../context/useLoggedBetsService";
import { mapLoggedBets } from "../../helper/Betting/mapLoggedBets";
import { useBetsService } from "../../context/useBetsService";
import NotePanel from "../../components/notes/NotePanel";
import fileDownload from "js-file-download";
import {
  notifyError,
  notifyLoading,
  notifyUpdate,
} from "../../helper/Generic/Notifications";
import { toast } from "react-toastify";
import { downloadLoggedBetsCSV } from "../../api/bets/downloadLoggedBetsCvs";
import {
  format_time_to_timezone,
  same_time_in_timezone,
} from "../../helper/Generic/dateTimeFormat";
import { useSelector } from "react-redux";
import { selectUser } from "../../Redux/user/user";
import DatePickerMenuLoggedBets from "../../components/bettingComponents/Menu/DatePickerMenuLoggedBets";
import TierMenu from "../../components/bettingComponents/Menu/TierMenu";
import { tierList } from "../../helper/Values/TierList";
import { useLocation, useOutletContext, useSearchParams } from "react-router-dom";
import BettingPageHeader from "../../components/bettingComponents/Header";
import qs from "query-string";
import { sortLoggedBetsByCreatedDate } from "../../helper/ModelDataHelper";
import WebSocketObserver from "../../helper/websocket/WebSocketObserver";
import { useWebSocketDataService } from "../../context/useWebSocketDataService";
import { getCompetitions, getCompetitionsForId, getCountries } from "../../helper/PricingHeaderMenuRequests";

const LoggedBets = () => {
  const user = useSelector(selectUser);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loggedBets, setLoggedBets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [countries, setCountries] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const newDate = new Date();
  const [date, setDate] = useState([newDate, newDate]);
  const [countriesIds, setCountriesIds] = useState([]);
  const [selectedCompetitions, setSelectedCompetitions] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [competitionsIds, setCompetitionIds] = useState([]);
  const [tier, setTier] = useState([]);
  const [tierIds, setTierIds] = useState([2, 3, 4]);
  const [loggedBetsList, setLoggedBetsList] = useState([]);

  const { getLBets } = useLoggedBetsService();

  const {
    betsPrices,
    getBetsPrices,
    brokers,
    getBrokers,
    bettingPricesWSHandler
  } = useBetsService();
  const { add_observer, remove_observer } = useWebSocketDataService();
  const [showNotes, setShowNotes] = useState(false);
  const [fixtureForConversation, setFixtureForConversation] = useState(null);
  const [modelEv, setModelEv] = useOutletContext();
  const [marketEv, setMarketEv] = useOutletContext();
  const [loggedBetsNumber, setLoggedbetsNumber] = useOutletContext();
  const [loggedBetsOUGNumber, setLoggedBetsOUGNumber] = useOutletContext();
  const [loggedBetsHCPNumber, setLoggedBetsHCPNumber] = useOutletContext();
  const [retStakeSum, setRetStakeSum] = useOutletContext();
  const [riskSum, setRiskSum] = useOutletContext();
  const toastId = useRef(null);

  const bettingMarketPriceObserver = new WebSocketObserver("MarketBettingPricesChannel", (message) => {
    bettingPricesWSHandler(message)
  })

  const handleInputSearch = (e) => {
    setSearchInput(e.target.value);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSetSearchParams();
      handleLoggedBets();
    }
  };
  const closeNote = () => {
    setShowNotes(false);
  };
  const handleNote = (fixture) => {
    setShowNotes(true);
    setFixtureForConversation(fixture);
  };

  const handleDownloadCSV = () => {
    let start_time = null;
    let end_time = null;
    let competitions_ids = null;
    let term = null;
    let tier_filter = null;

    notifyLoading(toastId, "Generating CSV");
    if (competitionsIds.length === 0) {
      competitions_ids = null;
    } else {
      competitions_ids = competitionsIds;
    }

    if (searchInput === "") {
      term = null;
    } else {
      term = searchInput;
    }
    if (tierIds == null) {
      tier_filter = null;
    } else {
      tier_filter = tierIds;
    }
    if (date !== null) {
      start_time = same_time_in_timezone(date[0], user.timezone)
        .startOf("day")
        .toDate();
      end_time = same_time_in_timezone(date[1], user.timezone)
        .endOf("day")
        .toDate();
    }

    downloadLoggedBetsCSV(
      competitions_ids,
      term,
      start_time,
      end_time,
      tier_filter,
      function (response) {
        notifyUpdate(toastId, "CSV Generated", toast.TYPE.SUCCESS);
        fileDownload(response.data, `${dayjs()}.csv`);
      },
      function (error) {
        notifyUpdate(toastId, "Failed to generate", toast.TYPE.ERROR);
      }
    );
  };

  const completelyRefreshLoggedBets = () => {
    setLoggedbetsNumber(0);
    setLoggedBetsList(null);
    handleLoggedBets();
  };

  const handleLoggedBets = () => {
    setLoggedBets();
    setIsLoading(true);
    let countries_ids = null;
    let start_time = null;
    let end_time = null;
    let competitions_ids = null;
    let term = null;
    let tier_filter = null;

    if (countriesIds.length === 0) {
      countries_ids = null;
    } else {
      countries_ids = countriesIds;
    }
    if (competitionsIds.length === 0) {
      competitions_ids = null;
    } else {
      competitions_ids = competitionsIds;
    }
    if (searchInput === "") {
      term = null;
    } else {
      term = searchInput;
    }
    if (tierIds == null) {
      tier_filter = null;
    } else {
      tier_filter = tierIds;
    }
    if (date !== null) {
      start_time = same_time_in_timezone(date[0], user.timezone)
        .startOf("day")
        .toDate();
      end_time = same_time_in_timezone(date[1], user.timezone)
        .endOf("day")
        .toDate();
    }

    getLBets(
      countries_ids,
      start_time,
      end_time,
      competitions_ids,
      term,
      tier_filter,
      function (response) {
        const sorted = sortLoggedBetsByCreatedDate(response.data.bets);
        setLoggedBets(sorted);
        setIsLoading(false);
      },
      function () {
        notifyError("Something wrong");
        setIsLoading(false);
      }
    );

    // getBetsPrices(function () {
    //   notifyError("Something wrong");
    // });

    // Set metrics to 0
    setModelEv(0);
    setMarketEv(0);
  };

  const handleGetBetsPrices = () => {
    getBetsPrices(function () {
      notifyError("Something wrong...");
    });
  }

  const calculateNumberOfBets = (betsList) => {
    let betsNumber = 0;
    let HCPSum = 0;
    let OUGSum = 0;

    betsList?.forEach((item) => {
      if (item.logged_bet.incorrect) {
        return;
      }

      if (item.bet.market == "HCP") {
        HCPSum = HCPSum + 1;
      }

      if (item.bet.market == "OUG") {
        OUGSum = OUGSum + 1;
      }

      betsNumber = betsNumber + 1;
    });

    setLoggedbetsNumber(betsNumber);
    setLoggedBetsHCPNumber(HCPSum);
    setLoggedBetsOUGNumber(OUGSum);
  };

  const calculateAllBetsRetStake = (betsList) => {
    let sum = 0;
    betsList?.forEach((item) => {
      if (item.logged_bet.incorrect) {
        return;
      }
      sum = sum + item.logged_bet.gbp_ret_stake;
    });

    setRetStakeSum(sum);
  };
  const calculateAllBetsRisk = (betsList) => {
    let sum = 0;
    betsList?.forEach((item) => {
      if (item.logged_bet.incorrect) {
        return;
      }

      sum =
        sum + item.logged_bet.gbp_ret_stake * item.logged_bet.confirmed_price;
    });
    setRiskSum(sum);
  };

  const CreateLoggedBetsRows = () => {
    let loggedBetsList = mapLoggedBets(loggedBets, betsPrices);
    setLoggedBetsList(loggedBetsList);
  };

  const calculateEvValues = () => {
    let model_ev = 0;
    let market_ev = 0;

    loggedBetsList?.forEach((item) => {
      if (item.logged_bet.incorrect) {
        // Go to the next element
        return;
      }

      if (item.bet.value) {
        model_ev =
          model_ev + item.logged_bet.gbp_ret_stake * (item.bet.value / 100);
      }

      if (item.bet.live_value) {
        market_ev =
          market_ev +
          item.logged_bet.gbp_ret_stake * (item.bet.live_value / 100);
      }
    });

    setModelEv(model_ev);
    setMarketEv(market_ev);
  };

  useEffect(() => {
    if (countriesList.length == 0) {
      getCountries(setCountriesList);
      getCompetitions(setCompetitions);
    }

    if (countriesList.length > 0 && countries.length > 0) {
      getCompetitionsForId(countriesIds,
        (sorted) => {
          setCompetitionIds([]);
          setSelectedCompetitions([]);
          setCompetitions(sorted);
        }
      );
    }

    if (countriesList.length > 0 && countries.length == 0) {
      getCompetitions(setCompetitions);
    }

  }, [countries]);

  const handleSetSearchParams = () => {
    let start;
    let end;

    if (date != null) {
      start = date[0].toISOString();
      end = date[1].toISOString();
    } else {
      start = null;
      end = null;
    }

    let params = qs.stringify({
      tier: tierIds,
      countryId: countriesIds,
      leagueId: competitionsIds,
      term: searchInput,
      start_date: start,
      end_date: end,
    });

    setSearchParams(params);
  };

  const initializeMenuValuesFromParams = () => {
    if (location.search != "") {
      let params = qs.parse(location.search);

      let tier = params.tier;
      let end_date = params.end_date;
      let start_date = params.start_date;
      let term = params.term;
      let leaguesId = params.leagueId;
      let countryId = params.countryId;

      setSearchInput(term);

      if (start_date != "" && end_date != "") {
        setDate([new Date(start_date), new Date(end_date)]);
      }

      if (Array.isArray(leaguesId)) {
        let values = leaguesId.map((item) => parseInt(item));
        setCompetitionIds(values);
      } else if (leaguesId?.length > 0) {
        setCompetitionIds([parseInt(leaguesId)]);
      }

      if (Array.isArray(countryId)) {
        let values = countryId.map((item) => parseInt(item));
        setCountriesIds(values);
      } else if (countryId?.length > 0) {
        setCountriesIds([parseInt(countryId)]);
      }

      if (Array.isArray(tier)) {
        let values = tier.map((item) => parseInt(item));
        setTierIds(values);
      } else if (tier?.length > 0) {
        setTierIds([parseInt(tier)]);
      }
    }
  };

  const fetchBrokers = () => {
    getBrokers(function () {
      notifyError("Something wrong...");
    });
  };

  useEffect(() => {
    calculateEvValues();
    calculateNumberOfBets(loggedBetsList);
    calculateAllBetsRetStake(loggedBetsList);
    calculateAllBetsRisk(loggedBetsList);
  }, [loggedBetsList]);

  useEffect(() => {
    handleLoggedBets();
    handleSetSearchParams();
  }, [countriesIds, competitionsIds, date, tier]);

  useEffect(() => {
    CreateLoggedBetsRows();
  }, [loggedBets, betsPrices]);

  useEffect(() => {
    initializeMenuValuesFromParams();
    handleGetBetsPrices();
    fetchBrokers();
    add_observer(bettingMarketPriceObserver);

    return () => {
      remove_observer(bettingMarketPriceObserver);
    }
  }, []);

  return (
    <Container>
      <Main>
        {/* <BettingPageHeader
          marketEv={marketEv}
          modelEv={modelEv}
          loggedBetsNumber={loggedBetsNumber}
          loggedBetsOUGNumber={loggedBetsOUGNumber}
          loggedBetsHCPNumber={loggedBetsHCPNumber}
          retStakeSum={retStakeSum}
          riskSum={riskSum}
        /> */}
        <Header>
          <Left>
            {" "}
            <div className="searchInput">
              <SearchSvg />
              <input
                value={searchInput}
                onChange={(e) => handleInputSearch(e)}
                placeholder="Filter by keyboard"
                onKeyDown={(e) => handleKeyDown(e)}
              ></input>
            </div>
            <CountryMenu
              className="countryDropDown"
              label={"Countries"}
              countriesList={countriesList}
              countries={countries}
              setCountries={setCountries}
              countriesIds={countriesIds}
              setCountriesIds={setCountriesIds}
            />
            <LeagueMenu
              label={"Leagues"}
              competitions={competitions}
              selectedCompetitions={selectedCompetitions}
              setSelectedCompetitions={setSelectedCompetitions}
              competitionIds={competitionsIds}
              setCompetitionIds={setCompetitionIds}
            />
            <DatePickerMenuLoggedBets
              label={"Date"}
              selectedValue={date}
              setSelectedValue={setDate}
            />
            <TierMenu
              label={"Tier"}
              tierList={tierList}
              tier={tier}
              setTier={setTier}
              tierIds={tierIds}
              setTierIds={setTierIds}
            />
          </Left>
          <Right>

            <CsvBtn onClick={() => handleDownloadCSV()}>
              <DownloadSvg />
              CSV
            </CsvBtn>
          </Right>
        </Header>
        <ContentWrapper>
          <Content>
            <LoggedBetsHeader
              loggedBetsList={loggedBetsList}
              setLoggedBetsList={setLoggedBetsList}
            />
            {isLoading ? (
              <div className="loader">
                <PulseLoader color="#006fff" />
              </div>
            ) : (
              loggedBetsList?.map((item, index) => (
                <LoggedBetsElement
                  key={item.logged_bet.id}
                  indexbg={index}
                  data={item}
                  handleNote={handleNote}
                  reloadData={completelyRefreshLoggedBets}
                  brokers={brokers}
                />
              ))
            )}
          </Content>
        </ContentWrapper>
      </Main>
      {showNotes && (
        <NoteWrapper>
          <NotePanel closeNote={closeNote} fixture={fixtureForConversation} />
        </NoteWrapper>
      )}
    </Container>
  );
};

export default LoggedBets;
const Container = styled.div`
  display: flex;
  width: 100%;
  min-width: 1600px;
  min-height: calc(100vh - 50px);
  background: ${({ theme }) => theme.matchElementBg};
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: calc(100vh - 90px);
`;
const NoteWrapper = styled.div`
  @media screen and (max-width: 1820px) {
    position: absolute;
    right: 10px;
    height: 100%;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  min-width: 1600px;
  justify-content: space-between;
`;
const Left = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.headerBG};
  padding: 8px 14px;
  column-gap: 6px;
  .searchInput {
    position: relative;
    svg {
      color: ${({ theme }) => theme.menuTextColor};
      position: absolute;
      top: 9px;
      left: 10px;
    }
    input {
      min-width: 242px;
      color: ${({ theme }) => theme.menuTextColor};
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      ::placeholder {
        color: ${({ theme }) => theme.menuTextColor};
      }
      outline: none;
      border: none;
      :focus-visible {
        border: none;
        outline: none;
      }
      padding: 5px 10px 3px 26px;
      background: ${({ theme }) => theme.menuBgColor};
      border: none;
      border-radius: 8px;
    }
  }
`;
const Right = styled(Left)`
  justify-content: end;

  .numberFormatter {
    text-transform: uppercase;
    color: ${({ theme }) => theme.menuTextColor};
    font-weight: 600;
    font-size: 10px;
  }
`;
const ModelEv = styled.div`
  display: flex;
  width: fit-content;
  column-gap: 5px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.menuBgColor};
  border-radius: 6px;
  padding: 0 10px;
  p {
    color: ${({ theme }) => theme.menuTextColor};
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
  }
`;
const MarketEv = styled(ModelEv)``;

const Content = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
`;

const CsvBtn = styled.button`
  cursor: pointer;
  border-radius: 4px;
  column-gap: 5px;
  padding: 5px 10px 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  background: var(--primaryBlue);
  color: #ffffff;
`;
