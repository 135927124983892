import cloneDeep from "lodash.clonedeep";

export const sortLeaguesAz = (leaguesList) => {
    let sortedLeagueList = leaguesList.sort((a, b) => a.name > b.name ? 1 : -1);
    sortedLeagueList.forEach((league) => {
        league.teams.sort((a, b) => a.name > b.name ? 1 : -1);
    });
    return sortedLeagueList;
};

export const sortTeamsAZ = (league) => {
    let clone = cloneDeep(league);
    if (!league) {
        return;
    }
    let sorted = clone.sort((a, b) => a.name > b.name ? 1 : -1);
    return sorted;
};

export const sortTeamsInLeagueAZ = (league) => {
    let clone = cloneDeep(league);
    if (!league) {
        return;
    }
    let sorted = clone.teams.sort((a, b) => a.name > b.name ? 1 : -1);
    clone.teams = sorted;

    return clone;
};

export const sortTeamsByName = (
    league,
    // setFunction,
    ascending = true
) => {

    let oldLeaguesList = cloneDeep(league);
    oldLeaguesList.forEach((league) => {
        if (ascending) {
            league.teams.sort((a, b) => {
                return a.name > b.name ? 1 : -1;
            });
        }
        else {
            league.teams.sort((a, b) => {
                return a.name < b.name ? 1 : -1;
            });
        }

    });
    return oldLeaguesList
    // setFunction(oldLeaguesList);
};

export const sortTeamsByKey = (
    leaguesList,
    sortKey,
    ascending = true
) => {

    let oldLeaguesList = cloneDeep(leaguesList);

    oldLeaguesList.forEach((league) => {
        let notNullItems = league.teams.filter((item) => item.stadium != null);
        let nullItems = league.teams.filter(item => item.stadium == null);

        if (ascending) {
            notNullItems.sort((a, b) => {
                // if (a.stadium == null || b.stadium == null) {
                //     return;
                // }
                return a.stadium[sortKey] > b.stadium[sortKey] ? 1 : -1;
            });
        }
        else {
            notNullItems.sort((a, b) => {
                // if (a.stadium == null || b.stadium == null) {
                //     return;
                // }
                return a.stadium[sortKey] < b.stadium[sortKey] ? 1 : -1;
            });
        }

        league.teams = notNullItems.concat(nullItems);

    });

    // setFunction(oldLeaguesList);
    return oldLeaguesList;
};

export const sortTeamsByPitchSize = (
    leaguesList,
    // setFunction,
    ascending = true
) => {

    let oldLeaguesList = cloneDeep(leaguesList);

    oldLeaguesList.forEach((league) => {
        let notNullItems = league.teams.filter((item) => item.stadium != null);
        let nullItems = league.teams.filter(item => item.stadium == null);
        if (ascending) {
            notNullItems.sort((a, b) => {
                // if (a.stadium == null || b.stadium == null) {
                //     return;
                // }
                let aSurface = a.stadium.width * a.stadium.length;
                let bSurface = b.stadium.width * b.stadium.length;
                return aSurface > bSurface ? 1 : -1;
            });
        }
        else {
            notNullItems.sort((a, b) => {
                // if (a.stadium == null || b.stadium == null) {
                //     return;
                // }
                let aSurface = a.stadium.width * a.stadium.length;
                let bSurface = b.stadium.width * b.stadium.length;
                return aSurface < bSurface ? 1 : -1;
            });

        }
        league.teams = notNullItems.concat(nullItems);
    });
    return (oldLeaguesList)
    //   setFunction(oldLeaguesList);
};

export const sortTeamsBySharedStadium = (
    leaguesList,
    // setFunction,
    ascending = true
) => {
    let oldLeaguesList = cloneDeep(leaguesList);

    oldLeaguesList.forEach((league) => {
        let notNullItems = league.teams.filter((item) => item.stadium != null);
        let nullItems = league.teams.filter(item => item.stadium == null);
        if (ascending) {

            notNullItems.sort((a, b) => {
                // if (a.stadium == null || b.stadium == null) {
                //     return;
                // }
                return a.stadium.teams.lenght > b.stadium.teams.length ? 1 : -1;
            });
        }
        else {
            notNullItems.sort((a, b) => {
                // if (a.stadium == null || b.stadium == null) {
                //     return;
                // }
                return a.stadium.teams.length < b.stadium.teams.length ? 1 : -1;
            });
        }
        league.teams = notNullItems.concat(nullItems);
    });
    return oldLeaguesList
    // setFunction(oldLeaguesList);
};

