/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SingleOptionMenuSmall from "../../components/squadManagerComponents/Menu/SingleOptionMenuSmall";
import SelectTeamPlayerMenu from "./Menu/SelectTeamPlayerMenu";
import fieldimg from "../../assets/icons/field.png";
import FieldPlayer from "./FieldPlayer";
import ProjectLineUpPopupWarning from "./ProjectLineUpPopupWarning";
import { useMatchPreviewService } from "../../context/useMatchPreviewService";
import { notifyError, notifySucces } from "../../helper/Generic/Notifications";
import {
  ALL,
  AVAILABLE,
  BENCH,
  TeamFormation,
  UNAVAILABLE,
} from "../../helper/Values/TeamFormation";
import {
  isPlayerDuplicateInPlayers,
  isPlayerChangedFromInitialSelection,
  sortPlayersBasedOnPositions,
  shouldShowDuplicated,
  calculate_starting_chance,
  calculateStartingChanceFromPlayers,
  teamHaveDuplicatedPlayers,
  keepInitialOrder,
  getValidSelections,
} from "../../helper/lineup/LineupHelper";

import {
  numberOfDifferentPlayersBetweenSelections,
  hasDuplicatePlayers,
  buildUpdatedSelectionWithNewBench,
} from "../../helper/lineup/LineupHelper";
import BenchPlayer from "./BenchPlayer";
import { ReactComponent as EditpencilIcon } from "../../assets/icons/LeaguesIcons/editPencilIcon.svg";
import { useSelector } from "react-redux";
import { selectUser } from "../../Redux/user/user";
import EditPlayersView from "../squadManagerComponents/EditPlayersView";
import SelectTeamPlayerMenuWithPosition from "./Menu/SelectTeamPlayerMenuWithPosition";
import { initializePositionWithFirstPosition } from "../../helper/Analyst/SquadManager/initializePositionWithFirstPosition";
import { data } from "jquery";
import { SpinnerComponent } from "../genericComponents/SpinnerComponent";
import { FullScreenSpinner } from "../genericComponents/FullScreenSpinner";

const ProjectedLineUp = ({
  selectedFixture,
  currentLeague,
  currentCup,
  handleGetGames,
  setIsLoading,
  isLoading,
  statusCompleted,
  navigateToSquadManager,
  navigateToEditTeamPlayers,
  setThisteam,
  refreshOnClose,
  setShowEditPlayersModal
}) => {
  const { update_squads, update_squads_partial, update_squads_after_complete } = useMatchPreviewService();
  const user = useSelector(selectUser);
  const [currentGame, setCurrentGame] = useState(null);

  const [pickedFormation, setPickedFormation] = useState(null);
  const [pickedFormationAway, setPickedFormationAway] = useState(null);

  const [homeTeam, setHomeTeam] = useState(null);
  const [awayTeam, setAwayTeam] = useState(null);

  const [homeTeamSquad, setHomeTeamSquad] = useState(null);
  const [awayTeamSquad, setAwayTeamSquad] = useState(null);

  const [homeOldTeamSquad, setHomeOldTeamSquad] = useState(null);
  const [awayOldTeamSquad, setAwayOldTeamSquad] = useState(null);

  const [homeInitialSelection, setHomeInitialSelection] = useState([]);
  const [awayInitialSelection, setAwayInitialSelection] = useState([]);

  const [homeInitialBench, setHomeInitialBench] = useState([]);
  const [awayInitialBench, setAwayInitialBench] = useState([]);

  const [homeCurrentSelection, setHomeCurrentSelection] = useState([]); //current XI
  const [awayCurrentSelection, setAwayCurrentSelection] = useState([]);

  const [homeCurrentBench, setHomeCurrentBench] = useState([]); //benchplayers
  const [awayCurrentBench, setAwayCurrentBench] = useState([]);

  const [homePlayersList, setHomePlayersList] = useState([]); //first XI and bench
  const [awayPlayersList, setAwayPlayersList] = useState([]);

  const [homeAllPlayersToSelect, setHomeAllPlayersToSelect] = useState([]); //all players
  const [awayAllPlayersToSelect, setAwayAllPlayersToSelect] = useState([]);

  const [homeInitialData, setHomeInitialData] = useState([]);
  const [awayInitialData, setAwayInitialData] = useState([]);

  const [homeValidSelections, setHomeValidSelections] = useState([])
  const [awayValidSelections, setAwayValidSelections] = useState([])

  const [confirmed, setConfirmed] = useState(false);
  const [homeReady, setHomeReady] = useState(false);
  const [awayReady, setAwayReady] = useState(false);
  const [factorsSubmitted, setFactorsSubmitted] = useState(false)

  const [homePredicted, setHomePredicted] = useState(11);
  const [awayPredicted, setAwayPredicted] = useState(11);

  const [homeSelectionScore, setHomeSelectionScore] = useState();
  const [awaySelectionScore, setAwaySelectionScore] = useState();

  const [homePmCompleted, setHomePmCompleted] = useState();
  const [awayPmCompleted, setAwayPmCompleted] = useState();

  const [updateSquadAfterCompleteBtnDisabled, setUpdateSquadAfterCompleteBtnDisabled] = useState(true)

  const shouldUpdate = useRef(null);
  // const refreshOnClose = useRef(null);
  // const [showEditPlayersModal, setShowEditPlayersModal] = useState(false);
  // const [thisTeam, setThisteam] = useState();

  const homeTeamHaveDuplicatedPlayers = teamHaveDuplicatedPlayers(homeCurrentSelection.concat(homeCurrentBench))
  const awayTeamHaveDuplicatedPlayers = teamHaveDuplicatedPlayers(awayCurrentSelection.concat(awayCurrentBench))



  const { get_squads } = useMatchPreviewService();

  const shouldDisplayWarning =
    !currentGame?.game.home_lineup_submitted ||
    !currentGame?.game.away_lineup_submitted;

  const loadInitialData = () => {
    if (!selectedFixture) {
      return;
    }
    handleGetSquads(
      selectedFixture.fixture.fixture_id,
      currentLeague,
      currentCup
    );
  };

  const handleGetSquads = (fixture_id, league, cup) => {
    if (!fixture_id) {
      return;
    }
    var dataObject = {
      fixture_id: fixture_id,
    };

    if (cup) {
      dataObject.cup_id = currentCup.id;
    } else {
      dataObject.league_id = currentLeague.id;
    }



    setIsLoading(true);
    get_squads(
      dataObject,
      function (response) {
        setIsLoading(false);
        refreshOnClose.current = false;
        setCurrentGame(response.result);
      },
      function (error) {
        setIsLoading(false);
        notifyError("Something wrong");
      }
    );
  };

  const setTeams = (game) => {
    if (!game) {
      return;
    }

    if (game.game.fixture.participant1is_home) {
      setHomeTeam(game.game.team1);
      setAwayTeam(game.game.team2);

      setHomeTeamSquad(game.team1_squad);
      setAwayTeamSquad(game.team2_squad);

      setHomeOldTeamSquad(game.team1_old_squad);
      setAwayOldTeamSquad(game.team2_old_squad);

      setHomePmCompleted(game.game.pm_home_completed);
      setAwayPmCompleted(game.game.pm_away_completed);

      if (game.team1_squad?.predicted) {
        setHomePredicted(game.team1_squad?.predicted);
      }

      if (game.team2_squad?.predicted) {
        setAwayPredicted(game.team2_squad?.predicted);
      }

      setPickedFormation(game.team1_squad?.formation);
      setPickedFormationAway(game.team2_squad?.formation);
    } else {
      setHomeTeam(game.game.team2);
      setAwayTeam(game.game.team1);

      setHomeTeamSquad(game.team2_squad);
      setAwayTeamSquad(game.team1_squad);

      setHomeOldTeamSquad(game.team2_old_squad);
      setAwayOldTeamSquad(game.team1_old_squad);

      if (game.team1_squad?.predicted) {
        setAwayPredicted(game.team1_squad.predicted);
      }

      if (game.team2_squad?.predicted) {
        setHomePredicted(game.team2_squad?.predicted);
      }

      setPickedFormation(game.team2_squad?.formation);
      setPickedFormationAway(game.team1_squad?.formation);
    }
  };

  const parseFormation = (formation) => {
    if (!formation) {
      return;
    }
    let found = TeamFormation.find((item) => item.value === formation);
    return found;
  };

  const BuildLineUpPlayer = (pickedFormation, currentSelection, away) => {
    if (!pickedFormation) {
      return;
    }

    if (!currentSelection) {
      return;
    }

    const sortedSelection = sortPlayersBasedOnPositions(currentSelection);

    let formationObject = parseFormation(pickedFormation);
    let returnList = [];
    let currentIndex = 0;

    formationObject.groups.forEach((item) => {
      let playersList = [];
      for (let index = 0; index < item.number_of_players; index++) {
        playersList.push(
          <FieldPlayer
            key={currentIndex}
            player={currentSelection[currentIndex]}
            away={away}
          />
        );
        currentIndex++;
      }

      returnList.push(<Line>{playersList}</Line>);
    });
    return returnList;
  };


  const BuildLineUp = (
    formation,
    playersList,
    currentSelection,
    setCurrentSelection,
    initialPlayerSelectionList,
    currentAllPlayerSelectionList,
    allPlayersToSelect,
    pmStatusCompleted
  ) => {
    if (!playersList) {
      return;
    }
    if (!formation) {
      return;
    }

    const changeMenuValue = (value) => {
      setCurrentSelection(value);
      shouldUpdate.current = true;
    };

    let formationObject = parseFormation(formation);
    let returnList = [];
    let currentIndex = 0;

    const sortedSelection = sortPlayersBasedOnPositions(currentSelection);

    formationObject?.groups.forEach((item) => {
      let itemsToDisplay = [];
      for (let index = 0; index < item.number_of_players; index++) {
        const playerDuplicated = isPlayerDuplicateInPlayers(
          currentSelection[currentIndex],
          currentAllPlayerSelectionList
        );

        const playerChanged = isPlayerChangedFromInitialSelection(
          currentSelection,
          initialPlayerSelectionList,
          currentIndex
        );

        itemsToDisplay.push(
          <SelectTeamPlayerMenuWithPosition
            emptyElement={true}
            // disabled={statusCompleted || pmStatusCompleted}
            playersList={allPlayersToSelect}
            currentSelection={currentSelection}
            setCurrentSelection={changeMenuValue}
            currentPlayerIndex={currentIndex}
            type={item.type}
            isDuplicated={playerDuplicated}
            playerChanged={playerChanged}
          />
        );
        currentIndex++;
      }
      returnList.push(<LineUpRow>{itemsToDisplay}</LineUpRow>);
    });

    return returnList;
  };

  const BuildBench = (
    formation,
    playersList,
    currentSelection,
    setCurrentSelection,
    initialPlayerSelectionList,
    currentAllPlayerSelectionList,
    allPlayersToSelect,
    pmStatusCompleted
  ) => {
    if (!playersList) {
      return;
    }
    if (!formation) {
      return;
    }


    const changeMenuValue = (value) => {
      setCurrentSelection(value);
      shouldUpdate.current = true;
    };

    let itemsToDisplay = [];
    let currentIndex = 0;

    for (let index = 0; index < currentSelection.length; index++) {
      const playerDuplicated = isPlayerDuplicateInPlayers(
        currentSelection[currentIndex],
        currentAllPlayerSelectionList
      );

      const playerChanged = isPlayerChangedFromInitialSelection(
        currentSelection,
        initialPlayerSelectionList,
        currentIndex
      );

      const showChanged = shouldShowDuplicated(
        currentSelection,
        playerChanged,
        currentIndex
      );

      itemsToDisplay.push(
        <SelectTeamPlayerMenu
          emptyElement={true}
          // disabled={statusCompleted || pmStatusCompleted}
          playersList={allPlayersToSelect}
          currentSelection={currentSelection}
          setCurrentSelection={changeMenuValue}
          currentPlayerIndex={currentIndex}
          type={BENCH}
          isDuplicated={playerDuplicated}
          playerChanged={false}
        />
      );
      currentIndex++;
    }

    return itemsToDisplay;
  };

  const handleOldSelectionsLineUp = (
    newSquad,
    oldSquad,
    initialSelection,
    setInitialSelection,
    initialBench,
    setInitialBench
  ) => {
    // Already set
    if (initialSelection.count > 0 && initialBench.count > 0) {
      return;
    }

    if (!oldSquad) {
      // Select current values
      let selected = [];
      let bench = [];

      if (newSquad) {
        selected = newSquad.selections.filter((element) => element.xi === true);
        bench = newSquad.selections.filter(
          (element) => element.status === BENCH
        );
      }

      setInitialSelection(selected);
      setInitialBench(bench);
      return;
    }

    let selected = oldSquad.selections.filter((element) => element.xi == true);
    let bench = oldSquad.selections.filter(
      (element) => element.status === BENCH
    );

    setInitialSelection(selected);
    setInitialBench(bench);
  };


  const validChangesForUpdate = () => {
    if (!homeCurrentSelection || !homeInitialData.selected) {
      return
    }

    let equalSelectionHome = _.isEqual(homeCurrentSelection, homeInitialData.selected);
    let equalBench = _.isEqual(homeCurrentBench, homeInitialData.bench);


    let equalSelectionAway = _.isEqual(awayCurrentSelection, awayInitialData.selected);
    let equalBenchAwayHome = _.isEqual(awayCurrentBench, awayInitialData.bench);


    if (equalSelectionHome && equalBench && equalSelectionAway && equalBenchAwayHome) {
      setUpdateSquadAfterCompleteBtnDisabled(true);
    } else {
      setUpdateSquadAfterCompleteBtnDisabled(false);
    }

  }



  const handleSetLineUp = (
    squad,
    setAllPlayers,
    setCurrentLineUp,
    setCurrentBench,
    setAllPlayersToSelect,
    setInitialData
  ) => {
    if (!squad) {
      setCurrentLineUp([]);
      setCurrentBench([]);
      setAllPlayers([]);
    } else {
      var selected = squad.selections.filter(
        (element) =>
          element.selected_for_the_game == true && element.status == AVAILABLE
      );

      if (selected.length == 0) {
        selected = squad.selections.filter((element) => element.xi == true);
      }

      let bench = squad.selections.filter((element) => element.status == BENCH);
      //initialize position woth first player position
      bench = initializePositionWithFirstPosition(bench);

      if (bench.length < 12) {
        for (let index = bench.length; index < 12; index++) {
          bench.push({
            attack_rating: null,
            confirmed: null,
            defence_rating: null,
            fatigue: null,
            has_message: null,
            id: null,
            importance: null,
            injured: null,
            long_term_formation: null,
            match_rating: null,
            player: null,
            player_id: null,
            position: null,
            squad_id: null,
            squad_number: null,
            starting_chance: null,
            status: null,
            xi: null,
          });
        }
      }

      let allAvailable = selected.concat(bench);
      let allSquad = squad.selections;

      setInitialData({
        "selected": selected,
        "bench": bench
      })

      setCurrentLineUp(selected);
      setCurrentBench(bench);
      setAllPlayers(allAvailable);
      setAllPlayersToSelect(allSquad);


    }
  };

  const resetReadyFlags = (game) => {
    if (!game) {
      return;
    }

    if (game.game.fixture) {
      setHomeReady(game.game.home_lineup_confirmed);
      setAwayReady(game.game.away_lineup_confirmed);
      setFactorsSubmitted(game.game.factors_submitted);
    } else {
      setHomeReady(false);
      setAwayReady(false);
      setFactorsSubmitted(false);
    }
  };


  const checkReady = () => {
    if ((homeReady || awayReady) && factorsSubmitted) {
      setConfirmed(true);
    } else {
      setConfirmed(false);
    }
  };

  const checkDuplicatedPlayers = () => {
    const homeHasDuplicates = hasDuplicatePlayers(
      homeCurrentSelection.concat(homeCurrentBench)
    );
    const awayHasDuplicates = hasDuplicatePlayers(
      awayCurrentSelection.concat(awayCurrentBench)
    );

    if (homeHasDuplicates) {
      setHomeReady(false);
    }


    if (awayHasDuplicates) {
      setAwayReady(false);
    }

  };

  const createSelections = (XI, bench, allPlayersList) => {
    let firstSelection = [];
    let benchSelection = [];
    let unavailableSelection = [];

    XI.forEach((player) => {
      let obj = {
        id: player.id,
        status: AVAILABLE,
        position: player.position,
        xi: player.xi,
        selected_for_the_game: true,
      };
      firstSelection.push(obj);
    });

    bench.forEach((player) => {
      if (player?.id == null) {
        return;
      }
      let obj = {
        id: player.id,
        status: BENCH,
        position: player.position,
        xi: player.xi,
        selected_for_the_game: true,
      };
      benchSelection.push(obj);
    });

    const unavailablePlayers = allPlayersList.filter((element) => {
      const selectedElement = XI.find((item) => item.id === element.id);
      const benchElement = bench.find((item) => item.id === element.id);

      return !selectedElement && !benchElement;
    });

    unavailablePlayers.forEach((player) => {
      var status = player.status;
      // Was removed from the bench
      if (status === BENCH) {
        status = AVAILABLE;
      }

      let obj = {
        id: player.id,
        status: status,
        position: player.position,
        xi: player.xi,
        selected_for_the_game: false,
      };

      unavailableSelection.push(obj);
    });

    const valuesToReturn = [
      ...firstSelection,
      ...benchSelection,
      ...unavailableSelection,
    ];

    return valuesToReturn;
  };

  const handleUpdateSquads = () => {
    let selections_to_update_home = [];
    let selections_to_update_away = [];
    let selections_to_update = [];
    setIsLoading(true);

    if (homeReady) {
      const selections = createSelections(
        homeCurrentSelection,
        homeCurrentBench,
        homeAllPlayersToSelect
      );

      let obj = {
        squad_id: homeTeamSquad.id,
        formation: pickedFormation,
        predicted: homePredicted,
        selections: selections,
      };

      selections_to_update_home.push(obj);
    }

    if (awayReady) {
      const selections = createSelections(
        awayCurrentSelection,
        awayCurrentBench,
        awayAllPlayersToSelect
      );

      let obj = {
        squad_id: awayTeamSquad.id,
        formation: pickedFormationAway,
        predicted: awayPredicted,
        selections: selections,
      };
      selections_to_update_away.push(obj);
    }

    selections_to_update = [
      ...selections_to_update_home,
      ...selections_to_update_away,
    ];

    var dataObject = {
      fixture_id: selectedFixture.fixture.fixture_id,
      selections_to_update: selections_to_update,
    };

    if (currentCup) {
      dataObject.cup_id = currentCup.id;
    } else {
      dataObject.league_id = currentLeague.id;
    }

    update_squads(
      dataObject,
      function (response) {
        notifySucces("Team updated");
        setIsLoading(false);
        loadInitialData();
        handleGetGames();
      },
      function (error) {
        setIsLoading(false);
        notifyError("Something wrong");
      }
    );
  };

  const handleUpdateSquadPartial = (
    currentSelection,
    currentBench,
    allPlayersToSelect,
    teamSquad,
    pickedFormation,
    teamPredicted
  ) => {

    if (statusCompleted) {
      return
    }



    let selections_to_update = [];
    let selections;
    let obj;
    let validCurrentSelections = getValidSelections(currentSelection);

    if (validCurrentSelections.length < 11) {
      return
    }

    const hasDuplicatedPlayers = hasDuplicatePlayers(
      currentSelection.concat(currentBench)
    );

    if (hasDuplicatedPlayers) {
      return;
    }

    selections = createSelections(
      currentSelection,
      currentBench,
      allPlayersToSelect
    );
    obj = {
      squad_id: teamSquad?.id,
      formation: pickedFormation,
      predicted: teamPredicted,
      selections: selections,
    };

    selections_to_update.push(obj);

    let send_selections_to_update = [...selections_to_update];

    var dataObject = {
      fixture_id: selectedFixture.fixture.fixture_id,
      selections_to_update: send_selections_to_update,
    };

    if (currentCup) {
      dataObject.cup_id = currentCup.id;
    } else {
      dataObject.league_id = currentLeague.id;
    }

    update_squads_partial(
      dataObject,
      function (response) {
        setIsLoading(false);
      },
      function (error) {
        setIsLoading(false);
        notifyError("Something wrong");
      }
    );
  };

  const handleUpdateSquadsPmCompleted = () => {
    let selections_to_update_home = [];
    let selections_to_update_away = [];
    let selections_to_update = [];
    setIsLoading(true);

    // if (homeReady) {
    const selections = createSelections(
      homeCurrentSelection,
      homeCurrentBench,
      homeAllPlayersToSelect
    );

    let obj = {
      squad_id: homeTeamSquad.id,
      formation: pickedFormation,
      predicted: homePredicted,
      selections: selections,
    };

    selections_to_update_home.push(obj);
    // }

    // if (awayReady) {
    const selectionsAWay = createSelections(
      awayCurrentSelection,
      awayCurrentBench,
      awayAllPlayersToSelect
    );

    let objAway = {
      squad_id: awayTeamSquad.id,
      formation: pickedFormationAway,
      predicted: awayPredicted,
      selections: selectionsAWay,
    };
    selections_to_update_away.push(objAway);
    // }

    selections_to_update = [
      ...selections_to_update_home,
      ...selections_to_update_away,
    ];


    var dataObject = {
      fixture_id: selectedFixture.fixture.fixture_id,
      selections_to_update: selections_to_update,
    };

    if (currentCup) {
      dataObject.cup_id = currentCup.id;
    } else {
      dataObject.league_id = currentLeague.id;
    }

    update_squads_after_complete(
      dataObject,
      function (response) {
        notifySucces("Team updated");
        setIsLoading(false);
        // loadInitialData();
        handleGetGames();
      },
      function (error) {
        setIsLoading(false);
        notifyError("Something wrong");
      }
    );
  }

  const updatePredictedValues = () => {
    const homeDifferentPlayers = numberOfDifferentPlayersBetweenSelections(
      homeCurrentSelection,
      homeInitialSelection
    );
    const awayDifferentPlayers = numberOfDifferentPlayersBetweenSelections(
      awayCurrentSelection,
      awayInitialSelection
    );

    setHomePredicted(11 - homeDifferentPlayers);
    setAwayPredicted(11 - awayDifferentPlayers);
  };

  // This adds values from the bench to the initial list of selectable values
  const updateValuesAvailable = () => {
    const newHomeList = buildUpdatedSelectionWithNewBench(
      homePlayersList,
      homeCurrentBench
    );

    setHomePlayersList(newHomeList);

    const newAwayList = buildUpdatedSelectionWithNewBench(
      awayPlayersList,
      awayCurrentBench
    );

    setAwayPlayersList(newAwayList);
  };

  const handleSelectPickedFormationHome = (value) => {
    setPickedFormation(value);
    shouldUpdate.current = true;
  };

  const handleSelectPickedFormationAway = (value) => {
    setPickedFormationAway(value);
    shouldUpdate.current = true;
  };

  const filterNotNullItems = (playerList) => {
    let filtered = playerList.filter((item) => item.id != null);
    return filtered;
  };

  const calculateStartingChances = () => {
    if (!homeTeamSquad) {
      return;
    }
    if (!awayTeamSquad) {
      return;
    }

    const homeSelectionScore = calculateStartingChanceFromPlayers(
      homeTeamSquad.selections
    );
    const awaySelectionScore = calculateStartingChanceFromPlayers(
      awayTeamSquad.selections
    );

    setHomeSelectionScore(homeSelectionScore);
    setAwaySelectionScore(awaySelectionScore);
  };

  // const closeEditPlayersWindow = () => {
  //   if (refreshOnClose.current == true) {
  //     setShowEditPlayersModal(false);
  //     loadInitialData();
  //   }
  //   setShowEditPlayersModal(false);
  // };

  const reloadOnCloseEditPlayersView = () => {
    if (refreshOnClose.current == true) {
      loadInitialData();
    }
  }

  const displayEditPlayersWindow = (team) => {
    setThisteam(team);
    setShowEditPlayersModal(true);
  };

  useEffect(() => {
    reloadOnCloseEditPlayersView();
  }, [refreshOnClose.current == true]);

  useEffect(() => {
    updateValuesAvailable();
  }, [awayCurrentBench, homeCurrentBench]);

  useEffect(() => {
    updatePredictedValues();
    checkReady();
    checkDuplicatedPlayers();
    calculateStartingChances();
    validChangesForUpdate();
  }, [
    awayCurrentSelection,
    homeCurrentSelection,
    awayCurrentBench,
    homeCurrentBench,
  ]);

  useEffect(() => {
    loadInitialData();
  }, [selectedFixture]);

  useEffect(() => {
    setTeams(currentGame);
  }, [currentGame]);

  useEffect(() => {
    handleSetLineUp(
      homeTeamSquad,
      setHomePlayersList,
      setHomeCurrentSelection,
      setHomeCurrentBench,
      setHomeAllPlayersToSelect,
      setHomeInitialData
    );

    handleSetLineUp(
      awayTeamSquad,
      setAwayPlayersList,
      setAwayCurrentSelection,
      setAwayCurrentBench,
      setAwayAllPlayersToSelect,
      setAwayInitialData
    );

    handleOldSelectionsLineUp(
      homeTeamSquad,
      homeOldTeamSquad,
      homeInitialSelection,
      setHomeInitialSelection,
      homeInitialBench,
      setHomeInitialBench
    );

    handleOldSelectionsLineUp(
      awayTeamSquad,
      awayOldTeamSquad,
      awayInitialSelection,
      setAwayInitialSelection,
      awayInitialBench,
      setAwayInitialBench
    );

    resetReadyFlags(currentGame);
  }, [
    currentGame,
    homeTeamSquad,
    awayTeamSquad,
    homeOldTeamSquad,
    awayOldTeamSquad,
  ]);

  useEffect(() => {
    checkReady();
    checkDuplicatedPlayers();
  }, [homeReady, awayReady]);

  useEffect(() => {
    let homeHaveDuplicatedPlayers = hasDuplicatePlayers(
      homeCurrentSelection.concat(homeCurrentBench)
    );

    if (!homeTeamSquad) {
      return;
    }
    if (homeHaveDuplicatedPlayers) {
      return;
    }

    setHomeValidSelections(getValidSelections(homeCurrentSelection))

    if (shouldUpdate.current == true) {
      handleUpdateSquadPartial(
        homeCurrentSelection,
        homeCurrentBench,
        homeAllPlayersToSelect,
        homeTeamSquad,
        pickedFormation,
        homePredicted
      );
      shouldUpdate.current = false;
    }
  }, [homeCurrentBench, homeCurrentSelection, pickedFormation]);

  useEffect(() => {
    let awayHaveDuplicatedPlayers = hasDuplicatePlayers(
      awayCurrentSelection.concat(awayCurrentBench)
    );

    if (!awayTeamSquad) {
      return;
    }
    if (awayHaveDuplicatedPlayers) {
      return;
    }
    setAwayValidSelections(getValidSelections(awayCurrentSelection))

    if (shouldUpdate.current == true) {
      handleUpdateSquadPartial(
        awayCurrentSelection,
        awayCurrentBench,
        awayAllPlayersToSelect,
        awayTeamSquad,
        pickedFormationAway,
        awayPredicted
      );
      shouldUpdate.current = false;
    }
  }, [awayCurrentBench, awayCurrentSelection, pickedFormationAway]);


  return (
    <Container>
      {/* {showEditPlayersModal && (
        <EditPlayersView
          teamID={thisTeam}
          closeWindow={closeEditPlayersWindow}
          leagueData={currentLeague}
          cupData={currentCup}
          refreshOnClose={refreshOnClose}
        />
      )} */}

      {!isLoading ? (
        <>
          {shouldDisplayWarning ? (
            <ProjectLineUpPopupWarning />
          ) : (
            <Content>
              <FullScreenSpinner loading={isLoading} />
              <Field>
                <BenchHome>
                  {filterNotNullItems(homeCurrentBench)?.map((item, index) => (
                    <BenchPlayer key={index} player={item} away={false} />
                  ))}
                </BenchHome>
                <MainField>
                  <ImgBg>
                    <img src={fieldimg}></img>
                  </ImgBg>

                  <LeftFieldSide>
                    {BuildLineUpPlayer(
                      pickedFormation,
                      homeCurrentSelection,
                      false
                    )}
                  </LeftFieldSide>

                  <RightFiledSide>
                    {BuildLineUpPlayer(
                      pickedFormationAway,
                      awayCurrentSelection,
                      true
                    )}
                  </RightFiledSide>
                </MainField>
                <BenchAway>
                  {filterNotNullItems(awayCurrentBench)?.map((item, index) => (
                    <BenchPlayer key={index} player={item} away={true} />
                  ))}
                </BenchAway>
              </Field>
              <BottomTeams>
                <Teams>
                  <HomeTeam>
                    <Row>
                      <Title>
                        <TeamLogoWrapper>
                          <img src={homeTeam?.logo_url} alt="logo" />
                        </TeamLogoWrapper>
                        <h1 onClick={() => navigateToSquadManager(homeTeam.id)}>
                          {homeTeam?.name}
                        </h1>
                        <span>home</span>
                      </Title>

                      <NavigateToEditPlayersButton
                        onClick={() => displayEditPlayersWindow(homeTeam)}
                      >
                        <EditpencilIcon />
                        <p> Edit Players</p>
                      </NavigateToEditPlayersButton>
                    </Row>

                    <Row>
                      <G1>
                        <SingleOptionMenuSmall
                          disabled={statusCompleted || homePmCompleted}
                          width={"90px"}
                          valuesList={TeamFormation}
                          selectedValue={pickedFormation}
                          setSelectedValue={handleSelectPickedFormationHome}
                        />
                        <Predicted>
                          <p>PREDICTED ({homePredicted}/11) </p>
                        </Predicted>
                      </G1>
                      {!statusCompleted && !homePmCompleted && (
                        <Check>
                          <p>Ready to send</p>
                          <input
                            type={"checkbox"}
                            checked={homeReady}
                            value={homeReady}
                            onChange={(e) =>
                              setHomeReady(e.currentTarget.checked)
                            }
                          ></input>
                        </Check>
                      )}
                    </Row>

                    <LineUpList>
                      {BuildLineUp(
                        pickedFormation,
                        homePlayersList,
                        homeCurrentSelection,
                        setHomeCurrentSelection,
                        homeInitialSelection,
                        homeCurrentSelection.concat(homeCurrentBench),
                        homeAllPlayersToSelect,
                        homePmCompleted
                      )}
                    </LineUpList>
                  </HomeTeam>

                  <Description>
                    <SendButton
                      hid={statusCompleted}
                      disabled={!confirmed || statusCompleted || homeValidSelections.length < 11 || awayValidSelections.length < 11}
                      onClick={() => handleUpdateSquads()}
                    >
                      Send Confirmed
                    </SendButton>

                    <SendButton
                      hid={!statusCompleted}
                      disabled={updateSquadAfterCompleteBtnDisabled || homeTeamHaveDuplicatedPlayers || awayTeamHaveDuplicatedPlayers || homeValidSelections.length < 11 || awayValidSelections.length < 11}
                      onClick={() => handleUpdateSquadsPmCompleted()}
                    >
                      Update
                    </SendButton>

                    <LineupBlock>
                      <h1>lineup</h1>
                    </LineupBlock>
                  </Description>

                  <AwayTeam>
                    <Row style={{ flexDirection: "row-reverse" }}>
                      <Title>
                        <span className="away">away</span>
                        <h1 onClick={() => navigateToSquadManager(awayTeam.id)}>
                          {awayTeam?.name}
                        </h1>

                        <TeamLogoWrapper>
                          <img src={awayTeam?.logo_url} alt="logo" />
                        </TeamLogoWrapper>
                      </Title>
                      <NavigateToEditPlayersButton
                        onClick={() => displayEditPlayersWindow(awayTeam)}
                      >
                        <EditpencilIcon />
                        <p> Edit Players</p>
                      </NavigateToEditPlayersButton>
                    </Row>

                    <Row>
                      {!statusCompleted && !awayPmCompleted && (
                        <Check>
                          <p>Ready to send</p>
                          <input
                            type={"checkbox"}
                            checked={awayReady}
                            value={awayReady}
                            onChange={(e) =>
                              setAwayReady(e.currentTarget.checked)
                            }
                          ></input>
                        </Check>
                      )}

                      <G1 style={{ marginLeft: "auto" }}>
                        <Predicted>
                          <p>PREDICTED ({awayPredicted}/11) </p>
                        </Predicted>
                        <SingleOptionMenuSmall
                          disabled={statusCompleted || awayPmCompleted}
                          width={"90px"}
                          valuesList={TeamFormation}
                          selectedValue={pickedFormationAway}
                          setSelectedValue={handleSelectPickedFormationAway}
                        />
                      </G1>
                    </Row>
                    <LineUpList>
                      {BuildLineUp(
                        pickedFormationAway,
                        awayPlayersList,
                        awayCurrentSelection,
                        setAwayCurrentSelection,
                        awayInitialSelection,
                        awayCurrentSelection.concat(awayCurrentBench),
                        awayAllPlayersToSelect,
                        awayPmCompleted
                      )}
                    </LineUpList>
                  </AwayTeam>
                </Teams>

                <Bench>
                  <HomeTeamBench>
                    {BuildBench(
                      pickedFormation,
                      homePlayersList,
                      homeCurrentBench,
                      setHomeCurrentBench,
                      homeInitialBench,
                      homeCurrentSelection.concat(homeCurrentBench),
                      homeAllPlayersToSelect,
                      homePmCompleted
                    )}
                  </HomeTeamBench>
                  <BenchBlock>
                    <h1>bench</h1>
                  </BenchBlock>
                  <AwayTeamBench>
                    {BuildBench(
                      pickedFormationAway,
                      awayPlayersList,
                      awayCurrentBench,
                      setAwayCurrentBench,
                      awayInitialBench,
                      awayCurrentSelection.concat(awayCurrentBench),
                      awayAllPlayersToSelect,
                      awayPmCompleted
                    )}
                  </AwayTeamBench>
                </Bench>
              </BottomTeams>
            </Content>
          )}
        </>
      ) : (
        ""
      )}
    </Container>
  );
};

export default ProjectedLineUp;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 5px;

`;
const Bench = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  column-gap: 20px;
`;
const Field = styled.div`
  display: flex;
  justify-content: center;
`;
const Teams = styled.div`
  display: flex;
  column-gap: 20px;

  padding: 0;
  justify-content: center;
`;
const HomeTeam = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
`;
const AwayTeam = styled(HomeTeam)`
  align-items: end;
`;
const G1 = styled.div`
  display: flex;
  column-gap: 10px;
`;
const Predicted = styled.div`
      background: #ecf0ff;
      border: 1px solid #9db1ff;
      align-items: center;
      display: flex;
      width:115px;
      height:20px;
      padding: 0px 10px;
      p {
        font - family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 150%;
      /* identical to box height, or 15px */
      display: flex;
      align-items: center;
      color: #5177ff;
  }
      `;
const Title = styled.div`
      display: flex;
      column-gap: 6px;
      align-items: center;
      display: flex;
      
      h1 {
          max-width: 200px;
        overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
        cursor:pointer;
        font - family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 23px;
      text-transform: capitalize;
      color:${({ theme }) => theme.matchPreview.textColor};
  }
      span {
        padding: 2px 7px;
      background: #efe8ff;
      border: 1px solid #9868fe;
      border-radius: 2px;
      font-style: normal;
      font-weight: 700;
      font-size: 8px;
      line-height: 10px;
      /* identical to box height */
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #9868fe;
  }
      .away {
        background: #b9eeff;
      border: 1px solid #2097bc;
      color: #2097bc;
  }
      `;
const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LineUpRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  border-bottom: 2px solid
    ${({ theme }) => theme.projectedLineup.lineUpMenuBorderColor};
  margin-bottom: -2px;
  :last-child {
    border: none;
  }
`;
const Check = styled.div`
      display: flex;
      column-gap: 5px;

align-items:center;
      p {
        font - family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      color:${({ theme }) => theme.matchPreview.textColor}
  }
      `;
const Description = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  min-width: 95px;
`;
const SendButton = styled.button`
  background: #006fff;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  border-radius: 4px;
  padding: 3px 10px;
  text-align: center;
  color: #ffffff;
  border: 1px solid #006fff;
  display: ${(props) => (props.hid ? "none" : "block")};
  :disabled {
    opacity: ${(props) => (props.hid ? "0" : "0.5")};
    cursor: inherit;
  }
  cursor: pointer;
`;
const LineupBlock = styled.div`
  background: ${({ theme }) => theme.projectedLineup.lineupBlock};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 285px;
  h1 {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #6b78ad;
  }
`;
const BenchBlock = styled.div`
  h1 {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #6b78ad;
  }
  background: ${({ theme }) => theme.projectedLineup.lineupBlock};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 25px;
  min-width: 95px;
`;
const BenchHome = styled.div`
  background: ${({ theme }) => theme.projectedLineup.benchBg}
  border: 2px solid ${({ theme }) => theme.projectedLineup.benchBorder};
  box-shadow: 0px 0px 20px rgba(23, 30, 47, 0.1);
  min-width: 108px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
`;
const BenchAway = styled(BenchHome)``;
const MainField = styled.div`
  position: relative;
  display: flex;
  column-gap: 5px;
  width: 100%;
  height: 100%;
`;

const LeftFieldSide = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
`;

const RightFiledSide = styled(LeftFieldSide)`
  flex-direction: row-reverse;
`;

const Line = styled.div`
  min-height: 400px;
  height: 90%;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 30px;
`;

const LineUpList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 2px;
`;

const HomeTeamBench = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  row-gap: 2px;
`;
const AwayTeamBench = styled(HomeTeamBench)``;

const TeamLogoWrapper = styled.div`
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 0.5px solid #eef2fd;
  border-radius: 2px;
  img {
    height: 15px;
    width: 15px;
  }
`;
const ImgBg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
`;

const PredictionRow = styled.div`
  display: flex;
  column-gap: 10px;
`;
const NavigateToEditPlayersButton = styled.button`
  display: flex;
  cursor: pointer;
  align-items: center;

  column-gap: 5px;
  max-height: 16px;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.projectedLineup.editButtonColor};
  background: ${({ theme }) => theme.matchPreview.selectMenuBg};
  border-radius: 2px;
  padding: 8px;

  p {
    display: flex;
    align-items: center;
    height: 100%;
    font-family: Roboto;
    font-size: 8px;
    font-weight: 500;
    line-height: 8px;
    color: ${({ theme }) => theme.projectedLineup.editButtonColor};
    margin: 0;
    padding: 0;
  }

  svg {
    height: 10px;
    color: ${({ theme }) => theme.projectedLineup.editButtonColor};
    padding-bottom: 1px;
  }
`;
const BottomTeams = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
`;
