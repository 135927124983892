import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import styled from "styled-components";
import {
  AtkDefValues,
  MatchImp,
  WinLoseValue,
} from "../../helper/Values/TeamFormation";
import AtkDefMenu from "./Menu/AtkDefMenu";

import AttendanceModal from "./Menu/AttendanceModal";

const HomeTeam = ({
  team,
  stats,
  handleChangeHomeTeamStats,
  readyToSubmitHome,
  setReadyToSubmitHome,
  statusCompleted,
  navigateToSquadManager,
  shouldUpdate,
  selectedFixture,
  shouldRefreshHomeStats
}) => {
  const [atkEdge, setAtkEdge] = useState();
  const [defEdge, setDefEgde] = useState();
  const [matchImp, setMatchImp] = useState();
  const [mustWin, setMustWin] = useState();
  const [mustNoLoose, setMustNoLoose] = useState();

  const [predictedAttendance, setPredictedAttendance] = useState({
    floatValue: null,
    formattedValue: "",
    value: "",
  });
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);

  const setInitialValues = (stats) => {
    if (!stats) {
      setAtkEdge(null);
      setDefEgde(null);
      setMatchImp(null);
      setMustWin(null);
      setMustNoLoose(null);
      setPredictedAttendance({
        floatValue: null,
        formattedValue: "",
        value: "",
      });
      setReadyToSubmitHome(false);
    } else {
      setAtkEdge(stats.attack_edge);
      setDefEgde(stats.defence_edge);
      setMatchImp(stats.match_importance);
      setMustWin(stats.must_win);
      setMustNoLoose(stats.must_not_lose);
      setReadyToSubmitHome(stats.ready || false);

      if (stats.predicted_attendance == null) {
        setPredictedAttendance({
          floatValue: null,
          formattedValue: "",
          value: "",
        });
      } else {
        setPredictedAttendance({
          floatValue: stats.predicted_attendance,
          formattedValue: stats.predicted_attendance,
          value: stats.predicted_attendance,
        });
      }
    }
  };

  const handlePredictedAttendanceValue = (val) => {
    const value = val;
    setPredictedAttendance(value);
    shouldUpdate.current = true;
  };

  const checkfIfAllFieldsAreFilled = () => {
    if (
      atkEdge != null &&
      defEdge != null &&
      matchImp != null &&
      mustWin != null &&
      mustNoLoose != null &&
      predictedAttendance.floatValue != null
    ) {
      setAllFieldsFilled(true);
    } else {
      setAllFieldsFilled(false);
    }
  };

  const changeMenuValue = (setFunction, value) => {
    shouldUpdate.current = true;
    setFunction(value);
  };

  useEffect(() => {
    if (!team) {
      return;
    }
    handleChangeHomeTeamStats(team.id, "attack_edge", atkEdge);
  }, [atkEdge]);

  useEffect(() => {
    if (!team) {
      return;
    }
    handleChangeHomeTeamStats(team.id, "defence_edge", defEdge);
  }, [defEdge]);

  useEffect(() => {
    if (!team) {
      return;
    }
    handleChangeHomeTeamStats(team.id, "match_importance", matchImp);
  }, [matchImp]);

  useEffect(() => {
    if (!team) {
      return;
    }
    handleChangeHomeTeamStats(team.id, "must_win", mustWin);
  }, [mustWin]);

  useEffect(() => {
    if (!team) {
      return;
    }
    handleChangeHomeTeamStats(team.id, "must_not_lose", mustNoLoose);
  }, [mustNoLoose]);

  useEffect(() => {
    if (!team) {
      return;
    }
    handleChangeHomeTeamStats(team.id, "ready", readyToSubmitHome);
  }, [readyToSubmitHome]);

  useEffect(() => {
    if (!team) {
      return;
    }
    const timer = setTimeout(() => {
      handleChangeHomeTeamStats(
        team.id,
        "predicted_attendance",
        predictedAttendance.floatValue
      );
    }, 500);

    return () => clearTimeout(timer);
  }, [predictedAttendance]);

  useEffect(() => {
    console.log(stats, 'home')
    setInitialValues(stats);
  }, [team]);


  useEffect(() => {
    if (shouldRefreshHomeStats.current = true) {
      setInitialValues(null);
      shouldRefreshHomeStats.current = false
    }
  }, [shouldRefreshHomeStats.current])

  useEffect(() => {
    checkfIfAllFieldsAreFilled();
  }, [atkEdge, defEdge, matchImp, mustNoLoose, mustWin, predictedAttendance]);

  return (
    <Container>
      <Top>
        <Team onClick={() => navigateToSquadManager(team.id)}>
          <h1>{team?.name}</h1>
          <span> home</span>
        </Team>

        {!statusCompleted && allFieldsFilled && (
          <Check
            onClick={(e) =>
              changeMenuValue(setReadyToSubmitHome, !readyToSubmitHome)
            }
          >
            <input
              type={"checkbox"}
              value={readyToSubmitHome}
              checked={readyToSubmitHome}
              readOnly
            ></input>
            <p>Ready to be submitted</p>
          </Check>
        )}
      </Top>
      <Row>
        <Group>
          <label>predicted attendance</label>
          <PredictedattendanceWrapper>
            <NumericFormat
              disabled={statusCompleted || readyToSubmitHome}
              value={predictedAttendance.formattedValue}
              onValueChange={(values) => handlePredictedAttendanceValue(values)}
              thousandSeparator=","
            />
            <div style={{ position: "absolute", right: "15px" }}>
              <AttendanceModal
                team={team}
                teamId={team?.id}
                fixtureId={selectedFixture?.fixture_id}
              />
            </div>

          </PredictedattendanceWrapper>
        </Group>
        <TeamLogoWrapper>
          <img src={team?.logo_url} />
        </TeamLogoWrapper>
      </Row>
      <Row>
        <Group>
          <label>attack edge</label>
          <AtkDefMenu
            dataList={AtkDefValues}
            value={atkEdge}
            setValue={setAtkEdge}
            changeMenuValue={changeMenuValue}
            disabled={statusCompleted || readyToSubmitHome}
          />
        </Group>
        <Group>
          <label>defence edge</label>
          <AtkDefMenu
            dataList={AtkDefValues}
            value={defEdge}
            setValue={setDefEgde}
            changeMenuValue={changeMenuValue}
            disabled={statusCompleted || readyToSubmitHome}
          />
        </Group>
      </Row>
      <Row>
        <Group>
          <label>match imp </label>
          <AtkDefMenu
            dataList={MatchImp}
            value={matchImp}
            setValue={setMatchImp}
            changeMenuValue={changeMenuValue}
            disabled={statusCompleted || readyToSubmitHome}
          />
        </Group>
        <Group>
          <label>must win</label>
          <AtkDefMenu
            dataList={WinLoseValue}
            value={mustWin}
            setValue={setMustWin}
            changeMenuValue={changeMenuValue}
            disabled={statusCompleted || readyToSubmitHome}
          />
        </Group>
        <Group>
          <label>must not lose</label>
          <AtkDefMenu
            dataList={WinLoseValue}
            value={mustNoLoose}
            setValue={setMustNoLoose}
            changeMenuValue={changeMenuValue}
            disabled={statusCompleted || readyToSubmitHome}
          />
        </Group>
      </Row>
    </Container>
  );
};

export default HomeTeam;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  max-width:420px;
  min-width:420px;
  position:relative;

`;
const Row = styled.div`
  display: flex;
  column-gap: 40px;
`;
const Group = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    letter-spacing: 0.03em;
    color: ${({ theme }) => theme.matchPreview.textColor};
    text-transform: uppercase;
  }
  input {
    background: ${({ theme }) => theme.matchPreview.matchFactorsReadOnlyInputs};
    border: 1px solid #bac3e8;
    border-radius: 6px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: ${({ theme }) => theme.matchPreview.textColor};
    padding: 12px 16px;
  }
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;
const Check = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
min-width:125px;
max-width:125px;
  p {
    font-size: 10px;
     color:${({ theme }) => theme.matchPreview.textColor}
  }
`;
const Team = styled.div`
  align-items: center;
  display: flex;
  column-gap: 10px;
  cursor: pointer;
  justify-content:space-between;
    overflow: hidden;
  span {
    padding: 2px 7px;
    background: #efe8ff;
    border: 1px solid #9868fe;
    border-radius: 2px;
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
    /* identical to box height */

    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #9868fe;
  }
  
  h1 {

    width:100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.matchPreview.textColor};
  }
  
`;
const TeamLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 2px solid #eef2fd;
  border-radius: 6px;
  height: 64px;
  width: 64px;
  img {
    height: 46px;
    width: 46px;
  }
`;

const PredictedattendanceWrapper = styled.div`
display:flex;
position:relative;
align-items:center;
`
