import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownSvg } from "../../../assets/icons/downSimpleArrow.svg";


const FormationMenu = ({ valuesList, selectedValue, setSelectedValue, width }) => {
    const ref = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuOpen = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        } else {
            setIsMenuOpen(true);
        }
    };

    const handleCheck = (item) => {
        setSelectedValue(item);
        handleMenuOpen();
    };

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    return (
        <Container ref={ref} width={width}>
            <DropDownMenu
                onClick={() => handleMenuOpen()}
                isMenuOpen={isMenuOpen}
                width={width}
            >
                {selectedValue != undefined ? <p>{selectedValue}</p> : <p>-</p>}
                {isMenuOpen ? <DownSvg style={{ rotate: "180deg" }} /> : <DownSvg />}
            </DropDownMenu>
            {isMenuOpen && (
                <DropDownList>
                    {valuesList.map((item, index) => (
                        <DropDownMenuItem
                            key={index}
                            onClick={() => handleCheck(item.value)}
                            className={item === selectedValue ? "active" : "nonActive"}
                        >
                            <input
                                key={index}
                                readOnly
                                onChange={() => handleCheck(item.value)}
                                className="check"
                                type="radio"
                                checked={selectedValue === item.value}
                                value={item.value}
                            ></input>
                            <p>{item.label}</p>
                        </DropDownMenuItem>
                    ))}
                </DropDownList>
            )}
        </Container>
    );
};

export default FormationMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width != null ? props.width : "90px")};
  position: relative;

`;
const DropDownMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.matchPreview.selectMenuBg};
  padding: 2px 5px;
  border: 1px solid #cbd7f5;
  border-radius: 2px;
  border-radius: ${(props) => (props.isMenuOpen ? "4px 4px 0 0" : "")};
  p {
    color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 11px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2em;
  }
  svg{
    color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
      }
`;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top: 16px;
  border-top: 2px solid;
  border-color: #3c4a5c;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 4px 4px;
  max-height: 400px;
  width: 100%;
  overflow-x: hidden;
  color: black;
`;
const DropDownMenuItem = styled.li`
  cursor: pointer;
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;
  padding: 2px 0px;
  .check {
    height: 10px;
  }
  background-color: ${({ theme }) => theme.matchPreview.selectMenuBg};
  align-items: center;
  display: flex;
  flex-direction: row;
  column-gap:3px;
  p {
    font-weight: 700;
    font-size: 8px;
    line-height: 8px;
    color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
  }
`;
