import { useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../Redux/user/user";


const useGetRole = () => {

	const user = useSelector(selectUser);

	const [role, setRole] = useState(user.roles[0]);

	return [role, setRole];
};
export default useGetRole;