
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownSvg } from "../../../assets/icons/DropDownMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/DropDownMenuIcons/up.svg";
import { ReactComponent as PlusSvg } from "../../../assets/icons/check/plus.svg";
import { ReactComponent as CheckSvg } from "../../../assets/icons/check/check.svg";



const DropDownMenuSeasonCup = ({
  label,
  listItems,
  selectedItems,
  setSelectedItems,
  disabled,
  searchTerm
}) => {

  const filterList = (list, term) => {
    if (term == "" || !term) {
      return list
    } else {
      let filtered = list.filter((item) => item.name.toLowerCase().includes(term.toLowerCase()))
      return filtered
    }


  }

  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleCheck = (event, item) => {
    let newList = selectedItems

    if (event.target.checked) {
      newList = [...newList, item]
    } else {
      newList = newList.filter((element) => element.id != item.id)
    }

    setSelectedItems(newList)

  }

  const handleMenuOpen = () => {
    if (disabled) {
      return;
    }
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  useEffect(() => {
    setIsMenuOpen(false)
  }, [disabled])

  return (
    <Container ref={ref}>
      <DropDownMenu onClick={() => handleMenuOpen()} disabled={disabled}>
        <p>{label}</p>
        {isMenuOpen ? <UpSvg /> : <DownSvg />}
      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>
          {filterList(listItems, searchTerm)?.map((item, index) => (
            <DropdownListItem key={index} draggable>
              <p>{item.name}</p>
              <CheckContainer>
                <label className="checkContainer">
                  <input
                    key={index}
                    checked={selectedItems.some(element => element.id === item.id)}
                    value={item}
                    onChange={(e) => handleCheck(e, item)}
                    type="checkbox"
                  ></input>
                  {selectedItems.some(element => element.id === item.id) ? <CheckSvg /> : <PlusSvg />}
                  <span className="checkmark"></span>
                </label>
              </CheckContainer>
            </DropdownListItem>
          ))}

        </DropDownList>
      )
      }
    </Container >
  );
};

export default DropDownMenuSeasonCup;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  overflow:hidden;
`;

const DropDownMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f9fb;
  padding:10px;
  border-radius: 8px;
  width:100%;
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    p {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #6a7584;
  }

  svg {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }
`;

const DropDownList = styled.ul`
width:100%;
height:100%;
display:flex;
flex-direction:column;
overflow:auto;
max-height:300px;
`;

const DropdownListItem = styled.li`
  padding: 10px 15px;
  list-style-type: none;
  display: flex;
  align-items: center;
  width:100%;
  position:relative;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
    }

 
    align-items: center;
    display: flex;
    column-gap: 10px;
    color: rgba(0, 18, 41, 1);




`;

const CheckContainer = styled.div`
margin-left:auto;
min-width:20px;
   svg {
      height: 14px;
      width: 18px;
      border-radius: 4px;
      fill:${({ theme }) => theme.groupPlayersModal.checkFill};
    }
  .checkContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .checkContainer input {
    display: none;
  }
  .checkContainer input:checked ~ svg {
    background: #ebf3ff;
    fill:var(--primaryBlue);
    border: 1px solid var(--primaryBlue);
  }
  .checkContainer svg {
    padding: 2px 3px;
    border: 1px solid ${({ theme }) => theme.groupPlayersModal.checkFill};
    border-radius: 2px;
    background: transparent;

    fill:${({ theme }) => theme.groupPlayersModal.checkFill};
  }

`