import { createContext, useCallback, useContext } from "react";
import { getGames, getGamesRequest } from "../api/matchPreview/getGames";
import { getPitchCondition } from "../api/matchPreview/getPitchCondition";
import { getSquadsForGame } from "../api/matchPreview/getSquadsForGame";
import { getStadiumWeather } from "../api/matchPreview/getStadiumWeather";
import { updateGameFactors } from "../api/matchPreview/updateGameFactors";
import { updateSquadsForGame } from "../api/matchPreview/updateSquadsForGame";
import { setNotesToGame } from "../api/matchPreview/setNotesToGame";
import { setGamePosponed } from "../api/matchPreview/setGamePostponed";
import { setGameCancelled } from "../api/matchPreview/setGameCancelled";
import { updateGameFactorsPartial } from "../api/matchPreview/updateGameFactorsPartial";
import { updateSquadsForGamePartial } from "../api/matchPreview/updateSquadsForGamePartial";
import { setConfirmedAttendance } from "../api/matchPreview/setConfirmedAttendance";
import { getWyScoutId } from "../api/matchPreview/getWyScoutId";
import { updateSquadsForGamePmCompeltedAPI } from "../api/matchPreview/updateSquadsForGamePmCompelted";

export const MatchPreviewServiceContext = createContext({
  get_games: () => { },
  get_squads: () => { },
  get_wyScout_id: () => { },
  get_pitch_condition: () => { },
  get_stadium_weather: () => { },
  update_game_factors: () => { },
  update_game_factors_partial: () => { },
  update_squads: () => { },
  update_squads_partial: () => { },
  update_squads_after_complete: () => { },
  set_game_postponed: () => { },
  set_game_cancelled: () => { },
  set_notes_to_game: () => { },
  set_confirmed_attendance: () => { },
  cancel_get_game_request: () => { },
});

const MatchPreviewServiceProvider = ({ children }) => {
  const get_games = useCallback(
    async (data, callbackFunction, callbackError) => {
      try {
        await getGames(
          data,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const cancel_get_game_request = () => {
    getGamesRequest?.cancel();
  }

  const get_squads = useCallback(
    async (data, callbackFunction, callbackError) => {
      try {
        await getSquadsForGame(
          data,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_wyScout_id = useCallback(
    async (fixture_id, callbackFunction, callbackError) => {
      try {
        await getWyScoutId(
          fixture_id,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_pitch_condition = useCallback(
    async (stadium_id, callbackFunction, callbackError) => {
      try {
        await getPitchCondition(
          stadium_id,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_stadium_weather = useCallback(
    async (stadium_id, fixture_id, callbackFunction, callbackError) => {
      try {
        await getStadiumWeather(
          stadium_id,
          fixture_id,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const update_game_factors = useCallback(
    async (data, callbackFunction, callbackError) => {
      try {
        await updateGameFactors(
          data,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const update_game_factors_partial = useCallback(
    async (

      data,
      callbackFunction,
      callbackError
    ) => {
      try {
        await updateGameFactorsPartial(

          data,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const set_game_postponed = useCallback(
    async (data, callbackFunction, callbackError) => {
      try {
        await setGamePosponed(
          data,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const set_game_cancelled = useCallback(
    async (data, callbackFunction, callbackError) => {
      try {
        await setGameCancelled(
          data,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const update_squads = useCallback(
    async (data, callbackFunction, callbackError) => {
      try {
        await updateSquadsForGame(
          data,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const update_squads_after_complete = useCallback(
    async (data, callbackFunction, callbackError) => {
      try {
        await updateSquadsForGamePmCompeltedAPI(
          data,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const update_squads_partial = useCallback(
    async (data, callbackFunction, callbackError) => {
      try {
        await updateSquadsForGamePartial(
          data,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const set_confirmed_attendance = useCallback(
    async (
      data,
      callbackFunction,
      callbackError
    ) => {
      try {
        await setConfirmedAttendance(
          data,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const set_notes_to_game = useCallback(
    async (
      data,
      callbackFunction,
      callbackError
    ) => {
      try {
        await setNotesToGame(
          data,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );
  return (
    <MatchPreviewServiceContext.Provider
      value={{
        get_games,
        get_pitch_condition,
        get_squads,
        get_wyScout_id,
        get_stadium_weather,
        update_game_factors,
        update_game_factors_partial,
        update_squads,
        update_squads_partial,
        update_squads_after_complete,
        set_game_postponed,
        set_game_cancelled,
        set_notes_to_game,
        set_confirmed_attendance,
        cancel_get_game_request
      }}
    >
      {children}
    </MatchPreviewServiceContext.Provider>
  );
};
export const useMatchPreviewService = () =>
  useContext(MatchPreviewServiceContext);
export default MatchPreviewServiceProvider;
