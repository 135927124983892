import { POST } from "../APiHelper/API";
let source;
export const resetSquadAPI = (data, onSuccess, onError) => {
    // const data = {
    //     team_id: team_id,
    //     fixture_id: fixture_id,
    //     formation: formation,
    //     selections: selections
    // }
    source = POST(`/api/v1/squads/teams/reset_squad`, data, onSuccess, onError, true, source)
}