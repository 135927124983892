import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CopyButtonSvg } from "../../../assets/icons/BettingIcons/duplicate.svg";
import { ReactComponent as NoteButtonSvg } from "../../../assets/icons/BettingIcons/notePencil.svg";
import { ReactComponent as LowPriority } from "../../../assets/icons/BettingIcons/lowPriority.svg";
import { ReactComponent as MediumPriority } from "../../../assets/icons/BettingIcons/mediumPriority.svg";
import { ReactComponent as HighPriority } from "../../../assets/icons/BettingIcons/highPriority.svg";
import { ReactComponent as TopPriority } from "../../../assets/icons/BettingIcons/topPriority.svg";
import { checkFlag, getCountryCode } from "../../../helper/Generic/GetFlag";
import { useSelector } from "react-redux";
import { selectUser } from "../../../Redux/user/user";
import {
  format_time_to_timezone,
  same_time_in_timezone,
} from "../../../helper/Generic/dateTimeFormat";
import { copyToClipBoard } from "../../../helper/Generic/CopyInfo";
import { NumericFormat } from "react-number-format";
import { BookmakersElement } from "./BookmakersElement";
import BetsListElementDetails from "./BetsListElementDetails";
import moment from "moment";

const BetsListElement = ({ indexbg, data, handleNote, handleParkBet, displayRsModalHandler, }) => {
  const initialValues = {
    nomove: false,
    noprice: false,
    screenlimit: {
      formattedValue: "",
      value: "",
      floatValue: null,
    },
  };

  const [expandDetails, setExpandDetails] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [countryCode, setCountryCode] = useState(null);
  const [readyToPark, setReadyToPark] = useState(false);
  const user = useSelector(selectUser);
  const timezonedStartTime = format_time_to_timezone(
    data.start_time,
    user.timezone
  );
  const timezonedReceivedTime = format_time_to_timezone(
    data.received_time,
    user.timezone
  );
  const startTime = timezonedStartTime.format("HH:mm");
  const startDate = timezonedStartTime.format("YYYY-MM-DD");

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setValues({
      ...values,
      [name]: checked,
    });
  };

  const handleInputChange = (event, val) => {
    const name = event.event.target.name;
    const value = val;

    setValues({
      ...values,
      [name]: value,
    });
  };
  const validatePosNumber = (value) => {
    if (!isNaN(value) === true) {
      if (parseFloat(value) > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handle_copy = () => {
    copyToClipBoard(
      data.home_team,
      data.away_team,
      data.competition_name,
      data.selection,
      data.min_price,
      data.country_name
    );
  };

  const checkReadyToPark = () => {
    let isScreenLimitValid = validatePosNumber(values.screenlimit.value);
    if (
      values.screenlimit == null ||
      values.screenlimit == undefined ||
      values.screenlimit == ""
    ) {
      setReadyToPark(false);
    } else {
      if (isScreenLimitValid) {
        setReadyToPark(true);
      } else {
        setReadyToPark(false);
      }
    }
  };

  const GetPlacedItems = () => {
    let placedItems = data.placed;
    if (placedItems != undefined) {
      return placedItems.map((item, index) => (
        <PlacedItem key={index}>
          <p>{item}</p>
        </PlacedItem>
      ));
    }
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const checkIfKOTimePassed = (itemKoTime) => {
    const currentDateTime = moment();
    if (currentDateTime > moment(itemKoTime)) {
      return true
    } else false

  }

  const checkPriority = (priority) => {
    if (priority == "low") {
      return <LowPriority />;
    }
    if (priority == "medium") {
      return <MediumPriority />;
    }
    if (priority == "high") {
      return <HighPriority />;
    }
    if (priority == "top") {
      return <TopPriority />;
    }
  };
  const parkButtonClick = () => {
    setValues(initialValues);
    handleParkBet(
      data.id,
      values.nomove,
      values.noprice,
      values.screenlimit.value,
      data.markets
    );
  };
  useEffect(() => {
    getCountryCode(data, setCountryCode);

  }, [data]);

  useEffect(() => {
    checkReadyToPark();
  }, [values.screenlimit]);

  return (
    <Container>


      <MainElement indexbg={indexbg} onDoubleClick={() => (setExpandDetails(!expandDetails))}>
        <ButtonCell>
          <button>
            <CopyButtonSvg onClick={() => handle_copy()} />
          </button>
        </ButtonCell>
        <KOTime>
          <p>{startTime}</p>
          <p>{startDate}</p>
        </KOTime>
        <PriorityCell>{checkPriority(data.priority)}</PriorityCell>
        <GameCell>
          <h2>
            {data.home_team} - {data.away_team}
          </h2>
          <div
            style={{ display: "flex", columnGap: "5px", alignItems: "Center" }}
          >
            <GameCellCompetiton>
              <img src={checkFlag(countryCode)} alt="" />
              <p>{data.competition_name}</p>
            </GameCellCompetiton>
            <ListNo>
              <p>{data.list_id}</p>
            </ListNo>
          </div>
        </GameCell>
        <TimeCell>
          <p>{timezonedReceivedTime.format("HH:mm")}</p>
          <p>{timezonedReceivedTime.format("YYYY-MM-DD")}</p>
        </TimeCell>
        <HighlitedCell>
          <p>{data.market}</p>
        </HighlitedCell>
        <SelectionCell>
          <p>{data.selection}</p>
        </SelectionCell>

        <Cell style={{ minWidth: "60px" }}>
          <NumericFormat
            value={data.target.toFixed(0)}
            displayType="text"
            suffix=" £"
            thousandSeparator=","
            className="numberFormatter"
          />
        </Cell>
        <Cell>
          <p>{data.min_price.toFixed(2)}</p>
        </Cell>



        <Cell style={{ minWidth: "40px" }}>
          <p>
            {data.market_price != undefined ? data.market_price.toFixed(2) : ""}
          </p>
        </Cell>

        <Cell style={{ minWidth: "60px" }}>
          <p
            className={
              data.market_value > 0 ? "positive-color" : "negative-color"
            }
          >
            {data.market_value != undefined
              ? `${data.market_value.toFixed(2)}%`
              : ""}
          </p>
        </Cell>
        <BooksCell>
          <BookmakersElement bookmakersNames={data.bookmakers} small={false} marketsData={data.markets} />
        </BooksCell>
        <Cell style={{ minWidth: "2px" }} ></Cell>
        <Cell style={{ minWidth: "2px" }}></Cell>
        <BtnCell style={{ minWidth: "70px" }}>
          <Switch
            size="small"
            name="nomove"
            checked={values.nomove}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </BtnCell>
        <BtnCell style={{ minWidth: "95px" }}>
          <Switch
            size="small"
            name="noprice"
            checked={values.noprice}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </BtnCell>
        <ScreenLimitCell style={{ minWidth: "70px" }}>
          <NumericFormat
            value={values.screenlimit.formattedValue}
            name="screenlimit"
            onValueChange={(values, sourceInfo) => {
              handleInputChange(sourceInfo, values);
            }}
            suffix=""
            thousandSeparator=","
          />
        </ScreenLimitCell>

        <PlacedCell>
          <GetPlacedItems />
        </PlacedCell>
        <SendRsBtn>
          <button disabled={!data.can_send_to_rs || checkIfKOTimePassed(data.start_time)} onClick={() => displayRsModalHandler(data)}>
            Send RS
          </button>
        </SendRsBtn>


        <LogBtn>
          <button disabled={!readyToPark} onClick={() => parkButtonClick()}>
            Park
          </button>
        </LogBtn>

        <NotesBtn>
          <button>
            <NoteButtonSvg onClick={() => handleNote(data)} />
          </button>
        </NotesBtn>

      </MainElement>
      {expandDetails ? (<BetsListElementDetails prices={data.calculatedValues} />) : ("")}


    </Container>
  );
};

export default BetsListElement;
const Container = styled.div`
display: flex;
flex-direction:column;
`
const MainElement = styled.div`
  display: flex;
  padding: 6px 14px;
  background: ${(props) =>
    props.indexbg % 2
      ? ({ theme }) => theme.matchElementBgindexL
      : ({ theme }) => theme.matchElementBg};
  color: ${({ theme }) => theme.leagueColor};
  column-gap: 9px;
align-items:center;

  .right {
    width: 100%;
    display: flex;
    column-gap: 14px;
  }
`;
const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  width: 100%;
  .positive-color {
    color: #23a760;
  }
  .negative-color {
    color: #ec4848;
  }
  p {
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }
  .numberFormatter {
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }
  button {
    cursor: pointer;
    background: none;
    border: none;
  }
`;
const HighlitedCell = styled(Cell)`
min-width:100px;
  p {
    padding: 3px 7px;
    background: var(--betsHeaderBg2);
    color: var(--elementHighlitedText);
    border-radius: 4px;
  }
`;
const ButtonCell = styled(Cell)`
  min-width: 30px;
  max-width: 30px;
  svg {
    height: 20px;
    width: 20px;
  }
`;
const KOTime = styled(Cell)`
  flex-direction: column;
  justify-content: start;
  width: 75px;
  p {
    color: ${({ theme }) => theme.leagueColor};
  }
`;
const GameCell = styled(Cell)`
  flex-direction: column;
  align-items: flex-start;
  min-width: 150px;

  row-gap: 2px;
  h2 {
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    color: ${({ theme }) => theme.bettTextColor};
  }
`;
const GameCellCompetiton = styled.div`
  background: ${({ theme }) => theme.gameCompetitionBg};
  border-radius: 2px;
  column-gap: 3px;
  padding: 3px 4px;
  p {
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: ${({ theme }) => theme.bettTextColor};
  }
  display: flex;
  flex-direction: row;
  img {
    height: 10px;
    width: 13px;
  }
`;
const SelectionCell = styled(Cell)`
  min-width: 100px;
  justify-content: left;

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};
  }
`;
const BooksCell = styled(Cell)`
  min-width: 200px;
  justify-content: start;
  p {
    overflow: hidden;
    word-wrap: break-word;
    font-weight: 400;
    font-size: 9px;
    line-height: 150%;
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};
  }
`;
const TimeCell = styled(Cell)`
  min-width: 45px;
  max-width:60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    text-align:center;
    font-weight: 500;
    font-size: 8px;
    line-height: 150%;
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};
    width: 45px;
  }
`;
const ScreenLimitCell = styled(Cell)`
  input {
    :focus-visible {
      outline: none;
    }
    color: ${({ theme }) => theme.bettTextColor};
    background: ${({ theme }) => theme.matchElementBg};
    border: none;
    width: 50px;
    border: 0.7px solid #cbd7f5;
    text-align: center;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    border-radius: 2px;
    padding: 4px;
  }
`;
const BtnCell = styled(Cell)`
  min-width: 60px;
  input {
    width: 100%;
  }
`;
const LogBtn = styled(Cell)`
  min-width: 50px;
  button {
    :disabled {
      cursor: inherit;
      opacity: 0.5;
    }
    width: 100%;
    padding: 5px 0;
    background: ${({ theme }) => theme.matchElementBg};
    border: 1px solid var(--parkBtnColor);
    border-radius: 4px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: var(--parkBtnColor);
  }
`;
const SendRsBtn = styled(LogBtn)`
button{
  border: 1px solid var(--sendRsBtnColor);
  color: var(--sendRsBtnColor);
}

`
const NotesBtn = styled(Cell)`

  min-width: 40px;
`;
const ListNo = styled.div`
  background: ${({ theme }) => theme.gameCompetitionListNoBg};
  border: 0.7px solid #e4e9f8;
  border-radius: 2px;
  padding: 3px 4px;
  p {
    font-family: "Roboto";
    
    font-weight: 500;
    font-size: 8px;
    line-height: 9px;
    text-align: center;
    letter-spacing: 0.04em;
    color: ${({ theme }) => theme.gameCompetitonColor};
  }
`;
const PlacedCell = styled(Cell)`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`;
const PlacedItem = styled.div`
  width: 33px;
  background: #e4e9f8;
  border-left: 2px solid #006fff;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  alig-items: center;
  padding: 3px;
  p {
    font-family: "Roboto";
    
    font-weight: 500;
    font-size: 8px;
    line-height: 9px;
    text-align: center;
    color: #525f73;
  }
`;

const PriorityCell = styled.div`
  display: flex;
  position: relative;
  min-width: 14px;
  max-width: 14px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
`;
