import { PATCH } from "../APiHelper/API";

export const setNotesToGame = (data, onSuccess, onError) => {

    // const data = {
    //     fixture_id: fixture_id,
    //     league_id: league_id,
    //     notes: notes,
    // }
    PATCH(`/api/v1/games/notes`, data, onSuccess, onError)
}