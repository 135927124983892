import React from 'react'
import ReactDatePicker from 'react-datepicker'
import styled from 'styled-components'

const DateMenuSingle = ({ value, setValue }) => {
    return (
        <Container>
            <ReactDatePicker
                showIcon={true}
                selected={value}
                dateFormat={"dd/MM/yyyy"}
                onChange={(date) => setValue(date)}
                className='custom-container'
            />
        </Container>
    )
}

export default DateMenuSingle
const Container = styled.div`
.custom-container{
        font-family: Roboto;
font-size: 14px;
font-weight: 500;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
border: 1px solid #BAC3E8;
background:${({ theme }) => theme.editLeague.selectMenuBg};
color:${({ theme }) => theme.editLeague.selectMenuTextColor};
border-radius: 6px;
padding:11px 15px;
:focus{
    outline:none;
}
}
`