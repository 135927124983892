
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CopyButtonSvg } from "../../../assets/icons/BettingIcons/duplicate.svg";
import { ReactComponent as NoteButtonSvg } from "../../../assets/icons/BettingIcons/notePencil.svg";
import { ReactComponent as DeleteButtonSvg } from "../../../assets/icons/BettingIcons/deleteBtnRsBet.svg";
import { useSelector } from "react-redux";
import { selectUser } from "../../../Redux/user/user";
import { checkFlag, getCountryCode } from "../../../helper/Generic/GetFlag";
import {
  format_time_to_timezone,
  same_time_in_timezone,
} from "../../../helper/Generic/dateTimeFormat";
import { copyToClipBoard } from "../../../helper/Generic/CopyInfo";
import { NumericFormat } from "react-number-format";
const RsBetListElement = ({
  data,
  handleNote,
  handleDisableRsBet,
  calculateActiveUntil,
  displayRsModalHandler,
}) => {
  const initialValues = {
    returnedStake: {},
    confirmedPrice: {},
  };

  const user = useSelector(selectUser);
  const [values, setValues] = useState(initialValues);
  const [countryCode, setCountryCode] = useState(null);
  const [activeTime, setActiveTime] = useState(
    calculateActiveUntil(data.runshop.active_until)
  );

  const timezonedStartTime = format_time_to_timezone(
    data.bet.start_time,
    user.timezone
  );

  const startTime = timezonedStartTime.format("HH:mm");
  const startDate = timezonedStartTime.format("YYYY-MM-DD");

  const calculateStakeBarPercent = (selectedStake, totalStake) => {
    return (totalStake * 100) / selectedStake;
  };

  const stakeBarPercent = calculateStakeBarPercent(
    data.runshop.stake,
    data.current_status.totalStakes
  );

  const StakeProgressBar = ({ selectedStake, totalStake, percent }) => {
    return (
      <StakeProgressContainer>
        <StakeCompleted completed={percent} />
        <StakeDetails>
          <p>
            <NumericFormat
              value={totalStake}
              displayType="text"
              thousandSeparator=","
              className="progressNumberFormatter"
            />
            /
            <NumericFormat
              value={selectedStake}
              displayType="text"
              thousandSeparator=","
              className="progressNumberFormatter"
            />
          </p>
        </StakeDetails>
      </StakeProgressContainer>
    );
  };

  const handle_copy = () => {
    copyToClipBoard(
      data.bet.home_team,
      data.bet.away_team,
      data.bet.competition_name,
      data.bet.selection,
      data.bet.min_price,
      data.bet.country_name
    );
  };

  useEffect(() => {
    getCountryCode(data.bet, setCountryCode);
  }, [data]);

  useEffect(() => {
    setActiveTime(calculateActiveUntil(data.runshop.active_until));
    const interval = setInterval(() => {
      let time = calculateActiveUntil(data.runshop.active_until);
      setActiveTime(time);
    }, 60000);
    return () => clearInterval(interval);
  }, [data.runshop.active_until]);

  return (
    <Container>

      <ButtonCell>
        <button>
          <CopyButtonSvg onClick={() => handle_copy()} />
        </button>
      </ButtonCell>

      <TimeCell>
        <p>{startTime}</p>
        <p>{startDate}</p>
      </TimeCell>

      <GameCell>
        <h2>
          {data.bet.home_team} - {data.bet.away_team}
        </h2>
        <div
          style={{ display: "flex", columnGap: "5px", alignItems: "Center" }}
        >
          <GameCellCompetiton>
            <img src={checkFlag(countryCode)} alt="" />
            <p>{data.bet.competition_name}</p>
          </GameCellCompetiton>
          <ListNo>
            <p>{data.bet.list_id}</p>
          </ListNo>
        </div>
      </GameCell>

      <HighlitedCell>
        <p>{data.bet.market}</p>
      </HighlitedCell>

      <SelectionCell>
        <p>{data.bet.selection}</p>
      </SelectionCell>

      <Cell style={{ minWidth: "60px" }}>
        <NumericFormat
          value={data.bet.target.toFixed(0)}
          displayType="text"
          suffix={" "}
          thousandSeparator=","
          className="numberFormatter"
        />
      </Cell>

      <Cell>
        <p>{data.bet.min_price.toFixed(2)}</p>
      </Cell>

      <Cell style={{ minWidth: "40px" }}>
        <p>{data.market_price}</p>
      </Cell>

      <Cell style={{ minWidth: "60px" }}>
        {data.market_value !== null && (
          <p
            className={
              data.market_value > 0 ? "positive-color" : "negative-color"
            }
          >{`${data.market_value.toFixed(2)}%`}</p>
        )}
      </Cell>

      <ScreenLimit></ScreenLimit>

      <BrokerCell></BrokerCell>

      <TimeRequestCell></TimeRequestCell>

      <ReturnedStakeCell>
        <StakeProgressBar
          selectedStake={data.runshop.stake}
          totalStake={data.current_status.totalStakes}
          percent={stakeBarPercent}
        />
      </ReturnedStakeCell>

      <TimeLeftcell>
        {data.runshop.active && (
          <p>
            Working • {activeTime.hours > 0 && `${activeTime.hours} h`}{" "}
            {activeTime.minutes} min
          </p>
        )}
      </TimeLeftcell>

      <BtnCell>
        <p>{data?.runshop?.price}</p>
      </BtnCell>

      <TimeReturnedCell></TimeReturnedCell>

      <BtnCell></BtnCell>

      <EditBtn>
        <button onClick={(e) => displayRsModalHandler(data, "edit")}>
          Edit
        </button>
      </EditBtn>

      <NotesBtn>
        <button onClick={() => handleNote(data.bet)}>
          <NoteButtonSvg />
        </button>
      </NotesBtn>

      <DeleteBtn>
        <button onClick={() => handleDisableRsBet(data.runshop.id)}>
          <DeleteButtonSvg />
        </button>
      </DeleteBtn>

    </Container>
  );
};

export default RsBetListElement;

const Container = styled.div`
  display: flex;
  padding: 6px 14px;
  background: ${(props) =>
    props.indexbg % 2
      ? ({ theme }) => theme.matchElementBgindexL
      : ({ theme }) => theme.matchElementBg};
  color: ${({ theme }) => theme.leagueColor};
  column-gap: 9px;
  align-items: center;
  border-bottom: 1px solid rgba(228, 233, 248, 1);
  .right {
    width: 100%;
    display: flex;
    column-gap: 14px;
  }
`;
const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  width: 100%;
  .positive-color {
    color: #23a760;
  }
  .negative-color {
    color: #ec4848;
  }
  p {
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }
  .numberFormatter {
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};

    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }
  .progressNumberFormatter {
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};
    color: rgba(58, 57, 87, 1);
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }
  button {
    cursor: pointer;
    background: none;
    border: none;
  }
`;
const HighlitedCell = styled(Cell)`
min-width:100px;
  p {
    padding: 3px 7px;
    background: var(--betsHeaderBg3);
    color: var(--elementHighlitedText);
    border-radius: 4px;
  }
`;
const ScreenLimit = styled(Cell)`
  min-width: 70px;
`;
const ButtonCell = styled(Cell)`
  min-width: 30px;
  max-width: 30px;
  svg {
    height: 20px;
    width: 20px;
  }
`;
const TimeCell = styled(Cell)`
  flex-direction: column;
  justify-content: start;
  width: 75px;
  p {
    color: ${({ theme }) => theme.leagueColor};
  }
`;
const GameCell = styled(Cell)`
  flex-direction: column;
  align-items: flex-start;
  min-width: 150px;
  row-gap: 2px;
  h2 {
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    color: ${({ theme }) => theme.bettTextColor};
  }
`;
const GameCellCompetiton = styled.div`
  background: ${({ theme }) => theme.gameCompetitionBg};
  border-radius: 2px;
  column-gap: 3px;
  padding: 3px 4px;

  p {
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: ${({ theme }) => theme.leagueColor};
  }
  display: flex;
  flex-direction: row;
  img {
    height: 10px;
    width: 13px;
  }
`;
const SelectionCell = styled(Cell)`
  min-width: 100px;
  justify-content: left;
  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
  }
`;

const TimeRequestCell = styled(Cell)`
  flex-direction: column;
  min-width: 60px;
  p {
    font-weight: 500;
    font-size: 8px;
    line-height: 150%;
    text-transform: uppercase;
  }
`;
const ReturnedStakeCell = styled(Cell)`
  min-width: 129px;
  input {
    color: ${({ theme }) => theme.leagueColor};
    background: ${({ theme }) => theme.matchElementBg};
    :focus-visible {
      outline: none;
    }
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    width: 100%;
    border: 0.7px solid #cbd7f5;
    text-align: center;
    border-radius: 2px;
    padding: 4px 9px;
  }
`;
const TimeLeftcell = styled(Cell)`
  min-width: 110px;
  border-radius: 4px;
  background: #fff8ed;
  height: 20px;
  p {
    color: #ff8d21;
    font-family: Roboto;
    font-size: 10px;
    font-weight: 500;
    text-transform: inherit;
  }
`;
const BtnCell = styled(Cell)`
  min-width: 51px;
  input {
    width: 100%;
  }
`;
const TimeReturnedCell = styled(Cell)`
  position: relative;
  flex-direction: column;
  min-width: 60px;
  .rc-time-picker-clear-icon:after {
    color: ${({ theme }) => theme.bettTextColor};
  }

  .rc-time-picker-panel-inner {
    background: green !important;
    box-shadow: 0 1px 5px red;
  }
`;

const LogBtn = styled(Cell)`
  min-width: 50px;
  button {
    :disabled {
      cursor: inherit;
      opacity: 0.5;
    }
    width: 100%;
    padding: 5px 8px;
    background: ${({ theme }) => theme.matchElementBg};
    border: 1px solid var(--primaryBlue);
    border-radius: 4px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: var(--primaryBlue);
  }
`;
const EditBtn = styled(LogBtn)`
  button {
    border: none;
    background: #eaeffe;
  }
`;
const NotesBtn = styled(Cell)`
  min-width: 40px;
`;
const DeleteBtn = styled(Cell)`
  min-width: 50px;

  svg {
    fill: ${({ theme }) => theme.matchElementBg};
  }
`;
const BrokerCell = styled(Cell)`
  min-width: 70px;
`;
const TimeResetBtn = styled.button`
  background: var(--primaryBlue);
  color: white;
  border: none;
  width: 100%;
  padding: 10px 5px;
  font-family: "Roboto";

  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
`;
const ListNo = styled.div`
  background: ${({ theme }) => theme.gameCompetitionListNoBg};
  border: 0.7px solid #e4e9f8;
  border-radius: 2px;
  padding: 3px 4px;
  p {
    font-family: "Roboto";

    font-weight: 500;
    font-size: 8px;
    line-height: 9px;
    text-align: center;
    letter-spacing: 0.04em;
    color: ${({ theme }) => theme.gameCompetitonColor};
  }
`;

const StakeProgressContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.notesBG};
  border: 1px solid rgba(203, 215, 245, 1);
  height: 20px;
  position: relative;
  overflow: hidden;
`;
const StakeCompleted = styled.div`
  background: rgba(202, 225, 255, 1);
  width: ${(props) => props.completed}%;
  height: 100%;
`;
const StakeDetails = styled.div`
  position: absolute;
  top: 3px;
  left: 8px;
  p {
    color: rgba(58, 57, 87, 1);
  }
`;
