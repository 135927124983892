export const mapTeamObjects = (teams) => {
    let listData = [];
    let data = {};

    teams.forEach((team) => {
        data = {
            id: team.id,
            name: team.name,
            derbies: team.derbies,
            stadium: team.stadium,
            logo_url: team.logo_url

        };
        listData.push(data);

    });
    return listData;
};