import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import DropdownMenuRole from "./DropDown/DropdownMenuRole";
import DropDownMenuCountry from "./DropDown/DropDownMenuCountry";
import LeaguesList from "./LeaguesList";
import SelectionList from "./SelectionList";
import { sendInvite } from "../../api/invites/SendInvite";
import { Switch } from "@mui/material";
import {
  notifyError,
  notifyLoading,
  notifySucces,
  notifyUpdate,
} from "../../helper/Generic/Notifications";
import { getUsersRoles } from "../../api/user/GetUsersRoles";
import { getOtherUsers } from "../../api/user/GetOtherUsers";
import { ReactComponent as SearchSvg } from "../../assets/icons/search.svg";
import { getCountries } from "../../api/user/GetCountriesListAdmin";
import { getCompetitionsForCountryId } from "../../api/competitions/GetCompetitionForCountryId";
import { getCompetitionsForTerm } from "../../api/competitions/GetCompetitionsForTerm";
import { toast } from "react-toastify";
import DropDownMenuSeasonCup from "./DropDown/DropdownSeasonCup";
import SeasonCupList from "./SeasonCupList";
import { useLeaguesDataService } from "../../context/useLeaguesDataService";

const InviteTeamMemberModal = (props) => {

  const COMPETITIONS = "competitons";
  const SEASONCUP = "season/cup";


  const { get_cups_for_admin, get_seasons_for_admin } = useLeaguesDataService()
  const [inputEmail, setInputEmail] = useState("");
  const [inputSearch, setInputSearch] = useState("");
  const [role, setRole] = useState(null);
  const [country, setCountry] = useState(null);
  const [competitions, setCompetitions] = useState([]);
  const [selectedCompetitions, setSelectedCompetitions] = useState([]);

  const [allLeagues, setallLeagues] = useState(false);
  const [allSeasons, setAllSeasons] = useState(false);
  const [allCups, setAllCups] = useState(false);
  const [ready, setReady] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);

  const [seasonsList, setSeasonsList] = useState([]);
  const [cupsList, setCupsList] = useState([]);


  const [selectedSeasons, setSelectedSeasons] = useState([]);
  const [selectedCups, setSelectedCups] = useState([]);

  const toastId = useRef(null);

  const [access, setAccess] = useState(COMPETITIONS);

  const handleEmailInput = (e) => {
    setInputEmail(e.target.value);
  };


  const handleGetSeasonsForAdmin = () => {
    const data = {
      archived: false
    }
    get_seasons_for_admin(
      data,
      (response) => {
        setSeasonsList(response)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  const handleGetCupsForAdmin = () => {
    const data = {
      archived: false
    }
    get_cups_for_admin(
      data,
      (response) => {
        setCupsList(response.data.cups)
      },
      (error) => {
        console.log(error)
      }
    )
  }


  const handleallLeagues = (event) => {
    setSelectedCompetitions([]);
    setCompetitions([]);
    setallLeagues(event.target.checked);
    setCountry(null);
  };

  const unselectAllCompetitions = () => {
    setSelectedCompetitions([]);
    setCompetitions([]);
    setallLeagues(false);
    setCountry(null);
  };

  const unselectAllSeasons = () => {
    setSelectedSeasons([]);
    setAllSeasons(false);
  };

  const unselectAllCups = () => {
    setSelectedCups([]);
    setAllCups(false);
  };

  const handleAllSeasons = (event) => {
    setSelectedSeasons([]);
    setAllSeasons(event.target.checked);

  };

  const handleAllCups = (event) => {
    setSelectedCups([]);
    setAllCups(event.target.checked);
  };

  const handleSearchInput = (e) => {
    setInputSearch(e.target.value);
  };

  const handleUserRoles = () => {
    getUsersRoles(
      function (response) {
        setRolesList(response.data.roles);
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };

  const handleSearchCompetition = () => {
    setCountry(null);
    if (access == SEASONCUP) {
      return
    }
    if (inputSearch !== "") {
      getCompetitionsForTerm(
        inputSearch,
        function (response) {
          setCompetitions(response.data.competitions);
        },
        function (error) {
          notifyError("Something wrong");
        }
      );
    } else {
      setCompetitions([]);
    }
  };

  const handleCountryList = () => {
    getCountries(
      function (response) {
        setCountriesList(response.data.countries);
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };

  const handleCompetitionList = () => {
    if (country !== null) {
      getCompetitionsForCountryId(
        [country.country_id],
        function (response) {
          setCompetitions(response.data.competitions);
        },
        function (error) {
          notifyError("Something wrong");
        }
      );
    }
  };

  const checkInviteReady = () => {
    if (inputEmail.length > 0 && role !== null) {
      setReady(true);
    } else {
      setReady(false);
    }
  };

  const handleOtherUsers = () => {
    getOtherUsers(
      function (response) {
        props.setOtherUsers(response.data.users);
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };

  const handleSendInvite = () => {
    notifyLoading(toastId, "Sending Invite...");

    const competitionsIds = selectedCompetitions.map((item) => {
      return item.competition_id
    })

    const seasonsIds = selectedSeasons.map((item) => {
      return item.id
    })

    const cupsIds = selectedCups.map((item) => {
      return item.id
    })


    sendInvite(
      inputEmail,
      role,
      allLeagues,
      allSeasons,
      allCups,
      competitionsIds,
      seasonsIds,
      cupsIds,
      function () {
        notifyUpdate(toastId, "Invitation sent", toast.TYPE.SUCCESS);
        handleOtherUsers();
        props.setShowInviteMember(false);
      },
      function (error) {
        if (error.response.status === 409) {
          notifyUpdate(toastId, "User already exist", toast.TYPE.ERROR);
        } else {
          notifyUpdate(toastId, "Something wrong", toast.TYPE.ERROR);
        }
      }
    );
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearchCompetition();
    }
  };


  const handleAccessSelect = (acessTo) => {
    setAccess(acessTo);
    setCompetitions([]);
    setInputSearch("")
    setCountry(null);
  };

  useEffect(() => {
    checkInviteReady();
  }, [inputEmail, role]);

  useEffect(() => {
    handleGetSeasonsForAdmin();
    handleGetCupsForAdmin();
    handleUserRoles();
    handleCountryList();
  }, []);

  useEffect(() => {
    handleCompetitionList();
  }, [country]);

  useEffect(() => {
    if (allSeasons) {
      if (selectedSeasons.length > 0) {
        setAllSeasons(false)
      }
    }

    if (allCups) {
      if (selectedCups.length > 0) {
        setAllCups(false)
      }
    }
  }, [selectedCups, selectedSeasons])

  return (
    <Container>
      <Card>
        <Top>
          <h2>Invite new member</h2>
          <div onClick={() => props.setShowInviteMember(false)}>
            <CloseIcon style={{ cursor: "pointer" }} />
          </div>
        </Top>
        <Middle>
          <Section>
            <h2>Send an invite to your team member via email</h2>
            <ColumnGroup>
              <label>Email</label>
              <CustomInput
                type={"email"}
                value={inputEmail}
                onChange={handleEmailInput}
                placeholder="email@whiteswam.com"
              ></CustomInput>
            </ColumnGroup>
          </Section>
          <Section>
            <h2>Choose the member role</h2>
            <DropdownMenuRole
              label={"Roles"}
              itemsSource={rolesList}
              selectedValue={role}
              setSelectedValue={setRole}
            />
          </Section>
          <Section>
            <h2>Interface</h2>
            <SelectEventType>
              <Event
                onClick={() => handleAccessSelect(COMPETITIONS)}
                selected={access === COMPETITIONS}
              >
                <p>{COMPETITIONS}</p>
              </Event>
              <Event
                onClick={() => handleAccessSelect(SEASONCUP)}
                selected={access === SEASONCUP}
              >
                <p>{SEASONCUP}</p>
              </Event>
            </SelectEventType>
          </Section>
          <Section>
            {access === COMPETITIONS && (
              <Line>
                <h2>Competitions access</h2>
                <SeletAllWrapper style={{ marginLeft: "auto" }}>
                  <h3 style={{ fontWeight: 400 }}>
                    access to all competitions
                  </h3>
                  <Switch
                    checked={allLeagues}
                    onChange={handleallLeagues}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </SeletAllWrapper>
              </Line>
            )}

            {access === SEASONCUP && (
              <Line>
                <h2>Seasons and cups access</h2>
                <SeletAllWrapper style={{ marginLeft: "auto" }}>
                  <h3 style={{ fontWeight: 400 }}>
                    access to all seasons
                  </h3>
                  <Switch
                    checked={allSeasons}
                    onChange={handleAllSeasons}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </SeletAllWrapper>
                <SeletAllWrapper style={{ marginLeft: "auto" }}>
                  <h3 style={{ fontWeight: 400 }}>
                    access to cups
                  </h3>
                  <Switch
                    checked={allCups}
                    onChange={handleAllCups}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </SeletAllWrapper>
              </Line>
            )}



            <ColumnGroup >
              {access === COMPETITIONS ? <label>Search by league name</label> : <label>Search by season cup name</label>}
              <SearchWrapper>
                <SearchSvg />
                <CustomInput
                  onKeyDown={(e) => handleKeyDown(e)}
                  type={"text"}
                  value={inputSearch}
                  onChange={handleSearchInput}
                  placeholder="Search"
                ></CustomInput>
                <button onClick={() => handleSearchCompetition()}>
                  {" "}
                  Search
                </button>
              </SearchWrapper>
            </ColumnGroup>

          </Section>

          <div style={{ display: "flex", flexDirection: "row" }}>
            {access === COMPETITIONS && (
              <Section >
                <label>Choose competitions from a specific country</label>
                <div>
                  <DropDownMenuCountry
                    label={"Country"}
                    itemsSource={countriesList}
                    selectedValue={country}
                    setSelectedValue={setCountry}
                    disabled={allLeagues}
                  />
                  <LeaguesList
                    country={country}
                    competitions={competitions}
                    selectedCompetitions={selectedCompetitions}
                    setSelectedCompetitions={setSelectedCompetitions}
                  />
                </div>
              </Section>
            )}

            {access === SEASONCUP && (
              <Section >
                <label>Choose seasons/cups</label>
                <ColumnGroup>

                  <DropDownMenuSeasonCup
                    label={"Season"}
                    listItems={seasonsList}
                    itemsSource={countriesList}
                    setSelectedItems={setSelectedSeasons}
                    selectedItems={selectedSeasons}
                    disabled={allSeasons}
                    searchTerm={inputSearch}
                  />

                  <DropDownMenuSeasonCup
                    disabled={allCups}
                    label={"Cup"}
                    listItems={cupsList}
                    itemsSource={countriesList}
                    setSelectedItems={setSelectedCups}
                    selectedItems={selectedCups}
                    searchTerm={inputSearch}
                  />

                  <SeasonCupList
                    country={country}
                    competitions={competitions}
                    selectedCompetitions={selectedCompetitions}
                    setSelectedCompetitions={setSelectedCompetitions}
                  />
                </ColumnGroup>
              </Section>
            )}
            <hr
              style={{
                margin: "0 25px ",
                minWidth: "3px",
                background: "#EEF2F6",
                border: "none",
              }}
            />

            {access === COMPETITIONS &&
              <Section>
                <h2>Your selection</h2>
                <SelectionList
                  allLeagues={allLeagues}
                  country={country}
                  selectedCompetitions={selectedCompetitions}
                  setSelectedCompetitions={setSelectedCompetitions}
                  unselectAllCompetitions={unselectAllCompetitions}
                />
              </Section>
            }
            {access === SEASONCUP &&
              <Section>
                <h2>Your selection</h2>
                <SelectionList
                  country={country}
                  selectedCups={selectedCups}
                  setSelectedCups={setSelectedCups}
                  selectedSeasons={selectedSeasons}
                  setSelectedSeasons={setSelectedSeasons}
                  unselectAllSeasons={unselectAllSeasons}
                  unselectAllCups={unselectAllCups}
                  allSeasons={allSeasons}
                  allCups={allCups}
                />
              </Section>
            }
          </div>
        </Middle>
        <Bottom>
          <button disabled={!ready} onClick={() => handleSendInvite()}>
            Send email invite
          </button>
        </Bottom>
      </Card>

    </Container>
  );
};

export default InviteTeamMemberModal;

const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  z-index: 5;
  background-color: rgba(119, 124, 134, 0.5);
  height:100%;
  padding:20px;
`;

const Card = styled.div`
  display: flex;
  position: relative;
  width: 616px;
  height: 100%;
  max-height:820px;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 1px 8px rgba(46, 46, 46, 0.04);
`;

const Top = styled.div`
  display: flex;
  padding: 12px 23px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background: var(--gradient1);
  display: flex;
  justify-content: space-between;
  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
`;

const Middle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  row-gap: 15px;
  height:100%;
overflow:auto;
  h2 {
    color: var(--darkBlue);
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
  }

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--darkBlue);
  }



`;

const CustomInput = styled.input`
font-size:14px;
line-height:16px;
 border: 1px solid #d9dfe7;
    border-radius: 4px;
    padding: 11px 16px;
    ::placeholder {
      color: #b3b9c0;
    }
    :focus-visible {
      outline: 0px;
      //outline-offset: 1px;
      border: 1px solid var(--primaryBlue);
    }
`

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
    width:100%;
`;
const ColumnGroup = styled.div`
 display: flex;
    flex-direction: column;
    row-gap: 8px;
`

const Bottom = styled.div`
  display: flex;
  margin-top: auto;
  background: var(--gradient1);
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  display: flex;
  justify-content: flex-end;
  padding: 12px 23px;

  button {
    padding: 12px 27px;
    border-radius: 4px;
    border: none;
    background-color: var(--primaryBlue);
    color: white;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    :disabled {
      opacity: 0.5;
      cursor: inherit;
    }
  }
`;

const SelectEventType = styled.div`
  display: flex;
  border: 1px solid
    ${({ theme }) => theme.editLeague.addEventModal.selectedOptionBg};
  width: fit-content;
  border-radius: 2px;
`;
const Event = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 30px;
  min-width: 100px;
  max-width: 100px;
  width: 100%;
  cursor: pointer;
  background: ${(props) =>
    props.selected
      ? ({ theme }) => theme.editLeague.addEventModal.selectedOptionBg
      : ({ theme }) => theme.editLeague.addEventModal.optionBg};
  p {
    font-family: Roboto;
    font-size: 9px;
    font-weight: 700;
    line-height: 11px;
    text-align: center;
    text-transform: uppercase;
    color: ${(props) =>
    props.selected
      ? ({ theme }) => theme.editLeague.addEventModal.selectedOptionColor
      : ({ theme }) => theme.editLeague.addEventModal.optionColor};
  }
`;

const SeletAllWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  position: relative;
  column-gap: 10px;
  input {
    width: 100%;
    padding-left: 30px;
  }

  svg {
    position: absolute;
    top: 12px;
    left: 10px;
  }

  button {
    background: #ffffff;
    border: 1px solid #006fff;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--primaryBlue);
    padding: 7px 28px;
    cursor: pointer;
  }
`;
