class LocalStorage {
  static setAuthenthicationToken(access_token) {

    localStorage.setItem("access_token", access_token);
  }
  static getAuthenticationToken() {
    return localStorage.getItem("access_token");
  }
  static setRefreshToken(refresh_token) {
    localStorage.setItem("refresh_token", refresh_token);
  }
  static getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }
}

export default LocalStorage;
