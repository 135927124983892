import React, { cloneElement } from "react";
import NotFound from "../../pages/NotFound";
import { PERMISSIONS } from "./Permissions";
import useGetRole from "./useGetRole";

const hasPermission = ({ permissions, scopes }) => {

	const scopesMap = {};
	scopes.forEach((scope) => {
		scopesMap[scope] = true;
	});
	return permissions.some((permission) => scopesMap[permission]);
};

export default function PermissionsGate({
	children,
	errorProps = null,
	scopes = []
}) {
	const [role, setRole] = useGetRole();
	const permissions = PERMISSIONS[role];
	const permissionGranted = hasPermission({ permissions, scopes });

	if (!permissionGranted && !errorProps) return <></>;
	if (!permissionGranted && errorProps.pageAuthorization == "unauthorized-page")
		return <><NotFound /></>;
	if (!permissionGranted && errorProps)
		return cloneElement(children, { ...errorProps });

	return <>{children}</>;
}

