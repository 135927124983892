import React from 'react'
import styled from 'styled-components'



const BetsListElementDetails = ({ prices }) => {
    const headerValues = ["HA", "HD", "AA", "AD", "1", "X", "2", " ", "H", "A", " ", "O", "U", "Sup.", "tgX"]
    const format = (value) => {
        if (value == null) {
            return "";
        }
        if (value.length == 0) {
            return ""
        }
        if (typeof (value) === "string") {
            return ""
        }
        return value.toFixed(2)
    }



    return (
        <Container>
            <Header>
                {headerValues.map((item, index) => (
                    <Cell>
                        <h3>{item}</h3>
                    </Cell>
                ))}
            </Header>
            <MainRow>
                <BlueCell>
                    <p>{prices?.ha}</p>
                </BlueCell>
                <BlueCell>
                    <p>{prices?.hd}</p>
                </BlueCell>
                <BlueCell>
                    <p>{prices?.aa}</p>
                </BlueCell>
                <BlueCell>
                    <p>{prices?.ad}</p>
                </BlueCell>
                <Cell>
                    <p>{prices?.model_1}</p>
                </Cell>
                <Cell>
                    <p>{prices?.model_x}</p>
                </Cell>
                <Cell>
                    <p>{prices?.model_2}</p>
                </Cell>
                <OrangeCell>
                    <p>{prices?.model_ha_value}</p>
                </OrangeCell>
                <Cell>
                    <p>{prices?.model_h}</p>
                </Cell>
                <Cell>
                    <p>{prices?.model_a}</p>
                </Cell>
                <OrangeCell>
                    <p>{prices?.model_ou_value}</p>
                </OrangeCell>
                <Cell>
                    <p>{prices?.model_o}</p>
                </Cell>
                <Cell>
                    <p>{prices?.model_u}</p>
                </Cell>
                <BlueCell>
                    <p>{prices?.model_sup}</p>
                </BlueCell>
                <BlueCell>
                    <p>{prices?.model_tgx}</p>
                </BlueCell>
            </MainRow>
            {prices?.sublines.map((item, index) => (
                <SecondaryRow key={index}>
                    <Cell>
                    </Cell>
                    <Cell>
                    </Cell>
                    <Cell>
                    </Cell>
                    <Cell>
                    </Cell>
                    <Cell>
                    </Cell>
                    <Cell>
                    </Cell>
                    <Cell>
                    </Cell>
                    <Cell >
                        <p>{format(item.model_ha_value)}</p>
                    </Cell>
                    <Cell >
                        <p>{format(item.model_h)}</p>
                    </Cell>
                    <Cell >
                        <p>{format(item.model_a)}</p>
                    </Cell>
                    <Cell >
                        <p>{format(item.model_ou_value)}</p>
                    </Cell>
                    <Cell>
                        <p>{format(item.model_o)}</p>
                    </Cell>
                    <Cell>
                        <p>{format(item.model_u)}</p>
                    </Cell>
                    <Cell>
                    </Cell>
                    <Cell>
                    </Cell>
                </SecondaryRow>
            ))}

        </Container>
    )
}

export default BetsListElementDetails
const Container = styled.div`
display:flex;
flex-direction:column;
align-items:center;


`
const Header = styled.div`
display:flex;
background: #E4E9F8;
width:100%;
padding:10px 0;
justify-content:center;
column-gap:40px;
`
const MainRow = styled.div`
display:flex;
width:100%;
padding:10px 0;
justify-content:center;
column-gap:40px;
`
const SecondaryRow = styled(MainRow)`
background:${({ theme }) => theme.matchElementBgindexL};
`
const Cell = styled.div`
min-width:25px;

h3{
    color: rgba(0, 18, 41, 0.80);
text-align: center;
font-family: Roboto;
font-size: 10px;
font-weight: 700;
line-height: normal;
letter-spacing: -0.2px;

}
p{
    color:${({ theme }) => theme.leagueColor};
text-align: center;
font-family: Roboto;
font-size: 10px;
font-weight: 500;
line-height: normal;
letter-spacing: -0.3px;


}
`
const BlueCell = styled(Cell)`
p{
    color: ${({ theme }) => theme.matchHighlitedText};
    font-weight: 700;
}
`
const OrangeCell = styled(Cell)`
p{
    color: ${({ theme }) => theme.matchDistinctValueModel};
    font-weight: 700;
}
`