import { POST } from "../../APiHelper/API";

var searchPlayerController = null;

export const searchPlayer = (term, onSuccess, onError) => {
  const data = {
    term: term,
  };
  searchPlayerController = POST(
    `/api/v1/leagues/player/search`,
    data,
    onSuccess,
    onError,
    false,
    searchPlayerController
  );
};
