import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as XBtn } from "../../../assets/icons/xBtn.svg";
import { ReactComponent as StatisticsButtonIcon } from "../../../assets/icons/LeaguesIcons/pitchConditionStatistic.svg";
import { useSelector } from "react-redux";
import { selectUser } from "../../../Redux/user/user";
import { format_time_to_timezone } from "../../../helper/Generic/dateTimeFormat";

const PitchConditionStatistics = ({ pitchConditionsHistory, currentStadium }) => {

  const user = useSelector(selectUser);

  const [showPopup, setShowPopup] = useState(false);
  const ref = useRef(null);

  const checkCondition = (condition) => {
    if (condition.toLowerCase() == "excellent") {
      return "#47B77B";
    }
    if (condition.toLowerCase() == "good") {
      return "#A7D46E";
    }
    if (condition.toLowerCase() == "average") {
      return "#D8B70C";
    }
    if (condition.toLowerCase() == "poor") {
      return "#F67B7B";
    }
    if (condition.toLowerCase() == "terrible") {
      return "#896748";
    }
  };

  const buildConditions = (conditions) => {
    let returnArray = [];

    conditions.forEach((element) => {
      const fixture = element.fixture;
      const pitchCondition = element.pitch_condition;
      const pitchConditionText =
        pitchCondition.charAt(0).toUpperCase() + pitchCondition.slice(1);

      const team1 = element.team1;
      const team2 = element.team2;

      var game = team1.name + " - " + team2.name;
      if (fixture.participant1is_home === false) {
        game = team2.name + " - " + team1.name;
      }

      const matchDate = format_time_to_timezone(
        fixture.start_time,
        user.timezone
      ).format("DD.MM.YYYY - HH:mm");

      const item = (
        <PitchCondition condition={checkCondition(pitchCondition)}>
          <Status condition={checkCondition(pitchCondition)}>
            <h3>{pitchConditionText}</h3>
          </Status>
          <Match>{game}</Match>
          <MatchDate>{matchDate}</MatchDate>
        </PitchCondition>
      );

      returnArray.push(item);
    });

    return returnArray;
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showPopup && ref.current && !ref.current.contains(e.target)) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setShowPopup]);

  return (
    <Container ref={ref}>
      <StatisticsButton onClick={() => setShowPopup(!showPopup)}>
        <StatisticsButtonIcon />
      </StatisticsButton>

      {showPopup && (
        <PopupContainer ref={ref}>
          <Xline>
            <XButton onClick={() => setShowPopup(false)}>
              <XBtn />
            </XButton>
          </Xline>
          <TitleLine>
            <h1>{currentStadium?.name}</h1>
            <h2>PITCH CONDITION</h2>
          </TitleLine>
          <BasedonLastMatchesLine>
            <h2>{pitchConditionsHistory.length < 1 ? ("No data for this stadium for this season") : ("Last 5 matches")}</h2>
          </BasedonLastMatchesLine>
          <LastMatchesPitch>
            {buildConditions(pitchConditionsHistory)}


          </LastMatchesPitch>
        </PopupContainer>
      )}
    </Container>
  );
};

export default PitchConditionStatistics;

const Container = styled.div`
  display: flex;
  position: relative;
`;
const StatisticsButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  svg{
fill:${({ theme }) => theme.matchPreview.pitchConditionIconFill};
stroke:${({ theme }) => theme.matchPreview.pitchConditionIconStroke};
color:${({ theme }) => theme.matchPreview.pitchConditionIconStroke};
  }
`;
const PopupContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50px;
  width: 250px;

  background: ${({ theme }) => theme.editLeague.readOnlyInputs};
  box-shadow: 0px 0px 10px rgba(98, 101, 112, 0.15);
  border-radius: 10px;
  z-index: 1;
  padding: 15px;
  row-gap: 20px;
`;
const Xline = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;

`;
const XButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  svg{
      color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
`;
const TitleLine = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
  h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
`;
const BasedonLastMatchesLine = styled.div`
  h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    color: ${({ theme }) => theme.editLeague.groupsTextColor};
    opacity:0.6;
  }
`;
const LastMatchesPitch = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
const PitchCondition = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 4px solid;
  border-color: ${(props) =>
    props.condition != undefined ? props.condition : "black"};
  padding-left: 5px;
`;
const Status = styled.div`
  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    letter-spacing: 0.02em;
    color: ${(props) =>
    props.condition != undefined ? props.condition : "black"};
  }
`;
const Match = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 140%;
  color: ${({ theme }) => theme.matchPreview.pitchConditionTextColor};
`;
const MatchDate = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  color: ${({ theme }) => theme.matchPreview.pitchConditionTextColor};
`;
