import { createContext, useCallback, useContext, useState } from "react";
import { editLeague } from "../api/analyst-leagues/leagues/editLeague";
import { deleteLeague } from "../api/analyst-leagues/leagues/deleteLeague";
import { getLeaguesForCurrentUser } from "../api/analyst-leagues/leagues/getLeaguesForCurrentUser";
import { getSeasons, getSeasonsForAdminAPICall } from "../api/analyst-leagues/season/getSeasons";
import { createNewSeason } from "../api/analyst-leagues/season/createNewSeason";
import { searchTeams } from "../api/analyst-leagues/teams/searchTeams";
import { deleteTeam } from "../api/analyst-leagues/teams/deleteTeam";
import { addReferee } from "../api/analyst-leagues/referee/addReferee";
import { deleteReferee } from "../api/analyst-leagues/referee/deleteReferee";
import { getPlayers } from "../api/analyst-leagues/players/getPlayers";
import { deletePlayer } from "../api/analyst-leagues/players/deletePlayer";
import { updatePlayer } from "../api/analyst-leagues/players/updatePlayer";
import { addNewStadiumToTeam } from "../api/analyst-leagues/stadiums/addNewStadiumToTeam";
import { updateTeamToNewStadium } from "../api/analyst-leagues/stadiums/updateTeamToNewStadium";
import { getStadiums } from "../api/analyst-leagues/stadiums/getStadiums";
import { addDerbyToTeam } from "../api/analyst-leagues/derby/addDerbyToTeam";
import { getDerbiesForTeam } from "../api/analyst-leagues/derby/getDerbies";
import { getTeamDetails } from "../api/analyst-leagues/teams/getTeamDetails";
import { moveTeam } from "../api/analyst-leagues/teams/moveTeam";
import { searchLeague } from "../api/analyst-leagues/leagues/searchLeague";
import { removeOtherTeamsFromStadium } from "../api/analyst-leagues/stadiums/removeOtherTeams";
import { updateStadium } from "../api/analyst-leagues/stadiums/updateStadium";
import { getLeagueById } from "../api/analyst-leagues/leagues/getLeagueById";
import { searchReferee } from "../api/analyst-leagues/referee/searchReferee";
import { addPlayerToTeam } from "../api/analyst-leagues/players/addPlayer";
import { addPenaltyTakers } from "../api/analyst-leagues/teams/addPenaltyTaker";
import { searchPlayer } from "../api/analyst-leagues/players/searchPlayer";
import { removeStadium } from "../api/analyst-leagues/stadiums/removeStadium";
import { repopulateTeamsWithPlayers } from "../api/analyst-leagues/teams/repopulateTeams";
import { addNewPlayers } from "../api/analyst-leagues/teams/addNewPlayers";
import { transferPlayer } from "../api/analyst-leagues/players/transferPlayer";
import { editSeasonAPI } from "../api/analyst-leagues/season/editSeason";
import { archiveSeasonAPI } from "../api/analyst-leagues/season/archiveSeason";
import { createNewCupAPI } from "../api/analyst-leagues/cups/createCup";
import { updateCupAPI } from "../api/analyst-leagues/cups/updateCup";
import { archiveUnarchiveCupAPI } from "../api/analyst-leagues/cups/archiveUnArchiveCup";
import { getCupsCompeitionsAPI } from "../api/analyst-leagues/cups/getCupsCompetitions";
import { getCupsAndFilterAPI, getCupsForAdminAPICall } from "../api/analyst-leagues/cups/getCupsAndFilter";
import { getSeasonsAndCupsAPI } from "../api/analyst-leagues/seasonsAndCups/getSeasonsAndCups";
import { addCupRefereeAPI } from "../api/analyst-leagues/cups/referee/addReferee";
import { deleteCupRefereeAPI } from "../api/analyst-leagues/cups/referee/deleteReferee";
import { searchCupRefereeAPI } from "../api/analyst-leagues/cups/referee/searchReferee";
import { getLeaguesForTeamsAPI } from "../api/analyst-leagues/seasonsAndCups/getLeaguesForTeam";
import { getCupsForTeamAPI } from "../api/analyst-leagues/seasonsAndCups/getCupsForTeam";
import { getAvailableTeamsForLeagueAPI } from "../api/analyst-leagues/teams/availableTeamsForLeague";
import { addTeamToLeagueAPI } from "../api/analyst-leagues/leagues/addTeamToLeague";
import { undoTransferPlayerAPI } from "../api/analyst-leagues/players/undoTransferPlayer";
import { getTransferedPlayersAPI } from "../api/analyst-leagues/players/getTransferedPlayers";
import { getDeletedPlayersAPI } from "../api/analyst-leagues/players/getDeteletedPlayers";
import { undoDeletePlayerAPI } from "../api/analyst-leagues/players/undeDeletePlayer";
import { undoDeleteTeamAPI } from "../api/analyst-leagues/teams/undoDeleteTeam";
import { getDeletedTeamsForLeaagueAPI } from "../api/analyst-leagues/leagues/getDeletedTeamsForLeague";
import { undoDeleteLeagueAPI } from "../api/analyst-leagues/leagues/undoDeleteLeague";
import { getDeletedLeaguesForSeasonAPI } from "../api/analyst-leagues/season/getDeletedLeaguesForSeason";
import { getDeletedTeamsForCupAPI } from "../api/analyst-leagues/cups/teams/getDeletedTeamForCup";
import { removeTeamFromCupAPI } from "../api/analyst-leagues/cups/teams/removeTeamFromCup";
import { undoRemoveTeamFromCupAPI } from "../api/analyst-leagues/cups/teams/undoRemoveTeamFromCup";
import { getTeamsFromLeaguesInValidCupsAPI } from "../api/analyst-leagues/seasonsAndCups/getTeamsFromValidCups";
import { getTeamsUsedInValidCupsAPI } from "../api/analyst-leagues/seasonsAndCups/getTEamsUsedInvalidCups";
import { addLeagueToSeasonAPI } from "../api/analyst-leagues/leagues/addLeagueToSeason";
import { repopulateShortNamesAPI } from "../api/analyst-leagues/teams/repopulateShortNames";
import { addParticipantAPICall, searchParticipantAPICall } from "../api/analyst-leagues/leagues/participantCalls";
import { getLastConfirmedAttendanceApiCall } from "../api/matchPreview/getLastConfirmedAttendance";
import { searchCupRefereeToAddAPICall } from "../api/analyst-leagues/cups/referee/searchRefereeForAdd";
import { searchLeagueRefereeToAddAPICall } from "../api/analyst-leagues/referee/searchRefereeForAdd";
import { repopulateOtherNamesAPI } from "../api/analyst-leagues/teams/repopulateOtherPlayerNames";

export const LeaguesDataServiceContext = createContext({
  leagueToEdit: [],
  teamsList: [],
  setTeamsList: [],
  get_leagues: () => { },
  get_league_by_id: () => { },
  search_league: () => { },
  get_deleted_leagues_for_season: () => { },
  edit_league: () => { },
  delete_league: () => { },
  add_league_to_season: () => { },
  undo_delete_league: () => { },
  get_seasons: () => { },
  create_new_season: () => { },
  get_team_details: () => { },
  get_deleted_teams_for_league: () => { },
  search_teams: () => { },
  move_team: () => { },
  add_penalty_takers: () => { },
  delete_team: () => { },
  undo_delete_team: () => { },
  add_referee: () => { },
  search_referee: () => { },
  search_referee_to_add_league: () => { },
  search_referee_to_add_cup: () => { },
  delete_referee: () => { },
  get_players_for_team: () => { },
  get_deleted_players_for_team: () => { },
  get_transferred_players_for_team: () => { },
  delete_player: () => { },
  update_player: () => { },
  transfer_player: () => { },
  undo_transfer_player: () => { },
  undo_delete_player: () => { },
  repopulate_team_with_players: () => { },
  repopulate_other_names: () => { },
  add_player: () => { },
  search_player: () => { },
  add_new_players: () => { },
  repopulate_short_names: () => { },
  update_team_to_new_stadium: () => { },
  get_stadiums: () => { },
  add_stadium_to_team: () => { },
  update_stadium: () => { },
  add_derby_to_team: () => { },
  get_derbies_for_team: () => { },
  setLeagueToEdit: () => { },
  remove_other_teams_from_stadium: () => { },
  remove_team_from_stadium: () => { },
  edit_season: () => { },
  archive_season: () => { },
  create_new_cup: () => { },
  update_cup: () => { },
  archive_unarchive_cup: () => { },
  get_cups_and_filter: () => { },
  get_cup_competitions: () => { },
  get_seasons_and_cups: () => { },
  delete_cup_referee: () => { },
  search_cup_referee: () => { },
  add_cup_referee: () => { },
  get_leagues_for_teams: () => { },
  get_cups_for_teams: () => { },
  get_available_teams_for_league: () => { },
  add_team_to_league: () => { },
  get_deleted_teams_for_cup: () => { },
  remove_team_from_cup: () => { },
  undo_remove_team_from_cup: () => { },

  get_teams_from_leagues_in_valid_cups: () => { },
  get_teams_used_in_valid_cups: () => { },

  add_participant: () => { },
  search_participant: () => { },

  get_last_home_confirmed_attendance: () => { },
  get_seasons_for_admin: () => { },
  get_cups_for_admin: () => { },


});

const LeaguesServiceProvider = ({ children }) => {
  const [leagueToEdit, setLeagueToEdit] = useState();
  const [teamsList, setTeamsList] = useState([]);

  //seasons and cups ->

  const get_seasons_and_cups = useCallback(
    async (archived, term, callbackFunction, callbackError) => {
      try {
        getSeasonsAndCupsAPI(
          archived,
          term,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_leagues_for_teams = useCallback(
    async (teamId, callbackFunction, callbackError) => {
      try {
        getLeaguesForTeamsAPI(
          teamId,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_teams_from_leagues_in_valid_cups = useCallback(
    async (league_ids, callbackFunction, callbackError) => {
      try {
        getTeamsFromLeaguesInValidCupsAPI(
          league_ids,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_teams_used_in_valid_cups = useCallback(
    async (team_id, callbackFunction, callbackError) => {
      try {
        getTeamsUsedInValidCupsAPI(
          team_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_cups_for_teams = useCallback(
    async (archived, term, callbackFunction, callbackError) => {
      try {
        getCupsForTeamAPI(
          archived,
          term,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );
  // season calls ->
  const create_new_season = useCallback(
    async (
      competition_ids,
      name,
      startDate,
      endDate,
      callbackFunction,
      callbackError
    ) => {
      try {
        createNewSeason(
          competition_ids,
          name,
          startDate,
          endDate,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const edit_season = useCallback(
    async (id, name, startDate, endDate, callbackFunction, callbackError) => {
      try {
        editSeasonAPI(
          id,
          name,
          startDate,
          endDate,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const archive_season = useCallback(
    async (id, name, callbackFunction, callbackError) => {
      try {
        archiveSeasonAPI(
          id,
          name,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_seasons = useCallback(
    async (data, callbackFunction, callbackError) => {
      try {
        await getSeasons(
          data,
          function (response) {
            callbackFunction(response.data.seasons);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_seasons_for_admin = useCallback(
    async (data, callbackFunction, callbackError) => {
      try {
        await getSeasonsForAdminAPICall(
          data,
          function (response) {
            callbackFunction(response.data.seasons);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  // cups calls ->
  const create_new_cup = useCallback(
    async (data, callbackFunction, callbackError) => {
      try {
        createNewCupAPI(
          data,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const update_cup = useCallback(
    async (data, callbackFunction, callbackError) => {
      try {
        updateCupAPI(
          data,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_deleted_teams_for_cup = useCallback(
    async (cup_id, callbackFunction, callbackError) => {
      try {
        getDeletedTeamsForCupAPI(
          cup_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const archive_unarchive_cup = useCallback(
    async (id, archived, callbackFunction, callbackError) => {
      try {
        archiveUnarchiveCupAPI(
          id,
          archived,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const remove_team_from_cup = useCallback(
    async (team_id, cup_id, callbackFunction, callbackError) => {
      try {
        removeTeamFromCupAPI(
          team_id,
          cup_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const undo_remove_team_from_cup = useCallback(
    async (team_id, cup_id, callbackFunction, callbackError) => {
      try {
        undoRemoveTeamFromCupAPI(
          team_id,
          cup_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_cups_and_filter = useCallback(
    async (data, callbackFunction, callbackError) => {
      try {
        getCupsAndFilterAPI(
          data,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_cups_for_admin = useCallback(
    async (data, callbackFunction, callbackError) => {
      try {
        getCupsForAdminAPICall(
          data,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_cup_competitions = useCallback(
    async (term, callbackFunction, callbackError) => {
      try {
        getCupsCompeitionsAPI(
          term,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );
  // cup referee calls->
  const delete_cup_referee = useCallback(
    async (refereeID, callbackFunction, callbackError) => {
      try {
        deleteCupRefereeAPI(
          refereeID,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );
  const search_cup_referee = useCallback(
    async (term, cupId, callbackFunction, callbackError) => {
      try {
        searchCupRefereeAPI(
          term,
          cupId,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );
  const add_cup_referee = useCallback(
    async (data, cupId, callbackFunction, callbackError) => {
      try {
        addCupRefereeAPI(
          data,
          cupId,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  // league calls ->
  const get_leagues = useCallback(
    async (
      season_ids,
      cup_ids,
      country_ids,
      league_ids,
      term,
      archived_teams,
      callbackFunction,
      callbackError
    ) => {
      try {
        await getLeaguesForCurrentUser(
          season_ids,
          cup_ids,
          country_ids,
          league_ids,
          term,
          archived_teams,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_league_by_id = useCallback(
    async (league_id, callbackFunction, callbackError) => {
      try {
        await getLeagueById(
          league_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const search_league = useCallback(
    async (season_ids, country_ids, name, callbackFunction, callbackError) => {
      try {
        await searchLeague(
          season_ids,
          country_ids,
          name,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (err) {
        callbackError(err);
      }
    }
  );

  const get_deleted_leagues_for_season = useCallback(
    async (season_id, callbackFunction, callbackError) => {
      try {
        await getDeletedLeaguesForSeasonAPI(
          season_id,
          function (response) {
            callbackFunction(response.data);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (err) {
        callbackError(err);
      }
    }
  );

  const edit_league = useCallback(
    async (league_id, name, tier, callbackFunction, callbackError) => {
      try {
        await editLeague(
          league_id,
          name,
          tier,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const delete_league = useCallback(
    async (league_id, callbackFunction, callbackError) => {
      try {
        await deleteLeague(
          league_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const add_league_to_season = useCallback(
    async (season_id, competition_id, callbackFunction, callbackError) => {
      try {
        await addLeagueToSeasonAPI(
          season_id,
          competition_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const undo_delete_league = useCallback(
    async (league_id, callbackFunction, callbackError) => {
      try {
        await undoDeleteLeagueAPI(
          league_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const add_team_to_league = useCallback(
    async (
      league_id,
      participant_id,
      wy_id,
      legacy_participant_id,
      callbackFunction,
      callbackError
    ) => {
      try {
        await addTeamToLeagueAPI(
          league_id,
          participant_id,
          wy_id,
          legacy_participant_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  // team calls ->
  const get_team_details = useCallback(
    async (team_id, callbackFunction, callbackError) => {
      try {
        await getTeamDetails(
          team_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_deleted_teams_for_league = useCallback(
    async (team_id, callbackFunction, callbackError) => {
      try {
        await getDeletedTeamsForLeaagueAPI(
          team_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const search_teams = useCallback(
    async (name, season_id, callbackFunction, callbackError) => {
      try {
        await searchTeams(
          name,
          season_id,
          function (response) {
            let sorted = response.data.teams.sort((a, b) =>
              a.name > b.name ? 1 : -1
            );
            setTeamsList(sorted);
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const move_team = useCallback(
    async (team_id, new_league_id, callbackFunction, callbackError) => {
      try {
        await moveTeam(
          team_id,
          new_league_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const add_penalty_takers = useCallback(
    async (
      team_id,
      first_penalty_taker_id,
      second_penalty_taker_id,
      third_penalty_taker_id,
      callbackFunction,
      callbackError
    ) => {
      try {
        await addPenaltyTakers(
          team_id,
          first_penalty_taker_id,
          second_penalty_taker_id,
          third_penalty_taker_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const delete_team = useCallback(
    async (team_id, callbackFunction, callbackError) => {
      try {
        await deleteTeam(
          team_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const undo_delete_team = useCallback(
    async (team_id, callbackFunction, callbackError) => {
      try {
        await undoDeleteTeamAPI(
          team_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const add_derby_to_team = useCallback(
    async (team_id, derbies, callbackFunction, callbackError) => {
      try {
        await addDerbyToTeam(
          team_id,
          derbies,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_derbies_for_team = useCallback(
    async (team_id, callbackFunction, callbackError) => {
      try {
        await getDerbiesForTeam(
          team_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_available_teams_for_league = useCallback(
    async (league_id, callbackFunction, callbackError) => {
      try {
        await getAvailableTeamsForLeagueAPI(
          league_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  // referee calls ->
  const add_referee = useCallback(
    async (leagueId, data, callbackFunction, callbackError) => {
      try {
        await addReferee(
          leagueId,
          data,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const search_referee = useCallback(
    async (leagueId, term, callbackFunction, callbackError) => {
      try {
        await searchReferee(
          leagueId,
          term,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const search_referee_to_add_league = useCallback(
    async (leagueId, term, callbackFunction, callbackError) => {
      try {
        await searchLeagueRefereeToAddAPICall(
          leagueId,
          term,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );
  const search_referee_to_add_cup = useCallback(
    async (leagueId, term, callbackFunction, callbackError) => {
      try {
        await searchCupRefereeToAddAPICall(
          leagueId,
          term,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const delete_referee = useCallback(
    async (referee_id, callbackFunction, callbackError) => {
      try {
        await deleteReferee(
          referee_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  // players calls ->
  const get_players_for_team = useCallback(
    async (team_id, callbackFunction, callbackError) => {
      try {
        await getPlayers(
          team_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_deleted_players_for_team = useCallback(
    async (team_id, callbackFunction, callbackError) => {
      try {
        await getDeletedPlayersAPI(
          team_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_transferred_players_for_team = useCallback(
    async (team_id, callbackFunction, callbackError) => {
      try {
        await getTransferedPlayersAPI(
          team_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const transfer_player = useCallback(
    async (id, callbackFunction, callbackError) => {
      try {
        await transferPlayer(
          id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const undo_transfer_player = useCallback(
    async (id, callbackFunction, callbackError) => {
      try {
        await undoTransferPlayerAPI(
          id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const undo_delete_player = useCallback(
    async (id, callbackFunction, callbackError) => {
      try {
        await undoDeletePlayerAPI(
          id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const delete_player = useCallback(
    async (player_id, callbackFunction, callbackError) => {
      try {
        await deletePlayer(
          player_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const add_player = useCallback(
    async (
      team_id,
      first_name,
      last_name,
      short_name,
      other_player_name,
      squad_number,
      position_1,
      position_2,
      position_3,
      wy_id,
      callbackFunction,
      callbackError
    ) => {
      try {
        await addPlayerToTeam(
          team_id,
          first_name,
          last_name,
          short_name,
          other_player_name,
          squad_number,
          position_1,
          position_2,
          position_3,
          wy_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const update_player = useCallback(
    async (player_id, data, callbackFunction, callbackError) => {
      try {
        await updatePlayer(
          player_id,
          data,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError();
      }
    }
  );

  const repopulate_team_with_players = useCallback(
    async (team_id, callbackFunction, callbackError) => {
      try {
        await repopulateTeamsWithPlayers(
          team_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const add_new_players = useCallback(
    async (team_id, callbackFunction, callbackError) => {
      try {
        await addNewPlayers(
          team_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const repopulate_short_names = useCallback(
    async (team_id, callbackFunction, callbackError) => {
      try {
        await repopulateShortNamesAPI(
          team_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );
  const repopulate_other_names = useCallback(
    async (team_id, callbackFunction, callbackError) => {
      try {
        await repopulateOtherNamesAPI(
          team_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const search_player = useCallback(
    async (term, callbackFunction, callbackError) => {
      try {
        await searchPlayer(
          term,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  // stadium calls ->
  const add_stadium_to_team = useCallback(
    async (
      team_id,
      name,
      capacity,
      length,
      width,
      surface,
      location,
      lat,
      long,
      altitude,
      team_not_listed,
      other_sports,
      race_track,
      country_id,
      callbackFunction,
      callbackError
    ) => {
      try {
        await addNewStadiumToTeam(
          team_id,
          name,
          capacity,
          length,
          width,
          surface,
          location,
          lat,
          long,
          altitude,
          team_not_listed,
          other_sports,

          race_track,
          country_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const update_team_to_new_stadium = useCallback(
    async (team_id, new_stadium_id, callbackFunction, callbackError) => {
      try {
        await updateTeamToNewStadium(
          team_id,
          new_stadium_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_stadiums = useCallback(async (callbackFunction, callbackError) => {
    try {
      await getStadiums(
        function (response) {
          // setStadiums(response.data.stadiums);
          callbackFunction(response.data.stadiums);
        },
        function (error) {
          callbackError(error);
        }
      );
    } catch (error) {
      callbackError(error);
    }
  });

  const update_stadium = useCallback(
    async (
      stadium_id,
      name,
      capacity,
      length,
      width,
      surface,
      location,
      latitude,
      longitude,
      altitude,
      team_not_listed,
      other_sports,
      race_track,
      country_id,
      callbackFunction,
      callbackError
    ) => {
      try {
        await updateStadium(
          stadium_id,
          name,
          capacity,
          length,
          width,
          surface,
          location,
          latitude,
          longitude,
          altitude,
          team_not_listed,
          other_sports,

          race_track,
          country_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const remove_other_teams_from_stadium = useCallback(
    async (team_id, stadium_id, callbackFunction, callbackError) => {
      try {
        await removeOtherTeamsFromStadium(
          team_id,
          stadium_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const remove_team_from_stadium = useCallback(
    async (team_id, callbackFunction, callbackError) => {
      try {
        await removeStadium(
          team_id,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const search_participant = useCallback(
    async (name, callbackFunction, callbackError) => {
      try {
        await searchParticipantAPICall(
          name,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const add_participant = useCallback(
    async (leadugeId, participantId, callbackFunction, callbackError) => {
      try {
        await addParticipantAPICall(
          leadugeId,
          participantId,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const get_last_home_confirmed_attendance = useCallback(
    async (teamId, fixtureId, callbackFunction, callbackError) => {
      try {
        await getLastConfirmedAttendanceApiCall(
          teamId,
          fixtureId,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  return (
    <LeaguesDataServiceContext.Provider
      value={{
        teamsList,
        setTeamsList,
        leagueToEdit,
        get_leagues,
        get_league_by_id,
        search_league,
        get_deleted_leagues_for_season,
        get_deleted_teams_for_league,
        edit_league,
        delete_league,
        add_league_to_season,
        undo_delete_league,
        get_seasons,
        create_new_season,
        get_team_details,
        search_teams,
        move_team,
        delete_team,
        undo_delete_team,
        add_penalty_takers,
        add_referee,
        search_referee,
        delete_referee,
        get_players_for_team,
        get_deleted_players_for_team,
        get_transferred_players_for_team,
        delete_player,
        update_player,
        transfer_player,
        undo_transfer_player,
        undo_delete_player,
        repopulate_team_with_players,
        add_new_players,
        repopulate_short_names,
        add_player,
        search_player,
        get_stadiums,
        add_stadium_to_team,
        update_stadium,
        update_team_to_new_stadium,
        add_derby_to_team,
        get_derbies_for_team,
        remove_other_teams_from_stadium,
        setLeagueToEdit,
        remove_team_from_stadium,
        edit_season,
        archive_season,
        create_new_cup,
        update_cup,
        archive_unarchive_cup,
        get_cups_and_filter,
        get_cup_competitions,
        get_seasons_and_cups,
        delete_cup_referee,
        search_cup_referee,
        add_cup_referee,
        get_cups_for_teams,
        get_leagues_for_teams,
        get_available_teams_for_league,
        add_team_to_league,
        get_teams_from_leagues_in_valid_cups,
        get_teams_used_in_valid_cups,
        remove_team_from_cup,
        undo_remove_team_from_cup,
        get_deleted_teams_for_cup,
        search_participant,
        add_participant,
        get_last_home_confirmed_attendance,
        search_referee_to_add_league,
        search_referee_to_add_cup,
        get_cups_for_admin,
        get_seasons_for_admin,
        repopulate_other_names
      }}
    >
      {children}
    </LeaguesDataServiceContext.Provider>
  );
};
export const useLeaguesDataService = () =>
  useContext(LeaguesDataServiceContext);
export default LeaguesServiceProvider;
