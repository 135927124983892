import { POST } from "../APiHelper/API";
import { formatted_date_for_backend } from "../../helper/Generic/dateTimeFormat";

let source = null;
export const get_bets_listIds = (start_time, end_time, onSuccess, onError) => {
  const data = {
    start_time: formatted_date_for_backend(start_time),
    end_time: formatted_date_for_backend(end_time),
  };

  source = POST("/api/v1/bets/list_ids", data, onSuccess, onError, true, source);
};
