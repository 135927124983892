import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";
import { ReactComponent as RemoveIcon } from "../assets/icons/LeaguesIcons/removeBin.svg";
import { ReactComponent as BinSvg } from "../assets/icons/bin.svg";
import { ReactComponent as RemoveTeamFromCup } from "../assets/icons/check/remove.svg";

const DeleteConfirmation = ({ handleRemoveItem, item, variant, disableButton }) => {
  const [open, setOpen] = React.useState(false);
  const LEAGUE = "league";
  const CHAT = 'chat';
  const TEAM_FROM_CUP = "team_from_cup"

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    handleRemoveItem(item);
    setOpen(false);
  };

  const DisplayVariant = ({ variant }) => {
    let displayItem = (
      <DeleteButton onClick={() => handleClickOpen()} disabled={disableButton}>
        <RemoveIcon />
      </DeleteButton>
    )

    if (variant == LEAGUE) {
      displayItem = (
        <DeleteLeagueBtn onClick={() => handleClickOpen()}>
          <BinSvg />
          Delete league
        </DeleteLeagueBtn>)
    }
    if (variant == CHAT) {
      displayItem = (
        <DeleteMessageButton onClick={() => handleClickOpen()} disabled={disableButton}>
          <BinSvg />
        </DeleteMessageButton>)
    }

    if (variant == TEAM_FROM_CUP) {
      displayItem = (
        <DeleteTeamFromCupBtn onClick={() => handleClickOpen()} disabled={disableButton}>
          <RemoveTeamFromCup />
        </DeleteTeamFromCupBtn>)
    }


    return (
      displayItem
    )
  }
  const DisplayDialogVariant = ({ variant }) => {
    let displayDialog = (<DialogContentText id="alert-dialog-description">
      Are you sure you want to remove this item?
    </DialogContentText>)
    if (variant == CHAT) {
      displayDialog = (<DialogContentText id="alert-dialog-description">
        Are you sure you want to remove this message?
      </DialogContentText>)
    }

    return displayDialog
  }

  return (
    (<div>
      <DisplayVariant variant={variant} />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
        <DialogContent>
          <DisplayDialogVariant variant={variant} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button style={{ color: "red" }} onClick={handleRemove} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>)


  );
};

export default DeleteConfirmation;

const DeleteButton = styled.button`
  background:  none;
  border:  none;
  cursor:  pointer;
  width:  40px;
  height:  40px;
display:flex;
align-items:center;
justify-content:center;
  svg{
    width:40px;
    height:40px;
  }
  :disabled{
    cursor:inherit;
    opacity:0.5;
  }
`;
const DeleteLeagueBtn = styled.button`
  display: flex;
  height: 40px;
  align-items: center;
  column-gap: 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ec4848;
`;
const DeleteMessageButton = styled.button`
background:  none;
border:  none;
cursor:  pointer;
width:  12px;
height:  12px;
svg{
  width:12px;
  height:12px;
}
:disabled{
  cursor:inherit;
  opacity:0.5;
}
`
const DeleteTeamFromCupBtn = styled.button`

  cursor: pointer;
  border: none;
  background-color: transparent;
  svg {
    height: 18px;
    width: 18px;
  }

`

const ConfirmationResetSquad = styled.div`
display:flex;
padding:6px 10px;
align-items:center;
border-radius:3px;
border:none;
gap:5px;
p{
  font-family: Roboto;
font-size: 10px;
font-weight: 400;
line-height: 12px;

color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
}
svg{
  stroke:${({ theme }) => theme.squadManager.textColor};
}
`