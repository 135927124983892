import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightTheme } from "../../../theme/theme";
import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";
import { ReactComponent as SearchSvg } from "../../../assets/icons/PricingMenuIcons/search.svg";

const SeasonMenuForNewEvent = ({ label, dataList, value, setValue }) => {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [filterTerm, setFilterTerm] = useState("");

  const handleCheck = (item) => {
    setValue(item);
    setIsMenuOpen(false)
    setFilterTerm("")
  };

  const findSelectedSeasonName = (season_id) => {
    let found = dataList.find((item) => item.id == season_id);
    if (found) {
      return found.name;
    }
    else {
      return "Chose season";
    }
  };

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };


  const filterSearch = (items, filter) => {
    if (filter === "") {
      return items;
    } else {
      return items.filter((item) =>
        item.name.toLowerCase().includes(filter.toLowerCase())
      );
    }
  };


  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref}>
      <DropDownMenu onClick={() => handleMenuOpen()} isMenuOpen={isMenuOpen}>
        <p>{findSelectedSeasonName(value)}</p>
        {isMenuOpen ? <UpSvg /> : <DownSvg />}
      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>
          <DropDownMenuItem>
            <FilterByKey>
              <SearchSvg />
              <input
                placeholder="Search"
                value={filterTerm}
                onChange={(e) => setFilterTerm(e.currentTarget.value)}
              ></input>
            </FilterByKey>
          </DropDownMenuItem>
          {filterSearch(dataList, filterTerm)?.map((item, index) => (
            <DropDownMenuItem key={index} onClick={(e) => handleCheck(item.id)}>
              <div className={item.id === value ? "active" : "nonActive"}>
                <input
                  key={index}
                  readOnly
                  className="check"
                  type="radio"
                  checked={value == item.id}
                  value={item}
                ></input>
                <p> {item.name}</p>
              </div>
            </DropDownMenuItem>
          ))}
        </DropDownList>
      )}
    </Container>
  );
};


export default SeasonMenuForNewEvent;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
min-width:240px;

  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: ${({ theme }) => theme.dropDownColor};
  }
`;
const DropDownMenu = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.editLeague.addEventModal.menuBg};
    padding: 10px;
    border-radius: 8px;
    border-radius: ${(props) => props.isMenuOpen ? "8px 8px 0 0" : ""} ;

    p{
      margin-right:5px;
      width:100%;
      overflow:hidden;
      text-overflow:elipsis;
      white-space:nowrap;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
    }
    svg {
      cursor: pointer;
      fill: ${({ theme }) => theme.dropDownSvgFill};
      stroke: ${({ theme }) => theme.dropDownSvgStroke};
    }
  `;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top:36px;
  border-top: 2px solid;
  border-color: ${({ theme }) => (theme === lightTheme ? "#3C4A5C" : "white")};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  max-height: 250px; 
  width:100%;
  overflow-x: hidden;
  color: black;
   
  .searchGroup {
    position: relative;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.menuTextColor};
    input {
      align-items: center;
      position: relative;
      width: 100%;
      padding: 5px 10px 5px 20px;
      background: ${({ theme }) => theme.leagueBG};
      border: none;
      border: 1px solid #d9dfe7;
      border-radius: 8px;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      :focus-visible {
        outline: none;
        border: 1px solid ${({ theme }) => theme.dropDownSvgStroke};
      }
    }
    svg {
      position: absolute;
      z-index: 1;
      top: 7px;
      left: 7px;
    }
  }
`;
const DropDownMenuItem = styled.li`

  cursor: pointer;
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;
  background-color: ${({ theme }) => theme.editLeague.addEventModal.menuBg};
  padding: 5px 10px;
  div {
    column-gap: 5px;
    align-items: center;
    display: flex;
    flex-direction: row;
    .check {
      margin-right: 5px;
      background: red;
      color: red;
    }
  }
  p {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
  }
  .active {
    p {
      color: rgba(97, 131, 255, 1);
    }
  }
  .nonActive {
    :hover {
      p {
        color: ${({ theme }) => theme.buttonBG};
      }
    }
    p {
      color: ${({ theme }) => theme.dropDownColor};
    }
  }
`;

const FilterByKey = styled.div`
display:flex;
width:100%;

input{
  width:100%;
  background:none;
   border: 1px solid #d9dfe7;
   color: ${({ theme }) => theme.menuTextColor};
  padding:4px 10px 4px 25px;
   border-radius: 8px;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  :focus-visible {
    outline: none;
    border: 1px solid #006fff;
  }

  ::placeholder{
    color: ${({ theme }) => theme.menuTextColor};
    opaciy:0.5;
  }

}
svg {
  height:10px;
  left:20px;
  position: absolute;
  z-index:2;
  color: ${({ theme }) => theme.menuTextColor};
}
`