import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownSvg } from "../../../assets/icons/downSimpleArrow.svg";


const LtfMenu = ({ valuesList, selectedValue, setSelectedValue, width }) => {
    const ref = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleMenuOpen = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        } else {
            setIsMenuOpen(true);
        }
    };

    const handleCheck = (item) => {
        setSelectedValue(item);
        handleMenuOpen();
    };
    const parseClassName = (value) => {
        if (value == true) {
            return "green";
        }
        if (value == false) {
            return "red";
        }
    };
    const parsevalue = (value) => {
        if (value == true) {
            return "Yes";
        }
        if (value == false) {
            return "No";
        }
    };
    const checkColor = (value) => {
        if (value == true) {
            return "greenColor";
        }
        if (value == false) {
            return "redColor";
        }
    };
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    return (
        <Container ref={ref} width={width}>
            <DropDownMenu
                onClick={() => handleMenuOpen()}
                isMenuOpen={isMenuOpen}
                width={width}
                className={parseClassName(selectedValue)}
            >
                {/* <DropDownMenu onClick={() => alert("IMPLEMENT")} isMenuOpen={isMenuOpen}> */}
                {selectedValue != undefined ? <p>{parsevalue(selectedValue)}</p> : <p>-</p>}
                {isMenuOpen ? <DownSvg style={{ rotate: "180deg" }} /> : <DownSvg />}
            </DropDownMenu>
            {isMenuOpen && (
                <DropDownList>
                    {valuesList.map((item, index) => (
                        <DropDownMenuItem
                            key={index}
                            onClick={() => handleCheck(item.value)}
                            className={item === selectedValue ? "active" : "nonActive"}
                        >
                            <input
                                key={index}
                                readOnly
                                onChange={() => handleCheck(item.value)}
                                className="check"
                                type="radio"
                                checked={selectedValue == item.value}
                                value={item.value}
                            ></input>
                            <p className={checkColor(item.value)}>{parsevalue(item.value)}</p>
                        </DropDownMenuItem>
                    ))}
                </DropDownList>
            )}
        </Container>
    );
};

export default LtfMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width != null ? props.width : "90px")};
  position: relative;
    cursor:pointer;
.greenColor{
    color: #47B77B;
}
.redColor{
    color: #EC4848;
}
`;
const DropDownMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 2px 5px;
  border: 1px solid #cbd7f5;
  border-radius: 2px;
  border-radius: ${(props) => (props.isMenuOpen ? "4px 4px 0 0" : "")};
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 150%;
    /* or 15px */
    display: flex;
    align-items: center;
  }
  svg{
    color:#006fff;
  }

`;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top: 20px;
 border-left:1px solid;
  border-bottom:1px solid;
  border-right:1px solid;
  border-color: #CBD7F5;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 4px 4px;
  max-height: 400px;
  width: 100%;
  overflow-x: hidden;
  color: black;
`;
const DropDownMenuItem = styled.li`
  cursor: pointer;
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
    :first-child{
    border-top: 1px solid black;
  }
  list-style-type: none;
  padding: 2px 0px;
  .check {
    height: 10px;
  }
  background-color: #ffffff;
  align-items: center;
  display: flex;
  flex-direction: row;
  column-gap:3px;
  p {
    font-weight: 500;
    font-size: 8px;
    line-height: 8px;
    color: var(--darkBlue);
  }
  
`;
