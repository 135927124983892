import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as UpSvg } from "../../../assets/icons/DropDownMenuIcons/up.svg";
import { ReactComponent as DownSvg } from "../../../assets/icons/DropDownMenuIcons/down.svg";

const PlayerNameDisplayMenu = ({ valuesList, value, handleNameOptionsMenu }) => {

    const ref = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuOpen = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        } else {
            setIsMenuOpen(true);
        }
    };

    const handleItemClick = (e) => {
        handleNameOptionsMenu(e);
        handleMenuOpen();
    };

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    const mapValues = (value) => {
        let display = "First name, last name"

        if (value == "fullName") {
            display = "First name, last name"
        }
        if (value == "shortName") {
            display = "Short names"
        }
        if (value == "otherName") {
            display = "Other name"
        }

        return display
    }

    return (
        <Container ref={ref}>
            <Menu onClick={() => handleMenuOpen()}>
                <p>{mapValues(value)}</p>
                {!isMenuOpen ? <DownSvg /> : <UpSvg />}
            </Menu>
            {isMenuOpen && (
                <MenuList>
                    {valuesList?.map((item, index) => (
                        <ListItem key={index} onClick={() => handleItemClick(item.value)}>
                            <p>{item.label}</p>
                        </ListItem>
                    ))}
                </MenuList>
            )}
        </Container>
    );
};

export default PlayerNameDisplayMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #d9dfe7;
  border-radius: 4px;

  position: relative;
`;
const Menu = styled.div`
  display: flex;
  padding: 16px 16px;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--darkBlue);
  }
  svg {
    cursor: pointer;
  }
`;
const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 51px;
  border: 1px solid #d9dfe7;
  max-height: 400px;
  overflow: auto;
  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
const ListItem = styled.li`
  display: flex;
  flex-direction: column;

  cursor: pointer;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #d9dfe7;
  padding: 16px 16px;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--darkBlue);
  }
  :last-child {
    border-bottom: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
