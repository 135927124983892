import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightTheme } from "../../../theme/theme";
import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";

const DropdownMenuMatches = ({ allMatches, setAllMatches }) => {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleSelect = (e) => {
    setAllMatches(e);
    setIsMenuOpen(false);
  };

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref}>
      <DropDownMenu onClick={() => handleMenuOpen()} isMenuOpen={isMenuOpen}>
        {allMatches ? (
          <p>All Matches</p>
        ) : (
          <p>Favorites</p>
        )}

        {isMenuOpen ? <UpSvg /> : <DownSvg />}
      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>
          <DropDownMenuItem>
            <p
              className={
                allMatches === true ? "active" : "nonActive"
              }
              onClick={() => handleSelect(true)}
            >
              All matches
            </p>
          </DropDownMenuItem>
          <DropDownMenuItem>
            <p
              className={
                allMatches === false ? "active" : "nonActive"
              }
              onClick={() => handleSelect(false)}
            >
              Favorites
            </p>
          </DropDownMenuItem>

        </DropDownList>
      )}
    </Container>
  );
};

export default DropdownMenuMatches;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 162px;
  position: relative;
  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: ${({ theme }) => theme.dropDownColor};
  }
`;
const DropDownMenu = styled.div`
position: relative;
display: flex;
align-items: center;
justify-content: space-between;
background-color: ${({ theme }) => theme.menuBgColor};
padding: 4px 10px;
border-radius: 8px;
border-radius: ${props => props.isMenuOpen ? "8px 8px 0 0" : ""};

svg {
  cursor: pointer;
  fill: ${({ theme }) => theme.dropDownSvgFill};
  stroke: ${({ theme }) => theme.dropDownSvgStroke};
}
`;
const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top: 28px;
  border-top: 2px solid;
  border-color: ${({ theme }) => (theme === lightTheme ? "#3C4A5C" : "white")};
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  color: black;
  scrollbar-width: 10px;
  max-height: 200px;
  overflow-x: hidden;
`;
const DropDownMenuItem = styled.li`
  cursor: pointer;
  width: 162px;
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;
  background-color: ${({ theme }) => theme.dropDownBG};
  color: black;
  padding: 5px 10px;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.dropDownColor};
    :hover {
      color: ${({ theme }) => theme.buttonBG};
    }
  }
  .active {
    color: rgba(97, 131, 255, 1);
  }
  .nonActive {
    :hover {
      color: ${({ theme }) => theme.buttonBG};
    }

    color: ${({ theme }) => theme.dropDownColor};
  }
`;
