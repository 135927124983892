import {
    percentageChange,
    findBookMakers,
    get_singbet_price,
} from "../ModelDataHelper";

export const mapRsObject = (rsBets, betsPrices) => {
    let listData = [];
    let data = {};
    let markets = [];
    rsBets?.forEach((bet) => {

        let bookmakers = null;
        let singprice = null;

        if (betsPrices != undefined && betsPrices.length > 0) {
            let price = betsPrices?.find((element) => {
                return element.fixture_id === bet.bet.fixture_id;
            });

            markets = price?.markets.filter((marketPrice) => {
                return marketPrice.market_id == bet.bet.market_id;
            });

            if (price !== null && price !== undefined) {
                let marketId = bet.bet.market_id;
                bookmakers = findBookMakers(price.markets, marketId);
                singprice = get_singbet_price(price.markets, marketId);

            }
        }

        data = {
            bet: bet.bet,
            current_status: bet.current_status,
            runshop: bet.runshop,
            markets: markets,
            market_value: null,
        };

        data.bookmakers = bookmakers;

        if (singprice != null) {
            const l_model_price = bet.bet.model_price;
            const l_model_min_price = bet.min_price;

            data.market_price = parseFloat(singprice);

            const mkt_value = percentageChange(l_model_price, singprice);

            if (mkt_value != undefined) {
                data.market_value = parseFloat(mkt_value);
            }
        }
        listData.push(data);
    });
    return listData;
};
