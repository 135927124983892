import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as CheckSvg } from "../../../assets/icons/check/check.svg";
import { ReactComponent as PlusSvg } from "../../../assets/icons/check/plus.svg";
import { ATK_POSITIONS, DEF_POSITIONS, GK_POSITIONS, MID_POSITIONS } from '../../../helper/Values/TeamFormation';
import { ReactComponent as SearchSvg } from "../../../assets/icons/PricingMenuIcons/search.svg";

const PositionMenuGroupPLayers = ({ selectedValue, setSelectedValue }) => {

    const ref = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [filterTerm, setFilterTerm] = useState("");

    const mapCategoryType = (category) => {
        let categoryType = {
            bg: "",
            color: ""
        };
        switch (category) {
            case "gk":
                categoryType = {
                    bg: ({ theme }) => theme.playerPerformaceCard.gk.bg,
                    color: ({ theme }) => theme.playerPerformaceCard.gk.color,
                }
                break;
            case "neutral":
                categoryType = {
                    bg: ({ theme }) => theme.playerPerformaceCard.neutral.bg,
                    color: ({ theme }) => theme.playerPerformaceCard.neutral.color,
                }
                break;
            case "defensive":
                categoryType = {
                    bg: ({ theme }) => theme.playerPerformaceCard.defensive.bg,
                    color: ({ theme }) => theme.playerPerformaceCard.defensive.color,
                }
                break;
            case "attack":
                categoryType = {
                    bg: ({ theme }) => theme.playerPerformaceCard.attack.bg,
                    color: ({ theme }) => theme.playerPerformaceCard.attack.color,
                }
                break;

            default:
                break;
        }
        return categoryType
    }

    const handleSelectPositions = (type, position) => {
        let found = selectedValue.find((item) => item.position === position)
        if (!found) {
            setSelectedValue((prev) => {
                return [...prev, {
                    type: type,
                    position: position
                }]
            })
        }
    }

    const handleClick = (type, position) => {
        handleSelectPositions(type, position);
    }

    const positionsObject = [
        {
            type: 'gk',
            positions: GK_POSITIONS
        },
        {
            type: 'defensive',
            positions: DEF_POSITIONS
        },
        {
            type: 'neutral',
            positions: MID_POSITIONS
        },
        {
            type: 'attack',
            positions: ATK_POSITIONS
        },
    ]


    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);


    const filterPositionObject = (positionsObject, term) => {
        if (term === "") {
            return positionsObject
        }

        let newObj = [];
        positionsObject.forEach(element => {
            let filtered = element.positions.filter((position) => position.toLowerCase().includes(term.toLowerCase()))
            if (filtered.length > 0) {
                newObj.push({
                    type: element.type,
                    positions: filtered
                })
            }
        });
        return newObj

    }


    return (
        <Container ref={ref}>
            <Menu onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <h1>Choose a position</h1>
                {isMenuOpen ? <DownSvg style={{ rotate: "180deg" }} /> : <DownSvg />}
            </Menu>
            {isMenuOpen &&
                <MenuList>
                    <MenuCategory style={{ padding: "7px 0" }}>
                        <FilterByKey>
                            <SearchSvg />
                            <input
                                placeholder="Search"
                                value={filterTerm}
                                onChange={(e) => setFilterTerm(e.currentTarget.value)}
                            ></input>
                        </FilterByKey>
                    </MenuCategory>
                    {
                        filterPositionObject(positionsObject, filterTerm).map((item) => (
                            <>
                                <MenuCategory type={mapCategoryType(item.type)}>
                                    <p type={mapCategoryType(item.type)}>{item.type}</p>
                                </MenuCategory>
                                {item.positions.map((position) => (
                                    <MenuItem>
                                        <p>{position}</p>
                                        <label className="checkContainer">
                                            <input
                                                checked={selectedValue?.find(element => element.position == position)}
                                                value={position}
                                                onClick={(e) => handleClick(item.type, position)}
                                                type="checkbox"
                                                readOnly
                                            ></input>
                                            {selectedValue?.find(element => element.position == position) ? <CheckSvg /> : <PlusSvg />}
                                        </label>
                                    </MenuItem>
                                ))}
                            </>

                        ))
                    }



                </MenuList>
            }


        </Container>
    )
}

export default PositionMenuGroupPLayers
const Container = styled.div`
display:flex;
flex-direction:column;
width:250px;
position:relative;
gap:20px;
`
const Menu = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
padding:10px;
background:${({ theme }) => theme.groupPlayersModal.menuBg};
border-radius:8px;
min-width:250px;
cursor:pointer;
h1{
font-size: 16px;
font-weight: 600;
line-height: 19px;
color:${({ theme }) => theme.groupPlayersModal.menuColor};
}
 svg {
    cursor: pointer;
    fill: ${({ theme }) => theme.dropDownSvgFill};
    stroke: ${({ theme }) => theme.dropDownSvgStroke};
  }
`
const MenuList = styled.div`
display:flex;
flex-direction:column;
padding:14px 10px;
background:${({ theme }) => theme.groupPlayersModal.menuListBg};
position:absolute;
width:100%;
border-radius:10px;
position:relative;
overflow:auto;
gap:5px;
`
const MenuItem = styled.div`
display:flex;
padding:7px 20px;
justify-content:space-between;
p{
    font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
color:${({ theme }) => theme.groupPlayersModal.menuListColor};
}
    svg {
        cursor:pointer;
      height: 14px;
      width: 18px;
      border-radius: 4px;
      fill:${({ theme }) => theme.groupPlayersModal.checkFill};
    }
  .checkContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .checkContainer input {
    display: none;
  }
  .checkContainer input:checked ~ svg {
    background: #ebf3ff;
    fill:var(--primaryBlue);
    border: 1px solid var(--primaryBlue);
  }
  .checkContainer svg {
    padding: 2px 3px;
    border: 1px solid ${({ theme }) => theme.groupPlayersModal.checkFill};
    border-radius: 2px;
    background: transparent;
    fill:${({ theme }) => theme.groupPlayersModal.checkFill};

`
const MenuCategory = styled.div`
display:flex;
padding:7px 20px;
border-radius:6px;

p{
font-size: 14px;
font-weight: 600;
line-height: 16px;
color:${(props) => props?.type?.color};
text-transform:uppercase;
}
background:${(props) => props?.type?.bg};

`

const FilterByKey = styled.div`
display:flex;
width:100%;

input{
  width:100%;
  background:none;
   border: 1px solid #d9dfe7;
  color: ${({ theme }) => theme.menuTextColor};
  padding:4px 10px 4px 25px;
   border-radius: 8px;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  :focus-visible {
    outline: none;
    border: 1px solid #006fff;
  }
    ::placeholder{
    color: ${({ theme }) => theme.menuTextColor};
    opaciy:0.5;
  }
}
svg {
  height:30px;
  left:20px;
  position: absolute;
  z-index:2;
    color: ${({ theme }) => theme.menuTextColor};
}
`