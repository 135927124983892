import axios from "axios";
import { Base64 } from "js-base64";

const URL = process.env.REACT_APP_API_URL;
const auth = `${process.env.REACT_APP_CLIENT_ID}:${process.env.REACT_APP_CLIENT_SECRET}`;
const encodedAuth = Base64.encode(auth);

var logoutConfig = (token) => {
  var config = {
    method: "post",
    url: `${URL}/oauth/revoke`,
    headers: {
      Authorization: `Basic ${encodedAuth}`,
      "Content-Type": "application/json",
    },
    data: {
      token: token,
    },
  };
  return config;
};

export const signOut = (token, onSuccess, onError) => {
  axios(logoutConfig(token))
    .then(function (response) {
      onSuccess(response);
    })
    .catch(function (error) {
      onError(error);
    });
};
