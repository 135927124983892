import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { getCurrentUserFixtures } from "../api/fixtures/CurrentUserFixtures";
import { getFavoritesFixture } from "../api/fixtures/FixtureFavorites";
import { getFixturePrices } from "../api/fixtures/GetFixturePrices";
import {
  sortFixturesByDate,
  sortFixturesByTierAndDivision,
} from "../helper/ModelDataHelper";
import { notifyError } from "../helper/Generic/Notifications";
import { mapFixturesAndPrices } from "../helper/Pricing/pricingServiceHelper";
// import WSDWebsocket from "../helper/Pricing/PricingWebSocketHelper";
import { buildNewStateForPricingPrices } from "../helper/Pricing/pricingPriceStateHelper";

export const PricingServiceContext = createContext({
  fixtures: [],
  favoriteList: [],
  competitionData: [],
  favoriteCompetitionData: [],
  setFixtures: () => { },
  setFavoriteList: () => { },
  getFixtures: () => { },
  fixturePrices: () => { },
  getFavoriteFixtures: () => { },
  websocketPricesReceived: () => { },

});

const PricingServiceProvider = ({ children }) => {
  const [fixtures, setFixtures] = useState([]);
  const [favoriteList, setFavoriteList] = useState([]);
  const [fixturesPrices, setFixturesPrices] = useState([]);
  const [competitionData, setCompetitionData] = useState([]);
  const [favoriteCompetitionData, setFavoriteCompetitionData] = useState([]);

  // Stores the prices
  const pricesRef = useRef([]);
  const previousFixturesCallRef = useRef(null);

  const handleNewPrices = (newPrices) => {
    const newState = buildNewStateForPricingPrices(
      pricesRef.current,
      newPrices
    );

    pricesRef.current = newState;
    setFixturesPrices(newState);
  };

  const websocketPricesReceived = (data) => {
    if (data == null) {
      return;
    }
    handleNewPrices(data);
  };

  // const pricesWS = new WSDWebsocket("PricesChannel", websocketPricesReceived);

  // const openPricingWs = useCallback(async () => {
  //   try {
  //     pricesWS.connect();
  //   } catch (error) {
  //     notifyError("Something Wrong");
  //   }
  // });

  // const closePricingWs = useCallback(async () => {
  //   try {
  //     pricesWS.closeSocket();
  //   } catch (error) {
  //     notifyError("Something Wrong");
  //   }
  // });

  const getFixtures = useCallback(
    async (
      countries_ids,
      start_time,
      end_time,
      competitions_ids,
      term,
      callbackFunction,
      callbackError
    ) => {
      try {
        // Cancel the previous request
        previousFixturesCallRef.current?.cancel();
        const ref = getCurrentUserFixtures(
          countries_ids,
          start_time,
          end_time,
          competitions_ids,
          term,
          function (response) {
            const sortedByDivisionTier = sortFixturesByTierAndDivision(
              response.data.fixtures
            );
            const sorted = sortFixturesByDate(sortedByDivisionTier);
            setFixtures(sorted);

            callbackFunction();
          },
          function (error) {
            callbackError();
          }
        );
        previousFixturesCallRef.current = ref;
      } catch (err) {
        callbackError();
      }
    }
  );

  const fixturePrices = useCallback(async (callbackError) => {
    try {
      await getFixturePrices(
        function (response) {
          handleNewPrices(response.data);
        },
        function (error) {
          callbackError();
        }
      );
    } catch (err) {
      callbackError();
    }
  });

  const getFavoriteFixtures = useCallback(
    async (
      countries_ids,
      start_time,
      end_time,
      competitions_ids,
      term,
      callbackFunction,
      callbackError
    ) => {
      try {
        await getFavoritesFixture(
          countries_ids,
          start_time,
          end_time,
          competitions_ids,
          term,
          function (response) {
            const sorted = sortFixturesByDate(response.data.fixtures);
            setFavoriteList(sorted);
            callbackFunction();
          },
          function (error) {
            callbackError();
          }
        );
      } catch (err) {
        callbackError();
      }
    }
  );

  useEffect(() => {
    let mappedData = mapFixturesAndPrices(fixtures, fixturesPrices);
    setCompetitionData(mappedData);
  }, [fixtures, fixturesPrices]);

  useEffect(() => {
    let mappedData = mapFixturesAndPrices(favoriteList, fixturesPrices);
    setFavoriteCompetitionData(mappedData);
  }, [favoriteList, fixturesPrices]);

  return (
    <PricingServiceContext.Provider
      value={{
        fixtures,
        setFixtures,
        favoriteList,
        setFavoriteList,
        favoriteCompetitionData,
        getFixtures,
        fixturePrices,
        getFavoriteFixtures,
        competitionData,
        websocketPricesReceived
      }}
    >
      {children}
    </PricingServiceContext.Provider>
  );
};

export const usePricingService = () => useContext(PricingServiceContext);
export default PricingServiceProvider;
