
import { PATCH } from "../APiHelper/API";


export const disableRsSelection = (id, onSuccess, onError) => {

    const data = {
        id: id,
    }
    PATCH("/api/v1/runshop_bets/disable_selection", data, onSuccess, onError);
}