import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { format_time_to_timezone } from "../../../helper/Generic/dateTimeFormat";
import { checkFlag } from "../../../helper/Generic/getFlagSrc";
import { selectUser } from "../../../Redux/user/user";
import ConfirmAttendanceComponent from "../Menu/ConfirmAttendanceComponent";
import DotMenu from "../Menu/DotMenu";
import { ReactComponent as ShirtIcon } from "../../../assets/icons/LeaguesIcons/shirtSmall.svg";

const FinishedMatchHeader2 = ({
  fixtureData,
  setEditInPhase3,
  focusOnOtherTeam,
  handleSetConfirmedAttendance,
  homeTeam,
  awayTeam,
  thisTeam,
  handleShowTransferModal,
  dotMenuOptionsList,
  handleResetSquad,
  handleOpenGroupPlayers
}) => {


  const user = useSelector(selectUser);

  const fixtureStartTime = format_time_to_timezone(
    fixtureData.fixture.start_time,
    user.timezone
  );

  const phase = "phase3"

  return (
    <TeamHeader>
      <FirstLine>
        {fixtureData.cup ?

          <LeagueAndFlag>
            <LogoWrap>
              <img src={fixtureData.cup.logo} alt=""></img>
            </LogoWrap>
            <h1>{fixtureData?.cup.name}</h1>
          </LeagueAndFlag> :

          <LeagueAndFlag>
            <LogoWrap>
              <img src={checkFlag(fixtureData?.league?.country)} alt=""></img>
            </LogoWrap>
            <h1>{fixtureData?.league.name}</h1>
          </LeagueAndFlag>
        }

        <DotMenu
          optionsList={dotMenuOptionsList}
          fixtureData={fixtureData}
          handleShowTransferModal={handleShowTransferModal}
          disabled={true}
          handleResetSquad={handleResetSquad}
          phase={phase}
        />

      </FirstLine>
      <SecondLine>
        <div
          style={{ display: "flex", columnGap: "20px", alignItems: "center" }}
        >
          <TeamsNames selectedTeam={homeTeam?.name === thisTeam?.name}>
            <TeamWrapper
              onClick={() => {
                if (homeTeam?.name !== thisTeam?.name) {
                  focusOnOtherTeam(homeTeam.id);
                }
              }}
            >
              <LogoWrapper>
                <img src={homeTeam?.logo_url} alt="logo" />
              </LogoWrapper>
              <h1>{homeTeam?.name}</h1>
            </TeamWrapper>

            <TeamWrapper
              onClick={() => {
                if (awayTeam?.name !== thisTeam?.name) {
                  focusOnOtherTeam(awayTeam.id);
                }
              }}
            >
              <LogoWrapper>
                <img src={awayTeam?.logo_url} alt="logo" />
              </LogoWrapper>
              <h2>{awayTeam?.name}</h2>
            </TeamWrapper>
          </TeamsNames>
          <DateAndTime>
            <p>{fixtureStartTime.format("DD/MM/YYYY")}</p>
            <p>{fixtureStartTime.format("HH:mm")}</p>
          </DateAndTime>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "2px",
            alignItems: "end",
            justifyContent: "end",
          }}
        >
          <EditBtn onClick={() => setEditInPhase3(true)}>Edit</EditBtn>
          <SmallBtnsGroup>
            <ConfirmAttendanceComponent
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              fixtureData={fixtureData}
              handleSetConfirmedAttendance={handleSetConfirmedAttendance}
            />
            {/* <GroupPlayersBtn
              disabled={fixtureData?.default_squad}
              onClick={handleOpenGroupPlayers}
              fightingPayers={fixtureData?.squad?.fight_for_groups?.length > 0}
            >
              <ShirtIcon />
            </GroupPlayersBtn> */}
          </SmallBtnsGroup>
        </div>
      </SecondLine>
      <ThirdLine>
        <PlayerTitle>
          <h1>player</h1>
        </PlayerTitle>
        <POSNTitle>
          <h1>posn</h1>
        </POSNTitle>
        <InjuredTitle />
      </ThirdLine>
    </TeamHeader>
  );
};

export default FinishedMatchHeader2;
const TeamHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  background: ${({ theme }) => theme.squadManager.finishedMatchHeader2};
  row-gap: 5px;
  color: white;
  min-height: 101px;
`;
const FirstLine = styled.div`
display:flex;
justify-content:space-between;
align-items:center;

`;
const SecondLine = styled.div`
  display: flex;
  justify-content: space-between;
`;
const LeagueAndFlag = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  min-height: 26px;
  h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: white;
  }
  img {
    height: 20px;
    width: 30px;
  }
`;

const TeamsNames = styled.div`
  min-width: 140px;
  row-gap: 2px;
  display: flex;
  flex-direction: column;
  h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: ${(props) => (props.selectedTeam ? "700" : "400")};
    font-size: 12px;
    line-height: 140%;
    text-decoration: ${(props) => (props.selectedTeam ? "" : "underline")};
  }
  h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: ${(props) => (props.selectedTeam ? "400" : "700")};
    font-size: 12px;
    line-height: 140%;
    text-decoration: ${(props) => (props.selectedTeam ? "underline" : "")};
  }
`;

const DateAndTime = styled.div`
p{font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 140%;
color:  color:white; ;
}
`;
const ThirdLine = styled.div`
  display: flex;
  column-gap: 10px;
`;
const PlayerTitle = styled.div`
min-width:155px;
display:flex;
align-items:center;
column-gap:5px;
h1{
    text-transform:uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;   
    letter-spacing: 0.03em; 
    color:  color:white; ;
}
`;
const POSNTitle = styled(PlayerTitle)`
  min-width: 34px;
`;
const InjuredTitle = styled(PlayerTitle)`
  min-width: 50px;
`;
const TeamWrapper = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  cursor: pointer;
`;
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17px;
  width: 17px;
  background: #ffffff;
  border: 0.5px solid #eef2fd;
  border-radius: 2px;
  img {
    height: 11px;
  }
`;
const EditBtn = styled.button`
  background: none;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  text-decoration-line: underline;
  color: white;
  cursor: pointer;
  height: 100%;
`;
const LogoWrap = styled.div`
display:flex;
  height: 20px;
    width: 30px;
    overflow:hidden;
 img {
  object-fit: contain;
  width:100%;
  }
`

const SmallBtnsGroup = styled.div`
display:flex;
gap:5px;
align-items:center;
`

const GroupPlayersBtn = styled.button`
display:flex;
align-items:center;
justify-content:center;
border:1px solid var(--primaryBlue);
background:white;
border-radius:4px;
height:17px;
width:25px;
cursor:pointer;

svg{
  fill:${props => props.fightingPayers ? "var(--primaryBlue)" : "white"};
}
:disabled{
  opacity:0.5;
}
`