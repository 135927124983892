import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import PermissionsGate from "../../helper/Generic/PermissionGate";
import { SCOPES } from "../../helper/Generic/Permissions";
const PageSelectorMenu = ({ page, setPage }) => {
    const ref = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();

    const handleSelect = (e) => {

        handleMenuOpen();
        navigate(`/${e.currentTarget.attributes.value.nodeValue}`);
    };

    const handleMenuOpen = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        } else {
            setIsMenuOpen(true);
        }
    };

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    const eProps = {
        hidden: true
    }
    return (
        <Container ref={ref}>
            <DropDownMenu onClick={() => handleMenuOpen()} isMenuOpen={isMenuOpen}>
                {isMenuOpen ? <ExpandMoreIcon
                    style={{
                        transform: "rotate(180deg)",
                    }}
                /> : <ExpandMoreIcon />}
                <p>{page} </p>
            </DropDownMenu>
            {isMenuOpen && (
                <DropDownList>
                    <DropDownMenuItem onClick={(e) => handleMenuOpen()}>
                        <ExpandMoreIcon />
                        <p>{page}</p>
                    </DropDownMenuItem>

                    <DropDownMenuItem value={"pricing"} onClick={(e) => handleSelect(e)}>
                        <p>pricing</p>
                    </DropDownMenuItem>
                    <PermissionsGate scopes={[SCOPES.canBet]}>
                        <DropDownMenuItem value={"betting/bets_list"} onClick={(e) => handleSelect(e)}>
                            <p> betting</p>
                        </DropDownMenuItem>
                    </PermissionsGate>
                    <PermissionsGate scopes={[SCOPES.canAnalyze]} >
                        <DropDownMenuItem value={"leagues"} onClick={(e) => handleSelect(e)}>
                            <p> leagues</p>
                        </DropDownMenuItem>
                    </PermissionsGate>
                    <PermissionsGate scopes={[SCOPES.canAudit]}>
                        <DropDownMenuItem value={"audit"} onClick={(e) => handleSelect(e)}>
                            <p> audit</p>
                        </DropDownMenuItem>
                    </PermissionsGate>
                </DropDownList>
            )}
        </Container>
    );
};

export default PageSelectorMenu;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width:110px;
  position: relative;

`;

const DropDownMenu = styled.div`
position: relative;
cursor:pointer;
display: flex;
align-items: center;
justify-content:space-around;
background: transparent;
padding: 3px 10px;
border: 1px solid #ffffff;
border-radius: 100px;
color: white;
p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}

`;

const DropDownList = styled.div`
  position:absolute;
  width:100%;
  background-color: var(--darkBlue);
  z-index: 3;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #ffffff;
`;

const DropDownMenuItem = styled.div`
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  color:white;
  justify-content:center;
  border-bottom:1px solid white;
  p {
    color:white;
   text-transform:capitalize;
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 300;
   font-size: 14px;
   line-height: 16px;
  }
  :first-child {
    padding:3px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    justify-content:space-around;

    p{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
    }
  }
  :last-child{
    border-bottom: none;
  }
`;
