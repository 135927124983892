import moment from "moment-timezone";

export const format_time_to_timezone = (date, timezone) => {
  let newDate = moment.utc(date).tz(timezone);
  return newDate;
};

export const same_time_in_timezone = (date, timezone) => {
  let time = moment(date).format("YYYY-MM-DD HH:mm");
  let newDate = moment.tz(time, timezone);

  return newDate;
};

export const formatted_date_for_backend = (date) => {
  if (date) {
    return date.toISOString();
  }

  return null;
};
