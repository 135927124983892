import { POST } from "../../APiHelper/API";
let source;
export const getLeaguesForCurrentUser = (season_ids, cup_ids, country_ids, league_ids, term, archived_teams, onSuccess, onError) => {

    const data = {
        season_ids: season_ids,
        cup_ids: cup_ids,
        country_ids: country_ids,
        league_ids: league_ids,
        term: term,
        archived_teams: archived_teams
    }
    source = POST('/api/v1/leagues', data, onSuccess, onError, true, source)
}