import { POST } from "../APiHelper/API"

export const getSquadsForTeam = (data, onSuccess, onError) => {
    // const data = {
    //     team_id: team_id,
    //     league_ids: league_id,
    //     cup_ids: cup_ids
    // }

    POST(`/api/v1/squads/teams`, data, onSuccess, onError)
}


export const getSquadsStatsForTeam = (data, onSuccess, onError) => {
    // const data = {
    //     team_id: team_id,
    //     league_ids: league_id,
    //     cup_ids: cup_ids
    // }

    POST(`/api/v1/squads/teams/stats`, data, onSuccess, onError)
}