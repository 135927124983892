import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BettingPageHeader from "../../components/bettingComponents/Header";
import { Outlet, useNavigate } from "react-router";


const Betting = () => {
  const [modelEv, setModelEv] = useState();
  const [marketEv, setMarketEv] = useState();
  const [loggedBetsNumber, setLoggedbetsNumber] = useState();
  const [loggedBetsOUGNumber, setLoggedBetsOUGNumber] = useState();
  const [loggedBetsHCPNumber, setLoggedBetsHCPNumber] = useState();
  const [retStakeSum, setRetStakeSum] = useState();
  const [riskSum, setRiskSum] = useState();
  const navigate = useNavigate();

  const checkRedirect = () => {
    if (location.pathname === "/betting") {
      navigate('/betting/bets_list')
    }
  }

  useEffect(() => {
    checkRedirect()
  }, [location.pathname])

  return (
    <Container>

      <BettingPageHeader />

      <Outlet
        context={[modelEv,
          setModelEv,
          setMarketEv,
          marketEv,
          loggedBetsNumber,
          setLoggedbetsNumber,
          loggedBetsOUGNumber,
          setLoggedBetsOUGNumber,
          loggedBetsHCPNumber,
          setLoggedBetsHCPNumber,
          retStakeSum,
          setRetStakeSum,
          riskSum,
          setRiskSum
        ]}

      />

    </Container>
  );
};

export default Betting;
const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-width: 1600px;
  position: relative;
`;
