import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AtkDefValues } from "../../../helper/Values/AtkDefList";
import { TeamImp } from "../../../helper/Values/TeamImp";
import { YesNo } from "../../../helper/Values/TeamFormation";
import LtfMenu from "../Menu/LtfMenu";
import SingleOptionMenuTiny from "../Menu/SingleOptionMenuTiny";
import StatusMenu from "../Menu/StatusMenu";
import { StChanceList } from "../../../helper/Values/StChance";
import StChanceMenu from "../Menu/StChanceMenu";
import PlayerPositionMenu from "../Menu/PlayerPositionMenu";
import { FatigueValues } from "../../../helper/Values/FatigueList";


const Player = ({
  data,
  handleFieldChange,
  displayPlayerAverageStats,
  selectedFixture,
  index,
  handleHightlight,
  checkHighlightedElement,
  user

}) => {


  var player_full_name =
    data.player?.first_name + " " + data.player?.last_name;

  if (user.use_short_player_names && data?.player?.short_name && data?.player?.short_name != "") {
    player_full_name = data.player.short_name
  }
  if (user.use_other_player_names && data?.player?.other_player_name && data?.player?.other_player_name != "") {
    player_full_name = data.player.other_player_name
  }

  if (data.lineup_changed_after_pm_complete) {
    player_full_name = " * " + player_full_name
  }

  const [xi, setXi] = useState();
  const [status, setStatus] = useState();
  const [imp, setImp] = useState();
  const [atk, setAtk] = useState();
  const [def, setDef] = useState();
  const [ltf, setLtf] = useState();
  const [fatigue, setFatigue] = useState();
  const [stChance, setStChance] = useState();
  const [position, setPosition] = useState(null);
  const [fadedName, setFadedName] = useState(false)

  const handleChange = (e) => {
    setXi(e.target.checked);
  };

  const extractPositionList = (player) => {
    if (!player) {
      return;
    }
    let positionlist = [];
    if (player.position_1 != null) {
      positionlist.push(player.position_1);
    }

    if (player.position_2 != null) {
      positionlist.push(player.position_2);
    }

    if (player.position_3 != null) {
      positionlist.push(player.position_3);
    }

    return positionlist;
  };

  const handleSetInitialPosition = (data) => {
    if (data.position) {
      setPosition(data.position);
    } else {
      setPosition(data.player?.position_1);
    }
  };


  const checkIfNoStChange = (value) => {

    if (value == "no_chance") {
      setFadedName(true)
    } else {
      setFadedName(false)
    }

  };
  const populateFiels = () => {
    handleSetInitialPosition(data);
    setStatus(data.status);
    setFatigue(data.fatigue);
    setAtk(data.attack_rating);
    setDef(data.defence_rating);
    setImp(data.importance);
    setLtf(data.long_term_formation);
    setStChance(data.starting_chance);
    setXi(data.xi);
  };



  useEffect(() => {
    handleFieldChange(data.player_id, "status", status);
  }, [status]);

  useEffect(() => {
    handleFieldChange(data.player_id, "fatigue", fatigue);
  }, [fatigue]);

  useEffect(() => {
    handleFieldChange(data.player_id, "attack_rating", atk);
  }, [atk]);

  useEffect(() => {
    handleFieldChange(data.player_id, "defence_rating", def);
  }, [def]);

  useEffect(() => {
    handleFieldChange(data.player_id, "importance", imp);
  }, [imp]);

  useEffect(() => {
    handleFieldChange(data.player_id, "long_term_formation", ltf);
  }, [ltf]);

  useEffect(() => {
    handleFieldChange(data.player_id, "starting_chance", stChance);
    checkIfNoStChange(stChance);
  }, [stChance]);

  useEffect(() => {
    handleFieldChange(data.player_id, "position", position);
  }, [position]);

  useEffect(() => {
    handleFieldChange(data.player_id, "xi", xi);
  }, [xi]);

  useEffect(() => {
    populateFiels();
  }, [selectedFixture]);

  return (
    <>
      {
        data.player.wy_id != null ? (
          <Wrapper high={checkHighlightedElement(index)}>
            <PlayerItem
              checked={xi}
            >
              <PlayerCell style={{ minWidth: "160px" }} noStChance={fadedName}>
                <PlayerNumber>
                  <h2>{data.player?.squad_number}</h2>
                </PlayerNumber>
                <PlayerNameCell onContextMenu={(e) => displayPlayerAverageStats(e, selectedFixture.league.season_id, selectedFixture.squad.team_id, data.player?.id)}>

                  <ChangesMarkesCell>
                    <MarkerWrapper>
                      {data.lineup_changed_after_pm_complete && <h5> {data.lineup_changed_after_pm_complete && "★"}</h5>}
                    </MarkerWrapper>
                    <MarkerWrapper>
                      {data.ratings_changed_after_pm_complete && <h6> {data.ratings_changed_after_pm_complete && "●"}</h6>}
                    </MarkerWrapper>
                  </ChangesMarkesCell>

                  <PlayerNameWrapper>
                    <h1>
                      {player_full_name}
                    </h1>
                  </PlayerNameWrapper>

                </PlayerNameCell>
              </PlayerCell>
              <POSNCell>
                <PlayerPositionMenu
                  valueList={extractPositionList(data.player)}
                  value={position}
                  setValue={setPosition}
                />
              </POSNCell>
              <STATUSCell>
                <StatusMenu
                  width={"70px"}
                  selectedValue={status}
                  setSelectedValue={setStatus}
                />
              </STATUSCell>
              <FATIGUECell>
                <SingleOptionMenuTiny
                  width={"35px"}
                  valuesList={FatigueValues}
                  selectedValue={fatigue}
                  setSelectedValue={setFatigue}
                />
              </FATIGUECell>
              <IMPCell>
                <SingleOptionMenuTiny
                  width={"35px"}
                  valuesList={TeamImp}
                  selectedValue={imp}
                  setSelectedValue={setImp}
                />
              </IMPCell>
              <ATKCell>
                <SingleOptionMenuTiny
                  width={"30px"}
                  valuesList={AtkDefValues}
                  selectedValue={atk}
                  setSelectedValue={setAtk}
                />
              </ATKCell>
              <DEFCell>
                <SingleOptionMenuTiny
                  width={"30px"}
                  valuesList={AtkDefValues}
                  selectedValue={def}
                  setSelectedValue={setDef}
                />
              </DEFCell>
              <LTFCell>
                {" "}
                <LtfMenu
                  width={"40px"}
                  valuesList={YesNo}
                  selectedValue={ltf}
                  setSelectedValue={setLtf}
                />
              </LTFCell>
              <STCHANCECell>
                <StChanceMenu
                  width={"60px"}
                  valuesList={StChanceList}
                  selectedValue={stChance}
                  setSelectedValue={setStChance}
                />
              </STCHANCECell>
              <XICell>
                <input
                  type={"checkbox"}
                  value={xi}
                  checked={xi}
                  onChange={(e) => handleChange(e)}
                />
              </XICell>
            </PlayerItem>
          </Wrapper>)
          :
          (<PlayerItem />)
      }

    </>
  );
};

export default Player;
const Wrapper = styled.div`
display: flex;
padding:${(props) => props.high ? "3px 0 3px 3px;" : ""};
margin:${(props) => props.high ? "-3px 0 -3px -3px;" : ""};
background: ${(props) => props.high ? ({ theme }) => theme.squadManager.highlightColor : ""};
`
const PlayerItem = styled.div`
width:100%;
  padding: 5px 10px;
  align-items: center;
  display: flex;
  column-gap: 5px;
background: ${(props) => (props.checked ? ({ theme }) => theme.squadManager.playerSelected : ({ theme }) => theme.squadManager.playerBg)};
  height: 32px;
  .green {
    border-color: #cbf2dc;
    background: #ebfcf3;
    h1 {
      color: #23a760;
    }
    p {
      color: #23a760;
    }
  }
  .blue {
    border-color: #d5dff7;
    background: #e4ecff;
    h1 {
      color: #4393fc;
    }
  }
  .orange {
    border-color: #f6dabd;
    background: #fff3e7;
    h1 {
      color: #ff8d21;
    }
  }
  .red {
    border-color: #f9dddd;
    background: #fff5f5;
    h1 {
      color: #ec4848;
    }
    p {
      color: #ec4848;
    }
  }
`;

const PlayerCell = styled.div`
  min-width: 100px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  h1 {
    cursor: pointer;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.03em;
    color:${(props) => props.noStChance ? (({ theme }) => theme.squadManager.noStChanceColor) : (({ theme }) => theme.squadManager.textColor)};
    max-width: 130px;
    overflow: hidden;
    max-height: 24px;
    white-space: break-spaces;
  }
`;
const PlayerNumber = styled.div`
  border: 0.7px solid #cbd7f5;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 20px;
  width: 20px;
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    /* identical to box height */
    text-align: center;
    color: rgba(0, 18, 41, 0.7);
  }
`;
const POSNCell = styled(PlayerCell)`
  min-width: 50px;
  min-height: 20px;
`;
const STATUSCell = styled(PlayerCell)`
  min-width: 70px;
`;
const FATIGUECell = styled(PlayerCell)`
  min-width: 35px;
`;
const IMPCell = styled(PlayerCell)`
  min-width: 30px;
`;
const ATKCell = styled(IMPCell)``;
const DEFCell = styled(IMPCell)``;
const LTFCell = styled(PlayerCell)`
  min-width: 40px;
`;
const STCHANCECell = styled(PlayerCell)`
  min-width: 70px;
  max-wdith: 70px;
`;
const XICell = styled(PlayerCell)`
  min-width: 20px;
  input {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }

`;


const PlayerNameCell = styled.div`
display:flex;
align-items:center;
gap:2px;
`
const PlayerNameWrapper = styled.div`
display:flex;
h1 {
  cursor: pointer;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.03em;
  color: ${({ theme }) => theme.squadManager.textColor};
  overflow: hidden;
  max-width: 130px;
  overflow: hidden;
  max-height: 24px;
  white-space: break-spaces;
}
`
const ChangesMarkesCell = styled.div`
display:flex;
flex-direction:column;
gap:2px;
justify-content:center;
align-items:center;
min-width:6px;
margin-left:-2px;
h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 6px;
  line-height: 5px;
   color: ${({ theme }) => theme.squadManager.textColor};
}
 h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 7px;
  line-height: 5px;
   color: ${({ theme }) => theme.squadManager.textColor};
}
`
const MarkerWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
min-width:6px;
min-height:5px;
`