import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import DatePicker from "../../components/auditComponents/DatePicker";
import { ReactComponent as DownloadSvg } from "../../assets/icons/BettingIcons/downloadArrow.svg";
import moment from "moment";
import { getAuditLogs } from "../../api/audit/getAuditLogs";
import { toast } from "react-toastify";
import fileDownload from "js-file-download";
import dayjs from "dayjs";
import {
  notifyLoading,
  notifyUpdate,
} from "../../helper/Generic/Notifications";
import WebSocketHandler from "../../helper/websocket/WebSocketHandler";
import WebSocketObserver from "../../helper/websocket/WebSocketObserver";
import { useWebSocketDataService } from "../../context/useWebSocketDataService";

const Audit = () => {
  const [date, setDate] = useState(new Date());
  const toastId = useRef(null);


  const handleDownloadCSV = () => {
    notifyLoading(toastId, "Generating CSV");
    let start_time = date.setHours(0, 0, 0);
    let time = moment(start_time).toDate();
    getAuditLogs(
      time,
      function (response) {
        notifyUpdate(toastId, "CSV Generated", toast.TYPE.SUCCESS);
        fileDownload(response.data, `${dayjs()}.csv`);
      },
      function () {
        notifyUpdate(toastId, "Failed to generate", toast.TYPE.ERROR);
      }
    );
  };

  return (
    <Container>
      <Content>
        <Line>
          <h1>Audit</h1>
        </Line>
        <Line>
          <h2>Logs</h2>
        </Line>
        <FlexLine>
          <DatePicker selectedValue={date} setSelectedValue={setDate} />
          <CsvBtn
            onClick={() => handleDownloadCSV()}
            disabled={date == null ? true : false}
          >
            <DownloadSvg />
            Download Logs
          </CsvBtn>
        </FlexLine>
      </Content>
    </Container>
  );
};

export default Audit;
const Container = styled.div`
  display: flex;
  min-width: 1600px;
  width: 100%;
  min-height: var(--pageHeight);
  padding: 40px 220px;
  position: relative;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  min-width: 400px;
  width: 100%;
  .logoutBtn {
    border: 1px solid rgba(255, 89, 79, 1);
    color: rgba(255, 89, 79, 1);
    background-color: rgba(252, 244, 243, 1);
    padding: 10px 28px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    border-radius: 4px;
    cursor: pointer;
    :hover {
      color: red;
      border: 1px solid red;
    }
  }
  .inviteBtn {
    background-color: var(--primaryBlue);
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 51px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
  }

  /* p {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  } */
`;
const Line = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  position: relative;

  h1 {
    color: var(--darkBlue);
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
  }
`;
const FlexLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    color: var(--darkBlue);
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }
`;
const CsvBtn = styled.button`
  cursor: pointer;
  border-radius: 4px;
  column-gap: 5px;
  padding: 5px 10px 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  background: var(--primaryBlue);
  color: #ffffff;

  :disabled {
    opacity: 0.6;
    cursor: inherit;
  }
`;
