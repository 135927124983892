import React from "react";
import styled from "styled-components";

const ReadOnlyField = ({ width, value, secondValue, type, icon }) => {
    const checkType = (type) => {
        if (type == "wind") {
            return "km/h";
        }
        if (type == "percent") {
            return "%";
        }
        if (type == "altitude") {
            return "m";
        }
        if (type == "temperature") {
            return "°C";
        }
    };
    const mapValue = (value) => {
        if (value === true) {
            return "Yes";
        } else if (value === false) {
            return "No";
        } else return value.toLocaleString();
    };

    return (
        <Container width={width}>
            {icon}{" "}
            {value != undefined ? (
                <p>
                    {mapValue(value)}
                    {type != undefined ? checkType(type) : ""}{" "}
                    {secondValue ? `- ${secondValue}` : ""}
                </p>
            ) : (
                <p>-</p>
            )}
        </Container>
    );
};

export default ReadOnlyField;
const Container = styled.div`
  min-width: 60px;
  min-height: 40px;
  width: fit-content;
  background: ${({ theme }) => theme.matchPreview.matchFactorsReadOnlyInputs};
  column-gap: 5px;
  border-radius: 6px;
  display: flex;
  width: ${(props) => (props.width != null ? props.width : "90px")};
  padding: 12px 16px;
  p {
    text-transform: capitalize;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.matchPreview.textColor};
  }

  svg {
    height: 15px;
  }
`;
