import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as XIcon } from "../../../assets/icons/xBtn.svg";
import EditLeagueSubmenuOption from "../EditLeagueComponents/EditLeagueSubmenuOption";
import { CURRENT_CUPS, CURRENT_LEAGUES, DELETED_LEAGUES, DELETED_REFEREES, DELETED_TEAMS, editCupSubmenuList, editSeasonSubmenuList } from "../../../helper/Values/EditLeagueSubmenuValues";
import TeamElement from "../LeagueDetailsComponents/TeamElement";
import { ReactComponent as BinSvg } from "../../../assets/icons/bin.svg";
import DeletedTeamElement from "../LeagueDetailsComponents/DeletedTeamElement";
import { ReactComponent as PlusSvg } from "../../../assets/icons/LeaguesIcons/plusIcon.svg";
import { useLeaguesDataService } from "../../../context/useLeaguesDataService";
import { notifyError, notifyInfo, notifyInfoNoClose, notifySucces } from "../../../helper/Generic/Notifications";
import LoaderSpinner from "../../../helper/LoaderSpinner";
import SeasonMenuForNewEvent from "../AddNewLeagueEvents/SeasonMenuForNewEvent";
import TeamsList from "../AddNewLeagueEvents/TeamsList";
import SelectedTeams from "../AddNewLeagueEvents/SelectedTeamsList";
import _ from "lodash";
import SelectedTeamsListForEditCup from "../AddNewLeagueEvents/SelectedTeamsListForEditCup";
import SearchRefereeMenu from "../Menu/SearchRefereeMenu";
import RefereeElement from "../LeagueDetailsComponents/RefereeElement";
import { sortReferees } from "../../../helper/Analyst/LeaguesData/SortReferees";

const EditCupModal = ({
    handleCloseEditCup,
    selectedCupToEdit,
    loadCupsAndSeasons,
    handleArchiveCup,
    setIsLoading,
    shouldReselectCup,
}) => {

    var content;
    const shouldRefresh = useRef();
    const ref = useRef();
    const inputLogoRef = useRef();
    const notifyNoCloseId = useRef();

    const {
        update_cup,
        add_cup_referee,
        search_teams,
        teamsList,
        setTeamsList,
        delete_cup_referee,
        get_seasons,
        get_deleted_teams_for_cup,
        remove_team_from_cup,
        undo_remove_team_from_cup,
        search_referee_to_add_cup
    } = useLeaguesDataService();

    const [submenuValue, setSubmenuValue] = useState(CURRENT_CUPS);
    const [cupName, setCupName] = useState(selectedCupToEdit.name)
    const [compettion, setCompetition] = useState(selectedCupToEdit.competition)
    const [referees, setReferees] = useState(selectedCupToEdit.referees);
    const [teams, setTeams] = useState(selectedCupToEdit.teams)
    const [newTeams, setNewTeams] = useState([])

    const [teamSearchTerm, setTeamSearch] = useState("")
    const [previewFile, setPreviewFile] = useState(selectedCupToEdit.logo);
    const [logo, setLogo] = useState(selectedCupToEdit.logo);
    const [isLoadingTeamList, setIsLoadingTeamList] = useState(false);

    const initializeValues = () => {
        setCupName(selectedCupToEdit.name);
        setCompetition(selectedCupToEdit.competition);
        setReferees(sortReferees(selectedCupToEdit.referees));
        setTeams(selectedCupToEdit.teams);
        setNewTeams([]);
        setPreviewFile(selectedCupToEdit.logo);
        setLogo(selectedCupToEdit.logo);
    }

    const [isTeamsListOpen, setIsTeamListOpen] = useState(false)

    const [readyToSave, setReadyToSave] = useState(false);
    const [selectedSeason, setSelectedSeason] = useState();
    const [seasonsList, setSeasonsList] = useState([]);
    const [deletedTeams, setDeletedTeams] = useState([]);

    const [refereeSearchTerm, setRefereeSearchTerm] = useState("");
    const [newReferee, setNewReferee] = useState(null);
    const shouldSearchReferee = useRef(false);
    const [refereeList, setRefereeList] = useState(null)


    const handleInputChange = (e) => {
        setCupName(e.target.value)
    };



    const handleTeamInputChange = (e) => {
        setSelectedSeason(null);
        setTeamsList([])
        setTeamSearch(e.target.value)
    };

    const handleAddteams = (team) => {
        setTeams(team);
        // setReadyToSave(true)
    }

    const closeModal = () => {
        if (shouldRefresh.current) {
            shouldRefresh.current = false;
            loadCupsAndSeasons();
        }
        handleCloseEditCup();
    }

    const handleSaveButton = () => {
        switch (submenuValue) {
            case CURRENT_CUPS:
                handleEditCup(selectedCupToEdit.id, selectedCupToEdit.competition_id, teams, cupName)
                break;

            default:
                break;
        }
    }

    const checkDuplicateTems = () => {
        let duplicate = false;
        teams.map((team) => {
            let found = teams.filter((filteredTeam) => filteredTeam.participant_id === team.participant_id)
            if (found && found.length > 1) {
                duplicate = true
            }
        })
        return duplicate
    }


    const handleUploadBtnClick = () => {
        inputLogoRef.current.click();
    };

    const handleFileInputChange = (e) => {
        setLogo(e.target.files[0]);
        setPreviewFile(URL.createObjectURL(e.target.files[0]));
    };

    const handleAddReferee = (refereeToAdd, cupId) => {
        setIsLoading(true)
        add_cup_referee(
            refereeToAdd,
            cupId,
            function (response) {
                shouldRefresh.current = true;
                setRefereeSearchTerm("");
                setNewReferee(null);
                setRefereeList([])
                setReferees((prev) => {
                    let newdata = [...prev, response.data.referee]
                    let sorted = sortReferees(newdata)
                    return sorted;
                })
                setIsLoading(false)

            },
            function (error) {
                notifyError("Something wrong")
                console.log(error)
                setIsLoading(false)
            }
        )
    }

    const handleDeleteReferee = (referee) => {
        setIsLoading(true)
        delete_cup_referee(
            referee.id,
            function (response) {
                let filtered = referees.filter((item) => item.id !== referee.id)
                setReferees(filtered);
                shouldRefresh.current = true;
                setIsLoading(false)
            },
            function (error) {
                notifyError("Something wrong")
                console.log(error)
                setIsLoading(false)
            }
        )
    }

    const handleEditCup = (id, competition_id, teams, name) => {
        setIsLoading(true)
        let team_ids = teams.map((item) => {
            return item.id
        })

        var dataObj = new FormData();

        dataObj.append("id", id);
        dataObj.append("competition_id", competition_id);
        dataObj.append("team_ids", JSON.stringify(team_ids));
        dataObj.append("name", name)


        if (logo != selectedCupToEdit.logo) {
            dataObj.append("image", logo)
        }

        update_cup(
            dataObj,
            function (response) {
                setTeamsList([]);
                setNewTeams([]);
                setIsLoading(false);
                setReadyToSave(false);
                setTeamSearch("");
                setSelectedSeason(null);
                loadCupsAndSeasons();
                handleGetDeletedTeams(selectedCupToEdit.id);
                shouldReselectCup.current = true;
            },
            function (error) {
                setIsLoading(false);
                notifyError("Something wrong");
                console.log(error);
            }

        )
    }


    const checkChanges = () => {
        if ((cupName != selectedCupToEdit.name ||
            logo != selectedCupToEdit.logo ||
            !_.isEqual(teams, selectedCupToEdit.teams) && !checkDuplicateTems())
        ) {
            setReadyToSave(true)
        } else {
            setReadyToSave(false)
        }
    }

    const handleRemoveTeamFromCup = (team_id) => {
        let itemIsNotSaved = newTeams.find((item) => item.id === team_id)
        if (itemIsNotSaved) {
            setNewTeams((prev) => {
                let filtered = prev.filter((item) => item.id != team_id)
                return filtered
            })
            setTeams((prev) => {
                let filtered = prev.filter((item) => item.id != team_id)
                return filtered
            })
        }
        else {
            remove_team_from_cup(
                team_id,
                selectedCupToEdit.id,
                function (response) {
                    let found = teams.find((item) => item.id === team_id);
                    setDeletedTeams((prev) => {
                        let deletedTeams = [...prev, found].sort((a, b) => a.name > b.name ? 1 : -1)
                        return (deletedTeams)
                    })
                    let filtered = teams.filter((item) => item.id !== team_id)
                    let sortedDeleted = filtered.sort((a, b) => a.name > b.name ? 1 : -1)
                    setTeams(sortedDeleted);
                    loadCupsAndSeasons();
                    notifySucces("Team removed");
                    shouldReselectCup.current = true;
                },

                function (error) {
                    notifyError("Something wrong");
                    console.log(error)
                }
            )
        }
    }

    const handleUndoRemoveTeamFromCup = (team_id) => {
        undo_remove_team_from_cup(
            team_id,
            selectedCupToEdit.id,
            function (response) {
                loadCupsAndSeasons();
                let found = deletedTeams.find((item) => item.id == team_id);
                let foundDuplicate = teams.find((item) => item.participant_id == found.participant_id);
                let filtered = deletedTeams.filter((item) => item.id != team_id);
                let sortedDeleted = filtered.sort((a, b) => a.name > b.name ? 1 : -1);
                setDeletedTeams(sortedDeleted);
                setTeams((prev) => {
                    let teams = [...prev, found].sort((a, b) => a.name > b.name ? 1 : -1);
                    return teams
                });
                notifySucces("Team reverted");
                shouldReselectCup.current = true;
                if (foundDuplicate) {
                    notifyInfo("This team is already in the list");
                };
            },

            function (error) {
                notifyError("Something wrong");
                console.log(error)
            }
        )
    }


    const handleTeamSearch = (term, seasonId) => {
        setIsLoadingTeamList(true)
        setIsTeamListOpen(true)
        search_teams(
            term,
            seasonId,
            function (response) {
                setIsLoadingTeamList(false)
            },
            function (error) {
                setIsLoadingTeamList(false)
                console.log(error)
                notifyError("Something wrong")
            }
        )
    }

    const handleGetSeasons = () => {
        const data = new FormData();
        data.append("term", teamSearchTerm)
        get_seasons(
            data,
            function (response) {
                let filtered = response.filter((item) => item.archived !== true)
                let sorted = filtered.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                setSeasonsList(sorted);
            },
            function (error) {
                console.log(error)
                notifyError("Something wrong")
            },

        )
    };

    const handleSelectSeason = (season) => {
        setSelectedSeason(season);
        handleTeamSearch("", season);
    }


    const handleGetDeletedTeams = (cup_id) => {
        get_deleted_teams_for_cup(
            cup_id,
            function (response) {
                let resp = response.data.teams
                let sorted = resp.sort((a, b) => a.name > b.name ? 1 : -1)
                setDeletedTeams(sorted)
            },
            function (error) {

                console.log(error)
            }
        )
    }


    const handleSearchRefereeToAdd = (term) => {
        setIsLoading(true)
        search_referee_to_add_cup(
            selectedCupToEdit.id,
            term,
            function (response) {
                setIsLoading(false);
                handleRefereesResponse(response.data.referees);
            },
            function (error) {
                setIsLoading(false);
                notifyError("Something wrong...");
            }
        )
    }

    const handleRefereesResponse = (referees) => {
        // console.log(referees, "refs")

        let mappedReferees = [];
        referees.forEach(referee => {
            let idx = mappedReferees.findIndex((item) => item.country == referee.country);

            if (idx == -1) {
                mappedReferees.push({
                    country: referee.country,
                    country_code: referee.country_code,
                    referees: [
                        referee
                    ],
                });
            }

            if (idx != -1) {
                mappedReferees[idx].referees.push(
                    referee
                );
            }
        });

        let sorted = mappedReferees.sort((a, b) => a.country > b.country ? 1 : -1);


        //find if exist index of element.country_code==currentLeague.country_code
        let wantedElementIdx = sorted.findIndex((item) => item.country == selectedCupToEdit?.country);

        if (wantedElementIdx != -1) {
            let element = sorted.splice(wantedElementIdx, 1)[0];
            sorted.splice(0, 0, element);
        }

        //find if exist index of unknownCountry and put elements at the end
        let unknounIdx = mappedReferees.findIndex((item) => item.country == null);

        if (unknounIdx != -1) {
            let unknownCountry = sorted.splice(unknounIdx, 1)[0]
            sorted.splice(sorted.length, 0, unknownCountry);
        }

        setRefereeList(sorted);
        // setLoading(false);
    };

    const checkDuplicatesReferees = () => {
        let mapped = referees?.map((referee) => {
            if (referee.wy_id) {
                return referee.wy_id;
            } else {
                return null
            }
        });

        let findDuplicates = mapped?.filter(
            (item, index) => {
                if (item) {
                    return mapped.indexOf(item) != index
                }
            }
        );

        if (findDuplicates?.length > 0) {
            notifyInfoNoClose(
                notifyNoCloseId,
                "The cup contains now duplicate referee, please check the current referee list!"
            );
        }
    };


    useEffect(() => {
        handleGetDeletedTeams(selectedCupToEdit.id);
    }, [])

    useEffect(() => {
        checkDuplicatesReferees(referees);
    }, [referees]);

    useEffect(() => {
        initializeValues();
    }, [selectedCupToEdit])

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isTeamsListOpen && ref.current && !ref.current.contains(e.target)) {
                setIsTeamListOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isTeamsListOpen]);

    useEffect(() => {
        if (shouldSearchReferee.current == false) {
            return;
        }

        if (refereeSearchTerm == "" || refereeSearchTerm.length < 3) {
            setRefereeList(null);
            return;
        }

        const timer = setTimeout(() => {
            handleSearchRefereeToAdd(refereeSearchTerm);
        }, 250);

        return () => clearTimeout(timer);
    }, [refereeSearchTerm]);


    switch (submenuValue) {
        case CURRENT_CUPS:
            content = <>
                <GroupLine>
                    <GroupContainer >
                        <h3>NAME</h3>
                        <CustomInputWrapper >
                            <input
                                placeholder="Enter Cup name"
                                name="cupName"
                                onChange={(e) => handleInputChange(e)}
                                value={cupName}
                            />
                        </CustomInputWrapper>
                    </GroupContainer>
                    <GroupContainer >
                        <h3>COMPETITION</h3>
                        <ElementInput
                            readOnly
                            value={compettion.competition}
                        />
                    </GroupContainer>

                    <GroupContainer>
                        <h3>LOGO</h3>
                        <GroupLine >
                            <LogoInputWrapper>
                                <input type="file" hidden ref={inputLogoRef} onChange={(e) => handleFileInputChange(e)} />
                                <LogoWrapper>
                                    {previewFile ? <img src={previewFile} alt="prev" />
                                        :
                                        <p>Upload logo</p>
                                    }
                                </LogoWrapper>
                                <UploadBtn
                                    onClick={() => handleUploadBtnClick()}
                                >
                                    {logo ? "Replace logo" : "Upload"}
                                </UploadBtn>
                            </LogoInputWrapper>

                        </GroupLine>

                    </GroupContainer>

                </GroupLine>
                <GroupContainer>
                    <h3>REFEREES NAME</h3>
                    <LeaguesList>
                        {referees?.map((referee, index) => (
                            <RefereeElement
                                key={referee.id}
                                handleRemoveReferee={handleDeleteReferee}
                                item={referee}
                            />
                        ))}
                        <GroupContainer >
                            <SearchRefereeMenu
                                value={newReferee}
                                setValue={setNewReferee}
                                term={refereeSearchTerm}
                                setTerm={setRefereeSearchTerm}
                                valuesList={refereeList}
                                shouldSearch={shouldSearchReferee}
                            />
                            {/* <ElementInput
                                placeholder="Enter referee name"
                                name="referee"
                                value={refereeName}
                                onChange={(e) => handleRefereeInputChange(e)}
                            /> */}
                            <NewRefereeButton
                                style={{ marginLeft: "auto" }}
                                disabled={newReferee === null}
                                onClick={() => handleAddReferee(newReferee, selectedCupToEdit.id)}
                            >
                                <PlusSvg />
                                Add new referee
                            </NewRefereeButton>
                        </GroupContainer>
                    </LeaguesList>
                </GroupContainer>

                <AddNewTeamWrapper>
                    <GroupContainer>
                        <h3>TEAMS NAME</h3>
                        <ElementInput
                            placeholder="Enter team name"
                            name="team"
                            value={teamSearchTerm}
                            onChange={(e) => handleTeamInputChange(e)}
                        />
                        <SeasonMenuForNewEvent
                            dataList={seasonsList}
                            value={selectedSeason}
                            setValue={handleSelectSeason}
                        />
                        {isLoadingTeamList ?
                            <LoaderSpinner />
                            :
                            <TeamsList
                                teamsList={teamsList}
                                selectedTeams={teams}
                                setSelectedTeams={handleAddteams}
                                setNewTeams={setNewTeams}
                                newTeams={newTeams}
                                deletedTeams={deletedTeams}
                                initialTeams={selectedCupToEdit.teams}
                                allTeams={teams.concat(deletedTeams)}
                                editCupModal={true}
                            />}

                    </GroupContainer>

                    <GroupContainer >
                        <h3>Selected Team</h3>
                        <SelectedTeamsListForEditCup
                            value={teams}
                            handleRemoveTeamFromCup={handleRemoveTeamFromCup}
                        />
                    </GroupContainer>

                </AddNewTeamWrapper>



            </>
            break;

        case DELETED_TEAMS:
            content = <>
                <LeaguesList>
                    {deletedTeams?.map((team, index) => (
                        <DeletedTeamElement
                            key={index}
                            handleRevert={handleUndoRemoveTeamFromCup}
                            item={team}
                            displayUpdatedAt={true}
                        />
                    ))}
                </LeaguesList>
            </>
            break;

        default:
            break;
    }

    useEffect(() => {
        handleGetSeasons();
    }, [])


    useEffect(() => {
        let time = setTimeout(() => {
            if (teamSearchTerm !== "") {
                handleTeamSearch(teamSearchTerm, null)
            } else {
                setTeamsList([]);
                setIsTeamListOpen(false);
            }
        }, 300)
        return () => {
            clearTimeout(time)
        }
    }, [teamSearchTerm])


    useEffect(() => {
        checkChanges();
    }, [cupName, logo, teams])

    return (
        <Container>
            <Card>
                <CardContent>
                    <Top>
                        <h1>Edit cup</h1>
                        <CloseButton onClick={() => closeModal()}>
                            <XIcon />
                        </CloseButton>
                    </Top>
                    <SubmenuWrapper>
                        <EditLeagueSubmenuOption
                            value={submenuValue}
                            setValue={setSubmenuValue}
                            valuesList={editCupSubmenuList}
                        />
                    </SubmenuWrapper>
                    <Content>
                        {content}
                    </Content>
                    <Bottom>
                        <ArchiveBtn onClick={
                            selectedCupToEdit?.archived ?
                                () => handleArchiveCup(selectedCupToEdit.id, false)
                                :
                                () => handleArchiveCup(selectedCupToEdit.id, true)}

                        >
                            <BinSvg />
                            {selectedCupToEdit?.archived ? "Unarchive Cup" : "Archive Cup"}

                        </ArchiveBtn>
                        <SaveBtn disabled={!readyToSave} onClick={() => handleSaveButton()}>
                            Save name
                        </SaveBtn>
                    </Bottom>
                </CardContent>
            </Card>
        </Container>
    );
};

export default EditCupModal;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  z-index: 4;
  overflow: hidden;
  padding: 0 20vw;
`;

const Card = styled.div`
position:sticky;
  background: ${({ theme }) => theme.editLeague.bg};
  top: 5vh;
  max-height: 80vh;
  height:100%;
  width:100%;
  overflow:hidden;
  @media (max-height: 1024px) {
    top: 2vh;
    max-height: 90vh;
  }
  border-radius: 16px;


`;
const CardContent = styled.div`
display:flex;
flex-direction:column;
position:relative;
height:100%;
`
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px 40px;
  background: ${({ theme }) => theme.editLeague.headerBg};

  h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: ${({ theme }) => theme.editLeague.headerColor};
  }
`;

const CloseButton = styled.div`
  cursor: pointer;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
    svg{
        color: ${({ theme }) => theme.editLeague.headerColor};
     }
`;

const SubmenuWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 25px 40px;
  border-bottom: 3px solid ${({ theme }) => theme.editLeague.borderColor};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 25px 40px;
  position:relative;
  height:100%;
  overflow:auto;
`;

const Bottom = styled.div`
  background: ${({ theme }) => theme.editLeague.headerBg};
  padding: 10px 40px;
  display: flex;
  justify-content: space-between;

`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  position: relative;
width:100%;
max-width:260px;
  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
`;
const GroupLine = styled.div`
display: flex;
gap:50px;
align-items:start;
`

const LeaguesList = styled.div`
  display: flex;
  align-items:start;
  flex-wrap: wrap;
  gap: 20px;


`;

const CustomInputWrapper = styled.div`
max-width:260px;
  input {
    width:100%;
    background: ${({ theme }) => theme.editLeague.readOnlyInputs};
    border: 1px solid #bac3e8;
    border-radius: 6px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.editLeague.menuTextColor};
    ::placeholder {
      color: ${({ theme }) => theme.editLeague.menuTextColor};
      opacity: 0.7;
    }
    padding: 11px 15px;
  }
`;
const ArchiveBtn = styled.button`
cursor: pointer;
border:none;
background:none;
display:flex;
align-items:center;
gap:5px;
color: #ec4848;

font-weight: 500;
font-size: 14px;
line-height: 16px;
`
const SaveBtn = styled.button`
  background-color: var(--primaryBlue);
  color: white;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding: 12px 27px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`;

const ElementInput = styled.input`
  height: 40px;
   background: ${({ theme }) => theme.editLeague.readOnlyInputs};
  width: 100%;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color:${({ theme }) => theme.editLeague.readOnlyInputsColor};
  padding: 10px 15px;
  :focus {
    outline:none;
  }
  ::placeholder {
     color:${({ theme }) => theme.editLeague.readOnlyInputsColor};
     opacity:0.7;
  }
  :disabled{
    background: ${({ theme }) => theme.editLeague.readOnlyInputs};
    color:${({ theme }) => theme.editLeague.readOnlyInputsColor};

  }
  &:read-only{
    background: ${({ theme }) => theme.editLeague.leagueDetails.deletedTeamsBg};
  }
`;
const NewRefereeButton = styled.button`
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  background: #ffffff;
  border: 0.7px solid #006fff;
  border-radius: 3px;
  padding: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--primaryBlue);
  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`;
const ReplaceLogoBtn = styled.button`
display:flex;
align-items:center;
column-gap:5px;
  background:${({ theme }) => theme.editLeague.buttonWithConfirmationBg};
  height: 40px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.editLeague.buttonWithConfirmationBorder};
  padding: 0 14px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.editLeague.buttonWithConfirmationTextColor};
  cursor: pointer;
  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }

`
// const LogoWrapper = styled.div`
// display:flex;
// width:55px;
// height:55px;
// overflow:hidden;
// img{
//     height:100%;
//     width:100%;
// }

// `
const ReadOnlyInput = styled.input`

`
const LogoInputWrapper = styled.div`
border-radius:4px;
border:1px dashed gray;
display:flex;
flex-direction:column;
align-items:center;

gap:10px;
padding:25px 30px;
`

const LogoWrapper = styled.div`
 height:100%;
    width:100%;
    min-height:55px;
display:flex;
align-items:center;
justify-content:center;
img{
  height:55px;
width:55px;
   
}
p{
font-size: 14px;
font-weight: 500;
line-height: 16px;
color:#2E2E2E66;
}
`
const UploadBtn = styled.button`
width:100px;
height:34px;
display:flex;
align-items:center;
justify-content:center;
border-radius:4px;
border: 1px solid #006fff;
background:${({ theme }) => theme.editLeague.saveAllBtnBg};
color:${({ theme }) => theme.editLeague.saveAllBtnColor};
font-family: Roboto;
font-size: 14px;
font-weight: 500;
line-height: 20px;
cursor:pointer;
`
const SearchTeamWrapper = styled.div`
display:flex;
flex-direction:column;
max-height:200px;
position:relative;
`
const SearchTeamList = styled.div`
display:flex;
flex-direction:column;
background: ${({ theme }) => theme.editLeague.readOnlyInputs};
position:absolute;
z-index:2;
top:36px;
max-height:200px;
overflow:auto;
width:100%;
border:1px solid #bac3e8;
border-top:none;
border-radius: 0 0 10px 10px;
`
const TeamSearchElement = styled.div`
display:flex;
cursor:pointer;
  padding: 5px 15px;
  align-items: center;
  gap:5px;
  img{
    max-height:14px;
  }
  p{
    opacity:0.8;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color:${({ theme }) => theme.editLeague.readOnlyInputsColor};
  }
  span{

  }
`
const AddNewTeamWrapper = styled.div`
display:flex;
gap:20px;
height:100%;

`