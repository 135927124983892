import { PATCH } from "../APiHelper/API";

export const updateSquadsForGamePmCompeltedAPI = (
  data,
  onSuccess,
  onError
) => {

  PATCH(`/api/v1/games/squads_after_complete`, data, onSuccess, onError);
};
