import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CopyButtonSvg } from "../../../assets/icons/BettingIcons/duplicate.svg";
import { ReactComponent as NoteButtonSvg } from "../../../assets/icons/BettingIcons/notePencil.svg";
import "rc-time-picker/assets/index.css";



import { useSelector } from "react-redux";
import { selectUser } from "../../../Redux/user/user";
import {
  formatted_date_for_backend,
  format_time_to_timezone,
} from "../../../helper/Generic/dateTimeFormat";
import { copyToClipBoard } from "../../../helper/Generic/CopyInfo";
import { checkFlag, getCountryCode } from "../../../helper/Generic/GetFlag";


const RetailLoggedBetsElement = ({
  indexbg,
  data,
  handleNote,
}) => {

  let bet = data.bet;
  let runshop = data.runshop;

  const user = useSelector(selectUser);
  const timezonedBetStartTime = format_time_to_timezone(
    bet.start_time,
    user.timezone
  );

  const startTime = timezonedBetStartTime.format("HH:mm");
  const startDate = timezonedBetStartTime.format("YYYY-MM-DD");

  const [countryCode, setCountryCode] = useState(null);

  const handle_copy = () => {
    copyToClipBoard(
      bet.home_team,
      bet.away_team,
      bet.competition_name,
      bet.selection,
      bet.min_price,
      bet.country_name
    );
  };

  useEffect(() => {
    getCountryCode(bet, setCountryCode);
  }, [bet]);



  return (
    <Container indexbg={indexbg}>

      <ButtonCell>
        <button onClick={() => handle_copy()}>
          <CopyButtonSvg />
        </button>
      </ButtonCell>

      <TierCell>
        <Tiercomponent>
          <h2>{bet.tier}</h2>
        </Tiercomponent>
      </TierCell>

      <TimeCell>
        <p>{startTime}</p>
        <p>{startDate}</p>
      </TimeCell>

      <GameCell>
        <h2>
          {bet.home_team} - {bet.away_team}
        </h2>
        <div>
          <img src={checkFlag(countryCode)} alt="" />
          <p>{bet.competition_name}</p>
        </div>
      </GameCell>
      <MarketCell>
        <p>{bet.market}</p>
      </MarketCell>
      <SelectionCell>
        <p>{bet.selection}</p>
      </SelectionCell>
      <TargetCell>
        <p>{bet.target}</p>
      </TargetCell>
      <MinPriceCell>
        <p>{bet.min_price.toFixed(2)}</p>
      </MinPriceCell>
      <MktCell>
        <p>{data.market_price}</p>
      </MktCell>
      <MktValueCell>
        <p>{data.market_value != undefined ? data.market_value.toFixed(2) : ""}</p>
      </MktValueCell>
      <Cell style={{ minWidth: "70px" }}></Cell>
      <Cell style={{ minWidth: "60px" }}></Cell>
      <Cell style={{ minWidth: "70px" }}></Cell>
      <StakeCell>
        <p>{runshop.stake}</p>
      </StakeCell>
      <PriceCell>
        <p>{runshop.price}</p>
      </PriceCell>
      <Cell style={{ minWidth: "60px" }}></Cell>
      <Cell style={{ minWidth: "50px" }}></Cell>
      <NotesCell>
        <button onClick={() => handleNote(bet)}>
          <NoteButtonSvg />
        </button>
      </NotesCell>



    </Container>
  );
};

export default RetailLoggedBetsElement;
const Container = styled.div`
  display: flex;
  padding: 6px 14px;
  background: ${(props) =>
    props.indexbg % 2
      ? ({ theme }) => theme.matchElementBgindexL
      : ({ theme }) => theme.matchElementBg};
  color: ${({ theme }) => theme.leagueColor};
  color: rgba(58, 57, 87, 1);
  column-gap: 9px;
  align-items: center;

  .right {
    width: 100%;
    display: flex;
    column-gap: 14px;
  }
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  width: 100%;
  .positive-color {
    color: #23a760;
  }
  .negative-color {
    color: #ec4848;
  }
  p {
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }
  .numberFormatter {
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }
`;

const ButtonCell = styled(Cell)`
  min-width: 30px;
  max-width: 30px;
  cursor: pointer;
  svg {
    fill: ${({ theme }) => theme.matchElementBg};
  }
  button {
    cursor: pointer;
    background: transparent;
    border: none;
  }
  svg {
    height: 20px;
    width: 20px;
  }
`;
const TimeCell = styled(Cell)`
  justify-content: start;
  flex-direction: column;
  width: 75px;
  p {
    color: ${({ theme }) => theme.bettTextColor};
  }
`;
const MktCell = styled(Cell)`
  min-width: 40px;
`;
const GameCell = styled(Cell)`
  flex-direction: column;
  align-items: flex-start;
  min-width: 150px;
  row-gap: 2px;
  h2 {
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    color: ${({ theme }) => theme.bettTextColor};
  }
  div {
    background: ${({ theme }) => theme.gameCompetitionBg};
    border-radius: 2px;
    column-gap: 3px;
    padding: 2px 3px;
    p {
      font-weight: 400;
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      color: ${({ theme }) => theme.leagueColor};
    }
    display: flex;
    flex-direction: row;
    img {
      height: 10px;
      width: 13px;
    }
  }
`;
const SelectionCell = styled(Cell)`
  min-width: 100px;
  justify-content: left;
  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.bettTextColor};
  }
`;


const Stake = styled(Cell)`
  min-width: 60px;
  p {
    padding: 4px 9px;
  }
  input {
    color: ${({ theme }) => theme.bettTextColor};
    background: ${({ theme }) => theme.matchElementBg};
    :focus-visible {
      outline: none;
    }
    cursor: text;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    width: 100%;
    border: 0.7px solid #cbd7f5;
    text-align: center;
    border-radius: 2px;
    padding: 4px 9px;
  }
`;

const NoteWrapper = styled.div`
  @media screen and (max-width: 1820px) {
    position: absolute;
    right: 10px;
    height: 100%;
  }
`;


const TierCell = styled(Cell)`
  display: flex;
  align-items: ceter;
  justify-content: center;
  min-width: 30px;
  max-width: 30px;
`;
const Tiercomponent = styled.div`
  display: flex;
  align-items: ceter;
  justify-content: center;
  background: #ffffff;
  border: 0.7px solid #cbd7f5;
  border-radius: 3px;
  height: 19px;
  width: 20px;
  h2 {
    
    font-weight: 400;
    font-size: 9px;
    line-height: 19px;
    color: rgba(0, 18, 41, 0.7);
  }
`;

const MarketCell = styled(Cell)`
min-width:100px;
`
const TargetCell = styled(Cell)``
const MinPriceCell = styled(Cell)``
const StakeCell = styled(Cell)`
min-width:70px;
`
const PriceCell = styled(Cell)`
min-width:40px;
`
const MktValueCell = styled(Cell)`
min-width:40px;
`
const NotesCell = styled(Cell)`
min-width:40px;
button {
    background: transparent;
    border: none;
  }
  svg {
    cursor: pointer;
    fill: ${({ theme }) => theme.matchElementBg};
  }
`