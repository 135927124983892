import React from "react";
import styled from "styled-components";
import { ReactComponent as CheckSvg } from "../../../assets/icons/check/check.svg";
import { ReactComponent as PlusSvg } from "../../../assets/icons/check/plus.svg";
import cloneDeep from "lodash.clonedeep";
import { notifyError, notifyInfo } from "../../../helper/Generic/Notifications";

const TeamsList = ({ teamsList, selectedTeams, setSelectedTeams, allTeams, setNewTeams, deletedTeams, initialTeams, editCupModal }) => {

  var oldTeamList = selectedTeams;

  const handleClick = (item, e) => {
    let copy = cloneDeep(selectedTeams);
    let found = selectedTeams.find((team) => team.id === item.id);
    let sameParticipant = selectedTeams.find((team) => team.participant_id === item.participant_id)
    if (e.currentTarget.checked) {
      if (found) {
        let filtered = copy.filter((element) => element.id !== found.id)
        let sorted = filtered.sort((a, b) => a.name > b.name ? 1 : -1)
        setSelectedTeams(sorted)
      } else {
        if (sameParticipant) {
          notifyInfo("This team is already in the list")
        }
        copy.push(item)
        let sorted = copy.sort((a, b) => a.name > b.name ? 1 : -1)
        setSelectedTeams(sorted);

        if (editCupModal) {
          setNewTeams((prev) => {
            return [...prev, item];
          })
        }

      }
    }
  };


  const handleSelectAll = (e) => {
    let selectedTeamsCopy = cloneDeep(selectedTeams);

    if (e.currentTarget.checked) {
      teamsList.forEach(element => {
        let found = allTeams.find((item) => item.id === element.id);
        if (!found) {
          selectedTeamsCopy.push(element)
        }

        let sorted = selectedTeamsCopy.sort((a, b) => a.name > b.name ? 1 : -1)
        setSelectedTeams(sorted)
      });
    } else {
      if (editCupModal) {
        setSelectedTeams(initialTeams)
      } else {
        setSelectedTeams([])
      }

    }

  }

  const checkSelectedAll = () => {
    let mappedIds = teamsList.map((team) => { return team.id })
    let selectedAll = true
    mappedIds.forEach(element => {
      let found = allTeams.find((team) => team.id === element)
      if (!found) {
        selectedAll = false
      }
    });
    return selectedAll
  }




  const checkSelectedAndDeleted = (item) => {
    var selectedAndDeleted = deletedTeams?.find((element) => element.id === item.id)
    if (selectedAndDeleted) {
      return true
    } else {
      return false
    }

  }
  return (
    <Container>
      <ul>
        {teamsList.length > 0 &&

          <SelectAllWrapper >
            <input
              type="checkbox"
              readOnly
              checked={checkSelectedAll()}
              onClick={(e) => handleSelectAll(e)}
            />
            <p>Select All</p>
          </SelectAllWrapper>

        }

        {teamsList?.map((item, index) => (
          <LeagueItem key={index} draggable isItemSelectedAndDeleted={checkSelectedAndDeleted(item)}>
            <div>
              <img src={item.logo_url} alt=""></img>
              <p>{item.name} <span>({item.season.name})</span> </p>
            </div>
            <div >
              <label className="checkContainer" >
                <input
                  key={index}
                  checked={allTeams?.some(element => element.id === item.id)}
                  value={item}
                  onClick={(e) => handleClick(item, e)}
                  type="checkbox"
                  readOnly
                ></input>
                {allTeams?.some(element => element.id === item.id) ? <CheckSvg /> : <PlusSvg />}

                <span ></span>
              </label>
            </div>
          </LeagueItem>
        ))}
      </ul>
    </Container >
  );
};

export default TeamsList;
const Container = styled.div`
  position:relative;
  list-style-type: none;
  overflow-x:auto;
  display:flex;

`;
const LeagueItem = styled.li`
cursor:pointer;
  padding: 5px 15px;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
width:240px;
  div {
    span{
      font-size:12px;
      opacity:0.8;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
    }
    img {
      height: 14px;
      width: 18px;
      border-radius: 4px;
    }
    svg {
      fill:${(props) => props.isItemSelectedAndDeleted ? "red" : "var(--primaryBlue)"};
      height: 14px;
      width: 18px;
      border-radius: 4px;
    }
    align-items: center;
    display: flex;
    column-gap: 10px;
    color: rgba(0, 18, 41, 1);
  }
  .checkContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .checkContainer input {
    display: none;
  }
  .checkContainer input:checked ~ svg {
    background: #ebf3ff;
    fill:${(props) => props.isItemSelectedAndDeleted ? "red" : "var(--primaryBlue)"};
    border: 1px solid var(--primaryBlue);
  }
  .checkContainer
   svg {
    padding: 2px 3px;
    border: 1px solid ${({ theme }) => theme.groupPlayersModal.checkFill};
    border-radius: 2px;
    background: transparent;
    fill:${({ theme }) => theme.groupPlayersModal.checkFill};
  }
`;

const SelectAllWrapper = styled.div`
display:flex;
width:100%;
justify-content:end;
align-items:center;
padding-right:15px;
p{
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
     color: ${({ theme }) => theme.editLeague.selectMenuTextColor};
}
input{
  height:10px;
}
`
