import { POST } from "../../../APiHelper/API"

export const undoRemoveTeamFromCupAPI = (
    team_id,
    cup_id,
    onSuccess,
    onError
) => {
    const data = {
        team_id: team_id,
        cup_id: cup_id
    }
    POST("/api/v1/cups/undo_remove_team", data, onSuccess, onError)
}