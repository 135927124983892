import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";
import { PitchConditionList } from "../../../helper/Values/TeamFormation";
const PitchConditionMenu = ({ label, selectedValue, setSelectedValue, disabled, changeMenuValue }) => {
    const ref = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleSelectItem = (item) => {
        changeMenuValue(setSelectedValue, item);
        handleMenuOpen();
    };

    const handleMenuOpen = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        } else {
            setIsMenuOpen(true);
        }
    };

    const checkCondition = (pitchCondition) => {
        if (!pitchCondition) {
            return;
        }

        if (pitchCondition.toLowerCase() == "excellent") {
            return "#47B77B";
        }
        if (pitchCondition.toLowerCase() == "good") {
            return "#A7D56E";
        }
        if (pitchCondition.toLowerCase() == "average") {
            return "#D8B70C";
        }
        if (pitchCondition.toLowerCase() == "poor") {
            return "#F77B7B";
        }
        if (pitchCondition.toLowerCase() == "terrible") {
            return "#896748";
        }
    };
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    return (
        <Container ref={ref}>
            <DropDownMenu onClick={() => !disabled && handleMenuOpen()} disabled={disabled} isMenuOpen={isMenuOpen} condition={selectedValue && checkCondition(selectedValue)}>
                <p>{selectedValue ? selectedValue : ""}</p>
                {isMenuOpen ? <UpSvg /> : (!disabled && <DownSvg />)}
            </DropDownMenu>
            {isMenuOpen && (
                <DropDownList>
                    {PitchConditionList.map((item, index) => (
                        <DropDownMenuItem key={index} onClick={() => handleSelectItem(item.value)}>
                            <input type={"radio"}
                                checked={item.value == selectedValue}
                                onChange={() => ""}
                            ></input>
                            <p>{item.label}</p>
                        </DropDownMenuItem>
                    ))}
                </DropDownList>
            )}
        </Container>
    );
};

export default PitchConditionMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  position: relative;
  cursor:pointer;
`;
const DropDownMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.matchPreview.selectMenuBg};
  padding: 11px 15px;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  border-radius: ${(props) => (props.isMenuOpen ? "8px 8px 0 0" : "")};
  p {
    text-transform:capitalize;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.condition != undefined ? props.condition : ({ theme }) => theme.matchPreview.selectMenuTextColor};

  }
  svg {
    cursor: pointer;
    fill: ${({ theme }) => theme.matchPreview.selectMenuSvgBg};
    stroke: ${({ theme }) => theme.matchPreview.selectMenuSvgColor};
  }
`;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  border: 1px solid #bac3e8;
  top: 39px;
  border-top: 1px solid #3c4a5c;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  max-height: 400px;
  width: 200px;
  overflow-x: hidden;
  color: black;

`;
const DropDownMenuItem = styled.li`
  display: flex;
  align-items: center;
  column-gap: 5px;
  background-color: ${({ theme }) => theme.matchPreview.selectMenuBg};
  cursor: pointer;
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;
  padding: 5px 10px;
  
  div {
    column-gap: 5px;
    align-items: center;
    display: flex;
    flex-direction: row;
    .check {
      margin-right: 5px;
      background: red;
      color: red;
    }
  }

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.matchPreview.textColor};
    text-transform: capitalize;
  }
`;
