import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";
import { ReactComponent as SearchSvg } from "../../../assets/icons/search.svg";

const StadiumsMenuSingleOption = ({
  label,
  dataList,
  value,
  setValue,
  disabled,
  leagueToEdit,
}) => {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState("");

  const [valuesToDisplay, setValuesToDisplay] = useState([]);

  const handleCheck = (item) => {
    setValue(item);
    handleMenuOpen();
  };

  const handleSearchInput = (e) => {
    setInputSearch(e.target.value);
  };

  const filterSearch = (items, filter) => {
    if (filter === "") {
      return items;
    } else {
      return items.filter((item) =>
        item.name.toLowerCase().includes(filter.toLowerCase())
      );
    }
  };

  const handleMenuOpen = () => {
    if (!disabled) {
      if (isMenuOpen) {
        setIsMenuOpen(false);
      } else {
        setIsMenuOpen(true);
      }
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
        setInputSearch("")
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    if (!leagueToEdit) {

      return;
    }
    if (!dataList) {
      return
    }

    let display = [];
    let stadiumsWithCountry = dataList?.filter((item) => {
      return item.country != null;
    });

    if (stadiumsWithCountry) {
      display = stadiumsWithCountry.filter((item) => {
        return item.country.country_id == leagueToEdit.country.country_id;
      });
    }

    let sorted = display.sort((a, b) => a.name > b.name ? 1 : -1);

    setValuesToDisplay(sorted);
  }, [leagueToEdit, dataList]);

  return (
    <Container ref={ref}>
      <DropDownMenu
        onClick={() => handleMenuOpen()}
        isMenuOpen={isMenuOpen}
        disabled={disabled}
      >
        {value != null ? <h4>{value.name}</h4> : <h4>{label}</h4>}

        {isMenuOpen ? <UpSvg /> : <DownSvg />}
      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>
          <DropDownMenuItem>
            <div className="searchGroup">
              <SearchSvg />
              <input
                type={"text"}
                value={inputSearch}
                onChange={handleSearchInput}
                placeholder="Search"
              ></input>
            </div>
          </DropDownMenuItem>
          {
            inputSearch != "" ?
              (filterSearch(valuesToDisplay, inputSearch)?.map((item, index) => (
                <DropDownMenuItem key={index} onClick={(e) => handleCheck(item)}>
                  <div className={item === value ? "active" : "nonActive"}>
                    <input
                      key={index}
                      readOnly
                      //onChange={(e) => handleCheck(e, item)}
                      className="check"
                      type="radio"
                      checked={value?.id == item.id}
                      value={item}
                    ></input>
                    <p> {item.name}</p>
                  </div>
                </DropDownMenuItem>
              ))) :
              (valuesToDisplay?.map((item, index) => (
                <DropDownMenuItem key={index} onClick={(e) => handleCheck(item)}>
                  <div className={item === value ? "active" : "nonActive"}>
                    <input
                      key={index}
                      readOnly
                      //onChange={(e) => handleCheck(e, item)}
                      className="check"
                      type="radio"
                      checked={value?.id == item.id}
                      value={item}
                    ></input>
                    <p> {item.name}</p>
                  </div>
                </DropDownMenuItem>
              )))
          }

        </DropDownList>
      )}
    </Container>
  );
};

export default StadiumsMenuSingleOption;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  position: relative;

  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: rgba(60, 74, 92, 1);
  }
`;
const DropDownMenu = styled.div`
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.matchPreview.selectMenuBg};
  padding: 4px 10px;
  border-radius: 8px;
  border-radius: ${(props) => (props.isMenuOpen ? "8px 8px 0 0" : "")};
  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
  }
  svg {
    cursor: pointer;
    fill: ${({ theme }) => theme.matchPreview.selectMenuSvgBg};
    stroke: ${({ theme }) => theme.matchPreview.selectMenuSvgColor};
  }
`;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top: 40px;
  border-top: 1px solid black !important;
  border-color: #3c4a5c;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  max-height: 400px;
  width: 100%;
  overflow-x: hidden;
  color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};

  .searchGroup {
    display: flex;
    position: relative;

    input {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      height: 20px;
      background: #ffffff;
      border: 1px solid #d9dfe7;
      border-radius: 4px;
      width: 100%;
      padding-left: 20px;
      background-color: ${({ theme }) => theme.matchPreview.selectMenuBg};
      color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
      &::placeholder{
        color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
        opacity:0.7;
      }
    }

    svg {
      height: 10px;
      position: absolute;
      top: 5px;
      left: 5px;
    }
  }
`;
const DropDownMenuItem = styled.li`
  cursor: pointer;
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;
  background-color: ${({ theme }) => theme.matchPreview.selectMenuBg};

  padding: 5px 10px;
  div {
    column-gap: 5px;
    align-items: center;
    display: flex;
    flex-direction: row;
    .check {
      margin-right: 5px;
      background: red;
      color: red;
    }
  }
  p {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
   
  }


`;
