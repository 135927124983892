/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DerbyTeamsMenu from "../Menu/DerbyTeamsMenu";
import { ReactComponent as PlusSvg } from "../../../assets/icons/LeaguesIcons/plusIcon.svg";
import { useLeaguesDataService } from "../../../context/useLeaguesDataService";
import { notifyError, notifySucces } from "../../../helper/Generic/Notifications";
import AddNewStadium from "./AddNewStadium";
import TeamHaveStadium from "./TeamHaveStadium";
import LoaderSpinner from "../../../helper/LoaderSpinner";
import PenaltyTakerMenu from "../Menu/PenaltyTakerMenu";
import { sortTeamsAZ } from "../../../helper/Analyst/LeaguesData/sortTeamsFromLeague";
import cloneDeep from "lodash.clonedeep";
import DeleteConfirmationDouble from "../../DeleteConfirmationDouble";

const TeamDetailsOption = ({
  leagueToEdit,
  refreshCurrentLeague,
  setRefreshListOnClose,
  setLoading,
  closeWindow,
  leaguesListForSeason,
  allCountries,
  handleSelectTeamToEdit,
  teamID,
  refreshAfterDeleteLeague

}) => {
  const {
    get_stadiums,
    add_stadium_to_team,
    update_team_to_new_stadium,
    get_team_details,
    get_players_for_team,
    move_team,
    add_penalty_takers,
    get_derbies_for_team,
    teamsList,
    add_derby_to_team,
    remove_other_teams_from_stadium,
    update_stadium,
    delete_league,
    remove_team_from_stadium
  } = useLeaguesDataService();

  //let [teamToEdit, setTeamToEdit] = useState(leagueToEdit.teams[0]);
  const [teamToEdit, setTeamToEdit] = useState(teamID);
  const initialInputValues = {
    stadiumName: "",
    capacity: {
      value: "",
      floatValue: "",
      formattedValue: ""
    },
    length: {
      value: "",
      floatValue: "",
      formattedValue: ""
    },
    width: {
      value: "",
      floatValue: "",
      formattedValue: ""
    },
    stadiumLocation: "",
    altitude: {
      value: "",
      floatValue: "",
      formattedValue: ""
    },
    latitude: "",
    longitude: "",
    team_not_listed: false,
    other_sports: false
  };

  const [surface, setSurface] = useState();
  const [raceTrack, setRaceTrack] = useState();
  const [stadiums, setStadiums] = useState([]);
  const [stadium, setStadium] = useState();
  const [stadiumCountry, setStadiumCountry] = useState();
  const [inputValues, setInputvalues] = useState(initialInputValues);
  const [addNewStadium, setAddNewStadium] = useState(false);
  const [readyToSave, setReadyToSave] = useState(false);

  const [lowDerby, setLowDerby] = useState([]);
  const [midDerby, setMidDerby] = useState([]);
  const [highDerby, setHighDerby] = useState([]);
  const [readyToMove, setReadyToMove] = useState(false);
  const [selectedLeagues, setSelectedLeagues] = useState([]);
  const [derbyChanged, setDerbyChanged] = useState(true);
  const [addNewDerbyRow, setAddNewDerbyRow] = useState(false);

  const [newLowDerby, setNewLowDerby] = useState([]);
  const [newMidDerby, setNewMidDerby] = useState([]);
  const [newHighDerby, setNewHighDerby] = useState([]);

  const [firstPTaker, setFirstPTaker] = useState();
  const [secondPTaker, setSecondPTaker] = useState();
  const [thirdPTaker, setThirdPTaker] = useState();
  const [firstPTakerInitial, setFirstPTakerInitial] = useState();
  const [secondPTakerInitial, setSecondPTakerInitial] = useState();
  const [thirdPTakerInitial, setThirdPTakerInitial] = useState();
  const [pkTakerChanged, setPkTakerChanged] = useState(null);

  const [derbyResponse, setDerbyResponse] = useState([]);
  const [derbiesToSend, setDerbiesToSend] = useState([]);
  const [sharedStadium, setSharedStadium] = useState(false);
  const [sharedTeams, setSharedTeams] = useState([]);
  const [newSharedTeams, setNewSharedTeams] = useState([]);
  const [sharedTeamsToRemove, setSharedTeamsToRemove] = useState([]);

  const [sharedChanged, setSharedChanged] = useState(false);
  const [readyToaddNewSharedTeam, setReadyToAddNewSharedTeam] = useState(false);
  const [addNewSharedTeam, setAddNewSharedTeam] = useState(false);
  const [readyToaddNewStadium, setReadyToAddNewStadium] = useState(false);
  const [changeStadiumDetails, setChangeStadiumDetails] = useState(false);
  const [readyToChoseAnotherStadium, setReadyToChoseAnotherStadium] =
    useState(false);
  const [readyToRemoveSharedTeam, setReadyToRemoveSharedTeam] = useState(false);
  const [players, setPlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDerby, setIsLoadingDerby] = useState(false);
  const stadiumId = useRef();

  const handleInputChange = (e) => {

    const { name, value } = e.target;

    setInputvalues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleCheckInputChange = (e) => {
    const { name, value, checked } = e.target;
    setInputvalues({
      ...inputValues,
      [name]: checked,
    });
  };

  const handleCustomInputChange = (event, val) => {
    if (event.event != undefined) {
      const name = event.event.target.name;
      const value = val;

      setInputvalues({
        ...inputValues,
        [name]: value
      });
    }
  };

  const handleGetPlayers = () => {
    //setPlayers(null);
    setIsLoading(true);
    get_players_for_team(
      teamToEdit.id,
      function (response) {
        setPlayers(response.data.players);
        // setIsLoading(false);
      },
      function (error) {
        notifyError("Something Wrong...");
      }
    );
  };

  const handleInsertNewStadium = () => {
    setStadium(null);
    setAddNewStadium(true);
    setInputvalues(initialInputValues);
    setRaceTrack(null);
    setStadiumCountry(null);
    setSurface(null);

  };

  const handleDeleteLeague = (league_id) => {
    setLoading(true);
    delete_league(
      league_id,
      function () {
        setLoading(false);
        notifySucces("League deleted");
        setRefreshListOnClose(true);
        refreshAfterDeleteLeague();
      },
      function (error) {
        setLoading(false);
        notifyError("Something wrong");
      }
    );
  };

  const handleAddNewStadium = () => {
    add_stadium_to_team(
      teamToEdit.id,
      inputValues.stadiumName,
      inputValues.capacity.floatValue,
      inputValues.length.floatValue,
      inputValues.width.floatValue,
      surface,
      inputValues.stadiumLocation,
      inputValues.latitude,
      inputValues.longitude,
      inputValues.altitude.floatValue,
      inputValues.team_not_listed,
      inputValues.other_sports,
      raceTrack,
      stadiumCountry.country_id,
      function (response) {
        notifySucces("Stadium added");
        handleAddnewSharedTeam(response.data.team.stadium.id);
        handleGetTeamDetails();
        setRefreshListOnClose(true);
      },
      function () {
        notifyError("Something wrong...");
      }
    );
  };

  const handleGetStadiums = (onSuccess) => {
    get_stadiums(
      function (response) {
        setStadiums(response);
        onSuccess();
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };

  const populateTeamStadium = () => {
    if (stadium != null) {
      // setAddNewStadium(false);
      setInputvalues({
        stadiumName: stadium.name,
        capacity: {
          value: stadium.capacity,
          floatValue: stadium.capacity,
          formattedValue: stadium.capacity
        },
        length: {
          value: stadium.length,
          floatValue: stadium.length,
          formattedValue: stadium.length
        },
        width: {
          value: stadium.width,
          floatValue: stadium.width,
          formattedValue: stadium.width
        },
        stadiumLocation: stadium.location,
        altitude: {
          value: stadium.altitude,
          floatValue: stadium.altitude,
          formattedValue: stadium.altitude
        },
        latitude: stadium.lat,
        longitude: stadium.long,
        team_not_listed: stadium.team_not_listed,
        other_sports: stadium.other_sports
      });
      setStadiumCountry(stadium.country);
      setRaceTrack(stadium.race_track);
      setSurface(stadium.surface);
      setIsLoading(false);
      setSharedTeamsToRemove([]);
      setNewSharedTeams(null);
    } else {
      // setAddNewStadium(false);
      setSharedStadium(false);
      setSharedTeams([]);
      setIsLoading(false);
      setSharedTeamsToRemove([]);
      setNewSharedTeams(null);
    }
  };

  const populateStadiumWithSharedTeams = () => {
    setNewSharedTeams([]);
    setAddNewSharedTeam(false);
    let teams = [];
    if (stadium != null) {
      if (stadium.teams.length >= 1) {
        stadium.teams.forEach((item) => {
          if (item.league.season_id == leagueToEdit.season_id) {
            teams.push(item);
          }
        });

        let index = teams.findIndex((item) => item.id === teamToEdit.id);

        if (index != -1) {
          teams.splice(index, 1);
        }

        if (teams.length > 0) {
          setSharedTeams(teams);
          setSharedStadium(true);
        }
        else if
          (stadium.other_sports || stadium.team_not_listed) {
          setSharedStadium(true);
          setSharedTeams([]);
        }
        else {
          setSharedStadium(false);
          setSharedTeams([]);
        }
      }

      else if (stadium.other_sports || stadium.team_not_listed) {
        setSharedStadium(true);
        setSharedTeams([]);
      }
      else {
        setSharedStadium(false);
        setSharedTeams([]);
      }
    }
  };

  const findCompetition = (competition) => {
    let found = leaguesListForSeason.find(
      (element) => element.id == competition.id
    );
    setSelectedLeagues(found);
  };

  const findStadium = (wanted_stadium_id) => {
    let found;
    if (wanted_stadium_id != null) {
      found = stadiums.find((item) => item.id === wanted_stadium_id);
    } else found = null;
    return found;
  };

  const setCurrentStadium = (id) => {
    let found = findStadium(id);
    setStadium(found);
  };

  const handleGetTeamDetails = () => {
    if (!teamID) {
      return;
    }
    setIsLoading(true);
    setIsLoadingDerby(true);

    get_team_details(
      teamID.id,
      function (response) {
        setTeamToEdit(response.data.team);
        if (response.data.team.stadium) {
          stadiumId.current = response.data.team.stadium.id;
          // setIsLoading(false);
          // setIsLoadingDerby(false);
        } else {
          stadiumId.current = null;
          // setIsLoading(false)
          // setIsLoadingDerby(false);
        }
        findCompetition(response.data.team.league);
      },
      function (error) {
        notifyError("Something wrong");
        setIsLoading(false);
      }
    );
  };



  const handleChoseStadiumFromList = () => {
    update_team_to_new_stadium(
      teamToEdit.id,
      stadium.id,
      function () {
        notifySucces("Stadium changed");
        handleGetTeamDetails();
        handleGetStadiums();
        setRefreshListOnClose(true);
      },
      function (error) {
        notifyError("Something wrong...");
      }
    );
  };

  const handleRemoveSharedTeamFromStadium = (teamList) => {
    let contor = 0;

    teamList.forEach((item, index) => {
      contor = contor + 1;
      if (item.id != null) {
        remove_team_from_stadium(
          item.id,
          function () {
            notifySucces("Shared team removed from stadium");
            if (contor === sharedTeamsToRemove.length) {
              setSharedTeamsToRemove([]);
              setReadyToRemoveSharedTeam(false);
              setRefreshListOnClose(true);
              setRefreshListOnClose(true);
              handleGetStadiums();
            }
          },
          function () {
            notifyError("Something wrong...");
            setIsLoading(false);
          }
        );

      }
    });


  };

  const handleAddnewSharedTeam = (stadium_id) => {
    let contor = 0;
    let notNullItems = newSharedTeams.filter((item) => item.id != null);
    notNullItems.forEach((item, index) => {
      contor = contor + 1;
      update_team_to_new_stadium(
        item.id,
        stadium_id,
        function () {
          notifySucces("New shared team added");
          if (contor === notNullItems.length) {
            setNewSharedTeams([]);
            handleGetStadiums();
            setAddNewSharedTeam(false);
            setRefreshListOnClose(true);
            setRefreshListOnClose(true);
          }
        },
        function (error) {
          notifyError("Something wrong...");
        }
      );

    });
  };

  const handleEditStadium = () => {

    update_stadium(
      stadium.id,
      inputValues.stadiumName,
      inputValues.capacity.floatValue,
      inputValues.length.floatValue,
      inputValues.width.floatValue,
      surface,
      inputValues.stadiumLocation,
      inputValues.latitude,
      inputValues.longitude,
      inputValues.altitude.floatValue,
      inputValues.team_not_listed,
      inputValues.other_sports,
      raceTrack,
      stadiumCountry.country_id,
      function () {
        handleGetStadiums();
        setRefreshListOnClose(true);
        notifySucces("Stadium info updated");
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };

  const handleSaveButton = () => {

    if (addNewStadium == true && readyToaddNewStadium) {
      handleAddNewStadium();
    }
    if (
      readyToChoseAnotherStadium &&
      addNewStadium == false &&
      teamToEdit.id != null
    ) {
      handleChoseStadiumFromList();
    }

    if (changeStadiumDetails) {
      handleEditStadium();
    }

    if (derbyChanged) {
      handleChangeDerby();
    }
    if (sharedChanged) {
      handleSharedStadium();
    }
    if (readyToaddNewSharedTeam) {
      handleAddnewSharedTeam(stadium.id);
    }
    if (readyToRemoveSharedTeam) {
      handleRemoveSharedTeamFromStadium(sharedTeamsToRemove);
    }

    if (pkTakerChanged) {
      handleAddPenaltyTakers(teamToEdit.id);
    }

  };

  const resetDerbyFiels = () => {
    setDerbiesToSend([]);
    setNewLowDerby([]);
    setNewMidDerby([]);
    setNewHighDerby([]);
    setAddNewDerbyRow(false);
  };

  const handleAddPenaltyTakers = (teamId) => {
    let primary_penalty_taker_id = null;
    let second_penalty_taker_id = null;
    let third_penalty_taker_id = null;

    if (firstPTaker) {
      primary_penalty_taker_id = firstPTaker.id;
    }
    if (secondPTaker) {
      second_penalty_taker_id = secondPTaker.id;
    }
    if (thirdPTaker) {
      third_penalty_taker_id = thirdPTaker.id;
    }

    add_penalty_takers(
      teamId,
      primary_penalty_taker_id,
      second_penalty_taker_id,
      third_penalty_taker_id,
      function (response) {
        handleGetTeamDetails();
        notifySucces("Penalty takers modified");
      },
      function (error) {
        notifyError("Something wrong...");
      }
    );
  };

  const handleChangeDerby = () => {
    setIsLoadingDerby(true);
    add_derby_to_team(
      teamToEdit.id,
      derbiesToSend,
      function () {
        notifySucces("Derbies added");
        populate_derbies_for_team();
        resetDerbyFiels();
        setRefreshListOnClose(true);
      },
      function () {
        setIsLoadingDerby(false);
        notifyError("Something wrong...");
      }
    );
  };

  // const handleMoveTeam = () => {
  //   setIsLoading(true);
  //   move_team(
  //     teamToEdit.id,
  //     selectedLeagues.id,
  //     function (response) {
  //       notifySucces("Team moved");
  //       refreshCurrentLeague(function () { });
  //       setRefreshListOnClose(true);
  //       setIsLoading(false);
  //       closeWindow(true);
  //     },
  //     function (error) {
  //       setIsLoading(false);
  //       setIsLoading(false);
  //       notifyError("something wrong");
  //     }
  //   );
  // };

  const handleSharedStadium = () => {
    handleRemoveShared();
  };



  const handleRemoveShared = () => {
    remove_other_teams_from_stadium(
      teamToEdit.id,
      teamToEdit.stadium.id,
      function () {
        notifySucces("Removed shared");
        updateWithFalseForCheckInput();
        // handleGetStadiums();
        // setRefreshListOnClose(true);
      },
      function (error) {
        notifyError("Something wrong...");
      }
    );
  };

  //update stadium info team_not_listed,other_sports FALSE
  const updateWithFalseForCheckInput = () => {
    update_stadium(
      stadium.id,
      inputValues.stadiumName,
      inputValues.capacity.floatValue,
      inputValues.length.floatValue,
      inputValues.width.floatValue,
      surface,
      inputValues.stadiumLocation,
      inputValues.latitude,
      inputValues.longitude,
      inputValues.altitude.floatValue,
      false, //inputValues.team_not_listed
      false, //inputValues.other_sports
      raceTrack,
      stadiumCountry.country_id,
      function () {
        handleGetStadiums();
        setRefreshListOnClose(true);
        //  notifySucces("Stadium info updated");
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  }

  const checkMaxLength = (array1, array2, array3) => {
    let maxLength = [];
    let maxNr = -999;

    maxLength.push(array1.length);
    maxLength.push(array2.length);
    maxLength.push(array3.length);

    maxLength.forEach((item) => {
      if (item > maxNr) {
        maxNr = item;
      }
    });

    return maxNr;
  };
  const verifyDerbyOrder = (element) => {
    if (element.team_1.id === teamToEdit.id) {
      return {
        derby_type: element.derby_type,
        id: element.id,
        team_1: element.team_1,
        team_2: element.team_2,
      };
    }
    if (element.team_2.id === teamToEdit.id) {
      return {
        derby_type: element.derby_type,
        id: element.id,
        team_1: element.team_2,
        team_2: element.team_1,
      };
    }
  };

  const createDerbyRows = (maxRows, derbies, type) => {
    let list = [];
    let obj = {
      derby_type: type,
      id: null,
      team_1: null,
      team_2: null,
    };

    for (let index = 0; index < maxRows; index++) {
      const element = derbies[index];
      if (element != undefined) {
        list.push(verifyDerbyOrder(element));
      } else {
        list.push(obj);
      }
    }
    return list;
  };

  const handleDerbiesResponse = (response) => {
    if (response.length < 1) {
      setAddNewDerbyRow(true);
    }

    let derbyToResponse = [];

    let low_derbies = response.filter((derby) => derby.derby_type == "low");
    let mid_derbies = response.filter((derby) => derby.derby_type == "mid");
    let high_derbies = response.filter((derby) => derby.derby_type == "high");

    let maxLength = checkMaxLength(low_derbies, mid_derbies, high_derbies);

    let low_derbies_list = createDerbyRows(maxLength, low_derbies, "low");
    let mid_derbies_list = createDerbyRows(maxLength, mid_derbies, "mid");
    let high_derbies_list = createDerbyRows(maxLength, high_derbies, "high");

    low_derbies.forEach(
      (item, index) =>
        item.team_2 != null &&
        derbyToResponse.push({
          derby_type: "low",
          other_team_id: item.team_2.id,
        })
    );
    mid_derbies.forEach(
      (item, index) =>
        item.team_2 != null &&
        derbyToResponse.push({
          derby_type: "mid",
          other_team_id: item.team_2.id,
        })
    );
    high_derbies.forEach(
      (item, index) =>
        item.team_2 != null &&
        derbyToResponse.push({
          derby_type: "high",
          other_team_id: item.team_2.id,
        })
    );

    setDerbyResponse(derbyToResponse);

    setLowDerby(low_derbies_list);
    setMidDerby(mid_derbies_list);
    setHighDerby(high_derbies_list);
  };

  const objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every((p) => o1[p] === o2[p]);

  const checkDerbyChange = () => {
    let derbies_to_send = [];

    lowDerby.forEach((item, index) => {
      if (item.team_2 != null) {
        derbies_to_send.push({
          derby_type: "low",
          other_team_id: item.team_2.id,
        });
      }
    });
    midDerby.forEach((item, index) => {
      if (item.team_2 != null) {
        derbies_to_send.push({
          derby_type: "mid",
          other_team_id: item.team_2.id,
        });
      }
    });

    highDerby.forEach((item, index) => {
      if (item.team_2 != null) {
        derbies_to_send.push({
          derby_type: "high",
          other_team_id: item.team_2.id,
        });
      }
    });

    setDerbiesToSend(derbies_to_send);

    if (addNewDerbyRow) {
      newLowDerby.forEach((item, index) => {
        if (item.team_2 != null) {
          derbies_to_send.push({
            derby_type: "low",
            other_team_id: item.team_2.id,
          });
        }
      });
      newMidDerby.forEach((item, index) => {
        if (item.team_2 != null) {
          derbies_to_send.push({
            derby_type: "mid",
            other_team_id: item.team_2.id,
          });
        }
      });

      newHighDerby.forEach((item, index) => {
        if (item.team_2 != null) {
          derbies_to_send.push({
            derby_type: "high",
            other_team_id: item.team_2.id,
          });
        }
      });
    }

    if (derbyResponse.length != derbies_to_send.length) {
      setReadyToSave(true);
      setDerbyChanged(true);
    } else {
      let found =
        derbies_to_send.length === derbyResponse.length &&
        derbies_to_send.every((item, index) =>
          objectsEqual(item, derbyResponse[index])
        );
      if (!found) {
        setReadyToSave(true);
        setDerbyChanged(true);
      } else {
        setReadyToSave(false);
        setDerbyChanged(false);
      }
    }
  };

  const handleAddNewDerbyRow = () => {
    if (addNewDerbyRow == false) {
      setAddNewDerbyRow(true);
    }
    const obj = (type) => {
      return {
        derby_type: type,
        id: null,
        team_1: null,
        team_2: null,
      };
    };

    let low = [...newLowDerby];
    let mid = [...newMidDerby];
    let high = [...newHighDerby];

    low.push(obj("low"));
    mid.push(obj("mid"));
    high.push(obj("high"));

    setNewLowDerby(low);
    setNewMidDerby(mid);
    setNewHighDerby(high);
  };

  const populate_derbies_for_team = () => {
    get_derbies_for_team(
      teamToEdit.id,
      function (response) {
        handleDerbiesResponse(response.data.derbies);
        setIsLoadingDerby(false);
      },
      function (error) {
        setIsLoadingDerby(false);
        notifyError("Something wrong...");

      }
    );
  };

  const handleAddnNewSharedTeams = () => {
    if (addNewSharedTeam == false) {
      setAddNewSharedTeam(true);
    }

    let elements = [...newSharedTeams];

    elements.push({ id: null, name: null, team_2: null });
    setNewSharedTeams(elements);
  };

  const checkReadySave = () => {
    if (stadium == null && addNewStadium == true) {
      if (
        inputValues.stadiumName != initialInputValues.stadiumName &&
        inputValues.capacity.floatValue != initialInputValues.capacity.floatValue &&
        inputValues.length.floatValue != initialInputValues.length.floatValue &&
        inputValues.width.floatValue != initialInputValues.width.floatValue &&
        inputValues.stadiumLocation != initialInputValues.stadiumLocation &&
        inputValues.altitude.formattedValue != initialInputValues.altitude.floatValue &&
        inputValues.latitude != initialInputValues.latitude &&
        inputValues.longitude != initialInputValues.longitude &&
        surface != null &&
        raceTrack != null &&
        stadiumCountry != null
      ) {
        setReadyToSave(true);
        setReadyToAddNewStadium(true);
      } else {
        setReadyToSave(false);
        setReadyToAddNewStadium(false);
      }
    }
  };

  const checkReadyToMove = () => {
    if (selectedLeagues?.id != leagueToEdit.id) {
      setReadyToMove(true);
    } else {
      setReadyToMove(false);
    }
  };

  const checkSharedStadium = () => {
    if (stadium != null) {
      if (stadium.teams.length > 1 && sharedStadium === false) {
        setSharedChanged(true);
        return true;
      } else {
        setSharedChanged(false);
        return false;
      }
    }
  };

  const verifyStadiumInputFields = () => {

    if (
      stadium != null &&
      stadiumCountry != null
      // addNewStadium == false &&
      // teamToEdit.stadium != null &&
      // teamToEdit.stadium.country != null
    ) {
      if (
        stadium.altitude != inputValues.altitude.floatValue ||
        stadium.capacity != inputValues.capacity.floatValue ||
        stadium.lat != inputValues.latitude ||
        stadium.long != inputValues.longitude ||
        stadium.length != inputValues.length.floatValue ||
        stadium.location != inputValues.stadiumLocation ||
        stadium.name != inputValues.stadiumName ||
        stadium.race_track != raceTrack ||
        stadium.surface != surface ||
        stadium.width != inputValues.width.floatValue ||
        stadium.other_sports != inputValues.other_sports ||
        stadium.team_not_listed != inputValues.team_not_listed ||
        stadium.country.country_id != stadiumCountry.country_id
      ) {
        setChangeStadiumDetails(true);
        return true;
      } else {
        setChangeStadiumDetails(false);
        return false;
      }
    }
  };

  const checkStadiumChosedFromMenu = () => {
    if (teamToEdit.stadium == null) {
      if (stadium != null && addNewStadium == false) {
        setReadyToChoseAnotherStadium(true);
        return true;
      } else {
        setReadyToChoseAnotherStadium(false);
        return false;
      }
    }

    if (teamToEdit.stadium) {
      if (
        stadium != null &&
        stadium.id != teamToEdit.stadium.id &&
        addNewStadium == false
      ) {
        setReadyToChoseAnotherStadium(true);
        return true;
      } else {
        setReadyToChoseAnotherStadium(false);
        return false;
      }
    }
  };

  const checkNewSharedTeamAdd = () => {
    if (newSharedTeams.length > 0) {

      let notNullItems = newSharedTeams.filter((item) => item.id != null)
      if (notNullItems.length > 0) {
        setReadyToAddNewSharedTeam(true);
        return true;
      } else {
        setReadyToAddNewSharedTeam(false);
        return false;
      }

    } else {
      setReadyToAddNewSharedTeam(false);
      return false;
    }
  };

  const checkRemoveSharedTeam = () => {

    if (sharedTeamsToRemove.length > 0) {
      setReadyToRemoveSharedTeam(true);
      return true;
    } else {
      setReadyToRemoveSharedTeam(false);
      return false;
    }
  };

  const checkReadyToEditStadium = () => {
    let stadiumDetailsChanged = false;
    let stadiumFromMenuChanged = false;
    let sharedTeamsChanged = false;
    let newSharedTeamAdd = false;
    let removeSharedTeam = false;

    stadiumDetailsChanged = verifyStadiumInputFields();
    stadiumFromMenuChanged = checkStadiumChosedFromMenu();
    sharedTeamsChanged = checkSharedStadium();
    newSharedTeamAdd = checkNewSharedTeamAdd();
    removeSharedTeam = checkRemoveSharedTeam();

    if (
      stadiumDetailsChanged ||
      stadiumFromMenuChanged ||
      sharedTeamsChanged ||
      newSharedTeamAdd ||
      removeSharedTeam
    ) {
      setReadyToSave(true);
    } else {
      setReadyToSave(false);
    }
  };

  const initiallySetPenaltyTakers = () => {
    if (!teamToEdit) {
      return;
    }

    setFirstPTakerInitial(teamToEdit.first_penalty_taker);
    setSecondPTakerInitial(teamToEdit.second_penalty_taker);
    setThirdPTakerInitial(teamToEdit.third_penalty_taker);

    setFirstPTaker(teamToEdit.first_penalty_taker);
    setSecondPTaker(teamToEdit.second_penalty_taker);
    setThirdPTaker(teamToEdit.third_penalty_taker);

  };

  const checkPKTakerChanges = () => {
    let primaryChanged = false;
    let secondaryChanged = false;
    let thirdChanged = false;


    if (firstPTakerInitial !== firstPTaker) {
      primaryChanged = true;
    }
    else {
      primaryChanged = false;
    }

    if (secondPTakerInitial != secondPTaker) {
      secondaryChanged = true;
    }
    else {
      secondaryChanged = false;
    }

    if (thirdPTakerInitial != thirdPTaker) {
      thirdChanged = true;
    }
    else {
      thirdChanged = false;
    }


    if (primaryChanged == true || secondaryChanged == true || thirdChanged == true) {
      setPkTakerChanged(true);
      setReadyToSave(true);
    }
    else {
      setPkTakerChanged(false);
      setReadyToSave(false);
    }
  };



  const handleRemoveNewSharedTeamItem = (index) => {
    let clone = cloneDeep(newSharedTeams);
    let deleted = clone.splice(index, 1);
    let newItems = clone.filter((item) => item.id != deleted.id);

    setNewSharedTeams(newItems);
  };

  const handleSetSharedTeamsToRemove = (index) => {
    let clone = cloneDeep(sharedTeams);
    let sharedTeamsToRemoveClone = cloneDeep(sharedTeamsToRemove);
    let deleted = clone.splice(index, 1);
    let newSharedTeams = clone.filter((item) => item.id != deleted.id);

    sharedTeamsToRemoveClone.push(deleted[0]);

    setSharedTeamsToRemove(sharedTeamsToRemoveClone);
    setSharedTeams(newSharedTeams);
  };


  // useEffect(() => {
  //   handleGetStadiums();
  // }, []);

  useEffect(() => {
    handleGetTeamDetails();
  }, [teamID]);

  useEffect(() => {

    handleGetStadiums(
      function () {
        populate_derbies_for_team();
        resetDerbyFiels();
        handleGetPlayers();
        initiallySetPenaltyTakers();
        setAddNewStadium(false);
        if (stadium == null) {
          setIsLoading(false);
        }
      }
    );
  }, [teamToEdit]);

  useEffect(() => {
    checkReadyToMove();
  }, [selectedLeagues]);

  useEffect(() => {
    populateTeamStadium();
    populateStadiumWithSharedTeams();
  }, [stadium]);

  useEffect(() => {
    if (stadiumId) {
      setCurrentStadium(stadiumId.current);
    }
    else {
      setStadium(null);
    }
  }, [stadiums]);

  useEffect(() => {
    checkReadyToEditStadium();
    checkReadySave();
  }, [stadium,
    inputValues,
    surface,
    raceTrack,
    sharedStadium,
    newSharedTeams,
    sharedTeamsToRemove,
    stadiumCountry,
    inputValues.capacity.floatValue,
    inputValues.length.floatValue,
    inputValues.width.floatValue
  ]);

  useEffect(() => {
    checkPKTakerChanges();
  }, [teamToEdit, firstPTaker, secondPTaker, thirdPTaker, players]);

  useEffect(() => {
    checkDerbyChange();
  }, [
    lowDerby,
    midDerby,
    highDerby,
    newHighDerby,
    newLowDerby,
    newMidDerby,
    derbyResponse,
  ]);

  return (
    <Card>
      <CardContent>
        <TeamsList>
          {sortTeamsAZ(leagueToEdit.teams).map((team, index) => (
            <TeamListItem
              key={team.id}
              onClick={() => handleSelectTeamToEdit(team)}
              active={team.id === teamID.id}
            >
              <h4>{team.name}</h4>
            </TeamListItem>
          ))}
        </TeamsList>

        <TeamDetails>
          <h1 className="team-name">{teamToEdit.name}</h1>

          {/* <GroupContainer
            style={{
              height: "fit-content",
              marginBottom: "15px",
              marginTop: "15px",
            }}
          >
            <h2>Team League</h2>
            <div style={{ display: "flex", columnGap: "8px" }}>
              <LeagueMenuSingleOption
                competitions={leaguesListForSeason}
                selectedCompetitions={selectedLeagues}
                setSelectedCompetitions={setSelectedLeagues}
              />

              <MoveButton
                disabled={!readyToMove}
                onClick={() => handleMoveTeam()}
              >
                Move team
              </MoveButton>
            </div>
          </GroupContainer> */}

          <StadiumInfo>
            {isLoading ? (
              <LoaderSpinner />
            ) : stadium != null ? (
              <TeamHaveStadium
                handleInputChange={handleInputChange}
                handleCustomInputChange={handleCustomInputChange}
                handleCheckInputChange={handleCheckInputChange}
                inputValues={inputValues}
                surface={surface}
                setSurface={setSurface}
                raceTrack={raceTrack}
                setRaceTrack={setRaceTrack}
                stadiums={stadiums}
                stadium={stadium}
                setStadium={setStadium}
                sharedStadium={sharedStadium}
                setSharedStadium={setSharedStadium}
                sharedTeams={sharedTeams}
                setSharedTeams={setSharedTeams}
                teamsList={teamsList}
                newSharedTeams={newSharedTeams}
                setNewSharedTeams={setNewSharedTeams}
                addNewSharedTeam={addNewSharedTeam}
                setAddNewSharedTeam={setAddNewSharedTeam}
                handleAddnNewSharedTeams={handleAddnNewSharedTeams}
                stadiumCountry={stadiumCountry}
                setStadiumCountry={setStadiumCountry}
                allCountries={allCountries}
                leagueToEdit={leagueToEdit}
                handleInsertNewStadium={handleInsertNewStadium}
                handleRemoveNewSharedTeamItem={handleRemoveNewSharedTeamItem}
                handleSetSharedTeamsToRemove={handleSetSharedTeamsToRemove}
              />
            ) : (
              <AddNewStadium
                handleInputChange={handleInputChange}
                handleCustomInputChange={handleCustomInputChange}
                handleCheckInputChange={handleCheckInputChange}
                inputValues={inputValues}
                surface={surface}
                setSurface={setSurface}
                raceTrack={raceTrack}
                setRaceTrack={setRaceTrack}
                stadiums={stadiums}
                stadium={stadium}
                setStadium={setStadium}
                addNewStadium={addNewStadium}
                handleInsertNewStadium={handleInsertNewStadium}
                sharedStadium={sharedStadium}
                setSharedStadium={setSharedStadium}
                sharedTeams={sharedTeams}
                setSharedTeams={setSharedTeams}
                addNewSharedTeam={addNewSharedTeam}
                setAddNewSharedTeam={setAddNewSharedTeam}
                newSharedTeams={newSharedTeams}
                setNewSharedTeams={setNewSharedTeams}
                teamsList={teamsList}
                handleAddnNewSharedTeams={handleAddnNewSharedTeams}
                stadiumCountry={stadiumCountry}
                setStadiumCountry={setStadiumCountry}
                allCountries={allCountries}
                leagueToEdit={leagueToEdit}
                handleRemoveNewSharedTeamItem={handleRemoveNewSharedTeamItem}
              />
            )}
          </StadiumInfo>

          <DerbyInfo>
            {isLoadingDerby ? (
              <LoaderSpinner />
            ) : (
              <>
                <DerbyLine>
                  <GroupContainer>
                    <h3>LOW DERBY TEAMS</h3>
                    {lowDerby?.map((item, index) => (
                      <DerbyTeamsMenu
                        teamToEdit={teamToEdit}
                        key={index}
                        index={index}
                        label={"-"}
                        derby={lowDerby}
                        setDerby={setLowDerby}
                        value={item}
                        valuesList={teamsList}
                      />
                    ))}
                  </GroupContainer>

                  <GroupContainer>
                    <h3>MID DERBY TEAMS</h3>
                    {midDerby?.map((item, index) => (
                      <DerbyTeamsMenu
                        teamToEdit={teamToEdit}
                        key={index}
                        index={index}
                        label={"-"}
                        derby={midDerby}
                        setDerby={setMidDerby}
                        value={item}
                        valuesList={teamsList}
                      />
                    ))}
                  </GroupContainer>
                  <GroupContainer>
                    <h3>HIGH DERBY TEAMS</h3>
                    {highDerby?.map((item, index) => (
                      <DerbyTeamsMenu
                        teamToEdit={teamToEdit}
                        key={index}
                        index={index}
                        label={"-"}
                        derby={highDerby}
                        setDerby={setHighDerby}
                        value={item}
                        valuesList={teamsList}
                      />
                    ))}
                  </GroupContainer>
                </DerbyLine>
                {addNewDerbyRow && (
                  <DerbyLine>
                    <GroupContainer>
                      {newLowDerby?.map((item, index) => (
                        <DerbyTeamsMenu
                          teamToEdit={teamToEdit}
                          key={index}
                          index={index}
                          label={"-"}
                          derby={newLowDerby}
                          setDerby={setNewLowDerby}
                          value={item}
                          valuesList={teamsList}
                        />
                      ))}
                    </GroupContainer>
                    <GroupContainer>
                      {newMidDerby?.map((item, index) => (
                        <DerbyTeamsMenu
                          teamToEdit={teamToEdit}
                          key={index}
                          index={index}
                          label={"-"}
                          derby={newMidDerby}
                          setDerby={setNewMidDerby}
                          value={item}
                          valuesList={teamsList}
                        />
                      ))}
                    </GroupContainer>
                    <GroupContainer>
                      {newHighDerby?.map((item, index) => (
                        <DerbyTeamsMenu
                          teamToEdit={teamToEdit}
                          key={index}
                          index={index}
                          label={"-"}
                          derby={newHighDerby}
                          setDerby={setNewHighDerby}
                          value={item}
                          valuesList={teamsList}
                        />
                      ))}
                    </GroupContainer>
                  </DerbyLine>
                )}
                <AddNewRowButton onClick={() => handleAddNewDerbyRow()}>
                  <PlusSvg />
                  Add new row
                </AddNewRowButton>
              </>
            )}
          </DerbyInfo>

          <PenaltyTakerInfo>
            <DerbyLine>
              <GroupContainer>
                <h3>PRIMARY PENALTY TAKER</h3>
                <PenaltyTakerMenu
                  valuesList={players}
                  value={firstPTaker}
                  setValue={setFirstPTaker}
                />
              </GroupContainer>
              <GroupContainer>
                <h3>SECONDARY PENALTY TAKER</h3>
                <PenaltyTakerMenu
                  valuesList={players}
                  value={secondPTaker}
                  setValue={setSecondPTaker}
                />
              </GroupContainer>
              <GroupContainer>
                <h3>TERTIARY PENALTY TAKER</h3>
                <PenaltyTakerMenu
                  valuesList={players}
                  value={thirdPTaker}
                  setValue={setThirdPTaker}
                />
              </GroupContainer>

            </DerbyLine>
          </PenaltyTakerInfo>
        </TeamDetails>
      </CardContent>

      <Bottom>
        <DeleteConfirmationDouble
          handleRemoveItem={handleDeleteLeague}
          item={leagueToEdit.id}
          variant={"league"}
        />
        <SaveBtn
          disabled={!readyToSave}
          onClick={() => {
            handleSaveButton();
          }}
        >
          Save
        </SaveBtn>
      </Bottom>
    </Card>
  );
};

export default TeamDetailsOption;
const Card = styled.div`

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
const CardContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
`;
const TeamsList = styled.ul`
  min-width: 240px;
  height: 100%;
  overflow: auto;
  padding: 25px 20px 0 20px;
  border-right: 3px solid ${({ theme }) => theme.editLeague.borderColor};
  list-style: none;
`;
const TeamListItem = styled.li`
  cursor: pointer;
  width: 100%;
  padding: 5px 8px;
  background: ${(props) => (props.active == true ? ({ theme }) => theme.editLeague.teamListItemSelected : ({ theme }) => theme.editLeague.bg)};
  border-radius: 6px;
  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.editLeague.menuTextColor}
  }
`;
const TeamDetails = styled.div`
  overflow: auto;
  padding: 25px 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  .team-name {
    margin-bottom:10px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.editLeague.menuTextColor}
  }
`;
const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 100%;
  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    color: ${({ theme }) => theme.editLeague.groupsTextColor}
  }
  h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    text-transform: uppercase;
    color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
`;
const MoveButton = styled.button`
  background-color: var(--primaryBlue);
  color: white;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding: 5px 26px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`;
const DerbyInfo = styled.div`
  background: ${({ theme }) => theme.editLeague.teamDetailsCardsBg};
  padding: 10px 45px;
  display: flex;
  flex-direction: column;
  align-items: end;
  row-gap: 10px;
  height: 100%;
`;
const PenaltyTakerInfo = styled(DerbyInfo)``;
const DerbyLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const AddNewRowButton = styled.button`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  background: #ffffff;
  border: 0.7px solid #006fff;
  border-radius: 3px;
  padding: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--primaryBlue);
  cursor: pointer;
`;
const StadiumInfo = styled.div`
  background: ${({ theme }) => theme.editLeague.teamDetailsCardsBg};
  padding: 20px 45px;
  display: flex;
  justify-content: space-between;
`;
const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  height: 100%;
`;
const RightSide = styled(LeftSide)``;
const ElementInput = styled.input`
  height: 40px;
  background: #ffffff;
  width: 330px;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(46, 46, 46, 0.8);
  padding: 10px 15px;
  ::placeholder {
    color: rgba(46, 46, 46, 0.4);
  }
`;
const InputWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height, or 117% */
    color: var(--darkBlue);
  }
`;
const Bottom = styled.div`
   background: ${({ theme }) => theme.editLeague.headerBg};
  padding: 10px 40px;
  border-radius: 0 0 16px 16px;
  display: flex;
  justify-content: space-between;
  height: 60px;
`;
const DeleteLeagueBtn = styled.button`
  display: flex;
  align-items: center;
  column-gap: 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ec4848;
`;
const SaveBtn = styled.button`
  background-color: var(--primaryBlue);
  color: white;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding: 12px 27px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`;
const NewRefereeButton = styled.button`
  cursor: pointer;

  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  background: #ffffff;
  border: 0.7px solid #006fff;
  border-radius: 3px;
  padding: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--primaryBlue);
  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`;
