import React from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const CupTeam = ({ bg, tier, teamName, season, user, teamId, cupId, searchParams, logo, archived }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (archived) {
      return
    }

    let newSearchParams = searchParams;
    newSearchParams.set("teamId", teamId);
    newSearchParams.set("selectedCupId", cupId);
    newSearchParams.delete("term");

    if (user.open_links_in_new_tabs) {
      let url = {
        pathname: "leagues/squad_manager",
        search: createSearchParams(newSearchParams).toString()
      };
      window.open(`${url.pathname}?${url.search}`, "_blank");
    } else {
      navigate({
        pathname: "squad_manager",
        search: createSearchParams(newSearchParams).toString()
      });
    }

  };

  return (
    <Container bg={bg}>
      <TierCell>
        <img src={logo} />

      </TierCell>
      <LeagueNameCell onClick={() => handleClick()} archived={archived}>
        <p>{teamName} <span>({season})</span></p>
      </LeagueNameCell>

    </Container>
  )
}

export default CupTeam
const Container = styled.div`
  display: flex;
  align-items: center;
  padding:5px 9px;
  column-gap: 10px;
  background: ${(props) =>
    props.bg % 2
      ? ({ theme }) => theme.matchElementBgindexL
      : ({ theme }) => theme.matchElementBg};
  min-height: 40px;

`;

const Cell = styled.div`
width:100%;
align-items:center;

p{
font-family: 'Roboto';
font-style: normal;
font-weight:400;
font-size: 11px;
color: ${({ theme }) => theme.leagueColor};
}
`;

const TierCell = styled.div`
  min-width: 20px;
  min-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.7px solid #cbd7f5;
  border-radius: 3px;
  position:relative;
img{
height:20px;
width:20px;
  position:relative;
}
  p {
    font-weight: 400;
    font-size: 9px;
    line-height: 11px;
    text-align: center;
    color: ${({ theme }) => theme.leagueColor};
  }
`;

const LeagueNameCell = styled(Cell)`
  min-width: 120px;
  display:flex;
  column-gap:5px;
cursor:${(props) => props.archived ? "inherit" : "pointer"};
  p{
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.01em;
    text-decoration-line:${(props) => props.archived ? "inherit" : "underline"} ;
    color: ${({ theme }) => theme.betHeaderTextColor};
  }

  span{
    opacity:0.3;
  }
`;