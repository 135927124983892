import React from 'react'
import styled from 'styled-components';
import ButtonWithConfirmation from '../../ButtonWithConfirmation';
import { format_time_to_timezone } from '../../../helper/Generic/dateTimeFormat';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../Redux/user/user';

const DeletedPlayerElement = ({ player, handleUndoDeletePLayer }) => {
    const user = useSelector(selectUser)

    const timezonedReceivedTime = format_time_to_timezone(
        player.updated_at,
        user.timezone
    );

    return (
        <Container>
            <InfoContainer>
                <TransferDateCell><h2>{timezonedReceivedTime.format("DD/MM/YYYY HH:mm")}</h2></TransferDateCell>
                <IdCell><h3>{player.wy_id}</h3></IdCell>
                <NameCell><h3>{player.first_name}</h3></NameCell>
                <NameCell><h3>{player.last_name}</h3></NameCell>
                <NameCell><h3>{player.short_name}</h3></NameCell>
                <NameCell><h3>{player.other_player_name}</h3></NameCell>
                <NumberCell><h3>{player.squad_number}</h3></NumberCell>
                <PosCell><h3>{player.position_1}</h3></PosCell>
                <PosCell><h3>{player.position_2}</h3></PosCell>
                <PosCell><h3>{player.position_3}</h3></PosCell>
            </InfoContainer>


            <ButtonWithConfirmation
                variant={"revert-delete"}
                action={handleUndoDeletePLayer}
                item={player.id}

            />

        </Container>
    )
}

export default DeletedPlayerElement
const Container = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`;
const InfoContainer = styled.div`
display: flex;
column-gap: 10px;
align-items: center;
background:${({ theme }) => theme.editLeague.transferredPlayerBg};

padding:13px 10px;
border-radius:6px;
`
const Cell = styled.div`
min-width:70px;
max-width:70px;
display:flex;
align-items:center;
overflow:hidden;
h3{
    text-overflow:ellipsis;
    overflow:hidden;
    white-space:nowrap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    color: ${({ theme }) => theme.editLeague.groupsTextColor};
    }
    h2{
font-size: 10px;
font-weight: 500;
line-height: 12px;
opacity:0.8;
color: ${({ theme }) => theme.editLeague.groupsTextColor};

    }
`
const TransferDateCell = styled(Cell)`
min-width:80px;
max-width:80px;
`
const IdCell = styled(Cell)`
`
const PosnCell = styled(Cell)`
`
const NameCell = styled(Cell)`
min-width:110px;
max-width:110px;
`
const NumberCell = styled(Cell)`
`
const PosCell = styled(Cell)`
min-width:80px;
max-width:80px;
`
