import { GET } from "../APiHelper/API";
let source = null;
export const getLiveStatus = (onSuccess, onError) => {
  source = GET(
    "/api/v1/system_status/current_status",
    onSuccess,
    onError,
    true,
    source
  );
};
