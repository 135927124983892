import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { resetPasswordEmail } from "../../api/authentication/ResetPassword";
import { notifyError, notifySucces } from "../../helper/Generic/Notifications";
import { ReactComponent as SwanLogo } from "../../assets/icons/swanLogo.svg";
import { ReactComponent as BackArrow } from "../../assets/icons/backArrow.svg";
import { SpinnerComponent } from "../../components/genericComponents/SpinnerComponent";
import { PulseLoader } from "react-spinners";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [resendedEmail, setResendedEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const whiteSwanDomain = /\S+@whiteswam.com/;
  // const adminDomain = /\S+@admin.com/;
  // const appssembleDomain = /\S+@appssemble.com/;
  // const gmailDomain = /\S+@gmail.com/;

  const handleInputEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      sendEmail();
    }
  };

  // const validateEmail = () => {
  //   return (
  //     whiteSwanDomain.test(email) ||
  //     adminDomain.test(email) ||
  //     appssembleDomain.test(email) ||
  //     gmailDomain.test(email)
  //   );
  // };

  const sendEmail = () => {

    setIsLoading(true)
    resetPasswordEmail(
      email,
      function () {
        setEmailSent(true);
        notifySucces("Email sent");
        setIsLoading(false);
      },
      function (error) {
        setIsLoading(false);
        notifyError("Send reset email failed");
      }
    );
  };

  const resendEmail = () => {
    sendEmail();
    setResendedEmail(true);
  };

  return (

    <Container>
      {isLoading && <SpinnerWrapper>
        <PulseLoader
          color={"#006fff"}
          loading={isLoading}
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />

      </SpinnerWrapper>}

      {!emailSent ? (
        <Card onKeyDown={(e) => handleKeyDown(e)}>
          <Back onClick={() => navigate("/login")}>
            <BackArrow />

            <h3>Back</h3>
          </Back>

          <Top>
            <h1>Reset Password</h1>
          </Top>
          <InputWrapper>
            <h3>
              Enter the email address associated with your account and we’ll
              send you a link to reset your password.
            </h3>
          </InputWrapper>
          <InputWrapper>
            <label>Email</label>
            <input
              type={"email"}
              placeholder={"@email.com"}
              value={email}
              onChange={(e) => handleInputEmail(e)}
            ></input>
          </InputWrapper>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button onClick={() => sendEmail()}>Reset Password</button>
          </div>
        </Card>


      ) : (
        <Card>

          <Top>
            <h1>Check your mail</h1>
          </Top>
          <InputWrapper>
            <h3>We have sent a password recover instructions to your email.</h3>
            <hr></hr>
            <h3>Did not receive the email?</h3>

            <h2 onClick={() => setEmailSent(false)}>
              Try another email address
            </h2>
          </InputWrapper>

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button disabled={resendedEmail} onClick={() => resendEmail()}>
              Send again
            </button>
          </div>
        </Card>
      )
      }
    </Container >


  );
};

export default ResetPassword;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(249, 249, 249, 1);
  height: var(--pageHeight);
  overflow: auto;
  min-height: 600px;
  min-width: 600px;
  width:100%;
`;
const Card = styled.div`
position:relative;
  min-width: 500px;
  min-height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 25px;
  background-color: white;
  padding: 80px 145px;
  border: 2px solid #f8f9fb;
  box-shadow: 0px 1px 8px rgba(46, 46, 46, 0.04);
  border-radius: 16px;
  overflow: hidden;

  button {
    padding: 15px 0;
    min-width: 180px;
    width: 100%;
    border-radius: 4px;
    border: none;
    background: var(--primaryBlue);
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    :disabled {
      opacity: 0.5;
    }
  }
`;
const Back = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  column-gap: 10px;
  top: 30px;
  left: 35px;
  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--darkBlue);
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  min-width: 20vw;
  width: 328px;
  h2 {
    margin-top: -10px;
    color: var(--primaryBlue);
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    margin-top: 1px;
  }
  h3 {
    color: #4a4a4a;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .wrong {
    border: 1px solid red;
    :focus-visible {
      outline: 0px;
      border: 1px solid red;
    }
  }
  input {
    width: 100%;
    border: 1px solid #d9dfe7;
    border-radius: 4px;
    padding: 10px 16px;
    ::placeholder {
      color: #b3b9c0;
    }
    :focus-visible {
      outline: 0px;
      border: 1px solid var(--primaryBlue);
    }
  }
  label {
    color: #001229;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  hr {
    border: none;
    height: 3px;
    background: rgba(245, 245, 245, 1);
    margin: 20px 0;
  }
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  svg {
    height: 58px;
    width: 58px;
  }
  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 42px;
    line-height: 56px;
  }
`;
const SpinnerWrapper = styled.div`
position:absolute;
z-index:2;
display:flex;
justify-content:center;
align-items:center;


`