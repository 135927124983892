import { useWebSocketDataService } from "./useWebSocketDataService";
import { getLiveStatus } from "../api/liveStatus/getLiveStatus";
import { createContext, useState, useEffect, useContext } from "react";
import WebSocketObserver from "../helper/websocket/WebSocketObserver";
import { useSelector } from "react-redux";
import { selectUser } from "../Redux/user/user";

export const SystemStatusContext = createContext();

const SystemStatusContextProvider = ({ children }) => {
  const [statusMessage, setStatusMessage] = useState(null);
  const user = useSelector(selectUser);

  const { add_observer, remove_observer } = useWebSocketDataService();

  const observer = new WebSocketObserver("SystemStatusChannel", (message) => {
    setStatusMessage(message);
  });

  const liveStatusReceived = (data) => {
    if (data !== null && data !== undefined && data.length !== 0) {
      setStatusMessage(data);
    }
  };

  const getCurrentStatus = () => {
    getLiveStatus(
      function (response) {
        setStatusMessage(response.data);
      },
      function (error) {
        setStatusMessage(null);
      }
    );
  };

  useEffect(() => {
    liveStatusReceived(statusMessage);
  }, [statusMessage]);

  useEffect(() => {
    if (!user) {
      return
    }
    getCurrentStatus();
    add_observer(observer);

    return () => {
      remove_observer(observer);
    };
  }, [user]);

  return (
    <SystemStatusContext.Provider value={{ statusMessage }}>
      {children}
    </SystemStatusContext.Provider>
  );
};

export const useSystemStatus = () => useContext(SystemStatusContext);

export default SystemStatusContextProvider;
