import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { format_time_to_timezone } from "../../../helper/Generic/dateTimeFormat";
import { checkFlag } from "../../../helper/Generic/getFlagSrc";
import { selectUser } from "../../../Redux/user/user";

import ConfirmAttendanceComponent from "../Menu/ConfirmAttendanceComponent";
import { ReactComponent as ShirtIcon } from "../../../assets/icons/LeaguesIcons/shirtSmall.svg";
import DotMenu from "../Menu/DotMenu";

const FinishedMatchHeader = ({
  fixtureData,
  updateSquad,
  allPlayersConfirmed,
  setEditBeforeGameStart,
  handleCheckAll,
  handleMatchPreviewButton,
  handleSetConfirmedAttendance,
  focusOnOtherTeam,
  editInPhase3,
  isLoading,
  disalbedForAction,
  homeTeam,
  awayTeam,
  thisTeam,
  handleShowTransferModal,
  dotMenuOptionsList,
  handleResetSquad,
  handleOpenGroupPlayers,
  squadDataUnsavedChanges
}) => {

  const user = useSelector(selectUser);

  const fixtureStartTime = format_time_to_timezone(
    fixtureData.fixture.start_time,
    user.timezone
  );

  const phase = "phase2"

  return (
    <TeamHeader>
      <FirstLine>
        {fixtureData.cup ?

          <LeagueAndFlag>
            <LogoWrap>
              <img src={fixtureData.cup.logo} alt=""></img>
            </LogoWrap>
            <h1>{fixtureData?.cup.name}</h1>
          </LeagueAndFlag> :

          <LeagueAndFlag>
            <LogoWrap>
              <img src={checkFlag(fixtureData?.league?.country)} alt=""></img>
            </LogoWrap>
            <h1>{fixtureData?.league.name}</h1>
          </LeagueAndFlag>
        }

        <BtnsWrapper>


          <MatchPreviewButton
            onClick={() => handleMatchPreviewButton(fixtureData)}
          >
            Factors
          </MatchPreviewButton>

          <MatchPreviewButton
            disabled={!fixtureData.has_both_squads_selected}
            onClick={() => handleMatchPreviewButton(fixtureData, true)}
          >
            Line-Up
          </MatchPreviewButton>

          <SaveBtn
            disabled={
              !allPlayersConfirmed || !fixtureData.can_confirm_selection || disalbedForAction
            }
            onClick={() => updateSquad()}
          >
            Save
          </SaveBtn>

          <DotMenu
            fixtureData={fixtureData}
            handleShowTransferModal={handleShowTransferModal}
            disabled={fixtureData.default_squad}
            optionsList={dotMenuOptionsList}
            handleResetSquad={handleResetSquad}
            phase={phase}
          />

        </BtnsWrapper>
      </FirstLine>

      <SecondLine>
        <div
          style={{ display: "flex", columnGap: "20px", alignItems: "center" }}
        >
          <TeamsNames selectedTeam={homeTeam?.name === thisTeam?.name}>
            <TeamWrapper
              onClick={() => {
                if (homeTeam?.name !== thisTeam?.name) {
                  focusOnOtherTeam(homeTeam.id);
                }
              }}
            >
              <LogoWrapper>
                <img src={homeTeam?.logo_url} alt="logo" />
              </LogoWrapper>
              <h1>{homeTeam?.name}</h1>
            </TeamWrapper>

            <TeamWrapper
              onClick={() => {
                if (awayTeam?.name !== thisTeam?.name) {
                  focusOnOtherTeam(awayTeam.id);
                }
              }}
            >
              <LogoWrapper>
                <img src={awayTeam?.logo_url} alt="logo" />
              </LogoWrapper>
              <h2>{awayTeam?.name}</h2>
            </TeamWrapper>
          </TeamsNames>
          <DateAndTime>
            <p>{fixtureStartTime.format("DD/MM/YYYY")}</p>
            <p>{fixtureStartTime.format("HH:mm")}</p>
          </DateAndTime>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "2px",
            alignItems: "end",
            justifyContent: "end",
          }}
        >
          {/* TODO: remove to can go from state 3 ->2 ->1 */}
          {!editInPhase3 && fixtureData.can_edit_current_state && (
            <EditBtn onClick={() => setEditBeforeGameStart(true)}>
              Edit players data
            </EditBtn>
          )}

          <SmallBtnsGroup>
            <ConfirmAttendanceComponent
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              fixtureData={fixtureData}
              handleSetConfirmedAttendance={handleSetConfirmedAttendance}
            />

            <GroupPlayersBtn
              disabled={fixtureData?.default_squad || squadDataUnsavedChanges}
              onClick={handleOpenGroupPlayers}
              fightingPayers={fixtureData?.squad?.fight_for_groups?.length > 0}
            >
              <ShirtIcon />
            </GroupPlayersBtn>
          </SmallBtnsGroup>

        </div>
      </SecondLine>

      <ThirdLine>
        <PlayerTitle>
          <h1>player</h1>
        </PlayerTitle>
        <POSNTitle>
          <h1>posn</h1>
        </POSNTitle>
        <InjuredTitle>
          <h1>INJURED</h1>
        </InjuredTitle>
        <MATCHRTGTitle>
          <h1>MATCH RTG</h1>
        </MATCHRTGTitle>
        <ConfTitle>
          <input
            type="checkbox"
            value={allPlayersConfirmed}
            checked={allPlayersConfirmed}
            onChange={(e) => handleCheckAll(e.target.checked)}
          ></input>
          <h1>CONF</h1>
        </ConfTitle>
      </ThirdLine>
    </TeamHeader>
  );
};

export default FinishedMatchHeader;
const TeamHeader = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.squadManager.finishedMatchHeader};
  row-gap: 10px;
  padding: 5px 15px;
  min-height: 101px;
`;
const FirstLine = styled.div`
display:flex;
justify-content:space-between;xw
`;
const SecondLine = styled.div`
  display: flex;
  justify-content: space-between;
`;
const LeagueAndFlag = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
     color:${({ theme }) => theme.squadManager.textColor};
  }
   img {
    height: 20px;
    width: 30px;
  }
`;
const SaveBtn = styled.button`
  background: var(--primaryBlue);
  border-radius: 4px;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  color: white;
  padding: 1px 20px;
  cursor: pointer;
  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`;
const EditBtn = styled.button`
  background: none;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  text-decoration-line: underline;
  color: #0061e0;
  cursor: pointer;
  height: 100%;
`;

const TeamsNames = styled.div`
  row-gap: 2px;
  display: flex;
  flex-direction: column;
  h1 {
    color:${({ theme }) => theme.squadManager.textColor};
    font-family: "Roboto";
    font-style: normal;
    font-weight: ${(props) => (props.selectedTeam ? "700" : "400")};
    font-size: 12px;
    line-height: 140%;
    text-decoration: ${(props) => (props.selectedTeam ? "" : "underline")};
  }
  h2 {
    color:${({ theme }) => theme.squadManager.textColor};
    font-family: "Roboto";
    font-style: normal;
    font-weight: ${(props) => (props.selectedTeam ? "400" : "700")};
    font-size: 12px;
    line-height: 140%;
    text-decoration: ${(props) => (props.selectedTeam ? "underline" : "")};
  }
`;

const DateAndTime = styled.div`
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 140%;
     color:${({ theme }) => theme.squadManager.textColor};
  }
`;
const ThirdLine = styled.div`
  display: flex;
  column-gap: 10px;
`;
const PlayerTitle = styled.div`
  min-width: 155px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  h1 {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.03em;
     color:${({ theme }) => theme.squadManager.textColor};
  }
`;
const POSNTitle = styled(PlayerTitle)`
  min-width: 34px;
`;
const InjuredTitle = styled(PlayerTitle)`
  min-width: 50px;
`;

const MATCHRTGTitle = styled(PlayerTitle)`
  min-width: 70px;
`;
const ConfTitle = styled(PlayerTitle)`
  min-width: 45px;
  input {
    height: 12px;
    width: 12px;
    cursor: pointer;
  }
`;

const TeamWrapper = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  cursor: pointer;
`;
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17px;
  width: 17px;
  background: #ffffff;
  border: 0.5px solid #eef2fd;
  border-radius: 2px;
  img {
    height: 11px;
  }
`;
const BtnsWrapper = styled.div`
  display: flex;
  column-gap: 5px;
  align-items:center;
`;
const MatchPreviewButton = styled(SaveBtn)`
  background: #ffffff;
  border: 1px solid #006fff;
  border-radius: 4px;
  color: #006fff;
  padding: 0 9px;
  :disabled {
    opacity: 0.5;
  }
`;

const LogoWrap = styled.div`
display:flex;
  height: 20px;
    width: 30px;
    overflow:hidden;
 img {
  object-fit: contain;
  width:100%;
  }
`
const GroupPlayersBtn = styled.button`
display:flex;
align-items:center;
justify-content:center;
border:1px solid var(--primaryBlue);
background:white;
border-radius:4px;
height:17px;
width:25px;
cursor:pointer;

svg{
  fill:${props => props.fightingPayers ? "var(--primaryBlue)" : "white"};
}
:disabled{
  opacity:0.5;
}
`
const SmallBtnsGroup = styled.div`
display:flex;
gap:5px;
align-items:center;
`