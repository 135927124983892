
import { PulseLoader } from "react-spinners";

export const SpinnerComponent = ({ loading, components }) => {
  return (
    <div className="content">
      <div className="spinner">
        <PulseLoader
          color={"#006fff"}
          loading={loading}
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
      {components}
    </div>
  );
};
