import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { format_time_to_timezone } from "../../../helper/Generic/dateTimeFormat";
import { checkFlag } from "../../../helper/Generic/getFlagSrc";
import { TeamFormation } from "../../../helper/Values/TeamFormation";
import { selectUser } from "../../../Redux/user/user";
import FormationMenu from "../Menu/FormationMenu";
import { ReactComponent as TransferIcon } from "../../../assets/icons/LeaguesIcons/transferDataButton.svg";
import { ReactComponent as ShirtIcon } from "../../../assets/icons/LeaguesIcons/shirtSmall.svg";
import { ReactComponent as MenuDotIcon } from "../../../assets/icons/LeaguesIcons/menuDot.svg";
import DotMenu from "../Menu/DotMenu";

const FutureMatchHeader = ({
  fixtureData,
  readyToSave,
  readyToSubmit,
  formation,
  setFormation,
  saveNewSquad,
  submitNewSquad,
  editSquad,
  editBeforeGameStart,
  numberOfSelectedXI,
  focusOnOtherTeam,
  startingChange,
  disalbedForAction,
  homeTeam,
  awayTeam,
  thisTeam,
  handleShowTransferModal,
  handleOpenGroupPlayers,
  dotMenuOptionsList,
  handleResetSquad,
  squadDataUnsavedChanges
}) => {


  const phase = "phase1"


  const [displaySave, setDisplaySave] = useState();

  const user = useSelector(selectUser);

  const fixtureStartTime = format_time_to_timezone(
    fixtureData?.fixture?.start_time,
    user.timezone
  );


  const checkDisplaySave = () => {
    if (thisTeam?.id == homeTeam?.id) {
      setDisplaySave(fixtureData?.game.home_lineup_submitted);
    } else {
      setDisplaySave(fixtureData?.game.away_lineup_submitted);
    }
  };




  return (
    <TeamHeader>
      <FirstLine>
        {fixtureData.cup ?
          <LeagueAndFlag>
            <LogoWrap>
              <img src={fixtureData.cup.logo} alt=""></img>
            </LogoWrap>
            <h1>{fixtureData?.cup.name}</h1>
          </LeagueAndFlag> :

          <LeagueAndFlag>
            <LogoWrap>
              <img src={checkFlag(fixtureData?.league?.country)} alt=""></img>
            </LogoWrap>
            <h1>{fixtureData?.league.name}</h1>
          </LeagueAndFlag>
        }


        {editBeforeGameStart ? (
          <SubmiSaveWrapper>
            <SaveBtn onClick={() => editSquad()} disabled={!readyToSubmit || disalbedForAction}>
              Update
            </SaveBtn>
            <DotMenu
              fixtureData={fixtureData}
              handleShowTransferModal={handleShowTransferModal}
              disabled={fixtureData.default_squad}
              optionsList={dotMenuOptionsList}
              handleResetSquad={handleResetSquad}
              phase={phase}
            />
          </SubmiSaveWrapper>

        ) : (
          <SubmiSaveWrapper>
            {!displaySave && (
              <SaveBtn onClick={() => saveNewSquad()} disabled={!readyToSave || disalbedForAction}>
                Save
              </SaveBtn>
            )}

            <SaveBtn onClick={() => submitNewSquad()} disabled={!readyToSubmit}>
              Submit
            </SaveBtn>

            <DotMenu
              fixtureData={fixtureData}
              handleShowTransferModal={handleShowTransferModal}
              disabled={fixtureData?.default_squad}
              optionsList={dotMenuOptionsList}
              handleResetSquad={handleResetSquad}
              phase={phase}
            />

          </SubmiSaveWrapper>
        )}
      </FirstLine>
      <SecondLine>
        <div
          style={{ display: "flex", columnGap: "20px", alignItems: "center" }}
        >
          <TeamsNames selectedTeam={homeTeam?.name === thisTeam?.name}>
            <TeamWrapper
              onClick={() => {
                if (homeTeam?.name !== thisTeam?.name) {
                  focusOnOtherTeam(homeTeam.id);
                }
              }}
            >
              <LogoWrapper>
                <img src={homeTeam?.logo_url} alt="logo" />
              </LogoWrapper>
              <h1>{homeTeam?.name}</h1>
            </TeamWrapper>

            <TeamWrapper
              onClick={() => {
                if (awayTeam?.name !== thisTeam?.name) {
                  focusOnOtherTeam(awayTeam.id);
                }
              }}
            >
              <LogoWrapper>
                <img src={awayTeam?.logo_url} alt="logo" />
              </LogoWrapper>
              <h2>{awayTeam?.name}</h2>
            </TeamWrapper>
          </TeamsNames>
          <div style={{ display: 'flex', alignItems: "start", gap: "10px" }}>
            <DateAndTime>
              <p>{fixtureStartTime.format("DD/MM/YYYY")}</p>
              <p>{fixtureStartTime.format("HH:mm")}</p>
            </DateAndTime>

          </div>

        </div>
        <div style={{ display: "flex", flexDirection: "column", rowGap: "3px", alignItems: "end" }}>

          <FormationAndStatsWrapper>
            <FormationMenu
              valuesList={TeamFormation}
              selectedValue={formation}
              setSelectedValue={setFormation}
            />
            <XICounterCell>
              <p>{startingChange}</p>
            </XICounterCell>
            <XICounterCell>
              <p>{numberOfSelectedXI}/11</p>
            </XICounterCell>
          </FormationAndStatsWrapper>
          <GroupPlayersBtn
            disabled={fixtureData?.default_squad || squadDataUnsavedChanges}
            onClick={handleOpenGroupPlayers}
            fightingPayers={fixtureData?.squad?.fight_for_groups?.length > 0}
          >
            <ShirtIcon />
          </GroupPlayersBtn>
        </div>
      </SecondLine>
      <ThirdLine>
        <PlayerTitle>
          <h1>player</h1>
        </PlayerTitle>
        <POSNTitle>
          <h1>posn</h1>
        </POSNTitle>
        <STATUSTitle>
          <h1>status</h1>
        </STATUSTitle>
        <FatigueTitle>
          <h1>fg</h1>
        </FatigueTitle>
        <IMPTitle>
          <h1>imp</h1>
        </IMPTitle>
        <ATKTitle>
          <h1>atk</h1>
        </ATKTitle>
        <DEFitle>
          <h1>def</h1>
        </DEFitle>
        <LTFitle>
          <h1>ltf</h1>
        </LTFitle>
        <STCHANCEitle>
          <h1>st chance</h1>
        </STCHANCEitle>
        <XIitle>
          <h1>xi</h1>
        </XIitle>
      </ThirdLine>
    </TeamHeader>
  );
};

export default FutureMatchHeader;
const TeamHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  background: ${({ theme }) => theme.squadManager.futureMatchHeader};
  row-gap: 10px;
  position: relative;
  min-height: 101px;
`;
const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SecondLine = styled.div`
  display: flex;
  justify-content: space-between;
`;
const LeagueAndFlag = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color:${({ theme }) => theme.squadManager.textColor};
  }
 
`;
const LogoWrap = styled.div`
display:flex;
  height: 20px;
    width: 30px;
    overflow:hidden;
 img {
  object-fit: contain;
  width:100%;
  }
`
const SaveBtn = styled.button`
  background: var(--primaryBlue);
  border-radius: 4px;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  color: white;
  padding: 1px 20px;
  cursor: pointer;
  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`;
const TeamsNames = styled.div`
  row-gap: 2px;
  display: flex;
  flex-direction: column;
  h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: ${(props) => (props.selectedTeam ? "700" : "400")};
    font-size: 12px;
    line-height: 140%;
    text-decoration: ${(props) => (props.selectedTeam ? "" : "underline")};
  color:${({ theme }) => theme.squadManager.textColor};
  }
  h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: ${(props) => (props.selectedTeam ? "400" : "700")};
    font-size: 12px;
    line-height: 140%;
    text-decoration: ${(props) => (props.selectedTeam ? "underline" : "")};
  color:${({ theme }) => theme.squadManager.textColor};
  }
`;

const DateAndTime = styled.div`
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 140%;
      color:${({ theme }) => theme.squadManager.textColor};
  }
`;
const ThirdLine = styled.div`
  display: flex;
  column-gap: 5px;
`;
const PlayerTitle = styled.div`
  min-width: 160px;
  display: flex;
  align-items: center;
  column-gap: 5px;

  h1 {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.03em;
      color:${({ theme }) => theme.squadManager.textColor};
  }
`;
const POSNTitle = styled(PlayerTitle)`
  min-width: 50px;
`;
const STATUSTitle = styled(PlayerTitle)`
  min-width: 70px;
`;
const IMPTitle = styled(PlayerTitle)`
  min-width: 35px;
`;
const ATKTitle = styled(IMPTitle)`
  min-width: 30px;
`;
const DEFitle = styled(IMPTitle)`
  min-width: 30px;
`;
const LTFitle = styled(PlayerTitle)`
  min-width: 40px;
`;
const STCHANCEitle = styled(PlayerTitle)`
  min-width: 70px;
`;
const XIitle = styled(PlayerTitle)`
  min-width: 20px;
`;
const FatigueTitle = styled(PlayerTitle)`
  min-width: 35px;
`;

const TeamWrapper = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  cursor: pointer;
`;
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17px;
  width: 17px;
  background: #ffffff;
  border: 0.5px solid #eef2fd;
  border-radius: 2px;
  img {
    height: 11px;
  }
`;
const XICounterCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17px;
  width: 33px;
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 150%;
    color: #006fff;
  }
  background: #ffffff;
  border: 1px solid #006fff;
  border-radius: 2px;
`;

const SubmiSaveWrapper = styled.div`
  display: flex;
  column-gap: 5px;
  align-items:center;
`;
const FormationAndStatsWrapper = styled.div`
display:flex;
column-gap:10px;
align-items:center;
`
const GroupPlayersBtn = styled.button`
display:flex;
align-items:center;
justify-content:center;
border:1px solid var(--primaryBlue);
background:white;
border-radius:4px;
height:17px;
width:25px;
cursor:pointer;

svg{
  fill:${props => props.fightingPayers ? "var(--primaryBlue)" : "white"};
}
:disabled{
  opacity:0.5;
}
`