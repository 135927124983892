import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";


const AtkDefMenu = ({ label, dataList, value, setValue, changeMenuValue, disabled }) => {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = (item) => {
    if (changeMenuValue) {
      changeMenuValue(setValue, item);
      handleMenuOpen();
    } else {
      setValue(item);
      handleMenuOpen();
    }

  };

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref}>
      <DropDownMenu onClick={() => !disabled && handleMenuOpen()} isMenuOpen={isMenuOpen}>
        <p> {value != null ? (value) : "-"}</p>
        {isMenuOpen ? <UpSvg /> : (!disabled && <DownSvg />)}
      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>
          {dataList.map((item, index) => (
            <DropDownMenuItem key={index} onClick={(e) => handleClick(item.value)}>
              <div className={item.value === value ? "active" : "nonActive"}>
                <input
                  key={index}
                  readOnly
                  className="check"
                  type="radio"
                  checked={value == item.value}
                  value={item.value}
                ></input>
                <p> {item.label}</p>
              </div>
            </DropDownMenuItem>
          ))}
        </DropDownList>
      )}
    </Container>
  );
};


export default AtkDefMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90px;
  position: relative;
  cursor:pointer;
  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: rgba(60, 74, 92, 1);
  }
`;
const DropDownMenu = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.matchPreview.selectMenuBg};
    padding: 12px 16px;
    border: 1px solid #BAC3E8;
    border-radius: 6px;
    border-radius: ${(props) => props.isMenuOpen ? "8px 8px 0 0" : ""} ;
    
    p{

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
    }
    svg {
      cursor: pointer;
      fill: ${({ theme }) => theme.matchPreview.selectMenuSvgBg};
    stroke: ${({ theme }) => theme.matchPreview.selectMenuSvgColor};
    }
  `;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top: 40px;
  border-top: 1px solid black !important;
  border-color: #3c4a5c;
  border: 1px solid #BAC3E8;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  max-height: 400px;
  width: 90px;
  overflow-x: hidden;
  color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
   
  .searchGroup {
    position: relative;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
    input {
      align-items: center;
      position: relative;
      width: 100%;
      padding: 5px 10px 5px 20px;
      background-color: ${({ theme }) => theme.matchPreview.selectMenuBg};
      border: none;
      border: 1px solid #d9dfe7;
      border-radius: 8px;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      :focus-visible {
        outline: none;
        border: 1px solid #006fff;
      }
      
    }
    svg {
      position: absolute;
      z-index: 1;
      top: 7px;
      left: 7px;
    }
  }
`;
const DropDownMenuItem = styled.li`

  cursor: pointer;
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;
  background-color: ${({ theme }) => theme.matchPreview.selectMenuBg};

  padding: 5px 10px;
  div {
    column-gap: 5px;
    align-items: center;
    display: flex;
    flex-direction: row;
    .check {
      margin-right: 5px;
      background: red;
      color: red;
    }
  }
  p {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
  }
  
  
`;
