import getSymbolFromCurrency from "currency-symbol-map";

export const mapResponseToBrokerList = (response) => {
  var returnValues = [];

  response.forEach((element) => {
    const mapped = mapBrokerItemToInternalBroker(element);
    if (mapped) {
      returnValues.push(mapped);
    }
  });

  return returnValues;
};

const mapBrokerItemToInternalBroker = (item) => {
  const currency = item.currency;
  const symbol = getSymbolFromCurrency(currency);

  if (!symbol) {
    return null;
  }

  const name = item.broker_name;

  return { name: name, currencySymbol: symbol };
};
