import { POST } from "../APiHelper/API";
import { formatted_date_for_backend } from "../../helper/Generic/dateTimeFormat";

export const downloadLoggedBetsCSV = (
  competition_ids,
  term,
  start_time,
  end_time,
  tiers,
  onSuccess,
  onError
) => {
  const data = {
    competition_ids: competition_ids,
    term: term,
    start_time: formatted_date_for_backend(start_time),
    end_time: formatted_date_for_backend(end_time),
    tiers: tiers,
  };
  POST("/api/v1/bets/download_logged_bets_csv", data, onSuccess, onError);
};
