import React from "react";
import styled from "styled-components";




const Item = ({ value, className = null }) => {

  return (
    <Cell>
      <h2 className={className}>{value}</h2>
    </Cell>
  );
};
const League = ({ name, country, children }) => {

  const checkFlag = (country) => {

    let country_code = country.country_code;
    if (!country_code) {
      return
    }
    if (country.name == "Scotland" || country.name == "Northern Ireland") {
      country_code = "GB";
    }
    if (country.name == "North America") {
      country_code = "GB";
    }

    if (country !== null && country !== undefined) {
      return (`https://flagcdn.com/${country_code?.toLowerCase()}.svg`);
    }

  };

  return (
    <Container>
      <Top>
        <TopLeft>
          <div className="first">
            <img src={checkFlag(country)} alt="" /> <h2>{name}</h2>
          </div>
          <Item value={"HA"} className={"modelValues"} />
          <Item value={"HD"} className={"modelValues"} />
          <Item value={"AA"} className={"modelValues"} />
          <Item value={"AD"} className={"modelValues"} />
          <div className="group ">
            <Item value={"1"} className={"modelValues"} />
            <Item value={"X"} className={"modelValues"} />
            <Item value={"2"} className={"modelValues"} />
          </div>
          <div className="group">
            <Item value={""} className={"modelValues"} />
            <Item value={"H"} className={"modelValues"} />
            <Item value={"A"} className={"modelValues"} />
          </div>
          <div className="group">
            <Item value={""} className={"modelValues"} />
            <Item value={"O"} className={"modelValues"} />
            <Item value={"U"} className={"modelValues"} />
          </div>
          <Item value={"Sup."} className={"modelValues"} />
          <Item value={"tgX"} className={"modelValues"} />
        </TopLeft>

        <TopRight>
          <div className="group ">
            <Item value={"Sup."} className={"marketValues"} />
            <Item value={"tgX"} className={"marketValues"} />
          </div>
          <div className="group ">
            <Item value={"1"} className={"marketValues"} />
            <Item value={"X"} className={"marketValues"} />
            <Item value={"2"} className={"marketValues"} />
          </div>
          <div className="group">
            <Item value={""} className={"marketValues"} />
            <Item value={"H"} className={"marketValues"} />
            <Item value={"A"} className={"marketValues"} />
          </div>
          <div className="group">
            <Item value={""} className={"marketValues"} />
            <Item value={"O"} className={"marketValues"} />
            <Item value={"U"} className={"marketValues"} />
          </div>
          <div className="group percent">
            <Item value={"1"} className={"marketValues"} />
            <Item value={"X"} className={"marketValues"} />
            <Item value={"2"} className={"marketValues"} />
          </div>
          <div className="group percent">
            <Item value={"H"} className={"marketValues"} />
            <Item value={"A"} className={"marketValues"} />
          </div>
          <div className="group percent">
            <Item value={"O"} className={"marketValues"} />
            <Item value={"U"} className={"marketValues"} />
          </div>
          <Cell>
            {/* <button onClick={_showLeagueGraph} className="graphBtn">
              <GraphSvg />
              Graph
            </button> */}
          </Cell>
        </TopRight>
      </Top>
      <List>{children}</List>
    </Container>
  );
};

export default League;
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 1600px;
  width: auto;
`;

const Top = styled.div`
  background-color: ${({ theme }) => theme.leagueBG};
  display: flex;
  position: relative;
  min-height:39px;
`;
const TopLeft = styled.div`

  min-width:800px;  
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.leagueBG};
  color: ${({ theme }) => theme.leagueColor};
  padding: 6px 10px;
  border-right: 2px solid ${({ theme }) => theme.middleBorder};
  .first {
    display: flex;
    align-items: center;
    min-width: 250px;
    column-gap: 10px;
    h2 {
      font-weight: 700;
      font-size: 13px;
      line-height: 14px;
    }
  }
  .marketValues{
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
  
}
.modelValues{
  margin-bottom:-8px;
}
  .percent{
    padding:0 2px;
  }
  .group {
    width: 100%;
    display: flex;
    margin: 0 6px;
    div {
      justify-content: center;
      align-items: center;
    }
  }
  img {
    height: 14px;
    width: 18px;
  }
`;

const TopRight = styled(TopLeft)`
  border-right: none;
  background-color: ${({ theme }) => theme.leagueBG2};
  .graphBtn {
    cursor: pointer;
    svg {
      color: ${({ theme }) => theme.buttonBG};
    }
   
    display: flex;
    align-items: center;
    padding: 2px 10px;
    background: transparent;
    color: ${({ theme }) => theme.buttonBG};
    border: 1.5px solid ${({ theme }) => theme.buttonBG};
    border-radius: 4px;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
  }
`;
const Cell = styled.div`
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items:center;
   width: 100%;
  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
  
`;
const List = styled.ul``;
