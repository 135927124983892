import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";
import { ReactComponent as SearchSvg } from "../../../assets/icons/PricingMenuIcons/search.svg";

const StadiumMenu = ({ label, dataList, disabled, selectedValue, setSelectedValue, changeMenuValue }) => {

  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState("");


  const handleSearchInput = (e) => {
    setInputSearch(e.target.value);
  };
  const filterSearch = (items, filter) => {
    if (filter === "") {
      return items;
    } else {
      return items.filter((item) =>
        item.name.toLowerCase().includes(filter.toLowerCase())
      );
    }
  };


  const handleClick = (stadium) => {
    changeMenuValue(setSelectedValue, stadium);
    handleMenuOpen();
  };

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
        setInputSearch("");
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref} >
      <DropDownMenu onClick={() => disabled ? "" : handleMenuOpen()} isMenuOpen={isMenuOpen} disabled={disabled}>
        {/* <DropDownMenu onClick={() => alert("IMPLEMENT")} isMenuOpen={isMenuOpen}> */}
        {selectedValue ? (<p>{selectedValue?.name}</p>) : (<p>{label}</p>)}
        {disabled ? "" : <>   {isMenuOpen ? <UpSvg /> : <DownSvg />}</>}

      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>
          <DropDownMenuItem>
            <div className="searchGroup">
              <SearchSvg />
              <input
                type={"text"}
                value={inputSearch}
                onChange={handleSearchInput}
                placeholder="Search"
              ></input>
            </div>
          </DropDownMenuItem>
          {inputSearch != "" ? (
            filterSearch(dataList, inputSearch).map((item, index) => (
              <DropDownMenuItem key={index} onClick={() => handleClick(item)}>
                <div
                  className={
                    item.id === selectedValue?.id ? "active" : "nonActive"
                  }
                >
                  <input
                    key={index}
                    readOnly
                    onChange={() => handleClick(item)}
                    className="check"
                    type="radio"
                    checked={selectedValue?.id == item.id}
                    value={item}
                  ></input>
                  <p> {item.name}</p>
                </div>
              </DropDownMenuItem>
            ))

          ) : (

            dataList.map((item, index) => (
              <DropDownMenuItem key={index} onClick={() => handleClick(item)}>
                <div
                  className={
                    item.id === selectedValue?.id ? "active" : "nonActive"
                  }
                >
                  <input
                    key={index}
                    readOnly
                    onChange={() => handleClick(item)}
                    className="check"
                    type="radio"
                    checked={selectedValue?.id == item.id}
                    value={item}
                  ></input>
                  <p> {item.name}</p>
                </div>
              </DropDownMenuItem>
            ))
          )}

        </DropDownList>
      )}
    </Container>
  );
};


export default StadiumMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  position: relative;
  cursor:pointer;
  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: rgba(60, 74, 92, 1);
  }
`;

const DropDownMenu = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.disabled ? ({ theme }) => theme.matchPreview.matchFactorsReadOnlyInputs : ({ theme }) => theme.matchPreview.selectMenuBg};
    padding: 12px 16px;
    border: 1px solid #BAC3E8;
    border-radius: 6px;
    border-radius: ${(props) => props.isMenuOpen ? "8px 8px 0 0" : ""} ;

    p{

overflow:hidden;
text-overflow:ellipsis;
white-space:nowrap;
width:100%;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      max-height:32px;
      color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
    }
    svg {
      cursor: pointer;
      fill: ${({ theme }) => theme.matchPreview.selectMenuSvgBg};
      stroke: ${({ theme }) => theme.matchPreview.selectMenuSvgColor};
    }
  `;

const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top:40px;
  border-top: 1px solid black !important;
  border-color: #3c4a5c;
  border: 1px solid #BAC3E8;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  max-height: 400px;
  width: 100%;
  overflow-x: hidden;
  color: black;
   
  .searchGroup {
    position: relative;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
    input {
      align-items: center;
      position: relative;
      width: 100%;
      padding: 5px 10px 5px 20px;
      background-color: ${({ theme }) => theme.matchPreview.selectMenuBg};
      border: none;
      border: 1px solid #d9dfe7;
      border-radius: 8px;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      :focus-visible {
        outline: none;
        border: 1px solid #006fff;
      }
      ::placeholder{
        color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
      }
     
    }
    svg {
      position: absolute;
      z-index: 1;
      top: 7px;
      left: 7px;
    }
  }
`;
const DropDownMenuItem = styled.li`

  cursor: pointer;
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;
  background-color: ${({ theme }) => theme.matchPreview.selectMenuBg};

  padding: 5px 10px;
  div {
    column-gap: 5px;
    align-items: center;
    display: flex;
    flex-direction: row;
    .check {
      margin-right: 5px;
      background: red;
      color: red;
    }
  }
  p {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.matchPreview.selectMenuTextColor};
  }
  .active {
    p {
      color: var(--darkBlue);
    }
  }
 
`;
