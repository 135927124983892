import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightTheme } from "../../../theme/theme";
import { ReactComponent as DownSvg } from "../../../assets/icons/PricingMenuIcons/down.svg";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";
const TierMenu = ({ label, tierList, tier, setTier, tierIds, setTierIds }) => {

  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const allExceptOneIds = [2, 3, 4];

  const handleClick = (item) => {

    var updatedList = [...tierIds];
    var updatedList2 = [...tier];
    let found = updatedList.find(element => element === item.tier_id);

    if (found) {
      let index = updatedList.indexOf(updatedList.find(element => element === item.tier_id));
      let index2 = updatedList.indexOf(updatedList2.find(element => element === item));
      updatedList.splice(index, 1);
      updatedList2.splice(index2, 1);
    }
    else {

      updatedList = [...tierIds, item.tier_id];
      updatedList2 = [...tier, item];
    }
    setTierIds(updatedList);
    setTier(updatedList2);
  };

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  const handleAllExceptOne = (event) => {
    if (event.target.checked) {
      setTierIds(allExceptOneIds);
      setTier(tierList);
    }
  };

  const handleAll = (event) => {
    var updateListIds = [];
    var updatedList = [];

    if (event.target.checked) {
      tierList.forEach(element => {
        updateListIds.push(element.tier_id);
        updatedList.push(element);
      });
      setTier(updatedList);
      setTierIds(updateListIds);
    }
    else {
      setTier(tierList);
      setTierIds([2, 3, 4]);
    }

  };

  const checkRetail = (retailList) => {
    let containAll = retailList.every(r => tierIds.includes(r));
    return containAll;

  };
  const checkAllExceptOne = (retailList) => {
    let containAll = retailList.every(r => tierIds.includes(r));
    if (containAll && tierIds.length === 3) {
      return true;
    } else { return false; }

  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);

      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    if (tierIds.length < 1) {
      setTier(tierList);
      setTierIds([2, 3, 4]);
    }
  }, [tierIds]);

  return (
    <Container ref={ref}>
      <DropDownMenu onClick={() => handleMenuOpen()} isMenuOpen={isMenuOpen}>
        <p>{label}</p>
        {isMenuOpen ? <UpSvg /> : <DownSvg />}
      </DropDownMenu>
      {isMenuOpen && (
        <DropDownList>
          <DropDownMenuItem>
            <div >
              <input
                readOnly
                onChange={(e) => handleAllExceptOne(e)}
                className="check"
                type="checkbox"
                checked={checkAllExceptOne(allExceptOneIds)}

              ></input>
              <p>All except 1</p>
            </div>
          </DropDownMenuItem>
          <DropDownMenuItem>
            <div >
              <input
                readOnly
                onChange={(e) => handleAll(e)}
                className="check"
                type="checkbox"
                checked={checkRetail([1, 2, 3, 4])}
              ></input>
              <p>All tiers</p>
            </div>
          </DropDownMenuItem>


          {tierList.map((item, index) => (
            <DropDownMenuItem key={index} onClick={(e) => handleClick(item)}>
              <div
                className={
                  tierIds.includes(item.tier_id) ? "active" : "nonActive"
                }
              >
                <input
                  key={index}
                  readOnly
                  className="check"
                  type="checkbox"
                  checked={tierIds.includes(item.tier_id)}
                  value={item}
                ></input>

                <p>{item.tier_name}</p>
              </div>
            </DropDownMenuItem>
          ))}
        </DropDownList>
      )}
    </Container>
  );
};

export default TierMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 162px;
  position: relative;
  p {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: ${({ theme }) => theme.dropDownColor};
  }
`;
const DropDownMenu = styled.div`
position: relative;
display: flex;
align-items: center;
justify-content: space-between;
background-color: ${({ theme }) => theme.menuBgColor};
padding: 4px 10px;
border-radius: 8px;
border-radius: ${props => props.isMenuOpen ? "8px 8px 0 0" : ""};
.group {
  align-items: center;
  display: flex;
  column-gap: 5px;
  img {
    height: 14px;
    width: 18px;
    border-radius: 4px;
  }
}
img {
  cursor: pointer;
  height: 21px;
  width: 22px;
}
svg {
  cursor: pointer;
  fill: ${({ theme }) => theme.dropDownSvgFill};
  stroke: ${({ theme }) => theme.dropDownSvgStroke};
}
`;
const DropDownList = styled.ul`
  position: absolute;
  z-index: 2;
  top: 28px;
  border-top: 2px solid;
  border-color: ${({ theme }) => (theme === lightTheme ? "#3C4A5C" : "white")};
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  max-height: 400px;
  width: 162px;
  overflow-x: hidden;
  color: black;

  .searchGroup {
    position: relative;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.menuTextColor};
    input {
      align-items: center;
      position: relative;
      width: 100%;
      padding: 4px 10px 4px 20px;
      background: ${({ theme }) => theme.leagueBG};
      border: none;
      border: 1px solid #d9dfe7;
      border-radius: 8px;
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      :focus-visible {
        outline: none;
        border: 1px solid #006fff;
      }
      color: ${({ theme }) => theme.menuTextColor};
    }
    svg {
      position: absolute;
      z-index: 1;
      top: 8, 5px;
      left: 7px;
    }
  }
`;
const DropDownMenuItem = styled.li`
  background-color: red;
  cursor: pointer;
  img {
    border-radius: 4px;
    height: 14px;
    width: 18px;
  }

  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  list-style-type: none;
  background-color: ${({ theme }) => theme.dropDownBG};
  padding: 5px 10px;
  div {
    column-gap: 5px;
    align-items: center;
    display: flex;
    flex-direction: row;
    .check {
      margin-right: 5px;
      background: red;
      color: red;
    }
  }

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.dropDownColor};
  }
  .active {
    p {
      color: rgba(97, 131, 255, 1);
    }
  }
  .nonActive {
    :hover {
      p {
        color: ${({ theme }) => theme.buttonBG};
      }
    }
    p {
      color: ${({ theme }) => theme.dropDownColor};
    }
  }
`;
