import { createContext, useContext, useEffect } from "react";
import WebSocketHandler from "../helper/websocket/WebSocketHandler";
import { useSelector } from "react-redux";
import { selectUser } from "../Redux/user/user";

export const WebSocketServiceContext = createContext({
  add_observer: () => {},
  remove_observer: () => {},
});

const WebSocketServiceProvider = ({ children }) => {
  const user = useSelector(selectUser);
  const handler = new WebSocketHandler();

  const add_observer = (observer) => {
    handler.addObserver(observer);
  };

  const remove_observer = (observer) => {
    handler.removeObserver(observer);
  };

  useEffect(() => {
    if (user) {
      handler.connect();
    } else {
      handler.disconnect();
    }
  }, [user]);

  return (
    <WebSocketServiceContext.Provider
      value={{
        add_observer,
        remove_observer,
      }}
    >
      {children}
    </WebSocketServiceContext.Provider>
  );
};
export const useWebSocketDataService = () =>
  useContext(WebSocketServiceContext);
export default WebSocketServiceProvider;
