import Header from "./components/header/Header";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/authentication/Login";
import Register from "./pages/authentication/Register";
import NewPassword from "./pages/authentication/NewPassword";
import ResetPassword from "./pages/authentication/ResetPassword";
import Pricing from "./pages/Pricing/Pricing";
import UserProfile from "./pages/user/UserProfile";
import ProtectedRoute from "./components/ProtectedRoute";
import NotFound from "./pages/NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./theme/theme";
import { useDarkMode } from "./helper/Generic/useDarkMode";
import Betting from "./pages/Betting/Betting";
import { useSelector } from "react-redux";
import { selectUser } from "./Redux/user/user";
import PricingServiceProvider from "./context/usePricingService";
import PermissionsGate from "./helper/Generic/PermissionGate";
import { SCOPES } from "./helper/Generic/Permissions";
import Leagues from "./pages/Leagues/Leagues";
import Audit from "./pages/Audit/Audit";
import SquadManager from "./pages/Leagues/SquadManager";
import SquadManagerServiceProvider from "./context/useSquadManagerService";
import NotesServiceProvider from "./context/useNotesService";
import MatchPreview from "./pages/Leagues/MatchPreview";
import LeaguesServiceProvider from "./context/useLeaguesDataService";
import MatchPreviewServiceProvider from "./context/useMatchPreviewService";
import RequestMenuServiceProvider from "./context/useRequestMenuService";
import BetsServiceProvider from "./context/useBetsService";
import LoggedBetsServiceProvider from "./context/useLoggedBetsService";
import LoggedBets from "./pages/Betting/LoggedBets";
import RetailLoggedBets from "./pages/Betting/RetailLoggedBets";
import RsBetsServiceProvider from "./context/useRsBetsService";
import BetsListPage from "./pages/Betting/BetsListPage";
import ParketBetsServiceProvider from "./context/useParkedBetsService";
import SystemStatusContextProvider from "./context/useSystemStatus";
import Archived from "./pages/Leagues/Archived";
import PlayersData from "./pages/Leagues/PlayersData";
import { Home } from "@mui/icons-material";
import PlayerStatsServiceProvider from "./context/usePlayerStatsService";

function App() {
  const UNAUTHORIZED_PAGE = "unauthorized-page";

  const [theme, toggleTheme] = useDarkMode();
  const themeMode = theme === "light" ? lightTheme : darkTheme;

  const user = useSelector(selectUser);

  return (
    <div className="App">

      <ThemeProvider theme={themeMode}>
        <ToastContainer autoClose={2000} />
        <Header theme={theme} toggleTheme={toggleTheme} />
        <SystemStatusContextProvider>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/login" element={<Login />} />
            <Route path="/users/accept_invite/" element={<Register />} />
            <Route path="/forgotPassword" element={<ResetPassword />} />
            <Route path="/users/reset_password/" element={<NewPassword />} />

            <Route element={<ProtectedRoute user={user} />}>
              <Route
                path="/pricing"
                element={
                  <PricingServiceProvider>
                    <NotesServiceProvider>
                      <Pricing />
                    </NotesServiceProvider>
                  </PricingServiceProvider>
                }
              />
              <Route
                path="/pricing/:bookmaker_id_param"
                element={
                  <PricingServiceProvider>
                    <NotesServiceProvider>
                      <Pricing />
                    </NotesServiceProvider>
                  </PricingServiceProvider>
                }
              />

              <Route path="/userprofile"
                element={
                  <LeaguesServiceProvider><
                    UserProfile />
                  </LeaguesServiceProvider>} />

              {/* Nested Routes Start */}

              <Route
                path="/betting"
                element={
                  <PermissionsGate
                    scopes={[SCOPES.canBet]}
                    errorProps={{ pageAuthorization: UNAUTHORIZED_PAGE }}
                  >
                    <Betting />
                  </PermissionsGate>
                }
              >
                <Route
                  path="/betting/bets_list"
                  element={
                    <PermissionsGate
                      scopes={[SCOPES.canBet]}
                      errorProps={{ pageAuthorization: UNAUTHORIZED_PAGE }}
                    >
                      <RequestMenuServiceProvider>
                        <BetsServiceProvider>
                          <ParketBetsServiceProvider>
                            <LoggedBetsServiceProvider>
                              <RsBetsServiceProvider>
                                <NotesServiceProvider>
                                  <BetsListPage />
                                </NotesServiceProvider>
                              </RsBetsServiceProvider>
                            </LoggedBetsServiceProvider>
                          </ParketBetsServiceProvider>
                        </BetsServiceProvider>
                      </RequestMenuServiceProvider>
                    </PermissionsGate>
                  }
                ></Route>

                <Route
                  path="/betting/asia_logged_bets"
                  element={
                    <PermissionsGate
                      scopes={[SCOPES.canBet]}
                      errorProps={{ pageAuthorization: UNAUTHORIZED_PAGE }}
                    >
                      <RequestMenuServiceProvider>
                        <BetsServiceProvider>
                          <LoggedBetsServiceProvider>
                            <NotesServiceProvider>
                              <LoggedBets />
                            </NotesServiceProvider>
                          </LoggedBetsServiceProvider>
                        </BetsServiceProvider>
                      </RequestMenuServiceProvider>
                    </PermissionsGate>
                  }
                ></Route>

                <Route
                  path="/betting/retail_logged_bets"
                  element={
                    <PermissionsGate
                      scopes={[SCOPES.canBet]}
                      errorProps={{ pageAuthorization: UNAUTHORIZED_PAGE }}
                    >
                      <RequestMenuServiceProvider>
                        <BetsServiceProvider>
                          <RsBetsServiceProvider>
                            <NotesServiceProvider>
                              <RetailLoggedBets />
                            </NotesServiceProvider>
                          </RsBetsServiceProvider>

                        </BetsServiceProvider>
                      </RequestMenuServiceProvider>
                    </PermissionsGate>
                  }
                ></Route>
              </Route>

              {/* Nested Routes End */}

              <Route
                path="/leagues"
                element={
                  <PermissionsGate
                    scopes={[SCOPES.canAnalyze]}
                    errorProps={{ pageAuthorization: UNAUTHORIZED_PAGE }}
                  >
                    <Leagues />
                  </PermissionsGate>
                }
              />

              <Route
                path="/leagues/archived"
                element={
                  <PermissionsGate
                    scopes={[SCOPES.canAnalyze]}
                    errorProps={{ pageAuthorization: UNAUTHORIZED_PAGE }}
                  >
                    <RequestMenuServiceProvider>
                      <LeaguesServiceProvider>
                        <MatchPreviewServiceProvider>
                          <NotesServiceProvider>
                            <Archived />
                          </NotesServiceProvider>
                        </MatchPreviewServiceProvider>
                      </LeaguesServiceProvider>
                    </RequestMenuServiceProvider>

                  </PermissionsGate>
                }
              />

              <Route
                path="/leagues/squad_manager"
                element={
                  <PermissionsGate
                    scopes={[SCOPES.canAnalyze]}
                    errorProps={{ pageAuthorization: UNAUTHORIZED_PAGE }}
                  >
                    <SquadManagerServiceProvider>
                      <MatchPreviewServiceProvider>
                        <LeaguesServiceProvider>
                          <NotesServiceProvider>
                            <PlayerStatsServiceProvider>
                              <SquadManager />
                            </PlayerStatsServiceProvider>
                          </NotesServiceProvider>
                        </LeaguesServiceProvider>
                      </MatchPreviewServiceProvider>
                    </SquadManagerServiceProvider>
                  </PermissionsGate>
                }
              />
              <Route
                path="/leagues/players_data"
                element={
                  <PermissionsGate
                    scopes={[SCOPES.canAnalyze]}
                    errorProps={{ pageAuthorization: UNAUTHORIZED_PAGE }}
                  >
                    <SquadManagerServiceProvider>
                      <MatchPreviewServiceProvider>
                        <LeaguesServiceProvider>
                          <NotesServiceProvider>
                            <PlayerStatsServiceProvider>
                              <PlayersData />
                            </PlayerStatsServiceProvider>
                          </NotesServiceProvider>
                        </LeaguesServiceProvider>
                      </MatchPreviewServiceProvider>
                    </SquadManagerServiceProvider>
                  </PermissionsGate>
                }
              />
              <Route
                path="/leagues/match_preview"
                element={
                  <PermissionsGate
                    scopes={[SCOPES.canAnalyze]}
                    errorProps={{ pageAuthorization: UNAUTHORIZED_PAGE }}
                  >
                    <SquadManagerServiceProvider>
                      <NotesServiceProvider>
                        <LeaguesServiceProvider>
                          <MatchPreviewServiceProvider>
                            <MatchPreview />
                          </MatchPreviewServiceProvider>
                        </LeaguesServiceProvider>
                      </NotesServiceProvider>
                    </SquadManagerServiceProvider>
                  </PermissionsGate>
                }
              />

              <Route
                path="/audit"
                element={
                  <PermissionsGate
                    scopes={[SCOPES.canAudit]}
                    errorProps={{ pageAuthorization: UNAUTHORIZED_PAGE }}
                  >
                    <Audit />
                  </PermissionsGate>
                }
              />
            </Route>

          </Routes>
        </SystemStatusContextProvider>
      </ThemeProvider>

    </div>
  );
}

export default App;
