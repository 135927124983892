
import { POST } from "../APiHelper/API";


export const signIn = (email, password, onSuccess, onError) => {

	const data = {
		grant_type: "password",
		email: email,
		password: password,
		client_id: process.env.REACT_APP_CLIENT_ID,
		client_secret: process.env.REACT_APP_CLIENT_SECRET,
	};
	POST("/oauth/token", data, onSuccess, onError);
};
