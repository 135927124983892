import React, { useState } from "react";
import styled from "styled-components";
import {
	sortLoggedBetsByBetKey,
	sortLoggedBetsByLoggedBetKey,
	sortLoggedBetsLeaguesTeams,
} from "../../../helper/Betting/sortBets";
import SortCell from "../SortCell";
const LoggedBetsHeader = ({ loggedBetsList, setLoggedBetsList }) => {
	const [ascending, setAscending] = useState(true);

	const handleSort = (betsList, filterKey, setFunction) => {
		sortLoggedBetsByBetKey(betsList, filterKey, setFunction, ascending);
		setAscending(!ascending);
	};
	const handleSort2 = (betsList, filterKey, setFunction) => {
		sortLoggedBetsByLoggedBetKey(betsList, filterKey, setFunction, ascending);
		setAscending(!ascending);
	};
	const handleSortByLeagueAndTeam = (betsList, setFunction) => {
		sortLoggedBetsLeaguesTeams(betsList, setFunction, ascending);
		setAscending(!ascending);
	};
	return (
		<Container>
			<Cell style={{ minWidth: "30px", maxWidth: "40px" }}></Cell>
			<Cell style={{ minWidth: "70px" }}>
				<p>BROKER</p>
			</Cell>
			<Cell style={{ minWidth: "30px", maxWidth: "30px" }}>
				<p>TIER</p>
			</Cell>
			<CellWrap style={{ minWidth: "150px" }}>
				<SortCell
					name={"GAME"}
					onClick={() =>
						handleSortByLeagueAndTeam(loggedBetsList, setLoggedBetsList)
					}
				/>
			</CellWrap>
			<CellWrap style={{ minWidth: "100px" }}>
				<SortCell
					name={"SELECTION"}
					onClick={() =>
						handleSort(loggedBetsList, "selection", setLoggedBetsList)
					}
				/>
			</CellWrap>
			<CellWrap style={{ minWidth: "50px" }} className="center">
				<SortCell
					name={"MIN PRICE"}
					onClick={() =>
						handleSort(loggedBetsList, "min_price", setLoggedBetsList)
					}
				/>
			</CellWrap>

			<Cell style={{ minWidth: "51px" }}>
				<p>CON. PRICE</p>
			</Cell>
			<Cell style={{ minWidth: "60px" }}>
				<p>RET. STAKE</p>
			</Cell>
			<CellWrap className="center">
				<SortCell
					name={"MODEL VALUE"}
					onClick={() => handleSort(loggedBetsList, "value", setLoggedBetsList)}
				/>
			</CellWrap>
			<CellWrap className="center" style={{ minWidth: "40px" }}>
				<SortCell
					name={"MKT"}
					onClick={() =>
						handleSort(loggedBetsList, "mkt_value", setLoggedBetsList)
					}
				/>
			</CellWrap>
			<CellWrap className="center">
				<SortCell
					name={"LIVE VALUE"}
					onClick={() =>
						handleSort(loggedBetsList, "live_value", setLoggedBetsList)
					}
				/>
			</CellWrap>
			<Cell style={{ minWidth: "50px" }}>
				<p>TIME REQ.</p>
			</Cell>
			<Cell style={{ minWidth: "60px" }}>
				<p>TIME RET</p>
			</Cell>

			<CellWrap style={{ minWidth: "60px" }} className="center">
				<SortCell
					name={"LIQUIDITY"}
					onClick={() =>
						handleSort2(loggedBetsList, "liquidity", setLoggedBetsList)
					}
				/>
			</CellWrap>
			<Cell>
				<p>LEAGUE TIER</p>
			</Cell>
			<Cell className="toggleWidth">
				<p>CLASH</p>
			</Cell>
			<Cell className="toggleWidth">
				<p>OPPO</p>
			</Cell>

			<Cell style={{ minWidth: "70px" }}>
				<p>BET STATUS</p>
			</Cell>
			<Cell>
				<p>INCORRECT</p>
			</Cell>
			<Cell>
				<p>ABNORMAL</p>
			</Cell>
			<Cell style={{ minWidth: "50px" }}>
				<p>NOTES</p>
			</Cell>
			<Cell style={{ minWidth: "40px" }}></Cell>
		</Container>
	);
};

export default LoggedBetsHeader;
const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-width: 1600px;
  background-color: ${({ theme }) => theme.leagueBG};
  color: ${({ theme }) => theme.betHeaderTextColor};
  padding: 14px 14px;
  column-gap: 9px;
  max-height: 36px;
  .center {
    justify-content: center;
  }
  .toggleWidth {
    min-width: 40px;
  }
`;

const CellWrap = styled.div`
  display: flex;
  align-items: center;
  min-width: 60px;
  width: 100%;
`;
const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  width: 100%;

  p {
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    text-transform: uppercase;
  }
`;
