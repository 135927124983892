import React, { useState } from 'react'
import styled from 'styled-components';
import { ReactComponent as XIcon } from "../../../assets/icons/xBtn.svg";
import AddNewSeason from './AddNewSeason';
import AddNewCupCard from './AddNewCupCard';

const AddNewEvents = ({ setIsAddNewSeasonOpen, setSearchParams,
    setSeasonsAndCupsList, setSelectedSeasonsAndCups
}) => {
    const SEASON = "season"
    const CUP = "cup"
    const [eventType, setEventType] = useState(SEASON);

    var content;

    switch (eventType) {
        case SEASON:
            content = <AddNewSeason
                setSeasonsAndCupsList={setSeasonsAndCupsList}
                setSelectedSeasonsAndCups={setSelectedSeasonsAndCups}
                setIsAddNewSeasonOpen={setIsAddNewSeasonOpen}
                setSearchParams={setSearchParams}
            />
            break;
        case CUP:
            content = <AddNewCupCard
                setIsAddNewSeasonOpen={setIsAddNewSeasonOpen}
                setSeasonsAndCupsList={setSeasonsAndCupsList}
                setSelectedSeasonsAndCups={setSelectedSeasonsAndCups}
            />
        default:
            break;
    }
    const handleClose = () => {
        setIsAddNewSeasonOpen(false)
    }
    return (

        <CardContainer>
            <Card>
                <Top>
                    <p>Add new {eventType}</p>{" "}
                    <button onClick={() => handleClose()}>
                        <XIcon />
                    </button>
                </Top>
                <Content>
                    <SelectEventType>
                        <Event onClick={() => setEventType(SEASON)} selected={eventType === SEASON}>
                            <p>{SEASON}</p>
                        </Event>
                        <Event onClick={() => setEventType(CUP)} selected={eventType === CUP}>
                            <p>{CUP}</p>
                        </Event>
                    </SelectEventType>
                    <>
                        {content}
                    </>
                </Content>
            </Card>
        </CardContainer>




    )
}

export default AddNewEvents

const CardContainer = styled.div`
position: fixed;
top: 0;
left: 0;
bottom: 0;
width: 100%;
background: rgba(0, 0, 0, 0.3);
display: flex;
justify-content: center;
z-index: 4;
overflow: hidden;
padding: 5vh 20vw;
`;

const Card = styled.div`
  position: sticky;
  top: 50px;
  width: 780px;
  height: 100%;
  max-height:900px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.editLeague.bg};
  box-shadow: 0px 1px 8px rgba(46, 46, 46, 0.04);
  border-radius: 16px;
overflow:hidden;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px 40px;
  background: ${({ theme }) => theme.editLeague.headerBg};
  border-radius: 16px 16px 0px 0px;
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: ${({ theme }) => theme.editLeague.headerColor};
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
height:100%;
width:100%;
overflow: hidden;
`;

const SelectEventType = styled.div`
display:flex;
border:1px solid ${({ theme }) => theme.editLeague.addEventModal.selectedOptionBg};
width:fit-content;
border-radius:2px;
margin-left:40px;
margin-top:40px;
`
const Event = styled.div`
display:flex;
align-items:center;
justify-content:center;
padding:6px 30px;
min-width:100px;
max-width:100px;
width:100%;
cursor:pointer;
background:${(props) => props.selected ? ({ theme }) => theme.editLeague.addEventModal.selectedOptionBg : ({ theme }) => theme.editLeague.addEventModal.optionBg};
p{
    font-family: Roboto;
    font-size: 9px;
    font-weight: 700;
    line-height: 11px;
    text-align: center;
    text-transform:uppercase;
    color:${(props) => props.selected ? ({ theme }) => theme.editLeague.addEventModal.selectedOptionColor : ({ theme }) => theme.editLeague.addEventModal.optionColor};
    }
`