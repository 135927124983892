import React from 'react'
import styled from 'styled-components';
import PermissionsGate from '../../../helper/Generic/PermissionGate';
import { SCOPES } from '../../../helper/Generic/Permissions';
import DeleteConfirmationDouble from '../../DeleteConfirmationDouble';


const LeagueElement = ({ handleDeleteLeague, item }) => {
    return (
        <AddedElement >
            <ElementInput value={item.name} disabled />
            <PermissionsGate
                scopes={[SCOPES.canEdit]}
                errorProps={{ disableButton: true }}>
                <DeleteConfirmationDouble
                    handleRemoveItem={handleDeleteLeague}
                    item={item.id}
                />
            </PermissionsGate>
        </AddedElement>


    )
}

export default LeagueElement

const AddedElement = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 6px;
`;

const ElementInput = styled.input`
  height: 40px;
   background: ${({ theme }) => theme.editLeague.readOnlyInputs};
  width: 240px;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color:${({ theme }) => theme.editLeague.readOnlyInputsColor};
  padding: 10px 15px;
  :focus {
    outline:none;
  }
  ::placeholder {
     color:${({ theme }) => theme.editLeague.readOnlyInputsColor};
     opacity:0.7;
  }
  :disabled{
    background: ${({ theme }) => theme.editLeague.readOnlyInputs};
    color:${({ theme }) => theme.editLeague.readOnlyInputsColor};

  }
`;