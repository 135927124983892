

import { POST } from "../APiHelper/API"

export const getSquadAvailableForDataTransferApi = (fixture_id, date = null, onSuccess, onError) => {
    const data = {
        fixture_id: fixture_id
    }

    if (date) {
        data.date = date;
    }

    POST(`/api/v1/squads/fixture/fixtures_for_transfer`, data, onSuccess, onError)
}