export const lightTheme = {
  headerBG: "#CBD7F5",
  menuTextColor: "#3C4A5C",
  menuBgColor: "#FFFFFF",
  buttonBG: "#006FFF",
  buttonTextColor: "white",
  middleBorder: "rgba(204, 217, 252, 1)",
  //------------>  <------------//
  dropDownColor: "#3C4A5C",
  dropDownBG: "rgba(248, 249, 252, 1)",
  dropDownSvgFill: "#F8F9FB",
  dropDownSvgStroke: "#006FFF",
  toggleBg: "#CBD7F5",
  toggleColor: "white",
  //------------>  <------------//
  leagueBG: "rgba(238, 242, 253, 1)",
  leagueBG2: "rgba(204, 217, 252, 1)",
  leagueColor: "rgba(0, 18, 41, 0.8)",
  matchElementBg: "#ffffff",
  matchElementBgindexL: "rgba(248, 249, 252, 1)",
  matchElementBgindexR: "rgba(231, 237, 255, 1)",
  matchElementBgFocused: "#C0CBEB",
  matchElementDetailsBgL: "#fff",
  matchElementDetailsBgR: "rgba(231, 237, 255, 1)",
  matchHighlitedText: "#006FFF",
  matchFctText: "rgba(17, 89, 186, 1)",
  matchBtnsColor: "#8298CF",
  matchDistinctValue: "#E465ED",
  matchDistinctValueModel: "#F57600",

  cupElementHeaderBg: "#D9DEF0",
  seasonElementHeaderBg: "#D9DEF0",
  cupElementTitleBg: "#A4B4DF",
  seasonElementTitleBg: "#A4B4DF",
  //------------>  <------------//
  matchProcentG: "#23A760",
  matchProcentR: "#EC4848",
  //------------>  <------------//
  favoriteStarFill: "#F2AF1F",
  favoriteStarStroke: "#001229",
  //------------>  <------------//
  notePanelBg: "white",
  notesBG: "#F8F9FC",
  notesTextColor: "rgba(0, 18, 41, 0.8)",
  notexInputColor: "rgba(88, 95, 103, 0.6)",
  //------------>  <------------//
  graphBg: "#FFFFFF",
  graphTitleText: "rgba(0, 18, 41, 0.8)",
  graphLeagueText: "#585E6C",
  graphLeagueSelectionMenuItemBg: "#EEF2FD",
  graphLeagueLine: "#ffffff",
  //------------>  <------------//
  gameCompetitionBg: "#E4E9F8",
  gameCompetitionListNoBg: "#F8F9FB",
  gameCompetitonColor: "rgba(58, 57, 87, 0.8);",
  //------------>  <------------//
  bettTextColor: "#3A3957",
  betHeaderTextColor: "#001229",
  topPriority: "#173FCB",
  //------------>  <------------//
  lineupDuplicateSelectionColor: "#F96A6A",
  lineupChangedSelectionColor: "#FFF5C1",
  //------------>  <------------//
  squadManager: {
    headerBg: "#eef1f6",
    bg: "#fff",
    textColor: "#001229",
    confirmedAttendanceColor: "#001229",
    matchElementP2Header: "#464f65",
    futureMatchHeader: "#e1e9ff",
    finishedMatchHeader: "#AFBFE8",
    finishedMatchHeader2: "#464f65",
    playerBg: "white",
    playerSelected: "#DEE3F0",
    menuBg: "white",
    menuTextColor: "#001229CC",
    menuSvgColor: "#006fff",
    highlightColor: "#C0CBEB",
    noStChanceColor: "#CCCCCC",
    transferredIcon: "#4174B2"

  },

  groupPlayersModal: {
    menuBg: "rgba(187, 200, 218, 1)",
    menuColor: "rgba(255, 255, 255, 1)",
    menuListBg: "rgba(248, 249, 251, 1)",
    menuListColor: "rgba(0, 18, 41, 0.8)",
    checkFill: "#006fff",
  },

  playerPerformaceCard: {
    cardBg: "rgba(255, 255, 255, 1)",
    cardColor: "rgba(51, 51, 51, 1)",
    gk: {
      bg: "rgba(235, 252, 243, 1)",
      color: "rgba(35, 167, 96, 0.8)",
    },
    neutral: {
      bg: "rgba(255, 243, 231, 1)",
      color: "rgba(255, 141, 33, 1)",
    },
    attack: {
      bg: "rgba(255, 245, 245, 1)",
      color: "rgba(255, 75, 75, 1)",
    },
    defensive: {
      bg: "rgba(228, 236, 255, 1)",
      color: "rgba(67, 147, 252, 1)",
    }

  },
  statusColor: {
    available: {
      bg: "#FFF",
      p: "green",
      h2: "#638673",
      border: "#47b77b"
    },
    availableGreen: {
      bg: "#e6faef",
      p: "green",
      h2: "#638673",
      border: "#47b77b"
    },
    availableOrange: {
      bg: " #FFF8F1",
      p: "#FF8D21",
      h2: "#FF8D21",
      border: "#FF8D21"
    },
    injured: {
      bg: "  #fff1f1",
      p: "#ec4848",
      h2: "rgba(236, 72, 72, 0.8)",
      border: "#ec4848"
    },
    rested: {
      bg: "#eaf3ff",
      p: "#4393fc",
      h2: "#4393fc",
      border: "#4393fc"
    },
    unavailable: {
      bg: "#f9f5ff",
      p: "#9965ed",
      h2: "rgba(153, 101, 237, 0.8)",
      border: "#9965ed"
    },
    bench: {
      bg: "#EDEDED",
      p: "#949494",
      h2: "#949494",
      border: "#949494"
    },
    fatigue: {
      f1: "#e465ed",
      f2: "#ff8d21",
      f3: "#ec4848"
    },
    transferred: {
      bg: "#DEEDFF",
      p: "#4174B2",
      h2: "#4174B2",
      border: "#4174B2"
    }


  },
  matchPreview: {
    bg: "#FFF",
    matchBg2: "white",
    matchBg1: "#F9FAFE",
    matchSelectedBg: "#B6CBFF",
    matchListHeaderBg: "#eef2fd",
    matchFactorsBg: "#F9FAFE",
    textColor: '#001229',
    activeHeaderMenuText: "#FFF",
    inactiveHeaderMenuText: "#7D8692",
    activeMenuBg: "#263549",
    matchFactorsReadOnlyInputs: "#EBEFFF",
    optionMenuActive: "#263549",
    headerBg: '#CBD7F5',
    selectMenuBg: "white",
    selectMenuTextColor: "#001229",
    selectMenuSvgBg: "#f8f9fb",
    selectMenuSvgColor: "#006fff",
    noteBg: "white",
    noteEditBtnBg: "white",
    pitchConditionTextColor: "#333",
    pitchConditionIconStroke: "#006FFF",
    pitchConditionIconFill: "white",

  },
  projectedLineup: {
    benchBg: "linear-gradient(0deg, #ebefff, #ebefff),linear-gradient(0deg, #f1f9f5, #f1f9f5), #f1f9f5",
    benchBorder: "#ffffff",
    lineupBlock: "#E3E9FF",
    playerMenuTextColor: "rgba(0, 18, 41, 0.80);",
    playerMenuTextColorPlayerChanged: "rgba(0, 18, 41, 0.80);",
    playerMenuBg: "white",
    playerMenuBorder: "#cbd7f5",
    playerChanged: "#FFF5C1",
    playerDuplicated: "#FFC5C1",
    fieldHomeColor: "#9868FE",
    fieldAwayColor: "#2097BC",
    editButtonColor: "#006FFF",
    lineUpMenuBorderColor: "rgba(66, 104, 255, 0.5)"
  },

  editLeague: {
    headerColor: "#001229",
    headerBg: "linear-gradient(0deg, #F8F9FB 0%, #F8F9FB 100%), linear-gradient(0deg, #F1F9F5 0%, #F1F9F5 100%), #F1F9F5",
    bg: "white",
    menuTextColor: "rgba(0, 18, 41, 0.80)",
    groupsTextColor: "#001229",
    borderColor: " #f8f9fb",
    readOnlyInputs: "#fff",
    readOnlyInputsColor: "rgba(46, 46, 46, 0.80)",
    wyChanged: "#F0F4FF",
    teamListItemBg: "white",
    teamListItemSelected: "#CCD9FC",
    teamDetailsCardsBg: "#f9fafe",
    selectMenuBg: "white",
    selectMenuTextColor: "rgba(46, 46, 46, 0.80)",
    selectMenuSvgBg: "white",
    selectMenuSvgColor: "#006FFF",
    saveAllBtnBg: "#ffffff",
    saveAllBtnColor: "#006fff",
    buttonWithConfirmationBg: "white",
    buttonWithConfirmationTextColor: "#006fff",
    buttonWithConfirmationBorder: "#006fff",
    transferredPlayerBg: "#E2EFFF",

    leagueDetails: {
      deletedTeamsBg: "rgba(235, 239, 255, 1)",
      deletedTeamsColor: "rgba(46, 46, 46, 0.80)",
    },

    addEventModal: {
      menuBg: "#BBC8DA",
      optionBg: "#EEF2FD",
      selectedOptionBg: "#263549",
      optionColor: "#7D8692",
      selectedOptionColor: "#FFFFFF",
      selectionItemBg: "#EEF2FD"
    }
  },
  // fieldPlayers: {
  //   fatigue: {
  //     textColor: "#47B77B",
  //     bg: "#E8FFF2"
  //   },
  //   fatigue1: {
  //     textColor: "#E44EF0",
  //     bg: "#FDE5FF"
  //   },
  //   fatigue2: {
  //     textColor: "#FF9C3E",
  //     bg: "#FFF3E7"
  //   },
  //   fatigue3: {
  //     textColor: "#EC4848",
  //     bg: "#FFF5F5"
  //   }
  // },
  matchStatus: {
    notCompleted: {
      bg: "#fff9f9",
      p: "#ec4848"
    },
    partialFactors: {
      bg: "#dee8ff",
      p: "#006fff"
    },
    lineupSaved: {
      bg: "#fff3e7",
      p: "#FF8D21"
    },

    lineupSubmitted: {
      bg: " #fde6ff",
      p: "#e44ef0"
    },
    previewSubmitted: {
      bg: "#ecfff5",
      p: "#47b77b"
    },
    plConfirmed: {
      bg: "#fffeeb",
      p: "#d8b70c"
    },
    pmCompleted: {
      bg: "#EEF2FD",
      p: "#9398AB"
    },

    xiCompleted: {
      bg: "#E0D3D8",
      p: "#7C363E"
    }

  },
  betting: {
    rsBetModal: {
      groupColor: "#001229",
      bg: "#FFFFFF",
      disabledInputBg: "#BAC3E833",
      detailsBg: "#E4E9F8",
      detailsColor: "#001229CC",
      inputBg: "#FFFFFF",
      inputColor: "#2E2E2E",
      activeUntilColor: "#263549",
      bookmakerColor: "rgba(46,46,46,0.8)",
      teamsColor: "#3A3957",
      inputPlaceHolderColor: "rgba(46, 46, 46, 0.80)"
    }


  },

  playersData: {
    background: "rgba(255, 255, 255, 1)",
    color: "rgba(0, 18, 41, 0.8)",
    elementLeftBg: "rgba(248, 249, 252, 1)",
    elementRightBg: "rgba(238, 242, 253, 1)",
    headerLeftBg: "rgba(238, 242, 253, 1)",
    headerRightBg: "rgba(196, 208, 242, 1)",
  },
  POSCell: {
    gkBg: "#ebfcf3",
    gkColor: "#23a760",
    gkBorder: "#cbf2dc",
    defBg: "#e4ecff",
    defColor: "#4393fc",
    defBorder: "#d5dff7",
    neutralBg: "#fff3e7",
    neutralColor: "#ff8d21",
    neutralBorder: " #f6dabd",
    atkBg: "#fff5f5",
    atkColor: "#ec4848",
    atkBorder: "#f9dddd",

  }
};
export const darkTheme = {
  headerBG: "rgba(0, 18, 41, 1)",
  menuTextColor: "#FFFFFF",
  menuBgColor: "#3F4353",
  buttonBG: "#4897FF",
  buttonTextColor: "#2A2F40",
  middleBorder: "rgba(72, 80, 111, 1)",
  //------------>  <------------//
  dropDownColor: "#FFFFFF",
  dropDownBG: "#3F4353",
  dropDownSvgFill: "#001229",
  dropDownSvgStroke: "#4897FF",
  toggleBg: "rgba(0, 111, 255, 0.5)",
  toggleColor: "#006FFF",
  //------------>  <------------//
  leagueBG: "rgba(0, 18, 41, 1)",
  leagueBG2: "rgba(2, 35, 78, 1)",
  leagueColor: "rgba(255, 255, 255, 0.8)",
  matchElementBg: "#2A2F40",
  matchElementBgindexL: "rgba(63, 67, 83, 1)",
  matchElementBgindexR: "linear-gradient(0deg, #48506F, #48506F), #3F4353",
  matchElementBgFocused: "#7937AD",
  matchElementDetailsBgL: "#3F4353",
  matchElementDetailsBgR: "rgba(72, 80, 111, 1)",
  matchHighlitedText: "#4897FF",
  matchFctText: "#4897FF",
  matchBtnsColor: "#9AB4F2",
  matchDistinctValue: "#D871E2",
  matchDistinctValueModel: "#FF7612",
  cupElementHeaderBg: "#2C394B",
  seasonElementHeaderBg: "#2C394B",
  cupElementTitleBg: "#0A2647",
  seasonElementTitleBg: "#0A2647",

  //------------>  <------------//
  matchProcentG: "#34CA79",
  matchProcentR: "#FA5C5C",
  //------------>  <------------//
  favoriteStarFill: "#FFDA15",
  favoriteStarStroke: "#FFFFFF",
  //------------>  <------------//
  notePanelBg: "#2A2F40",
  notesBG: "rgba(83, 89, 109, 1)",
  notesTextColor: "rgba(255, 255, 255, 0.8)",
  notexInputColor: "rgba(255, 255, 255, 0.6)",
  //------------>  <------------//
  graphBg:
    "linear-gradient(0deg, #001229, #001229), linear-gradient(0deg, #001229, #001229), #001229;",
  graphTitleText: "rgba(255, 255, 255, 0.8)",
  graphLeagueText: "#CCD0D4",
  graphLeagueSelectionMenuItemBg: "#3F4353",
  graphLeagueLine: "#001229",
  //------------>  <------------//
  gameCompetitionBg: "#57586F",
  gameCompetitionListNoBg: "#606177",
  gameCompetitonColor: "rgba(255, 255, 255, 0.8)",
  //------------>  <------------//
  bettTextColor: "#FFFFFF",
  betHeaderTextColor: "#FFFFFF",
  topPriority: "#5259FF",
  //------------>  <------------//
  lineupDuplicateSelectionColor: "#F96A6A",
  lineupChangedSelectionColor: "#FFF5C1",
  //------------>  <------------//
  squadManager: {
    headerBg: "#001229",
    bg: "#606A90",
    textColor: "#fff",
    confirmedAttendanceColor: "#ffffff",
    matchElementP2Header: "#2A2F40",
    futureMatchHeader: "#464B5C",
    finishedMatchHeader: "#464B5C",
    finishedMatchHeader2: "#464B5C",
    playerBg: "#48506F",
    playerSelected: "#2A2F40",
    menuBg: "#79809B",
    menuTextColor: "#FFFFFF",
    menuSvgColor: "#FFFFFF",
    highlightColor: "#9868FE",
    noStChanceColor: "#989898",
    transferredIcon: "#4174B2"
  },

  groupPlayersModal: {
    menuBg: "#263549",
    menuColor: "rgba(255, 255, 255, 1)",
    menuListBg: "#79809B",
    menuListColor: "white",
    checkFill: "white",
  },

  playerPerformaceCard: {
    cardBg: "rgba(72, 80, 111, 1)",
    cardColor: "rgba(255, 255, 255, 1)",
    gk: {
      bg: "rgba(235, 252, 243, 1)",
      color: "rgba(35, 167, 96, 0.8)",
    },
    neutral: {
      bg: "rgba(255, 243, 231, 1)",
      color: "rgba(255, 141, 33, 1)",
    },
    attack: {
      bg: "rgba(255, 245, 245, 1)",
      color: "rgba(255, 75, 75, 1)",
    },
    defensive: {
      bg: "rgba(228, 236, 255, 1)",
      color: "rgba(67, 147, 252, 1)",
    }
  }
  ,
  statusColor: {
    available: {
      bg: "rgba(255, 255, 255, 1)",
      p: "#44AA99",
      h2: " #44AA99",
      border: "#44AA99"
    },
    availableGreen: {
      bg: "#A9FFF2",
      p: "#44AA99",
      h2: "#44AA99",
      border: "#44AA99"
    },
    availableOrange: {
      bg: " #FFEDF0",
      p: "#CC6577",
      h2: "#CC6577",
      border: "#CC6577"
    },
    injured: {
      bg: " #FFE3FA",
      p: "#882155",
      h2: "#882155",
      border: "#882155"
    },
    rested: {
      bg: "#EDF5FF",
      p: "#009DEB",
      h2: "#009DEB",
      border: "#009DEB"
    },
    unavailable: {
      bg: "#FFBEF3",
      p: "#AA4499",
      h2: "#AA4499",
      border: "#AA4499"
    },
    bench: {
      bg: "#FFEFD0",
      p: "#FFA500",
      h2: "#FFA500",
      border: "#FFA500"
    },
    fatigue: {
      f1: "#FF5375",
      f2: "#CC6577",
      f3: "#EC4848"
    },
    transferred: {
      bg: "#DEEDFF",
      p: "#4174B2",
      h2: "#4174B2",
      border: "#4174B2"
    }

  },
  matchPreview: {
    bg: "#2A2F40",
    matchBg2: "#2A2F40",
    matchBg1: "#48506F",
    matchFactorsBg: "#48506F",
    matchSelectedBg: "#4678B8",
    matchListHeaderBg: "#464B5C",
    textColor: '#FFF',
    activeHeaderMenuText: "#001229",
    inactiveHeaderMenuText: "#7D8692",
    activeMenuBg: "#FFFFFF",
    matchFactorsReadOnlyInputs: "#263549",
    optionMenuActive: "#FFF",
    headerBg: '#001229',
    selectMenuBg: "#79809B",
    selectMenuTextColor: "#FFFFFF",
    selectMenuSvgBg: "#48506F",
    selectMenuSvgColor: "white",
    noteBg: "#464B5C",
    noteEditBtnBg: "#001229",
    pitchConditionTextColor: "white",
    pitchConditionIconStroke: "#BAC3E8",
    pitchConditionIconFill: "#263549",

  },
  projectedLineup: {
    benchBg: "linear-gradient(0deg, #02234E 0%, #02234E 100%), linear-gradient(0deg, #F1F9F5 0%,#F1F9F5 100%), #F1F9F5;",
    benchBorder: "#11478D",
    lineupBlock: "#2A2F40",
    playerMenuTextColor: "#FFF",
    playerMenuTextColorPlayerChanged: "rgba(0, 18, 41, 0.80);",
    playerMenuBg: "#2A2F40",
    playerChanged: "#FFE665",
    playerDuplicated: "#FF6060",
    playerMenuBorder: "#2A2F40",
    fieldHomeColor: "#E28976",
    fieldAwayColor: "#A186DF",
    editButtonColor: "white",
    lineUpMenuBorderColor: "rgba(213, 94, 0, 0.5)",

  },
  editLeague: {
    headerColor: "#fff",
    headerBg: "#48506F",
    bg: "#2A2F40",
    menuTextColor: "#fff",
    groupsTextColor: "#fff",
    borderColor: " #48506F",
    readOnlyInputs: "#263549",
    readOnlyInputsColor: "white",
    wyChanged: "#48506F",
    teamListItemBg: "#2A2F40",
    teamListItemSelected: "#48506F",
    teamDetailsCardsBg: "#48506F",
    selectMenuBg: "#79809B",
    selectMenuTextColor: "#FFFFFF",
    selectMenuSvgBg: "#48506F",
    selectMenuSvgColor: "white",
    saveAllBtnBg: "#006fff",
    saveAllBtnColor: "#fff",
    buttonWithConfirmationBg: "#79809B",
    buttonWithConfirmationTextColor: "white",
    buttonWithConfirmationBorder: "#BAC3E8",
    transferredPlayerBg: "#A0ABD9",

    leagueDetails: {
      deletedTeamsBg: "#263549",
      deletedTeamsColor: "white",
    }
    ,
    addEventModal: {
      menuBg: "#79809B",
      optionBg: "#EEF2FD",
      selectedOptionBg: "#263549",
      optionColor: "#7D8692",
      selectedOptionColor: "#FFFFFF",
      selectionItemBg: "#263549"
    }
  },
  // fieldPlayers: {
  //   fatigue: {
  //     textColor: "#44AA99",
  //     bg: "#BFFFF6"
  //   },
  //   fatigue1: {
  //     textColor: "#FF5375",
  //     bg: "#FFF6F8"
  //   },
  //   fatigue2: {
  //     textColor: "#CC6577",
  //     bg: "#FFEDF0"
  //   },
  //   fatigue3: {
  //     textColor: "#882155",
  //     bg: "#FFE3FA"
  //   },
  // },

  matchStatus: {
    notCompleted: {
      bg: "#FFE3FA",
      p: "#882155"
    },
    partialFactors: {
      bg: "#EDF5FF",
      p: "#009DEB"
    },
    lineupSaved: {
      bg: "#FFEDF0",
      p: "#CC6577"
    },

    lineupSubmitted: {
      bg: " #FFF6F8",
      p: "#FF5375"
    },
    previewSubmitted: {
      bg: "#BFFFF6",
      p: "#44AA99"
    },
    plConfirmed: {
      bg: "#FFFEEB",
      p: "#BD9E02"
    },
    pmCompleted: {
      bg: "#EEF2FD",
      p: "#757575"
    },
    xiCompleted: {
      bg: "#E0D3D8",
      p: "#7C363E"
    }
  },
  betting: {
    rsBetModal: {
      groupColor: "white",
      bg: "#2A2F40",
      disabledInputBg: "#263549",
      detailsBg: "rgba(186, 195, 232, 0.20)",
      detailsColor: "white",
      inputBg: "#263549",
      inputColor: "white",
      activeUntilColor: "white",
      bookmakerColor: "white",
      teamsColor: "white",
      inputPlaceHolderColor: "rgba(255,255,255,1)"
    }

  },

  playersData: {
    background: "rgba(255, 255, 255, 1)",
    color: "rgba(255, 255, 255, 0.8)",
    elementLeftBg: "rgba(63, 67, 83, 1)",
    elementRightBg: "#2A2F40",
    headerLeftBg: "rgba(0, 18, 41, 1)",
    headerRightBg: "rgba(2, 35, 78, 1)",
  },

  POSCell: {
    gkBg: "#ebfcf3",
    gkColor: "#23a760",
    gkBorder: "#cbf2dc",
    defBg: "#e4ecff",
    defColor: "#4393fc",
    defBorder: "#d5dff7",
    neutralBg: "#fff3e7",
    neutralColor: "#ff8d21",
    neutralBorder: " #f6dabd",
    atkBg: "#fff5f5",
    atkColor: "#ec4848",
    atkBorder: "#f9dddd",

  }
};
