export const Positions = [
    { position: "GK" },
    { position: "LWB" },
    { position: "LB" },
    { position: "CB" },
    { position: "RB" },
    { position: "RWB" },
    { position: "DM" },
    { position: "CM" },
    { position: "LM" },
    { position: "RM" },
    { position: "CAM" },
    { position: "AM" },
    { position: "LW" },
    { position: "RW" },
    { position: "CF" },
];

// if change values here change also in PlayerHelper.js