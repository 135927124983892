import copy from "copy-to-clipboard";


export const copyToClipBoard = (
  home_team,
  away_team,
  competition_name,
  selection,
  min_price,
  country
) => {
  const text = `${country} ${competition_name} 
${home_team} - ${away_team} 
${selection} @${min_price.toFixed(2)}`;

  copy(text);
};
