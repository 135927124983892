import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import MatchExpandDetails from "./MatchExpandDetails";
import { ReactComponent as PencilNoteSvg } from "../../../assets/icons/PricingPageIcons/pencilNote.svg";
import { ReactComponent as MatchGraphSvg } from "../../../assets/icons/PricingPageIcons/matchGraph.svg";
import { ReactComponent as FavoriteFilled } from "../../../assets/icons/PricingPageIcons/favoriteFilled.svg";
import { ReactComponent as FavoriteEmpty } from "../../../assets/icons/PricingPageIcons/favoriteEmpty.svg";
import { useSelector } from "react-redux";
import { selectUser } from "../../../Redux/user/user";
import { format_time_to_timezone } from "../../../helper/Generic/dateTimeFormat";
import moment from "moment";
import {
  percentageChange,
  filterElementsByName,
  findMinDiffFromValues,
  formatNumberForValue,
  shouldMakeValuesBold,
} from "../../../helper/ModelDataHelper";
import {
  addFixtureToFavorites,
  removeFixtureFromFavorites,
} from "../../../api/fixtures/FixtureFavorites";
import { notifyError } from "../../../helper/Generic/Notifications";

import {
  calculateHAHDAAADValues,
  calculateModel1X2Values,
  calculateModelSupTgx,
  calculateMarketSupTgx,
  calculateMarket1X2Values,
  calculateModelHAMainLine,
  calculateModelOUMainLine,
  calculateMarketHAMainLine,
  calculateMarketOUMainLine,
  calculatePriceDiff,
  calculateHADiff,
  calculateOUDiff,
  computeSubLines,
} from "../../../helper/fixturesPricingHelper";

import $, { data } from "jquery";

const Item = ({
  value,
  className = null,
  type = null,
  fixture = null,
  bold = false,

  handleExpand,
}) => {
  let elementId = "";
  if (type != null && fixture != null) {
    elementId = type + "-" + fixture;
  }

  const hoverOn = ({ e }) => {
    highlightToggle();
  };

  const hoverOff = ({ e }) => {
    highlightToggle();
  };

  const highlightToggle = () => {
    if (elementId.length == 0) {
      return;
    }
    const selector = "." + elementId;
    $(selector).each(function () {
      $(this).toggleClass("highlight-value");
    });
  };

  let boldClass = "";
  if (bold == true) {
    boldClass = "bold-price";
  }

  return (
    <Cell className={elementId} onClick={handleExpand}>
      <div
        className="item-container"
        onMouseOver={hoverOn}
        onMouseLeave={hoverOff}
      >
        <h2 className={className + " " + boldClass}>{value}</h2>
      </div>
    </Cell>
  );
};

const Match = ({ handleNote, fixture, bg, fixtureData, bookmakersIds }) => {
  const bookmaker_ids = bookmakersIds;

  // Model simple values
  let handicapValues = calculateHAHDAAADValues(fixtureData.price);
  let ha = handicapValues.ha;
  let hd = handicapValues.hd;
  let aa = handicapValues.aa;
  let ad = handicapValues.ad;

  let model1X2Values = calculateModel1X2Values(fixtureData.price);
  let model_1 = model1X2Values.model_1;
  let model_x = model1X2Values.model_x;
  let model_2 = model1X2Values.model_2;

  let modelSupTXG = calculateModelSupTgx(fixtureData.price);
  let model_sup = modelSupTXG.model_sup;
  let model_tgx = modelSupTXG.model_tgx;

  // Market values
  let marketSupTXG = calculateMarketSupTgx(fixtureData.price);
  let market_sup = marketSupTXG.market_sup;
  let market_tgx = marketSupTXG.market_tgx;

  let market1X2Values = calculateMarket1X2Values(
    fixtureData.price,
    bookmaker_ids
  );
  let market_1 = market1X2Values.market_1;
  let market_x = market1X2Values.market_x;
  let market_2 = market1X2Values.market_2;

  // Model HA main line
  const modelHAMainLine = calculateModelHAMainLine(
    fixtureData.price,
    bookmaker_ids
  );
  let model_ha_value = modelHAMainLine.model_ha_value;
  let model_h = modelHAMainLine.model_h;
  let model_a = modelHAMainLine.model_a;

  // Model OU main line
  const modelOUMainLine = calculateModelOUMainLine(
    fixtureData.price,
    bookmaker_ids
  );
  let model_ou_value = modelOUMainLine.model_ou_value;
  let model_o = modelOUMainLine.model_o;
  let model_u = modelOUMainLine.model_u;

  // Market HA Main line
  const marketHAMainLine = calculateMarketHAMainLine(
    fixtureData.price,
    bookmaker_ids
  );
  let market_ha_value = marketHAMainLine.market_ha_value;
  let market_h = marketHAMainLine.market_h;
  let market_a = marketHAMainLine.market_a;

  // Market OU Main line
  const marketOUMainLine = calculateMarketOUMainLine(
    fixtureData.price,
    bookmaker_ids
  );

  let market_ou_value = marketOUMainLine.market_ou_value;
  let market_o = marketOUMainLine.market_o;
  let market_u = marketOUMainLine.market_u;

  //   Differences

  //   prices differences
  const priceDiff = calculatePriceDiff(fixtureData.price, bookmaker_ids);
  let diff_1 = priceDiff.diff_1;
  let diff_x = priceDiff.diff_x;
  let diff_2 = priceDiff.diff_2;

  // HA Difference
  const haDiff = calculateHADiff(fixtureData.price, bookmaker_ids);
  let diff_h = haDiff.diff_h;
  let diff_a = haDiff.diff_a;

  // OU Difference
  const ouDiff = calculateOUDiff(fixtureData.price, bookmaker_ids);
  let diff_o = ouDiff.diff_o;
  let diff_u = ouDiff.diff_u;

  const user = useSelector(selectUser);
  const [expanded, setExpanded] = useState(false);
  const [focused, setFocused] = useState(false);
  const [favorite, setFavorite] = useState(fixtureData.fixture.is_favorite);

  const ref = useRef(null);

  const _handleFocused = (e) => {
    if (e.detail === 1) {
      if (focused === false) {
        setFocused(true);
      } else {
        setFocused(false);
      }
    }
    if (e.detail === 2) {
      _handleExpand();
    }
  };
  const _handleExpand = () => {
    if (expanded === false) {
      setExpanded(true);
      setFocused(true);
    } else {
      setExpanded(false);
    }
  };

  {
    /*Get Favorites Fixture */
  }
  const handleFavorite = () => {
    if (favorite === true) {
      setFavorite(false);
      removeFixtureFromFavorites(
        fixtureData.fixture.fixture_id,
        function () { },
        function (error) {
          notifyError("Failed to remove");
          setFavorite(true);
        }
      );
    }

    if (favorite === false) {
      setFavorite(true);
      addFixtureToFavorites(
        fixtureData.fixture.fixture_id,
        function () { },
        function (error) {
          notifyError("Failed to add favorite");
          setFavorite(false);
        }
      );
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (focused && ref.current && !ref.current.contains(e.target)) {
        setFocused(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [focused]);

  {
    /*Set expanded element rows values*/
  }
  const MakeRows = () => {
    const sublines = computeSubLines(fixtureData.price, bookmaker_ids);

    let rows = [];
    sublines.forEach((element, index) => {
      const highlightModelHA = element.model_ha_value == model_ha_value;
      const highlightModelOU = element.model_ou_value == model_ou_value;
      const highlightMarketHA = element.market_ha_value == market_ha_value;
      const highlightMarketOU = element.market_ou_value == market_ou_value;

      rows.push(
        <MatchExpandDetails
          data={element}
          highlightModelHA={highlightModelHA}
          highlightModelOU={highlightModelOU}
          highlightMarketHA={highlightMarketHA}
          highlightMarketOU={highlightMarketOU}
          key={index}
        />
      );
    });

    return rows;
  };

  {
    /* Display positive/negative percent */
  }
  const DisplayPercent = ({ value }) => {
    if (!value) {
      return;
    }

    if (value > 0) {
      return (
        <Cell>
          <h2 className="procent green">+{value}%</h2>
        </Cell>
      );
    } else {
      return (
        <Cell>
          <h2 className="procent red">{value}%</h2>
        </Cell>
      );
    }
  };

  return (
    <Container>
      <Top onClick={(e) => _handleFocused(e)} ref={ref}>
        <Left bg={bg} focused={focused}>
          <FirstCell>
            <div className="first">
              {favorite ? (
                <FavoriteFilled onClick={() => handleFavorite(fixture)} />
              ) : (
                <FavoriteEmpty onClick={() => handleFavorite(fixture)} />
              )}

              <p>
                {format_time_to_timezone(
                  fixtureData.fixture.start_time,
                  user.timezone
                ).format("DD/MM/YYYY HH:mm")}
              </p>

              {fixtureData.fixture.participant1is_home ? (
                <h2>
                  {fixtureData.fixture.participant1.name} -{" "}
                  {fixtureData.fixture.participant2.name}
                  {/* {fixture.fixture_id} */}
                </h2>
              ) : (
                <h2>
                  {fixtureData.fixture.participant2.name} -{" "}
                  {fixtureData.fixture.participant1.name}
                  {/* {fixture.fixture_id} */}
                </h2>
              )}
            </div>
          </FirstCell>

          {/* HA */}
          <Item value={ha} className="fct" />
          {/* HD */}
          <Item value={hd} className="fct" />
          {/* AA */}
          <Item value={aa} className="fct" />
          {/* AD */}
          <Item value={ad} className="fct" />

          <div className="group ">
            {/* Model 1 */}
            <Item
              value={model_1}
              type="one"
              fixture={fixtureData.fixture.fixture_id}
              handleExpand={_handleExpand}
            />
            {/* Model X */}
            <Item
              value={model_x}
              type="even"
              fixture={fixtureData.fixture.fixture_id}
              handleExpand={_handleExpand}
            />
            {/* Model 2 */}
            <Item
              value={model_2}
              type="two"
              fixture={fixtureData.fixture.fixture_id}
              handleExpand={_handleExpand}
            />
          </div>
          <div className="group">
            {/* Model HA Min */}
            <Item
              value={model_ha_value}
              className={"distinctModelValue"}
              type="ha"
              fixture={fixtureData.fixture.fixture_id}
              handleExpand={_handleExpand}
            />
            {/* Model H */}
            <Item
              value={model_h}
              type="h"
              fixture={fixtureData.fixture.fixture_id}
              handleExpand={_handleExpand}
            />
            {/* Model A */}
            <Item
              value={model_a}
              type="a"
              fixture={fixtureData.fixture.fixture_id}
              handleExpand={_handleExpand}
            />
          </div>
          <div className="group">
            {/* Model OU Diff */}
            <Item
              value={model_ou_value}
              className={"distinctModelValue"}
              type="ou"
              fixture={fixtureData.fixture.fixture_id}
              handleExpand={_handleExpand}
            />
            {/* Model O */}
            <Item
              value={model_o}
              type="o"
              fixture={fixtureData.fixture.fixture_id}
              handleExpand={_handleExpand}
            />
            {/* Model U */}
            <Item
              value={model_u}
              type="u"
              fixture={fixtureData.fixture.fixture_id}
              handleExpand={_handleExpand}
            />
          </div>

          {/* Model Sup */}
          <Item
            value={model_sup}
            className="fct fct2"
            type="sup"
            fixture={fixtureData.fixture.fixture_id}
          />
          {/* Model TGx */}
          <Item
            value={model_tgx}
            className="fct fct2"
            type="tgx"
            fixture={fixtureData.fixture.fixture_id}
          />
        </Left>

        <Right bg={bg} focused={focused}>
          <div className="group ">
            {/* Market Sup */}
            <Item
              value={market_sup}
              className="fct fct2"
              type="sup"
              fixture={fixtureData.fixture.fixture_id}
            />
            {/* Market TGx */}
            <Item
              value={market_tgx}
              className="fct fct2"
              type="tgx"
              fixture={fixtureData.fixture.fixture_id}
            />
          </div>
          <div className="group ">
            {/* Market 1 */}
            <Item
              value={market_1}
              type="one"
              fixture={fixtureData.fixture.fixture_id}
              bold={shouldMakeValuesBold(model_1, market_1)}
              handleExpand={_handleExpand}
            />
            {/* Market X */}
            <Item
              value={market_x}
              type="even"
              fixture={fixtureData.fixture.fixture_id}
              bold={shouldMakeValuesBold(model_x, market_x)}
              handleExpand={_handleExpand}
            />
            {/* Market 2 */}
            <Item
              value={market_2}
              type="two"
              fixture={fixtureData.fixture.fixture_id}
              bold={shouldMakeValuesBold(model_2, market_2)}
              handleExpand={_handleExpand}
            />
          </div>

          <div className="group">
            {/* Market HA Diff */}
            <Item
              value={market_ha_value}
              type="ha"
              fixture={fixtureData.fixture.fixture_id}
              className={"distinctMarketValue"}
              handleExpand={_handleExpand}
            />
            {/* Market H */}
            <Item
              value={market_h}
              type="h"
              fixture={fixtureData.fixture.fixture_id}
              bold={shouldMakeValuesBold(model_h, market_h)}
              handleExpand={_handleExpand}
            />
            {/* Market A */}
            <Item
              value={market_a}
              type="a"
              fixture={fixtureData.fixture.fixture_id}
              bold={shouldMakeValuesBold(model_a, market_a)}
              handleExpand={_handleExpand}
            />
          </div>
          <div className="group">
            {/* Market OU Diff */}
            <Item
              value={market_ou_value}
              type="ou"
              fixture={fixtureData.fixture.fixture_id}
              className={"distinctMarketValue"}
              handleExpand={_handleExpand}
            />
            {/* Market O */}
            <Item
              value={market_o}
              type="o"
              fixture={fixtureData.fixture.fixture_id}
              bold={shouldMakeValuesBold(model_o, market_o)}
              handleExpand={_handleExpand}
            />
            {/* Market U */}
            <Item
              value={market_u}
              type="u"
              fixture={fixtureData.fixture.fixture_id}
              bold={shouldMakeValuesBold(model_u, market_u)}
              handleExpand={_handleExpand}
            />
          </div>
          <div className="group ">
            {/* Model vs Market 1 */}
            <DisplayPercent value={diff_1} />

            {/* Model vs Market X */}
            <DisplayPercent value={diff_x} />

            {/* Model vs Market 2 */}
            <DisplayPercent value={diff_2} />
          </div>
          <div className="group">
            {/* Model vs Market H */}

            <DisplayPercent value={diff_h} />
            {/* Model vs Market A */}
            <DisplayPercent value={diff_a} />
          </div>
          <div className="group">
            {/* Model vs Market O */}
            <DisplayPercent value={diff_o} />
            {/* Model vs Market U */}
            <DisplayPercent value={diff_u} />
          </div>
          <Cell>
            <button onClick={() => handleNote(fixtureData.fixture)}>
              <PencilNoteSvg />
            </button>
          </Cell>
        </Right>
      </Top>
      {expanded && (
        <Expand bg={bg}>
          <MakeRows />
        </Expand>
      )}
    </Container>
  );
};

export default Match;
const Container = styled.div`
  overflow: auto;
`;
const Expand = styled.ul`
  background: ${(props) =>
    props.bg % 2
      ? ({ theme }) => theme.matchElementBg
      : ({ theme }) => theme.matchElementBgindex};
`;

const Left = styled.ul`

  width: 100%;
  min-width: 800px;
  display: flex;
  align-items: center;
  list-style: none;
  color: ${({ theme }) => theme.leagueColor};
  background-color: ${(props) =>
    props.bg % 2
      ? ({ theme }) => theme.matchElementBgindexL
      : ({ theme }) => theme.matchElementBg};
  background-color: ${(props) =>
    props.focused ? ({ theme }) => theme.matchElementBgFocused : ""};

  padding: 6px 10px;
  border-right: 2px solid ${({ theme }) => theme.middleBorder};
  .nofav {
    color: ${({ theme }) => theme.favoriteStartStroke};
  }
  .group {
    width: 100%;
    display: flex;
    margin: 0 6px;
    justify-content: center;
    align-items: center;
  }
  img {
    height: 14px;
    width: 18px;
  }
  :last-child {
    border-right: 1px solid ${({ theme }) => theme.leagueBG2};
  }
`;

const Right = styled(Left)`
  background: ${(props) =>
    props.bg % 2
      ? ({ theme }) => theme.matchElementBgindexR
      : ({ theme }) => theme.matchElementBg};
  background: ${(props) =>
    props.focused ? ({ theme }) => theme.matchElementBgFocused : ""};
  :last-child {
    border-right: none;
  }
`;

const Top = styled.div`
  display: flex;
`;
const FirstCell = styled.li`
  display: flex;
  min-width: 250px;
  justify-content: space-between;
  .first {
    display: flex;
    column-gap: 10px;
    align-items: center;
    svg {
      color: ${({ theme }) => theme.favoriteStarFill};
    }
    h2 {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
    }
    p {
      font-weight: 700;
      font-size: 8.5px;
      line-height: 10px;
    }
  }
  .firstDn {
    button {
      color: ${({ theme }) => theme.buttonBG};
      background: none;
      border: none;
      cursor: pointer;
    }
  }
`;
const Cell = styled.li`
  min-width: 32px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .bold-price {
    font-weight: 900 !important;
    // color: ${({ theme }) => theme.matchFctText};
  }
  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
  }
  .procent {
    padding: 0 2px;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: -0.3px;
  }
  .bld {
    font-weight: 900;
  }
  .red {
    color: ${({ theme }) => theme.matchProcentR};
  }
  .green {
    color: ${({ theme }) => theme.matchProcentG};
  }
  .fct {
    color: ${({ theme }) => theme.matchHighlitedText};
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
  }
  .fct2 {
    color: ${({ theme }) => theme.matchFctText};
    font-size: 12px;
    font-weight: 800;
  }
  .distinctMarketValue {
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.matchDistinctValue};
  }
  .distinctModelValue {
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.matchDistinctValueModel};
  }
  button {
    margin-right: 8px;
    cursor: pointer;
    border: none;
    background: none;
    color: ${({ theme }) => theme.matchBtnsColor};
  }
`;
