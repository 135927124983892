import React, { useEffect, useState } from "react";
import styled from "styled-components";
import EditUserModal from "../../components/userProfileComponents/EditUserModal";
import InviteTeamMemberModal from "../../components/userProfileComponents/InviteTeamMemberModal";
import UserProfileTable from "../../components/userProfileComponents/UserProfileTable";
import TeamMermbersTable from "../../components/userProfileComponents/TeamMembersTable";
import { loginUser, logoutUser, selectUser } from "../../Redux/user/user";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../api/authentication/Logout";
import { editUser } from "../../api/user/EditUser";
import { notifyError, notifySucces } from "../../helper/Generic/Notifications";
import PermissionsGate from "../../helper/Generic/PermissionGate";
import { SCOPES } from "../../helper/Generic/Permissions";
import LocalStorage from "../../helper/Generic/LocalStorage";
import { getOtherUsers } from "../../api/user/GetOtherUsers";
import EditTeamMemberModal from "../../components/userProfileComponents/EditTeamMemberModal";
import { PulseLoader } from "react-spinners";

const UserProfile = () => {
  const user = useSelector(selectUser);
  const [showEditUser, setShowEditUser] = useState(false);
  const [showInviteMember, setShowInviteMember] = useState(false);
  const [inputUser, setInputUser] = useState(user.name);
  const [timeZone, setTimeZone] = useState(user.timezone_name);
  const [openLinksInNewTabs, setOpenLinksInNewTabs] = useState(user.open_links_in_new_tabs);

  const [usePlayerShortName, setUsePlayerShortName] = useState(user.use_short_player_names);
  const [usePlayerOtherName, setUsePlayerOtherName] = useState(user.use_other_player_names);
  const [displayPlayerName, setDisplayPlayerName] = useState()

  const [otherUsers, setOtherUsers] = useState([]);
  const [changes, setChanges] = useState(false);
  const [showEditTeamMember, setShowEditTeamMember] = useState(false);
  const [editMember, setEditMember] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputUserChange = (e) => {
    setInputUser(e.currentTarget.value);
  };
  const handleTimeZoneChange = (item) => {
    setTimeZone(item);
  };
  const checkChanges = () => {

    if (timeZone !== user.timezone_name ||
      inputUser !== user.name ||
      openLinksInNewTabs !== user.open_links_in_new_tabs ||
      usePlayerShortName !== user.use_short_player_names ||
      usePlayerOtherName !== user.use_other_player_names
    ) {
      setChanges(true);
    } else {
      setChanges(false);
    }
  };

  const handleEditUser = () => {
    // if (user.roles[0] !== "admin") {
    //   editNonAdminUser(
    //     timeZone,
    //     openLinksInNewTabs,
    //     function (response) {
    //       notifySucces("User edited");
    //       dispatch(loginUser(response.data.message));
    //       setShowEditUser(false);
    //       setChanges(false);
    //     },
    //     function (error) {
    //       notifyError("Something Wrong")
    //     }
    //   );
    // } else {
    editUser(
      inputUser,
      timeZone,
      openLinksInNewTabs,
      usePlayerShortName,
      usePlayerOtherName,
      function (response) {
        notifySucces("User edited");
        dispatch(loginUser(response.data.message));
        setShowEditUser(false);
        setChanges(false);
      },
      function (error) {
        notifyError("Something Wrong")
      }
    );
    // }
  };

  const handleOtherUsers = () => {
    if (user.roles[0] === "admin") {
      getOtherUsers(
        function (response) {
          setOtherUsers(response.data.users);
          setIsLoading(false)
        },
        function (error) {
          notifyError("Something wrong");
          setIsLoading(false)
        }
      );
    }

  };
  const handleLogout = () => {
    signOut(
      LocalStorage.getAuthenticationToken(),
      function () {
        LocalStorage.setAuthenthicationToken(null);
        LocalStorage.setRefreshToken(null);
        dispatch(logoutUser());
        navigate("/");
      },
      function (error) {
        notifyError("Something wrong")
      }
    );
  };

  const handleUseShortName = () => {
    setUsePlayerShortName(true);
    setUsePlayerOtherName(false)

  }

  const handleUseOtherName = () => {
    setUsePlayerOtherName(true);
    setUsePlayerShortName(false)

  }

  const handleUseFullName = () => {
    setUsePlayerShortName(false);
    setUsePlayerOtherName(false)
  }

  const mapDisplayNameValues = (short_name, other_name) => {
    let value = "fullName"
    if (!short_name && !other_name) {
      value = "fullName"
    }
    if (short_name && !other_name) {
      value = "shortName"
    }
    if (!short_name && other_name) {
      value = "otherName"
    }
    return value

  }

  const handleNameOptionsMenu = (value) => {
    if (value === "fullName") {
      handleUseFullName()
    }
    if (value === "shortName") {
      handleUseShortName()
    }
    if (value === "otherName") {
      handleUseOtherName()
    }
  }

  useEffect(() => {
    handleOtherUsers();
  }, []);

  useEffect(() => {
    checkChanges();
    setDisplayPlayerName(mapDisplayNameValues(usePlayerShortName, usePlayerOtherName))
  }, [inputUser, timeZone, openLinksInNewTabs, usePlayerShortName, usePlayerOtherName]);


  return (
    <Container>
      <Content>
        <Line>
          <h1>User Profile</h1>
        </Line>
        <Line>
          <FlexLine>
            <h2>User settings</h2>
          </FlexLine>
          <UserProfileTable
            displayPlayerName={displayPlayerName}
            setShowEditUser={() => setShowEditUser(true)} />
        </Line>

        <PermissionsGate scopes={[SCOPES.canEdit]}>
          <Line style={{ marginTop: "60px" }}>
            <FlexLine>
              <h2>Team Members</h2>
              <button
                className="inviteBtn"
                onClick={() => setShowInviteMember(true)}
              >
                Invite new member
              </button>
            </FlexLine>
            {isLoading ? (<div className="loader">
              <PulseLoader color="#006fff" />
            </div>) : (<TeamMermbersTable
              otherUsers={otherUsers}
              setOtherUsers={setOtherUsers}
              setShowEditTeamMember={setShowEditTeamMember}
              setEditMember={setEditMember}
            />)}

          </Line>
        </PermissionsGate>
        <Line>
          <div>
            <button className="logoutBtn" onClick={() => handleLogout()}>
              Log Out
            </button>
          </div>
        </Line>
      </Content>

      {showEditUser && (
        <EditUserModal
          handleInputUserChange={(e) => handleInputUserChange(e)}
          handleTimeZoneChange={(e) => handleTimeZoneChange(e)}
          inputUser={inputUser}
          timeZone={timeZone}
          setShowEditUser={() => setShowEditUser(false)}
          saveClick={(e) => handleEditUser(e)}
          changes={changes}
          setOpenLinksInNewTabs={setOpenLinksInNewTabs}
          openLinksInNewTabs={openLinksInNewTabs}
          usePlayerShortName={usePlayerShortName}
          setUsePlayerShortName={setUsePlayerShortName}
          usePlayerOtherName={usePlayerOtherName}
          setUsePlayerOtherName={setUsePlayerOtherName}
          displayPlayerName={displayPlayerName}
          handleNameOptionsMenu={handleNameOptionsMenu}
        />
      )}

      {showInviteMember && (
        <InviteTeamMemberModal
          setShowInviteMember={setShowInviteMember}
          setOtherUsers={setOtherUsers}
        />
      )}

      {showEditTeamMember && (
        <EditTeamMemberModal
          member={editMember}
          setMember={setEditMember}
          setShowEditTeamMember={setShowEditTeamMember}
          setOtherUsers={setOtherUsers}
        />
      )}
    </Container>
  );
};

export default UserProfile;
const Container = styled.div`
  display: flex;
  min-width: 1600px;
  width: 100%;
  min-height: var(--pageHeight);
  padding: 40px 140px;

`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  min-width: 400px;
  width: 100%;

  .logoutBtn {
    border: 1px solid rgba(255, 89, 79, 1);
    color: rgba(255, 89, 79, 1);
    background-color: rgba(252, 244, 243, 1);
    padding: 10px 28px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    border-radius: 4px;
    cursor: pointer;
    :hover {
      color: red;
      border: 1px solid red;
    }
  }
  .inviteBtn {
    background-color: var(--primaryBlue);
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 51px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
  }

  /* p {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  } */
`;
const Line = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  h1 {
    color: var(--darkBlue);
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
  }
`;
const FlexLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    color: var(--darkBlue);
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }
`;
