import cloneDeep from "lodash.clonedeep";
import React from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as GoToStatsIcon } from "../../../assets/icons/LeaguesIcons/goToTeamStatsIcon.svg";
const LeagueElementItem = ({ leagueTeamData, leagueData, bg, leagueTier, user, searchParams, archived }) => {

  const navigate = useNavigate();
  const stadiumData = leagueTeamData.stadium;
  const derbiesData = leagueTeamData.derbies;

  const mapDerbies = (type) => {
    let list = [];
    derbiesData.forEach((element) => {
      if (element.derby_type == type) {
        list.push(element);
      }
    });
    return list;
  };

  let low_derbies = mapDerbies("low");
  let mid_derbies = mapDerbies("mid");
  let high_derbies = mapDerbies("high");

  const mapSharedTeams = (stadium, team) => {

    if (!stadium) {
      return;
    }
    if (!stadium.teams) {
      return;
    }
    let cloneList = cloneDeep(stadium.teams);
    let teams = [];
    if (cloneList.length >= 1) {
      stadium.teams.forEach((item) => {
        if (item.league.season_id == leagueData.season_id) {
          teams.push(item)
        }
      })
    }
    teams = teams.filter(item => item.id != team.id);
    return teams;
  };

  const checkOppositeTeam = (derby) => {

    if (derby.team_1 != null && derby.team_2 != null) {
      if (derby.team_1.name === leagueTeamData.name) {
        return <p>{derby.team_2.name}</p>;
      }
      if (derby.team_2.name === leagueTeamData.name) {
        return <p>{derby.team_1.name}</p>;
      }
    }

  };
  const mapTrueFalse = (value) => {
    if (value === true) {
      return <p>Yes</p>;
    }
    if (value === false) {
      return <p>No</p>;
    }
  };

  const mapShareStadium = (stadium) => {

    let teams = [];

    if (stadium) {
      if (stadium.teams.length >= 1) {
        stadium.teams.forEach((item) => {
          if (item.league.season_id == leagueData.season_id) {
            teams.push(item)
          }
        })
      }
      let filtered = teams.filter((element) => element.id != leagueTeamData.id)
      if (filtered.length > 0) {
        return <p>Yes</p>
      } else if (stadium.other_sports || stadium.team_not_listed) {
        return <p>Yes</p>
      } else {
        return <p>No</p>
      }
    }
  };

  const handleClick = () => {
    if (archived) {
      return
    }
    let paramsObj = {
      teamId: leagueTeamData.id,
      selectedLeagueForTeam: leagueData.id,
      seasonIds: searchParams.getAll("seasonIds"),
      cupIds: searchParams.getAll("cupIds")
    }

    if (user.open_links_in_new_tabs) {
      let url = {
        pathname: "leagues/squad_manager",
        search: createSearchParams(paramsObj).toString()
      };
      window.open(`${url.pathname}?${url.search}`, "_blank");
    } else {
      navigate({
        pathname: "squad_manager",
        search: createSearchParams(paramsObj).toString()
      });
    }

  };


  const handleGoToTeamStats = () => {

    let paramsObj = {
      teamId: leagueTeamData.id,
      selectedSeasonId: leagueData.season_id,
      seasonIds: searchParams.getAll("seasonIds"),
      cupIds: searchParams.getAll("cupIds"),

    }

    if (user.open_links_in_new_tabs) {
      let url = {
        pathname: "leagues/players_data",
        search: createSearchParams(paramsObj).toString()
      };
      window.open(`${url.pathname}?${url.search}`, "_blank");
    } else {
      navigate({
        pathname: "players_data",
        search: createSearchParams(paramsObj).toString()
      });
    }

  }

  return (
    <Item bg={bg}>
      <FlagCell>
        <p>{leagueTier}</p>
      </FlagCell>

      <LeagueNameCell onClick={() => handleClick()} archived={archived}>
        <img src={leagueTeamData.logo_url}></img>
        <p className="leagueName">{leagueTeamData.name}</p>
      </LeagueNameCell>
      <Stadium>
        {stadiumData && <p className="stadiumName">{stadiumData.name}</p>}
      </Stadium>
      <Capacity>
        <p>{stadiumData?.capacity.toLocaleString()}</p>
      </Capacity>
      <Surface>
        <p>{stadiumData?.surface}</p>
      </Surface>
      <RaceTrack>{mapTrueFalse(stadiumData?.race_track)}</RaceTrack>
      <PitchSize>
        {stadiumData && (
          <p>
            {stadiumData.width}x{stadiumData.length}
          </p>
        )}
      </PitchSize>
      <ShareStadium>{mapShareStadium(stadiumData)}</ShareStadium>
      <SharingTeam>
        {mapSharedTeams(stadiumData, leagueTeamData)?.map((item) => <p key={item.id}>{item.name}</p>)}
      </SharingTeam>
      <Location>
        <p>{stadiumData?.location}</p>
      </Location>
      <Latitude>
        <p>{stadiumData?.lat}</p>
      </Latitude>
      <Longitude>
        <p>{stadiumData?.long}</p>
      </Longitude>
      <Altitude>
        <p>{stadiumData?.altitude}</p>
      </Altitude>
      <DerbyLow>
        {low_derbies.map((item, index) => (
          <div
            style={{ width: "100%" }}
            key={index}
          >
            {checkOppositeTeam(item)}
          </div>
        ))}
      </DerbyLow>
      <DerbyMed>
        {mid_derbies.map((item, index) => (
          <div
            style={{ width: "100%" }}
            key={index}
          >
            {checkOppositeTeam(item)}
          </div>
        ))}
      </DerbyMed>
      <DerbyHigh>
        {high_derbies.map((item, index) => (
          <div
            style={{ width: "100%" }}
            key={index}
          >
            {checkOppositeTeam(item)}
          </div>
        ))}
      </DerbyHigh>
      <Edit>
        {!archived &&
          <TeamStatsBtn onClick={() => handleGoToTeamStats()}>
            <GoToStatsIcon />
          </TeamStatsBtn>
        }

      </Edit>
    </Item>
  );
};

export default LeagueElementItem;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding:5px 9px;
  column-gap: 10px;
  background: ${(props) =>
    props.bg % 2
      ? ({ theme }) => theme.matchElementBgindexL
      : ({ theme }) => theme.matchElementBg};
  min-height: 40px;
  max-height:40px;
  height:100%;

`;
const Cell = styled.div`
width:100%;
height:100%;
align-items:center;
display:flex;
p{
font-family: 'Roboto';
font-style: normal;
font-weight:400;
font-size: 11px;
color: ${({ theme }) => theme.leagueColor};
}
.leagueTitle{
    letter-spacing: 0.01em;
text-decoration-line: underline;
}
`;
const FlagCell = styled.div`
  min-width: 20px;
  min-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.7px solid #cbd7f5;
  border-radius: 3px;
  p {
    font-weight: 400;
    font-size: 9px;
    line-height: 11px;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${({ theme }) => theme.leagueColor};
  }
`;
const LeagueNameCell = styled(Cell)`
cursor:${(props) => props.archived ? "inherit" : "pointer"};
  min-width: 120px;
  display:flex;
  column-gap:5px;
  img{
    height:21px;
    width:21px;
  }
  .leagueName {
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.01em;
    
     text-decoration-line:${(props) => props.archived ? "inherit" : "underline"} ;
    color: ${({ theme }) => theme.betHeaderTextColor};
  }
`;
const Stadium = styled(Cell)`
  min-width: 140px;
  .stadiumName {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
  }
`;
const Capacity = styled(Cell)`
  min-width: 50px;
`;
const Surface = styled(Cell)`
  min-width: 60px;
`;
const RaceTrack = styled(Cell)`
  min-width: 30px;
`;
const PitchSize = styled(Cell)`
  min-width: 45px;
`;
const ShareStadium = styled(Cell)`
  min-width: 35px;
`;
const SharingTeam = styled(Cell)`

  min-width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-betwen;
  row-gap: 3px;
  p {
    display: flex;
    align-items: center;
    width: 100%;
  }
`;
const Location = styled(Cell)`
  min-width: 50px;
`;
const Latitude = styled(Cell)`
  min-width: 50px;
`;
const Longitude = styled(Cell)`
  min-width: 55px;
`;
const Altitude = styled(Cell)`
display:flex;
  min-width: 50px;
  min-height:40px;
  border-right: 2px solid var(--leagueElementBg);
align-items:center;
`;
const DerbyLow = styled(Cell)`
  min-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-betwen;
  height: 100%;


  row-gap: 3px;
  
  p {
    display: flex;
    align-items: center;
    width: 100%;

  }
`;
const DerbyMed = styled(Cell)`
  min-width: 70px;
  h2 {
    width: 30px;
  }
`;
const DerbyHigh = styled(Cell)`
  min-width: 60px;
  h2 {
    width: 30px;
  }
`;
const Edit = styled(Cell)`
  min-width: 50px;
justify-content:end;
`;

const TeamStatsBtn = styled.button`
display:flex;
border:none;
background:none;
cursor:pointer;
padding:4px 3px;
`