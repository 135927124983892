import { POST } from "../../APiHelper/API";
let source;
export const getCupsForTeamAPI = (
    team_id,
    onSuccess,
    onError
) => {
    const data = {
        team_id: team_id
    }
    source = POST("/api/v1/seasons_and_cups/cups_for_team", data, onSuccess, onError, true, source)
}