/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PlayerElement from "../TeamPlayersComponent/PlayerElement";
import PlayersHeader from "../TeamPlayersComponent/PlayersHeader";
import { useLeaguesDataService } from "../../../context/useLeaguesDataService";
import {
  notifyError,
  notifyInfoNoClose,
  notifyLoading,
  notifySucces,
  notifyUpdate,
} from "../../../helper/Generic/Notifications";
import AddNewPlayerSection from "../Menu/AddNewPlayerSection";
import { toast } from "react-toastify";
import cloneDeep from "lodash.clonedeep";
import { PulseLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { selectUser } from "../../../Redux/user/user";
import EditSaveAllConfirmation from "../../EditSaveAllConfirmation";
import ButtonWithConfirmation from "../../ButtonWithConfirmation";
import TransferredPlayersHeader from "../TeamPlayersComponent/TransferredPlayersHeader";
import TransferredPlayerElement from "../TeamPlayersComponent/TransferredPlayerElement";
import DeletedPlayerHeader from "../TeamPlayersComponent/DeletedPlayerheader";
import DeletedPlayerElement from "../TeamPlayersComponent/DeletedPlayerElement";
import EditLeagueSubmenuOption from "./EditLeagueSubmenuOption";
import { CURRENT_PLAYERS, DELETED_PLAYERS, TRANSFERRED_PLAYERS, teamPlayersSubmenuList } from "../../../helper/Values/EditLeagueSubmenuValues";
import DeleteConfirmationDouble from "../../DeleteConfirmationDouble";

const TeamsPlayersOption = ({
  refreshAfterDeleteLeague,
  leagueToEdit,
  setLoading,
  setRefreshListOnClose,
  closeWindow,
  handleSelectTeamToEdit,
  refreshOnClose,
  teamID,
  needToRefreshList = false,
  setUnsavedCounter = undefined,

}) => {
  const {
    get_players_for_team,
    delete_player,
    update_player,
    delete_league,
    repopulate_team_with_players,
    add_new_players,
    repopulate_short_names,
    transfer_player,
    get_deleted_players_for_team,
    get_transferred_players_for_team,
    undo_transfer_player,
    undo_delete_player,
    repopulate_other_names
  } =
    useLeaguesDataService();


  const [playersSubmenu, setPlayersSubmenu] = useState(CURRENT_PLAYERS)
  const [initialPlayers, setInitialPlayers] = useState([]);
  const [players, setPlayers] = useState([]);
  const [deletedPlayers, setDeletedPlayers] = useState([])
  const [transferredPlayers, setTransferredPlayers] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [saveAllReady, setSaveAllReady] = useState(false);
  const toastId = useRef();
  const notifyNoCloseId = useRef();
  const [changesCounter, setChangesCounter] = useState(0);
  const [isUserAdmin, setIsUserAdmin] = useState();
  const [saveAllConfirmationDialog, setSaveAllConfirmationDialog] =
    useState(false);

  const handlePlayerResponse = (newPlayer) => {

    setPlayers((prevData) => {
      const newData = prevData.map((player) => {
        if (player.id === newPlayer.id) {
          return (newPlayer);
        } else {
          return player;
        }
      })
      return newData
    })

    setInitialPlayers((prevData) => {
      const newData = prevData.map((player) => {
        if (player.id === newPlayer.id) {
          return (newPlayer);
        } else {
          return player;
        }
      })
      return newData
    })


  };

  let user = useSelector(selectUser);

  const checkIfUserIsAdmin = () => {
    if (user?.roles.includes("admin")) {
      setIsUserAdmin(true);
    } else {
      setIsUserAdmin(false);
    }

  }

  const handleEditPlayer = (
    player_id,
    wy_id,
    first_name,
    last_name,
    short_name,
    other_player_name,
    position_1,
    position_2,
    position_3,
    squad_number,
    onSuccess
  ) => {
    let data = {
      first_name: first_name,
      last_name: last_name,
      short_name: short_name,
      other_player_name: other_player_name,
      position_1: position_1,
      position_2: position_2,
      position_3: position_3,
      squad_number: squad_number,
    };
    let admin = user.roles.includes("admin");
    if (admin) {
      data.wy_id = wy_id;
    }


    update_player(
      player_id,
      data,
      function (response) {
        onSuccess();
        handlePlayerResponse(response.data.player);
        if (needToRefreshList) {
          refreshOnClose.current = true;
        }


        notifySucces("Player updated");
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };

  const handleDeleteLeague = (league_id) => {
    setLoading(true);
    delete_league(
      league_id,
      function () {
        setLoading(false);
        notifySucces("League deleted");
        setRefreshListOnClose(true);
        refreshAfterDeleteLeague();
      },
      function (error) {
        setLoading(false);
        notifyError("Something wrong");
      }
    );
  };

  const handleRemovePlayer = (player_id) => {
    notifyLoading(toastId, "Deleting player");
    delete_player(
      player_id,
      function () {
        notifyUpdate(toastId, "Player deleted", toast.TYPE.SUCCESS);
        if (needToRefreshList) {
          refreshOnClose.current = true;
        }

        setPlayers((prevData) => {
          let newPlayers = prevData.filter((player) => player.id != player_id);
          return newPlayers;
        })

        // handleGetPlayers();
        handleGetDeletedPlayers();
      },
      function (error) {
        notifyUpdate(toastId, "Something wrong...", toast.TYPE.ERROR);
      }
    );
  };



  const handleGetPlayers = () => {
    setIsLoading(true);
    get_players_for_team(
      teamID.id,
      function (response) {
        const players = response.data.players
        const filtered = players.filter((player) => player.transferred != true);
        const sortedPlayers = sortPlayersAlphabetical(filtered);
        setPlayers(sortedPlayers);
        setInitialPlayers(sortedPlayers);
        setIsLoading(false);
      },
      function (error) {
        notifyError("Something Wrong...");
        setIsLoading(false);
      }
    );
  };

  const handleGetTransferredPlayers = () => {
    setIsLoading(true);
    get_transferred_players_for_team(
      teamID.id,
      function (response) {
        const sortedPlayers = sortPlayersAlphabetical(response.data.players);
        setTransferredPlayers(sortedPlayers);
        setIsLoading(false);
      },
      function (error) {
        notifyError("Something Wrong...");
        setIsLoading(false);
      }
    );
  };

  const handleGetDeletedPlayers = () => {
    setIsLoading(true);
    get_deleted_players_for_team(
      teamID.id,
      function (response) {
        const sortedPlayers = sortPlayersAlphabetical(response.data.players);
        setDeletedPlayers(sortedPlayers);
        setIsLoading(false);
      },
      function (error) {
        notifyError("Something Wrong...");
        setIsLoading(false);
      }
    );
  };

  const sortPlayersAlphabetical = (arrayToCompare) => {
    if (!arrayToCompare) {
      return;
    }

    let comparteThis = cloneDeep(arrayToCompare);
    let sorted = comparteThis.sort((a, b) =>
      a.first_name.toLowerCase() > b.first_name.toLowerCase() ? 1 : -1
    );
    return sorted;
  };

  const handleFieldChange = (id, field, value) => {
    setPlayers((prevData) => {
      const newData = prevData?.map((item) => {
        if (item.id === id) {
          return { ...item, [field]: value };
        }
        return item;
      });

      return newData;
    });
  };

  const filterInitial = (player) => {
    let initialData = initialPlayers.find((item) => {
      return item.id === player.id;
    });
    return initialData;
  };

  const checkSaveAll = () => {
    let counter = 0;
    players?.forEach((player) => {
      if (!player.details_changed) {
        return;
      }
      if (player.details_changed == true) {
        counter = counter + 1;
      }
    });


    setUnsavedCounter(counter);


    if (counter > 1) {
      setSaveAllReady(true);
    } else {
      setSaveAllReady(false);
    }
  };

  const handleSaveAllChanges = () => {
    var players_that_need_update = players.filter(
      (p) => p.details_changed
    ).length;

    var players_that_need_update_wyID = players.filter(
      (p) => p.wyId_details_changed
    ).length;

    if (players_that_need_update_wyID > 0) {
      setSaveAllConfirmationDialog(true);
    } else {
      saveAllChanges();
    }
  };

  const saveAllChanges = () => {

    var players_that_need_update = players.filter(
      (p) => p.details_changed
    ).length;

    var players_that_need_update2 = players.filter(
      (p) => p.wyId_details_changed
    ).length;

    let changes = players_that_need_update;
    if (players_that_need_update2 > players_that_need_update) {
      changes = players_that_need_update2;
    }
    players.forEach((player) => {
      if (player.details_changed || player.wyId_details_changed) {
        handleEditPlayer(
          player.id,
          player.wy_id,
          player.first_name,
          player.last_name,
          player.short_name,
          player.other_player_name,
          player.position_1,
          player.position_2,
          player.position_3,
          player.squad_number,
          () => {
            changes -= 1;
            if (changes <= 0) {
              if (needToRefreshList) {
                refreshOnClose.current = true;
              }
              // handleGetPlayers();
            }
          }
        );
      }
    });
  };

  const handleRepopulateTeam = () => {
    setIsLoading(true)
    repopulate_team_with_players(
      teamID.id,
      function (response) {

        if (needToRefreshList) {
          refreshOnClose.current = true;
        }
        handleGetPlayers();
        handleGetDeletedPlayers();
        notifySucces("Team Repopulated")
      },
      function (error) {
        setIsLoading(false)
        notifyError("Something Wrong")
      },
    )

  };

  const handleAddNewPlayers = () => {
    setIsLoading(true);
    add_new_players(
      teamID.id,
      function (response) {
        if (needToRefreshList) {
          refreshOnClose.current = true;
        }
        handleGetPlayers();
        notifySucces("Added new players")
      },
      function (error) {
        setIsLoading(false)
        notifyError("Something Wrong")
      },
    )
  }

  const handleRepopulateShortnames = () => {
    setIsLoading(true);
    setIsLoading(true);
    repopulate_short_names(
      teamID.id,
      function (response) {
        if (needToRefreshList) {
          refreshOnClose.current = true;
        }
        setPlayers([]);
        setInitialPlayers([]);
        handleGetPlayers();
        notifySucces("Short name overwrited")
      },
      function (error) {
        setIsLoading(false)
        notifyError("Something Wrong")
      },
    )
  }

  const handleRepopulateOthernames = () => {
    setIsLoading(true);
    setIsLoading(true);
    repopulate_other_names(
      teamID.id,
      function (response) {
        if (needToRefreshList) {
          refreshOnClose.current = true;
        }
        setPlayers([]);
        setInitialPlayers([]);
        handleGetPlayers();
        notifySucces("Other name overwrited")
      },
      function (error) {
        setIsLoading(false)
        notifyError("Something Wrong")
      },
    )
  }

  const handleTransferPlayer = (player_id) => {
    notifyLoading(toastId, "Transfering player");
    transfer_player(
      player_id,
      function () {
        notifyUpdate(toastId, "Player transfered", toast.TYPE.SUCCESS);
        if (needToRefreshList) {
          refreshOnClose.current = true;
        }

        setPlayers((prevData) => {
          let newPlayers = prevData.filter((player) => player.id != player_id);
          return newPlayers;
        })

        // handleGetPlayers();
        handleGetTransferredPlayers();
      },
      function (error) {
        notifyUpdate(toastId, "Something wrong...", toast.TYPE.ERROR);
      }
    );
  };

  const handleUndoTransferPlayer = (player_id) => {
    notifyLoading(toastId, "Transfering player");
    undo_transfer_player(
      player_id,
      function () {
        notifyUpdate(toastId, "Player transfered", toast.TYPE.SUCCESS);
        if (needToRefreshList) {
          refreshOnClose.current = true;
        }
        handleGetPlayers();
        handleGetTransferredPlayers();
      },
      function (error) {
        notifyUpdate(toastId, "Something wrong...", toast.TYPE.ERROR);
      }
    );
  }

  const handleUndoDeletePLayer = (player_id) => {
    undo_delete_player(
      player_id,
      function (response) {
        if (needToRefreshList) {
          refreshOnClose.current = true;
        }

        notifySucces("Reverted")
        handleGetPlayers();
        handleGetDeletedPlayers();
      },
      function (error) {
        notifyError("Something wrong")
      }
    );
  }


  const checkDuplicates = () => {
    let mapped = players?.map((player) => {
      if (player.wy_id) {
        return player.wy_id;
      } else {
        return null
      }
    });

    let findDuplicates = mapped?.filter(
      (item, index) => {
        if (item) {
          return mapped.indexOf(item) != index
        }
      }
    );

    if (findDuplicates?.length > 0) {
      notifyInfoNoClose(
        notifyNoCloseId,
        "The team contains now duplicate players, please check the current players list!"
      );
    }
  };


  const displayPlayersContent = () => {
    var playersContent;
    switch (playersSubmenu) {
      case CURRENT_PLAYERS:
        playersContent = (
          <>
            <AddNewPlayerSection
              leagueToEdit={leagueToEdit}
              teamID={teamID}
              handleGetPlayers={handleGetPlayers}
              setIsLoading={setIsLoading}
              refreshOnClose={refreshOnClose}
              needToRefreshList={needToRefreshList}
              setPlayers={setPlayers}
              players={players}
              initialPlayers={initialPlayers}
              setInitialPlayers={setInitialPlayers}
              sortPlayersAlphabetical={sortPlayersAlphabetical}
              toastId={toastId}

            />
            <PlayersHeader />
            {players?.map((item, index) => (
              <PlayerElement
                key={item.id}
                data={item}
                initialData={filterInitial(item)}
                handleEditPlayer={handleEditPlayer}
                handleRemovePlayer={handleRemovePlayer}
                handleFieldChange={handleFieldChange}
                handleTransferPlayer={handleTransferPlayer}
              />
            ))}
          </>
        )
        break;

      case TRANSFERRED_PLAYERS:
        playersContent = (<>
          <TransferredPlayersHeader />
          {transferredPlayers?.map((item, index) => (
            <TransferredPlayerElement
              player={item}
              key={index}
              handleUndoTransferPlayer={handleUndoTransferPlayer}
            />
          ))}

        </>)
        break;
      case DELETED_PLAYERS:
        playersContent = (<>
          <DeletedPlayerHeader />
          {deletedPlayers?.map((player, index) => (
            <DeletedPlayerElement
              key={index}
              player={player}
              handleUndoDeletePLayer={handleUndoDeletePLayer}
            />
          ))}
        </>)
        break;

      default:
        break;
    }
    return playersContent
  }




  useEffect(() => {
    handleGetPlayers();
    handleGetTransferredPlayers()
    handleGetDeletedPlayers();

  }, [teamID]);

  useEffect(() => {
    checkSaveAll();
    checkDuplicates();
  }, [players, initialPlayers]);

  useEffect(() => {
    checkIfUserIsAdmin();
  }, []);

  return (
    <Card>
      {isLoading && (
        <SpinnerWrapper>
          <PulseLoader
            color={"#006fff"}
            loading={isLoading}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </SpinnerWrapper>
      )}


      <CardContent>
        {location.pathname == "/leagues" && leagueToEdit ? (<TeamsList>
          {leagueToEdit?.teams?.map((team, index) => (
            <TeamListItem
              key={index}
              active={team.id == teamID.id}
              onClick={() => handleSelectTeamToEdit(team)}
            >
              <h4>{team.name}</h4>
            </TeamListItem>
          ))}
        </TeamsList>) : (<>
        </>)}


        <Players>
          <SubmenuWrapper>
            <EditLeagueSubmenuOption
              valuesList={teamPlayersSubmenuList}
              value={playersSubmenu}
              setValue={setPlayersSubmenu}
            />
          </SubmenuWrapper>

          {displayPlayersContent()}

        </Players>

      </CardContent>

      <Bottom>

        {location.pathname == "/leagues" && leagueToEdit ? (
          <BottomLeft>
            <DeleteConfirmationDouble
              handleRemoveItem={handleDeleteLeague}
              item={leagueToEdit.id}
              variant={"league"}
            />
          </BottomLeft>
        ) : (<></>)}

        {playersSubmenu === CURRENT_PLAYERS && (
          <>
            <BottomGroup>
              <Line>
                <ButtonWithConfirmation action={handleAddNewPlayers} variant={"new-players"}> </ButtonWithConfirmation>
                <ButtonWithConfirmation action={handleRepopulateShortnames} variant={"short-names"}></ButtonWithConfirmation>
                <ButtonWithConfirmation action={handleRepopulateOthernames} variant={"other-names"}></ButtonWithConfirmation>
              </Line>
              <ButtonWithConfirmation action={handleRepopulateTeam} variant={"pre-season-overwite"} disableButton={!isUserAdmin}></ButtonWithConfirmation>
            </BottomGroup>
            {saveAllReady && (
              <BottomRight>

                <>
                  <EditSaveAllConfirmation
                    handleEdit={saveAllChanges}
                    saveAllConfirmationDialog={saveAllConfirmationDialog}
                    setSaveAllConfirmationDialog={setSaveAllConfirmationDialog}
                  ></EditSaveAllConfirmation>

                  <SaveAllButton onClick={() => handleSaveAllChanges()}>
                    Save All
                  </SaveAllButton>
                </>

              </BottomRight>
            )}
          </>
        )}

      </Bottom>

    </Card>
  );
};

export default TeamsPlayersOption;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  height:100%;
  width: 100%;
`;

const CardContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
`;

const TeamsList = styled.ul`
  min-width: 240px;
  height: 100%;
  overflow: auto;
  padding: 25px 20px 0 20px;
  border-right: 3px solid ${({ theme }) => theme.editLeague.borderColor};
  list-style: none;
`;

const TeamListItem = styled.li`
  cursor: pointer;
  width: 100%;
  padding: 5px 8px;
  background: ${(props) => (props.active == true ? ({ theme }) => theme.editLeague.teamListItemSelected : ({ theme }) => theme.editLeague.bg)};
  border-radius: 6px;
  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    letter-spacing: 0.01em;
     color: ${({ theme }) => theme.editLeague.menuTextColor}
  }
`;

const Players = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  column-gap: 30px;
  row-gap: 10px;
  padding: 30px;
`;

const Bottom = styled.div`
   background: ${({ theme }) => theme.editLeague.headerBg};
  padding: 10px 40px;
  border-radius: 0 0 16px 16px;
  display: flex;
  justify-content: space-between;
  column-gap:10px;
  height: 60px;
  width:100%;
`;
const SubmenuWrapper = styled.div`
border-bottom: 3px solid #f8f9fb;
max-width: calc(100% - 130px);
padding-bottom:15px;
`
const SaveAllRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  padding-right: 32px;
`;
const SaveAllButton = styled.button`
  background: ${({ theme }) => theme.editLeague.saveAllBtnBg};
  border: 1.5px solid #006fff;
  border-radius: 4px;
  padding: 6px 25px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.editLeague.saveAllBtnColor};
  cursor: pointer;
  min-width:120px;

`;
const SpinnerWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;
const BottomGroup = styled.div`
  display: flex;
  justify-content:space-between;
  width:100%;
  column-gap: 15px;
  align-items: center;

 
`;
const BottomLeft = styled.div`
display:flex;
min-width:220px;
`
const BottomRight = styled.div`
display:flex;
min-width:120px;
`
const Line = styled.div`
display:flex;
gap:20px;
align-items:center;
`