import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as XIcon } from "../../assets/icons/xBtn.svg";
import { checkFlag } from '../../helper/Generic/GetFlag';
import { useSelector } from 'react-redux';
import { format_time_to_timezone } from '../../helper/Generic/dateTimeFormat';
import { selectUser } from '../../Redux/user/user';
import DateMenuSingle from './Menu/DateMenuSingle';
import SelectFixtureMenu from './Menu/SelectFixtureMenu';
import moment from 'moment';

const TransferDataModal = ({
    showModal,
    leagueData,
    fixtureData,
    homeTeam,
    awayTeam,
    formation,
    matchTransferOptionsList,
    handleTransferDataButton,
    getFixturesAvailableForDataTransfer,
    transferDataToThisMatch,
    setTransferDataToThisMatch,
    handleTransferMoldaClose,
    isLoading
}) => {

    const ref = useRef(null);
    const closeWindow = () => {
        handleTransferMoldaClose();
    }

    const user = useSelector(selectUser);

    const fixtureStartTime = format_time_to_timezone(
        fixtureData?.fixture?.start_time,
        user.timezone
    );

    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleSelectDate = (date) => {
        let startOfDay = moment(date).startOf("day");
        let newDate = new Date(startOfDay)
        setSelectedDate(newDate);
    };

    // useEffect(() => {

    //     const checkIfClickedOutside = (e) => {
    //         if (showModal && ref.current && !ref.current.contains(e.target)) {
    //             closeWindow();
    //         }
    //     };
    //     document.addEventListener("mousedown", checkIfClickedOutside);
    //     return () => {
    //         // Cleanup the event listener
    //         document.removeEventListener("mousedown", checkIfClickedOutside);
    //     };

    // }, [showModal]);

    useEffect(() => {
        getFixturesAvailableForDataTransfer(fixtureData.fixture.fixture_id, selectedDate);
    }, [selectedDate])

    return (
        <Container >
            <CardContainer >
                <Card ref={ref}>
                    <Top>
                        <h4>Transfer Data Between Matches</h4>
                        <button
                            onClick={() => {
                                closeWindow();
                            }}
                        >
                            <XIcon />
                        </button>

                    </Top>
                    <Subtitle style={{ borderBottom: "1px solid #3131310D" }}>
                        <h5>FROM</h5>
                    </Subtitle>

                    <League>
                        <img
                            src={checkFlag(leagueData?.country?.country_code)}
                            alt=""
                        />
                        <h1>{leagueData?.name}</h1>

                    </League>
                    <MatchDetails>
                        <Teams>
                            <Team>
                                <img src={homeTeam?.logo_url} alt="logo" />
                                <h1>{homeTeam?.name}</h1>
                            </Team>
                            <Team>
                                <img src={awayTeam?.logo_url} alt="logo" />
                                <h2>{awayTeam?.name}</h2>
                            </Team>
                        </Teams>
                        <Formation>
                            <p>
                                {formation}
                            </p>
                        </Formation>

                    </MatchDetails>

                    <MatchDate>
                        <p>{fixtureStartTime.format("DD/MM/YYYY - HH:mm ")}</p>
                    </MatchDate>


                    <Bottom>
                        <Subtitle>
                            <h5>TO</h5>
                        </Subtitle>

                        <BottomOptions>
                            <BottomGroup>
                                <h3>EARLIEST DATE</h3>
                                <DateMenuSingle value={selectedDate} setValue={handleSelectDate} />
                            </BottomGroup>
                            <BottomGroup>
                                <h3>MATCH</h3>
                                <SelectFixtureMenu
                                    dataList={matchTransferOptionsList}
                                    selectedValue={transferDataToThisMatch}
                                    setSelectedValue={setTransferDataToThisMatch}
                                    label={"Select available match"}
                                />
                            </BottomGroup>
                            <BottomGroup>
                                <TransferButton onClick={() => handleTransferDataButton()}>
                                    Transfer
                                </TransferButton>
                            </BottomGroup>


                        </BottomOptions>


                    </Bottom>

                </Card>
            </CardContainer>
        </Container>
    )
}

export default TransferDataModal
const Container = styled.div`
display:block;
`;

const CardContainer = styled.div`
position: fixed;
  z-index: 4;
left:0;
bottom:0;
width:100%;
height:100%;
min-width:1600px;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items:center;
  overflow: none;
`
const Card = styled.div`
position: sticky;
margin-left:auto;
margin-right:auto;
display: flex;
flex-direction: column;
row-gap:15px;

background: ${({ theme }) => theme.editLeague.bg};
box-shadow: 0px 1px 8px rgba(46, 46, 46, 0.04);
border-radius: 16px;
@media(max-height:1024px){
  top:0;
  max-height:90vh;
}

`;

const Top = styled.div`
display:flex;
justify-content:space-between;
padding:30px 50px 0 50px;
align-items:center;
h4{
    font-family: Roboto;
font-size: 26px;
font-weight: 700;
line-height: 30px;
letter-spacing: -0.01em;
text-align: left;  
color: ${({ theme }) => theme.squadManager.textColor};
opacity:0.8;
}
button {
    transition: none !important;
    background: none;
    border: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.editLeague.menuTextColor};
    padding-bottom: 4px;
    cursor: pointer;
  }
`;
const Subtitle = styled.div`

padding:0 50px 15px 50px;
h5{
    font-family: Roboto;
font-size: 10px;
font-weight: 600;
line-height: 12px;
color: ${({ theme }) => theme.squadManager.textColor};
}
`
const League = styled.div`
 display: flex;
  align-items: center;
  column-gap: 10px;
  padding-bottom:15px;
  border-bottom: 1px solid #3131310D;
padding:0 50px 15px 50px;
  h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: ${({ theme }) => theme.editLeague.headerColor};
  }
  img {
    border-radius: 4px;
    height: 18px;
  }

`
const MatchDetails = styled.div`
display:flex;
gap:20px;
align-items:top;
padding:0 50px;
`
const Teams = styled.div`


`
const Team = styled.div`
display:flex;
gap:10px;
align-items:center;
h1{
font-family: Roboto;
font-size: 14px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
color: ${({ theme }) => theme.squadManager.textColor};
}
h2{
font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
color: ${({ theme }) => theme.squadManager.textColor};
}
img{
    max-height:11px;
    max-width:11px;
}

`
const Formation = styled.div`
p{
    font-family: Roboto;
font-size: 14px;
font-weight: 700;
line-height:22px;
letter-spacing: -0.02em;
color: ${({ theme }) => theme.squadManager.textColor};
}
`
const MatchDate = styled.div`
display:flex;
background:${({ theme }) => theme.matchPreview.matchFactorsReadOnlyInputs};
padding:12px 14px;
margin-left:50px;
border-radius:6px;
width:fit-content;
p{
    color:${({ theme }) => theme.squadManager.textColor};
    font-family: Roboto;
font-size: 14px;
font-weight: 700;
line-height: 16px;
}

`
const Bottom = styled.div`
padding:30px 0;
background:${({ theme }) => theme.editLeague.headerBg};
border-radius: 16px;
`
const BottomOptions = styled.div`
display:flex;
gap:20px;
padding:0 50px;
`
const BottomGroup = styled.div`
display:flex;
flex-direction:column;
gap:10px;
height:100%;

h3{
font-family: Roboto;
font-size: 12px;
font-weight: 600;
line-height: 14px;
color: ${({ theme }) => theme.squadManager.textColor};
}
`
const TransferButton = styled.button`
margin-top:24px;
cursor:pointer;
display:flex;
align-items:center;
justify-content:center;
padding:12px 30px;
font-family: Roboto;
font-size: 14px;
font-weight: 600;
line-height: 16px;
text-align: center;
color:white;
background:${({ theme }) => theme.buttonBG};
border:none;
border-radius:6px;

`