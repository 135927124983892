import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as XIcon } from "../../../assets/icons/xBtn.svg";
import { ReactComponent as SearchSvg } from "../../../assets/icons/search.svg";
import { getCompetitionsForTerm } from "../../../api/competitions/GetCompetitionsForTerm";
import { getCountries } from "../../../api/user/GetCountriesListAdmin";
import CountryMenuSingleOption from "../Menu/CountryMenuSingleOption";
import { getCompetitionsForCountryId } from "../../../api/competitions/GetCompetitionForCountryId";
import SelectionList from "../../userProfileComponents/SelectionList";
import LeaguesList from "../../userProfileComponents/LeaguesList";
import {
  notifyError,
  notifyLoading,
  notifyUpdate,
} from "../../../helper/Generic/Notifications";
import { toast } from "react-toastify";
import { createNewSeason } from "../../../api/analyst-leagues/season/createNewSeason";
import LoaderSpinner from "../../../helper/LoaderSpinner";
import CustomDateMenu from "../Menu/CustomDateMenu";

const AddNewSeason = ({
  setIsAddNewSeasonOpen,
  setSearchParams,
  setSelectedSeasonsAndCups,
  setSeasonsAndCupsList
}) => {
  const initialInputValues = {
    seasonName: "",
    inputSearch: "",
  };
  const toastId = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputvalues] = useState(initialInputValues);
  const [competitions, setCompetitions] = useState([]);
  const [selectedCompetitions, setSelectedCompetitions] = useState([]);

  const [countriesList, setCountriesList] = useState([]);
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState([]);
  const [ready, setReady] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [allCompetitionsLength, setAllCompetitionsLength] = useState();

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const handleCountryList = () => {
    getCountries(
      function (response) {
        setCountriesList(response.data.countries);
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };

  const handleSearchCompetition = () => {
    setIsLoading(true);
    setCountry([]);
    setCountryId([]);
    if (inputValues.inputSearch !== "") {
      getCompetitionsForTerm(
        inputValues.inputSearch,
        function (response) {

          setCompetitions(response.data.competitions);
          setIsLoading(false);
        },
        function (error) {
          setIsLoading(false);
          notifyError("Something wrong");
        }
      );
    } else {
      setCompetitions([]);
      setIsLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearchCompetition();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputvalues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleCheck = (event) => {
    let countryListIds = [];

    if (event.target.checked) {
      countriesList.forEach((item) => {
        countryListIds.push(item.country_id);
      });
      setIsLoading(true);

      getCompetitionsForCountryId(
        countryListIds,
        function (response) {
          setSelectedCompetitions(response.data.competitions);
          setAllCompetitionsLength(response.data.competitions.length);
          setIsLoading(false);
        },
        function (error) {
          notifyError("Something wrong");
          setIsLoading(false);
        }
      );
    } else {
      setSelectedCompetitions([]);

    }
  };

  const handleCompetitions = () => {
    setIsLoading(true);
    getCompetitionsForCountryId(
      countryId,
      function (response) {
        setCompetitions(response.data.competitions);
        setIsLoading(false);
      },
      function (error) {
        notifyError("Something wrong");
        setIsLoading(false);
      }
    );
  };

  const checkReadyToAddSeason = () => {
    if (selectedCompetitions.length > 0 && inputValues.seasonName != "") {
      setReady(true);
    } else {
      setReady(false);
    }
  };

  const add_new_season = () => {
    notifyLoading(toastId, "Adding new season");
    const competitionsIds = selectedCompetitions.map((item) => { return item.competition_id })
    setReady(false);
    createNewSeason(
      competitionsIds,
      inputValues.seasonName,
      startDate,
      endDate,
      function (response) {
        let newSeason = response.data.season;
        setSearchParams((params) => {
          params.delete("cupIds")
          params.delete("seasonIds")
        })
        setSelectedSeasonsAndCups((prev) => {
          return {
            ...prev,
            seasons: [...prev.seasons, newSeason.id]
          }
        })

        setSeasonsAndCupsList((prev) => {
          return {
            ...prev,
            seasons: [newSeason, ...prev.seasons]
          }
        })

        notifyUpdate(toastId, "Season added", toast.TYPE.SUCCESS);
        setIsAddNewSeasonOpen(false);
        setInputvalues(initialInputValues);
        setCompetitions([]);
        setCountry([]);
        setCountryId([]);
        setSelectedCompetitions([]);
      },
      function () {
        setReady(true);
        notifyError("Something wrong");
      }
    );
  };

  const handleClose = () => {
    setIsAddNewSeasonOpen(false);
    setInputvalues(initialInputValues);
    setCompetitions([]);
    setCountry([]);
    setCountryId([]);
    setSelectedCompetitions([]);
  };

  useEffect(() => {
    handleCountryList();
  }, []);

  useEffect(() => {
    if (country.length > 0) {
      handleCompetitions();
    }
  }, [country]);

  useEffect(() => {
    checkReadyToAddSeason();
  }, [country, selectedCompetitions, inputValues.seasonName]);

  useEffect(() => {
    if (selectedCompetitions.length === allCompetitionsLength) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedCompetitions]);


  useEffect(() => {
    let time = setTimeout(() => {
      if (inputValues.inputSearch !== "") {
        handleSearchCompetition();
      } else {
        setCountry([])
        setCountryId([])
      }
    }, 300);
    return () => {
      clearTimeout(time);
    };
  }, [inputValues.inputSearch]);


  return (
    <Card>
      <Content>
        <Top>
          <TopLeft>
            <GroupContainer>
              <h3>SEASON NAME</h3>
              <ElementInput
                placeholder="Enter season name"
                name="seasonName"
                onChange={(e) => handleInputChange(e)}
                value={inputValues.seasonName}
              />
            </GroupContainer>
            <GroupContainer>
              <SelectAllCompetitions>
                <h2>Select all competitions</h2>
                <input
                  type={"checkbox"}
                  onChange={(e) => handleCheck(e)}
                  checked={selectAll}
                ></input>
              </SelectAllCompetitions>
            </GroupContainer>
          </TopLeft>
          <TopRight>
            <GroupContainer>
              <h3>SEASON START DATE</h3>
              <CustomDateMenu
                value={startDate}
                setValue={setStartDate}
              />
            </GroupContainer>
            <GroupContainer>
              <h3>SEASON END DATE</h3>
              <CustomDateMenu
                value={endDate}
                setValue={setEndDate}
              />
            </GroupContainer>

          </TopRight>
        </Top>


        {!selectAll && (
          <>
            <GroupContainer >
              <h2>Search by league name</h2>
              <div className="searchGroup">
                <SearchSvg />
                <ElementInput
                  placeholder="Search leagues"
                  name="inputSearch"
                  onChange={(e) => handleInputChange(e)}
                  value={inputValues.inputSearch}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
                <button onClick={() => handleSearchCompetition()}>
                  {" "}
                  Search
                </button>
              </div>
            </GroupContainer>


            <CompetitionsList>
              <Left>
                <GroupContainer>
                  <h2>Choose leagues from a specific country</h2>
                  <CountryMenuSingleOption
                    label={"COUNTRY"}
                    countriesList={countriesList}
                    setCountry={setCountry}
                    country={country}
                    setCountryId={setCountryId}
                    countryId={countryId}
                    setInputvalues={setInputvalues}
                  />
                  {isLoading && <LoaderSpinner />}
                </GroupContainer>

                <LeaguesList
                  country={country}
                  competitions={competitions}
                  selectedCompetitions={selectedCompetitions}
                  setSelectedCompetitions={setSelectedCompetitions}
                />
              </Left>
              <Right>
                {selectedCompetitions.length > 0 && (
                  <GroupContainer>
                    <h4>Your selection</h4>
                    <SelectionList
                      country={country}
                      selectedCompetitions={selectedCompetitions}
                      setSelectedCompetitions={setSelectedCompetitions}
                    />
                  </GroupContainer>
                )}
              </Right>
            </CompetitionsList>
          </>
        )}
      </Content>

      <Bottom>
        <button disabled={!ready} onClick={() => add_new_season()}>
          Add season
        </button>
      </Bottom>
    </Card>


  );
};

export default AddNewSeason;


const Card = styled.div`
  display: flex;
  flex-direction: column;
height:100%;
overflow:hidden;
`;
const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding:0 40px;
height:100%;
overflow:auto;
`;


const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  
  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
     color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
  h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
     color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */

     color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
  .searchGroup {
    display: flex;
    position: relative;
    column-gap: 10px;
    input {
      padding-left: 30px;
    }
    svg {
      position: absolute;
      top: 13px;
      left: 10px;
    }
    button {
      background: ${({ theme }) => theme.editLeague.saveAllBtnBg};
      border: 1px solid #006fff;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${({ theme }) => theme.editLeague.buttonWithConfirmationTextColor};
      padding: 7px 28px;
      cursor: pointer;
    }
  }
`;
const ElementInput = styled.input`
  height: 40px;
  background: ${({ theme }) => theme.editLeague.readOnlyInputs};
  width: 280px;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  padding: 10px 15px;

`;
const Bottom = styled.div`
  background: ${({ theme }) => theme.editLeague.headerBg};
  padding: 10px 40px;
  border-radius: 0 0 16px 16px;
  display: flex;
  justify-content: end;
  min-height:60px;
  button {
    background-color: var(--primaryBlue);
    color: white;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 27px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    :disabled {
      opacity: 0.5;
    }
  }
`;

const CompetitionsList = styled.div`
  display: flex;
  min-height: 350px;
  width: 100%;
`;
const Left = styled.div`
  min-width: 280px;
  border-right: 1.5px solid #eef2f6;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const Right = styled.div`
  padding: 20px 0 10px 15px;
  width: 100%;
  border-left: 1.5px solid #eef2f6;
`;
const SelectAllCompetitions = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;

  h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

     color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
`;

const TypeMenu = styled.div`


`
const Top = styled.div`
display: flex;
gap: 10px;
`
const TopLeft = styled.div`
display: flex;
flex-direction: column;
row-gap: 25px;
`
const TopRight = styled.div`
display: flex;
flex-direction: column;
row-gap: 25px;
`