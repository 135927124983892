import React from "react";
import { func } from "prop-types";
import styled from "styled-components";
import { lightTheme } from "../../theme/theme";
import { ReactComponent as DotSvg } from "../../assets/icons/dot.svg";
const ToggleDarkMode = ({ toggleTheme }) => {
  return (
    <Container>
      <p>Dark Mode</p>
      <button onClick={toggleTheme}>
        <span className="dot">
          <DotSvg />
        </span>
      </button>
    </Container>
  );
};

ToggleDarkMode.propTypes = {
  toggleTheme: func.isRequired,
};

export default ToggleDarkMode;
const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 8px;
  p {
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  button {
    position: relative;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    display: flex;
    position: relative;
    width: 35px;
    height: 15px;
    background-color: ${({ theme }) => theme.toggleBg};
    span {
      top: -2.5px;
      position: absolute;
      &:first-child {
        transform: ${({ theme }) =>
          theme === lightTheme ? "translateX(0)" : "translateX(20px)"};
      }
    }
  }
  .dot {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    background-color: ${({ theme }) => theme.toggleColor};
    border-radius: 50%;
  }
`;
