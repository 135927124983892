import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownSvg } from "../../../assets/icons/downSimpleArrow.svg";

import { checkPositionColor } from "../../../helper/Analyst/SquadManager/checkPOSNCellColor";
import { Positions } from "../../../helper/positionsList";
import cloneDeep from "lodash.clonedeep";


const PlayerPositionMenu = ({ valueList, label, value, setValue }) => {

  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const otherPositions = (playerPrincipalPositions, allPositions) => {
    let remainingPositions = cloneDeep(allPositions);

    playerPrincipalPositions.forEach(element => {
      remainingPositions = remainingPositions.filter(item => item.position != element);
    });

    return (remainingPositions);
  };

  const handleMenuOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  const handleCheck = (item) => {
    setValue(item);
    handleMenuOpen();
  };


  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);

      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container ref={ref}>
      <DropDownMenu onClick={() => handleMenuOpen()} isMenuOpen={isMenuOpen} className={checkPositionColor(value)}>
        {value !== null ? <h4>{value}</h4> : <h4>{label}</h4>}
        {isMenuOpen ? <DownSvg style={{ rotate: "180deg" }} /> : <DownSvg />}
      </DropDownMenu>
      {isMenuOpen && (

        <DropDownList separatorPosition={valueList?.length}>
          {valueList.map((item, index) => (
            <DropDownMenuItem key={index} onClick={() => handleCheck(item)}>
              <p> {item}</p>
            </DropDownMenuItem>
          ))}
          {otherPositions(valueList, Positions)?.map((item, index) => (
            <DropDownMenuItem key={index} onClick={() => handleCheck(item.position)}>
              <p> {item.position}</p>
            </DropDownMenuItem>
          ))}
        </DropDownList>




      )}

    </Container>
  );
};


export default PlayerPositionMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 50px;
  height:100%;
  cursor:pointer;
  .green {
    border-color: #cbf2dc;
    background: #ebfcf3;
    h4 {
      color: #23a760;
    }
    p{
      color: #23a760;
    }
  }
  .blue {
    border-color: #D5DFF7;
    background: #E4ECFF;
    h4 {
      color: #4393FC;
    }
  }
  .orange {
    border-color: #F6DABD;
    background: #FFF3E7;
    h4 {
      color: #FF8D21;
    }
  }
  .red {
    border-color: #F9DDDD;
    background: #FFF5F5;
    h4 {
      color: #EC4848;
    }
    p{
      color: #EC4848;
    }
  }
`;
const DropDownMenu = styled.div`
    display: flex;
    border: 1px solid #CBD7F5;
    border-radius: 2px;
    min-height:20px;
    justify-content: space-between;
    padding: 3px 4px 3px 8px;
    align-items: center;
    background-color: #ffffff;
    h4{
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.03em;
    color: var(--darkBlue);
    }
svg{
  color:#006fff;
}
  `;
const DropDownMenuItem = styled.li`
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  padding:2px;
  p{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 150%;
  color: var(--darkBlue);
  }
    cursor: pointer;
  `;

const DropDownList = styled.ul`
  position: absolute;
  width:100%;
  z-index: 2;
  border-top: 1px solid #3c4a5c;
  border-left: 1px solid #CBD7F5;
  border-bottom: 1px solid #CBD7F5;
  border-right: 1px solid #CBD7F5;
  border-radius: 2px;
  top: 18px;
  display: flex;
  flex-direction: column;
  max-height:160px;
  color: black;
  background:white;
  overflow:auto;
${DropDownMenuItem}:nth-child(${props => props?.separatorPosition}) {
  border-bottom:1px solid #CBD7F5;
padding:0;
}
`;

