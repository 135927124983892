import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Positions } from "../../../helper/positionsList";
import PositionMenu from "../../squadManagerComponents/Menu/PositionMenu";
import { useLeaguesDataService } from "../../../context/useLeaguesDataService";
import { notifyError, notifyLoading, notifySucces } from "../../../helper/Generic/Notifications";
import SearchPlayerMenu from "./SearchPlayerMenu";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";

const AddNewPlayerSection = ({ handleGetPlayers, teamID, leagueToEdit, setIsLoading, refreshOnClose, needToRefreshList, toastId,
    setPlayers, setInitialPlayers, players, initialPlayers, sortPlayersAlphabetical }) => {

    const { add_player, search_player } = useLeaguesDataService();

    const [number, setNumber] = useState("");
    const [position1, setPosition1] = useState(null);
    const [position2, setPosition2] = useState(null);
    const [position3, setPosition3] = useState(null);
    const [readyToSave, setreadyToSave] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [playersList, setPlayersList] = useState(null);
    const [newPlayer, setNewPlayer] = useState(null);
    const shouldSearch = useRef(false);

    const handleNumberInput = event => {
        const result = event.target.value.replace(/\D/g, '');
        setNumber(result);
    };

    const checkSaveReady = () => {
        if (
            newPlayer != null &&
            (position1 && position1 != null ||
                position2 && position2 != null ||
                position3 && position3 != null)
        ) {

            setreadyToSave(true);
        } else {
            setreadyToSave(false);
        }
    };

    const handleAddPlayer = () => {
        setIsLoading(true)
        add_player(
            teamID.id,
            newPlayer.first_name,
            newPlayer.last_name,
            newPlayer.short_name,
            newPlayer.other_player_name,
            number,
            position1,
            position2,
            position3,
            newPlayer.wy_id,
            function (response) {
                notifySucces("Player added");
                resetToInitialValues();
                if (needToRefreshList) {
                    refreshOnClose.current = true;
                }
                const playerResponse = response.data.player;

                let playersClone = cloneDeep(players)
                let initialPlayersClone = cloneDeep(initialPlayers)

                playersClone.push(playerResponse)
                initialPlayersClone.push(playerResponse)

                setPlayers(sortPlayersAlphabetical(playersClone));
                setInitialPlayers(sortPlayersAlphabetical(initialPlayersClone));
                setIsLoading(false)
                // handleGetPlayers();
            },
            function (error) {
                setIsLoading(false)
                notifyError("Someting wrong");
            }
        );
    };

    const mapDifferentCountryCodes = (country_code) => {
        if (!country_code) {
            return
        }

        if (country_code == "EN") {
            return "GB"
        }

        return country_code
    }

    const handlePlayersResponse = (players) => {

        let mappedPlayers = [];

        players.forEach(player => {
            let idx = mappedPlayers.findIndex((item) => item.country == player.player.country);
            if (idx == -1) {
                mappedPlayers.push({
                    country: player.player.country,
                    country_code: player.player.country_code,
                    players: [
                        {
                            player: player.player,
                            team_name: player.team_name
                        }]
                });
            }
            if (idx != -1) {
                mappedPlayers[idx].players.push({
                    player: player.player,
                    team_name: player.team_name
                });
            }
        });

        let sorted = mappedPlayers.sort((a, b) => a.country > b.country ? 1 : -1);

        //find if exist index of element.country_code==currentLeague.country_code
        let wantedElementIdx = sorted.findIndex((item) => mapDifferentCountryCodes(item.country_code) == leagueToEdit?.country.country_code);

        if (wantedElementIdx != -1) {
            let element = sorted.splice(wantedElementIdx, 1)[0];
            sorted.splice(0, 0, element);
        }

        setPlayersList(sorted);
        setIsLoading(false);
    };

    const handleSearchPlayer = (term) => {
        setIsLoading(true);
        search_player(
            term,
            function (response) {
                setIsLoading(false);
                handlePlayersResponse(response.data.players);
            },
            function (error) {
                setIsLoading(false);
                notifyError("Something wrong...");
            }
        );
    };


    const resetToInitialValues = () => {
        setSearchTerm("");
        setNewPlayer(null);
        setNumber("");
        setPosition1(null);
        setPosition2(null);
        setPosition3(null);
        setNewPlayer(null);
        setPlayersList(null)
    };

    useEffect(() => {
        checkSaveReady();
    }, [
        newPlayer,
        number,
        position1,
        position2,
        position3,
    ]);

    useEffect(() => {
        resetToInitialValues();
    }, [teamID]);

    useEffect(() => {
        if (shouldSearch.current == false) {
            return;
        }

        if (searchTerm == "" || searchTerm.length < 3) {
            setPlayersList(null);
            return;
        }

        const timer = setTimeout(() => {
            handleSearchPlayer(searchTerm);
        }, 250);

        return () => clearTimeout(timer);
    }, [searchTerm]);

    return (
        <Container>
            <Group>
                <h1>PLAYER NAME</h1>
                <SearchPlayerMenu
                    term={searchTerm}
                    setTerm={setSearchTerm}
                    value={newPlayer}
                    setValue={setNewPlayer}
                    valuesList={playersList}
                    shouldSearch={shouldSearch}
                />
            </Group>
            <Group>
                <h1>NUMBER</h1>
                <NumberInput
                    style={{ width: "52px" }}
                    placeholder={"-"}
                    value={number}
                    onChange={(e) => handleNumberInput(e)}
                ></NumberInput>
            </Group>
            <Group>
                <h1>FIRST POSITION</h1>
                <PositionMenu
                    valuesList={Positions}
                    value={position1}
                    setValue={setPosition1}
                    smallMenu={true}
                />
            </Group>
            <Group>
                <h1>SECOND POSITION</h1>
                <PositionMenu
                    valuesList={Positions}
                    value={position2}
                    setValue={setPosition2}
                    smallMenu={true}
                />
            </Group>
            <Group>
                <h1>THIRD POSITION</h1>
                <PositionMenu
                    valuesList={Positions}
                    value={position3}
                    setValue={setPosition3}
                    smallMenu={true}
                />
            </Group>


            <Group style={{ justifyContent: "end" }}>
                <AddPlayerButton
                    disabled={!readyToSave}
                    onClick={(e) => handleAddPlayer()}
                >
                    Add player
                </AddPlayerButton>
            </Group>
        </Container>
    );
};

export default AddNewPlayerSection;

const Container = styled.div`
  display: flex;
  column-gap: 16px;
  border-bottom: 3px solid #f8f9fb;
  padding-bottom: 15px;
  margin-bottom: 15px;
  max-width: calc(100% - 130px);
`;
const Group = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
       color: ${({ theme }) => theme.editLeague.groupsTextColor}
  }
`;
const NumberInput = styled.input`
  height: 32px;
   background-color: ${({ theme }) => theme.editLeague.readOnlyInputs};
  width: 240px;
  border: 1px solid #cbd7f5;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  padding: 10px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  ::placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #ababab;
  }


`;
const AddPlayerButton = styled.button`
  background: #006fff;
  border-radius: 4px;
  width: 112px;
  height: 32px;
  display: flex;
  margin-left:10px;
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: white;
  border: none;
  cursor: pointer;
  :disabled {
    opacity: 0.5;
  }
`;
