import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as PlusSvg } from "../../../assets/icons/LeaguesIcons/plusIcon.svg";
import LoaderSpinner from "../../../helper/LoaderSpinner";
import { ReactComponent as UpSvg } from "../../../assets/icons/PricingMenuIcons/up.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { removeDiacritics } from "../../../helper/Generic/StringHelpers";

const AddPlayerGroupPosition = ({
  handleAddNewPlayerToGroup,
  squadData,
  position,
  shouldReinitialize,
  selectedPlayers,
}) => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState();
  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [term, setTerm] = useState("");
  const disableMenuOpenRef = useRef(true);

  const ref = useRef();

  const mapName = (currentPLayer) => {
    let player = currentPLayer.player;
    let fullName = player.first_name + " " + player.last_name;

    return fullName;
  };

  const handleSelectPlayer = (player) => {
    disableMenuOpenRef.current = true;
    setSelectedPlayer(player);
    setTerm(mapName(player));
    setIsMenuOpen(false);
  };

  const filterPlayersByTerm = (players, term) => {
    if (term == "") {
      return players;
    }

    let filtered = players.filter((player) => {
      let fullName = mapName(player);
      return removeDiacritics(fullName.toLowerCase()).includes(
        term.toLowerCase()
      );
    });
    return filtered;
  };

  const initializeData = () => {
    let availableSquadMember = squadData.selections.filter(
      (item) => item.id && item.player.transferred == false
    );
    let filteredNotSelectedPlayers = availableSquadMember;
    selectedPlayers?.forEach((element) => {
      filteredNotSelectedPlayers = filteredNotSelectedPlayers.filter(
        (item) => item.player_id != element.selection.player_id
      );
    });
    let sorted = filteredNotSelectedPlayers.sort((a, b) =>
      mapName(a) > mapName(b) ? 1 : -1
    );
    setAvailablePlayers(sorted);
  };

  useEffect(() => {
    if (!squadData) {
      return;
    }
    initializeData();
  }, [squadData, selectedPlayers?.length]);

  const resetMenu = () => {
    if (term == "") {
      setSelectedPlayer(null);
    }
  };

  useEffect(() => {
    if (shouldReinitialize.current) {
      disableMenuOpenRef.current = true;
      setIsMenuOpen(false);
      //   setTerm("");
      //   shouldReinitialize.current = false;
    }
  }, [shouldReinitialize.current]);

  useEffect(() => {
    resetMenu();
  }, [term == ""]);

  useEffect(() => {

  }, [term]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const handleAdd = () => {
    disableMenuOpenRef.current = false;
    setTerm("");
    handleAddNewPlayerToGroup(
      selectedPlayer.squad_id,
      selectedPlayer.id,
      position
    );
  };

  const valueChanged = (value) => {
    // if (!disableMenuOpenRef.current) {
    setIsMenuOpen(true);
    // }

    setTerm(value);
  };

  return (
    <Container>
      <GroupContainer style={{ alignItems: "end" }}>
        <MenuWrapper ref={ref}>
          <SelectedItemWrapper onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <ElementInput
              onClick={() => setIsMenuOpen(true)}
              placeholder={"Search player"}
              value={term}
              onChange={(e) => valueChanged(e.currentTarget.value)}
            />
            <IconWrapper>
              {isMenuOpen ? <UpSvg /> : <UpSvg style={{ rotate: "180deg" }} />}
            </IconWrapper>
          </SelectedItemWrapper>
          {isMenuOpen && (
            <MenuList>
              {filterPlayersByTerm(availablePlayers, term)?.map(
                (player, index) => (
                  <MenuListItem
                    key={index}
                    onClick={() => handleSelectPlayer(player)}
                  >
                    <input
                      value={player.player}
                      type="radio"
                      checked={
                        selectedPlayer?.player?.id === player?.player?.id
                      }
                      readOnly
                    />
                    <p>
                      {mapName(player)} ({player.player?.wy_id})
                    </p>
                  </MenuListItem>
                )
              )}
            </MenuList>
          )}
        </MenuWrapper>
        <NewRefereeButton
          disabled={!selectedPlayer}
          onClick={() => handleAdd()}
        >
          <PlusSvg />
          Add new player
        </NewRefereeButton>
      </GroupContainer>
    </Container>
  );
};

export default AddPlayerGroupPosition;

const Container = styled.div`
  width: 100%;
`;
const MenuWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  max-height: 250px;
  overflow: auto;
  width: 100%;
  background: ${({ theme }) => theme.editLeague.readOnlyInputs};
  z-index: 2;
  border: 1px solid #bac3e8;
  margin-top: -3px;
`;
const MenuListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  width: 100%;
  cursor: pointer;
  gap: 5px;
  align-items: center;

  p {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    color: ${({ theme }) => theme.editLeague.menuTextColor};
  }
  input {
    height: 14px;
  }
`;
const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
`;

const ElementInput = styled.input`
  height: 40px;
  background: ${({ theme }) => theme.editLeague.readOnlyInputs};
  width: 100%;
  border: 1px solid #bac3e8;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
  padding: 10px 30px 10px 15px;
  cursor: inherit;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: ${({ theme }) => theme.editLeague.readOnlyInputsColor};
    opacity: 0.7;
  }
  :disabled {
    background: ${({ theme }) => theme.editLeague.readOnlyInputs};
    color: "red";
    opacity: inherit;
  }
`;
const NewRefereeButton = styled.button`
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  background: #ffffff;
  border: 0.7px solid #006fff;
  border-radius: 3px;
  padding: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--primaryBlue);
  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`;

const SelectedItemWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const DropdownArrow = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  svg {
    cursor: pointer;
    fill: ${({ theme }) => theme.editLeague.selectMenuSvgBg};
    stroke: ${({ theme }) => theme.editLeague.selectMenuSvgColor};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
  width: 16px;
  height: 16px;
  svg {
    stroke: #006fff;
  }
`;
